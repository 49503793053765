<template>
  <el-dialog
    :visible.sync="active"
    width="70%"
    :close-on-click-modal="false"
    :close-on-press-escape="true"
    :before-close="close"
    destroy-on-close
    style="max-height: 90vh"
    title="修改项目信息"
  >
    <el-form :model="project" ref="projectform">
      <div>
        <div class="project_type">学生来源信息</div>
        <el-row>
          <el-col style="margin-bottom: 10px; height: 60px">
            <div class="title">渠道</div>
            <div class="form-item-error">
              <el-form-item
                prop="channel"
                :rules="{
                  validator: channelCheck,
                  trigger: 'change',
                  required: true
                }"
              >
                <channelSearchBar
                  :type.sync="project.channel_type"
                  :select.sync="project.channel"
                  :cmanager.sync="project.cmanager"
                  :amanager.sync="project.amanager"
                  @validateChannel="validateChannel"
                />
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row class="top">
          <el-col :span="12" v-if="project.channel_type.indexOf('own') === -1">
            <el-form-item prop="cmanager">
              <div class="title">渠道经理*</div>
              <el-select
                placeholder="渠道经理"
                v-model="project.cmanager"
                class="input saleselect"
                :disabled="
                  project.channel_type.indexOf('own') !== -1 ? false : true
                "
              >
                <el-option
                  v-for="item in cmanagerlist"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="project.channel_type.indexOf('inst') !== -1">
            <el-form-item prop="amanager">
              <div class="title">客户经理*</div>
              <el-select
                placeholder="客户经理"
                v-model="project.amanager"
                class="input saleselect"
                :disabled="true"
              >
                <el-option
                  v-for="item in amanagerlist"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="sponser"
              :rules="{
                required: true,
                message: '请选择规划师',
                trigger: 'change'
              }"
            >
              <div class="title">规划师*</div>
              <el-select
                placeholder="规划师"
                v-model="project.sponser"
                class="input saleselect"
              >
                <el-option
                  v-for="item in plannerlist"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" :disabled="pending" @click="pushProject">
        修 改
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import channelSearchBar from '../channelSearchBar'
import axios from '../../axios'
import { MessageBox } from 'element-ui'
export default {
  name: 'editPart',
  data () {
    return {
      project: {
        id: '',
        project_type: '',
        channel_type: 'inst', // 0渠道，1顾问
        channel: NaN, // channel对应的id
        misc: '', // 备注
        sponser: '', // 支持
        connectTime: '', // 可联系时间
        startTime: '', // 意向开始时间 string
        projectName: '', // 项目名称
        attachments: [], // 附件
        isPacked: false,
        amanager: '',
        cmanager: ''
      },
      project_temp: '',
      active: false,
      pending: false,
      plannerlist: [],
      cmanagerlist: [],
      amanagerlist: []
    }
  },
  props: {
    api: ''
  },
  components: {
    channelSearchBar
  },
  watch: {
    'project.channel': function (newval, oldval) {
      if (
        (typeof newval === 'number' || typeof newval === 'undefined') &&
        typeof oldval === 'object'
      ) {
        this.project_temp = JSON.stringify(this.project)
      }
    }
  },
  methods: {
    validateChannel () {
      this.$refs.project1.validateField('channel')
    },
    channelCheck (rule, value, callback) {
      if (this.project.channel_type === 'own' || this.project.channel !== '') {
        callback()
      } else {
        callback(new Error('请选择渠道'))
      }
    },
    openDialog (project) {
      this.project = {
        ...this.$options.data().project,
        ...project,
        channel_type: project.channel_type || 'inst'
      }
      this.project_temp = JSON.stringify(this.project)
      this.active = true
      let role = this.$store.state.roleApi
      axios.get(`/${role}/sponser_list?role=cmanager`).then(response => {
        this.cmanagerlist = response.data.supports
      })
      axios.get(`/${role}/sponser_list?role=amanager`).then(response => {
        this.amanagerlist = response.data.supports
      })
      axios.get(`/${role}/sponser_list?role=planner`).then(response => {
        this.plannerlist = response.data.supports
      })
    },
    pushProject () {
      let that = this
      const projectToSend = { ...this.project }
      this.$refs.projectform.validate(function (valid) {
        if (valid) {
          if (that.project_temp === JSON.stringify(that.project)) {
            that.close()
          } else {
            MessageBox.confirm('是否更改此项目状态')
              .then(() => {
                that.pending = true
                projectToSend.attachments = projectToSend.attachments.map(
                  attachment =>
                    typeof attachment === 'string' ? attachment : attachment.id
                )
                axios
                  .put(
                    `/${that.$store.state.roleApi}/project/${that.project.id}/change_part`,
                    projectToSend
                  )
                  .then(response => {
                    if (response.status === 200) {
                      that.$message({
                        showClose: true,
                        message: `修改成功`,
                        type: 'success'
                      })
                      setTimeout(() => {
                        window.location.reload()
                      }, 1000)
                    }
                  })
                  .catch(error => {
                    console.log(error)
                  })
              })
              .catch(e => {
                console.error(e)
              })
          }
        }
      })
    },
    close () {
      console.log(this.project_temp !== JSON.stringify(this.project))
      if (this.project_temp !== JSON.stringify(this.project)) {
        MessageBox.confirm('是否放弃更改内容').then(() => {
          this.active = false
          this.project = {
            ...this.$options.data().project
          }
          this.pending = false
        })
      } else {
        this.active = false
        this.project = {
          ...this.$options.data().project
        }
        this.pending = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
