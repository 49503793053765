import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import login from '../components/login'
import Course from '../components/Course'
import incourse from '../components/incourse'
import Video from '../components/Video'
import newmission from '../components/S/newmission'
import smodify from '../components/S/smodify'
import ku from '../components/S/ku'
import Sview from '../components/S/Sview'
import tfinish from '../components/T/tfinish'
import tmodify from '../components/T/tmodify'
import tschedule from '../components/T/tschedule'
import readOnlyManual from '../components/T/readOnlyManual'
import Tview from '../components/T/Tview'
import projectInfo from '../components/public/projectInfo/index.vue'
import Jview from '../components/J/Jview'
import Jstudent from '../components/J/Jstudent'
import Jteacher from '../components/J/Jteacher'
import Jaccount from '../components/J/Jaccount'
import Jschedule from '../components/J/Jschedule'
import Jpost from '../components/J/Jpost'
import Jrecommendations from '../components/J/Jrecommendations'
import Jretouches from '../components/J/Jretouches'
import Jinterview from '../components/J/Jinterview'
import Jmanual from '../components/J/Jmanual'
import ASview from '../components/AS/ASview'
import ASschedule from '../components/AS/ASschedule'
import ASpost from '../components/AS/ASpost'
import ASrecommendations from '../components/AS/ASrecommendations'
import ASretouches from '../components/AS/ASretouches'
import ASstudent from '../components/AS/ASstudent'
import Aview from '../components/A/Aview'
import Ainterview from '../components/A/Ainterview'
import Aschedule from '../components/A/Aschedule'
import Apost from '../components/A/Apost'
import Arecommendations from '../components/A/Arecommendations'
import Aretouches from '../components/A/Aretouches'
import Astudent from '../components/A/Astudent'
import Aaccount from '../components/A/Aaccount'
import Ateacher from '../components/A/Ateacher'
import Dview from '../components/D/Dview'
import Dinterview from '../components/D/Dinterview'
import Dschedule from '../components/D/Dschedule'
import Dstudent from '../components/D/Dstudent'
import Dviewcha from '../components/D/Dviewcha'
import Dchannel from '../components/D/Dchannel'
import Kview from '../components/K/Kview'
import Kinterview from '../components/K/Kinterview'
import Kschedule from '../components/K/Kschedule'
import Kpost from '../components/K/Kpost'
import Krecommendations from '../components/K/Krecommendations'
import Kretouches from '../components/K/Kretouches'
import Kstudent from '../components/K/Kstudent'
import Kteacher from '../components/K/Kteacher'
import Kchannel from '../components/K/Kchannel'
import Kaccount from '../components/K/Kaccount'
import Kviewchan from '../components/K/Kviewchan'
import TDview from '../components/TD/TDview'
import TDinterview from '../components/TD/TDinterview'
import TDschedule from '../components/TD/TDschedule'
import TDpost from '../components/TD/TDpost'
import TDrecommendations from '../components/TD/TDrecommendations'
import TDretouches from '../components/TD/TDretouches'
import TDstudent from '../components/TD/TDstudent'
import TDteacher from '../components/TD/TDteacher'
import TDsupport from '../components/TD/TDsupport'
import TDaccount from '../components/TD/TDaccount'
import TDmanual from '../components/TD/TDmanual'
import MDview from '../components/MD/MDview'
import MDinterview from '../components/MD/MDinterview'
import MDschedule from '../components/MD/MDschedule'
import MDpost from '../components/MD/MDpost'
import MDrecommendations from '../components/MD/MDrecommendations'
import MDretouches from '../components/MD/MDretouches'
import MDstudent from '../components/MD/MDstudent'
import MDchannel from '../components/MD/MDchannel'
import MDteacher from '../components/MD/MDteacher'
import MDsupport from '../components/MD/MDsupport'
import MDaccount from '../components/MD/MDaccount'
import MDviewcha from '../components/MD/MDviewcha'
import MSview from '../components/MS/MSview'
import MSinterview from '../components/MS/MSinterview'
import MSmeet from '../components/MS/MSmeet'
import Mview from '../components/M/Mview'
import Mjurisdict from '../components/M/Mjurisdict'
import Maccount from '../components/M/Maccount'
import ADview from '../components/AD/ADview'
import ADschedule from '../components/AD/ADschedule'
import ADstudent from '../components/AD/ADstudent'
import ADpost from '../components/AD/ADpost'
import ADrecommendations from '../components/AD/ADrecommendations'
import ADretouches from '../components/AD/ADretouches'
import ADassistant from '../components/AD/ADassistant'
import ADteacher from '../components/AD/ADteacher'
import Clist from '../components/C/Clist'
import Home from '../components/V/Home'
import Thesis from '../components/Topic/Thesis'
import Research from '../components/Topic/Research'
import Research_pack from '../components/Topic/Research_pack'
import Thesis_pack from '../components/Topic/Thesis_pack'
import Epq from '../components/Topic/Epq'
import Iap from '../components/Topic/Iap'
import IapHigh from '../components/Topic/IapHigh'
import Status from '../components/Topic/Status'
import preview from '../components/Topic/preview'
import TopicPlusView from '../components/Topic/TopicPlusView'
import TopicView from '../components/Topic/TopicView'
import TopicT from '../components/Topic/TopicT'
import TopicR from '../components/Topic/TopicR'
import TopicA from '../components/Topic/TopicA'
import TopicRT from '../components/Topic/TopicRT'
import TopicEpq from '../components/Topic/TopicEpq'
import TopicIap from '../components/Topic/TopicIap'
import TopicIapHigh from '../components/Topic/TopicIapHigh'
import Topicglasgow from '../components/Topic/Topic-glasgow'
import previewglasgow from '../components/Topic/preview-glasgow'
import topicMobile from '../components/Topic/topic-mobile'
import mobileView from '../components/Topic/mobileView'
import Finance from '../components/F/Finance'
import ETview from '../components/ET/ETview'
import ETT from '../components/ET/TopicT'
import ETR from '../components/ET/TopicR'
import ETA from '../components/ET/TopicA'
import ETRT from '../components/ET/TopicRT'
import ETEpq from '../components/ET/TopicEpq'
import ETIap from '../components/ET/TopicIap'
import ETIapHigh from '../components/ET/TopicIapHigh'
import Eview from '../components/E/Eview'
import Einterview from '../components/E/Einterview'
import Eschedule from '../components/E/Eschedule'
import Estudent from '../components/E/Estudent'
import manual_mp from '../components/manual/manual_mp'
import pdfReview_mp from '../components/manual/pdfReview_mp'
import notFound from '../components/404'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/video',
    name: 'Video',
    component: Video
  },
  {
    path: '/course',
    name: 'Course',
    component: Course
  },
  {
    path: '/incourse',
    name: 'incourse',
    component: incourse
  },
  {
    path: '/S',
    name: 'S',
    component: Sview,
    children: [
      {
        path: 'newmission',
        name: 'newmission',
        component: newmission
      },
      {
        path: 'smodify',
        name: 'smodify',
        component: smodify
      },
      {
        path: 'ku',
        name: 'ku',
        component: ku
      }
    ]
  },
  {
    path: '/T',
    name: 'T',
    component: Tview,
    children: [
      {
        path: 'tfinish',
        name: 'tfinish',
        component: tfinish
      },
      {
        path: 'tmodify',
        name: 'tmodify',
        component: tmodify
      },
      {
        path: 'tschedule',
        name: 'tschedule',
        component: tschedule
      },
      {
        path: 'readOnlyManual',
        name: 'readOnlyManual',
        component: readOnlyManual
      }
    ]
  },
  {
    path: '/J',
    name: 'J',
    component: Jview,
    children: [
      {
        path: 'Jinterview',
        name: 'Jinterview',
        component: Jinterview
      },
      {
        path: 'Jstudent',
        name: 'Jstudent',
        component: Jstudent
      },
      {
        path: 'Jteacher',
        name: 'Jteacher',
        component: Jteacher
      },
      {
        path: 'Jaccount',
        name: 'Jaccount',
        component: Jaccount
      },
      {
        path: 'Jschedule',
        name: 'Jschedule',
        component: Jschedule
      },
      {
        path: 'Jpost',
        name: 'Jpost',
        component: Jpost
      },
      {
        path: 'Jrecommendations',
        name: 'Jrecommendations',
        component: Jrecommendations
      },
      {
        path: 'Jretouches',
        name: 'Jretouches',
        component: Jretouches
      },
      {
        path: 'Jmanual',
        name: 'Jmanual',
        component: Jmanual
      }
    ]
  },
  {
    path: '/AS',
    name: 'AS',
    component: ASview,
    children: [
      {
        path: 'ASschedule',
        name: 'ASschedule',
        component: ASschedule
      },
      {
        path: 'ASpost',
        name: 'ASpost',
        component: ASpost
      },
      {
        path: 'ASrecommendations',
        name: 'ASrecommendations',
        component: ASrecommendations
      },
      {
        path: 'ASretouches',
        name: 'ASretouches',
        component: ASretouches
      },
      {
        path: 'ASstudent',
        name: 'ASstudent',
        component: ASstudent
      }
    ]
  },
  {
    path: '/A',
    name: 'A',
    component: Aview,
    children: [
      {
        path: 'Ainterview',
        name: 'Ainterview',
        component: Ainterview
      },
      {
        path: 'Aschedule',
        name: 'Aschedule',
        component: Aschedule
      },
      {
        path: 'Apost',
        name: 'Apost',
        component: Apost
      },
      {
        path: 'Arecommendations',
        name: 'Arecommendations',
        component: Arecommendations
      },
      {
        path: 'Aretouches',
        name: 'Aretouches',
        component: Aretouches
      },
      {
        path: 'Astudent',
        name: 'Astudent',
        component: Astudent
      },
      {
        path: 'Ateacher',
        name: 'Ateacher',
        component: Ateacher
      },
      {
        path: 'Aaccount',
        name: 'Aaccount',
        component: Aaccount
      }
    ]
  },
  {
    path: '/D',
    name: 'D',
    component: Dview,
    children: [
      {
        path: 'Dinterview',
        name: 'Dinterview',
        component: Dinterview
      },
      {
        path: 'Dschedule',
        name: 'Dscherview',
        component: Dschedule
      },
      {
        path: 'Dstudent',
        name: 'Dstudent',
        component: Dstudent
      },
      {
        path: 'Dchannel',
        name: 'Dchannel',
        component: Dchannel
      },
      {
        path: 'Dviewcha',
        name: 'Dviewcha',
        component: Dviewcha
      }
    ]
  },
  {
    path: '/K',
    name: 'K',
    component: Kview,
    children: [
      {
        path: 'Kinterview',
        name: 'Kinterview',
        component: Kinterview
      },
      {
        path: 'Kschedule',
        name: 'Kschedule',
        component: Kschedule
      },
      {
        path: 'Kpost',
        name: 'kpost',
        component: Kpost
      },
      {
        path: 'Krecommendations',
        name: 'Krecommendations',
        component: Krecommendations
      },
      {
        path: 'Kretouches',
        name: 'Kretouches',
        component: Kretouches
      },
      {
        path: 'Kstudent',
        name: 'Kstudent',
        component: Kstudent
      },
      {
        path: 'Kteacher',
        name: 'Kteacher',
        component: Kteacher
      },
      {
        path: 'Kchannel',
        name: 'Kchannel',
        component: Kchannel
      },
      {
        path: 'Kaccount',
        name: 'Kaccount',
        component: Kaccount
      },
      {
        path: 'Kviewchan',
        name: 'Kviewchan',
        component: Kviewchan
      }
    ]
  },
  {
    path: '/TD',
    name: 'TD',
    component: TDview,
    children: [
      {
        path: 'TDinterview',
        name: 'TDinterview',
        component: TDinterview
      },
      {
        path: 'TDschedule',
        name: 'TDschedule',
        component: TDschedule
      },
      {
        path: 'TDpost',
        name: 'TDpost',
        component: TDpost
      },
      {
        path: 'TDrecommendations',
        name: 'TDrecommendations',
        component: TDrecommendations
      },
      {
        path: 'TDretouches',
        name: 'TDretouches',
        component: TDretouches
      },
      {
        path: 'TDstudent',
        name: 'TDstudent',
        component: TDstudent
      },
      {
        path: 'TDteacher',
        name: 'TDteacher',
        component: TDteacher
      },
      {
        path: 'TDaccount',
        name: 'TDaccount',
        component: TDaccount
      },
      {
        path: 'TDsupport',
        name: 'TDsupport',
        component: TDsupport
      },
      {
        path: 'TDmanual',
        name: 'TDmanual',
        component: TDmanual
      }
    ]
  },
  {
    path: '/MD',
    name: 'MD',
    component: MDview,
    children: [
      {
        path: 'MDinterview',
        name: 'MDinterview',
        component: MDinterview
      },
      {
        path: 'MDschedule',
        name: 'MDschedule',
        component: MDschedule
      },
      {
        path: 'MDpost',
        name: 'MDpost',
        component: MDpost
      },
      {
        path: 'MDrecommendations',
        name: 'MDrecommendations',
        component: MDrecommendations
      },
      {
        path: 'MDretouches',
        name: 'MDretouches',
        component: MDretouches
      },
      {
        path: 'MDstudent',
        name: 'MDstudent',
        component: MDstudent
      },
      {
        path: 'MDchannel',
        name: 'MDchannel',
        component: MDchannel
      },
      {
        path: 'MDteacher',
        name: 'MDteacher',
        component: MDteacher
      },
      {
        path: 'MDsupport',
        name: 'MDsupport',
        component: MDsupport
      },
      {
        path: 'MDaccount',
        name: 'MDaccount',
        component: MDaccount
      },
      {
        path: 'MDviewcha',
        name: 'MDviewcha',
        component: MDviewcha
      }
    ]
  },
  {
    path: '/MS',
    name: 'MS',
    component: MSview,
    children: [
      {
        path: 'MSinterview',
        name: 'MSinterview',
        component: MSinterview
      },
      {
        path: 'MSmeet',
        name: 'MSmeet',
        component: MSmeet
      }
    ]
  },
  {
    path: '/M',
    name: 'M',
    component: Mview,
    children: [
      {
        path: 'Mjurisdict',
        name: 'Mjurisdict',
        component: Mjurisdict
      },
      {
        path: 'Maccount',
        name: 'Maccount',
        component: Maccount
      }
    ]
  },
  {
    path: '/AD',
    name: 'AD',
    component: ADview,
    children: [
      {
        path: 'ADschedule',
        name: 'ADschedule',
        component: ADschedule
      },
      {
        path: 'ADstudent',
        name: 'ADstudent',
        component: ADstudent
      },
      {
        path: 'ADpost',
        name: 'ADpost',
        component: ADpost
      },
      {
        path: 'ADrecommendations',
        name: 'ADrecommendations',
        component: ADrecommendations
      },
      {
        path: 'ADretouches',
        name: 'ADretouches',
        component: ADretouches
      },
      {
        path: 'ADassistant',
        name: 'ADassistant',
        component: ADassistant
      },
      {
        path: 'ADteacher',
        name: 'ADteacher',
        component: ADteacher
      }
    ]
  },
  {
    path: '/TopicPlus',
    name: 'TopicPlus',
    component: TopicPlusView,
    children: [
      {
        path: 'Thesis',
        name: 'Thesis',
        component: Thesis
      },
      {
        path: 'Research',
        name: 'Research',
        component: Research
      },
      {
        path: 'Status',
        name: 'Status',
        component: Status
      },
      {
        path: 'Research_pack',
        name: 'Research_pack',
        component: Research_pack
      },
      {
        path: 'Thesis_pack',
        name: 'Thesis_pack',
        component: Thesis_pack
      },
      {
        path: 'Epq',
        name: 'Epq',
        component: Epq
      },
      {
        path: 'Iap',
        name: 'Iap',
        component: Iap
      },
      {
        path: 'IapHigh',
        name: 'IapHigh',
        component: IapHigh
      },
      {
        path: 'Lite',
        name: 'Lite',
        component: Research
      },
      {
        path: 'Standard',
        name: 'Standard',
        component: Research_pack
      },
      {
        path: 'XDF',
        name: 'XDF',
        component: Research_pack
      },
      {
        path: 'XT',
        name: 'XT',
        component: Research_pack
      },
      {
        path: 'BYR',
        name: 'BYR',
        component: Research_pack
      },
      {
        path: 'EWA',
        name: 'EWA',
        component: Research_pack
      }
    ]
  },
  {
    path: '/Topic',
    name: 'Topic',
    component: TopicView,
    children: [
      {
        path: 'TopicT',
        name: 'TopicT',
        component: TopicT
      },
      {
        path: 'TopicR',
        name: 'TopicR',
        component: TopicR
      },
      {
        path: 'TopicRT',
        name: 'TopicRT',
        component: TopicRT
      },
      {
        path: 'TopicA',
        name: 'TopicA',
        component: TopicA
      },
      {
        path: 'TopicEpq',
        name: 'TopicEpq',
        component: TopicEpq
      },
      {
        path: 'TopicIap',
        name: 'TopicIap',
        component: TopicIap
      },
      {
        path: 'TopicIapHigh',
        name: 'TopicIapHigh',
        component: TopicIapHigh
      },
      {
        path: 'Lite',
        name: 'Lite',
        component: TopicR
      },
      {
        path: 'Standard',
        name: 'Standard',
        component: TopicRT
      },
      {
        path: 'BYR',
        name: 'BYR',
        component: TopicRT
      },
      {
        path: 'EWA',
        name: 'EWA',
        component: TopicRT
      },
      {
        path: 'XDF',
        name: 'XDF',
        component: TopicRT
      },
      {
        path: 'XT',
        name: 'XT',
        component: TopicRT
      }
    ]
  },
  {
    path: '/topic-mobile',
    name: 'topicMobile',
    component: topicMobile
  },
  {
    path: '/mobile/*',
    name: 'mobileView',
    component: mobileView
  },
  {
    path: '/uestc-glasgow',
    name: 'Topicglasgow',
    component: Topicglasgow,
    meta: {
      title: '论文选题检索'
    }
  },
  {
    path: '/preview-uestc-glasgow',
    name: 'previewglasgow',
    component: previewglasgow,
    meta: {
      title: '定制学术指导'
    }
  },
  {
    path: '/public/manual',
    name: 'manual_mp',
    component: manual_mp,
    meta: {
      title: '导师手册'
    }
  },
  {
    path: '/public/teacherManualReview',
    name: 'pdfReview_mp',
    component: pdfReview_mp,
    meta: {
      title: '导师手册'
    }
  },
  {
    path: '/E',
    name: 'E',
    component: Eview,
    children: [
      {
        path: 'Einterview',
        name: 'Einterview',
        component: Einterview
      },
      {
        path: 'Eschedule',
        name: 'Eschedule',
        component: Eschedule
      },
      {
        path: 'Estudent',
        name: 'Estudent',
        component: Estudent
      }
    ]
  },
  {
    path: '/Enterpise',
    name: 'Enterpise',
    component: ETview,
    children: [
      {
        path: 'EnterpiseR',
        name: 'EnterpiseR',
        component: ETR
      },
      {
        path: 'EnterpiseT',
        name: 'EnterpiseT',
        component: ETT
      },
      {
        path: 'EnterpiseRT',
        name: 'EnterpiseRT',
        component: ETRT
      },
      {
        path: 'EnterpiseA',
        name: 'EnterpiseA',
        component: ETA
      },
      {
        path: 'EnterpiseEpq',
        name: 'EnterpiseEpq',
        component: ETEpq
      },
      {
        path: 'EnterpiseIap',
        name: 'EnterpiseIap',
        component: ETIap
      },
      {
        path: 'EnterpiseIap2',
        name: 'EnterpiseIap2',
        component: ETIap
      },
      {
        path: 'EnterpiseIapHigh',
        name: 'EnterpiseIapHigh',
        component: ETIapHigh
      },
      {
        path: 'EnterpiseXT',
        name: 'EnterpiseXT',
        component: ETRT
      },
      {
        path: 'EnterpiseBYR',
        name: 'EnterpiseBYR',
        component: ETRT
      },
      {
        path: 'EnterpiseEWA',
        name: 'EnterpiseEWA',
        component: ETRT
      },
      {
        path: 'EnterpiseLite',
        name: 'EnterpiseLite',
        component: ETR
      },
      {
        path: 'EnterpiseStandard',
        name: 'EnterpiseStandard',
        component: ETRT
      },
      {
        path: 'EnterpiseXDF',
        name: 'EnterpiseXDF',
        component: ETRT
      }
    ]
  },
  {
    path: '/preview',
    name: 'preview',
    component: preview
  },
  {
    path: '/C',
    name: 'C',
    component: Clist
  },
  {
    path: '/public/projectinfo/:id',
    name: 'pubprojectinfo',
    component: projectInfo
  },
  {
    path: '/projectinfo',
    name: 'projectinfo',
    component: projectInfo
  },
  {
    path: '/V',
    name: 'Home',
    component: Home
  },
  {
    path: '/F',
    name: 'Finance',
    component: Finance
  },
  {
    name: '404',
    path: '/404',
    component: notFound
  },
  {
    path: '*', // 此处需特别注意至于最底部
    redirect: '/404'
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/login' || /^\/public\//.test(to.path)) {
    console.log(to.path === '/login' || /^\/public\//.test(to.path))
    next()
  } else if (to.path === '/course' || /^\/public\//.test(to.path)) {
    next()
  } else if (to.path === '/video' || /^\/public\//.test(to.path)) {
    next()
  } else {
    let token = localStorage.getItem('Authorization')
    if (token === null || token === '') {
      router.app.$options.store.commit('set_defaultRouter', to.path)
      next('/login')
    } else {
      router.app.$options.store.commit('set_defaultRouter', '')
      let role = to.path.split('/')[1]
      let roleData = {
        roleApi: '',
        role: ''
      }
      switch (role) {
        case 'A':
          roleData = {
            roleApi: 'planner',
            role: '规划师'
          }
          break
        case 'AD':
          roleData = {
            roleApi: 'adirector',
            role: '助教总监'
          }
          break
        case 'AS':
          roleData = {
            roleApi: 'assistant',
            role: '助教'
          }
          break
        case 'C':
          roleData = {
            roleApi: 'courier',
            role: '选会'
          }
          break
        case 'D':
          roleData = {
            roleApi: 'cmanager',
            role: '渠道经理'
          }
          break
        case 'F':
          roleData = {
            roleApi: 'finance',
            role: '财务'
          }
          break
        case 'J':
          roleData = {
            roleApi: 'eadmin',
            role: '教务'
          }
          break
        case 'K':
          roleData = {
            roleApi: 'amanager',
            role: '客户经理'
          }
          break
        case 'M':
          roleData = {
            roleApi: 'admin',
            role: '管理员'
          }
          break
        case 'MD':
          roleData = {
            roleApi: 'mdirector',
            role: '市场总监'
          }
          break
        case 'MS':
          roleData = {
            roleApi: 'meeting',
            role: '会务组'
          }
          break
        case 'S':
          roleData = {
            roleApi: 'student',
            role: '学生'
          }
          break
        case 'T':
          roleData = {
            roleApi: 'teacher',
            role: '导师'
          }
          break
        case 'TD':
          roleData = {
            roleApi: 'tdirector',
            role: '教学总监'
          }
          break
        case 'Topic':
          roleData = {
            roleApi: 'topic',
            role: '选题库'
          }
          break
        case 'TopicPlus':
          roleData = {
            roleApi: 'topic',
            role: '选题库管理员'
          }
          break
        case 'Enterpise':
          roleData = {
            roleApi: 'topic',
            role: '渠道选题库'
          }
          break
        case 'E':
          roleData = {
            roleApi: 'enterpise',
            role: '渠道'
          }
          break
      }
      router.app.$options.store.commit('set_roleApi', roleData)
      if (from.meta.keepAlive) {
        const $content = document.querySelector('.background')
        const scrollTop = $content ? $content.scrollTop : 0
        from.meta.scrollTop = scrollTop
      }
      next()
    }
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
})

export default router
