import { render, staticRenderFns } from "./manualList_mp.vue?vue&type=template&id=902fc56a&scoped=true"
import script from "./manualList_mp.vue?vue&type=script&lang=js"
export * from "./manualList_mp.vue?vue&type=script&lang=js"
import style0 from "./manualList_mp.vue?vue&type=style&index=0&id=902fc56a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "902fc56a",
  null
  
)

export default component.exports