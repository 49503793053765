<template>
  <div class="aside">
    <changerole></changerole>
    <el-menu router @select="handleSelect">
      <el-menu-item
        :class="$route.path == '/AS/ASschedule' ? 'is-active' : ''"
        index="/AS/ASschedule"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">学员<br />进度</div>
          <img class="iconmenu" src="../../assets/icon/学员进度@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/AS/ASpost' ? 'is-active' : ''"
        index="/AS/ASpost"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">投稿<br />进度</div>
          <el-badge :value="value.length" class="item" v-if="value.length > 0">
            <img class="iconmenu" src="../../assets/icon/稿件.png" />
          </el-badge>
          <img v-else class="iconmenu" src="../../assets/icon/稿件.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/AS/ASrecommendations' ? 'is-active' : ''"
        index="/AS/ASrecommendations"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">推荐信<br />进度</div>
          <img class="iconmenu" src="../../assets/icon/推荐信.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/AS/ASretouches' ? 'is-active' : ''"
        index="/AS/ASretouches"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">翻译/润色<br />进度</div>
          <img class="iconmenu" src="../../assets/icon/翻译_润色icon.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/AS/ASstudent' ? 'is-active' : ''"
        index="/AS/ASstudent"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">结项<br />学员</div>
          <img class="iconmenu" src="../../assets/icon/结项学员@3x.png" />
        </el-tooltip>
      </el-menu-item>
    </el-menu>
    <div class="back">
      <img
        src="../../assets/icon/退出.png"
        @click="logout"
        height="20px"
        width="20px"
      />
    </div>
  </div>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'
import changerole from '../pub/changerole'
export default {
  name: 'asnav',
  components: { changerole },
  data () {
    return {
      value: ''
    }
  },
  mounted () {
    axios.get(`/assistant/finalPapers`).then(response => {
      this.conference = response.data.projects
      this.has_next = response.data.has_next
      let convalue = []
      this.conference.map(item => {
        if (item.Contributed === false && item.meeting !== '') {
          convalue.push(item)
        }
      })
      this.value = convalue
    })
  },
  methods: {
    handleSelect (key, keyPath) {
      this.$router.replace({ path: `${keyPath}` })
    },
    logout () {
      MessageBox.confirm('是否注销？')
        .then(() => {
          axios.delete('/logout').then(() => {
            localStorage.removeItem('Authorization')
            this.$router.replace('/login')
          })
        })
        .catch(() => {})
    }
  }
}
</script>
<style scoped>
/deep/ .el-badge__content.is-fixed {
  top: 10px;
  right: 0px;
}
</style>
