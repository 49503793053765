<template>
  <div style="text-align: left; position: relative">
    <div style="display: flex; flex-direction: column">
      <div v-for="(item, index) in stages" :key="index" class="stage">
        <div class="circle activeStage" v-if="active === index">
          {{ index + 1 }}
        </div>
        <div
          class="circle"
          :class="active >= index ? 'active' : ''"
          v-else
        ></div>
        <div
          class="up"
          v-if="index !== 0"
          :class="active > index ? 'active' : ''"
        ></div>
        <div
          class="down"
          v-if="index !== stages.length - 1"
          :class="active >= index ? 'active' : ''"
        ></div>
        <div class="content" :style="active >= index ? 'background:#fff' : ''">
          <div
            style="
              display: flex;
              justify-content: space-between;
              padding-right: 2px;
            "
          >
            <div>{{ item.stage }}</div>
          </div>
          <div v-if="active >= index">
            <Schart
              :tableData.sync="tableData[index]"
              :editable="editable"
              v-if="tableData[index] && tableData[index].length > 0"
            >
            </Schart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Schart from './Schart'
export default {
  components: {
    Schart
  },
  data () {
    return {
      show: true
    }
  },
  props: {
    tableData: Array,
    editable: {
      default: false
    },
    active: Number,
    stages: Array
  }
}
</script>

<style lang="scss" scoped>
.stage {
  position: relative;
  color: #656d78;
  text-align: center;
  .content {
    margin-top: 15px;
    border-radius: 8px;
    padding: 20px 30px;
    background: #e9ecf1;
    margin-left: 25px;
    p {
      text-align: left;
      margin: 0;
      font-size: 16px;
    }
  }
}
.up {
  position: absolute;
  top: 0;
  height: 50%;
  border-left: 2px solid #c0c0c0;
  left: 10px;
}
.down {
  position: absolute;
  bottom: 0;
  height: 50%;
  border-left: 2px solid #c0c0c0;
  left: 10px;
}
.circle {
  width: 12px;
  height: 12px;
  background: #c0c0c0;
  border-radius: 12px;
  position: absolute;
  z-index: 10;
  left: 5px;
  top: 50%;
}

.active {
  &.down {
    border-left: 2px solid #a1d46a;
  }
  &.up {
    border-left: 2px solid #a1d46a;
  }
  &.circle {
    background: #a1d46a;
  }
}
.activeStage {
  width: 20px;
  height: 20px;
  background: #a1d46a;
  left: 0px;
  top: calc(50% - 5px);
  color: #fff;
  line-height: 20px;
}
.el-button {
  min-width: 36px;
  padding: 0;
  height: 24px;
  font-size: 12px;
}
.open {
  position: relative;
  .el-button {
    margin-left: 30px;
    position: relative;
    top: -28px;
  }
  .circle {
    top: 5px;
  }
  .nextS {
    position: relative;
    left: -36px;
    top: 5px;
    font-size: 12px;
    color: #656d78;
  }
}
.close {
  position: absolute;
  left: 30px;
  margin-top: 8px;
}
.el-progress {
  margin-top: 10px;
}
</style>
