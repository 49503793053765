<template>
  <el-container v-if="ready">
    <el-aside>
      <ETnav></ETnav>
    </el-aside>
    <router-view class="Topic" :key="$route.name"></router-view>
  </el-container>
</template>
<script>
/* eslint-disable no-console */
import axios from '../axios'
import { MessageBox } from 'element-ui'
import ETnav from './ETnav'

export default {
  name: 'ETview',
  components: {
    ETnav
  },
  data () {
    return {
      ready: false
    }
  },
  beforeCreate () {
    axios.get('/is_login/').then(response => {
      if (response.data.roles.indexOf(this.$store.state.role) > -1) {
        this.$store.commit('setLogin', response.data)
        axios.get('/get_enterpise').then(response => {
          let topics = response.data
          this.$store.commit('set_enterpise', topics)
          this.ready = true
          let path = ''
          switch (response.data.sort.split('')[0]) {
            case 'A':
              path = '/Enterpise/EnterpiseT'
              break
            case 'B':
              path = '/Enterpise/EnterpiseR'
              break
            case 'C':
              path = '/Enterpise/EnterpiseRT'
              break
            case 'D':
              path = '/Enterpise/EnterpiseA'
              break
            case 'E':
              path = '/Enterpise/EnterpiseEpq'
              break
            case 'F':
              path = '/Enterpise/EnterpiseIap'
              break
            case 'G':
              path = '/Enterpise/EnterpiseIap2'
              break
            case 'H':
              path = '/Enterpise/EnterpiseLite'
              break
            case 'I':
              path = '/Enterpise/EnterpiseStandard'
              break
            case 'J':
              path = '/Enterpise/EnterpiseXDF'
              break
            case 'K':
              path = '/Enterpise/EnterpiseIapHigh'
              break
            case 'L':
              path = '/Enterpise/EnterpiseXT'
              break
            case 'M':
              path = '/Enterpise/EnterpiseBYR'
              break
            case 'N':
              path = '/Enterpise/EnterpiseEWA'
              break
            default:
              path = '/Enterpise'
          }
          this.$router.push(path)
        })
      } else {
        MessageBox.alert('未登录，请重新登录')
        localStorage.removeItem('Authorization')
        this.$router.push('/login')
      }
    })
  }
}
</script>
