<template>
  <div>
    <el-dialog
      title="添加学员"
      :visible="searchDialog"
      :before-close="quituserfix"
      width="310px"
      :close-on-click-modal="false"
      destroy-on-close
      @close="errorPhoneNum = true"
    >
      <el-form :model="smes" @submit.native.prevent="searchByPhone" ref="smes">
        <el-form-item>
          <el-input
            v-model="smes.phone"
            placeholder="请输入手机"
            @input="handlePhoneNum"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="quituserfix()">取 消</el-button>
        <el-button
          type="primary"
          :disabled="errorPhoneNum"
          @click="searchByPhone"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      destroy-on-close
      :visible="active"
      width="60%"
      :close-on-click-modal="false"
      :before-close="closeDialog"
      style="max-height: 90vh"
      title="添加学员"
      custom-class="addStudentScroll"
    >
      <studentform ref="studentform" :smes="smes" :step="step" />
      <div slot="footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" v-if="step === 1" @click="bookpart">
          登 记
        </el-button>
        <el-button v-else type="primary" :disabled="pending" @click="pushfix">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import studentform from './studentform'
import projectform from './projectform'
import { MessageBox } from 'element-ui'

import axios from '../../axios'
export default {
  name: 'newStudent',
  data () {
    return {
      first: true,
      active: false,
      smes: {
        name: '', // 名字
        username: '', // 用户名
        school: '', // 学校
        phone: '', // 手机号
        email: '', // 邮箱
        misc: '', // 备注
        direction: '', // 学生申请方向
        wechat: '', // 微信
        country: '', // 申请国家
        identification: '', // 身份证号码
        address: '', // 联系地址
        planedSpecialty: '', // 未来打算就读方向
        schoolStartTime: '', // 下一个学历的入学时间 20220202
        educationMes: [], // 学员教育信息
        activityMes: [], // 学员活动信息
        scienceMes: [], // 学员学术信息
        computerMes: '', // 计算机水平
        language: {
          // 语言能力
          four: '',
          six: '',
          toefl: '',
          ieltes: '',
          gre: '',
          gmat: '',
          other: ''
        },
        project_data: {
          channel_type: 'inst',
          sponser: '', // 支持
          amanager: '',
          cmanager: '',
          channel: ''
        }
      },
      smes_temp: '',
      searchDialog: false,
      pending: false,
      errorPhoneNum: true,
      roleApi: '',
      step: 0
    }
  },
  components: {
    studentform,
    projectform
  },
  created () {
    this.roleApi = this.$store.state.roleApi
    this.smes.project_data.sponser = this.$store.state.userid
  },
  methods: {
    bookpart () {
      this.step = 2
    },
    handlePhoneNum () {
      let reg = /^[0-9]*$/
      this.errorPhoneNum = !reg.test(this.smes.phone)
    },
    searchByPhone () {
      axios
        .get(`/${this.roleApi}/student/exists/`, {
          params: {
            phone: this.smes.phone
          }
        })
        .then(res => {
          if (res.data.id) {
            this.step = 1
            MessageBox.confirm(
              `学生:${res.data.name || res.data.phone} 已存在，是否查看该学生`,
              {
                cancelButtonText: '返回',
                confirmButtonText: '查看'
              }
            ).then(() => {
              this.openDialog2(res.data, this.smes.phone)
              this.searchDialog = false
            })
          } else {
            this.openDialog2(res.data, this.smes.phone)
            this.searchDialog = false
          }
        })
    },
    openDialog () {
      this.searchDialog = true
    },
    openDialog2 (smes, phone) {
      this.smes = {
        ...this.smes,
        ...smes,
        phone
      }
      if (!this.smes.name) {
        this.smes.username = phone
      }
      this.smes_temp = JSON.stringify(this.smes)
      this.active = true
    },
    closeDialog () {
      if (this.smes_temp !== JSON.stringify(this.smes)) {
        MessageBox.confirm('是否放弃更改内容').then(() => {
          this.active = false
          this.smes = { ...this.$options.data().smes }
        })
      } else {
        this.active = false
        this.smes = { ...this.$options.data().smes }
      }
      this.step = 0
    },
    quituserfix () {
      this.searchDialog = false
      this.smes.phone = ''
    },
    pushfix () {
      let that = this
      this.$refs.studentform.check(function (valid) {
        if (valid) {
          MessageBox.confirm('是否添加该学员').then(() => {
            that.pending = true
            if (that.step === 0) {
              axios
                .post(`/${that.roleApi}/student/`, that.smes)
                .then(response => {
                  if (response.status === 200) {
                    that.$message({
                      showClose: true,
                      message: `添加成功`,
                      type: 'success'
                    })
                    setTimeout(() => {
                      window.location.reload()
                    }, 1000)
                  }
                })
                .catch(error => {
                  that.pending = false
                  console.log(error)
                })
            } else {
              axios
                .post(`/${that.roleApi}/student/create_part`, that.smes)
                .then(response => {
                  if (response.status === 200) {
                    that.$message({
                      showClose: true,
                      message: `添加成功`,
                      type: 'success'
                    })
                    setTimeout(() => {
                      window.location.reload()
                    }, 1000)
                  }
                })
                .catch(error => {
                  that.pending = false
                  console.log(error)
                })
            }
          })
        } else {
          // console.log(that.$refs)
          const base = that.$refs.studentform.$children[0].$el.getBoundingClientRect()
            .top
          for (const validElement of that.$refs.studentform.$children[0]
            .fields) {
            // console.log(validElement.$el._prevClass)
            const isError =
              validElement.$el._prevClass.indexOf('is-error') !== -1
            const isSuccess =
              validElement.$el._prevClass.indexOf('is-success') !== -1
            const isRequire =
              validElement.$el._prevClass.indexOf('is-require') !== -1
            if ((isRequire && !isError && !isSuccess) || isError) {
              // console.log(base, validElement.$el.getBoundingClientRect().top, document.getElementsByClassName('dialogScroll')[0].scrollTop)
              document.getElementsByClassName('addStudentScroll')[0].scrollTop =
                validElement.$el.getBoundingClientRect().top - base
              // document.getElementsByClassName('dialogScroll')[0].scrollTop = 50
              break
            }
          }
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
