<template>
  <el-main class="main">
    <div class="flex">
      <div class="listMD" ref="element">
        <div class="search flex">
          <div class="seaicon">
            <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
          </div>
          <el-input v-model="stutitle" @input="search" class="seasubfix"></el-input>
          <el-select v-model="role" popper-class="rolelist" class="changerole" @change="rolechange">
            <el-option v-for="item in options" :key="item" :value="item"></el-option>
          </el-select>
        </div>
        <el-row>
          <el-col :span="24" class="colorGroup flex">
            <el-tooltip placement="top" content="全部学生" effect="light" popper-class="atooltip">
              <div class="first">
                <img :class="{ firstyuan: type !== '' }" @click="chooseColor1" src="../../assets/icon/mixcolor.png"
                  height="15" width="15" />
              </div>
            </el-tooltip>
            <el-tooltip placement="top" content="正常辅导中" effect="light" popper-class="atooltip">
              <div class="yuan color3" :class="{ selectcolor: type === '1' }" @click="day_filter(1)"></div>
            </el-tooltip>
            <el-tooltip placement="top" content="Deadline已超7天" effect="light" popper-class="atooltip">
              <div class="yuan color4" :class="{ selectcolor: type === '8' }" @click="day_filter(8)"></div>
            </el-tooltip>
            <el-tooltip placement="top" content="Deadline已超15天" effect="light" popper-class="atooltip">
              <div class="yuan color5" :class="{ selectcolor: type === '16' }" @click="day_filter(16)"></div>
            </el-tooltip>
            <div class="iconDU" :class="showSports ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
              @click="showSports = !showSports" style="color: #656D78;margin-top: 11px;font-size: 2px"></div>
            <el-tooltip placement="top" content="Deadline已超30天" effect="light" popper-class="atooltip">
              <div class="yuan color6" v-show="showSports" :class="{ selectcolor: type === '31' }"
                @click="day_filter(31)"></div>
            </el-tooltip>
            <el-tooltip placement="top" content="Deadline已超60天" effect="light" popper-class="atooltip">
              <div class="yuan color7" v-show="showSports" :class="{ selectcolor: type === '61' }"
                @click="day_filter(61)"></div>
            </el-tooltip>
            <el-tooltip placement="top" content="未辅导学生" effect="light" popper-class="atooltip">
              <div class="yuan color2" :class="{ selectcolor: type === null }" @click="chooseColor2" v-show="showSports">
              </div>
            </el-tooltip>
          </el-col>
        </el-row>
        <div class="jstudent" @scroll="getMore()" ref="list">
          <div class="preWrapper">
            <div class="pre" @click="preturn">
              <div class="pretext">未分配助教</div>
              <div :class="{ turn: preturn1 }" class="preimg  el-icon-arrow-down"></div>
            </div>
            <div v-if="preturn1">
              <div class="jstunum" @click="getstu1(item.project_id)"
                :class="{ changeStyle: changeSelectStyle === item.project_id }" v-for="(item, index) in notlist"
                :key="index">
                <div :class="{ bold: item.important }" class="studentname">
                  <span>{{ item.id }}.{{ item.name }}</span>
                  <img src="../../assets/icon/组合项目.png" class="zhimg" alt="" v-if="item.ispacked === true" />
                </div>
                <div>
                  <div class="colortag">
                    <div :class="getClass(item.days, item.started, item.posted)"></div>
                    <div v-if="item.warning !== false && item.posted === false" class="remind"></div>
                  </div>
                </div>
              </div>
              <div style="font-size: 16px;color: #656d78;" v-if="notlist.length == 0">
                <span v-if="get_not">未搜索到相关信息</span>
                <span v-else>
                  <i class="eliwise-loading"></i> 加载中，请稍后</span>
              </div>
            </div>
          </div>
          <div class="preWrapper">
            <div class="pre" @click="endturn">
              <div class="pretext">已分配助教</div>
              <div :class="{ turn: endturn3 }" class="preimg  el-icon-arrow-down">
                <!--              <img src="../../assets/icon/箭头.png" height="12" />-->
              </div>
            </div>
            <div v-if="endturn3">
              <div @click="getstu1(item.project_id)" :class="{ changeStyle: changeSelectStyle === item.project_id }"
                class="jstunum" v-for="(item, index) in haslist" :key="index">
                <div :class="{ bold: item.important }" class="studentname">
                  <span>{{ item.id }}.{{ item.name }}</span>
                  <img src="../../assets/icon/组合项目.png" class="zhimg" alt="" v-if="item.ispacked === true" />
                </div>
                <div class="colortag">
                  <div :class="getClass(item.days, item.started, item.posted)"></div>
                  <div v-if="item.warning !== false && item.posted === false" class="remind"></div>
                </div>
                <div></div>
              </div>
              <div style="font-size: 16px;color: #656d78;">
                <span v-if="has_next">
                  <i class="eliwise-loading"></i> 加载中，请稍后
                </span>
                <span v-else-if="haslist.length == 0">未搜索到相关信息</span>
                <span v-else>没有更多信息了</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="neirong">
        <div class="jtitle">
          <h2 class="jtext">辅导进度</h2>
        </div>
        <projectInfo :project="activeProject">
          <div slot="roles" class="rolesIcon">
            <img src="../../assets/roles/icon/导师.png" />
            <span>{{ activeProject.teacher || '待分配' }}</span>
            <img src="../../assets/roles/icon/助教.png" />
            <span>{{ activeProject.assistant || '待分配' }}</span>
            <img src="../../assets/roles/icon/规划师.png" />
            <span>{{ activeProject.planner }}</span>
            <img src="../../assets/roles/icon/客户经理.png" />
            <span>{{ activeProject.amanagerName || '无' }}</span>
          </div>
          <div slot="buttons">
            <informationButton :pid="pid"> </informationButton>
            <file-button :pid="pid"></file-button>
            <el-button type="primary" @click="openAssistDialog">
              {{ activeProject.assistant === '' ? '分配助教' : '修改助教' }}
            </el-button>
            <status-button ref="editStatus" @refresh="refresh" :pid="pid"></status-button>
            <remark-button ref="editRemark" @refresh="refresh" :pid="pid"></remark-button>
            <el-button type="primary" @click="uploadOpen">
              上传文件
            </el-button>
            <el-button type="primary" @click="mail = true" v-if="$store.state.baseType[project_type] === '学术指导' ||
              $store.state.baseType[project_type] === '科研' || project_type === 'RP+RL' || $store.state.baseType[project_type] === 'IAP'
              ">
              推荐信邮件
            </el-button>
          </div>
          <div slot="stages">
            <p style="font-size: 14px;color: #989898;">
              置顶备注：{{ activeProject.stickyMisc || '无' }}
            </p>
            <stages @listentoChart="showMsgchart" :tableData="tableData" :editable="true" :project="activeProject"
              :interview="interview" :stageCompleteRole="true"></stages>
          </div>
        </projectInfo>
      </div>
    </div>
    <assistDialog api="adirector" ref="assistDialog" />
    <uploadForm api="adirector" ref="uploadForm" :closeUploadForm.sync="uploadFormActive" v-if="uploadFormActive" />
    <recommendationMail v-if="mail" />
    <suggestRead :project="activeProject"></suggestRead>
  </el-main>
</template>

<script>
import axios from '../axios'
import projectInfo from '../pub/projectInfo'
import stages from '../pub/stages'
import exportImg from '../pub/exportImg'
import fileButton from '../pub/buttons/fileButton'
import informationButton from '../pub/buttons/informationButton'
import statusButton from '../pub/buttons/statusButton'
import remarkButton from '../pub/buttons/remarkButton'
import suggestRead from '../pub/suggestRead'
import assistDialog from '../pub/assistDialog'
import uploadForm from '../pub/uploadForm'
import recommendationMail from '../pub/mail/recommendationMail'
export default {
  name: 'ADshedule',
  components: {
    projectInfo,
    stages,
    exportImg,
    fileButton,
    informationButton,
    statusButton,
    remarkButton,
    suggestRead,
    uploadForm,
    recommendationMail,
    assistDialog
  },
  data() {
    return {
      beizhuTitle: '',
      smes: {
        name: '',
        school: '',
        cellphone: '',
        email: '',
        direction: '',
        sex: ''
      },
      ruleform: {
        status: '',
        beizhu: ''
      },
      tableData: [],
      zhuangTitle: '',
      zhuangDialog: false,
      remarkDialog: false,
      interview: {},
      options: ['学员', '导师', '助教', '项目', '规划师', '方向'],
      role: '学员',
      stutitle: '',
      s_name: '',
      t_name: '',
      a_name: '',
      as_name: '',
      pro_type: '',
      dir: '',
      type: '',
      started: true,
      changeSelectStyle: '',
      preturn1: true,
      endturn3: true,
      notlist: [],
      haslist: [],
      page: 1,
      has_next: true,
      loading: true,
      get_not: false,
      project_type: '',
      teacher: '',
      pid: 0,
      status_id: '',
      remark_id: '',
      activeProject: {},
      beizhuDialog: false,
      uploadFormActive: false,
      mail: false,
      showSports: false
    }
  },
  created() {
    this.getnotassistant()
    this.gethasassistant()
  },
  methods: {
    uploadOpen() {
      this.uploadFormActive = true
      this.openUploadForm()
    },
    openUploadForm() {
      this.$nextTick(() => {
        let info = {
          project_type: this.project_type,
          tname: this.teacher.name,
          sname: this.smes.name,
          uploadTitle: '上传文件'
        }
        this.$refs.uploadForm.openDialog(this.pid, info)
      })
    },

    endturn() {
      this.endturn3 = !this.endturn3
    },
    preturn() {
      this.preturn1 = !this.preturn1
    },
    search: _.debounce(function () {
      this.has_next = true
      this.get_not = false
      this.getnotassistant()
      this.gethasassistant()
      this.notlist = []
      this.haslist = []
    }, 1000),
    async getnotassistant() {
      if (this.role === '学员') {
        this.s_name = this.stutitle
      } else if (this.role === '导师') {
        this.t_name = this.stutitle
      } else if (this.role === '助教') {
        this.as_name = this.stutitle
      } else if (this.role === '项目') {
        this.pro_type = this.stutitle
      } else if (this.role === '规划师') {
        this.a_name = this.stutitle
      } else if (this.role === '方向') {
        this.dir = this.stutitle
      } else {
        this.s_name = this.stutitle
        this.isIm = true
      }

      this.loading = true
      await axios
        .get(`adirector/students/not_finished/not_assistant`, {
          params: {
            s_name: this.s_name,
            t_name: this.t_name,
            as_name: this.as_name,
            pro_type: this.pro_type,
            a_name: this.a_name,
            direction: this.dir,
            days: this.type,
            important: this.isIm,
            started: this.started
          }
        })
        .then(response => {
          this.loading = false
          this.notlist = response.data.students
          this.get_not = true
          if (this.notlist.length !== 0) {
            this.getfirst(this.notlist[0].project_id)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async gethasassistant() {
      if (this.role === '学员') {
        this.s_name = this.stutitle
      } else if (this.role === '导师') {
        this.t_name = this.stutitle
      } else if (this.role === '助教') {
        this.as_name = this.stutitle
      } else if (this.role === '项目') {
        this.pro_type = this.stutitle
      } else if (this.role === '规划师') {
        this.a_name = this.stutitle
      } else if (this.role === '方向') {
        this.dir = this.stutitle
      } else {
        this.s_name = this.stutitle
        this.isIm = true
      }
      this.loading = true
      await axios
        .get(`adirector/students/not_finished/has_assistant`, {
          params: {
            s_name: this.s_name,
            t_name: this.t_name,
            as_name: this.as_name,
            pro_type: this.pro_type,
            a_name: this.a_name,
            direction: this.dir,
            days: this.type,
            important: this.isIm,
            started: this.started
          }
        })
        .then(response => {
          this.loading = false
          this.haslist = response.data.students
          this.has_next = response.data.has_next
          if (this.haslist.length !== 0 && this.notlist.length === 0) {
            this.getfirst(this.haslist[0].project_id)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    getMore() {
      let dom = this.$refs.list
      let scrollTop = dom.scrollTop
      let windowHeight = dom.clientHeight
      let scrollHeight = dom.scrollHeight
      if (
        scrollTop + windowHeight >= scrollHeight - 1 &&
        this.has_next &&
        !this.loading
      ) {
        this.loading = true
        this.page++
        axios
          .get(`adirector/students/not_finished/has_assistant`, {
            params: {
              s_name: this.s_name,
              t_name: this.t_name,
              as_name: this.as_name,
              pro_type: this.pro_type,
              a_name: this.a_name,
              direction: this.dir,
              days: this.type,
              important: this.isIm,
              started: this.started,
              page: this.page
            }
          })
          .then(response => {
            this.haslist = this.haslist.concat(response.data.students)
            this.has_next = response.data.has_next
            this.loading = false
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    getfirst(pid) {
      this.changeSelectStyle = pid
      axios
        .get(`/adirector/project/${pid}`)
        .then(response => {
          this.smes = response.data.student
          this.teacher = response.data.teacher
          this.project_type = response.data.project.project_type
          this.pid = response.data.project.id
          this.tableData = response.data.stages
          this.interview = response.data.interview[0]
          this.activeProject = {
            ...response.data.project,
            salesman: response.data.sales,
            sponser: response.data.support,
            amanager: response.data.amanager,
            cmanager: response.data.cmanager
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    getstu1(pid = false) {
      let id = 0
      if (pid) {
        id = pid
        this.changeSelectStyle = id
      } else {
        id = this.pid
      }
      axios
        .get(`/${this.$store.state.roleApi}/project/${id}`)
        .then(response => {
          this.smes = response.data.student
          this.teacher = response.data.teacher
          this.project_type = response.data.project.project_type
          this.pid = response.data.project.id
          this.interview = response.data.interview[0]
          this.tableData = response.data.stages
          this.activeProject = {
            ...response.data.project,
            salesman: response.data.sales,
            sponser: response.data.support,
            amanager: response.data.amanager,
            cmanager: response.data.cmanager
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    rolechange() {
      this.stutitle = ''
      this.s_name = ''
      this.c_name = ''
      this.t_name = ''
      this.pro_type = ''
      this.as_name = ''
      this.a_name = ''
      this.dir = ''
      this.type = ''
      this.notlist = []
      this.haslist = []
      if (this.role === '标星') {
        this.isIm = true
      } else {
        this.isIm = false
      }
      this.loading = true
      axios
        .get(`adirector/students/not_finished/not_assistant`, {
          params: {
            s_name: this.s_name,
            c_name: this.c_name,
            t_name: this.t_name,
            as_name: this.as_name,
            a_name: this.a_name,
            pro_type: this.a_name,
            direction: this.dir,
            days: this.type,
            important: this.isIm
          }
        })
        .then(response => {
          this.notlist = response.data.students
          this.loading = false
          if (this.notlist.length !== 0) {
            this.getfirst(this.notlist[0].project_id)
          }
        })
      axios
        .get(`adirector/students/not_finished/has_assistant`, {
          params: {
            s_name: this.s_name,
            c_name: this.c_name,
            t_name: this.t_name,
            as_name: this.as_name,
            a_name: this.a_name,
            pro_type: this.a_name,
            direction: this.dir,
            days: this.type,
            important: this.isIm
          }
        })
        .then(response => {
          this.haslist = response.data.students
          this.has_next = response.data.has_next
          this.loading = false
        })
    },
    chooseColor1() {
      this.type = ''
      this.getnotassistant()
      this.gethasassistant()
    },
    async chooseColor2() {
      this.type = null
      this.started = false
      this.getnotassistant()
      this.gethasassistant()
    },
    day_filter(days) {
      this.type = days.toString()
      this.started = true
      this.getnotassistant()
      this.gethasassistant()
    },
    getClass(days, started, posted) {
      if (posted) {
        return 'posted'
      } else if (days > 60) {
        return 'day60'
      } else if (days > 30 && days <= 60) {
        return 'day30'
      } else if (days > 15 && days <= 30) {
        return 'day15'
      } else if (days > 7 && days <= 15) {
        return 'day7'
      } else if (days > 0 && days <= 7) {
        return 'day0'
      } else if (days === 0 && started === true) {
        return 'day0'
      } else if (days === 0 && started === false) {
        return 'dayfull'
      }
    },
    openAssistDialog() {
      this.$refs.assistDialog.open(this.pid)
    },

    showMsgchart(data) {
      switch (data.title) {
        case '修改备注（导师不可见）':
          this.$refs.editRemark.edit(data)
          break
        case '修改状况（导师可见）':
          this.$refs.editStatus.edit(data)
          break
        case 'refresh':
          this.refresh()
          break
      }
    },
    refresh() {
      this.getstu1()
    }
  }
}
</script>

<style lang="scss" scoped>
.colorGroup {
  justify-content: left;
  flex-wrap: wrap;
  height: auto;
  //overflow: hidden;
  position: relative;
  padding: 0 4px;

  .first {
    height: 2px;
    cursor: pointer;
    margin-right: 16%;
    margin-top: 7px;
  }

  .iconDU {
    position: absolute;
    right: 4px;
  }

  .yuan {
    width: 12px;
    height: 12px;
    //margin-top: 2px;
    margin: 10px 16% 0 0;
    border-radius: 50%;
    cursor: pointer;
  }

  .color2 {
    background-color: #c0c0c0;
  }

  .color3 {
    background-color: #a0d469;
  }

  .color4 {
    background-color: #ffce54;
  }

  .color5 {
    background-color: #fc6e51;
  }

  .color6 {
    background-color: #ac92ec;
    margin-left: calc(16% + 15px);
  }

  .color7 {
    background-color: #656d78;
  }
}

//.yuan {
//  width: 12px;
//  height: 12px;
//  margin-top: 2px;
//  border-radius: 50%;
//  cursor: pointer;
//}
//.selectcolor {
//  width: 15px !important;
//  height: 15px !important;
//  margin-top: 0px !important;
//}
//.color2 {
//  background-color: #c0c0c0;
//}
//.color3 {
//  background-color: #a0d469;
//}
//.color4 {
//  background-color: #ffce54;
//}
//.color5 {
//  background-color: #fc6e51;
//}
//.color6 {
//  background-color: #ac92ec;
//}
//.color7 {
//  background-color: #656d78;
//}
//.flex {
//  display: flex;
//  flex-wrap: wrap;
//}
.selectcolor {
  width: 15px !important;
  height: 15px !important;
  margin-top: 8px !important;
}

.remind {
  border-left: solid 1px white;
  border-bottom: solid 1px white;
  height: 5.5px;
  width: 5.5px;
  position: relative;
  bottom: 12px;
  left: 6px;
}

.dayfull {
  background-color: #c0c0c0;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}

.day0 {
  background-color: #a0d469;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}

.day7 {
  background-color: #ffce54;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}

.day15 {
  background-color: #fc6e51;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}

.day30 {
  background-color: #ac92ec;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}

.day60 {
  background-color: #656d78;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}

.posted {
  background-image: url(../../assets/icon/投稿中icon.png);
  background-size: cover;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}

.days {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: 0px;
}
</style>
