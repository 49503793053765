<template>
  <el-main class="main">
    <div>
      <div class="jtitle">
        <h2 class="jtext">学生账户</h2>
      </div>
      <div class="table">
        <div class="search flex">
          <div class="seaicon">
            <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
          </div>
          <el-input v-model="accmessage" @input="search" class="seasubfix">
          </el-input>
        </div>
        <div>
          <el-table :data="tableData1" ref="tableScroll1" :height="tableHeight">
            <el-table-column key="2" prop="id" label="编号" align="center">
            </el-table-column>
            <el-table-column key="3" prop="name" label="姓名" align="center">
            </el-table-column>
            <el-table-column key="4" prop="sex" label="性别" align="center">
            </el-table-column>
            <el-table-column key="5" prop="school" label="学校" align="center">
            </el-table-column>
            <el-table-column
              key="6"
              prop="direction"
              label="专业方向"
              align="center"
            >
            </el-table-column>
            <el-table-column key="7" prop="phone" label="手机" align="center">
            </el-table-column>
            <el-table-column key="8" prop="wechat" label="微信" align="center">
            </el-table-column>
            <el-table-column
              key="9"
              prop="cmanager.name"
              label="渠道经理"
              align="center"
            >
            </el-table-column>
            <el-table-column
              key="10"
              prop="amanager.name"
              label="客户经理"
              align="center"
            >
            </el-table-column>
            <el-table-column
              key="11"
              prop="planner"
              label="规划师"
              align="center"
            >
            </el-table-column>
            <div slot="append" style="text-align: center; font-size: 16px">
              <span v-if="has_next">
                <i class="eliwise-loading"></i>加载中请稍后...
              </span>
              <i v-else>--没有数据了--</i>
            </div>
          </el-table>
        </div>
      </div>
    </div>
  </el-main>
</template>
<script>
/* eslint-disable no-console */
import axios from '../axios'
import { MessageBox } from 'element-ui'
import _ from 'lodash'
import changerole from '../pub/changerole.vue'

export default {
  name: 'MDaccount',
  data () {
    return {
      chooseTypefix: null,
      tableHeight: window.innerHeight - 150,
      tableData1: [],
      sales: [],
      accmessage: '',
      pagenumber: 1,
      has_next: true
    }
  },
  created () {
    axios
      .get('/mdirector/students/', {
        params: {
          name: this.accmessage,
          page: this.pagenumber
        }
      })
      .then(response => {
        this.tableData1 = response.data.students
        if (response.data.has_previous) {
          this.tableData1 = this.tableData1.concat(response.data.students)
        }
        this.has_next = response.data.has_next
      })
      .catch(error => {
        console.log(error)
      })
  },
  mounted () {
    const that = this
    ;(window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 150
      })()
    }),
      this.dom2()
  },
  methods: {
    search () {
      this.getS()
    },
    getS: _.debounce(function () {
      this.pagenumber = 1
      this.dom.scrollTop = 0
      this.getStudent()
    }, 500),
    getStudent () {
      axios
        .get('/mdirector/students/', {
          params: {
            name: this.accmessage,
            page: this.pagenumber
          }
        })
        .then(response => {
          this.tableData1 = response.data.students
          this.has_next = response.data.has_next
        })
        .catch(error => {
          console.log(error)
        })
    },
    a () {
      let scrollTop = this.dom.scrollTop
      let windowHeight = this.dom.clientHeight || this.dom.clientHeight
      let scrollHeight = this.dom.scrollHeight || this.dom.scrollHeight
      if (scrollTop + windowHeight >= scrollHeight - 1 && this.has_next) {
        this.pagenumber = this.pagenumber + 1
        axios
          .get('/mdirector/students/', {
            params: {
              name: this.accmessage,
              page: this.pagenumber
            }
          })
          .then(response => {
            if (response.data.has_previous) {
              this.tableData1 = this.tableData1.concat(response.data.students)
            }
            this.has_next = response.data.has_next
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    dom2 () {
      this.dom = this.$refs.tableScroll1.bodyWrapper
      this.dom.addEventListener('scroll', this.a)
    }
  },
  components: {
    changerole
  }
}
</script>
