<template>
  <div>
    <remark-icon @click.native="add"> </remark-icon>
    <el-dialog
      :title="remarkTitle"
      :visible.sync="remarkDialog"
      width="60%"
      :close-on-click-modal="false"
    >
      <div style="position:relative">
        <p style="margin-bottom:10px" v-if="!first">
          备注是指公司其他部门同事需要了解的学生的特殊情况或者学生的进度信息，
          如学生正在学习先导课内容。添加备注后，学生状态不会暂停。<br />
          备注信息导师和学生均不可见
        </p>
        <el-checkbox
          v-model="first"
          :disabled="remarkTitle !== '添加备注（导师不可见）'"
          style="position:absolute;right: 0;bottom: 0;"
          >学生辅导感受</el-checkbox
        >
      </div>
      <el-form :model="remarkForm" ref="remarkForm">
        <el-form-item prop="remark">
          <el-input
            type="textarea"
            class="input"
            maxlength="500"
            show-word-limit
            :autosize="{ minRows: 3, maxRows: 3 }"
            v-model="remarkForm.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="confirm" :disabled="pending">
          确 认
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../../axios'
import remarkIcon from '../../../assets/icon-font/remarkIcon'
import { MessageBox } from 'element-ui'
export default {
  components: { remarkIcon },
  data () {
    return {
      remarkDialog: false,
      remarkForm: {
        remark: ''
      },
      pending: false,
      remarkTitle: '添加备注（导师不可见）',
      first: false
    }
  },
  props: {
    pid: Number
  },
  methods: {
    cancel () {
      this.remarkDialog = false
      this.remarkForm.remark = ''
      this.first = false
      this.pending = false
    },
    confirm () {
      this.$refs.remarkForm.validate(valid => {
        if (valid) {
          if (this.remarkTitle === '添加备注（导师不可见）') {
            MessageBox.confirm('是否添加备注').then(() => {
              this.pending = true
              axios
                .post(
                  `/${this.$store.state.roleApi}/project/${this.pid}/remarks`,
                {
                  remark: this.remarkForm.remark,
                  first: this.first
                }
                )
                .then(response => {
                  if (response.status === 201) {
                    this.$message({
                      showClose: true,
                      message: '添加成功',
                      type: 'success'
                    })
                    this.$emit('refresh', null)
                    this.cancel()
                  }
                })
                .catch(error => {
                  console.log(error)
                })
            })
          } else {
            MessageBox.confirm('是否修改备注').then(() => {
              this.pending = true
              axios
                .put(`/mdirector/remark/${this.remark_id}`, {
                  remark: this.remarkForm.remark
                })
                .then(res => {
                  if (res.status === 200) {
                    this.$message({
                      showClose: true,
                      message: '修改成功',
                      type: 'success'
                    })
                    this.$emit('refresh', null)
                    this.cancel()
                  }
                })
            })
          }
        }
      })
    },
    add () {
      this.remarkDialog = true
      this.remarkTitle = '添加备注（导师不可见）'
    },
    edit (data) {
      this.remarkDialog = true
      this.remarkTitle = data.title
      this.remarkForm.remark = data.remark
      this.remark_id = data.remark_id
      this.first = data.first
    }
  }
}
</script>
