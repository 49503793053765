<template>
  <ElDialog title="辅导记录" :visible.sync="active" width="80%">
    <studentInfo :nameOnly="false" :smes="student" />
    <stages
      :tableData="stages"
      :project="project"
      :interview="interviews"
    ></stages>
    <div slot="footer">
      <el-button class="true button" type="primary" @click="closeDialog">
        返回
      </el-button>
    </div>
  </ElDialog>
</template>
<script>
import axios from '../axios'
import stages from '@/components/pub/stages'
import studentInfo from '@/components/pub/studentInfo.vue'

export default {
  name: 'RecordDialog',
  data () {
    return {
      active: false,
      project: {},
      stages: [],
      student: {},
      interview: []
    }
  },
  components: {
    stages,
    studentInfo
  },
  computed: {
    interviews () {
      return this.interview && this.interview[0]
    }
  },
  methods: {
    openDialog (projectId) {
      axios.get(`/courier/project/${projectId}/`).then(res => {
        Object.assign(this.$data, res.data)
      })
      this.active = true
    },
    closeDialog () {
      this.active = false
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body {
  overflow: scroll;
  max-height: 73vh;
}
</style>
