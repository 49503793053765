import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../components/axios'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    // 存储token
    Authorization: localStorage.getItem('Authorization') || '',
    username: '',
    userid: '',
    roles: [],
    sales: [],
    unfinished: 0,
    topics: {
      research: '',
      thesis: '',
      rt: '',
      academic: '',
      epq: '',
      iap: '',
      iap2: '',
      proHigh: '',
      xt: '',
      byr: '',
      ewa: '',
      lite: '',
      standard: '',
      xdf: '',
      sort: ''
    },
    roleApi: '',
    role: '',
    defaultRouter: '',
    baseType: {
      '学习型科研-4次': '科研',
      '论文A': '论文',
      '论文B': '论文',
      '远程实践型科研-8次': '科研',
      Lite: '科研',
      Standard: '学术指导',
      'Pro': 'IAP',
      'Pro-5': 'IAP',
      'Pro-10': 'IAP',
      'Research Proposal': 'RP',
      'RP+RL': 'RP',
      EPQ: 'EPQ',
      '会务组论文-刊': '会务组论文',
      '会务组论文-会': '会务组论文',
      'EI/CPCI国际会议论文': '论文',
      国家级论文: '论文',
      国际普通期刊论文: '论文',
      IPQ: 'EPQ',
      学习型科研: '科研',
      远程实践型科研: '科研',
      实地实践型科研: '科研',
      小组科研: '其他',
      海外科研: '其他',
      EI会议论文: '论文',
      CPCI会议论文: '论文',
      'EI/CPCI会议论文': '论文',
      协作型论文: '论文',
      'SCI/SSCI/EI期刊论文': '论文',
      IAP: 'IAP',
      学术指导: '学术指导',
      EI学术指导: '学术指导',
      CPCI学术指导: '学术指导',
      'EI/CPCI学术指导': '学术指导',
      会务组论文: '会务组论文',
      精品会务组论文: '会务组论文',
      实习实训: '科研',
      专利: '科研'
    }
  },
  actions: {
    updateUnfinished ({ commit }) {
      axios.get(`/sponser/contribute/count`).then(({ data }) => {
        commit('updateUnfinished', data.count)
      })
    }
  },
  mutations: {
    // 修改token，并将token存入localStorage
    changeLogin (state, user) {
      state.Authorization = user.Authorization
      localStorage.setItem('Authorization', user.Authorization)
    },
    setLogin (state, res) {
      state.username = res.name || 1
      state.userid = res.id || -1
      state.roles = res.roles
    },
    updateUnfinished (state, number) {
      state.unfinished = number
    },
    set_enterpise (state, res) {
      state.topics.research = res.research
      state.topics.thesis = res.thesis
      state.topics.rt = res.rt
      state.topics.academic = res.academic
      state.topics.epq = res.epq
      state.topics.iap = res.iap
      state.topics.iap2 = res.iap2
      state.topics.lite = res.lite
      state.topics.standard = res.standard
      state.topics.lite = res.lite
      state.topics.xdf = res.xdf
      state.topics.sort = res.sort
      state.topics.proHigh = res.proHigh
      state.topics.xt = res.xt
      state.topics.byr = res.byr
      state.topics.ewa = res.ewa
    },
    set_roleApi (state, res) {
      state.roleApi = res.roleApi
      state.role = res.role
    },
    set_defaultRouter (state, res) {
      state.defaultRouter = res
    }
  }
})

export default store
