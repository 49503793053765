<template>
  <el-dialog :title="dialogName" :visible="true" width="40%" :close-on-press-escape="false"
    :close-on-click-modal="false" :open="dialogOpen()">
    <el-form ref="professor" :model="professor" :rules="rules" :hide-required-asterisk="true">
      <el-row>
        <el-col :span="12">
          <el-form-item label="First Name*" prop="firstName">
            <el-input v-model="professor.firstName" placeholder="First Name"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Last Name*" prop="lastName">
            <el-input v-model="professor.lastName" placeholder="Last Name"> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="学校*" prop="school">
            <el-input v-model="professor.school" placeholder="学校"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="职位*" prop="position">
            <el-select v-model="professor.position" placeholder="职位">
              <el-option v-for="(item, index) in options" :key="index" :value="item" :label="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="邮箱*" prop="email">
            <el-input v-model="professor.email" placeholder="邮箱"> </el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="课时数*" prop="hours">
            <el-select v-model="professor.hours" placeholder="课时数">
              <el-option v-for="(item, index) in hourses" :key="index" :value="item" :label="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :span="12">
          <el-form-item label="推荐信*" prop="letter">
            <el-select v-model="professor.letter" placeholder="推荐信*">
              <el-option v-for="(item, index) in letters" :key="index" :value="item.value" :label="item.label">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
      </el-row>
      <el-row>
        <el-col :span="6" style="width: 180px">
          <el-form-item label="教授头像*" prop="avatar">
            <!--            <el-input v-model="professor.avatar" placeholder="教授头像">-->
            <!--            </el-input>-->
            <el-upload class="avatar-uploader" :action="uploadUrl" drag :show-file-list="false"
              :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img v-if="professor.avatar" :src="professor.avatar" class="avatar" />
              <!--              <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
              <div v-else>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              </div>
            </el-upload>
            <div>推荐尺寸120*120</div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item label="背景*" prop="background">
          <el-input type="textarea" v-model="professor.background" placeholder="背景"> </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="备注" prop="misc">
          <el-input type="textarea" v-model="professor.misc" placeholder="备注"> </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="对内简历" prop="inside" class="outside">
            <div class="hasResume" v-if="professor.inside" @click="download(professor.inside)">
              <img class="pdf" src="../../assets/icon/pdf-standard.png" />
            </div>
            <el-upload class="upload-demo" drag :action="uploadUrl" :on-success="
              (res) => {
                this.professor.inside = res.url
              }
            ">
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="对外简历" prop="outside" class="outside">
            <div class="hasResume" v-if="professor.outside" @click="download(professor.outside)">
              <img class="pdf" src="../../assets/icon/pdf-standard.png" />
            </div>
            <el-upload class="upload-demo" drag :action="uploadUrl" :on-success="
              (res) => {
                this.professor.outside = res.url
              }
            ">
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button @click="$parent.$parent.professorForm = false">取消</el-button>
      <el-button type="primary" @click="commit" :disabled="commitable">
        确认
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from '../axios'
export default {
  name: 'professorForm',
  data() {
    return {
      uploadUrl: `${axios.defaults.baseURL}/upload/`,
      rules: {
        firstName: [{ required: true, message: '请填写First Name', trigger: 'blur' }],
        lastName: [{ required: true, message: '请填写Last Name', trigger: 'blur' }],
        school: [{ required: true, message: '请填写学校', trigger: 'blur' }],
        position: [{ required: true, message: '请选择职位', trigger: 'change' }],
        email: [{ required: true, message: '请填写邮件', trigger: 'blur' }],
        hours: [{ required: true, message: '请选择课时数', trigger: 'change' }],
        letter: [{ required: true, message: '请选择推荐信', trigger: 'change' }],
        background: [{ required: true, message: '请填写背景', trigger: 'blur' }],
        avatar: [{ required: true, message: '请上传头像', trigger: 'change' }]
      },
      professor: {
        firstName: '',
        lastName: '',
        school: '',
        position: '',
        email: '',
        hours: '',
        letter: '',
        background: '',
        misc: '',
        inside: '',
        outside: '',
        avatar: '',
        id: ''
      },
      options: ['终身教授', '荣誉教授', '荣休教授', '教授', '临床教授'],

      hourses: ['5课时', '5课时/10课时'],
      letters: [
        {
          label: '均支持',
          value: '均支持'
        },
        {
          label: '待定',
          value: '待定'
        },
        {
          label: '不支持本校/本院申请',
          value: '不支持本校/本院申请'
        }
      ],
      post: true,
      id: null,
      dialogName: '',
      commitable: false
    }
  },
  methods: {
    dialogOpen() {
      if (this.post) this.dialogName = '添加教授'
      else this.dialogName = '修改教授'
    },
    commit() {
      this.$refs.professor.validate(valid => {
        if (valid) {
          this.commitable = true
          if (this.post) {
            this.postform()
          } else {
            this.putform()
          }
        } else {
          this.$message({
            message: '请输入必填项',
            type: 'warning'
          })
          return false
        }
      })
    },
    postform() {
      axios.post('/topic/professor', this.professor).then(response => {
        if (response.status === 200) {
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          this.$parent.$parent.professorForm = false
          this.$parent.$parent.search()
        }
      })
    },
    putform() {
      axios.put(`/topic/professor/${this.professor.id}`, this.professor).then(response => {
        if (response.status === 200) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.$parent.$parent.professorForm = false
          this.$parent.$parent.search()
        }
      })
    },
    handleAvatarSuccess(res) {
      this.professor.avatar = res.url
      this.$forceUpdate()
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    download(url) {
      let routeData = this.$router.resolve({
        path: '/pdf2',
        query: {
          url: url
        }
      })
      if (/Android|webOS|iPhone|ipad/i.test(navigator.userAgent)) {
        window.open(routeData.href, '_blank')
      } else {
        window.open(url, '_blank')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.el-col {
  &:nth-child(odd) {
    padding-right: 10px;
  }

  &:nth-child(even) {
    padding-left: 10px;
  }
}

/deep/.el-input__inner {
  background-color: #fff;
}

/deep/.el-form-item__label {
  color: #656d78;
}

.el-form-item {
  margin-bottom: 10px;
}

.el-select {
  width: 100%;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.outside /deep/.el-upload-dragger {
  height: 30px;
}

.pdf {
  width: 30px;
  display: inline-block;
  cursor: pointer;
}
</style>
