<template>
  <el-dialog
    :title="title"
    :visible.sync="active"
    width="60%"
    :close-on-click-modal="false"
    :before-close="closeDialog"
    destroy-on-close
    :opened="open()"
  >
    <div class="stuinfo">
      {{ dialogData.project_type }}--{{ dialogData.teacher }}--{{
        dialogData.name
      }}
    </div>
    <el-form
      :model="dialogData"
      label-position="top"
      :rules="rules"
      ref="form"
      :hide-required-asterisk="true"
    >
      <el-form-item :label="`选择${this.titleType}*`" prop="conf_id">
        <el-radio-group v-model="dialogData.conf_id">
          <el-radio
            v-for="item in types"
            :key="item.conf_id"
            :label="item.conf_id"
            @change="getid(item)"
            >{{ item.name }}</el-radio
          >
          <el-radio label="external" @change="getid(item)">其他</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="`${this.titleType}链接*`" prop="url">
        <el-input
          v-model="dialogData.url"
          :placeholder="`${this.titleType}链接`"
          :disabled="isnumber"
          @click.native="inputon()"
        ></el-input>
      </el-form-item>
      <el-form-item label="终稿备注">
        <el-input
          class="input"
          v-model="dialogData.final_memo"
          type="textarea"
          resize="none"
          :row="4"
          placeholder="终稿备注"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button class="button" @click="closeDialog">取消</el-button>
      <el-button class="button true" type="primary" @click="pushUrl"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'
export default {
  name: 'EditeDialog',
  data () {
    return {
      active: false,
      dialogData: {
        url: '',
        conf_id: 0
      },
      type: '', // 类型
      types: [],
      item: [],
      dialogDataTemp: {},
      pid: 0,
      resolve: '',
      reject: '',
      rules: {
        conf_id: [{ required: true, message: `请选择${this.titleType}`, trigger: 'change' }],
        url: [{ required: true, message: `请填写${this.titleType}链接`, trigger: 'change' }]
      },
      title: '添加会议',
      itemId: '',
      cid: '',
      isnumber: false,
      titleType: '会议'
    }
  },
  props: {
    project_id: ''
  },
  methods: {
    open () {
      if (this.dialogData.url !== '' && this.dialogData.conf_id === '') {
        this.dialogData.conf_id = 'external'
      }
      this.itemId = this.dialogData.conf_id
    },
    getid (item) {
      this.itemId = item.conf_id
      if (this.dialogData.conf_id === 'external') {
        this.dialogData.url = ''
        this.isnumber = false
      } else {
        this.dialogData.url = item.url
        this.isnumber = true
      }
    },
    inputon () {
      if (this.dialogData.conf_id === '') this.dialogData.conf_id = 'external'
    },
    openDialog (data) {
      const conf_type = data.project_type === 'Lite' ? 1 : 0
      this.titleType = data.project_type === 'Lite' ? '期刊' : '会议'
      axios.get(`/courier/confs`, {params: {conf_type: conf_type}}).then(res => {
        this.types = res.data.confs
      })
      this.active = true
      this.pid = data.project_id
      this.dialogData = { ...data }
      this.cid = this.dialogData.conf_id
      this.dialogDataTemp = JSON.stringify(this.dialogData)
      this.type = ''
      this.types.some(item => item === data.url) || data.url.length === 0
        ? data.url
        : 'external'
      const that = this
      this.title = data.url ? `修改${this.titleType}` : `添加${this.titleType}`
      return new Promise((res, rej) => {
        that.resolve = res
        that.reject = rej
      })
    },
    closeDialog () {
      if (JSON.stringify(this.dialogData) !== this.dialogDataTemp) {
        MessageBox.confirm('是否放弃更改内容').then(() => {
          this.active = false
          this.reject()
        })
      } else {
        this.active = false
        this.reject()
      }
    },
    pushUrl () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.dialogData.conf_id === 'external') {
            axios
              .put(`/courier/project/${this.project_id}/conference`, {
                type: this.dialogData.conf_id,
                url: this.dialogData.url,
                final_memo: this.dialogData.final_memo
              })
              .then(
                this.resolve({
                  url: this.dialogData.url,
                  final_memo: this.dialogData.final_memo
                }),
                (this.active = false)
              )
              .then(res => {
                if (res.status === 200) {
                  window.location.reload()
                }
              })
          } else {
            axios
              .put(`/courier/project/${this.project_id}/conference`, {
                con_id: this.itemId,
                final_memo: this.dialogData.final_memo
              })
              .then(
                this.resolve({
                  url: this.dialogData.url,
                  final_memo: this.dialogData.final_memo
                }),
                (this.active = false)
              )
              .then(res => {
                if (res.status === 200) {
                  window.location.reload()
                }
              })
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.stuinfo {
  width: 100%;
  height: 30px;
  text-align: left;
  margin-bottom: 10px;
  color: #c0c0c0;
}

.el-radio-group {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .el-radio {
    padding-bottom: 16px;
  }
}
.el-form-item {
  margin-bottom: 16px;
}
</style>
