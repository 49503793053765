<template>
  <div>
    <status-icon @click.native="add"> </status-icon>
    <el-dialog
      :title="zhuangTitle"
      :visible.sync="statusDialog"
      width="60%"
      :close-on-click-modal="false"
    >
      <p style="margin-bottom:10px">
        状况是指可能会影响辅导进度或者造成项目暂停的学生情况，如学生考试等。添加状况后，学生状态会变为暂停。<br />
        状况信息学生不可见。
      </p>
      <el-form :model="statusForm" :rules="rules" ref="statusForm">
        <el-form-item prop="status">
          <el-input
            type="textarea"
            class="input"
            maxlength="500"
            show-word-limit
            :autosize="{ minRows: 3, maxRows: 3 }"
            v-model="statusForm.status"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="confirm" :disabled="pending">
          确 认
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../../axios'
import statusIcon from '../../../assets/icon-font/statusIcon'
import { MessageBox } from 'element-ui'
export default {
  components: { statusIcon },
  data () {
    return {
      rules: {
        status: [{ required: true, message: '请填写学生状况', trigger: 'blur' }]
      },
      statusDialog: false,
      statusForm: {
        status: ''
      },
      pending: false,
      zhuangTitle: '添加状况（导师可见）'
    }
  },
  props: {
    pid: Number
  },
  methods: {
    cancel () {
      this.statusDialog = false
      this.statusForm.status = ''
    },
    confirm () {
      this.$refs.statusForm.validate(valid => {
        if (valid) {
          if (this.zhuangTitle === '添加状况（导师可见）') {
            MessageBox.confirm('是否添加状况').then(() => {
              this.pending = true
              axios
                .post(
                  `/${this.$store.state.roleApi}/project/${this.pid}/status`,
                {
                  status: this.statusForm.status
                }
                )
                .then(response => {
                  if (response.status === 201) {
                    this.$message({
                      showClose: true,
                      message: '添加成功',
                      type: 'success'
                    })
                    this.$emit('refresh', null)
                    this.pending = false
                    this.statusDialog = false
                  }
                })
                .catch(error => {
                  console.log(error)
                })
            })
          } else {
            MessageBox.confirm('是否修改状况').then(() => {
              this.pending = true
              axios
                .put(`/mdirector/status/${this.status_id}/`, {
                  status: this.statusForm.status
                })
                .then(response => {
                  if (response.status === 200) {
                    this.$message({
                      showClose: true,
                      message: '修改成功',
                      type: 'success'
                    })
                    axios
                      .get(`/mdirector/project/${this.pid}/`)
                      .then(response => {
                        this.tableData = response.data.stages
                      })
                      .catch(error => {
                        console.log(error)
                      })
                    this.$emit('refresh', null)
                    this.pending = false
                    this.statusDialog = false
                  }
                })
                .catch(error => {
                  console.log(error)
                })
            })
          }
        } else {
          return false
        }
      })
    },
    add () {
      this.statusDialog = true
      this.zhuangTitle = '添加状况（导师可见）'
    },
    edit (data) {
      this.statusDialog = true
      this.zhuangTitle = data.title
      this.statusForm.status = data.status
      this.status_id = data.status_id
    }
  }
}
</script>
