<template>
  <el-button
    type="primary"
    size="mini"
    @mouseenter.native="active = true"
    @mouseleave.native="active = false"
    @focus.native="active = true"
    @blur.native="active = false"
  >
    <svg
      width="27px"
      height="20px"
      viewBox="0 0 27 20"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.7601 0L1.80372 0C0.819872 0 0 0.819872 0 1.80372L0 18.0372C0 19.185 0.819872 19.8409 1.80372 19.8409L24.5962 19.8409C25.744 19.8409 26.3999 19.021 26.3999 18.0372L26.3999 1.96769C26.5639 0.819872 25.744 0 24.7601 0L24.7601 0ZM7.87057 4.75525C9.34634 4.75525 10.6581 5.90307 10.6581 7.54281C10.4942 9.01859 9.34634 10.3304 7.87057 10.3304C6.23083 10.1664 5.24698 8.85461 5.08301 7.37884C5.24698 5.7391 6.3948 4.75525 7.87057 4.75525L7.87057 4.75525ZM12.9541 16.0694L2.95166 16.0694L2.95166 15.5775C2.95166 12.79 4.42743 10.8223 8.03487 10.8223C11.6423 10.8223 13.1181 12.626 13.1181 15.2496C12.9541 15.4136 12.9541 16.0694 12.9541 16.0694L12.9541 16.0694ZM23.6122 13.9378C23.6122 14.2657 23.4482 14.4297 23.1203 14.4297L15.7414 14.4297C15.4135 14.4297 15.2495 14.2657 15.2495 13.9378L15.2495 13.1179C15.2495 12.79 15.4135 12.626 15.5775 12.626L23.1203 12.626C23.4482 12.626 23.6122 12.79 23.6122 13.1179L23.6122 13.9378L23.6122 13.9378ZM23.6122 10.9862C23.6122 11.3142 23.4482 11.4782 23.1203 11.4782L15.7414 11.4782C15.5775 11.4782 15.2495 11.3142 15.2495 11.1502L15.2495 10.1664C15.2495 9.83841 15.4135 9.67444 15.7414 9.67444L23.1203 9.67444C23.4482 9.67444 23.6122 9.83841 23.6122 10.1664L23.6122 10.9862L23.6122 10.9862ZM23.6122 8.03473C23.6122 8.36268 23.4482 8.52665 23.1203 8.52665L15.7414 8.52665C15.4135 8.52665 15.2495 8.36268 15.2495 8.03473L15.2495 7.05088C15.2495 6.72293 15.4135 6.55896 15.7414 6.55896L23.1203 6.55896C23.4482 6.55896 23.6122 6.72293 23.6122 7.05088L23.6122 8.03473L23.6122 8.03473Z"
        id="形状"
        :fill="color"
        stroke="none"
      />
    </svg>
  </el-button>
</template>

<script>
/* eslint-disable no-console */
export default {
  data () {
    return {
      active: false,
      color: '#a0d469'
    }
  },
  watch: {
    active () {
      if (this.active) {
        this.color = '#fff'
      } else {
        this.color = '#a0d469'
      }
    }
  }
}
</script>
