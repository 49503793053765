<template>
  <el-main class="main">
    <div class="content">
      <h2>{{ type === 'teachers' ? '导师状态' : '教授状态' }}</h2>
      <div class="roletype">
        <el-select v-model="type" @change="changetype()">
          <el-option
            v-for="item in types"
            :key="item.content"
            :label="item.label"
            :value="item.content"
          ></el-option>
        </el-select>
      </div>
      <div v-if="type === 'teachers'">
        <el-row class="top">
          <el-col :span="22">
            <el-input
              placeholder="导师姓名"
              v-model="keyword"
              @keyup.enter.native="search()"
              @input="input_search()"
            >
            </el-input>
          </el-col>
          <el-col :span="2">
            <el-button class="sea" type="primary" @click="search()">搜索</el-button>
          </el-col>
        </el-row>
        <el-table
          :data="tableData"
          :row-class-name="fullclass"
          ref="teacherTable"
          @scroll.native="scrollT"
          class="base"
        >
          <el-table-column prop="name" align="center" label="导师姓名" key="1-1"> </el-table-column>
          <el-table-column prop="misc" align="center" label="导师身份" key="1-2"> </el-table-column>
          <el-table-column prop="research" align="center" label="推荐信状态" key="1-3">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.research"
                active-color="#7ED321"
                inactive-color="#c0c0c0"
                @change="teacherStatus(scope.row, $event, 'research', scope.$index)"
                :disabled="offlist[scope.row.id] && offlist[scope.row.id].research !== undefined"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="thesis" align="center" label="论文状态" key="1-4">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.thesis"
                active-color="#7ED321"
                inactive-color="#c0c0c0"
                @change="teacherStatus(scope.row, $event, 'thesis', scope.$index)"
                :disabled="offlist[scope.row.id] && offlist[scope.row.id].thesis !== undefined"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="graduate" align="center" label="是否博士毕业" key="1-5">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.graduate"
                active-color="#7ED321"
                inactive-color="#c0c0c0"
                @change="changeGraduate(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="graduate" align="center" label="国内申请" key="1-5">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.domestic"
                active-color="#7ED321"
                inactive-color="#c0c0c0"
                @change="changeDomestic(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <div slot="append" style="text-align: center; font-size: 16px">
            <span v-if="has_next"> <i class="eliwise-loading"></i>加载中请稍后... </span>
            <i v-else>--没有数据了--</i>
          </div>
        </el-table>
      </div>
      <div v-else>
        <el-row class="top">
          <el-col :span="21">
            <el-input
              placeholder="教授"
              v-model="keyword"
              @keyup.enter.native="search()"
              @input="input_search()"
            >
            </el-input>
          </el-col>
          <el-col :span="2">
            <el-button class="sea" type="primary" @click="search()">
              搜索
            </el-button>
          </el-col>
          <el-col :span="1">
            <img
              @click="professorForm = true"
              src="../../assets/icon/添加用户.png"
              style="margin: auto;height: 40px;display: block;"
            />
          </el-col>
        </el-row>
        <el-table
          :data="tableData"
          :row-class-name="fullclass"
          ref="teacherTable"
          @scroll.native="scrollT"
          class="base"
        >
          <el-table-column prop="name" align="center" label="教授" key="2-1">
            <template slot-scope="scope">
              {{ scope.row.firstName }} {{ scope.row.lastName }}</template
            >
          </el-table-column>
          <el-table-column prop="position" align="center" label="教授职位" key="2-2">
          </el-table-column>
          <el-table-column prop="school" align="center" label="机构" key="2-3"> </el-table-column>
          <el-table-column prop="status" align="center" label="状态" key="2-4">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                active-color="#7ED321"
                inactive-color="#c0c0c0"
                @change="professorStatus(scope.row, $event, 'status', scope.$index)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column align="center" key="2-9">
            <template slot-scope="scope">
              <img
                src="../../assets/icon/编辑icon.png"
                class="svg"
                @click="putProfessor(scope.row)"
              />
              <img
                src="../../assets/icon/删除icon.png"
                class="svg"
                @click="deleteProfessor(scope.row)"
              />
            </template>
          </el-table-column>
          <div slot="append" style="text-align: center; font-size: 16px">
            <span v-if="has_next"> <i class="eliwise-loading"></i>加载中请稍后... </span>
            <i v-else style="font-size: 16px">--没有数据了--</i>
          </div>
        </el-table>
        <professorForm v-if="professorForm" ref="professorForm"></professorForm>
      </div>
    </div>
    <teacherOff
      :offlist="offlist"
      @deleteOff="deleteOff"
      @refresh="refresh"
      v-if="Object.keys(this.offlist).length > 0"
    ></teacherOff>
    <professorOff
      :offlist="proOffList"
      @proDeleteOff="proDeleteOff"
      @refresh="refresh"
      v-if="Object.keys(this.proOffList).length > 0"
    ></professorOff>
  </el-main>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'
import changerole from '../pub/changerole'
import professorForm from './professorForm.vue'
import teacherOff from './teacherOff.vue'
import professorOff from './professorOff'

export default {
  name: 'Status',
  components: {
    changerole,
    professorForm,
    teacherOff,
    professorOff
  },
  data () {
    return {
      keyword: '',
      tableData: [],
      page: 1,
      has_next: true,
      type: 'teachers',
      types: [
        {
          content: 'teachers',
          label: '导师'
        },
        {
          content: 'professors',
          label: '教授'
        }
      ],
      professorForm: false,
      loading: false,
      offlist: {},
      proOffList: {}
    }
  },
  created () {
    this.getTeacher()
  },
  methods: {
    refresh () {
      this.offlist = {}
      this.proOffList = {}
      this.search()
    },
    deleteOff (row) {
      this.$delete(this.offlist, row.id)
      if (row.research !== undefined) {
        this.tableData[row.row].research = !row.research
      }
      if (row.thesis !== undefined) {
        this.tableData[row.row].thesis = !row.thesis
      }
    },
    proDeleteOff (row) {
      console.log(row, 'proOff')
      this.$delete(this.proOffList, row.id)
      if (row.status !== undefined) {
        this.tableData[row.row].status = !row.status
      }
    },
    getTeacher () {
      if (!this.loading) {
        this.loading = true
        if (this.type === 'teachers') {
          axios
            .get(`DBS/${this.type}`, {
              params: {
                name: this.keyword,
                page: this.page
              }
            })
            .then(response => {
              this.tableData = this.tableData.concat(response.data.teachers)
              this.has_next = response.data.has_next
              this.loading = false
            })
        } else {
          axios
            .get(`topic/${this.type}`, {
              params: {
                keyword: this.keyword,
                page: this.page
              }
            })
            .then(response => {
              this.tableData = this.tableData.concat(response.data.teachers)
              this.has_next = response.data.has_next
              this.loading = false
            })
        }
      }
    },
    input_search: _.debounce(function () {
      this.search()
    }, 500),
    changetype () {
      this.keyword = ''
      this.search()
    },
    search () {
      this.page = 1
      this.has_next = true
      this.tableData = []
      this.loading = false
      this.$nextTick(this.getTeacher())
    },
    scrollT () {
      let table = this.$refs.teacherTable.$el
      let scrollTop = table.scrollTop
      let windowHeight = table.clientHeight
      let scrollHeight = table.scrollHeight
      if (scrollTop + windowHeight >= scrollHeight - 1 && this.has_next & !this.loading) {
        this.page++
        this.getTeacher()
      }
    },
    changeDomestic (row) {
      MessageBox.confirm('是否更改国内申请状态')
        .then(() => {
          axios.put('/DBS/domestic', {
            id: row.id,
            domestic: row.domestic
          })
        })
        .catch(() => {
          row.graduate = !row.graduate
        })
    },
    changeGraduate (row) {
      MessageBox.confirm('是否更改毕业状态')
        .then(() => {
          axios.put('/DBS/graduate', {
            id: row.id,
            graduate: row.graduate
          })
        })
        .catch(() => {
          row.graduate = !row.graduate
        })
    },
    teacherStatus (row, $event, type, $index) {
      const sort =
        (this.offlist[row.id] && this.offlist[row.id].sort) || Object.keys(this.offlist).length
      const teacher = {
        id: row.id,
        name: row.name,
        sort: sort,
        row: $index,
        ...this.offlist[row.id]
      }
      teacher[type] = $event
      this.$set(this.offlist, row.id, teacher)
    },
    professorStatus (row, $event, type, $index) {
      const sort =
        (this.proOffList[row.id] && this.proOffList[row.id].sort) ||
        Object.keys(this.proOffList).length
      const professor = {
        id: row.id,
        name: row.firstName + ' ' + row.lastName,
        sort: sort,
        row: $index,
        ...this.proOffList[row.id]
      }
      professor[type] = $event
      this.$set(this.proOffList, row.id, professor)
      // axios.put('/topic/professor/status', {
      //   id: row.id,
      //   status: row.status
      // })
    },
    putProfessor (row) {
      this.professorForm = true
      this.$nextTick(() => {
        this.$refs.professorForm.post = false
        // this.$refs.professorForm.professor = JSON.parse(JSON.stringify(row))
        this.$refs.professorForm.professor = row
      })
    },
    deleteProfessor (row) {
      MessageBox.confirm('确定删除').then(() => {
        axios.delete(`/topic/professor/${row.id}`).then(response => {
          if (response.status === 200) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
          }
          this.changetype()
        })
      })
    },
    fullclass ({ row }) {
      return row.research || row.thesis || row.status ? '' : 'fulling'
    }
  }
}
</script>

<style lang="scss" scoped>
.Topic.main .content {
  margin: 0 auto;
}
/deep/.fulling {
  color: #c0c0c0;
}
.el-table {
  height: 80vh;
  overflow: scroll;
}
.roletype {
  top: 40px;
  right: 0;
  width: 80px;
  position: absolute;
  /deep/.el-input__inner {
    border: none;
  }
}
</style>
