<template>
  <div>
    <el-row style="padding-right: 50px;">
      <el-col :span="12">
        <p class="name">{{ project.student }}.{{ project.project_type }}</p>
      </el-col>
      <el-col :span="12"> <slot name="roles"></slot></el-col>
    </el-row>
    <div
      style="overflow:scroll;height:calc(100vh - 155px);padding-right: 50px;"
      ref="scrollView"
    >
      <el-row>
        <el-col :span="12">
          <p class="direction">课题方向：{{ project.projectName || '暂无' }}</p>
        </el-col>
        <el-col :span="12" class="buttons">
          <slot name="buttons"></slot>
        </el-col>
      </el-row>
      <slot name="stages"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    project: Object
  },
  watch: {
    project: {
      handler: function () {
        this.$refs.scrollView.scrollTop = 0
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  text-align: left;
  margin: 0;
}
.name {
  margin: 25px 0 17px 0;
  font-size: 30px;
  color: #656d78;
}
.direction {
  font-size: 20px;
  color: #656d78;
}
</style>
