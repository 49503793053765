<template>
  <div>
    <el-table
      :span-method="arraySpanMethod"
      :data="tableData"
      class="base"
      @select="handleSelectionChange"
      @select-all="selectAll"
      :row-class-name="tableRowClassName"
      :header-row-class-name="showHeader ? '' : 'exportable'"
      :show-header="true"
      ref="table"
      :header-cell-style="headerCellStyle"
    >
      <el-table-column align="center" width="30">
        <template slot-scope="{ row }">
          <div class="img">
            <img
              src="../../assets/icon/惊叹号.png"
              v-if="row.type === 'status'"
            />
            <img
              src="../../assets/icon/备注.png"
              v-else-if="row.type === 'remark'"
            />
            <img
              src="../../assets/icon/结项.png"
              v-else-if="row.type === 'end'"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="日期" align="center" width="110">
        <template slot-scope="{ row }">
          {{
            row.type === 'nextMission' || row.type === 'teacher_evaluate'
              ? ''
              : row.date
          }}
        </template>
      </el-table-column>
      <el-table-column
        label="导师辅导内容"
        align="left"
        header-align="center"
        min-width="28%"
      >
        <template slot-scope="{ row }">
          <div v-if="row.type === 'status'">
            {{ row.status }}
          </div>
          <div v-else-if="row.type === 'nextMission'">
            预计下次辅导内容：{{ row.mission }}
          </div>
          <div v-else-if="row.type === 'remark'">
            <span v-if="row.first">【学生辅导感受】</span>{{ row.remark }}
          </div>
          <div v-else-if="row.type === 'end'">
            <p v-for="item in row.chosen" :key="item" style="display: inline">
              {{ item }}，
            </p>
            <br />
            {{ row.written }}
          </div>
          <div v-else-if="row.type === 'task'">
            <div class="xietask">{{ row.teacher_work }}</div>
          </div>
          <div v-else-if="row.type === 'teacher_evaluate'">
            【学生评价】:{{ row.teacher_evaluate }}
          </div>
          <div v-else-if="row.type === 'feedback' && row.time === '2'">
            【特训营】<br />
            1.学生查找论文导读<br />
            2.论文阅读技巧讲解 <br />
            3.论文各部分结构和内容 <br />
            4.论文阅读笔记技巧讲解<br />
            5.论文中公式、图表、参考文献的使用 <br />
            6.论文的投递与查重 <br />
            【特训营反馈】<br />
            学生是否按时参加:{{ row.attend }}<br />
            学生是否按时完成任务:{{ row.finish }}<br />
            学生计划论文选题:{{ row.topic }}<br />
            评价: {{ row.note }} -- {{ row.assistant_name }}
          </div>
          <div v-else-if="row.type === 'feedback' && row.time === '1'">
            【特训营】<br />
            1.论文的基本概念<br />
            2.公开发表论文与其他类型论文的区别<br />
            3.公开发表论文的常见类型 <br />
            4.论文的查找与下载<br />
            5.论文选题常见技巧 <br />
            【特训营反馈】<br />
            学生是否按时参加:{{ row.attend }}<br />
            学生是否按时完成任务:{{ row.finish }}<br />
            学生计划论文选题:{{ row.topic }}<br />
            评价: {{ row.note }} -- {{ row.assistant_name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="work_time"
        label="辅导时间"
        align="center"
        min-width="10%"
        :formatter="timeFormatter"
        v-if="!isEnterprise"
      >
      </el-table-column>
      <el-table-column label="学生任务" align="center" min-width="28%">
        <template slot-scope="{ row }">
          <div v-if="row.type === 'feedback' && row.time === '1'">
            查找并下载本专业领域同一方向学术论文（中文5篇+英文5篇）
          </div>
          <div v-else-if="row.type === 'feedback' && row.time === '2'">
            完成论文阅读笔记（中文5篇+英文5篇，并给出大致行选题思路）
          </div>
          <div v-else>{{ row.student_work }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="deadline"
        label="Deadline"
        align="center"
        min-width="15%"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.type === 'task'">
            {{ scope.row.deadline }}
          </div>
        </template>
      </el-table-column>
      <el-table-column type="selection" width="45" class-name="exportable">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'chart',
  data () {
    return {
      beizhu: {
        beizhuTitle: '',
        beizhuDialog: false,
        remark: '',
        remark_id: '',
        attend: '',
        finish: '',
        topic: '',
        note: '',
        feed_id: ''
      },
      zhuang: {
        zhuangTitle: '',
        zhuangDialog: false,
        status: '',
        status_id: ''
      },
      status_id: '',
      remark_id: '',
      feed_id: '',
      roles: [],
      a: ''
    }
  },
  props: {
    stage: Array,
    editable: Boolean,
    checked: {},
    isEnterprise: Boolean,
    showHeader: Boolean
  },
  computed: {
    tableData () {
      return this.stage
    }
  },
  mounted () {
    this.initSelection()
  },
  methods: {
    timeFormatter (row) {
      const ttime = row.work_time
      if (row.type === 'feedback') {
        return `1.5h`
      } else if (ttime) {
        if (ttime > 3) return `${ttime / 4}h`
        else return `${ttime * 15}min`
      }
      return ''
    },
    arraySpanMethod ({ rowIndex, columnIndex }) {
      const item = this.tableData[rowIndex]
      const status = item.status
      const nextmission = item.mission
      const teacher_evaluate = item.teacher_evaluate
      const remark = item.remark
      const written = item.written
      const type = item.type
      if (this.isEnterprise) {
        if (!!nextmission && columnIndex === 2) return [1, 3]
        if (!!nextmission && columnIndex === 5) return [1, 1]
        if (!!status && columnIndex === 2) return [1, 3]
        if (!!status && columnIndex === 5) return [1, 1]
      }
      if (!!written && columnIndex === 2) return [1, 4]
      if (!!nextmission && columnIndex === 2) return [1, 4]
      if (!!status && columnIndex === 2) return [1, 4]
      if (
        !!teacher_evaluate &&
        type === 'teacher_evaluate' &&
        columnIndex === 2
      ) {
        return [1, 4]
      }
      if (!!remark && columnIndex === 2) return [1, 4]

      if (!!written && columnIndex >= 3 && columnIndex <= 5) return [0, 0]
      if (!!nextmission && columnIndex >= 3 && columnIndex <= 5) return [0, 0]
      if (!!status && columnIndex >= 3 && columnIndex <= 5) return [0, 0]
      if (
        !!teacher_evaluate &&
        type === 'teacher_evaluate' &&
        columnIndex >= 3 &&
        columnIndex <= 5
      ) {
        return [0, 0]
      }
      if (!!remark && columnIndex >= 3 && columnIndex <= 5) return [0, 0]
    },
    handleSelectionChange (val, row) {
      row.exportable = !row.exportable
      if (val.length > 0) {
        this.$emit('checked', true)
      } else {
        this.$emit('checked', false)
      }
    },
    selectAll (val) {
      if (val.length > 0) {
        this.tableData.forEach(e => {
          e.exportable = true
        })
        this.$emit('checked', true)
      } else {
        this.tableData.forEach(e => {
          e.exportable = false
        })
        this.$emit('checked', false)
      }
    },
    tableRowClassName ({ row }) {
      if (row.exportable) {
        return ''
      } else return 'exportable'
    },
    clearSelect () {
      this.$refs.table.clearSelection()
      if (this.tableData) {
        this.tableData.forEach(row => {
          row.exportable = false
        })
      }
    },
    allSelection () {
      this.$refs.table.toggleAllSelection()
      if (this.tableData) {
        this.tableData.forEach(row => {
          row.exportable = true
        })
      }
    },
    initSelection () {
      // 初始化选中
      if (this.tableData) {
        this.tableData.forEach(row => {
          this.$refs.table.toggleRowSelection(row, row.exportable)
        })
      }
    },
    // 用于删除表头行尾部的checkbox
    headerCellStyle ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 6) {
        // return 'display:none'
      }
    }
  }
}
</script>
<style scoped lang="scss">
/deep/.el-table {
  background-color: transparent;
}
/deep/.el-table tr {
  background-color: transparent;
}
.base {
  /deep/.el-table-column--selection {
    left: 4px;
    //padding-left: 5px;
    border: none;
    text-align: center !important;
  }
}
.isok {
  width: 26px;
  height: 18px;
}
.feedbs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #f4f6f9;
}
.feedbsp {
  margin-left: 60px;
}
.feedbssp {
  margin-right: 20px;
  flex: 1;
  text-align: center;
}
.feedp {
  margin-top: 0;
  width: 300px;
  padding-left: 7px;
}
.feedcontainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: cneter;
}
.feedleft {
  width: 100px;
}
.feedright {
  float: left;
}
.feedimg {
  width: 25px;
  height: 25px;
}
.img img {
  height: 20px;
  vertical-align: top;
}
</style>
