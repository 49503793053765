<template>
  <div class="aside">
    <el-menu router @select="handleSelect">
      <el-menu-item
        :class="$route.path == '/E/Einterview' ? 'is-active' : ''"
        index="/E/Einterview"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">面试<br />学员</div>
          <img class="iconmenu" src="../../assets/icon/面试学员@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/E/Eschedule' ? 'is-active' : ''"
        index="/E/Eschedule"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">学员<br />进度</div>
          <img class="iconmenu" src="../../assets/icon/学员进度@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/E/Estudent' ? 'is-active' : ''"
        index="/E/Estudent"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">结项<br />学员</div>
          <img class="iconmenu" src="../../assets/icon/结项学员@3x.png" />
        </el-tooltip>
      </el-menu-item>
    </el-menu>
    <div>
    <changeRoleET></changeRoleET>
    <div class="back" style="margin: 20px 0;">
      <img
        @click="logout"
        src="../../assets/icon/退出.png"
        height="20"
        width="20"
      />
    </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'
import changeRoleET from '../pub/changeRoleEnterprise'
export default {
  name: 'mnav',
  components: { changeRoleET },
  methods: {
    handleSelect (key, keyPath) {
      this.$router.replace({ path: `${keyPath}` })
    },
    logout () {
      MessageBox.confirm('是否注销？')
        .then(() => {
          axios.delete('/logout/').then(() => {
            localStorage.removeItem('Authorization')
            this.$router.replace('/login')
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.back {

}
</style>
