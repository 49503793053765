<template>
  <div class="aside">
    <changerole></changerole>
    <el-menu router @select="handleSelect">
      <el-menu-item
        :class="$route.path == '/T/tschedule' ? 'is-active' : ''"
        index="/T/tschedule"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">学员<br />进度</div>
          <img class="iconmenu" src="../../assets/icon/学员进度@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/T/tfinish' ? 'is-active' : ''"
        index="/T/tfinish"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">结项<br />学员</div>
          <img class="iconmenu" src="../../assets/icon/结项学员@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/T/readOnlyManual' ? 'is-active' : ''"
        index="/T/readOnlyManual"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">导师<br />手册</div>
          <img class="iconmenu" src="../../assets/icon/导师手册@3x.png" />
        </el-tooltip>
        <div class="point" v-if="manual_unread">{{ manual_unread }}</div>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/T/tmodify' ? 'is-active' : ''"
        index="/T/tmodify"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">修改<br />信息</div>
          <img class="iconmenu" src="../../assets/icon/账户管理@3x.png" />
        </el-tooltip>
      </el-menu-item>
    </el-menu>
    <div class="back">
      <img
        @click="logout"
        src="../../assets/icon/退出.png"
        height="20"
        width="20"
      />
    </div>
  </div>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'
import changerole from '../pub/changerole'
export default {
  name: 'tnav',
  components: { changerole },
  data () {
    return {
      manual_unread: false
    }
  },
  mounted () {
    axios.get(`/teacher/manual`).then(res => {
      this.manual_unread = res.data.manual
    })
  },
  methods: {
    handleSelect (key, keyPath) {
      if (
        keyPath[0].indexOf('readOnlyManual') !== -1 &&
        this.manual_unread > 0
      ) {
        axios.put(`/teacher/manual`).then(res => {
          if (res.status === 200) {
            this.manual_unread = false
          }
        })
      }
      this.$router.replace({ path: `${keyPath}` })
    },
    logout () {
      MessageBox.confirm('是否注销？')
        .then(() => {
          axios.delete('/logout/').then(() => {
            localStorage.removeItem('Authorization')
            this.$router.replace('/login')
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped>
.point {
  position: absolute;
  top: 12px;
  right: 16px;
  width: 14px;
  height: 14px;
  background: #ff0000;
  border-radius: 50%;
  font-size: 8px;
  color: #fff;
  line-height: 14px;
}
.is-active .point,
.el-menu-item:hover .point {
  position: absolute;
  top: 5px;
  right: 8px;
  width: 20px;
  height: 20px;
  background: #ff0000;
  border-radius: 50%;
  font-size: 8px;
  color: #fff;
  line-height: 20px;
  font-size: 16px;
}
</style>
