<template>
  <el-row class="jstumessage">
    <el-col :span="6">
      <el-tooltip
        class="item"
        effect="light"
        :content="smes.name"
        placement="top"
        :disabled="showTooltip"
      >
        <h1 class="sname" id="sname">{{ smes.name }}</h1>
      </el-tooltip>
      <!--      <h1 class="sname">{{ smes.name }}</h1>-->
    </el-col>
    <el-col :span="18" v-if="!nameOnly">
      <div class="mes">
        <el-row>
          <el-col :span="3">
            <img
              class="icon"
              src="../../assets/icon/性别@3x.png"
              height="13"
              width="13"
            />
            <span class="messagetext">{{ smes.sex }}</span>
          </el-col>
          <el-col :span="4">
            <img
              class="icon"
              src="../../assets/icon/学校@3x.png"
              height="12"
              width="14"
            />
            <el-tooltip
              placement="top"
              :disabled="!schoolEllips"
              :content="smes.school"
              effect="light"
              popper-class="atooltip"
            >
              <span ref="schooltext" class="messagetext">{{
                smes.school
              }}</span>
            </el-tooltip>
          </el-col>
          <el-col :span="4">
            <img
              class="icon"
              src="../../assets/icon/专业@3x.png"
              height="13"
              width="13"
            />
            <el-tooltip
              placement="top"
              :disabled="!directionEllips"
              :content="smes.direction"
              effect="light"
              popper-class="atooltip"
            >
              <span ref="directiontext" class="messagetext">{{
                smes.direction
              }}</span>
            </el-tooltip>
          </el-col>
          <el-col :span="6">
            <img
              class="icon"
              src="../../assets/icon/电话@3x.png"
              height="13"
              width="13"
            />
            <el-tooltip
              placement="top"
              :disabled="!phoneEllips"
              :content="smes.cellphone"
              effect="light"
              popper-class="atooltip"
            >
              <span ref="phonetext" class="messagetext">{{
                smes.cellphone || '无手机信息'
              }}</span>
            </el-tooltip>
          </el-col>
          <el-col :span="7">
            <img
              class="icon"
              src="../../assets/icon/邮件@3x.png"
              height="13"
              width="18"
            />
            <el-tooltip
              placement="top"
              :disabled="!mailEllips"
              :content="smes.email"
              effect="light"
              popper-class="atooltip"
            >
              <span ref="mailtext" class="messagetext">{{
                smes.email || '无邮箱信息'
              }}</span>
            </el-tooltip>
          </el-col>
        </el-row>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'studentInfo',
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    nameOnly: {
      type: Boolean,
      default: false
    },
    smes: {
      type: Object,
      default: () => {
        return {
          name: '',
          sex: '',
          school: '',
          direction: '',
          cellphone: '',
          email: ''
        }
      }
    }
  },
  data () {
    return {
      mailEllips: false,
      directionEllips: false,
      schoolEllips: false,
      phoneEllips: false,
      showTooltip: true
    }
  },
  watch: {
    smes: {
      handler () {
        setTimeout(() => {
          this.weatherShowTooltip()
        }, 100)
      },
      deep: true
    }
  },
  mounted () {
    this.mounted = true
    this.weatherShowTooltip()
  },
  methods: {
    // 判断是不是需要鼠标悬浮弹窗显示
    weatherShowTooltip () {
      let cell = document.getElementById('sname')
      this.showTooltip = !(cell.scrollWidth > cell.offsetWidth)
    }
  }
}
</script>
