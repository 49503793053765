<template>
  <el-dialog :title="title" :visible="open" v-if="open">
    <p style="color: #c0c0c0">此条信息学生不可见</p>
    <p style="margin: 10px 0">面试反馈范例</p>
    <div class="example">
      <p>
        学生基础情况：学生工科背景，但对理工科完全不感兴趣，因此转专业申请工程管理/项目管理。学生无学术基础也无学科基础，有过几门基础课程的学习，但还远远不够，因此项目难度不会太难。
      </p>
      <p>学生沟通态度：沟通顺畅，学生懂礼貌。</p>
      <p>
        沟通内容/任务规划：沟通内容主要对工程管理/项目管理的研究内容进行了简单讲解，并且讲了一下商科、管理学、经济学等学科的研究范式以及工程管理作为交叉学科的常见研究范式。
      </p>
      <p>
        预计大致内容/选题方向：由于学生不喜欢工程和数理内容，因此选题暂定为绿色/环境方向，及绿色建造或绿色工程管理方向。
      </p>
    </div>
    <div class="content" :class="{ active: is_active }">
      <interviewText
        class="text1"
        v-model="interview[0]"
        @keydown.enter.native="keydown(2)"
        id="text1"
        :total="total"
      >
        学生基础情况*：
      </interviewText>
      <interviewText
        class="text1"
        v-model="interview[1]"
        @keydown.enter.native="keydown(3)"
        id="text2"
        :total="total"
      >
        学生沟通态度*：
      </interviewText>
      <interviewText
        class="text2"
        v-model="interview[2]"
        @keydown.enter.native="keydown(4)"
        id="text3"
        :total="total"
      >
        沟通内容/任务规划*：
      </interviewText>
      <interviewText
        class="text3"
        v-model="interview[3]"
        @keydown.enter.native="keydown(5)"
        id="text4"
        :total="total"
      >
        预计大致内容/选题方向*：
      </interviewText>
      <interviewText
        class="text4"
        id="text5"
        v-model="interview[4]"
        :total="total"
      >
        特殊情况（学生的额外诉求等）：
      </interviewText>
    </div>
    <div style="position: relative">
      <div
        style="position: absolute; right: 0; font-size: 12px; color: silver"
        :style="total >= 750 ? 'color:red' : ''"
      >
        ({{ total }}/750)
      </div>
      <div class="warnText" v-if="warn">请填写{{ warn }}</div>
    </div>
    <div slot="footer">
      <el-button @click="open = false">返回</el-button>
      <el-button type="primary" @click="confirm('ruleform')" :disabled="pending"
        >确认</el-button
      >
      <i
        class="el-icon-loading"
        style="position: absolute; margin-left: 5px; margin-top: 8px"
        v-if="pending"
      ></i>
    </div>
  </el-dialog>
</template>

<script>
import interviewText from './interviewText'
import { MessageBox } from 'element-ui'
import axios from '../axios'
export default {
  components: {
    interviewText
  },
  data () {
    return {
      open: false,
      title: '填写面试反馈',
      interview: ['', '', '', '', ''],
      is_active: false,
      activeValue: false,
      warn: '',
      labels: [
        '学生基础情况',
        '学生沟通态度',
        '沟通内容/任务规划',
        '预计大致内容/选题方向',
        '特殊情况'
      ],
      now: '',
      pending: false
    }
  },
  computed: {
    total () {
      const x = this.interview.join('')
      return x.length || 0
    }
  },
  props: {
    interviewPro: {},
    pid: {}
  },
  methods: {
    keydown (index) {
      document
        .getElementById('text' + index)
        .getElementsByTagName('textarea')[0]
        .focus()
    },
    focus () {
      this.is_active = true
      this.activeValue = true
    },
    blur () {
      this.activeValue = false
      setTimeout(() => {
        if (!this.activeValue) {
          this.is_active = false
        }
      }, 300)
    },
    confirm () {
      let res = [],
        warn = ''
      // 前四个必填检验
      for (let i = 0; i < 4; i++) {
        if (!this.interview[i]) {
          this.warn = this.labels[i]
          return
        }
        // 结尾判断一下加个句号
        let endCode = this.interview[i][this.interview[i].length - 1]
        if (endCode !== '。' && endCode !== '.') {
          this.interview[i] += '。'
        }
        res.push(this.labels[i] + '：' + this.interview[i])
      }
      // 把第五个加上
      if (this.interview[4]) {
        let endCode = this.interview[4][this.interview[4].length - 1]
        if (endCode !== '。' && endCode !== '.') {
          this.interview[4] += '。'
        }
        res.push(this.labels[4] + '：' + this.interview[4])
      }
      this.warn = ''
      res = res.join('')
      if (this.interviewPro) {
        // 编辑
        this.editInterview(res)
      } else {
        // 新写
        this.newInerview(res)
      }
    },
    openDialog (now) {
      // 父组件调用
      // 先处理一下interviewPro
      if (this.interviewPro) {
        this.title = '编辑面试反馈'
        let inter = this.interviewPro.situation
        if (inter.indexOf('学生基础情况') === 0) {
          for (let i = 0; i < 6; i++) {
            inter = inter.split(this.labels[i] + '：')
            if (i - 1 >= 0) {
              this.$set(this.interview, i - 1, inter[0])
            }
            inter = inter[1] || ''
          }
        } else {
          // 初始化
          this.interview = new Array(5).fill('')
        }
      } else {
        this.title = '填写面试反馈'
        this.interview = new Array(5).fill('')
      }
      this.warn = ''
      this.open = true
      this.now = now
    },
    editInterview (res) {
      this.pending = true
      MessageBox.confirm('是否提交更改', {
        beforeClose: (action, instance, done) => {
          if (action !== 'confirm') this.pending = false
          done()
        }
      }).then(() => {
        axios
          .put(`/teacher/interview/${this.interviewPro.id}`, {
            project_id: this.pid,
            situation: res
          })
          .then((response) => {
            if (response.status === 200) {
              this.$message({
                showClose: true,
                message: '更改成功',
                type: 'success'
              })
              // getstu3
              this.$emit('refresh')
              this.open = false
              this.pending = false
            }
          })
          .catch((error) => {
            this.pending = false
            console.log(error)
          })
      })
    },
    newInerview (res) {
      this.pending = true
      MessageBox.confirm('是否提交', {
        beforeClose: (action, instance, done) => {
          if (action !== 'confirm') this.pending = false
          done()
        }
      }).then(() => {
        axios
          .post(`/teacher/project/${this.pid}/interview`, {
            feedback: res,
            date: this.now
          })
          .then((response) => {
            if (response.status === 201) {
              this.$message({
                showClose: true,
                message: '提交成功',
                type: 'success'
              })
              setTimeout(() => {
                this.pending = false
                window.location.reload()
              }, 1000)
            }
          })
          .catch((error) => {
            this.pending = false
            console.log(error)
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.example {
  color: #c0c0c0;
  p {
    margin-bottom: 5px;
  }
}
.warnText {
  color: #ff0000;
  margin-top: 5px;
}
/deep/ .content {
  background-color: #fff;
  padding: 10px 15px;
  max-height: 220px;
  overflow: scroll;
  border: #c0c0c0 solid 1px;
  border-radius: 8px;
  &.active {
    border: #a0d469 solid 1px !important;
  }
  p {
    position: relative;
    span {
      position: absolute;
      z-index: 10;
    }
    textarea {
      border: none;
      border-radius: 0;
      min-height: 0px !important;
      line-height: 1.4;
      padding: 0;
      padding-bottom: 10px;
      box-sizing: content-box;
      overflow: hidden;
    }
  }
  .text1 {
    textarea {
      text-indent: 100px;
    }
  }
  .text2 {
    textarea {
      text-indent: 135px;
    }
  }
  .text3 {
    textarea {
      text-indent: 165px;
    }
  }
  .text4 {
    textarea {
      text-indent: 205px;
    }
  }
}
</style>
