<template>
  <el-main class="main">
    <div class="flex">
      <schedulelist
        @select="getstu1"
        url="/tdirector/students/not_finished"
        :options="options"
      />
      <div class="neirong">
        <div class="jtitle">
          <h2 class="jtext">辅导进度</h2>
        </div>
        <projectInfo :project="activeProject">
          <div slot="roles" class="rolesIcon">
            <img src="../../assets/roles/icon/导师.png" />
            <span>{{ activeProject.teacher || '待分配' }}</span>
            <img src="../../assets/roles/icon/助教.png" />
            <span>{{ activeProject.assistant || '待分配' }}</span>
            <img src="../../assets/roles/icon/规划师.png" />
            <span>{{ activeProject.planner }}</span>
            <img src="../../assets/roles/icon/客户经理.png" />
            <span>{{ activeProject.amanagerName || '无' }}</span>
          </div>
          <div slot="buttons">
            <informationButton :pid="pid"> </informationButton>
            <file-button :pid="pid"></file-button>
            <status-button
              ref="editStatus"
              @refresh="refresh"
              :pid="pid"
            ></status-button>
            <remark-button
              ref="editRemark"
              @refresh="refresh"
              :pid="pid"
            ></remark-button>
            <el-button
              type="primary"
              @click="sendmail"
              v-if="
                activeProject.issend === false && activeProject.assistant != ''
              "
            >
              启动邮件
            </el-button>
          </div>
          <div slot="stages">
            <p style="font-size: 14px;color: #989898;">
              置顶备注：{{ activeProject.stickyMisc || '无' || '无' }}
            </p>
            <stages
              @listentoChart="showMsgchart"
              :tableData="tableData"
              :editable="true"
              :project="activeProject"
              :interview="interview"
            ></stages>
          </div>
        </projectInfo>
      </div>
    </div>
    <startMail v-if="mail" />
    <suggestRead :project="activeProject"></suggestRead>
  </el-main>
</template>

<script>
import axios from '../axios'
import schedulelist from '../pub/scheduleList'
import projectInfo from '../pub/projectInfo'
import stages from '../pub/stages'
import fileButton from '../pub/buttons/fileButton'
import informationButton from '../pub/buttons/informationButton'
import statusButton from '../pub/buttons/statusButton'
import remarkButton from '../pub/buttons/remarkButton'
import suggestRead from '../pub/suggestRead'
import startMail from '../pub/mail/startMail'
export default {
  name: 'TDshedule',
  components: {
    schedulelist,
    projectInfo,
    stages,
    fileButton,
    informationButton,
    statusButton,
    remarkButton,
    suggestRead,
    startMail
  },
  data () {
    return {
      beizhuTitle: '',
      smes: {
        name: '',
        school: '',
        cellphone: '',
        email: '',
        direction: '',
        sex: ''
      },
      ruleform: {
        status: '',
        beizhu: ''
      },
      tableData: [],
      zhuangTitle: '',
      zhuangDialog: false,
      remarkDialog: false,
      options: ['学员', '导师', '助教', '项目', '规划师', '方向', '待发启动'],
      interview: {},
      project_type: '',
      teacher: '',
      pid: 0,
      status_id: '',
      remark_id: '',
      activeProject: {},
      beizhuDialog: false,
      mail: false
    }
  },
  methods: {
    sendmail () {
      this.mail = true
    },
    showMsgchart (data) {
      switch (data.title) {
        case '修改备注（导师不可见）':
          this.$refs.editRemark.edit(data)
          break
        case '修改状况（导师可见）':
          this.$refs.editStatus.edit(data)
          break
        case 'refresh':
          this.refresh()
          break
      }
    },
    getstu1 (smes = false) {
      let id = 0
      if (smes) {
        id = smes.project_id
      } else {
        id = this.pid
      }
      axios
        .get(`/${this.$store.state.roleApi}/project/${id}/`)
        .then(response => {
          this.smes = response.data.student
          this.teacher = response.data.teacher.name
          this.project_type = response.data.project.project_type
          this.interview = response.data.interview[0]
          this.pid = response.data.project.id
          this.tableData = response.data.stages
          this.activeProject = {
            ...response.data.project,
            salesman: response.data.sales,
            sponser: response.data.support,
            amanager: response.data.amanager,
            cmanager: response.data.cmanager
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    refresh () {
      this.getstu1()
    }
  }
}
</script>
