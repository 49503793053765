<template>
  <div>
    <el-row class="contentTitle">
      <el-col :span="22">{{ title }}</el-col>
      <el-col :span="2">
        <div
          class="downIcon el-icon-arrow-down"
          :class="{ active: active }"
          @click="active = !active"
        ></div>
      </el-col>
    </el-row>
    <transition name="file">
      <div class="fileWrapper" v-if="active" :key="2">
        <div v-for="(item, i) in data" :key="i">
          <div class="files">
            <img class="fileImg" src="../../assets/icon/MP4.png" />
            <div class="name">
              {{ item.name }}
            </div>
            <img
              width="18"
              height="18"
              src="../../assets/icon/bofang.png"
              v-if="$store.state.role === '学生'"
              @click="clicks(item)"
            />
            <el-popover
              placement="bottom-end"
              width="200"
              trigger="click"
              v-else
            >
              <div>选择有效期</div>
              <el-select v-model="days" placeholder="请选择" class="elSelect">
                <el-option
                  v-for="item in times"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  size="medium"
                  class="elOption"
                >
                </el-option>
              </el-select>
              <el-button type="primary" @click="clicks(item)">
                复制链接
              </el-button>
              <img
                width="18"
                height="18"
                slot="reference"
                src="../../assets/icon/zhuanfa.png"
              />
            </el-popover>
          </div>
        </div>
        <div v-if="data.length === 0" style="font-size:14px">暂无资料</div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'suggestReadCard',
  props: {
    data: {},
    title: String
  },
  data () {
    return {
      active: true,
      times: [
        {
          label: '3天',
          value: 3
        },
        {
          label: '7天',
          value: 7
        },
        {
          label: '15天',
          value: 15
        }
      ],
      days: 7
    }
  },
  methods: {
    clicks (item) {
      if (this.$store.state.role === '学生') {
        let routeUrl = this.$router.resolve({
          path: '/incourse',
          query: {
            stage: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      } else {
        axios
          .post(`/course/get_url/`, {
            deadline: this.days,
            stage: item.id
          })
          .then(res => {
            this.$copyText(res.data.url).then(e => {
              this.$message({
                message: '复制成功',
                type: 'success'
              })
            })
          })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.contentTitle {
  font-size: 14px;
  height: 20px;
  line-height: 20px;

  .downIcon {
    transition: 0.3s;
    transform: rotate(-90deg);
    font-size: 19px;
  }

  .active {
    transition: 0.3s;
    transform: rotate(0);
  }
}

.fileWrapper {
  margin-top: 25px;
  .files {
    margin-bottom: 15px;
    display: flex;
    width: 100%;
    align-items: center;
    .fileImg {
      width: 40px;
      height: 30px;
    }
    .name {
      font-size: 12px;
      width: 100%;
      color: #434a54;
      margin: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
  }
  > div:last-child .files {
    margin-bottom: 0;
  }
}
.el-button {
  width: 100%;
  margin-top: 10px;
}
</style>
