<template> </template>

<script>
import axios from '../axios'
export default {
  name: 'mobileView',
  created () {
    let url
    axios.get('/is_login/').then(response => {
      if (response.data.roles.indexOf('渠道') > -1) {
        this.$store.commit('setLogin', response.data)
        axios.get('/get_enterpise').then(response => {
          let topics = response.data
          this.$store.commit('set_enterpise', topics)
          url = (() => {
            switch (response.data.sort.split('')[0]) {
              case 'A':
                return '/mobile/thesis'
              case 'B':
                return '/mobile/research'
              case 'C':
                return '/mobile/rt'
              case 'D':
                return '/mobile/academic'
              case 'E':
                return '/mobile/epq'
              case 'F':
                return '/mobile/iap5'
              case 'G':
                return '/mobile/iap10'
              default:
                return '/mobile'
            }
          })()
          this.$router.push(url)
        })
      } else {
        this.$router.push('/mobile/thesis')
      }
    })
  }
}
</script>

<style></style>
