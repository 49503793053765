<template>
  <div>
    <el-table
      :span-method="arraySpanMethod"
      :data="tableData"
      class="base"
      :show-header="showHeader"
    >
      <el-table-column align="center" width="30">
        <template slot-scope="{ row }">
          <div class="img">
            <img
              src="../../assets/icon/惊叹号.png"
              v-if="row.type === 'status'"
            />
            <img
              src="../../assets/icon/备注.png"
              v-else-if="row.type === 'remark'"
            />
            <img
              src="../../assets/icon/结项.png"
              v-else-if="row.type === 'end'"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="日期" align="center" min-width="14%">
        <template slot-scope="{ row }">
          {{
            row.type === 'nextMission' || row.type === 'teacher_evaluate'
              ? ''
              : row.date
          }}
        </template>
      </el-table-column>
      <el-table-column
        label="导师辅导内容"
        align="left"
        header-align="center"
        min-width="28%"
      >
        <template slot-scope="{ row }">
          <div v-if="row.type === 'status'">
            {{ row.status }} —— {{ row.creator }}
          </div>
          <div v-else-if="row.type === 'nextMission'">
            预计下次辅导内容：{{ row.mission }}
          </div>
          <div v-else-if="row.type === 'remark'">
            <span v-if="row.first">【学生辅导感受】</span>{{ row.remark }} ——
            {{ row.creator }}
          </div>
          <div v-else-if="row.type === 'end'">
            <p v-for="item in row.chosen" :key="item" style="display: inline">
              {{ item }}，
            </p>
            <br />
            {{ row.written }}
          </div>
          <div v-else-if="row.type === 'task'">
            <div class="xietask">{{ row.teacher_work }}</div>
          </div>
          <div v-else-if="row.type === 'teacher_evaluate'">
            【学生评价】:{{ row.teacher_evaluate }}
          </div>
          <div v-else-if="row.type === 'feedback' && row.time === '2'">
            【特训营】<br />
            1.学生查找论文导读<br />
            2.论文阅读技巧讲解 <br />
            3.论文各部分结构和内容 <br />
            4.论文阅读笔记技巧讲解<br />
            5.论文中公式、图表、参考文献的使用 <br />
            6.论文的投递与查重 <br />
            【特训营反馈】<br />
            学生是否按时参加:{{ row.attend }}<br />
            学生是否按时完成任务:{{ row.finish }}<br />
            学生计划论文选题:{{ row.topic }}<br />
            评价: {{ row.note }} -- {{ row.assistant_name }}
          </div>
          <div v-else-if="row.type === 'feedback' && row.time === '1'">
            【特训营】<br />
            1.论文的基本概念<br />
            2.公开发表论文与其他类型论文的区别<br />
            3.公开发表论文的常见类型 <br />
            4.论文的查找与下载<br />
            5.论文选题常见技巧 <br />
            【特训营反馈】<br />
            学生是否按时参加:{{ row.attend }}<br />
            学生是否按时完成任务:{{ row.finish }}<br />
            学生计划论文选题:{{ row.topic }}<br />
            评价: {{ row.note }} -- {{ row.assistant_name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="work_time"
        label="辅导时间"
        align="center"
        min-width="10%"
        :formatter="timeFormatter"
        v-if="!enterpise"
      >
      </el-table-column>
      <el-table-column
        label="学生任务"
        align="left"
        header-align="center"
        min-width="28%"
      >
        <template slot-scope="{ row }">
          <div v-if="row.type === 'feedback' && row.time === '1'">
            查找并下载本专业领域同一方向学术论文（中文5篇+英文5篇）
          </div>
          <div v-else-if="row.type === 'feedback' && row.time === '2'">
            完成论文阅读笔记（中文5篇+英文5篇，并给出大致行选题思路）
          </div>
          <div v-else>{{ row.student_work }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="bearing"
        label="学生态度"
        align="center"
        min-width="9%"
        v-if="!enterpise"
      >
      </el-table-column>
      <el-table-column
        prop="quality"
        label="完成质量"
        align="center"
        min-width="9%"
        v-if="!enterpise"
      >
      </el-table-column>
      <el-table-column
        prop="deadline"
        label="Deadline"
        align="center"
        min-width="15%"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.type === 'task'">
            {{ scope.row.deadline }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="确认辅导内容" align="center" min-width="15%">
        <template slot-scope="scope">
          <div v-if="scope.row.type === 'task'">
            <div v-if="scope.row.isconfirm === true">
              <img class="isok" src="../../assets/icon/isok.png" />
            </div>
            <el-popover
              v-else-if="scope.row.feedbacks.length !== 0"
              placement="top-start"
              width="536px"
              trigger="hover"
            >
              <div class="feedwrap">
                <div
                  class="feedcontainer"
                  v-for="(item, index) in scope.row.feedbacks"
                  :key="index"
                >
                  <div class="feedleft">{{ item.date }}</div>
                  <div class="feedright">
                    <span class="feeds">【疑问反馈】</span>
                    <p class="feedp">{{ item.content }}</p>
                  </div>
                </div>
              </div>
              <img
                class="feedimg"
                slot="reference"
                src="../../assets/icon/feedbacks.png"
              />
            </el-popover>
          </div>
          <div
            v-if="
              editable &&
                (scope.row.type === 'status' ||
                  scope.row.type === 'remark' ||
                  (scope.row.type === 'feedback' &&
                    $store.state.roleApi === 'assistant'))
            "
          >
            <el-button
              class="listedit"
              @click="noteEdit(scope.$index, scope.row)"
            >
              <i class="el-icon-edit"></i>
            </el-button>
            <el-button
              class="listedit"
              @click="noteDelete(scope.$index, scope.row)"
            >
              <i class="el-icon-delete"></i>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'

export default {
  name: 'chart',
  data () {
    return {
      beizhu: {
        beizhuTitle: '',
        beizhuDialog: false,
        remark: '',
        remark_id: '',
        attend: '',
        finish: '',
        topic: '',
        note: '',
        feed_id: ''
      },
      zhuang: {
        zhuangTitle: '',
        zhuangDialog: false,
        status: '',
        status_id: ''
      },
      status_id: '',
      remark_id: '',
      feed_id: '',
      roles: [],
      a: ''
    }
  },
  props: {
    stage: Object,
    editable: Boolean,
    enterpise: {
      default: false
    }
  },
  computed: {
    showHeader () {
      return this.stage.showHeader
    },
    tableData () {
      return this.stage.tasks
    }
  },
  methods: {
    timeFormatter (row) {
      const ttime = row.work_time
      if (row.type === 'feedback') {
        return `1.5h`
      } else if (ttime) {
        if (ttime > 3) return `${ttime / 4}h`
        else return `${ttime * 15}min`
      }
      return ''
    },
    arraySpanMethod ({ rowIndex, columnIndex }) {
      const item = this.tableData[rowIndex]
      const status = item.status
      const nextmission = item.mission
      const teacher_evaluate = item.teacher_evaluate
      const remark = item.remark
      const written = item.written
      const enterpise = this.enterpise
      const type = item.type
      const end = item.chosen || item.written

      if (!!written && columnIndex === 2) return [1, 6]

      if (!!nextmission && columnIndex === 2) return [1, 6]
      if (!!status && columnIndex === 2) return [1, 6]

      if (!!end && columnIndex === 2) return [1, 7]
      if (
        !!teacher_evaluate &&
        type === 'teacher_evaluate' &&
        columnIndex === 2
      ) {
        return enterpise ? [1, 5] : [1, 6]
      }
      if (!!remark && columnIndex === 2) return [1, 6]

      if (!!written && columnIndex >= 3 && columnIndex <= 7) return [0, 0]
      if (!!nextmission && columnIndex >= 3 && columnIndex <= 7) return [0, 0]
      if (!!status && columnIndex >= 3 && columnIndex <= 7) return [0, 0]
      if (
        !!teacher_evaluate &&
        columnIndex >= 3 &&
        type === 'teacher_evaluate' &&
        columnIndex <= (enterpise ? 6 : 7)
      ) {
        return [0, 0]
      }
      if (!!remark && columnIndex >= 3 && columnIndex <= 7) return [0, 0]
      if (!!end && columnIndex >= 3 && columnIndex <= 8) return [0, 0]
    },
    noteEdit (index, row) {
      if (row.type === 'status') this.missionEdit(index, row)
      else if (row.type === 'remark') this.remarkEdit(index, row)
      else if (row.type === 'feedback') this.feedEdit(index, row)
    },
    noteDelete (index, row) {
      if (row.type === 'status') var promptInfo = '是否删除此条状况信息'
      else if (row.type === 'remark') var promptInfo = '是否删除此条备注信息'
      MessageBox.confirm(promptInfo).then(() => {
        axios
          .delete(`/${this.$store.state.roleApi}/${row.type}/${row.id}`)
          .then(res => {
            if (res.status === 200) {
              this.$message({
                showClose: true,
                message: '删除成功',
                type: 'success'
              })
              this.$emit('listentoChart', { title: 'refresh' })
            }
          })
      })
    },
    remarkEdit (index, row) {
      // title用来判断三种不同的形式
      this.beizhu.title = '修改备注（导师不可见）'
      this.beizhu.beizhuDialog = true
      this.beizhu.remark = row.remark
      this.beizhu.remark_id = row.id
      this.beizhu.first = row.first
      this.$emit('listentoChart', this.beizhu)
    },
    missionEdit (index, row) {
      this.zhuang.title = '修改状况（导师可见）'
      this.zhuang.zhuangDialog = true
      this.zhuang.status = row.status
      this.zhuang.status_id = row.id
      this.$emit('listentoChart', this.zhuang)
    }
  }
}
</script>
<style scoped>
.isok {
  width: 26px;
  height: 18px;
}
.feedbs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #f4f6f9;
}
.feedbsp {
  margin-left: 60px;
}
.feedbssp {
  margin-right: 20px;
  flex: 1;
  text-align: center;
}
.feedp {
  margin-top: 0;
  width: 300px;
  padding-left: 7px;
}
.feedcontainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: cneter;
}
.feedleft {
  width: 100px;
}
.feedright {
  float: left;
}
.feedimg {
  width: 25px;
  height: 25px;
}
.img img {
  height: 20px;
  vertical-align: top;
}
</style>
