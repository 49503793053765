<template>
  <!-- 上传终稿弹窗 -->
  <el-dialog
    title="查看论文"
    :visible.sync="active"
    width="60%"
    height="555px"
    :close-on-click-modal="true"
  >
    <el-table
      :header-cell-style="{
        background: '#F4F6F9',
        color: '#c0c0c0',
        'text-align': 'center'
      }"
      :span-method="arraySpanMethod"
      :data="toShowFile"
      ref="tableScroll"
      :cell-style="{ 'vertical-align': 'top' }"
      align="center"
    >
      <el-table-column
        key="1"
        prop="paper_title"
        label="论文题目"
        align="center"
        min-width="40%"
      >
      </el-table-column>
      <el-table-column
        key="2"
        prop="date"
        label="上传日期"
        align="center"
        min-width="20%"
      >
        <template v-slot="{ row }">
          <el-progress
            v-if="row.hasOwnProperty('percentage')"
            :percentage="row.percentage"
          />
          <div v-else>{{ formatDate(row.created) }}</div>
        </template>
      </el-table-column>
      <el-table-column
        key="3"
        prop="time"
        label="上传时间"
        align="center"
        min-width="20%"
      >
        <template v-slot="{ row }">
          <div>{{ formatTime(row.created) }}</div>
        </template>
      </el-table-column>
      <el-table-column
        key="4"
        prop="updater.name"
        label="上传者"
        align="center"
        min-width="20%"
      >
      </el-table-column>
      <el-table-column key="5" align="center" width="120px">
        <template slot-scope="scope">
          <img src="../../assets/icon/下载.png" @click="download(scope.row)" />
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <el-button @click="closeDialog" :disabled="pending">
        取 消
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from '../axios'
import moment from 'moment'
export default {
  name: 'finalDialog',
  data () {
    return {
      active: false,
      finalPaper: {},
      role: {
        sponser: '规划师',
        admin: '管理员',
        teacher: '老师',
        student: '学生'
      },
      update: [],
      pending: false,
      project_id: ''
    }
  },
  computed: {
    project_ids () {
      return Object.keys(this.finalPaper).sort((a, b) => {
        return this.finalPaper[a].length - this.finalPaper[b].length
      })
    },
    toShowFile () {
      if (this.finalPaper) {
        return Object.keys(this.finalPaper)
          .reduce((pre, cur) => {
            return pre.concat(this.finalPaper[cur])
          }, [])
          .concat(
            this.update.map(el => {
              if (el.hasOwnProperty('response')) {
                return el.response
              } else return el
            })
          )
      }
      return []
    }
  },
  methods: {
    download (item) {
      let url = item.url
      var x = new XMLHttpRequest()
      x.open('GET', url, true)
      x.responseType = 'blob'
      x.onload = function (e) {
        var url = window.URL.createObjectURL(x.response)
        var a = document.createElement('a')
        a.href = url
        a.download = item.name
        a.click()
      }
      x.send()
    },
    formatDate (data) {
      return moment(data * 1000).format('YYYY-MM-DD')
    },
    formatTime (data) {
      return moment(data * 1000).format('HH:mm')
    },
    closeDialog () {
      this.active = false
      this.update = []
    },
    openDialog (project_id) {
      this.project_id = project_id
      axios
        .get(`/${this.$store.state.roleApi}/project/${project_id}/finalPapers`)
        .then(response => {
          this.finalPaper = response.data.papers
        })
      this.active = true
    },
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (row.hasOwnProperty('percentage')) {
        if (columnIndex === 0) {
          return [1, 1]
        } else {
          return [1, 4]
        }
      }
    }
  }
}
</script>
