import { render, staticRenderFns } from "./exportImg.vue?vue&type=template&id=72ac951a&scoped=true"
import script from "./exportImg.vue?vue&type=script&lang=js"
export * from "./exportImg.vue?vue&type=script&lang=js"
import style0 from "./exportImg.vue?vue&type=style&index=0&id=72ac951a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72ac951a",
  null
  
)

export default component.exports