<template>
  <el-main class="main">
    <div class="flex">
      <channelList @select="getstu1" url="/cmanager/channel/institutions/" />
      <div class="neirong">
        <div class="jtitle">
          <h2 class="jtext">查看渠道</h2>
        </div>
        <channelInfo :tmes="tmes" />
        <div class="adviser">
          <el-button type="primary" class="export" @click="exportForm">
            导表
          </el-button>
          <el-select
            v-model="adviser"
            placeholder="销售顾问"
            @change="changeAdviser"
          >
            <el-option
              v-for="item in advisers"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <jlistgroup api="cmanager" :detail="detail" />
      </div>
    </div>
  </el-main>
</template>

<script>
/* eslint-disable no-console */
import axios from '../axios'
import channelInfo from '../pub/channelInfo'
import jlistgroup from '../pub/jlistgroup'
import channelList from '../pub/channelList'
import changerole from '../pub/changerole'
export default {
  name: 'Dviewcha',
  data () {
    return {
      qd: {
        cname: '',
        kname: ''
      },
      tmes: {
        star: '',
        name: '',
        id: '',
        organization: '',
        research_direction: '',
        cellphone: '',
        scientific_research: '',
        paper: ''
      },
      detail: {
        notfinlist: [],
        now_interview: [],
        finlist: [],
        fin_interview: [],
        teacher: {}
      },
      loading: true,
      adviser: '',
      advisers: '',
      id: ''
    }
  },
  methods: {
    exportForm () {
      window.open(
        `${axios.defaults.baseURL}/${this.$store.state.roleApi}/exportInstitution?id=${this.id}`,
        '_blank'
      )
    },
    getstu1 (id) {
      this.id = id
      this.loading = true
      axios
        .get(`/${this.$store.state.roleApi}/channel/institution/${id}/projects`)
        .then(response => {
          this.tmes = response.data.channel
          let advisers = [{ name: '全部', id: '' }]
          this.advisers = advisers.concat(response.data.channel.advisers)
          this.detail.part = response.data.part
          this.detail.now_interview = response.data.not_interviewed
          this.detail.notfinlist = response.data.not_finished
          this.detail.finlist = response.data.finished
          this.detail.fin_interview = response.data.interviewed
          this.loading = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    changeAdviser () {
      this.loading = true
      axios
        .get(`/cmanager/channel/institution/${this.id}/projects`, {
          params: {
            name: this.adviser
          }
        })
        .then(response => {
          this.detail.part = response.data.part
          this.detail.now_interview = response.data.not_interviewed
          this.detail.notfinlist = response.data.not_finished
          this.detail.finlist = response.data.finished
          this.detail.fin_interview = response.data.interviewed
          this.loading = false
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  components: {
    channelInfo,
    jlistgroup,
    channelList,
    changerole
  }
}
</script>

<style lang="scss" scoped>
.adviser {
  position: relative;
  /deep/.el-select {
    width: 220px;
    height: 40px;
    position: absolute;
    right: 30px;
    top: 15px;
    z-index: 100;
  }
  .export {
    position: absolute;
    right: 280px;
    top: 15px;
    z-index: 100;
  }
}
</style>
