<template>
  <div>
    <div v-if="visible" class="popup">
      <div class="title">
        <img
          src="../../assets/icon/shousuo.png"
          style="vertical-align: text-top;width:20px;margin-right:5px"
          @click="visible = false"
        />
        确认上架/下架并发送邮件
      </div>
      <el-table :data="teachers" class="base" ref="table">
        <el-table-column key="1" prop="name" label="导师" align="center"> </el-table-column>
        <el-table-column key="2" prop="research" label="推荐信状态" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.research"
              active-color="#7ED321"
              inactive-color="#c0c0c0"
              v-if="scope.row.research !== undefined"
              :disabled="true"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column key="3" prop="thesis" label="论文状态" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.thesis"
              active-color="#7ED321"
              inactive-color="#c0c0c0"
              v-if="scope.row.thesis !== undefined"
              :disabled="true"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column key="4" align="center">
          <template slot-scope="scope">
            <img
              src="../../assets/icon/chexiao.png"
              style="vertical-align: text-top;width:20px"
              @click="deleteOff(scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-button @click="send" type="primary" class="button" :disabled="pending">
        状态变更并发送邮件
      </el-button>
    </div>
    <div v-else class="tip">
      <img
        style="vertical-align: text-top;width:20px;margin-right:5px"
        src="../../assets/icon/kuozhan.png"
        @click="visible = true"
      />
      确认上架/下架（{{ teachers.length }}）
    </div>
  </div>
</template>

<script>
import { MessageBox } from 'element-ui'
import axios from 'axios'
export default {
  name: 'teacherOff',
  data () {
    return {
      visible: true,
      pending: false
    }
  },
  props: {
    offlist: Object
  },
  computed: {
    teachers () {
      return Object.values(this.offlist).sort((a, b) => a.sort - b.sort)
    }
  },
  watch: {
    teachers (nv, ov) {
      if (nv.length > ov.length) {
        this.$nextTick(() => {
          this.$refs.table.$refs.bodyWrapper.scrollTop = 999
        })
      }
    }
  },
  methods: {
    send () {
      MessageBox.confirm('确定发送邮件').then(() => {
        this.pending = true
        axios
          .post('DBS/status', {
            teachers: this.teachers
          })
          .then(res => {
            if (res.status === 200) {
              this.$message({
                message: '发送成功',
                type: 'success'
              })
              this.$emit('refresh')
            }
          })
      })
    },
    deleteOff (row) {
      this.$emit('deleteOff', row)
    }
  }
}
</script>

<style lang="scss" scoped>
.popup {
  width: 400px;
  position: fixed;
  right: 0;
  bottom: 0;
  background: #f5f7fa;
  box-shadow: 2px 2px 10px 2px rgba(#000, 0.3);
  border-top-left-radius: 16px;
  padding: 40px 30px;
  .title {
    line-height: 20px;
    text-align: left;
  }
}
/deep/.el-switch.is-disabled {
  opacity: 1;
}
/deep/.el-table__body-wrapper {
  height: 25vh;
  overflow-y: scroll;
  min-height: 250px;
}
.button {
  color: #ffffff;
  background-color: #a0d469;
  border-color: #a0d469;
  width: 100%;
  height: 40px;
  font-size: 14px;
  margin-top: 30px;
}
.button.is-disabled {
  color: #ffffff;
  background-color: #c0c0c0;
  border-color: #c0c0c0;
}
.tip {
  bottom: 280px;
  right: 0;
  position: fixed;
  background: #f5f7fa;
  box-shadow: 2px 2px 10px 2px rgba(#000, 0.3);
  padding: 20px 50px 20px 20px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
</style>
