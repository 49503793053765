<template>
  <div>
    <div @click="open" type="primary" class="suspend">
      <div class="el-icon-arrow-left"></div>
      <el-divider></el-divider>
      <div>相关资料</div>
    </div>
    <el-drawer :visible.sync="drawer" :with-header="false" size="343">
      <div class="wrapper">
        <el-row class="title">
          <el-col :span="22">相关资料</el-col>
          <el-col :span="2">
            <div
              class="el-icon-arrow-right"
              @click="close"
              style="cursor: pointer"
            ></div>
          </el-col>
        </el-row>
        <el-row class="reminder">
          可根据项目阶段将相关资料发送给学生学习
        </el-row>
        <el-row>
          <el-col :span="8">
            <div
              class="button"
              :class="{ videoOrFile: videoOrFile }"
              @click="videoOrFile = true"
            >
              教学视频
            </div>
          </el-col>
          <el-col :span="8">
            <div
              class="button"
              :class="{ videoOrFile: !videoOrFile }"
              @click="videoOrFile = false"
            >
              补充论文
            </div>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <div v-if="videoOrFile">
          <div class="contentWrapper" v-if="now">
            <suggestReadCard
              class="content"
              :data="now.stages"
              :title="`正在进行：${stages[now.stage].stage}`"
              v-if="videoOrFile"
            >
            </suggestReadCard>
            <suggestReadCard
              class="content"
              :data="now.extras"
              title="其他"
              v-if="now.extras.length>0"
            >
            </suggestReadCard>
          </div>
          <div
            class="contentWrapper"
            v-if="all"
            style="border-top:1px solid #c0c0c0;margin-top: 16px"
          >
            <div @click="all = false" style="text-align:center;margin-top:20px">
              <i
                class="el-icon-d-arrow-left"
                style="transform: rotate(90deg);"
              ></i>
              折叠
            </div>
            <div 
              v-for="(item, index) in readings" 
              :key="index"
              >
              <suggestReadCard
                :data="item.stages"
                :title="stages[item.stage].stage"
                class="content"
              >
              </suggestReadCard>
              <suggestReadCard
                class="content"
                :data="item.extras"
                title="其他"
                v-if="item.extras.length>0"
              >
              </suggestReadCard>
            </div>
          </div>
          <div
            v-else
            @click="all = true"
            style="text-align:center;margin-top:20px"
          >
            <i
              class="el-icon-d-arrow-left"
              style="transform: rotate(-90deg);"
            ></i>
            查看其他阶段
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import axios from '../axios'
import suggestReadCard from './suggestReadCard'
import stageType from './stageType.json'
import stagesList from './stagesList.json'
export default {
  name: 'suggestRead',
  components: {
    suggestReadCard
  },
  data () {
    return {
      drawer: false,
      videoOrFile: true,
      readings: [],
      now: undefined,
      all: false
    }
  },
  props: {
    project: Object
  },
  computed: {
    stages () {
      return stagesList[stageType[this.project.project_type] || '基础']
    }
  },
  methods: {
    open () {
      this.all = false
      this.now = undefined
      const stageLength =
        this.$store.state.roleApi === 'student'
          ? this.project.stage + 1
          : this.stages.length - 1
      axios
        .get(
          `${this.$store.state.roleApi}/project/${this.project.id}/readings`,
        {
          params: {
            stageLength: stageLength
          }
        }
        )
        .then(res => {
          this.readings = res.data.readings.filter(e => {
            if (e.stage === this.project.stage) {
              this.now = e
            } else {
              return e
            }
          })
        })
      this.drawer = true
    },
    close () {
      this.drawer = false
    }
  }
}
</script>

<!--<style>-->
<!--::-webkit-scrollbar {-->
<!--  background-color: red !important;-->
<!--/*//background-color: #F0F2F5;*/-->
<!--}-->
<!--</style>-->
<style scoped lang="scss">
/deep/.el-drawer {
  background-color: #f0f2f5;
}
.wrapper {
  background-color: #f0f2f5;
  color: #656d78;
  padding: 40px 20px 20px 20px;
  height: 100%;
  text-align: left;
  width: 375px;
  .reminder{
    font-size: 14px;
    line-height: 18px;
    height: 18px;
    color: #c0c0c0;
    margin-bottom: 10px;
  }
  .title {
    padding: 0 2px;
    font-size: 20px;
    line-height: 28px;
    height: 28px;
    margin-bottom: 10px;
  }

  .button {
    font-size: 14px;
    width: fit-content;
    height: 23px;
    text-align: center;
    border-bottom: 1px transparent solid;
    cursor: pointer;
  }
  .videoOrFile {
    color: #a1d46a;
    border-color: #a1d46a;
  }

  .contentWrapper {
    .content {
      padding: 20px 20px;
      background-color: #fff;
      margin-top: 16px;
      border-radius: 4px;
    }
  }
}
</style>
