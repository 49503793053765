<template>
  <el-dialog :title="dialogTitle" :visible="true" :close-on-click-modal="false">
    <div v-if="step === -1">
      <div>
        已发送推荐信邮件，内容如下：
      </div>
      <p>学生姓名：{{ student.name }}</p>
      <p>学生性别：{{ student.sex }}</p>
      <p>导师姓名：{{ teacher.name }}</p>
      <p>导师身份：{{ teacher.misc }}</p>
      <p>项目类型：{{ project.project_type }}</p>
      <p>deadline：{{ recommendation.deadline }}</p>
      <p>备注：{{ recommendation.misc }}</p>
      <div style="border-top:solid 1px #C0C0C0;padding: 10px 0">
        <div v-if="recommendation.status !== 2">是否要再次发送推荐信邮件？</div>
        <div v-else style="color:red">
          该项目已出推荐信，不支持更新推荐信需求。如有新情况，请与翻译沟通。
        </div>
      </div>
    </div>
    <div v-else-if="step === 0">
      <p>学生是否完成项目？</p>
      <el-radio-group v-model="finish">
        <el-radio :label="true">是</el-radio>
        <el-radio :label="false">否</el-radio>
      </el-radio-group>
    </div>
    <div v-else-if="step === 1">
      <el-row>
        <el-select v-model="mailType">
          <el-option v-for="item in types" :key="item" :value="item">
          </el-option>
        </el-select>
        <span>-{{ this.student.name }}</span>
      </el-row>
      <p>学生姓名：{{ this.student.name }}</p>
      <p>学生性别：{{ this.student.sex }}</p>
      <p>导师姓名：{{ this.teacher.name }}</p>
      <p>导师身份：<el-input v-model="teacher.misc"></el-input></p>
      <p>项目类型：{{ project.project_type }}</p>
      <p style="color:red" v-if="!finish">项目未完成</p>
      <p style="position:relative">
        deadline*：
        <el-date-picker
          v-model="deadline"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </p>
      <p>
        备注：<el-input v-model="misc" type="textarea" :rows="6"></el-input>
      </p>
    </div>
    <div v-else-if="step === 2">
      <p>{{ title }}</p>
      <div v-html="content"></div>
    </div>
    <div slot="footer">
      <el-button @click="close">{{ step === 0 ? '取消' : '返回' }}</el-button>
      <el-button
        type="primary"
        @click="next"
        :disabled="pending"
        v-if="!(step === -1 && recommendation.status === 2)"
      >
        {{
          step === -1
            ? '确认'
            : step === 0
            ? '下一步'
            : step === 1
            ? '预览'
            : '发送'
        }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import axios from 'axios'
import { MessageBox } from 'element-ui'
export default {
  name: 'recommendationMail',
  data () {
    return {
      pending: false,
      finish: '',
      step: 0,
      types: ['【推荐信翻译】', '加急 【推荐信翻译】'],
      mailType: '【推荐信翻译】',
      student: {},
      project: {},
      deadline: '',
      misc: '',
      teacher: {},
      dialogTitle: '推荐信邮件',
      pickerOptions: {
        disabledDate (time) {
          const curDate = new Date().getTime()
          const one = 24 * 3600 * 1000
          const now = curDate - one
          return time.getTime() <= now
        }
      },
      recommendation: {}
    }
  },
  created () {
    this.student = this.$parent.$parent.smes
    this.project = { ...this.project, ...this.$parent.$parent.activeProject }
    axios
      .get(`/${this.$store.state.roleApi}/getMailInfo`, {
        params: {
          id: this.project.id
        }
      })
      .then(response => {
        let project = response.data.project
        this.teacher = project.teacher
        // this.student = project.student
        this.recommendation = response.data.recommendation
        if (Object.keys(this.recommendation).length > 0) {
          this.step = -1
        }
      })
  },
  methods: {
    async next () {
      this.pending = true
      if (this.step === 2) {
        MessageBox.confirm('确定发送？')
          .then(() => {
            axios
              .post(`/${this.$store.state.roleApi}/sendMail`, {
                id: this.project.id,
                title: this.title,
                content: this.content,
                finish: this.finish,
                identity: this.teacher.misc,
                deadline: this.deadline,
                misc: this.misc
              })
              .then(response => {
                if (response.status === 200) {
                  this.$message({
                    showClose: true,
                    message: `发送成功`,
                    type: 'success'
                  })
                  this.$parent.$parent.mail = false
                  this.$parent.$parent.getstu2()
                }
              })
          })
          .catch(() => {
            this.pending = false
          })
      }
      if (this.step === 1) {
        if (this.deadline === '') {
          this.$message({
            showClose: true,
            message: '请选择deadline',
            type: 'warning'
          })
          this.pending = false
          return 0
        }
        this.title = `${this.mailType}-${this.student.name}`
        this.content = `
        <p>学生姓名：${this.student.name}</p>
        <p>学生性别：${this.student.sex}</p>
        <p>导师姓名：${this.teacher.name}</p>
        <p>导师身份：${this.teacher.misc}</p>
        <p>项目类型：${this.project.project_type}</p>
        ${this.finish ? '' : `<p style="color:red">项目未完成</p>`}
        <p>deadline：${this.deadline}</p>
        ${this.misc !== '' ? `<p>备注：<br />${this.misc}` : ''}</p>
        `
        this.step = 2
        this.pending = false
      }
      if (this.step === 0) {
        this.step = 1
        if (this.finish) {
          this.dialogTitle = '推荐信邮件（to陈奕竹）'
        } else {
          this.dialogTitle = '推荐信邮件（to陈奕竹&李林子）'
        }
        this.pending = false
      }
      if (this.step === -1) {
        this.step = 0
        this.pending = false
      }
    },
    close () {
      if (this.step <= 0) this.$parent.$parent.mail = false
      this.step--
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body {
  text-align: left;
  color: #656d78;
  font-size: 14px;
}
/deep/.el-input__inner {
  color: #656d78;
}
p {
  position: relative;
  line-height: 30px;
}
.el-input {
  width: calc(100% - 70px);
  position: absolute;
  right: 0;
}
/deep/.el-input__prefix {
  left: 0px;
  .el-input__icon {
    line-height: 30px;
  }
}
</style>
