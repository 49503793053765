<template>
  <el-form :model="smes" :rules="rules" ref="smes">
    <div>
      <div v-if="step !== 1">
        <div class="project_type">学生来源信息</div>
        <el-row>
          <el-col style="margin-bottom: 10px; height: 60px">
            <div class="title">渠道</div>
            <div class="form-item-error">
              <el-form-item
                prop="project_data.channel"
                :rules="{
                  validator: channelCheck,
                  trigger: 'change',
                  required: true
                }"
              >
                <channelSearchBar
                  :type.sync="smes.project_data.channel_type"
                  :select.sync="smes.project_data.channel"
                  :cmanager.sync="smes.project_data.cmanager"
                  :amanager.sync="smes.project_data.amanager"
                  @validateChannel="validateChannel"
                />
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row class="top">
          <el-col
            :span="12"
            v-if="smes.project_data.channel_type.indexOf('own') === -1"
          >
            <el-form-item prop="project_data.cmanager">
              <div class="title">渠道经理*</div>
              <el-select
                placeholder="渠道经理"
                v-model="smes.project_data.cmanager"
                class="input saleselect"
                :disabled="
                  smes.project_data.channel_type.indexOf('own') !== -1
                    ? false
                    : true
                "
              >
                <el-option
                  v-for="item in cmanagerlist"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :span="12"
            v-if="smes.project_data.channel_type.indexOf('inst') !== -1"
          >
            <el-form-item prop="project_data.amanager">
              <div class="title">客户经理*</div>
              <el-select
                placeholder="客户经理"
                v-model="smes.project_data.amanager"
                class="input saleselect"
                :disabled="true"
              >
                <el-option
                  v-for="item in amanagerlist"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="project_data.sponser"
              :rules="{
                required: true,
                message: '请选择规划师',
                trigger: 'change'
              }"
            >
              <div class="title">规划师*</div>
              <el-select
                placeholder="规划师"
                v-model="smes.project_data.sponser"
                class="input saleselect"
              >
                <el-option
                  v-for="item in plannerlist"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div v-if="step !== 2">
        <div class="project_type">学员基本信息</div>
        <el-row class="top">
          <el-col :span="12">
            <el-form-item prop="name">
              <div class="title">姓名*</div>
              <el-input
                placeholder="姓名"
                v-model.trim="smes.name"
                maxlength="16"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="username">
              <div class="title">用户名*</div>
              <el-input
                placeholder="用户名"
                v-model="smes.username"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="sex">
              <div class="title">性别*</div>
              <el-input
                placeholder="性别"
                v-model="smes.sex"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="phone">
              <div class="title">手机*</div>
              <el-input
                placeholder="手机"
                maxlength="64"
                v-model="smes.phone"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <div class="title">微信</div>
              <el-input
                placeholder="微信"
                maxlength="32"
                v-model="smes.wechat"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <div class="title">邮箱</div>
              <el-input
                placeholder="邮箱"
                maxlength="64"
                v-model="smes.email"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <div class="title">身份证号码</div>
              <el-input
                placeholder="身份证号码"
                maxlength="32"
                v-model="smes.identification"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <div class="title">联系地址</div>
              <el-input
                placeholder="联系地址"
                maxlength="64"
                v-model="smes.address"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <div class="title">申请国家/地区</div>
              <el-input
                placeholder="申请国家/地区"
                maxlength="64"
                v-model="smes.country"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <div class="title">未来打算就读专业</div>
              <el-input
                placeholder="未来打算就读专业"
                maxlength="64"
                v-model="smes.planedSpecialty"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div class="title">下一学历入学时间*</div>
            <el-form-item
              class="schoolStartTimeInput"
              prop="schoolStartTime"
              :rules="{ validator: schoolStartTimeYearPass, trigger: 'blur' }"
            >
              <el-input
                class="input"
                v-model="schoolStartTimeYear"
                maxlength="4"
              ></el-input>
            </el-form-item>
            <span class="schoolStartTimeText">&ensp;年&ensp;</span>
            <el-form-item
              class="schoolStartTimeInput"
              prop="schoolStartTime"
              :rules="{ validator: schoolStartTimeMonthPass, trigger: 'blur' }"
            >
              <el-input
                class="input"
                v-model="schoolStartTimeMonth"
                maxlength="2"
              ></el-input>
            </el-form-item>
            <span class="schoolStartTimeText">&ensp;月</span>
          </el-col>
        </el-row>
        <addform
          v-model="smes.educationMes"
          v-slot="{ item: educationMesItem, index }"
          title="学员教育信息"
          :default="1"
          :editAble="true"
        >
          <el-row>
            <el-col :span="12" class="inputleft">
              <div class="title">入学日期*</div>
              <el-form-item
                class="schoolStartTimeInput"
                :prop="'educationMes.' + index + '.schoolStartTimeYear'"
                :rules="{
                  validator: educationMesSchoolStartTimeYearPass,
                  trigger: 'blur'
                }"
              >
                <el-input
                  class="input"
                  v-model="educationMes[index].schoolStartTimeYear"
                  maxlength="4"
                  @input="
                    educationMes[index].schoolStartTimeYear = educationMes[
                      index
                    ].schoolStartTimeYear.replace(/[^\d]/g, '')
                    updateSmesEducationMes(index)
                  "
                ></el-input>
              </el-form-item>
              <span class="schoolStartTimeText">&ensp;年&ensp;</span>
              <el-form-item
                class="schoolStartTimeInput"
                :prop="'educationMes.' + index + '.schoolStartTime'"
                :rules="{
                  validator: educationMesSchoolStartTimeMonthPass,
                  trigger: 'blur'
                }"
              >
                <el-input
                  class="input"
                  v-model="educationMes[index].schoolStartTimeMonth"
                  maxlength="2"
                  @input="
                    educationMes[index].schoolStartTimeMonth = educationMes[
                      index
                    ].schoolStartTimeMonth.replace(/[^\d]/g, '')
                    updateSmesEducationMes(index)
                  "
                ></el-input>
              </el-form-item>
              <span class="schoolStartTimeText">&ensp;月</span>
            </el-col>
            <el-col :span="12" class="inputright">
              <el-form-item
                :prop="'educationMes.' + index + '.school'"
                :rules="{
                  required: true,
                  message: '请输入学校',
                  trigger: 'blur'
                }"
              >
                <div class="title">学校*</div>
                <el-input
                  placeholder="学校"
                  class="input"
                  v-model="educationMesItem.school"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" class="inputleft">
              <el-form-item
                :prop="'educationMes.' + index + '.specialty'"
                :rules="{
                  required: true,
                  message: '请输入专业方向',
                  trigger: 'blur'
                }"
              >
                <div class="title">专业方向*</div>
                <el-input
                  placeholder="专业方向"
                  class="input"
                  v-model="educationMesItem.specialty"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputright">
              <el-form-item>
                <div class="title">绩点成绩</div>
                <el-input
                  placeholder="绩点成绩"
                  class="input"
                  v-model="educationMesItem.score"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </addform>
        <addform
          v-model="smes.activityMes"
          v-slot="{ item: activityMesItem, index }"
          title="学员活动信息"
          :default="0"
          :editAble="true"
        >
          <el-row>
            <el-col :span="12" class="inputleft">
              <el-form-item>
                <div class="title">组织/活动</div>
                <el-input
                  placeholder="组织/活动"
                  class="input"
                  v-model="activityMesItem.activity"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputright">
              <el-form-item
                class="startEndDateInput"
                :prop="'activityMes.' + index + '.startEndDate0'"
                :rules="{
                  validator: activityMesStartEndDatePass1,
                  trigger: 'blur'
                }"
              >
                <div class="title">起止日期</div>
                <el-input
                  class="input"
                  v-model="activityMes[index].startEndDate[0]"
                  maxlength="4"
                  @input="
                    activityMes[index].startEndDate[0] = activityMes[
                      index
                    ].startEndDate[0].replace(/[^\d]/g, '')
                    updateSmesActivityMes(index)
                  "
                ></el-input>
              </el-form-item>
              <span>&ensp;年&ensp;</span>
              <el-form-item
                class="startEndDateInput"
                :prop="'activityMes.' + index + '.startEndDate1'"
                :rules="{
                  validator: activityMesStartEndDatePass2,
                  trigger: 'blur'
                }"
              >
                <el-input
                  class="input"
                  v-model="activityMes[index].startEndDate[1]"
                  maxlength="2"
                  @input="
                    activityMes[index].startEndDate[1] = activityMes[
                      index
                    ].startEndDate[1].replace(/[^\d]/g, '')
                    updateSmesActivityMes(index)
                  "
                ></el-input>
              </el-form-item>
              <span>&ensp;月&emsp;—&emsp;</span>
              <el-form-item class="startEndDateInput">
                <el-input
                  class="input"
                  v-model="activityMes[index].startEndDate[2]"
                  maxlength="4"
                  @input="
                    activityMes[index].startEndDate[2] = activityMes[
                      index
                    ].startEndDate[2].replace(/[^\d]/g, '')
                    updateSmesActivityMes(index)
                  "
                ></el-input>
              </el-form-item>
              <span>&ensp;年&ensp;</span>
              <el-form-item class="startEndDateInput">
                <el-input
                  class="input"
                  v-model="activityMes[index].startEndDate[3]"
                  maxlength="2"
                  @input="
                    activityMes[index].startEndDate[3] = activityMes[
                      index
                    ].startEndDate[3].replace(/[^\d]/g, '')
                    updateSmesActivityMes(index)
                  "
                ></el-input>
              </el-form-item>
              <span>&ensp;月</span>
            </el-col>
            <el-col :span="12" class="inputleft">
              <el-form-item>
                <div class="title">职位</div>
                <el-input
                  placeholder="职位"
                  class="input"
                  v-model="activityMesItem.position"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputright">
              <el-form-item>
                <div class="title">担任职责</div>
                <el-input
                  placeholder="担任职责"
                  class="input"
                  v-model="activityMesItem.duty"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </addform>
        <addform
          v-model="smes.scienceMes"
          v-slot="{ item: scienceMesItem, index }"
          title="学员学术背景"
          :default="0"
          :editAble="true"
        >
          <el-row>
            <el-col :span="12" class="inputleft">
              <el-form-item>
                <div class="title">项目/题目</div>
                <el-input
                  placeholder="项目/题目"
                  class="input"
                  v-model="scienceMesItem.project"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputright">
              <el-form-item
                class="startEndDateInput"
                :prop="'scienceMes.' + index + '.startEndDate0'"
                :rules="{
                  validator: scienceMesStartEndDatePass1,
                  trigger: 'blur'
                }"
              >
                <div class="title">起止日期</div>
                <el-input
                  class="input"
                  v-model="scienceMes[index].startEndDate[0]"
                  maxlength="4"
                  @input="
                    scienceMes[index].startEndDate[0] = scienceMes[
                      index
                    ].startEndDate[0].replace(/[^\d]/g, '')
                    updateScienceMes(index)
                  "
                ></el-input>
              </el-form-item>
              <span>&ensp;年&ensp;</span>
              <el-form-item
                class="startEndDateInput"
                :prop="'scienceMes.' + index + '.startEndDate1'"
                :rules="{
                  validator: scienceMesStartEndDatePass2,
                  trigger: 'blur'
                }"
              >
                <el-input
                  class="input"
                  v-model="scienceMes[index].startEndDate[1]"
                  maxlength="2"
                  @input="
                    scienceMes[index].startEndDate[1] = scienceMes[
                      index
                    ].startEndDate[1].replace(/[^\d]/g, '')
                    updateScienceMes(index)
                  "
                ></el-input>
              </el-form-item>
              <span>&ensp;月&emsp;—&emsp;</span>
              <el-form-item class="startEndDateInput">
                <el-input
                  class="input"
                  v-model="scienceMes[index].startEndDate[2]"
                  maxlength="4"
                  @input="
                    scienceMes[index].startEndDate[2] = scienceMes[
                      index
                    ].startEndDate[2].replace(/[^\d]/g, '')
                    updateScienceMes(index)
                  "
                ></el-input>
              </el-form-item>
              <span>&ensp;年&ensp;</span>
              <el-form-item class="startEndDateInput">
                <el-input
                  class="input"
                  v-model="scienceMes[index].startEndDate[3]"
                  maxlength="2"
                  @input="
                    scienceMes[index].startEndDate[3] = scienceMes[
                      index
                    ].startEndDate[3].replace(/[^\d]/g, '')
                    updateScienceMes(index)
                  "
                ></el-input>
              </el-form-item>
              <span>&ensp;月</span>
            </el-col>
            <el-col :span="12" class="inputleft">
              <el-form-item>
                <div class="title">内容</div>
                <el-input
                  placeholder="内容"
                  class="input"
                  v-model="scienceMesItem.content"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputright">
              <el-form-item>
                <div class="title">成果</div>
                <el-input
                  placeholder="成果"
                  class="input"
                  v-model="scienceMesItem.achievement"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </addform>
        <el-row type="flex">
          <span class="project_type" style="margin-right: 10px"
            >学员外语能力</span
          >
          <img
            class="arrow"
            src="@/assets/icon/tran.png"
            height="20"
            width="20"
            :style="{
              transform: languageOpen ? 'rotate(0deg)' : 'rotate(-90deg)'
            }"
            @click="languageOpen = !languageOpen"
          />
        </el-row>
        <el-row v-show="languageOpen" justify="space-between" type="flex">
          <el-col :span="3">
            <el-form-item>
              <div class="title">四级</div>
              <el-input
                placeholder="四级"
                maxlength="64"
                v-model="smes.language.four"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <div class="title">六级</div>
              <el-input
                placeholder="六级"
                maxlength="64"
                v-model="smes.language.six"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <div class="title">TOEFL</div>
              <el-input
                placeholder="TOEFL"
                maxlength="64"
                v-model="smes.language.toefl"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <div class="title">IELTES</div>
              <el-input
                placeholder="IELTES"
                maxlength="64"
                v-model="smes.language.ieltes"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <div class="title">GRE</div>
              <el-input
                placeholder="GRE"
                maxlength="64"
                v-model="smes.language.gre"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <div class="title">GMAT</div>
              <el-input
                placeholder="GMAT"
                maxlength="64"
                v-model="smes.language.gmat"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <div class="title">其他</div>
              <el-input
                placeholder="其他"
                maxlength="64"
                v-model="smes.language.other"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <span class="project_type" style="margin-right: 10px"
            >学员计算机能力</span
          >
          <img
            class="arrow"
            src="@/assets/icon/tran.png"
            height="20"
            width="20"
            :style="{
              transform: computerOpen ? 'rotate(0deg)' : 'rotate(-90deg)'
            }"
            @click="computerOpen = !computerOpen"
          />
        </el-row>
        <el-input
          class="smesTextarea"
          type="textarea"
          v-model="smes.computerMes"
          v-show="computerOpen"
          rows="3"
          style="resize: none"
          placeholder="学员计算机能力"
        ></el-input>
        <el-row>
          <el-form-item>
            <div class="title">其他信息备注</div>
            <el-input
              placeholder="学生的学术背景情况、沟通态度及学习积极性等情况备注，其他信息备注仅作记录使用，不会出现在邮件中"
              class="input"
              type="textarea"
              v-model="smes.misc"
            ></el-input>
          </el-form-item>
        </el-row>
      </div>
    </div>
  </el-form>
</template>

<script>
import addform from '../form/addform'
import channelSearchBar from '../channelSearchBar'
import axios from '../../axios'
export default {
  name: 'studentform',
  props: {
    smes: Object,
    step: Number
  },
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入性别'))
      } else if (value === '男' || value === '女') {
        callback()
      } else {
        callback(new Error('请输入正确字符，“男”或“女”'))
      }
    }
    return {
      rules: {
        username: [
          { required: true, message: '请填写用户名', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
        direction: [{ required: true, message: '请填写方向', trigger: 'blur' }],
        phone: [{ required: true, message: '请填写电话号码', trigger: 'blur' }],
        sex: [{ validator: validatePass, trigger: 'blur' }],
        schoolStartTime: [
          {
            required: true,
            message: '请选择下一学历入学时间',
            trigger: 'blur'
          }
        ]
      },
      languageOpen: false,
      computerOpen: false,
      cmanagerlist: [],
      amanagerlist: [],
      plannerlist: []
    }
  },
  created () {
    axios.get(`/planner/sponser_list?role=cmanager`).then(response => {
      this.cmanagerlist = response.data.supports
    })
    axios.get(`/planner/sponser_list?role=amanager`).then(response => {
      this.amanagerlist = response.data.supports
    })
    axios.get(`/planner/sponser_list?role=planner`).then(response => {
      this.plannerlist = response.data.supports
    })
  },
  computed: {
    schoolStartTimeYear: {
      get () {
        let smesCopy = JSON.parse(JSON.stringify(this.smes))
        if (
          smesCopy.schoolStartTime == '' ||
          smesCopy.schoolStartTime == undefined
        ) {
          smesCopy.schoolStartTime = '-'
        }
        return smesCopy.schoolStartTime.split('-')[0]
      },
      set (value) {
        let arr = this.smes.schoolStartTime.split('-')
        arr[0] = value
        this.smes.schoolStartTime = arr.join('-')
      }
    },
    schoolStartTimeMonth: {
      get () {
        let smesCopy = JSON.parse(JSON.stringify(this.smes))
        if (
          smesCopy.schoolStartTime == '' ||
          smesCopy.schoolStartTime == undefined
        ) {
          smesCopy.schoolStartTime = '-'
        }
        return smesCopy.schoolStartTime.split('-')[1]
      },
      set (value) {
        let arr = this.smes.schoolStartTime.split('-')
        arr[1] = value
        this.smes.schoolStartTime = arr.join('-')
      }
    },
    educationMes: {
      get () {
        return JSON.parse(JSON.stringify(this.smes.educationMes)).map(item => {
          if (item.schoolStartTime == undefined || item.schoolStartTime == '') {
            item.schoolStartTime = '-'
          }
          item.schoolStartTimeYear = item.schoolStartTime.split('-')[0]
          item.schoolStartTimeMonth = item.schoolStartTime.split('-')[1]
          return item
        })
      }
    },
    activityMes: {
      get () {
        return JSON.parse(JSON.stringify(this.smes.activityMes)).map(item => {
          if (item.startEndDate == undefined || item.startEndDate.length == 0) {
            item.startEndDate = ['-', '-']
          }
          let arr = []
          arr[0] = item.startEndDate[0].split('-')[0]
          arr[1] = item.startEndDate[0].split('-')[1]
          arr[2] = item.startEndDate[1].split('-')[0]
          arr[3] = item.startEndDate[1].split('-')[1]
          item.startEndDate = arr
          return item
        })
      }
    },
    scienceMes: {
      get () {
        return JSON.parse(JSON.stringify(this.smes.scienceMes)).map(item => {
          if (item.startEndDate == undefined || item.startEndDate.length == 0) {
            item.startEndDate = ['-', '-']
          }
          let arr = []
          arr[0] = item.startEndDate[0].split('-')[0]
          arr[1] = item.startEndDate[0].split('-')[1]
          arr[2] = item.startEndDate[1].split('-')[0]
          arr[3] = item.startEndDate[1].split('-')[1]
          item.startEndDate = arr
          return item
        })
      }
    }
  },
  methods: {
    validateChannel () {
      this.$refs.smes.validateField('channel')
    },
    channelCheck (rule, value, callback) {
      if (
        this.smes.project_data.channel_type === 'own' ||
        this.smes.project_data.channel !== ''
      ) {
        callback()
      } else {
        callback(new Error('请选择渠道'))
      }
    },
    closeDialog () {
      this.$emit('reset')
    },
    check (fun) {
      this.$refs.smes.validate(fun)
    },
    updateSmesEducationMes (index) {
      this.smes.educationMes[index].schoolStartTime =
        this.educationMes[index].schoolStartTimeYear +
        '-' +
        this.educationMes[index].schoolStartTimeMonth
      this.$set(this.smes.educationMes, index, this.smes.educationMes[index])
    },
    updateSmesActivityMes (index) {
      let start =
        this.activityMes[index].startEndDate[0] +
        '-' +
        this.activityMes[index].startEndDate[1]
      let end =
        this.activityMes[index].startEndDate[2] +
        '-' +
        this.activityMes[index].startEndDate[3]
      this.smes.activityMes[index].startEndDate = [start, end]
      this.$set(this.smes.activityMes, index, this.smes.activityMes[index])
    },
    updateScienceMes (index) {
      let start =
        this.scienceMes[index].startEndDate[0] +
        '-' +
        this.scienceMes[index].startEndDate[1]
      let end =
        this.scienceMes[index].startEndDate[2] +
        '-' +
        this.scienceMes[index].startEndDate[3]
      this.smes.scienceMes[index].startEndDate = [start, end]
      this.$set(this.smes.scienceMes, index, this.smes.scienceMes[index])
    },
    schoolStartTimeYearPass (rule, value, callback) {
      let arr = this.smes.schoolStartTime.split('-')
      if (arr[0] == '' || arr[0] == undefined) {
        callback(new Error('请输入年份'))
      } else {
        callback()
      }
    },
    schoolStartTimeMonthPass (rule, value, callback) {
      let arr = this.smes.schoolStartTime.split('-')
      if (arr[1] == '' || arr[1] == undefined) {
        callback(new Error('请输入月份'))
      } else {
        callback()
      }
    },
    educationMesSchoolStartTimeYearPass (rule, value, callback) {
      let index = Number(rule.field.split('.')[1])
      let schoolStartTime = this.smes.educationMes[index].schoolStartTime
      if (schoolStartTime == undefined || schoolStartTime == '') {
        callback(new Error('请输入年份'))
      } else {
        let year = schoolStartTime.split('-')[0]
        if (year == '' || year == undefined) {
          callback(new Error('请输入年份'))
        } else {
          callback()
        }
      }
    },
    educationMesSchoolStartTimeMonthPass (rule, value, callback) {
      let index = Number(rule.field.split('.')[1])
      let schoolStartTime = this.smes.educationMes[index].schoolStartTime
      if (schoolStartTime == undefined || schoolStartTime == '') {
        callback(new Error('请输入月份'))
      } else {
        let month = schoolStartTime.split('-')[1]
        if (month == '' || month == undefined) {
          callback(new Error('请输入月份'))
        } else {
          callback()
        }
      }
    },
    activityMesStartEndDatePass1 (rule, value, callback) {
      let index = Number(rule.field.split('.')[1])
      let startEndDate = this.smes.activityMes[index].startEndDate
      let year = this.activityMes[index].startEndDate[0]
      if (
        startEndDate == undefined ||
        startEndDate.length == 0 ||
        (year != '' && year != undefined)
      ) {
        callback()
      } else if (
        startEndDate[1].split('-')[0] == '' ||
        startEndDate[1].split('-')[1] == ''
      ) {
        callback()
      } else {
        callback(new Error('请输入起始日期'))
      }
    },
    activityMesStartEndDatePass2 (rule, value, callback) {
      let index = Number(rule.field.split('.')[1])
      let startEndDate = this.smes.activityMes[index].startEndDate
      let month = this.activityMes[index].startEndDate[1]
      if (
        startEndDate == undefined ||
        startEndDate.length == 0 ||
        (month != '' && month != undefined)
      ) {
        callback()
      } else if (
        startEndDate[1].split('-')[0] == '' ||
        startEndDate[1].split('-')[1] == ''
      ) {
        callback()
      } else {
        callback(new Error('请输入起始日期'))
      }
    },
    scienceMesStartEndDatePass1 (rule, value, callback) {
      let index = Number(rule.field.split('.')[1])
      let startEndDate = this.smes.scienceMes[index].startEndDate
      let year = this.scienceMes[index].startEndDate[0]
      if (
        startEndDate == undefined ||
        startEndDate.length == 0 ||
        (year != '' && year != undefined)
      ) {
        callback()
      } else if (
        startEndDate[1].split('-')[0] == '' ||
        startEndDate[1].split('-')[1] == ''
      ) {
        callback()
      } else {
        callback(new Error('请输入起始日期'))
      }
    },
    scienceMesStartEndDatePass2 (rule, value, callback) {
      let index = Number(rule.field.split('.')[1])
      let startEndDate = this.smes.scienceMes[index].startEndDate
      let month = this.scienceMes[index].startEndDate[1]
      if (
        startEndDate == undefined ||
        startEndDate.length == 0 ||
        (month != '' && month != undefined)
      ) {
        callback()
      } else if (
        startEndDate[1].split('-')[0] == '' ||
        startEndDate[1].split('-')[1] == ''
      ) {
        callback()
      } else {
        callback(new Error('请输入起始日期'))
      }
    }
  },
  components: {
    addform,
    channelSearchBar
  }
}
</script>

<style lang="scss" scoped>
@import './style.scss';
.form-item-error {
  /deep/.el-form-item__error {
    left: 40vh;
  }
}
/deep/.el-textarea__inner{
  min-height: 100px !important;
}
</style>
