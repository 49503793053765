<template>
  <div class="aside">
    <changerole></changerole>
    <el-menu router @select="handleSelect">
      <el-menu-item
        :class="$route.path == '/D/Dinterview' ? 'is-active' : ''"
        index="/D/Dinterview"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">面试<br />学员</div>
          <img class="iconmenu" src="../../assets/icon/面试学员@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/D/Dschedule' ? 'is-active' : ''"
        index="/D/Dschedule"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">学员<br />进度</div>
          <img class="iconmenu" src="../../assets/icon/学员进度@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/D/Dstudent' ? 'is-active' : ''"
        index="/D/Dstudent"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">结项<br />学员</div>
          <img class="iconmenu" src="../../assets/icon/结项学员@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/D/Dviewcha' ? 'is-active' : ''"
        index="/D/Dviewcha"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">查看<br />渠道</div>
          <img class="iconmenu" src="../../assets/icon/查看渠道.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/D/Dchannel' ? 'is-active' : ''"
        index="/D/Dchannel"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">渠道<br />信息</div>
          <img class="iconmenu" src="../../assets/icon/渠道@3x.png" />
        </el-tooltip>
      </el-menu-item>
    </el-menu>
    <div class="back">
      <img
        src="../../assets/icon/退出.png"
        @click="logout"
        height="20px"
        width="20px"
      />
    </div>
  </div>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'
import changerole from '../pub/changerole'
export default {
  name: 'dnav',
  components: { changerole },
  methods: {
    handleSelect (key, keyPath) {
      this.$router.replace({ path: `${keyPath}` })
    },
    logout () {
      MessageBox.confirm('是否注销？')
        .then(() => {
          axios.delete('/logout/').then(() => {
            localStorage.removeItem('Authorization')
            this.$router.replace('/login')
          })
        })
        .catch(() => {})
    }
  }
}
</script>
