<template>
  <div>
    <el-row class="jstumessage">
      <el-col :span="8">
        <el-tooltip
          class="item"
          effect="light"
          :content="tmes.abbreviation"
          placement="top"
          :disabled="showTooltip"
        >
          <h1 class="sname" id="abbreviation">
            {{ tmes.abbreviation }}
          </h1>
        </el-tooltip>
      </el-col>
      <el-col :span="16">
        <div class="mes">
          <el-row>
            <el-col class="inmes inmesfix" :span="5">
              <img
                class="icon"
                src="../../assets/icon/渠道品牌.png"
                height="25"
                width="25"
              />
              <span ref="mailtext" class="icontext messagetext">{{
                tmes.name || '暂无信息'
              }}</span>
            </el-col>
            <el-col :span="9">
              <img
                class="icon"
                src="../../assets/icon/地址.png"
                height="20"
                width="20"
              />
              <el-tooltip
                placement="top"
                :disabled="!directionEllips"
                :content="tmes.company"
                effect="light"
                popper-class="atooltip"
                class="icontext"
              >
                <span ref="directiontext" class="messagetext">{{
                  tmes.company || '暂无信息'
                }}</span>
              </el-tooltip>
            </el-col>
            <el-col class="inmes inmesfix" :span="4">
              <img
                class="icon"
                src="../../assets/icon/渠道经理icon.png"
                height="25"
                width="25"
              />
              <el-tooltip
                placement="top"
                :disabled="!phoneEllips"
                :content="tmes.cname"
                effect="light"
                popper-class="atooltip"
                class="icontext"
              >
                <span ref="phonetext" class="messagetext">{{
                  tmes.cname || '暂无信息'
                }}</span>
              </el-tooltip>
            </el-col>
            <el-col class="inmes inmesfix" :span="4">
              <img
                class="icon"
                src="../../assets/icon/客户经理icon.png"
                height="25"
                width="25"
              />
              <el-tooltip
                placement="top"
                :disabled="!mailEllips"
                :content="tmes.kname"
                effect="light"
                popper-class="atooltip"
                class="icontext"
              >
                <span ref="mailtext" class="messagetext">{{
                  tmes.kname || '暂无信息'
                }}</span>
              </el-tooltip>
            </el-col>

            <img
              class="tran"
              :class="{
                changekai: channelview === false,
                tran1: channelview === true
              }"
              @click="channelviewchange"
              src="../../assets/icon/tran.png"
              height="20"
              width="20"
            />
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-row class="jstumessage bg " v-if="channelview === true">
      <div class="mes">
        <el-col :span="7">
          <img
            class="icon"
            src="../../assets/icon/对接人.png"
            height="13"
            width="13"
          />
          <el-tooltip
            placement="top"
            :disabled="!directionEllips"
            :content="tmes.contacts[0].name"
            effect="light"
            popper-class="atooltip"
          >
            <span ref="directiontext" class="messagetext">{{
              tmes.contacts[0].name || '暂无信息'
            }}</span>
          </el-tooltip>
        </el-col>
        <el-col class="inmes inmesfix" :span="9">
          <img
            class="icon"
            src="../../assets/icon/对接微信.png"
            height="13"
            width="13"
          />
          <el-tooltip
            placement="top"
            :disabled="!phoneEllips"
            :content="tmes.contacts[0].cellphone"
            effect="light"
            popper-class="atooltip"
          >
            <span ref="phonetext" class="messagetext">{{
              tmes.contacts[0].cellphone || '暂无信息'
            }}</span>
          </el-tooltip>
        </el-col>
        <el-col class="inmes inmesfix" :span="8">
          <img
            class="icon"
            src="../../assets/icon/对接职位.png"
            height="13"
            width="13"
          />
          <el-tooltip
            placement="top"
            :disabled="!phoneEllips"
            :content="tmes.contacts[0].position"
            effect="light"
            popper-class="atooltip"
          >
            <span ref="phonetext" class="messagetext channelpos">{{
              tmes.contacts[0].position || '暂无信息'
            }}</span>
          </el-tooltip>
        </el-col>
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'channelInfo',
  props: {
    tmes: {
      type: Object,
      default: () => {
        return {
          star: '',
          name: '',
          id: '',
          organization: '',
          research_direction: '',
          cellphone: '',
          scientific_research: '',
          paper: '',
          contacts: [{ name: '', wechat: '', position: '' }]
        }
      }
    }
  },
  data () {
    return {
      val: true,
      channelview: false,
      colors: {
        2: '#A0D469',
        4: { value: '#A0D469', excluded: true },
        5: '#A0D469'
      },
      mailEllips: false,
      phoneEllips: false,
      directionEllips: false,
      showTooltip: true
    }
  },
  watch: {
    tmes: {
      handler () {
        this.$nextTick(function () {
          this.mailEllips =
            this.$refs['mailtext'].offsetWidth <
            this.$refs['mailtext'].scrollWidth
          this.phoneEllips =
            this.$refs['phonetext'].offsetWidth <
            this.$refs['phonetext'].scrollWidth
          this.directionEllips =
            this.$refs['directiontext'].offsetWidth <
            this.$refs['directiontext'].scrollWidth
        })
        setTimeout(() => {
          // 判断是不是需要鼠标悬浮弹窗显示
          let cell = document.getElementById('abbreviation')
          this.showTooltip = !(cell.scrollWidth > cell.offsetWidth)
        }, 100)
      },
      deep: true
    }
  },
  methods: {
    channelviewchange () {
      this.channelview = !this.channelview
    }
  }
}
</script>

<style lang="scss" scoped>
.jstumessage {
  padding-bottom: 0 !important;
}
.bg {
  border: none;
  padding: 20px 0px 10px 35%;
}
.icontext {
  margin-top: 7px !important;
}
.messagetext {
  margin-right: 5px;
}
</style>
