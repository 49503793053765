<template>
  <el-dialog
    :title="dialogName"
    :visible.sync="active"
    width="35%"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :open="dialogOpen()"
  >
    <el-form ref="topic" :model="topic" :rules="rules" :hide-required-asterisk="true">
      <el-row>
        <el-col>
          <el-form-item :label="degreeName" prop="degree">
            <el-rate v-model="topic.degree" :colors="colors" @change="degreevalid()"> </el-rate>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item :label="titleName" prop="title">
            <el-input v-model="topic.title" placeholder="选题"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="inputleft">
          <el-form-item label="导师姓名*" prop="teacher" class="neat">
            <el-select
              v-model="topic.teacher"
              placeholder="导师姓名"
              filterable
              remote
              :remote-method="remoteTeacher"
              value-key="id"
            >
              <el-option
                v-for="item in teachers"
                :key="item.id"
                :label="item.name"
                :value="item"
                @click.native="getTeacher_identity(item)"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="inputright">
          <el-form-item label="导师身份*" prop="teacher_identity">
            <el-input v-model="topic.teacher_identity" placeholder="导师身份"> </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="subject" class="neat">
            <div style="font-size: 14px" class="flex">
              学科标签：
              <tagsSearch :subject.sync="topic.subject"></tagsSearch>
            </div>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="direction">
            <div style="font-size: 14px" class="flex">
              方向标签/细分方向：
              <tagsSearchSecond :direction="topic.direction"></tagsSearchSecond>
            </div>
          </el-form-item>
        </el-col>
        <div v-if="research">
          <el-col :span="12" class="inputleft">
            <el-form-item label="导师配合程度*" prop="teacher.cooperate">
              <el-select v-model="topic.teacher.cooperate" placeholder="导师配合程度">
                <el-option v-for="item in level" :key="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="inputright">
            <el-form-item label="导师沟通能力*" prop="teacher.connect">
              <el-select v-model="topic.teacher.connect" placeholder="导师沟通能力">
                <el-option v-for="item in level" :key="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="inputleft">
            <el-form-item label="科研项目草料详情链接*" prop="url">
              <el-input v-model="topic.url" placeholder="科研项目草料详情链接"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="inputright">
            <el-form-item label="科研项目海报*" prop="img">
              <p>支持PNG，JPG，JPEG格式</p>
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="handleSuccess"
                accept=".png,.jpg,.jpeg"
                v-if="topic.imgurl === ''"
                :before-upload="beforeupload"
              >
                <i class="eliwise-loading uploader-icon" v-if="uploading"></i>
                <i class="el-icon-plus uploader-icon" v-else></i>
              </el-upload>
              <img
                v-if="topic.imgurl"
                :src="topic.imgurl"
                class="avatar"
                @mouseenter="deleteOver()"
              />
              <div v-if="deleteOn" class="imgover" @mouseleave="deleteBelow()">
                <el-button class="listedit" @click="deleteimg()">
                  <i class="el-icon-delete"></i>
                </el-button>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="inputleft">
            <el-form-item label="科研+论文项目草料详情链接*" prop="pack_url">
              <el-input v-model="topic.pack_url" placeholder="科研+论文项目草料详情链接">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="inputright">
            <el-form-item label="科研+论文项目海报*" prop="pack_img">
              <p>支持PNG，JPG，JPEG格式</p>
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="handleSuccess_pack"
                accept=".png,.jpg,.jpeg"
                v-if="topic.pack_imgurl === ''"
                :before-upload="beforeupload_pack"
              >
                <i class="eliwise-loading uploader-icon" v-if="pack_uploading"></i>
                <i class="el-icon-plus uploader-icon" v-else></i>
              </el-upload>
              <img
                v-if="topic.pack_imgurl"
                :src="topic.pack_imgurl"
                class="avatar"
                @mouseenter="deleteOver_pack()"
              />
              <div v-if="deleteOn_pack" class="imgover" @mouseleave="deleteBelow_pack()">
                <el-button class="listedit" @click="deleteimg_pack()">
                  <i class="el-icon-delete"></i>
                </el-button>
              </div>
            </el-form-item>
          </el-col>
        </div>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button @click="cancel('topic')">取消</el-button>
      <el-button type="primary" @click="commit('topic')" :disabled="commitable">确认</el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from '../axios'
import tagsSearch from '../pub/tagsSearch'
import tagsSearchSecond from '../pub/tagsSearchSecond'

export default {
  name: 'topicform',
  components: {
    tagsSearch,
    tagsSearchSecond
  },
  data () {
    return {
      active: false,
      colors: ['#8DC152', '#8DC152', '#8DC152'],
      rules: {
        degree: [{ min: 1, message: '请填写难度', type: 'number' }],
        title: [{ required: true, message: '请填写标题', trigger: 'blur' }],
        teacher: [{ required: true, message: '请填写导师姓名', trigger: 'change' }],
        teacher_identity: [{ required: true, message: '请填写导师身份', trigger: 'blur' }],
        subject: [{ required: true, message: '请填写学科标签', trigger: 'change' }],
        direction: [{ required: true, message: '请填写方向标签', trigger: 'change' }],
        url: [{ required: true, message: '请填写草料详情链接', trigger: 'blur' }],
        img: [{ required: true, message: '请上传海报', trigger: 'blur' }],
        pack_url: [{ required: true, message: '请填写草料详情链接', trigger: 'blur' }],
        pack_img: [{ required: true, message: '请上传海报', trigger: 'blur' }]
      },
      topic_id: '',
      postable: true,
      teachers: [],
      subjects: [],
      research: false,
      level: [1, 2, 3, 4, 5],
      uploadUrl: `${axios.defaults.baseURL}/upload/`,
      dialogName: '',
      degreeName: '',
      titleName: '',
      deleteOn: false,
      deleteOn_pack: false,
      topic: {
        degree: 0,
        title: '',
        teacher: {
          name: '',
          id: '',
          cooperate: '',
          connect: ''
        },
        teacher_identity: '',
        subject: '',
        direction: [],
        img: '',
        imgurl: '',
        url: '',
        pack_img: '',
        pack_imgurl: '',
        pack_url: ''
      },
      commitable: false,
      uploading: false,
      pack_uploading: false
    }
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  methods: {
    dialogOpen () {
      if (this.type === 'thesis') {
        this.degreeName = '选题难度*'
        this.titleName = '选题*'
        if (this.postable) this.dialogName = '添加选题'
        else this.dialogName = '编辑选题'
      } else {
        this.degreeName = '项目难度*'
        this.titleName = '项目名称*'
        if (this.postable) this.dialogName = '上架科研'
        else this.dialogName = '编辑科研项目'
      }
    },
    remotes () {
      this.remoteTeacher(this.topic.teacher.name)
      // this.remoteSubject(1, this.topic.subject.content)
    },
    cancel (formName) {
      this.teachers = []
      this.$refs[formName].resetFields()
      this.topic.imgurl = ''
      this.active = false
    },
    degreevalid () {
      this.$refs['topic'].validateField('degree')
    },
    remoteTeacher (query) {
      axios
        .get('DBS/teachers', {
          params: {
            name: query
          }
        })
        .then(response => {
          this.teachers = response.data.teachers
        })
    },
    getTeacher_identity (item) {
      this.topic.teacher_identity = item.misc
    },
    beforeupload () {
      this.uploading = true
    },
    beforeupload_pack () {
      this.uploading = true
    },
    handleSuccess (res, file) {
      this.$message({
        showClose: true,
        message: `文件上传成功`,
        type: 'success'
      })
      this.topic.img = res.key
      this.topic.imgurl = URL.createObjectURL(file.raw)
      this.$refs['topic'].validateField('img')
      this.uploading = false
    },
    handleSuccess_pack (res, file) {
      this.$message({
        showClose: true,
        message: `文件上传成功`,
        type: 'success'
      })
      this.topic.pack_img = res.key
      this.topic.pack_imgurl = URL.createObjectURL(file.raw)
      this.$refs['topic'].validateField('pack_img')
      this.pack_uploading = false
    },
    deleteimg () {
      this.topic.img = ''
      this.topic.imgurl = ''
      this.deleteOn = false
    },
    deleteOver () {
      this.deleteOn = true
    },
    deleteBelow () {
      this.deleteOn = false
    },
    deleteimg_pack () {
      this.topic.pack_img = ''
      this.topic.pack_imgurl = ''
      this.deleteOn_pack = false
    },
    deleteOver_pack () {
      this.deleteOn_pack = true
    },
    deleteBelow_pack () {
      this.deleteOn_pack = false
    },
    commit (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.commitable = true
          if (this.postable) {
            this.postform()
          } else {
            this.putform()
          }
        } else {
          this.$message({
            message: '请输入必填项',
            type: 'warning'
          })
          return false
        }
      })
    },
    postform () {
      if (this.type === 'thesis') {
        axios
          .post(`/DBS/topic/${this.type}`, {
            degree: this.topic.degree,
            title: this.topic.title,
            teacher: this.topic.teacher.id,
            teacher_identity: this.topic.teacher_identity,
            subject: this.topic.subject,
            tags: this.topic.direction
          })
          .then(response => {
            if (response.status === 200) {
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              setTimeout(() => {
                window.location.reload()
              }, 1000)
            }
          })
      } else {
        axios
          .post(`/DBS/topic/${this.type}`, {
            degree: this.topic.degree,
            title: this.topic.title,
            teacher: this.topic.teacher.id,
            teacher_identity: this.topic.teacher_identity,
            subject: this.topic.subject.id,
            tags: this.topic.direction,
            cooperate: this.topic.teacher.cooperate,
            connect: this.topic.teacher.connect,
            url: this.topic.url,
            img: this.topic.img,
            pack_url: this.topic.pack_url,
            pack_img: this.topic.pack_img
          })
          .then(response => {
            if (response.status === 200) {
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              setTimeout(() => {
                window.location.reload()
              }, 1000)
            }
          })
      }
    },
    putform () {
      if (this.type === 'thesis') {
        axios
          .put(`/DBS/topic/${this.type}/${this.topic.id}`, {
            degree: this.topic.degree,
            title: this.topic.title,
            teacher: this.topic.teacher.id,
            teacher_identity: this.topic.teacher_identity,
            subject: this.topic.subject.id,
            tags: this.topic.direction
          })
          .then(response => {
            if (response.status === 200) {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              setTimeout(() => {
                window.location.reload()
              }, 1000)
            }
          })
      } else {
        axios
          .put(`/DBS/topic/${this.type}/${this.topic.id}`, {
            degree: this.topic.degree,
            title: this.topic.title,
            teacher: this.topic.teacher.id,
            teacher_identity: this.topic.teacher_identity,
            subject: this.topic.subject.id,
            tags: this.topic.direction,
            cooperate: this.topic.teacher.cooperate,
            connect: this.topic.teacher.connect,
            url: this.topic.url,
            img: this.topic.img,
            pack_url: this.topic.pack_url,
            pack_img: this.topic.pack_img
          })
          .then(response => {
            if (response.status === 200) {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              setTimeout(() => {
                window.location.reload()
              }, 1000)
            }
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-dialog__title {
  font-size: 24px;
}
.el-form-item {
  margin-bottom: 5px;
}
/deep/.el-form-item__label {
  float: none;
  color: #656d78;
}
.checkbox {
  position: absolute;
  top: 0px;
  right: 45px;
}
/deep/.el-dialog .el-input__inner {
  background-color: #ffffff;
}
/deep/ .el-select {
  width: 100%;
}
.neat {
  /deep/.el-input__inner {
    cursor: text;
  }
  /deep/.el-dialog__footer {
    margin-top: 30px;
  }
  /deep/.el-input__suffix {
    display: none;
  }
}
.avatar-uploader /deep/.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 0px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader /deep/.el-upload:hover {
  border-color: #409eff;
}
/deep/.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.avatar-uploader {
  height: 52px;
}
.avatar {
  width: 50px;
  height: 50px;
  display: block;
  position: relative;
  border: 1px dashed #d9d9d9;
}
.imgover {
  position: absolute;
  color: #ffffff;
  background: #656d78;
  opacity: 0.8;
  width: 50px;
  height: 50px;
  top: 26px;
  text-align: center;
  line-height: 50px;
  font-size: 25px;
  border: 1px dashed #d9d9d9;
  .listedit {
    font-size: 25px;
    color: #ffffff;
    &:hover {
      background: transparent;
      color: #ffffff;
    }
  }
}
</style>
