<template>
  <div class="proWrapper" ref="proWrapper" :style="{ '--x': cssx }">
    <img class="img" src="../../assets/icon/iap.png" />
    <!--    <div class="img" >-->
    <!--      <div class="meng"></div>-->
    <!--      <img src="../../assets/icon/Pro-tou1.png" alt="" class="topTou1" />-->
    <!--      <img src="../../assets/icon/Pro-tou2.png" alt="" class="topTou2" />-->
    <!--    </div>-->
    <img class="topImg" :src="pro.img" />
    <div class="proMain">
      <div class="divide">
        <div class="line"></div>
        <div class="proContent">项目收获</div>
      </div>
      <div class="got">
        <div v-for="item in got" :key="item[0]">
          <img src="../../assets/icon/gou.png" />
          {{ item[0] }}
        </div>
      </div>
      <div class="divide">
        <div class="line"></div>
        <div class="proContent">教授介绍</div>
      </div>
      <div class="professor">
        <div class="professorTop">
          <div class="professorDesc">
            <div class="tag_p">{{ pro.professor.school + pro.professor.position }}</div>
            <div class="tag_p">顶级学术期刊审稿人</div>
            <div class="tag_p">知名国际学术会议编委</div>
          </div>
          <div class="professorImg">
            <img :src="pro.professor.avatar" alt="" />
          </div>
        </div>
        <div class="professorBottom">
          {{ pro.professor.background }}
        </div>
      </div>
      <div class="divide">
        <div class="line"></div>
        <div class="proContent">适合人群</div>
      </div>
      <div class="people">
        <div class="proContent">
          有意提高自身知识水平及学术能力的学生；有意掌握最前沿科研热点及科研方法的学生；有升学意向、跨专业深造的学生。
        </div>
        <div>
          <div class="tag_p">适合年级：{{ get_degree() }}</div>
          <div class="tag_p">
            适合专业：
            <span class="tag">
              {{ pro.subject.content }}
            </span>
            <span v-for="item in pro.tagsText.split(',')" :key="item" class="tag">
              {{ item }}
            </span>
          </div>
        </div>
      </div>
      <div class="divide">
        <div class="line"></div>
        <div class="proContent">学科介绍</div>
      </div>
      <div class="people">
        {{ pro.introduction }}
      </div>
      <div class="divide">
        <div class="line"></div>
        <div class="proContent">专题方向</div>
      </div>
      <div class="people">
        专题一：{{ pro.topicDirection[0].title }}
      </div>
      <div class="people">
        摘要：{{ pro.topicDirection[0].abstract }}
      </div>
      <div class="people">
        <br />
        专题二：{{ pro.topicDirection[1].title }}
      </div>
      <div class="people">
        摘要：{{ pro.topicDirection[1].abstract }}
      </div>
      <div class="people">
        <br />
        专题三：{{ pro.topicDirection[2].title }}
      </div>
      <div class="people">
        摘要：{{ pro.topicDirection[2].abstract }}
      </div>
      <div class="divide">
        <div class="line"></div>
        <div class="proContent">参考学习内容</div>
      </div>
      <div class="refer">
        <div class="re">
          <div class="tag_p" v-for="item in task" :key="item[0]">{{ item[0] }}</div>
        </div>
        <div v-for="(item, index) in tasks" :key="item.title" class="task">
          <div class="left">
            <div class="circle"></div>
            <div class="line" v-if="index !== tasks.length - 1"></div>
          </div>
          <div style="color:#D7AF87;">{{ item.title }}</div>
          <div class="tag_p" v-for="value in item.value" :key="value">{{ value }}</div>
          <div class="tag_p">
            <p v-if="item.duty"><span style="color:#d7af87">海外教授Meeting（英文）：</span>{{ item.duty }}</p>
            <p v-if="item.task">{{ item.task }}</p>
          </div>
        </div>
      </div>
      <div class="tips">（以上任务仅供参考，实际辅导根据定制化要求展开）</div>
      <div class="files">
        附件
        <div class="fileList">
          <img src="../../assets/icon/suo.png" class="suo" />
          <div class="file" v-for="item in files" :key="item" @click="download(item)">
            <img class="pdf" src="../../assets/icon/pdf-standard.png" />
            {{ item }}
            <img class="down" src="../../assets/icon/down.png" />
          </div>
        </div>
      </div>
      <div class="tips">
        （注：补充包所涉及课程，旨在为参与项目的学生强化学术基础，将与1v1正式科研穿插进行，当补充包课程与1v1核心专业指导时间冲突时，以1v1的指导安排为主。
        补充课程的具体安排安排及内容详情参考附件。）
      </div>
    </div>
  </div>
</template>
<script>
import axios from '../axios'
export default {
  data () {
    return {
      got: [
        ['高亲密度的海外教授量推荐信'],
        ['定制化的国内导师推荐信'],
        ['独立一作的EI/CPCI/Scopus/ProQuest/Crossref/EBSCO或同等级别索引收录国际会议论文'],
        ['国际认可的论文录用证明'],
        ['定制化学术研究经历'],
        ['14课时学术写作课（必看）'],
        ['73课时科研基础课（选看）']
      ],
      tasks: [
        {
          title: '「Week 1」',
          value: [
            '掌握查阅文献和面向文献学习的方法',
            '掌握文献管理的方法',
            '通过查阅文献，学习课题方向的研究热点和常见研究问题',
            '掌握快速提炼文献重要信息的方法'
          ],
          duty: '学生与教授自我介绍互相了解；教授介绍课题研究背景以及相关子专题概况',
          task: '学生任务：预习专题一'
        },
        {
          title: '「Week 2」',
          value: [
            '针对专题一内容和研究问题扩充文献',
            '学习了解相关基本理论和研究范式'
          ],
          duty: '学生向教授汇报学习内容；教授根据学生汇报情况对专题内容进行讲解',
          task: '学生任务：总结专题一；预习专题二'
        },
        {
          title: '「Week 3」',
          value: [
            '针对专题二内容和研究问题扩充文献',
            '学习了解相关基本理论和研究范式'
          ],
          duty: '学生向教授汇报学习内容；教授根据学生汇报情况对专题内容进行讲解',
          task: '学生任务：总结专题二；预习专题三'
        },
        {
          title: '「Week 4」',
          value: [
            '针对专题三内容和研究问题扩充文献',
            '学习了解相关基本理论和研究范式'
          ],
          duty: '学生向教授汇报学习内容；教授根据学生汇报情况对专题内容进行讲解',
          task: '学生任务：总结专题三；准备最终汇报'
        },
        {
          title: '「Week 5」',
          value: [
            '总结全部专题内容，寻找研究方向',
            '习论文结构以及提纲撰写方法'
          ],
          duty: '学生向教授汇报学习内容；教授根据学生汇报情况对专题内容进行讲解',
          task: '学生任务：完善论文提纲，准备论文写作'
        },
        {
          title: '「Week 6-12」',
          value: [
            '学习论文各部分写作内容及技巧',
            '完成论文各部分写作',
            '根据会议要求修改论文格式',
            '论文查重与降重',
            '论文投递与注册',
            '收获录用证明和海外教授推荐信'
          ]
        }
      ],
      tags: [],
      task: [],
      files: [
        '项目流程-Pro',
        '海外教授简历',
        '海外教授专属科研评价信范本',
        '国内导师推荐信范本',
        'EI/CPCI国际学术会议录用证明展示',
        '学员案例:论文范例+学员反馈+启航系统展示',
        'FAQ-Pro',
        '12学时学术写作课详情（必看）',
        '73课时科研基础课（选看）'
      ],
      uuid: '',
      title: '',
      direction_tag: '',
      degree: '',
      method: '',
      ability: '',
      identity: '',
      paper: '',
      back: '',
      introduce: '',
      standard: '',
      resume: '',
      targetAudienceStatus: 0
    }
  },
  props: {
    pro: {}
  },
  mounted () {
    console.log(this.$refs.proWrapper)
  },
  computed: {
    cssx () {
      return 1200 / 15 * 0.04 + 'px'
    }
  },
  methods: {
    get_degree () {
      console.log(this.degree)
      console.log(this.targetAudienceStatus)
      if (this.targetAudienceStatus === 1) {
        return '高中生'
      } else {
        return '本科生/研究生'
      }
      // switch (this.degree) {
      //   case '5':
      //     return '研究生'
      //   case '4':
      //     return '本科生/研究生'
      //   default:
      //     return '高中生/本科生/研究生'
      // }
    },
    download (item) {
      item = item.replace('/', '_')
      if (item === '学员案例:论文范例+学员反馈+启航系统展示') {
        item = '学员案例'
      }
      let url = `https://elitebackground.oss-cn-zhangjiakou.aliyuncs.com/poster/Pro/${item}.pdf`
      if (item === '海外教授简历') {
        url = this.pro.professor.outside
      }
      let routeData = this.$router.resolve({
        path: '/pdf2',
        query: {
          url: url
        }
      })
      if (/Android|webOS|iPhone|ipad/i.test(navigator.userAgent)) {
        window.open(routeData.href, '_blank')
      } else {
        window.open(url, '_blank')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
$x: 1.2px;

.proWrapper {
  background-color: white;

  .topImg {
    width: 375 * $x;
    height: 393 * $x;
  }

  .topImg {
    background-color: white;
  }

  .img {
    width: 375 * $x;
    position: absolute;
    top: 0;
    left: 0;

    .meng {
      width: 375 * $x;
      height: 406 * $x;
      position: absolute;
      //top: 0 * 2px;
      z-index: 1;
      background: rgba(#000000, 0.65);
    }

    .topTou1 {
      width: 102 * $x;
      position: absolute;
      top: 38 * $x;
      left: 50 * $x;
      z-index: 2;
    }

    .topTou2 {
      width: 275 * $x;
      position: absolute;
      top: 95 * $x;
      left: 50 * $x;
      z-index: 2;
    }
  }

  .proMain {
    padding: 0 20 * $x;
    padding-bottom: 90 * $x;
  }

  .poster {
    width: 375 * $x;
    height: 210 * $x;
  }

  .title {
    margin-top: 30 * $x;
    font-family: PFBD;
    font-style: normal;
    font-weight: 600;
    font-size: 16 * $x;
    line-height: 24 * $x;
    text-align: justify;
    color: #555555;
  }

  .divide {
    font-family: PFBD;
    font-style: normal;
    font-weight: 600;
    font-size: 12 * $x;
    line-height: 16 * $x;
    margin-top: 30 * $x;
    margin-bottom: 10 * $x;
    color: #d7af87;
    height: 14 * $x;
    position: relative;

    .line {
      top: 7 * $x;
      border-top: 1 * $x solid #d7af87;
      position: absolute;
      width: 100%;
    }

    .proContent {
      margin-left: 10 * $x;
      background: #fff;
      padding: 0 10 * $x;
      position: absolute;
      font-family: PFBD;
      font-style: normal;
      font-weight: 600;
      font-size: 12 * $x;
      line-height: 16 * $x;
      color: #d7af87;
    }
  }

  .got {
    font-family: PFBD;
    font-style: normal;
    font-weight: 400;
    font-size: 12 * $x;
    line-height: 20 * $x;

    span {
      color: #d7af87;
      font-size: 10 * $x;
    }

    img {
      margin-right: 5 * $x;
      width: 9.74 * $x;
    }
  }

  .professor {
    font-size: 10 * $x;
    line-height: 1.6;
    text-align: justify;

    .professorTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 26 * $x;

      .professorDesc {
        width: 70%;
      }

      .professorImg {
        width: 72 * $x;
        height: 72 * $x;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }
  }

  .people {
    font-family: PFBD;
    font-style: normal;
    font-weight: 400;
    font-size: 10 * $x;
    line-height: 20 * $x;
    color: #000000;

    .proContent {
      margin-bottom: 5 * $x;
    }

    .tag {
      height: 18 * $x;
      padding: 0 5 * $x;
      line-height: 18 * $x;
      background: #d7af87;
      margin-right: 10 * $x;
      display: inline-block;
      color: #fff;
    }
  }

  .tag_p {
    display: list-item;
    position: relative;
    left: 2em;
    width: calc(100% - 2em);
    line-height: 20 * $x;
  }

  .teacher {
    font-family: PFBD;
    font-style: normal;
    font-weight: 400;
    font-size: 12 * $x;
    line-height: 20 * $x;
    text-align: justify;
    color: #000000;
    white-space: pre-line;

    .content {
      margin-top: 5 * $x;
    }
  }

  .back {
    font-family: PFBD;
    font-style: normal;
    font-weight: 400;
    font-size: 10 * $x;
    line-height: 16 * $x;
    text-align: justify;
    color: #000000;
    white-space: pre-line;
  }

  .introduce {
    font-family: PFBD;
    font-style: normal;
    font-weight: 400;
    font-size: 10 * $x;
    line-height: 16 * $x;
    text-align: justify;
    color: #000000;
    white-space: pre-line;
  }

  .refer {
    font-family: PFBD;
    font-style: normal;
    font-weight: 500;
    font-size: 8 * $x;
    line-height: 16 * $x;
    color: #000000;

    .tag_p {
      line-height: 14 * $x;
      font-weight: 400;
      display: inline-block;
    }

    .re {
      margin-left: 18 * $x;
      margin-bottom: 10 * $x;
    }

    .task {
      padding-left: 18 * $x;
      position: relative;
      padding-bottom: 15 * $x;
    }

    .left {
      position: absolute;
      left: 0;
      top: 0.5em;
      height: 100%;
    }

    .circle {
      height: 5 * $x;
      width: 5 * $x;
      border-radius: 50%;
      background: #ffffff;
      border: 1 * $x solid #d7af87;
      box-sizing: border-box;
    }

    .line {
      border-left: 1 * $x solid #d7af87;
      height: 100%;
      width: 0;
      position: absolute;
      left: 2 * $x;
    }
  }

  .tips {
    font-family: PFBD;
    font-style: normal;
    font-weight: 400;
    font-size: 12 * $x;
    line-height: 16 * $x;
    text-align: center;
    color: #a7a7a7;
  }

  .files {
    font-family: PFBD;
    font-style: normal;
    font-weight: 600;
    font-size: 12 * $x;
    line-height: 16 * $x;
    color: #d7af87;
    margin-top: 30 * $x;
    margin-bottom: 10 * $x;

    .fileList {
      border: 1 * $x solid #d7af87;
      margin-top: 14.5 * $x;
      padding: 10.5 * $x 19.5 * $x 21 * $x;
      position: relative;

      .suo {
        position: absolutez;
        top: -4 * $x;
        left: 45 * $x;
        padding: 0 5 * $x;
        width: 15 * $x;
        background: #fff;
      }

      .file {
        background: #ffffff;
        box-shadow: 0 * $x 0 * $x 10 * $x rgba(0, 0, 0, 0.25);
        padding: 14 * $x 30 * $x 15 * $x 32 * $x;
        margin-top: 11 * $x;
        font-family: PFBD;
        font-style: normal;
        font-weight: 400;
        font-size: 10 * $x;
        line-height: 14 * $x;
        color: #000000;
        position: relative;

        .pdf {
          width: 20 * $x;
          position: absolute;
          left: 8 * $x;
          top: 11 * $x;
        }

        .down {
          width: 15 * $x;
          position: absolute;
          top: 14 * $x;
          right: 10 * $x;
        }
      }
    }
  }
}
</style>
