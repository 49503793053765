<template>
  <div class="aside">
    <changerole></changerole>
    <el-menu router @select="handleSelect">
      <el-menu-item
        :class="$route.path == '/J/Jinterview' ? 'is-active' : ''"
        index="/J/Jinterview"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">面试<br />学员</div>
          <img class="iconmenu" src="../../assets/icon/面试学员@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/J/Jschedule' ? 'is-active' : ''"
        index="/J/Jschedule"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">学员<br />进度</div>
          <img class="iconmenu" src="../../assets/icon/学员进度@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/J/Jpost' ? 'is-active' : ''"
        index="/J/Jpost"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">投稿<br />进度</div>
          <img class="iconmenu" src="../../assets/icon/稿件.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/J/Jrecommendations' ? 'is-active' : ''"
        index="/J/Jrecommendations"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">推荐信<br />进度</div>
          <img class="iconmenu" src="../../assets/icon/推荐信.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/J/Jretouches' ? 'is-active' : ''"
        index="/J/Jretouches"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">翻译/润色<br />进度</div>
          <img class="iconmenu" src="../../assets/icon/翻译_润色icon.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/J/Jstudent' ? 'is-active' : ''"
        index="/J/Jstudent"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">结项<br />学员</div>
          <img class="iconmenu" src="../../assets/icon/结项学员@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/J/Jmanual' ? 'is-active' : ''"
        index="/J/Jmanual"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">导师<br />手册</div>
          <img class="iconmenu" src="../../assets/icon/导师手册@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/J/Jteacher' ? 'is-active' : ''"
        index="/J/Jteacher"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">查看<br />导师</div>
          <img class="iconmenu" src="../../assets/icon/查看导师@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/J/Jaccount' ? 'is-active' : ''"
        index="/J/Jaccount"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">账户<br />管理</div>
          <img class="iconmenu" src="../../assets/icon/账户管理@3x.png" />
        </el-tooltip>
      </el-menu-item>
    </el-menu>
    <div class="back">
      <img
        @click="logout"
        src="../../assets/icon/退出.png"
        height="20"
        width="20"
      />
    </div>
  </div>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'
import changerole from '../pub/changerole'
export default {
  name: 'jnav',
  components: { changerole },
  methods: {
    handleSelect (key, keyPath) {
      this.$router.replace({ path: `${keyPath}` })
    },
    logout () {
      MessageBox.confirm('是否注销？')
        .then(() => {
          axios.delete('/logout/').then(() => {
            localStorage.removeItem('Authorization')
            this.$router.replace('/login')
          })
        })
        .catch(() => {})
    }
  }
}
</script>
