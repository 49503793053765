<template>
  <el-dialog
    title="分配导师"
    :visible.sync="active"
    width="80%"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <el-row>
      <el-col :span="24">
        <div class="project_type">面试时间</div>
        <el-input
          placeholder="面试时间"
          v-model="connectTime"
          class="input"
        ></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="project_type">指派导师</div>
      </el-col>
      <el-col :span="24" class="flex border">
        <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
        <el-input
          v-model="teacherName"
          @input="searchTeacher"
          @focus="showL"
          @blur="selected"
          placeholder="导师姓名"
          class="seasub fixseasub"
        >
        </el-input>
      </el-col>
      <el-col :span="24">
        <el-table
          v-if="showList"
          :header-cell-style="{
            background: '#F4F6F9',
            color: '#c0c0c0',
            'text-align': 'center'
          }"
          :data="tableDataSearch"
          height="240"
          @row-click="selectTeacher"
          ref="tabs"
          class="tabin base"
        >
          <el-table-column
            key=""
            prop="id"
            label="编号"
            align="center"
            min-width="10%"
          >
          </el-table-column>
          <el-table-column
            key="2"
            prop="name"
            label="导师姓名"
            align="center"
            min-width="12%"
          >
          </el-table-column>
          <el-table-column
            key="3"
            prop="organization"
            label="机构"
            align="center"
            min-width="18%"
          >
          </el-table-column>
          <el-table-column
            key="4"
            prop="research_direction"
            label="研究方向"
            align="center"
            min-width="33%"
          >
          </el-table-column>
          <el-table-column
            key="5"
            prop="email"
            label="邮箱"
            align="center"
            min-width="27%"
          >
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="24">
        <div>
          <div class="project_type">导师备注</div>
          <el-input
            placeholder="导师备注"
            v-model="teacherMisc"
            class="input"
          ></el-input>
        </div>
      </el-col>
    </el-row>
    <template #footer>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="pushfix" :disabled="pending"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import axios from 'axios'
import { debounce } from 'lodash'
import { MessageBox } from 'element-ui'
export default {
  name: 'teacherDialog',
  data () {
    return {
      showList: false,
      pid: 0,
      tid: '',
      teacherName: '',
      tableDataSearch: [],
      connectTime: '',
      active: false,
      pending: false,
      scrollTop: 0,
      has_next: true,
      teacherMisc: '',
      notlist: []
    }
  },
  computed: {
    api () {
      if (this.$store.state.roles.indexOf('教学总监') !== -1) {
        return 'tdirector'
      } else {
        return 'eadmin'
      }
    }
  },
  methods: {
    open (pid, teacher, connectTime) {
      this.pid = pid
      this.tid = teacher.id
      this.teacherName = teacher.name
      this.connectTime = connectTime
      this.teacherMisc = teacher.misc
      this.active = true
      this.$nextTick(function () {
        this.searchTeacher()
      })
    },
    close () {
      this.pagenumber = 1
      this.active = false
      this.tid = ''
      this.teacherName = ''
      this.connectTime = ''
      this.teacherMisc = ''
    },
    selected () {
      setTimeout(() => {
        this.showList = !this.showList
      }, 400)
    },
    searchTeacher: debounce(function () {
      this.pagenumber = 1
      if (this.$refs.tabs) {
        this.$refs.tabs.bodyWrapper.scrollTop = 0
      }
      axios
        .get(`/${this.api}/teachers`, {
          params: {
            name: this.teacherName,
            page: this.pagenumber
          }
        })
        .then(response => {
          this.has_next = response.data.has_next
          this.tableDataSearch = response.data.teachers
        })
    }, 500),
    selectTeacher (row) {
      this.teacherName = row.name
      this.tid = row.id
      this.teacherMisc = row.misc
    },
    linkList () {
      this.$refs.tabs.bodyWrapper.addEventListener('scroll', () => {
        let scrollTop = this.$refs.tabs.bodyWrapper.scrollTop
        let windowHeight =
          this.$refs.tabs.bodyWrapper.clientHeight ||
          this.$refs.tabs.bodyWrapper.clientHeight
        let scrollHeight =
          this.$refs.tabs.bodyWrapper.scrollHeight ||
          this.$refs.tabs.bodyWrapper.scrollHeight
        if (scrollTop + windowHeight >= scrollHeight && this.has_next) {
          this.pagenumber = this.pagenumber + 1
          axios
            .get(`/${this.api}/teachers/`, {
              params: {
                name: this.teacherName,
                page: this.pagenumber
              }
            })
            .then(response => {
              if (response.data.has_previous) {
                this.tableDataSearch = this.tableDataSearch.concat(
                  response.data.teachers
                )
              }
              this.has_next = response.data.has_next
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    showL () {
      this.showList = !this.showList
      this.$nextTick(() => {
        this.linkList()
      })
    },
    pushfix () {
      if (this.otherItem !== '') {
        this.chooseItem = this.otherItem
      }
      MessageBox.confirm(`是否分配${this.teacherName}到该项目？`).then(() => {
        this.pending = true
        axios
          .post(`${this.api}/project/${this.pid}/change_teacher`, {
            teacher_id: this.tid,
            connectTime: this.connectTime,
            teacherMisc: this.teacherMisc
          })
          .then(response => {
            if (response.status === 200) {
              this.$message({
                showClose: true,
                message: '添加成功',
                type: 'success'
              })
              setTimeout(() => {
                this.active = false
                window.location.reload()
                this.pending = false
              }, 1000)
            } else this.pending = false
          })
          .catch(error => {
            console.log(error)
          })
      })
    }
  }
}
</script>
<style lang="scss">
.seasub .el-input__inner {
  background-color: #f4f6f9;
  border: solid 0px;
  padding-left: 0px;
  padding-right: 0px;
}
</style>
<style lang="scss" scoped>
.border {
  border-bottom: solid 1px #c0c0c0;
  padding-bottom: 5px;
}
.seasub {
  padding-left: 10px;
  padding-top: 14px;
  position: relative;
  bottom: 3px;
}
.tabin {
  font-size: 12px;
  color: #434a54;
  box-shadow: 0 2px 8px 0 rgba(204, 209, 217, 0.5);
  background-color: #f5f7fa;
  position: absolute;
  z-index: 100;
}
.fixseasub {
  padding-top: 0px !important;
  padding-left: 10px;
}
/deep/.el-table tbody tr:hover > td {
  background-color: #a0d469;
  color: #ffffff;
}
/deep/.el-dialog {
  overflow: visible;
}
.project_type {
  margin-top: 12px;
  margin-bottom: 0px;
}
</style>
