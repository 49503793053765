<template>
  <el-main class="main">
    <div class="flex">
      <div class="list">
        <div class="search flex">
          <div class="seaicon">
            <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
          </div>
          <el-input @input="search" v-model="subtitle" class="seasubfix">
          </el-input>
        </div>
        <div v-if="sublist.length > 0" :style="height" class="subtitle">
          <div
            @click="getsub(index)"
            :class="{ changeStyle: changeSelectStyle === index }"
            class="subject"
            v-for="(item, index) in sublist"
            :key="index"
          >
            <div class="insubtitle">{{ item.number }}.{{ item.title }}</div>
          </div>
        </div>
        <div
          v-else
          :style="height"
          style="font-size: 16px;color: #656d78;padding-top: 1em;"
          class="subtitle"
        >
          未搜索到相关信息
        </div>
      </div>
      <div class="neirong">
        <div class="kutitle">
          <h2 class="jtext">访问智库</h2>
        </div>
        <div class="insubgroup" :style="insubheight">
          <div v-for="(item, index) in insublist" class="insub" :key="index">
            <el-row class="outside">
              <el-col :span="20">
                <h1 class="subintitle">
                  {{ item.title }}
                </h1>
              </el-col>
              <el-col :span="4">
                <div class="icontran">
                  <img
                    class="icon1"
                    :class="{ changekai: i === index, icon2: i !== index }"
                    @click="isView(index)"
                    src="../../assets/icon/tran.png"
                    height="20"
                    width="20"
                  />
                </div>
              </el-col>
            </el-row>
            <!--<div style="width: 93%" v-if="{zhankai:changekaiStyle === num}">-->
            <div style="width: 93%" v-if="index === i">
              <el-row class="inside">
                <el-col :span="24" class="secondtitle">
                  二级编号：{{ item.number }}
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="secondtext">
                  {{ item.introduce }}
                </el-col>
              </el-row>
              <el-row>
                <el-col v-if="keywords !== null" :span="24" class="tag">
                  <div
                    v-for="(item1, index) in keywords.split('+')"
                    :key="index"
                    class="intag"
                  >
                    <i
                      class="el-icon-price-tag"
                      style="transform: rotate(-45deg)"
                    ></i>
                    {{ item1 }}
                  </div>
                </el-col>
                <el-col v-else :span="24" class="tag"> </el-col>
              </el-row>
              <el-row class="inside">
                <el-col :span="24" class="filetitle"> 文件列表 </el-col>
              </el-row>
              <el-row class="filegroup">
                <el-col
                  :span="8"
                  v-for="(item, index) in filelist"
                  :key="index"
                  class="filetext"
                >
                  <div class="infile">
                    <div class="imggroup">
                      <div class="fileimg">
                        <img
                          src="../../assets/icon/pdf.png"
                          class="filetype"
                          height="32"
                          width="27"
                        />
                      </div>
                      <div class="filesize">
                        {{ Math.ceil(item.size / 1024) }}KB
                      </div>
                    </div>
                    <div class="downloadgroup">
                      <p class="filename">
                        {{ item.file_name }}
                      </p>
                      <a :href="item.url" target="_blank">
                        <el-button type="primary" class="downbutton">
                          下 载
                        </el-button>
                      </a>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <!-- <el-row class="inside">
                <el-col :span="24" class="ketitle">
                  公开课
                </el-col>
              </el-row>
              <el-row v-if="classlist.length > 0">
                <el-col
                  class="kelist"
                  :span="8"
                  v-for="(item, index) in classlist"
                  :key="index"
                >
                  <a :href="item.url" target="_blank">
                    <el-card
                      class="card"
                      @click="jump"
                      :body-style="{ padding: '0px' }"
                      shadow="never"
                    >
                      <div>
                        <img
                          :src="item.image_url"
                          width="300"
                          height="170"
                          class="image"
                        />
                      </div>
                      <div class="kename">
                        <span>{{ item.title }}</span>
                      </div>
                    </el-card>
                  </a>
                </el-col>
              </el-row>
              <el-alert
                title="暂无相关公开课"
                type="info"
                effect="dark"
                :closable="false"
              /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-main>
</template>

<script>
/* eslint-disable no-console */
import axios from '../axios'
import _ from 'lodash'
import changerole from '../pub/changerole'
export default {
  name: 'ku',
  data () {
    return {
      role: '学生',
      value: '',
      filenum: 7,
      kenum: 4,
      tagnum: 5,
      a: 0,
      changekaiStyle: '',
      changeSelectStyle: '',
      height: {
        height: ''
      },
      insubheight: {
        height: ''
      },
      sublist: [],
      insublist: [],
      filelist: [],
      classlist: [],
      keywords: '',
      subid: 14,
      ersubid: 1,
      subtitle: '',
      empty: '',
      i: null
    }
  },
  components: {
    changerole
  },

  created () {
    this.listhh()
    this.inhh()
    this.getsubject()
  },
  methods: {
    search: _.debounce(function () {
      this.getsubject()
    }, 500),
    isView (index) {
      if (this.i === index) {
        this.i = null
      } else {
        this.i = index
      }
      this.keywords = this.insublist[index].keywords
      this.ersubid = this.insublist[index].id
      axios.get(`/sub_subject/${this.ersubid}/file`).then(response => {
        this.filelist = response.data.class
      })
      axios.get(`/sub_subject/${this.ersubid}/class`).then(response => {
        this.classlist = response.data.class
      })
    },
    listhh () {
      this.height.height = window.innerHeight - 90 + 'px'
    },
    inhh () {
      this.insubheight.height = window.innerHeight - 61 + 'px'
    },
    getfirst () {
      this.changeSelectStyle = 0
      this.i = null
      this.subid = this.sublist[0].id
      axios.get(`/sub_subject/${this.subid}`).then(response => {
        this.insublist = response.data.sub_subjects
      })
    },
    getsubject () {
      axios
        .get('/subject/', {
          params: {
            title: this.subtitle
          }
        })
        .then(response => {
          this.sublist = response.data.subjects
          this.getfirst()
        })
        .catch(error => {
          console.log(error)
        })
    },
    getsub (index) {
      this.changeSelectStyle = index
      this.i = null
      this.subid = this.sublist[this.changeSelectStyle].id
      axios.get(`/sub_subject/${this.subid}`).then(response => {
        this.insublist = response.data.sub_subjects
      })
    },
    jump () {
      this.empty = 1
    }
  }
}
</script>
<style lang="scss" scoped>
.right {
  margin-top: 20px;
  margin-right: 40px;
}
.aside {
  width: 60px !important;
  position: fixed;
  left: 0px;
  height: 100%;
  background: #ffffff;
  z-index: 100;
}
.el-container {
  height: 100%;
}
.main {
  background: #f4f6f9;
  min-width: 600px;
  height: 100vh;
}
.el-row {
  margin-right: 0px !important;
}
.inmain {
  height: 100%;
  margin-left: 2.9%;
}
.newtext {
  text-align: left;
  font-size: 24px;
  line-height: 33px;
  color: #c0c0c0;
  font-weight: normal;
  margin-top: 17px !important;
  margin-bottom: 10px !important;
}
.kutitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 43px;
  width: 100%;
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 17px;
}

.seasub {
  padding-left: 10px;
  padding-top: 14px;
  height: 20px;
}
.subject {
  height: 41px;
  background: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(204, 209, 217, 0.5);
  margin-bottom: 10px;
  padding-top: 19px;
  border-left: 4px solid white;
  width: calc(100% - 4px);
  cursor: pointer;
}
.subject:hover {
  position: relative;
  left: 5px;
}
.insubtitle {
  font-size: 14px;
  color: #656d78;
  margin: 1px 0 19px 24px;
  text-align: left;
}
.subtitle {
  overflow-y: scroll;
  padding-right: 8px;
}
.changeStyle {
  border-left: 4px solid #8dc152;
}
.neirong {
  display: flex;
  flex-direction: column;
  width: 75.7%;
  padding-left: 2.9%;
  position: relative;
  right: 0px;
}
.subintitle {
  text-align: left;
  padding-bottom: 17px;
  padding-top: 17px;
  font-size: 40px;
  width: 100%;
  margin-bottom: 0px;
  font-weight: normal;
  color: #656d78;
}
.insub:last-child {
  margin-bottom: 20px;
}
.outside {
  border-bottom: 1px solid #c0c0c0;
  width: 100%;
}
.icontran {
  margin-top: 38px;
}
.changekai {
  transform: rotate(0deg) !important;
  transition: 0.3s;
}
.icon1 {
  transform: rotate(-90deg);
  transition: 0.3s;
}
.icon2 {
  transform: rotate(-90deg) !important;
  transition: 0.3s;
}
.changebi {
  transform: rotate(0deg);
  transition: 0.3s;
}
.insubgroup {
  overflow-y: scroll;
  width: 100%;
}
.secondtitle {
  text-align: left;
  font-size: 24px;
  color: #656d78;
  line-height: 33px;
  margin-top: 9px;
}
.secondtext {
  margin-top: 13px;
  font-size: 12px;
  color: #656d78;
  letter-spacing: 0;
  text-align: justify;
  line-height: 20px;
}
.tag {
  display: flex;
  flex-direction: row;
  margin-bottom: 28px;
  flex-wrap: wrap;
}
.intag {
  margin-right: 14px;
  height: 16px;
  margin-top: 18px;
  background-color: #656d78;
  color: white;
  padding: 7px 13px 7px 10px;
  font-size: 12px;
}
.filetitle {
  text-align: left;
  font-size: 24px;
  color: #656d78;
  line-height: 33px;
}
.filegroup {
  margin-top: 14px;
}
.filesize {
  font-size: 12px;
  color: #c0c0c0;
}
.filetext {
  height: 100px;
  padding-right: 30px;
  margin-bottom: 14px;
  min-width: 200px;
}
.infile {
  border: 1px solid #c0c0c0;
  min-width: 150px;
  padding: 17px;
  display: flex;
  flex-direction: row;
}
.filetype {
  position: relative;
  left: 4px;
  top: 0px;
}
.imggroup {
  position: relative;
  top: 2px;
  left: 0px;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 12%;
  margin-right: 20px;
}
.ketitle {
  text-align: left;
  font-size: 24px;
  color: #656d78;
  line-height: 33px;
  margin-bottom: 14px;
}
.downloadgroup {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.downbutton {
  position: relative;
  bottom: 20px;
  font-size: 12px;
}
.filename {
  height: 40px;
  width: 100%;
  min-width: 60px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  font-size: 12px;
  color: #656d78;
  margin: 0 0 0 0;
}
.kelist {
  padding-right: 30px;
  min-width: 200px;
  margin-bottom: 10px;
}
.el-card,
.el-message {
  border-radius: 0px;
  border: 1px solid #c0c0c0;
}
.kename {
  height: 30px;
  text-align: left;
  margin-left: 10px;
  margin-top: 6px;
  font-size: 12px;
  color: #656d78;
}

.image {
  width: 100%;
  display: block;
}
a {
  text-decoration: none;
}
.jtext {
  color: #434a54;
}
</style>
