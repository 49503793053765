<template>
  <div class="aside">
    <changerole></changerole>
    <el-menu router @select="handleSelect">
      <el-menu-item
        :class="$route.path == '/M/Mjurisdict' ? 'is-active' : ''"
        index="/M/Mjurisdict"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">权限<br />管理</div>
          <img class="iconmenu" src="../../assets/icon/权限.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/M/Maccount' ? 'is-active' : ''"
        index="/M/Maccount"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">账户<br />管理</div>
          <img class="iconmenu" src="../../assets/icon/账户管理@3x.png" />
        </el-tooltip>
      </el-menu-item>
    </el-menu>
    <div class="back">
      <img
        @click="logout"
        src="../../assets/icon/退出.png"
        height="20"
        width="20"
      />
    </div>
  </div>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'
import changerole from '../pub/changerole'
export default {
  name: 'mnav',
  components: { changerole },
  methods: {
    handleSelect (key, keyPath) {
      this.$router.replace({ path: `${keyPath}` })
    },
    logout () {
      MessageBox.confirm('是否注销？')
        .then(() => {
          axios.delete('/logout/').then(() => {
            localStorage.removeItem('Authorization')
            this.$router.replace('/login')
          })
        })
        .catch(() => {})
    }
  }
}
</script>
