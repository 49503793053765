<template>
  <div>
    <el-dialog
      destroy-on-close
      :visible="active"
      width="60%"
      :close-on-click-modal="false"
      :before-close="closeDialog"
      :title="dialogT"
    >
      <studentform ref="studentform" :smes="smes" :step="1" />
      <div slot="footer">
        <el-button @click="closeDialog"> 取 消 </el-button>
        <el-button type="primary" @click="reset_password"> 重置密码 </el-button>
        <el-button type="primary" @click="pushfix" :disabled="pending">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import studentform from './studentform'
import { MessageBox } from 'element-ui'

import axios from '../../axios'
export default {
  name: 'editStudent',
  data () {
    return {
      active: false,
      smes: {
        name: '', // 名字
        username: '', // 用户名
        school: '', // 学校
        phone: '', // 手机号
        email: '', // 邮箱
        misc: '', // 备注
        direction: '', // 学生申请方向
        wechat: '', // 微信
        country: '', // 申请国家
        identification: '', // 身份证号码
        address: '', // 联系地址
        planedSpecialty: '', // 未来打算就读方向
        schoolStartTime: '', // 下一个学历的入学时间 20220202
        educationMes: [], // 学员教育信息
        activityMes: [], // 学员活动信息
        scienceMes: [], // 学员学术信息
        computerMes: '', // 计算机水平
        language: {
          // 语言能力
          four: '',
          six: '',
          toefl: '',
          ieltes: '',
          gre: '',
          gmat: '',
          other: ''
        }
      },
      smes_temp: '',
      searchDialog: false,
      pending: false
    }
  },
  components: {
    studentform
  },
  computed: {
    dialogT () {
      return '修改学员信息'
    },
    api () {
      if (this.$store.state.roles.indexOf('规划师') !== -1) {
        return 'planner'
      }
    }
  },
  methods: {
    handlePhoneNum () {
      let reg = /^[0-9]*$/
      this.errorPhoneNum = !reg.test(this.smes.phone)
    },
    openDialog (smes) {
      this.smes = {
        ...this.smes,
        ...smes
      }
      this.smes_temp = JSON.stringify(this.smes)
      this.active = true
    },
    reset_password () {
      MessageBox.confirm('是否重置该用户密码').then(() => {
        axios
          .post('/reset_password', {
            type: 2,
            id: this.smes.id
          })
          .then(response => {
            if (response.status === 200) {
              this.$message({
                showClose: true,
                message: '已重置',
                type: 'success'
              })
            }
          })
          .catch(error => {
            this.$message({
              showClose: true,
              message: `重置失败,${error.response.data.error_message}`,
              type: 'error'
            })
          })
      })
    },
    closeDialog () {
      if (this.smes_temp !== JSON.stringify(this.smes)) {
        MessageBox.confirm('是否放弃更改内容').then(() => {
          this.active = false
          this.smes = { ...this.$options.data().smes }
        })
      } else {
        this.active = false
        this.smes = { ...this.$options.data().smes }
      }
    },
    pushfix () {
      let that = this
      this.$refs.studentform.check(function (valid) {
        if (valid) {
          that.attachments = that.attachments
          delete that.attachments
          MessageBox.confirm('是否修改学员信息').then(() => {
            that.pending = true
            axios
              .put(`/${that.api}/student/${that.smes.id}/`, that.smes)
              .then(response => {
                if (response.status === 200) {
                  that.$message({
                    showClose: true,
                    message: `修改成功`,
                    type: 'success'
                  })
                  setTimeout(() => {
                    window.location.reload()
                  }, 1000)
                }
              })
              .catch(error => {
                that.pending = false
                console.log(error)
              })
          })
        }
      })
    }
  }
}
</script>
