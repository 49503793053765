<template>
  <div>
    <el-tooltip placement="top" effect="light" content="查看文件">
      <el-button type="primary" @click="getfile" size="mini">
        <i class="el-icon-document" style="font-size:22px"></i>
      </el-button>
    </el-tooltip>
    <el-dialog
      title="查看文件"
      :visible.sync="fileDialog"
      width="1090px"
      :close-on-click-modal="true"
    >
      <el-table :data="file" style="width: 100%" max-height="500">
        <el-table-column
          prop="paper_type"
          label="文件类型"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="paper_name"
          label="文件名"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column label="上传日期" align="center" prop="updater.date">
          <template slot-scope="scope">
            <div>{{ formatDate(scope.row.updater.date) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="上传时间" align="center">
          <template slot-scope="scope">
            <div>{{ formatTime(scope.row.updater.date) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="updater.name" label="上传者" align="center">
        </el-table-column>
        <el-table-column label="确认为终稿" align="center">
          <template slot-scope="scope">
            <p v-if="scope.row.isfinal">
              <img class="isok" src="../../../assets/icon/isok.png" />
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot-scope="scope">
            <span class="download" @click="download(scope.row)">
              <img src="../../../assets/icon/下载.png" />
            </span>
            <img
              style="padding:2px  0 0 20px;position:relative;top: 2px;cursor:pointer;"
              v-if="scope.row.updater.is_delete"
              src="../../../assets/icon/删除.png"
              @click="delect(scope.$index, scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="fileDialog = false">返回</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../../axios'
import moment from 'moment'
import { MessageBox } from 'element-ui'
export default {
  data () {
    return {
      fileDialog: false,
      file: []
    }
  },
  props: {
    pid: Number
  },
  methods: {
    getfile () {
      axios
        .get(`/${this.$store.state.roleApi}/project/${this.pid}/paper`)
        .then(res => {
          let arr = res.data.papers
          this.file = arr.reverse()
        })
      this.fileDialog = true
    },
    formatDate (data) {
      return moment(data * 1000).format('YYYY-MM-DD')
    },
    formatTime (data) {
      return moment(data * 1000).format('HH:mm')
    },
    delect (index, row) {
      MessageBox.confirm('是否确认删除').then(() => {
        axios
          .delete(`/${this.$store.state.roleApi}/finalPaper/${row.filekey}`)
          .then(response => {
            if (response.status === 200) {
              this.$message({
                showClose: true,
                message: '删除成功',
                type: 'success'
              })
              this.getfile()
            }
          })
      })
    },
    download (row) {
      let x = new XMLHttpRequest()
      x.open('GET', row.url, true)
      x.responseType = 'blob'
      let that = this
      x.onload = function (e) {
        let url = window.URL.createObjectURL(x.response)
        let a = document.createElement('a')
        a.href = url
        a.download = row.paper_name
        a.click()
      }
      x.send()
    }
  }
}
</script>
