<template>
  <main>
    <div class="title">
      <p>{{ title }}</p>
      <a @click="download">
        <img src="../../assets/icon/下载icon.png" />
      </a>
    </div>
    <iframe :src="url"> </iframe>
  </main>
</template>

<script>
export default {
  name: 'preview',
  data () {
    return {
      url: '',
      title: '',
      img: ''
    }
  },
  created () {
    document.title = '预览'
    this.title = this.$route.query.title
    this.url = this.$route.query.url
    this.img = this.$route.query.img
  },
  methods: {
    download () {
      let x = new XMLHttpRequest()
      x.open('GET', this.img, true)
      x.responseType = 'blob'
      let that = this
      x.onload = function (e) {
        var url = window.URL.createObjectURL(x.response)
        var a = document.createElement('a')
        a.href = url
        a.download = that.title + '.png'
        a.click()
      }
      x.send()
    }
  }
}
</script>

<style lang="scss" scoped>
main {
  background-color: #979797;
}
.title {
  height: 60px;
  background: #000000;
  position: absolute;
  opacity: 0.5;
  width: 100%;
  p {
    font-size: 24px;
    color: #c0c0c0;
    text-align: left;
    margin: 1% 1% 1% 10%;
  }
  a {
    position: absolute;
    top: 20%;
    right: 10%;
    img {
      width: 36px;
      height: 33px;
    }
  }
}
iframe {
  width: 55%;
  height: 99vh;
  border: none;
  overflow: scroll;
}
</style>
