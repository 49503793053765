<template>
  <el-dialog
    destroy-on-close
    :visible="active"
    width="72%"
    :close-on-click-modal="false"
    :before-close="close"
    style="max-height: 90vh"
    title="添加项目"
    custom-class="addScroll"
  >
    <projectform
      :uploadLock.sync="uploadLock"
      :project="project"
      ref="projectform"
    />
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-popover
        ref="popover"
        placement="top"
        width="100"
        trigger="hover"
        content="附件未上传完毕"
        :disabled="!uploadLock"
      >
      </el-popover>
      <span v-popover:popover>
        <el-button
          type="primary"
          :disabled="pending || uploadLock"
          @click="pushProject"
          :loading="loading"
          style="margin-left:10px"
        >
          确 定
        </el-button>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import projectform from './projectform'
import { MessageBox } from 'element-ui'
import axios from '../../axios'
export default {
  name: 'newProject',
  data () {
    return {
      project: {
        id: '',
        student_id: '',
        project_types: [],
        project_type: '',
        channel_type: 'inst', // 0渠道，1顾问
        channel: {}, // channel对应的id
        misc: '', // 备注
        sponser: '', // 支持
        salesman: '', // 销售
        connectTime: '', // 可联系时间
        startTime: '', // 意向开始时间 string
        projectName: '', // 项目名称
        attachments: [], // 附件
        isPacked: false,
        amanager: '',
        cmanager: '',
        adviser: '',
        teacher: ''
      },
      project_temp: '',
      active: false,
      pending: false,
      uploadLock: false,
      loading: false
    }
  },
  components: {
    projectform
  },
  watch: {
    'project.channel': function (newval, oldval) {
      if (typeof newval === 'number' && typeof oldval === 'object') {
        this.project_temp = JSON.stringify(this.project)
      }
    }
  },
  methods: {
    openDialog (project, smes) {
      this.project.id = project.id
      this.project.student_id = smes.id
      this.project.channel_type = project.channel_type
      this.project.amanager = project.amanager
      this.project.cmanager = project.cmanager
      this.project.channel = project.channel || {}
      this.project.sponser = this.$store.state.userid
      this.project.salesman = ''
      this.active = true
      this.project_temp = JSON.stringify(project)
      this.$nextTick(() => {
        this.$refs.projectform.student_misc = smes.misc
        this.$refs.projectform.get_advisers(this.project.channel)
      })
    },
    close () {
      if (this.project_temp !== JSON.stringify(this.project)) {
        MessageBox.confirm('是否放弃更改内容').then(() => {
          this.project = { ...this.$options.data().project }
          this.active = false
          this.pending = false
        })
      } else {
        this.project = { ...this.$options.data().project }
        this.active = false
        this.pending = false
      }
    },
    pushProject () {
      let that = this
      this.$refs.projectform.check(function (valid) {
        if (valid) {
          that.pending = false
          if (that.project_temp === JSON.stringify(that.project)) {
            that.close()
          } else {
            MessageBox.confirm('是否添加这些项目')
              .then(() => {
                that.project.attachments = that.project.attachments
                that.loading = true
                if (!that.project.isPacked) {
                  if (that.project.project_types.length == 0) {
                    that.project.project_types.push(that.project.project_type)
                  } else {
                    that.project.project_types[0] = that.project.project_type
                  }
                }
                axios
                  .post(`/${that.$store.state.roleApi}/project/`, that.project)
                  .then(response => {
                    if (response.status === 200) {
                      that.$message({
                        showClose: true,
                        message: `添加成功`,
                        type: 'success'
                      })
                      setTimeout(() => {
                        window.location.reload()
                      }, 1000)
                    }
                  })
                  .catch(error => {
                    console.log(error)
                    that.loading = false
                  })
              })
              .catch(error => {
                that.pending = false
                console.log(error)
              })
          }
        } else {
          // console.log(that.$refs)
          const base = that.$refs.projectform.$children[0].$el.getBoundingClientRect()
            .top
          for (const validElement of that.$refs.projectform.$children[0]
            .fields) {
            // console.log(validElement.$el._prevClass)
            const isError =
              validElement.$el._prevClass.indexOf('is-error') !== -1
            const isSuccess =
              validElement.$el._prevClass.indexOf('is-success') !== -1
            const isRequire =
              validElement.$el._prevClass.indexOf('is-require') !== -1
            if ((isRequire && !isError && !isSuccess) || isError) {
              // console.log(base, validElement.$el.getBoundingClientRect().top, document.getElementsByClassName('dialogScroll')[0].scrollTop)
              document.getElementsByClassName('addScroll')[0].scrollTop =
                validElement.$el.getBoundingClientRect().top - base
              // document.getElementsByClassName('dialogScroll')[0].scrollTop = 50
              break
            }
          }
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
