<template>
  <div>
    <el-table :row-style="rowStyle"
              :span-method="arraySpanMethod"
              :data="tableData"
              class="base">
      <el-table-column prop="date"
                       label="日期"
                       align="center"
                       min-width="10%">
        <template slot-scope="{ row }">
          {{
            row.type === 'nextMission'
              ? ''
              : row.date
          }}
        </template>
      </el-table-column>
      <el-table-column
        label="导师辅导内容"
        align="left"
        header-align="center"
        min-width="26%"
      >
        <template slot-scope="{ row }">
          <div v-if="row.type === 'nextMission' && row.mission">
            预计下次辅导内容：{{ row.mission }}
          </div>
          <div v-else>{{ row.teacher_work }}</div>

        </template>
      </el-table-column>
      <el-table-column
        label="学生任务"
        align="left"
        header-align="center"
        min-width="29%"
      >
        <template slot-scope="{ row }">
          {{ row.student_work }}
        </template>
      </el-table-column>
      <el-table-column
        prop="deadline"
        label="Deadline"
        align="center"
        min-width="10%"
      >
        <template slot-scope="{ row }">
          {{ row.deadline }}
        </template>
      </el-table-column>
      <el-table-column
        prop="teacher"
        label="导师"
        align="center"
        min-width="10%"
      >
        <template slot-scope="{ row }">
          {{ row.teacher }}
        </template>
      </el-table-column>
      <el-table-column label="确认辅导内容"
                       align="center"
                       min-width="10%">
        <template slot-scope="{ row }">
          <div v-if="row.isconfirm">
            <img class="isok"
                 src="../../assets/icon/isok.png" />
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'chart',
  data () {
    return {
      beizhu: {
        beizhuTitle: '',
        beizhuDialog: false,
        remark: '',
        remark_id: '',
        attend: '',
        finish: '',
        topic: '',
        note: '',
        feed_id: ''
      },
      zhuang: {
        zhuangTitle: '',
        zhuangDialog: false,
        status: '',
        status_id: ''
      },
      status_id: '',
      remark_id: '',
      feed_id: '',
      roles: [],
      a: ''
    }
  },
  props: {
    tableData: {},
    editable: Boolean
  },
  methods: {
    arraySpanMethod ({rowIndex, columnIndex}) {
      const item = this.tableData[rowIndex]
      const nextmission = item.mission
      if (!!nextmission && columnIndex === 1) return [1, 5]
      if (!!nextmission && columnIndex >= 3 && columnIndex <= 5) return [0, 0]
    },
    rowStyle ({row, rowIndex}) {
      const item = this.tableData[rowIndex]
      const nextmission = item.mission
      const type = item.type
      if (type === 'nextMission' && !nextmission) return {display: 'none'}
    }
  }
}
</script>
<style scoped>
.isok {
  width: 26px;
  height: 18px;
}

.img img {
  height: 20px;
  vertical-align: top;
}
</style>
