<template>
  <div class="listTest" ref="element">
    <div class="search flex">
      <div class="seaicon">
        <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
      </div>
      <el-input v-model="stutitle" @input="search" class="seasubfix"></el-input>
      <el-select
        v-model="role"
        popper-class="rolelist"
        class="changerole"
        @change="rolechange"
        v-if="!enterpise"
      >
        <el-option
          v-for="item in options"
          :key="item"
          :value="item"
        ></el-option>
      </el-select>
    </div>
    <el-row>
      <el-col :span="24" class="colorGroup flex">
        <el-tooltip
          placement="top"
          content="全部学生"
          effect="light"
          popper-class="atooltip"
        >
          <div class="first">
            <img
              :class="{ firstyuan: type !== '' }"
              @click="chooseColor1"
              src="../../assets/icon/mixcolor.png"
              height="15"
              width="15"
            />
          </div>
        </el-tooltip>
        <el-tooltip
          placement="top"
          content="正常辅导中"
          effect="light"
          popper-class="atooltip"
        >
          <div
            class="yuan color3"
            :class="{ selectcolor: type === '1' }"
            @click="day_filter(1)"
          ></div>
        </el-tooltip>
        <el-tooltip
          placement="top"
          content="Deadline已超7天"
          effect="light"
          popper-class="atooltip"
        >
          <div
            class="yuan color4"
            :class="{ selectcolor: type === '8' }"
            @click="day_filter(8)"
          ></div>
        </el-tooltip>
        <el-tooltip
          placement="top"
          content="Deadline已超15天"
          effect="light"
          popper-class="atooltip"
        >
          <div
            class="yuan color5"
            :class="{ selectcolor: type === '16' }"
            @click="day_filter(16)"
          ></div>
        </el-tooltip>
        <div
          class="iconDU"
          :class="showSports ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
          @click="showSports = !showSports"
          style="color: #656D78;margin-top: 11px;font-size: 2px"
        ></div>
        <el-tooltip
          placement="top"
          content="Deadline已超30天"
          effect="light"
          popper-class="atooltip"
        >
          <div
            class="yuan color6"
            v-show="showSports"
            :class="{ selectcolor: type === '31' }"
            @click="day_filter(31)"
          ></div>
        </el-tooltip>
        <el-tooltip
          placement="top"
          content="Deadline已超60天"
          effect="light"
          popper-class="atooltip"
        >
          <div
            class="yuan color7"
            v-show="showSports"
            :class="{ selectcolor: type === '61' }"
            @click="day_filter(61)"
          ></div>
        </el-tooltip>
        <el-tooltip
          placement="top"
          content="未辅导学生"
          effect="light"
          popper-class="atooltip"
        >
          <div
            class="yuan color2"
            :class="{ selectcolor: type === null }"
            @click="chooseColor2"
            v-show="showSports"
          ></div>
        </el-tooltip>
      </el-col>
    </el-row>
    <div class="jstudent" v-if="loading">
      <i class="eliwise-loading"></i>加载中请稍后...
    </div>
    <list
      v-else
      ref="list"
      @next="getNext"
      class="jstudent"
      :data="notlist"
      :next="has_next"
      v-slot="{ item, index }"
    >
      <div
        @click="getstu1(index)"
        :class="{ changeStyle: changeSelectStyle === index }"
        class="jstunum"
      >
        <div :class="{ bold: item.important }" class="studentname">
          <span>{{ item.id }}.{{ item.name }}</span>
          <img
            src="../../assets/icon/组合项目.png"
            class="zhimg"
            alt=""
            v-if="item.ispacked === true"
          />
        </div>
        <div>
          <div class="colortag" v-if="!suo">
            <div :class="getClass(item.days, item.started, item.posted)"></div>
            <div
              v-if="item.warning !== false && item.posted === false"
              class="remind"
            ></div>
          </div>
        </div>
      </div>
    </list>
  </div>
</template>

<script>
import axios from '../axios'
import list from './list'
import { debounce } from 'lodash'
export default {
  name: 'scheduleList',
  components: {
    list
  },
  data () {
    return {
      page: 1,
      has_next: true,
      stutitle: '',
      s_name: '',
      t_name: '',
      as_name: '',
      c_name: '',
      adviser: '',
      pro_type: '',
      a_name: '',
      projectName: '',
      s_dir: '',
      isIm: false, // 是否标星
      notlist: [],
      changeSelectStyle: 0,
      type: '',
      suo: false,
      suo2: false,
      loading: false,
      role: '学员',
      showSports: false
    }
  },
  props: {
    url: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      default: []
    },
    enterpise: false
  },
  mounted () {
    this.getstudent()
  },
  methods: {
    day_filter (days) {
      this.resize()
      this.type = days.toString()
      this.getstudent()
    },
    async getNext () {
      if (this.role === '学员') {
        this.s_name = this.stutitle
      } else if (this.role === '导师') {
        this.t_name = this.stutitle
      } else if (this.role === '助教') {
        this.as_name = this.stutitle
      } else if (this.role === '项目') {
        this.pro_type = this.stutitle
      } else if (this.role === '渠道') {
        this.c_name = this.stutitle
      } else if (this.role === '销售顾问') {
        this.adviser = this.stutitle
      } else if (this.role === '规划师') {
        this.a_name = this.stutitle
      } else if (this.role === '方向') {
        this.projectName = this.stutitle
      } else if (this.role === '专业') {
        this.s_dir = this.stutitle
      } else if (this.role === '待发启动') {
        this.s_name = this.stutitle
        this.issend = true
      } else {
        this.s_name = this.stutitle
        this.isIm = true
      }
      await Promise.all([
        axios
          .get(this.url, {
            params: {
              s_name: this.s_name,
              c_name: this.c_name,
              adviser: this.adviser,
              t_name: this.t_name,
              as_name: this.as_name,
              a_name: this.a_name,
              pro_type: this.pro_type,
              projectName: this.projectName,
              s_dir: this.s_dir,
              days: this.type,
              page: this.page,
              important: this.isIm,
              issend: this.issend,
              started: this.type === null ? false : null
            }
          })
          .then(response => {
            this.page++
            this.notlist.push(...response.data.students)
            this.has_next = response.data.has_next
          })
          .catch(error => {
            console.log(error)
          })
      ])
    },
    async getstudent () {
      if (this.role === '学员') {
        this.s_name = this.stutitle
      } else if (this.role === '导师') {
        this.t_name = this.stutitle
      } else if (this.role === '助教') {
        this.as_name = this.stutitle
      } else if (this.role === '项目') {
        this.pro_type = this.stutitle
      } else if (this.role === '渠道') {
        this.c_name = this.stutitle
      } else if (this.role === '销售顾问') {
        this.adviser = this.stutitle
      } else if (this.role === '规划师') {
        this.a_name = this.stutitle
      } else if (this.role === '方向') {
        this.projectName = this.stutitle
      } else if (this.role === '专业') {
        this.s_dir = this.stutitle
      } else if (this.role === '待发启动') {
        this.s_name = this.stutitle
        this.issend = true
      } else {
        this.s_name = this.stutitle
        this.isIm = true
      }
      this.loading = true
      await Promise.all([
        axios
          .get(this.url, {
            params: {
              s_name: this.s_name,
              c_name: this.c_name,
              adviser: this.adviser,
              t_name: this.t_name,
              as_name: this.as_name,
              a_name: this.a_name,
              pro_type: this.pro_type,
              projectName: this.projectName,
              s_dir: this.s_dir,
              days: this.type,
              page: this.page,
              important: this.isIm,
              issend: this.issend
            }
          })
          .then(response => {
            this.page++
            this.loading = false
            this.notlist = response.data.students
            this.has_next = response.data.has_next
          })
          .catch(error => {
            console.log(error)
          })
      ])
      if (this.notlist.length !== 0) {
        this.getfirst()
      }
    },
    getstu1 (index) {
      this.changeSelectStyle = index
      this.$emit('select', this.notlist[index])
    },
    getfirst () {
      this.changeSelectStyle = 0
      this.$emit('select', this.notlist[0])
    },
    getClass (days, started, posted) {
      if (posted) {
        return 'posted'
      } else if (days > 60) {
        return 'day60'
      } else if (days > 30 && days <= 60) {
        return 'day30'
      } else if (days > 15 && days <= 30) {
        return 'day15'
      } else if (days > 7 && days <= 15) {
        return 'day7'
      } else if (days > 0 && days <= 7) {
        return 'day0'
      } else if (days === 0 && started === true) {
        return 'day0'
      } else if (days === 0 && started === false) {
        return 'dayfull'
      }
    },
    rolechange () {
      this.stutitle = ''
      this.s_name = ''
      this.c_name = ''
      this.adviser = ''
      this.t_name = ''
      this.pro_type = ''
      this.as_name = ''
      this.a_name = ''
      this.projectName = ''
      this.s_dir = ''
      this.type = ''
      this.page = 1
      if (this.role === '待发启动') {
        this.issend = true
      } else {
        this.issend = false
      }
      if (this.role === '标星') {
        this.isIm = true
      } else {
        this.isIm = false
      }
      this.loading = true
      axios
        .get(this.url, {
          params: {
            s_name: this.s_name,
            c_name: this.c_name,
            adviser: this.adviser,
            t_name: this.t_name,
            as_name: this.as_name,
            a_name: this.a_name,
            pro_type: this.a_name,
            projectName: this.projectName,
            s_dir: this.s_dir,
            days: this.type,
            page: 1,
            important: this.isIm,
            issend: this.issend
          }
        })
        .then(response => {
          this.notlist = response.data.students
          this.has_next = response.data.has_next
          this.page++
          this.loading = false
          if (this.notlist.length !== 0) {
            this.getfirst()
          }
        })
    },
    search: debounce(function () {
      this.resize()
      this.getstudent()
    }, 500),
    resize () {
      this.$refs.list.$el.scrollTop = 0
      this.page = 1
      this.has_next = false
    },
    chooseColor1 () {
      this.resize()
      this.type = ''
      this.getstudent()
    },
    async chooseColor2 () {
      this.resize()
      this.type = null
      if (this.role === '学员') {
        this.s_name = this.stutitle
      } else if (this.role === '导师') {
        this.t_name = this.stutitle
      } else if (this.role === '助教') {
        this.as_name = this.stutitle
      } else if (this.role === '项目') {
        this.pro_type = this.stutitle
      } else if (this.role === '渠道') {
        this.c_name = this.stutitle
      } else if (this.role === '销售顾问') {
        this.adviser = this.stutitle
      } else if (this.role === '规划师') {
        this.a_name = this.stutitle
      } else if (this.role === '方向') {
        this.projectName = this.stutitle
      } else if (this.role === '方向') {
        this.s_dir = this.stutitle
      } else if (this.role === '待发启动') {
        this.s_name = this.stutitle
        this.issend = true
      } else {
        this.s_name = this.stutitle
        this.isIm = true
      }
      this.loading = true
      await Promise.all([
        axios
          .get(this.url, {
            params: {
              s_name: this.s_name,
              c_name: this.c_name,
              adviser: this.adviser,
              t_name: this.t_name,
              as_name: this.as_name,
              a_name: this.a_name,
              pro_type: this.pro_type,
              projectName: this.projectName,
              s_dir: this.s_dir,
              days: this.type,
              page: this.page,
              important: this.isIm,
              started: false,
              issend: this.issend
            }
          })
          .then(response => {
            this.page++
            this.notlist = response.data.students.filter(
              item => item.started === false
            )
            this.loading = false
            this.has_next = response.data.has_next
          })
          .catch(error => {
            console.log(error)
          })
      ])
      if (this.notlist.length !== 0) {
        this.getfirst()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.colorGroup {
  justify-content: left;
  flex-wrap: wrap;
  height: auto;
  //overflow: hidden;
  position: relative;
  padding: 0 4px;
  .first {
    height: 2px;
    cursor: pointer;
    margin-right: 16%;
    margin-top: 7px;
  }
  .iconDU {
    position: absolute;
    right: 4px;
  }
  .yuan {
    width: 12px;
    height: 12px;
    //margin-top: 2px;
    margin: 10px 16% 0 0;
    border-radius: 50%;
    cursor: pointer;
  }
  .color2 {
    background-color: #c0c0c0;
  }
  .color3 {
    background-color: #a0d469;
  }
  .color4 {
    background-color: #ffce54;
  }
  .color5 {
    background-color: #fc6e51;
  }
  .color6 {
    background-color: #ac92ec;
    margin-left: calc(16% + 15px);
  }
  .color7 {
    background-color: #656d78;
  }
}
.selectcolor {
  width: 15px !important;
  height: 15px !important;
  margin-top: 8px !important;
}
.remind {
  border-left: solid 1px white;
  border-bottom: solid 1px white;
  height: 5.5px;
  width: 5.5px;
  position: relative;
  bottom: 12px;
  left: 6px;
}
.dayfull {
  background-color: #c0c0c0;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}
.day0 {
  background-color: #a0d469;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}
.day7 {
  background-color: #ffce54;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}
.day15 {
  background-color: #fc6e51;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}
.day30 {
  background-color: #ac92ec;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}
.day60 {
  background-color: #656d78;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}
.posted {
  background-image: url(../../assets/icon/投稿中icon.png);
  background-size: cover;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}
.days {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: 0px;
}
</style>
