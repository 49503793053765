<template>
  <el-dialog title="编辑" :visible.sync="dialogEdit" width="950px">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="120px"
      style="margin-top: 20px"
    >
      <el-row>
        <el-col :span="8">
          <el-form-item label="导师姓名">
            <el-input
              v-model="form.teacher"
              disabled
              class="inputwidth"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="学生姓名" label-width="150px">
            <el-input
              v-model="form.student"
              disabled
              class="inputwidth"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="项目类型">
            <el-input
              v-model="form.project_type"
              disabled
              class="inputwidth"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="导师身份*" prop="t_identity">
            <el-select
              v-model="form.t_identity"
              placeholder="请选择"
              class="inputwidth"
              disabled="true"
            >
              <el-option v-for="item in options" :key="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="导师星级" label-width="150px">
            <el-select
              v-model="form.t_star"
              placeholder="请选择"
              class="inputwidth"
              @change="starChange()"
            >
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="启动时间">
            <el-input
              v-model="form.start_time"
              disabled
              class="inputwidth"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="完成时间">
            <el-input
              v-model="form.end_time"
              disabled
              class="inputwidth"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            label="项目历时时长（天）*"
            prop="project_time"
            label-width="150px"
          >
            <el-input v-model="form.project_time" class="inputwidth"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="辅导时长（h）*" prop="work_time">
            <el-input v-model="form.work_time" class="inputwidth"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="时薪">
            <el-input
              v-model="form.hourly_wage"
              disabled
              class="inputwidth"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="小计*" prop="subtotal" label-width="150px">
            <el-input v-model="form.subtotal" class="inputwidth"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="奖励金额*" prop="reward">
            <el-input v-model="form.reward" class="inputwidth"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="辅导工资*" prop="project_wage">
            <el-input
              v-model="form.project_wage"
              disabled
              class="inputwidth"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="备注" label-width="150px">
            <el-input v-model="form.remark" class="inputwidth"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogEdit = false">取 消</el-button>
      <el-button type="primary" @click="putedit1">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from '../axios.js'
export default {
  name: 'conclusionDialog',
  props: {
    form: {}
  },
  data () {
    return {
      dialogEdit: false,
      rules: {
        t_identity: [
          {
            required: true,
            message: '请输入导师身份'
          }
        ],
        t_star: [
          {
            required: true,
            message: '请输入导师星级'
          }
        ],
        start_time: [
          {
            required: true,
            message: '请输入启动时间'
          }
        ],
        project_time: [
          {
            required: true,
            message: '请输入项目时长'
          }
        ],
        work_time: [
          {
            required: true,
            message: '请输入辅导时长'
          }
        ],
        hourly_wage: [
          {
            required: true,
            message: '请输入时薪'
          }
        ],
        subtotal: [
          {
            required: true,
            message: '请输入小计'
          }
        ],
        reward: [
          {
            required: true,
            message: '请输入奖励金额'
          }
        ],
        project_wage: [
          {
            required: true,
            message: '请输入辅导工资'
          }
        ]
      },
      options: ['博士毕业', '博士在读'],
      options2: [
        {
          value: '1',
          label: '1'
        },
        {
          value: '2',
          label: '2'
        },
        {
          value: '3',
          label: '3'
        },
        {
          value: '4',
          label: '4'
        },
        {
          value: '5',
          label: '5'
        }
      ]
    }
  },
  methods: {
    openDialog () {
      this.dialogEdit = true
    },
    putedit1 () {
      axios
        .put(`/finance/conclusion/${this.form.id}`, {
          form: this.form
        })
        .then(res => {
          if (res.status == 200) {
            this.$parent.getconclusions()
            this.dialogEdit = false
            this.$message({
              message: '修改成功',
              type: 'success'
            })
          }
        })
    },
    starChange () {
      if (this.form.hourly_wage !== '0.00') {
        switch (this.form.t_star) {
          case '1':
            this.form.hourly_wage = 100
            break
          case '2':
            this.form.hourly_wage = 120
            break
          case '3':
            this.form.hourly_wage = 150
            break
          case '4':
            this.form.hourly_wage = 180
            break
          case '5':
            this.form.hourly_wage = 200
            break
        }
        this.subtotalChange()
      }
    },
    subtotalChange () {
      this.form.subtotal = this.form.hourly_wage * this.form.work_time
      this.salaryChange()
    },
    salaryChange () {
      this.form.project_wage =
        Number(this.form.subtotal) + Number(this.form.reward)
    }
  },
  watch: {
    t_star: 'starChange',
    work_time: 'subtotalChange',
    subtotal: 'salaryChange',
    reward: 'salaryChange'
  }
}
</script>

<style scoped>
.inputwidth {
  width: 135px;
}
/deep/ [data-v-abf932de] .el-dialog .el-input__inner {
  width: 135px !important;
}
/deep/ .el-dialog__header {
  padding-left: 65px;
}
/deep/ .el-dialog {
  padding-right: 30px;
}
/deep/ .el-dialog__footer {
  padding-right: 50px;
}
</style>
