<template>
  <el-main class="main">
    <div>
      <div class="jtitle">
        <h2 class="jtext">账户管理</h2>
      </div>
      <div class="table">
        <div class="search flex">
          <div class="seaicon">
            <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
          </div>
          <el-input v-model="accmessage" @input="search" class="seasubfix">
          </el-input>
          <el-select
            v-model="role"
            popper-class="changerolelist"
            class="changerole"
            @change="rolechange"
          >
            <el-option v-for="item in options" :key="item" :value="item">
            </el-option>
          </el-select>
          <div class="add" v-if="role === '菁英'">
            <img
              @click="adduser"
              src="../../assets/icon/添加用户.png"
              height="30"
              width="30"
            />
          </div>
        </div>
        <div v-show="role === '学员'">
          <el-table
            :data="tableData1"
            ref="tableScroll1"
            :height="tableHeight"
            class="tab"
            @scroll.native="load1()"
          >
            <el-table-column
              key="2"
              prop="id"
              label="编号"
              align="center"
              min-width="10%"
            >
            </el-table-column>
            <el-table-column
              key="3"
              prop="name"
              label="姓名"
              align="center"
              min-width="15%"
            >
            </el-table-column>
            <el-table-column
              key="4"
              prop="username"
              label="用户名"
              align="center"
              min-width="8%"
            >
            </el-table-column>
            <el-table-column key="39" prop="编辑" align="center" min-width="4%"
              ><template slot-scope="scope">
                <el-button
                  type="primary"
                  @click="sturepass(scope.$index, scope.row)"
                >
                  重置密码
                </el-button>
              </template>
            </el-table-column>
            <el-table-column key="10" prop="删除" align="center" min-width="4%"
              ><template slot-scope="scope">
                <el-button
                  type="warning"
                  class="delete"
                  @click="sDel(scope.$index, scope.row)"
                >
                  删除账号
                </el-button>
              </template>
            </el-table-column>
            <div slot="append" style="text-align: center; font-size: 16px">
              <span v-if="has_next">
                <i class="eliwise-loading"></i>加载中请稍后...
              </span>
              <i v-else>--没有数据了--</i>
            </div>
          </el-table>
        </div>
        <div v-show="role === '导师'">
          <el-table
            :data="tableData2"
            :height="tableHeight"
            ref="tableScroll2"
            class="tab"
            @scroll.native="load2()"
          >
            <el-table-column
              key="11"
              prop="id"
              label="编号"
              align="center"
              min-width="6%"
            >
            </el-table-column>
            <el-table-column
              key="12"
              prop="name"
              label="姓名"
              align="center"
              min-width="10%"
            >
            </el-table-column>
            <el-table-column
              key="12.1"
              prop="username"
              label="用户名"
              align="center"
              min-width="10%"
            >
            </el-table-column>
            <el-table-column key="21" prop="编辑" align="center" min-width="4%"
              ><template slot-scope="scope">
                <el-button
                  type="primary"
                  @click="repassword(scope.$index, scope.row)"
                >
                  重置密码
                </el-button>
              </template>
            </el-table-column>
            <el-table-column key="22" prop="删除" align="center" min-width="4%">
              <template slot-scope="scope">
                <el-button
                  type="warning"
                  class="delete"
                  :disabled="scope.row.number > 0"
                  @click="tDel(scope.$index, scope.row)"
                >
                  删除账号
                  <!-- <i class="el-icon-delete"></i> -->
                </el-button>
              </template>
            </el-table-column>
            <div slot="append" style="text-align: center; font-size: 16px">
              <span v-if="has_next">
                <i class="eliwise-loading"></i>加载中请稍后...
              </span>
              <i v-else>--没有数据了--</i>
            </div>
          </el-table>
        </div>
        <div v-show="role === '菁英'">
          <el-table :data="tableData3" :height="tableHeight" class="tab">
            <el-table-column
              key="24"
              prop="name"
              label="姓名"
              align="center"
              min-width="15%"
            >
            </el-table-column>
            <el-table-column
              key="25"
              prop="username"
              label="用户名"
              align="center"
              min-width="20%"
            >
            </el-table-column>
            <el-table-column
              key="26.1"
              prop="work"
              label="部门"
              align="center"
              min-width="20%"
            >
            </el-table-column>
            <el-table-column
              key="26.2"
              prop="cellphone"
              label="电话"
              align="center"
              min-width="20%"
            >
            </el-table-column>
            <el-table-column
              key="26"
              prop="email"
              label="邮箱"
              align="center"
              min-width="20%"
            >
            </el-table-column>
            <el-table-column
              key="26.9"
              prop="编辑"
              align="center"
              min-width="10%"
              ><template slot-scope="scope">
                <el-button
                  type="primary"
                  @click="editjy(scope.$index, scope.row)"
                >
                  编辑
                </el-button>
              </template>
            </el-table-column>
            <el-table-column key="28" prop="编辑" align="center" min-width="10%"
              ><template slot-scope="scope">
                <el-button
                  type="primary"
                  @click="jyrepass(scope.$index, scope.row)"
                >
                  重置密码
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              key="29"
              prop="删除"
              align="center"
              min-width="10%"
            >
              <template slot-scope="scope">
                <el-button
                  type="warning"
                  @click="jDel(scope.$index, scope.row)"
                >
                  删除账号
                </el-button>
              </template>
            </el-table-column>
            <div slot="append" style="text-align: center; font-size: 16px">
              <span v-if="has_next">
                <i class="eliwise-loading"></i>加载中请稍后...
              </span>
              <i v-else>--没有数据了--</i>
            </div>
          </el-table>
        </div>
        <div v-show="role === '渠道'">
          <el-table
            :data="tableData4"
            :height="tableHeight"
            ref="tableScroll4"
            class="tab"
          >
            <el-table-column
              prop="id"
              label="编号"
              align="center"
              min-width="15%"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="品牌名"
              align="center"
              min-width="20%"
            >
            </el-table-column>
            <el-table-column
              prop="company"
              label="公司名"
              align="center"
              min-width="20%"
            >
            </el-table-column>
            <el-table-column
              prop="username"
              label="用户名"
              align="center"
              min-width="20%"
            >
            </el-table-column>
            <el-table-column align="center" min-width="10%">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  @click="erepsw(scope.$index, scope.row)"
                >
                  重置密码
                </el-button>
              </template>
            </el-table-column>
            <el-table-column align="center" min-width="10%">
              <template slot-scope="scope">
                <el-button
                  type="warning"
                  @click="eDel(scope.$index, scope.row)"
                >
                  删除账号
                </el-button>
              </template>
            </el-table-column>
            <div slot="append" style="text-align: center; font-size: 16px">
              <span v-if="has_next">
                <i class="eliwise-loading"></i>加载中请稍后...
              </span>
              <i v-else>--没有数据了--</i>
            </div>
          </el-table>
        </div>
        <el-dialog title="编辑账户信息" :visible.sync="jyVisible" width="70%">
          <el-form :rules="rules" :model="jy" ref="jy">
            <el-row>
              <el-col :span="12" class="inputleft">
                <el-form-item prop="jyname">
                  <div>姓名</div>
                  <el-input v-model="jy.jyname" placeholder="姓名"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" class="inputright">
                <el-form-item prop="jyuser">
                  <div>用户名</div>
                  <el-input
                    :disabled="true"
                    v-model="jy.jyuser"
                    placeholder="用户名"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12" class="inputleft">
                <el-form-item prop="jyposition">
                  <div>部门*</div>
                  <el-select
                    v-model="jy.jyposition"
                    placeholder="请选择部门"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in posis"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" class="inputright">
                <el-form-item prop="jycall">
                  <div>电话</div>
                  <el-input v-model="jy.jycall" placeholder="电话"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" class="inputleft">
                <el-form-item prop="jyemail">
                  <div>邮箱*</div>
                  <el-input v-model="jy.jyemail" placeholder="邮箱"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="jyVisible = false">取 消</el-button>
            <el-button @click="pushjy('jy')" type="primary">确 定</el-button>
          </span>
        </el-dialog>
        <el-dialog
          title="添加用户"
          :visible.sync="useradd"
          width="60%"
          :close-on-click-modal="false"
        >
          <el-radio-group class="radiogroup" v-model="chooseType">
          </el-radio-group>
          <el-form :model="jmes" :rules="rules" ref="jmes">
            <div>
              <el-row>
                <el-col :span="12" class="inputleft">
                  <el-form-item prop="name">
                    <div class="title">姓名*</div>
                    <el-input
                      placeholder="姓名"
                      v-model="jmes.name"
                      class="input"
                      maxlength="10"
                      autocomplete="name"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" class="inputright">
                  <el-form-item prop="username">
                    <div class="title">用户名*</div>
                    <el-input
                      placeholder="用户名"
                      v-model="jmes.username"
                      class="input"
                      autocomplete="username"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12" class="inputleft">
                  <el-form-item prop="work">
                    <div class="title">部门*</div>
                    <el-select
                      v-model="jmes.work"
                      placeholder="请选择部门"
                      style="width: 100%"
                      required
                    >
                      <el-option
                        v-for="item in posis"
                        :key="item.value"
                        :label="item.label"
                        :value="item.label"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12" class="inputright">
                  <el-form-item prop="cellphone">
                    <div class="title">电话*</div>
                    <el-input
                      placeholder="电话"
                      v-model="jmes.cellphone"
                      maxlength="64"
                      class="input"
                      autocomplete="cellphone"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" class="inputleft">
                  <el-form-item prop="email">
                    <div class="title">邮箱*</div>
                    <el-input
                      placeholder="邮箱"
                      v-model="jmes.email"
                      maxlength="64"
                      class="input"
                      autocomplete="email"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-form>
          <div slot="footer">
            <el-button @click="quituseradd('jmes')">取 消</el-button>
            <el-button type="primary" @click="pushadd('jmes')">
              确 定
            </el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </el-main>
</template>
<script>
/* eslint-disable no-console */
import axios from '../axios'
import { MessageBox } from 'element-ui'
import _ from 'lodash'
export default {
  name: 'Maccount',
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入性别'))
      } else if (value === '男' || value === '女') {
        callback()
      } else {
        callback(new Error('请输入正确字符，“男”或“女”'))
      }
    }
    return {
      posis: [
        {
          value: '选项1',
          label: '市场部'
        },
        {
          value: '选项2',
          label: '教学产品部'
        },
        {
          value: '选项3',
          label: '教学管理部'
        },
        {
          value: '选项4',
          label: '学术委员会'
        }
      ],
      chooseTypefix: null,
      rules: {
        password: [{ required: true, message: '请填写密码', trigger: 'blur' }],
        username: [
          { required: true, message: '请填写用户名', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
        email: [{ required: true, message: '请填写邮箱', trigger: 'blur' }],
        direction: [{ required: true, message: '请填写方向', trigger: 'blur' }],
        research_direction: [
          { required: true, message: '请填写研究方向', trigger: 'blur' }
        ],
        school: [
          { required: true, message: '请填写学校名称', trigger: 'blur' }
        ],
        cellphone: [
          { required: true, message: '请填写电话号码', trigger: 'blur' }
        ],
        sex: [{ validator: validatePass, trigger: 'blur' }],
        work: [{ required: true, message: '请填写职位', trigger: 'change' }],
        organization: [
          { required: true, message: '请填写机构名称', trigger: 'blur' }
        ],
        salesman: [
          { required: true, message: '请选择销售人员', trigger: 'blur' }
        ],
        sponser: [{ required: true, message: '请选择规划师', trigger: 'blur' }],
        jyname: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
        jyposition: [
          { required: true, message: '请填写部门', trigger: 'change' }
        ],
        jyemail: [{ required: true, message: '请填写邮箱', trigger: 'blur' }]
      },
      tmes: {
        star: '',
        birthday: '',
        name: '',
        username: '',
        organization: '',
        inst_email: '',
        cellphone: '',
        email: '',
        misc: '',
        research_direction: '',
        address: '',
        paper: '',
        scientific_research: ''
      },
      smes: {
        name: '',
        username: '',
        school: '',
        cellphone: '',
        email: '',
        misc: '',
        direction: '',
        wechat: '',
        sponser: '',
        salesman: '',
        sex: ''
      },
      jmes: {
        name: '',
        username: '',
        work: '',
        cellphone: '',
        email: '',
        password: ''
      },
      tableHeight: window.innerHeight - 150,
      tableData1: [],
      tableData2: [],
      tableData3: [],
      tableData4: [],
      useradd: false,
      jy: {
        jyname: '',
        jyposition: '',
        jycall: '',
        jyuser: '',
        jyemail: ''
      },
      options: ['学员', '导师', '菁英', '渠道'],
      sales: [],
      colors: {
        2: '#A0D469',
        4: { value: '#A0D469', excluded: true },
        5: '#A0D469'
      },
      role: '菁英',
      accmessage: '',
      chooseType: 1,
      islookcolumn: false,
      userid: '',
      page: 1,
      has_next: true,
      activeInedx: 0,
      jyVisible: false,
      editid: ''
    }
  },

  created () {
    this.getSponser()
  },
  mounted () {
    const that = this
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 150
      })()
    }
  },
  methods: {
    jyrepass (index, row) {
      MessageBox.confirm('是否重置该用户密码').then(() => {
        axios
          .post('/reset_password', {
            type: 3,
            id: row.id
          })
          .then(response => {
            if (response.status === 200) {
              this.$message({
                showClose: true,
                message: '已重置',
                type: 'success'
              })
            }
          })
          .catch(error => {
            this.$message({
              showClose: true,
              message: `重置失败,${error.response.data.error_message}`,
              type: 'error'
            })
          })
      })
    },
    pushjy (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          axios
            .put(`/admin/sponser/${this.editid}/`, {
              cellphone: this.jy.jycall,
              name: this.jy.jyname,
              work: this.jy.jyposition,
              email: this.jy.jyemail
            })
            .then(response => {
              if (response.status === 200) {
                this.$message({
                  showClose: true,
                  message: '添加成功',
                  type: 'success'
                })
                window.location.reload()
              }
            })
        }
      })
    },
    editjy (index, row) {
      this.jyVisible = true
      this.editid = row.id
      this.jy.jyname = row.name
      this.jy.jyuser = row.username
      this.jy.jyposition = row.work
      this.jy.jycall = row.cellphone
      this.jy.jyemail = row.email
    },
    sturepass (index, row) {
      MessageBox.confirm('是否重置该用户密码').then(() => {
        axios
          .post('/reset_password', {
            type: 2,
            id: row.id
          })
          .then(response => {
            if (response.status === 200) {
              this.$message({
                showClose: true,
                message: '已重置',
                type: 'success'
              })
            }
          })
          .catch(error => {
            this.$message({
              showClose: true,
              message: `重置失败,${error.response.data.error_message}`,
              type: 'error'
            })
          })
      })
    },
    load2 () {
      let dom2 = this.$refs.tableScroll2.$el
      let scrollTop2 = dom2.scrollTop
      let windowHeight2 = dom2.clientHeight || dom2.clientHeight
      let scrollHeight2 = dom2.scrollHeight || dom2.scrollHeight
      if (
        scrollTop2 + windowHeight2 >= scrollHeight2 - 1 &&
        this.has_next &&
        this.role === '导师'
      ) {
        this.page = this.page + 1
        axios
          .get('/admin/teachers/', {
            params: {
              name: this.accmessage,
              page: this.page
            }
          })
          .then(response => {
            if (response.data.has_previous) {
              this.tableData2 = this.tableData2.concat(response.data.teachers)
            }
            this.has_next = response.data.has_next
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    load1 () {
      let dom = this.$refs.tableScroll1.$el
      let scrollTop = dom.scrollTop
      let windowHeight = dom.clientHeight || dom.clientHeight
      let scrollHeight = dom.scrollHeight || dom.scrollHeight
      if (
        scrollTop + windowHeight >= scrollHeight - 1 &&
        this.has_next &&
        this.role === '学员'
      ) {
        this.page = this.page + 1
        axios
          .get('/admin/students/', {
            params: {
              name: this.accmessage,
              page: this.page
            }
          })
          .then(response => {
            if (response.data.has_previous) {
              this.tableData1 = this.tableData1.concat(response.data.students)
            }
            this.has_next = response.data.has_next
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    load4 () {
      this.dom4 = this.$refs.tableScroll4.dom.$el
      let scrollTop4 = this.dom4.scrollTop
      let windowHeight4 = this.dom4.clientHeight || this.dom4.clientHeight
      let scrollHeight4 = this.dom4.scrollHeight || this.dom4.scrollHeight
      if (
        scrollTop4 + windowHeight4 >= scrollHeight4 - 1 &&
        this.has_next &&
        this.role === '渠道'
      ) {
        this.page = this.page + 1
        axios
          .get('/admin/enterpises', {
            params: {
              name: this.accmessage,
              page: this.page
            }
          })
          .then(response => {
            if (response.data.has_previous) {
              this.tableData4 = this.tableData4.concat(response.data.enterpises)
            }
            this.has_next = response.data.has_next
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    erepsw (index, row) {
      MessageBox.confirm('是否重置该用户密码').then(() => {
        axios
          .post('/reset_password', {
            type: 4,
            id: row.id
          })
          .then(response => {
            if (response.status === 200) {
              this.$message({
                showClose: true,
                message: '已重置',
                type: 'success'
              })
            }
          })
          .catch(error => {
            this.$message({
              showClose: true,
              message: `重置失败,${error.response.data.error_message}`,
              type: 'error'
            })
          })
      })
    },
    search () {
      this.has_next = true
      this.page = 1
      if (this.role === '学员') {
        this.getS()
      } else if (this.role === '导师') {
        this.getT()
      } else if (this.role === '菁英') {
        this.getSponser()
      } else if (this.role === '渠道') {
        this.getE()
      }
    },
    getS: _.debounce(function () {
      this.getStudent()
    }, 500),
    getT: _.debounce(function () {
      this.getTeacher()
    }, 500),
    getE: _.debounce(function () {
      this.getEnterpise()
    }, 500),
    getStudent () {
      axios
        .get('/admin/students/', {
          params: {
            name: this.accmessage,
            page: this.page
          }
        })
        .then(response => {
          this.tableData1 = response.data.students
          this.has_next = response.data.has_next
        })
        .catch(error => {
          console.log(error)
        })
    },
    getTeacher () {
      axios
        .get('/admin/teachers/', {
          params: {
            name: this.accmessage,
            page: this.page
          }
        })
        .then(response => {
          this.tableData2 = response.data.teachers
          this.has_next = response.data.has_next
        })
        .catch(error => {
          console.log(error)
        })
    },
    getSponser () {
      axios
        .get('/admin/sponsers/', {
          params: {
            name: this.accmessage
          }
        })
        .then(response => {
          this.tableData3 = response.data.sponser
          this.has_next = response.data.has_next
        })
        .catch(error => {
          console.log(error)
        })
    },
    getEnterpise () {
      axios
        .get('/admin/enterpises', {
          params: {
            name: this.accmessage,
            page: this.page
          }
        })
        .then(response => {
          this.tableData4 = response.data.enterpises
        })
        .catch(error => {
          console.log(error)
        })
    },
    addSponser (formName) {
      const { username, name, work, cellphone, email } = this.jmes
      this.$refs[formName].validate(valid => {
        if (
          username === undefined ||
          name === undefined ||
          work === undefined ||
          cellphone === undefined ||
          email === undefined
        ) {
          this.$message({
            message: '请填写信息',
            type: 'warning'
          })
          return true
        } else {
          axios
            .post('/admin/sponser/', {
              username: this.jmes.username,
              name: this.jmes.name,
              work: this.jmes.work,
              cellphone: this.jmes.cellphone,
              email: this.jmes.email
            })
            .then(response => {
              if (response.status === 200) {
                this.$message({
                  showClose: true,
                  message: '添加成功',
                  type: 'success'
                })
                this.getSponser()
                window.location.reload()
              }
            })
            .catch(error => {
              this.$message({
                showClose: true,
                message: `添加失败,${error.response.data.error_message}`,
                type: 'error'
              })
            })
        }
      })
    },
    addStudent () {
      axios
        .post('/admin/student/', this.smes)
        .then(response => {
          if (response.status === 200) {
            this.$message({
              showClose: true,
              message: '添加成功',
              type: 'success'
            })
            this.page = 1
            this.has_next = true
            this.getStudent()
          }
        })
        .catch(error => {
          this.$message({
            showClose: true,
            message: `添加失败,${error.response.data.error_message}`,
            type: 'error'
          })
        })
    },
    addTeacher () {
      this.tmes.star = this.tmes.star + 1
      axios
        .post('/admin/teacher/', this.tmes)
        .then(response => {
          if (response.status === 200) {
            this.$message({
              showClose: true,
              message: '添加成功',
              type: 'success'
            })
            this.page = 1
            this.has_next = true
            this.getTeacher()
          }
        })
        .catch(error => {
          this.$message({
            showClose: true,
            message: `添加失败,${error.response.data.error_message}`,
            type: 'error'
          })
        })
    },
    rolechange () {
      this.page = 1
      this.accmessage = ''
      this.has_next = true
      if (this.role === '学员') {
        this.getStudent()
      } else if (this.role === '导师') {
        this.getTeacher()
      } else if (this.role === '菁英') {
        this.getSponser()
      } else if (this.role === '渠道') {
        this.getEnterpise()
      }
    },
    adduser () {
      axios
        .get(`/admin/sponser_list/`)
        .then(response => {
          if (response.status === 200) {
            this.sales = response.data.supports
          }
        })
        .catch(error => {
          console.log(error)
        })
      this.useradd = true
      this.jmes = {}
      this.tmes = {}
      this.smes = {}
    },
    fixuser (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          MessageBox.confirm('是否修改该用户信息').then(() => {
            if (this.role === '导师') {
              axios
                .put(`/admin/teacher/${this.userid}/`, this.tmes)
                .then(response => {
                  if (response.status === 200) {
                    this.$message({
                      showClose: true,
                      message: '修改成功',
                      type: 'success'
                    })
                    this.tableData2[this.activeInedx] = this.tmes
                  }
                })
                .catch(error => {
                  this.$message({
                    showClose: true,
                    message: `修改失败,${error.response.data.error_message}`,
                    type: 'error'
                  })
                })
            } else if (this.role === '学员') {
              axios
                .put(`/admin/student/${this.userid}/`, this.smes)
                .then(response => {
                  if (response.status === 200) {
                    this.$message({
                      showClose: true,
                      message: '修改成功',
                      type: 'success'
                    })
                    this.tableData1[this.activeInedx] = this.smes
                  }
                })
                .catch(error => {
                  this.$message({
                    showClose: true,
                    message: `修改失败,${error.response.data.error_message}`,
                    type: 'error'
                  })
                })
            } else {
              axios
                .put(`/admin/sponser/${this.userid}/`, this.jmes)
                .then(response => {
                  if (response.status === 200) {
                    this.$message({
                      showClose: true,
                      message: '修改成功',
                      type: 'success'
                    })
                    this.tableData3[this.activeInedx] = this.jmes
                    this.getSponser()
                  }
                })
                .catch(error => {
                  this.$message({
                    showClose: true,
                    message: `修改失败,${error.response.data.error_message}`,
                    type: 'error'
                  })
                })
            }
          })
        } else {
          return false
        }
      })
    },
    pushadd (formName) {
      this.addSponser(formName)
    },
    jDel (index, row) {
      MessageBox.confirm('是否删除该用户').then(() => {
        axios.delete(`/admin/sponser/${row.id}`).then(response => {
          if (response.status === 200) {
            this.$message({
              showClose: true,
              message: '已删除',
              type: 'success'
            })
            this.getSponser()
          }
        })
      })
    },
    eDel (index, row) {
      MessageBox.confirm('是否删除该用户').then(() => {
        axios
          .delete(`/admin/enterpise/${row.id}`)
          .then(response => {
            if (response.status === 200) {
              this.$message({
                showClose: true,
                message: '已删除',
                type: 'success'
              })
              this.getEnterpise()
            }
          })
          .catch(error => {
            this.$message({
              showClose: true,
              message: `删除失败,${error.response.data.error_message}`,
              type: 'error'
            })
          })
      })
    },
    sDel (index, row) {
      MessageBox.confirm('是否删除该用户').then(() => {
        axios
          .delete(`/admin/student/${row.id}/`, {
            student: row.id
          })
          .then(response => {
            if (response.status === 200) {
              this.$message({
                showClose: true,
                message: '已删除',
                type: 'success'
              })
              this.getStudent()
              window.location.reload()
            }
          })
          .catch(error => {
            console.log('error', error)
            this.$message({
              showClose: true,
              message: `删除失败,${error.response.data.error_message}`,
              type: 'error'
            })
          })
      })
    },
    tDel (index, row) {
      MessageBox.confirm('是否删除该用户').then(() => {
        axios
          .delete(`/admin/teacher/${row.id}`, {
            teacher: row.id
          })
          .then(response => {
            if (response.status === 200) {
              this.$message({
                showClose: true,
                message: '已删除',
                type: 'success'
              })
              this.getTeacher()
              window.location.reload()
            }
          })
          .catch(error => {
            this.$message({
              showClose: true,
              message: `删除失败,${error.response.data.error_message}`,
              type: 'error'
            })
          })
      })
    },
    quituseradd (formName) {
      this.$refs[formName].resetFields()
      this.useradd = false
    },
    repassword (index, row) {
      MessageBox.confirm('是否重置该用户密码').then(() => {
        axios
          .post('/reset_password', {
            type: 1,
            id: row.id
          })
          .then(response => {
            if (response.status === 200) {
              this.$message({
                showClose: true,
                message: '已重置',
                type: 'success'
              })
            }
          })
          .catch(error => {
            this.$message({
              showClose: true,
              message: `重置失败,${error.response.data.error_message}`,
              type: 'error'
            })
          })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tab {
  overflow: scroll;
  /deep/.el-table__body-wrapper {
    height: auto !important;
  }
}
/deep/.el-table .cell {
  text-overflow: clip;
}
/deep/.changerole {
  width: 120px;
  position: relative;
  bottom: 0px;
  right: 0px;
  .el-input--suffix .el-input__inner {
    border: 0px;
    text-align: right;
    border-radius: 0px;
    font-size: 12px;
    height: 30px;
  }
  .el-input__suffix {
    top: -2px;
  }
}
</style>
