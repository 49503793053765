<template>
  <el-dialog
    title="面试邮件（to导师）"
    :visible="true"
    :close-on-click-modal="false"
    :class="step === 1 ? 'step1' : ''"
    ref="dialog"
  >
    <base-mail v-if="step === 1" />
    <div v-else-if="step === 2">
      <el-row>{{ teacher.name }}-{{ teacher.cellphone }}</el-row>
      <el-row style="margin-top:25px"
        >【面试】{{ project.project_type }}项目-{{ student.name }}</el-row
      >
      <el-row style="margin-top:20px">
        <el-input class="teacherName" v-model="teacherName"></el-input>
      </el-row>
      <el-row style="color:red;margin-top:30px"
        >请在面试中确认学生基础知识水平+学生学习态度+预计大致内容/选题方向</el-row
      >
      <el-row style="color:red;font-size:24px;font-weight:600"
        >在面试完成后登陆菁英启航系统记录面试信息。</el-row
      >
      <br />
      <el-row>以下为面试细则。</el-row>
      <el-row style="margin-top:20px" v-if="project.channel_type === 'inst'"
        >学生来源：{{ project.channel.abbreviation }}</el-row
      >
      <el-row>学生信息详见学生信息表：学生信息表</el-row>
      <el-row v-if="project.attachments.length > 0"
        >附件:
        <div v-for="item in project.attachments" :key="item.id">
          <a href="javascript:void(0);" @click="download(item.url,item.name)">{{ item.name }}</a>
        </div>
      </el-row>
      <div class="items">
        <el-row
          >项目名称：<el-input v-model="project.projectName"></el-input>
        </el-row>
        <el-row
          >意向开展时间：<el-input v-model="project.startTime"></el-input>
        </el-row>
        <el-row
          >面试时间*：<el-input v-model="project.connectTime"></el-input>
        </el-row>
        <el-row
          >学生备注：
          <div
            style="color:red;font-weight:600"
            v-if="project.channel_type === 'inst'"
          >
            导师在面试中，介绍自己身份为与{{
              project.channel.abbreviation
            }}合作导师。不得提及菁英品牌。
          </div>
          <el-input v-model="project.misc" type="textarea" :rows="9"></el-input>
        </el-row>
        <el-row>
          面试备注：
          <el-input
            v-model="project.startMisc"
            type="textarea"
            :rows="9"
          ></el-input>
        </el-row>
        <el-row>
          其他信息备注：
          <el-input
            v-model="project.otherMisc"
            type="textarea"
            :rows="9"
          ></el-input>
        </el-row>
      </div>
      <el-row>展信安，</el-row><br /><br />
      <el-row
        >李林子<br />
        教务<br />
        教学产品部<br />
        菁英怀思学院<br />
        M: +86 186-1817-7700<br />
        W: www.elitebackground.cn<br />
        A: 北京市海淀区学院南路15号北发大厦B座1028</el-row
      >
    </div>
    <div v-else-if="step === 3">
      <p>{{ title }}</p>
      <div v-html="content"></div>
    </div>
    <div slot="footer">
      <el-button @click="close">{{ step === 1 ? '取消' : '返回' }}</el-button>
      <el-button type="primary" @click="next" :disabled="pending">
        {{ step === 3 ? '发送' : step === 2 ? '预览' : '下一步' }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { baseMailMixin } from './baseMail'

export default {
  name: 'interviewMail',
  data () {
    return {
      pending: false
    }
  },
  mixins: [baseMailMixin],
  methods: {
    download (fileUrl,name) {
      let x = new XMLHttpRequest()
      x.open('GET', fileUrl, true)
      x.responseType = 'blob'
      x.onload = function (e) {
        let url = window.URL.createObjectURL(x.response)
        let a = document.createElement('a')
        a.href = url
        a.download = name
        a.click()
      }
      x.send()
    }
  }
}
</script>
<style lang="scss" scoped>
.step1 {
  /deep/.el-dialog {
    overflow: visible;
  }
}
/deep/.el-dialog__body {
  text-align: left;
  color: #656d78;
  font-size: 14px;
}

.items {
  margin-top: 30px;
  .el-row {
    min-height: 35px;
  }
  .el-input {
    position: absolute;
    left: 100px;
    width: calc(100% - 100px);
  }
}
/deep/.el-input__inner {
  color: #656d78;
}
.el-textarea {
  margin-bottom: 10px;
}
</style>
