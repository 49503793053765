<template>
  <div class="aside">
    <changerole></changerole>
    <el-menu router @select="handleSelect">
      <el-menu-item
        :class="$route.path == '/K/Kinterview' ? 'is-active' : ''"
        index="/K/Kinterview"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">面试<br />学员</div>
          <img class="iconmenu" src="../../assets/icon/面试学员@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/K/Kschedule' ? 'is-active' : ''"
        index="/K/Kschedule"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">学员<br />进度</div>
          <img class="iconmenu" src="../../assets/icon/学员进度@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/K/Kpost' ? 'is-active' : ''"
        index="/K/Kpost"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">投稿<br />进度</div>
          <img class="iconmenu" src="../../assets/icon/稿件.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/K/Krecommendations' ? 'is-active' : ''"
        index="/K/Krecommendations"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">推荐信<br />进度</div>
          <img class="iconmenu" src="../../assets/icon/推荐信.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/K/Kretouches' ? 'is-active' : ''"
        index="/K/Kretouches"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">翻译/润色<br />进度</div>
          <img class="iconmenu" src="../../assets/icon/翻译_润色icon.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/K/Kstudent' ? 'is-active' : ''"
        index="/K/Kstudent"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">结项<br />学员</div>
          <img class="iconmenu" src="../../assets/icon/结项学员@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/K/Kteacher' ? 'is-active' : ''"
        index="/K/Kteacher"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">查看<br />导师</div>
          <img class="iconmenu" src="../../assets/icon/查看导师@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/K/Kviewchan' ? 'is-active' : ''"
        index="/K/Kviewchan"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">查看<br />渠道</div>
          <img class="iconmenu" src="../../assets/icon/查看渠道.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/K/Kchannel' ? 'is-active' : ''"
        index="/K/Kchannel"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">渠道<br />信息</div>
          <img class="iconmenu" src="../../assets/icon/渠道@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/K/Kaccount' ? 'is-active' : ''"
        index="/K/Kaccount"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">账户<br />管理</div>
          <img class="iconmenu" src="../../assets/icon/账户管理@3x.png" />
        </el-tooltip>
      </el-menu-item>
    </el-menu>
    <div class="back">
      <img
        @click="logout"
        src="../../assets/icon/退出.png"
        height="20"
        width="20"
      />
    </div>
  </div>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'
import changerole from '../pub/changerole'
export default {
  name: 'knav',
  components: { changerole },
  methods: {
    handleSelect (key, keyPath) {
      this.$router.replace({ path: `${keyPath}` })
    },
    logout () {
      MessageBox.confirm('是否注销？')
        .then(() => {
          axios.delete('/logout/').then(() => {
            localStorage.removeItem('Authorization')
            this.$router.replace('/login')
          })
        })
        .catch(() => {})
    }
  },
  props: {}
}
</script>
