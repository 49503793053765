<template>
  <div>
    <el-dialog :visible="true" v-if="step === 0" title="批量上架">
      <div>
        <el-upload drag action="" :httpRequest="success">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em style="color: #8dc152; text-decoration: underline">
              点击上传
            </em>
          </div>
        </el-upload>
      </div>
      <div slot="footer">
        <el-button @click="close()">
          返回
        </el-button>
        <el-button type="primary" @click="next">加载 </el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible="true"
      :custom-class="topics.length > 0 ? 'forms' : 'form'"
      v-else-if="step === 1"
      title="上架科研"
    >
      <el-row>
        <el-col :span="10" v-if="topics.length > 0">
          <div class="tlist">
            <div
              v-for="(item, index) in topics"
              :key="item.title"
              :class="clicked === index ? 'clicked' : ''"
              @click="selectTopic(index)"
            >
              {{ index + 1 }}.{{ item.title }}
              <i class="el-icon-close close" @click.stop="deleteOne(index)"></i>
            </div>
          </div>
        </el-col>
        <el-col :span="topics.length > 0 ? 14 : 24" style="padding-left:10px">
          <el-form :model="topic" :rules="rules" ref="form">
            <el-row>
              <el-col :span="5">项目名称*：</el-col>
              <el-col :span="19">
                <el-form-item prop="title">
                  <el-input v-model="topic.title"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">项目难度*：</el-col>
              <el-col :span="19">
                <el-form-item prop="degree">
                  <el-rate :colors="colors" v-model="topic.degree"> </el-rate
                ></el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">学科分类*：</el-col>
              <el-col :span="19">
                <el-form-item prop="course">
                  <!--                  <el-input v-model="topic.course"></el-input>-->
                  <el-select v-model="topic.course" placeholder="请选择" style="width: 100%;">
                    <el-option
                      v-for="item in subject_types"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">学科标签*：</el-col>
              <el-col :span="19" id="subSearch">
                <el-form-item prop="subject">
                  <tagsSearch :subject.sync="topic.subject"></tagsSearch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5"> 方向标签*：</el-col>
              <el-col :span="19" id="dirSearch">
                <el-form-item prop="tags">
                  <tagsSearchSecond :direction.sync="topic.tags"></tagsSearchSecond>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">海报标签*：</el-col>
              <el-col :span="19">
                <el-form-item prop="direction_tag">
                  <el-select
                    v-model="topic.direction_tag"
                    filterable
                    remote
                    :remote-method="remotedirection_tag"
                    value-key="id"
                    style="width:100%"
                  >
                    <el-option
                      v-for="item in direction_tags"
                      :key="item.id"
                      :label="item.content"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">涉及内容*：</el-col>
              <el-col :span="19">
                <el-form-item prop="involve">
                  <el-input
                    v-model="topic.involve"
                    :placeholder="placeHolderWordforInvolve"
                  ></el-input
                ></el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">研究前沿性*：</el-col>
              <el-col :span="19">
                <el-form-item prop="back"
                  ><el-input v-model="topic.back" type="textarea" :rows="3"></el-input
                ></el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">研究介绍*：</el-col>
              <el-col :span="19">
                <el-form-item prop="introduce"
                  ><el-input v-model="topic.introduce" type="textarea" :rows="3"></el-input
                ></el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">课题研究方法*：</el-col>
              <el-col :span="19">
                <el-form-item prop="method"
                  ><el-input v-model="topic.method" type="textarea" :rows="3"></el-input
                ></el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">课题难点*：</el-col>
              <el-col :span="19">
                <el-form-item prop="ability"
                  ><el-input v-model="topic.ability" type="textarea" :rows="3"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div v-for="(item, index) in tasks" :key="item">
              <el-row>
                <el-col :span="5">任务{{ item }}*：</el-col>
                <el-col :span="19">
                  <el-form-item>
                    <el-input v-model="topic.task[index][0]"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="5">任务{{ item }}详情*：</el-col>
                <el-col :span="19">
                  <el-form-item>
                    <el-input v-model="topic.task[index][1]" type="textarea" :rows="3"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-row>
              <el-col :span="5">海报背景图*：</el-col>
              <el-col :span="19">
                <el-form-item prop="file">
                  <el-upload
                    class="uploader"
                    drag
                    :action="uploadUrl"
                    multiple
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                  >
                    <img
                      v-if="topic.file"
                      :src="topic.file"
                      class="avatar"
                      crossorigin="anonymous"
                    />
                    <i v-else class="el-icon-plus uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">导师姓名*：</el-col>
              <el-col :span="19">
                <el-form-item prop="teacher">
                  <el-select
                    v-model="topic.teacher"
                    filterable
                    remote
                    :remote-method="remoteTeacher"
                    style="width:100%"
                    value-key="id"
                  >
                    <el-option
                      v-for="item in teachers"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                      @click.native="getTeacher_identity(item)"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">导师身份*：</el-col>
              <el-col :span="19">
                <el-form-item></el-form-item>
                <el-input v-model="topic.teacher_identity" disabled></el-input
              ></el-col>
            </el-row>
            <el-row>
              <el-col :span="5">导师沟通能力*：</el-col>
              <el-col :span="19">
                <el-form-item></el-form-item>
                <el-input v-model="topic.connect" disabled></el-input
              ></el-col>
            </el-row>
            <el-row>
              <el-col :span="5">导师配合程度*：</el-col>
              <el-col :span="19">
                <el-form-item></el-form-item>
                <el-input v-model="topic.cooperate" disabled></el-input
              ></el-col>
            </el-row>
            <el-row>
              <el-col :span="5">导师背景*：</el-col>
              <el-col :span="19">
                <el-form-item prop="paper">
                  <el-input v-model="topic.paper" type="textarea" :rows="3" disabled> </el-input
                ></el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-button @click="back()">返回</el-button>
        <el-button type="primary" @click="preview()">预览 </el-button>
      </div>
    </el-dialog>
    <el-dialog :visible="true" v-else-if="step === 2" :title="topic.title" custom-class="iframe"
      ><el-button @click="show = 'lite'" :type="show === 'lite' ? 'primary' : ''">
        lite
      </el-button>
      <el-button @click="show = 'standard'" :type="show === 'standard' ? 'primary' : ''">
        standard
      </el-button>
      <el-button @click="show = 'repage'" :type="show === 'repage' ? 'primary' : ''">
        科研
      </el-button>
      <el-button @click="show = 'acpage'" :type="show === 'acpage' ? 'primary' : ''">
        学术指导
      </el-button>
      <el-button @click="show = 'xdf'" :type="show === 'xdf' ? 'primary' : ''">
        XDF
      </el-button>
      <el-button @click="show = 'xt'" :type="show === 'xt' ? 'primary' : ''">
        新通
      </el-button>
      <el-button @click="show = 'byr'" :type="show === 'byr' ? 'primary' : ''">
        保研人
      </el-button>
      <el-button @click="show = 'ewa'" :type="show === 'ewa' ? 'primary' : ''">
        EWA
      </el-button>
      <div style="display:flex" :style="show === 'lite' ? '' : 'height:0'">
        <iframe
          id="imgIframe"
          :src="`${origin}/litepage?uuid=${topic.uuid}`"
          :style="show === 'lite' ? '' : 'height:0'"
        ></iframe>
        <div class="divide" :style="show === 'lite' ? '' : 'display:none'"></div>
        <iframe
          :src="topic.lsp_url"
          :style="show === 'lite' ? '' : 'height:0'"
          v-if="ready1"
        ></iframe>
        <i class="eliwise-loading" v-else :style="show === 'lite' ? '' : 'display:none'"></i>
      </div>
      <div style="display:flex" :style="show === 'standard' ? '' : 'height:0'">
        <iframe
          id="imgIframe"
          :src="`${origin}/standardpage?uuid=${topic.uuid}`"
          :style="show === 'standard' ? '' : 'height:0'"
        ></iframe>
        <div class="divide" :style="show === 'standard' ? '' : 'display:none'"></div>
        <iframe
          :src="topic.lsp_pack_url"
          :style="show === 'standard' ? '' : 'height:0'"
          v-if="ready2"
        ></iframe>
        <i class="eliwise-loading" v-else :style="show === 'standard' ? '' : 'display:none'"></i>
      </div>
      <div style="display:flex" :style="show === 'repage' ? '' : 'height:0'">
        <iframe
          id="imgIframe"
          :src="`${origin}/page1?uuid=${topic.uuid1}&img=${topic.file}`"
          :style="show === 'repage' ? '' : 'height:0'"
        ></iframe>
        <div class="divide" :style="show === 'repage' ? '' : 'display:none'"></div>
        <iframe
          :src="topic.url"
          :style="show === 'repage' ? '' : 'height:0'"
          v-if="ready3"
        ></iframe>
        <i class="eliwise-loading" v-else :style="show === 'repage' ? '' : 'display:none'"></i>
      </div>
      <div style="display:flex" :style="show === 'acpage' ? '' : 'height:0'">
        <iframe
          id="imgIframe"
          :src="`${origin}/page2?uuid=${topic.uuid2}&img=${topic.file}`"
          :style="show === 'acpage' ? '' : 'height:0'"
        ></iframe>
        <div class="divide" :style="show === 'acpage' ? '' : 'display:none'"></div>
        <iframe
          :src="topic.pack_url"
          :style="show === 'acpage' ? '' : 'height:0'"
          v-if="ready4"
        ></iframe>
        <i class="eliwise-loading" v-else :style="show === 'acpage' ? '' : 'display:none'"></i>
      </div>
      <div style="display:flex" :style="show === 'xdf' ? '' : 'height:0'">
        <iframe
          id="imgIframe"
          :src="`${origin}/xdfpage?uuid=${topic.uuid}&img=${topic.file}`"
          :style="show === 'xdf' ? '' : 'height:0'"
        ></iframe>
        <div class="divide" :style="show === 'xdf' ? '' : 'display:none'"></div>
        <iframe
          :src="topic.xdf_url"
          :style="show === 'xdf' ? '' : 'height:0'"
          v-if="ready5"
        ></iframe>
        <i class="eliwise-loading" v-else :style="show === 'xdf' ? '' : 'display:none'"></i>
      </div>
      <div style="display:flex" :style="show === 'xt' ? '' : 'height:0'">
        <iframe
          id="imgIframe"
          :src="`${origin}/xtpage?uuid=${topic.uuid}&img=${topic.file}`"
          :style="show === 'xt' ? '' : 'height:0'"
        ></iframe>
        <div class="divide" :style="show === 'xt' ? '' : 'display:none'"></div>
        <iframe
          :src="topic.xt_url"
          :style="show === 'xt' ? '' : 'height:0'"
          v-if="ready6"
        ></iframe>
        <i class="eliwise-loading" v-else :style="show === 'xt' ? '' : 'display:none'"></i>
      </div>
      <div style="display:flex" :style="show === 'byr' ? '' : 'height:0'">
        <iframe
          id="imgIframe"
          :src="`${origin}/byrpage?uuid=${topic.uuid}&img=${topic.file}`"
          :style="show === 'byr' ? '' : 'height:0'"
        ></iframe>
        <div class="divide" :style="show === 'byr' ? '' : 'display:none'"></div>
        <iframe
          :src="topic.byr_url"
          :style="show === 'byr' ? '' : 'height:0'"
          v-if="ready7"
        ></iframe>
        <i class="eliwise-loading" v-else :style="show === 'byr' ? '' : 'display:none'"></i>
      </div>
      <div style="display:flex" :style="show === 'ewa' ? '' : 'height:0'">
        <iframe
          id="imgIframe"
          :src="`${origin}/ewapage?uuid=${topic.uuid}&img=${topic.file}`"
          :style="show === 'ewa' ? '' : 'height:0'"
        ></iframe>
        <div class="divide" :style="show === 'ewa' ? '' : 'display:none'"></div>
        <iframe
          :src="topic.ewa_url"
          :style="show === 'ewa' ? '' : 'height:0'"
          v-if="ready8"
        ></iframe>
        <i class="eliwise-loading" v-else :style="show === 'ewa' ? '' : 'display:none'"></i>
      </div>
      <div slot="footer">
        <el-button @click="step = 1">返回</el-button>
        <el-button
          type="primary"
          @click="post()"
          :disabled="!(ready1 & ready2 & ready3 && ready4 && ready5 && ready6 && ready7 && ready8) || pending"
        >
          上传
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import axios from '../axios'
import tagsSearch from '../pub/tagsSearch'
import tagsSearchSecond from '../pub/tagsSearchSecond'
import ExcelJS from 'exceljs'
export default {
  components: {
    tagsSearch,
    tagsSearchSecond
  },
  data () {
    return {
      uploadUrl: `${axios.defaults.baseURL}/upload/`,
      step: 0,
      colors: ['#8DC152', '#8DC152', '#8DC152'],
      tasks: ['二', '三', '四', '五'],
      directionValue: '',
      directions: [],
      teachers: [],
      placeHolderWordforInvolve: '内容间以","间隔',
      rules: {
        title: [{ required: true, message: '请填写项目名称', trigger: 'blur' }],
        degree: [{ required: true, message: '请填写项目难度', trigger: 'blur' }],
        course: [{ required: true, message: '请填写学科分类', trigger: 'blur' }],
        subject: [{ required: true, message: '请填写学科标签', trigger: 'change' }],
        tags: [{ required: true, message: '请填写方向标签', trigger: 'blur' }],
        direction_tag: [{ required: true, message: '请填写海报标签', trigger: 'blur' }],
        involve: [{ required: true, message: '请填写涉及内容', trigger: 'blur' }],
        back: [{ required: true, message: '请填写研究前沿性', trigger: 'blur' }],
        introduce: [{ required: true, message: '请填写研究介绍', trigger: 'blur' }],
        method: [{ required: true, message: '请填写课题研究方法', trigger: 'blur' }],
        ability: [{ required: true, message: '请填写课题难点', trigger: 'blur' }],
        paper2: [{ required: true, message: '请填写导师背景', trigger: 'blur' }],
        file: [{ required: true, message: '请填写海报背景图', trigger: 'blur' }],
        teacher: [{ required: true, message: '请填写导师姓名', trigger: 'change' }]
      },
      topic: {
        title: '',
        degree: 0,
        course: '',
        direction_tag: '',
        subject: '',
        tags: [],
        involve: '',
        back: '',
        introduce: '',
        task: [[], [], [], []],
        method: '',
        ability: '',
        teacher_identity: '',
        teacher: '',
        cooperate: '',
        connect: '',
        file: '',
        paper: '',
        url: '',
        img: '',
        pack_url: '',
        pack_img: '',
        lsp_url: '',
        lsp_img: '',
        lsp_pack_url: '',
        lsp_pack_img: '',
        xdf_url: '',
        xdf_img: '',
        xt_url: '',
        xt_img: '',
        byr_url: '',
        byr_img: '',
        ewa_url: '',
        ewa_img: ''
      },
      topics: [],
      clicked: 0,
      ready1: false,
      ready2: false,
      ready3: false,
      ready4: false,
      ready5: false,
      ready6: false,
      ready7: false,
      ready8: false,
      show: 'lite',
      direction_tags: [],
      topic_id: 0,
      put: false,
      subject_types: ['理科', '工科', '商科', '社科', '医科'],
      showSubTagSearch: false,
      showTagSearch: false,
      origin: '',
      finishList: [],
      pending: false
    }
  },
  created () {
    if (window.location.host.indexOf('eliteqihang') > -1) {
      this.origin = 'https://p.eliteqihang.com'
    } else {
      this.origin = 'https://p.manager.eliwise.demo.sya.org.cn'
    }
    this.hideTagsSearch('subSearch')
    this.hideTagsSearch('dirSearch')
  },
  mounted () {
    let that = this
    window.addEventListener('message', function (e) {
      if (e.origin === that.origin) {
        if (e.data.indexOf('lite') > -1) {
          that.ready1 = true
          that.topic.lsp_img = e.data.split('?=')[1]
        } else if (e.data.indexOf('standard') > -1) {
          that.ready2 = true
          that.topic.lsp_pack_img = e.data.split('?=')[1]
        } else if (e.data.indexOf('repage') > -1) {
          that.ready3 = true
          that.topic.img = e.data.split('?=')[1]
        } else if (e.data.indexOf('acpage') > -1) {
          that.ready4 = true
          that.topic.pack_img = e.data.split('?=')[1]
        } else if (e.data.indexOf('xdf') > -1) {
          that.ready5 = true
          that.topic.xdf_img = e.data.split('?=')[1]
        } else if (e.data.indexOf('xt') > -1) {
          that.ready6 = true
          that.topic.xt_img = e.data.split('?=')[1]
        } else if (e.data.indexOf('byr') > -1) {
          that.ready7 = true
          that.topic.byr_img = e.data.split('?=')[1]
        } else if (e.data.indexOf('ewa') > -1) {
          that.ready8 = true
          that.topic.ewa_img = e.data.split('?=')[1]
        } else if (e.data === 'error') {
          that.$message({
            showClose: true,
            message: '图片上传失败',
            type: 'error'
          })
        }
      }
    })
  },
  methods: {
    back () {
      if (this.put) {
        this.close()
      } else {
        this.step = 0
      }
    },
    get_topic (id) {
      this.topic_id = id
      this.put = true
      this.step = 1
      axios.get(`DBS/topic/research/${this.topic_id}`).then(res => {
        this.topic = res.data.topic
        this.remoteTeacher(this.topic.teacher.name, true)
        this.remotedirection_tag(this.topic.direction_tag.content)
      })
    },
    close () {
      this.$parent.$parent.getlist()
      this.$parent.$parent.researchForm = false
    },
    deleteOne (index) {
      this.topics.splice(index, 1)
      if (this.topics.length > 0) {
        if (index < this.clicked) {
          this.clicked--
        } else if (index === this.clicked) {
          this.selectTopic(0)
        }
      } else {
        this.close()
      }
    },
    post () {
      this.pending = true
      if (this.put) {
        axios.put(`DBS/topic/research/${this.topic_id}`, this.topic).then(() => {
          this.$message({
            showClose: true,
            message: '修改成功',
            type: 'success'
          })
          this.close()
        })
      } else {
        axios.post('DBS/topic/research', this.topic).then(res => {
          this.$message({
            showClose: true,
            message: '上传成功',
            type: 'success'
          })
          this.finishList.push(res.data.id)
          this.topics.splice(this.clicked, 1)
          if (this.topics.length > 0) {
            this.selectTopic(0)
            this.step = 1
            this.pending = false
          } else {
            axios
              .post('DBS/add_msg', {
                finishList: this.finishList
              })
              .then(res => {
                this.$message({
                  message: '发送成功',
                  type: 'success'
                })
                this.close()
              })
          }
        })
      }
    },
    preview () {
      this.$refs.form.validate(valid => {
        if (valid) {
          axios.post('previews/add_pages', this.topic).then(res => {
            this.topic.uuid = res.data.uuid
            this.topic.uuid1 = res.data.uuid1
            this.topic.uuid2 = res.data.uuid2
            this.topic.lsp_url = `${this.origin}/lite/${this.topic.uuid}`
            this.topic.lsp_pack_url = `${this.origin}/standard/${this.topic.uuid}`
            this.topic.xdf_url = `${this.origin}/xdf/${this.topic.uuid}`
            this.topic.xt_url = `${this.origin}/xt/${this.topic.uuid}`
            this.topic.byr_url = `${this.origin}/byr/${this.topic.uuid}`
            this.topic.ewa_url = `${this.origin}/ewa/${this.topic.uuid}`
            this.topic.url = `${this.origin}/repage/${this.topic.uuid1}`
            this.topic.pack_url = `${this.origin}/acpage/${this.topic.uuid2}`
            this.step = 2
            this.ready1 = false
            this.ready2 = false
            this.ready3 = false
            this.ready4 = false
            this.ready5 = false
            this.ready6 = false
            this.ready7 = false
            this.ready8 = false
            this.show = 'lite'
          })
        } else {
          this.$message({
            showClose: true,
            message: '请输入必填项',
            type: 'warning'
          })
        }
      })
    },
    success (e) {
      const key = e.file
      const render = new FileReader()
      render.readAsArrayBuffer(key)
      render.onload = async () => {
        const workbook = new ExcelJS.Workbook()
        workbook.xlsx.load(render.result).then(book => {
          const worksheet = book.getWorksheet(1)
          console.log(worksheet)
          const rows = worksheet.getRows(2, worksheet.rowCount)
          rows.forEach((e, index) => {
            const sheet2 = book.getWorksheet(2)
            const sheet3 = book.getWorksheet(3)
            let task = []
            for (const each of [4, 5, 6, 7]) {
              const sheet = book.getWorksheet(each)
              task.push([
                sheet.getRow(index + 2).values[1],
                sheet
                  .getRow(index + 2)
                  .values.slice(2)
                  .join('\n')
              ])
            }
            const topic = {
              course: e.values[1],
              title: e.values[2],
              degree: e.values[3],
              direction_tag: e.values[4],
              method: e.values[5],
              ability: e.values[6],
              back: e.values[7],
              introduce: e.values[8],
              involve: sheet2
                .getRow(index + 2)
                .values.slice(1)
                .join(','),
              subject: sheet3.getRow(index + 2).values[1] || '',
              tags: sheet3.getRow(index + 2).values.slice(2),
              task: task
            }
            if (topic.title) {
              this.topics.push(topic)
            }
          })
        })
      }
    },
    next () {
      if (this.topics.length === 0) {
        this.$message({
          message: `未识别到选题`,
          type: 'error'
        })
        this.step = 1
      } else {
        this.step = 1
        this.selectTopic(0)
      }
    },
    selectTopic (index) {
      this.topic = this.topics[index]
      this.clicked = index
      this.validateLabel()
    },
    validateLabel () {
      if (typeof this.topic.subject === 'string') {
        axios
          .post('previews/validateLabel', {
            subject: this.topic.subject,
            tags: this.topic.tags,
            direction_tag: this.topic.direction_tag
          })
          .then(response => {
            Object.assign(this.topic, response.data)
            this.direction_tags = [response.data.direction_tag]
            this.$forceUpdate()
          })
      }
    },
    handleAvatarSuccess (res) {
      this.topic.file = res.url
      this.$forceUpdate()
    },
    remotedirection_tag (query) {
      axios
        .get('DBS/direction_tag', {
          params: {
            content: query
          }
        })
        .then(response => {
          this.direction_tags = response.data.directions
          this.$forceUpdate()
        })
    },
    remoteTeacher (query, put = false) {
      axios
        .get('DBS/teachers', {
          params: {
            name: query
          }
        })
        .then(response => {
          this.teachers = response.data.teachers
          if (put) {
            this.teachers.forEach(e => {
              if (e.id === this.topic.teacher.id) {
                this.getTeacher_identity(e)
              }
            })
          }
        })
    },
    getTeacher_identity (item) {
      this.topic.teacher_identity = item.misc
      this.topic.cooperate = item.cooperate
      this.topic.connect = item.connect
      this.topic.paper = item.paper
      this.$forceUpdate()
    },
    handleClose (tag) {
      this.topic.tags.splice(this.topic.tags.indexOf(tag), 1)
    },
    tagcolor (tag) {
      switch (tag.type) {
        case '理科':
          return 'tag2'
        case '工科':
          return 'tag4'
        case '商科':
          return 'tag6'
        case '社科':
          return 'tag8'
        case '医科':
          return 'tag10'
      }
    },

    hideTagsSearch (id) {
      document.addEventListener('click', e => {
        let ele = document.getElementById(id)
        if (id === 'subSearch') {
          if (this.showSubTagSearch === true) {
            let isSelf = ele.contains(e.target)
            if (!isSelf) {
              this.showSubTagSearch = false
            }
          }
        } else {
          if (this.showTagSearch === true) {
            let isSelf = ele.contains(e.target)
            if (!isSelf) {
              this.showTagSearch = false
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-upload {
  width: 100%;
  .el-upload-dragger {
    background-color: inherit;
    border-width: 2px;
  }
}
/deep/.form {
  max-height: 95%;
  .el-dialog__footer {
    padding-top: 25px;
  }
}
/deep/.forms {
  width: 70%;
  padding: 0;
  max-height: 95%;
  .el-dialog__body {
    padding: 0;
    color: #656d78;
  }
  .el-dialog__footer {
    padding-top: 25px;
  }
}
.tlist {
  height: 70vh;
  overflow: scroll;
  background-color: #fff;
  text-align: left;
  width: 85%;
  margin: auto;
  padding: 10px;
  div {
    width: 90%;
    margin: 5px auto;
    padding: 5px 5px;
    line-height: 30px;
    cursor: pointer;
    position: relative;
  }
  .close {
    color: #c0c0c0;
    font-size: 20px;
    position: absolute;
    right: 5px;
    top: 8px;
  }
  .clicked {
    background-color: #a0d469;
    color: #fff;
    .close {
      color: #fff;
    }
  }
}
.el-form {
  height: 70vh;
  overflow: scroll;
  padding-right: 30px;
  .el-rate__icon {
    line-height: 30px;
  }
  .el-row {
    padding: 8px 0;
    .el-col {
      line-height: 30px;
    }
  }
}
.direction {
  border-bottom: solid 1px #c0c0c0;
  .icon {
    margin-top: 5px;
  }
  /deep/.el-input__inner {
    background-color: #f4f6f9;
    border: solid 0px;
    padding-left: 5px;
    padding-right: 0px;
  }
}
/deep/.uploader {
  .el-upload {
    width: 180px;
  }
  .uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
/deep/.iframe {
  width: 70%;
  max-height: 95%;
  .el-dialog__body {
    text-align: left;
  }
}
iframe {
  border: none;
  height: 70vh;
  width: 500px;
}
.el-button {
  margin-bottom: 10px;
}
.divide {
  width: 0;
  height: 70vh;
  margin: 0 10px;
  border-left: #c0c0c0 3px dashed;
}
.eliwise-loading {
  font-size: 100px;
  width: 100px;
  height: 100px;
  color: #c0c0c0;
  margin: auto;
}
/deep/.el-form-item {
  margin-bottom: 0px;
}
</style>
