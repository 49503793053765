<template>
  <div>
    <el-tooltip placement="top" effect="light" content="学生信息表">
      <information-icon @click.native="getInformation"> </information-icon>
    </el-tooltip>
    <el-dialog
      title="学生信息表"
      :visible.sync="informationDialog"
      v-if="informationDialog"
      width="800px"
      :close-on-click-modal="true"
    >
      <information :pid="pid"></information>
      <span slot="footer" class="dialog-footer">
        <el-button @click="informationDialog = false">返回</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import informationIcon from '../../../assets/icon-font/informationIcon'
import information from '../../public/projectInfo/info.vue'
export default {
  components: {
    informationIcon,
    information
  },
  data () {
    return {
      informationDialog: false
    }
  },
  props: {
    pid: Number
  },
  methods: {
    getInformation () {
      this.informationDialog = true
    }
  }
}
</script>
