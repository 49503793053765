<template>
  <el-main class="main">
    <div class="flex">
      <div class="listMD" ref="element">
        <div class="search flex">
          <div class="seaicon">
            <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
          </div>
          <el-input v-model="stutitle" @input="search" class="seasubfix">
          </el-input>
          <div class="addicon">
            <img
              @click="addPerson"
              src="../../assets/icon/添加用户.png"
              height="30"
              width="30"
            />
          </div>
        </div>
        <div class="jstudent">
          <projectList
            class="preWrapper"
            :type="'part'"
            :selectId.sync="selectId"
            ref="part"
          />
          <projectList
            class="preWrapper"
            :type="'not_interviewed'"
            :selectId.sync="selectId"
            ref="not_interviewed"
            v-if="part"
          />
          <projectList
            class="preWrapper"
            :type="'interviewed'"
            :selectId.sync="selectId"
            ref="interviewed"
            v-if="not_interviewed"
          />
          <div
            style="
              font-size: 16px;
              color: #656d78;
              padding-top: 1em;
            "
            v-if="selectId === 0"
          >
            未搜索到相关信息
          </div>
        </div>
      </div>
      <div class="neirong">
        <div class="jtitle">
          <h2 class="jtext">面试学员</h2>
        </div>
        <projectInfo :project="activeProject">
          <div slot="roles" class="rolesIcon">
            <img
              v-if="project_type !== ''"
              src="../../assets/roles/icon/导师.png"
            />
            <span v-if="project_type !== ''">{{
              activeProject.teacher || '待分配'
            }}</span>
            <img src="../../assets/roles/icon/规划师.png" />
            <span>{{ activeProject.planner }}</span>
            <img src="../../assets/roles/icon/客户经理.png" />
            <span>{{ activeProject.amanagerName || '无' }}</span>
            <img src="../../assets/roles/icon/渠道.png" />
            <span class="channel">
              {{
                activeProject.channel_type === 'inst'
                  ? activeProject.channel.abbreviation
                  : activeProject.channel_type === 'cons'
                  ? `个人-${activeProject.channel.name}`
                  : '自有'
              }}
            </span>
            <label v-if="activeProject.adviserName">{{
              '-' + activeProject.adviserName
            }}</label>
          </div>
          <div slot="buttons">
            <informationButton :pid="pid"> </informationButton>
            <el-button type="primary" @click="additem">添加项目</el-button>
            <el-button type="primary" @click="fixitem">
              修改项目信息
            </el-button>
            <el-button type="primary" @click="isEnd">
              不签约
            </el-button>
            <div v-if="project_type != ''">
              <el-popover
                ref="popover"
                placement="top"
                trigger="hover"
                v-if="teacher.id === undefined"
                content="未添加导师"
              >
              </el-popover>
              <el-popover
                ref="popover"
                placement="top"
                width="100"
                trigger="hover"
                content="未添加面试反馈"
                v-if="
                  teacher.id !== undefined &&
                    interview === undefined &&
                    project_type.indexOf('会务组论文') === -1
                "
              >
              </el-popover>
              <div v-popover:popover>
                <el-button
                  type="primary"
                  @click="isStart"
                  :disabled="
                    (interview === undefined &&
                      project_type.indexOf('学习型科研') === -1 &&
                      project_type.indexOf('会务组论文') === -1) ||
                      teacher.id === undefined
                  "
                >
                  签 约
                </el-button>
              </div>
            </div>
          </div>
          <el-row class="interview" slot="stages">
            <img src="../../assets/icon/面试.png" />
            <span v-if="!interview">
              暂无面试反馈
            </span>
            <span v-else class="viewdate">{{ interview.date }}</span>
            <span v-if="interview">
              {{ interview.situation }}
            </span>
          </el-row>
        </projectInfo>
      </div>
    </div>
    <newStudent ref="newStudent" />
    <newProject ref="newProject" />
    <editPart ref="editPart" />
    <editProject ref="editProject" />
    <StartDialog ref="StartDialog" />
    <EndDialog
      isstart="interview"
      :tasks="tableData"
      ref="EndDialog"
      :params="['commit']"
    />
  </el-main>
</template>

<script>
/* eslint-disable no-console */
import axios from '../axios'
import projectList from '../pub/projectList'
import projectInfo from '../pub/projectInfo'
import informationButton from '../pub/buttons/informationButton'
import newStudent from '../pub/studentDialog/newStudent'
import newProject from '../pub/studentDialog/newProject'
import editPart from '../pub/studentDialog/editPart'
import editProject from '../pub/studentDialog/editProject'
import StartDialog, { StartDialogMixin } from '../pub/StartDialog'
import { EndDialogMixin } from '../pub/EndDialog.vue'
import _ from 'lodash'
export default {
  name: 'MDinterview',
  mixins: [StartDialogMixin, EndDialogMixin],
  components: {
    projectList,
    projectInfo,
    informationButton,
    newStudent,
    newProject,
    editPart,
    editProject,
    StartDialog
  },
  data () {
    return {
      smes: {
        name: '',
        school: '',
        cellphone: '',
        email: '',
        direction: '',
        sex: ''
      },
      tableData: [],
      stutitle: '',
      role: '学员',
      teacher: '',
      project_type: '',
      s_name: '',
      t_name: '',
      pid: 0,
      interview: undefined,
      activeProject: {},
      selectId: 0,
      part: false,
      not_interviewed: false
    }
  },
  watch: {
    selectId (newVal, oldVal) {
      if (newVal !== oldVal) this.getProject()
    }
  },
  methods: {
    search: _.debounce(function () {
      this.$refs.part.getList(this.stutitle)
      this.$refs.not_interviewed.getList(this.stutitle)
      this.$refs.interviewed.getList(this.stutitle)
    }, 500),
    addPerson () {
      this.$refs.newStudent.openDialog()
    },
    fixitem () {
      if (this.project_type !== '') {
        this.$refs.editProject.openDialog(this.activeProject)
      } else {
        this.$refs.editPart.openDialog(this.activeProject)
      }
    },
    additem () {
      this.$refs.newProject.openDialog(this.activeProject, this.smes)
    },
    getProject () {
      axios
        .get(`/${this.$store.state.roleApi}/project/${this.selectId}/`)
        .then(response => {
          this.teacher = response.data.teacher
          this.project_type = response.data.project.project_type
          this.interview = response.data.interview[0]
          this.pid = response.data.project.id
          this.smes = response.data.student
          this.activeProject = {
            ...response.data.project,
            sponser: response.data.support,
            amanager: response.data.amanager,
            cmanager: response.data.cmanager
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
