<template>
  <el-dialog
    :visible.sync="endDialog"
    width="530px"
    :close-on-click-modal="false"
  >
    <template v-if="isstart === 'schedule'">
      <div slot="title">
        <span class="el-dialog__title"> 结项确认</span>
      </div>
      <div>
        <el-upload
          v-if="abnormal === false"
          class="upload-demo"
          :action="uploadUrl"
          :on-remove="handleRemove"
          multiple
          :on-success="handleSuccess"
          :on-exceed="handleExceed"
          :file-list="filesout"
        >
          <div class="xmcc">
            <div>项目产出:</div>
            <p class="ccdoc">添加文件</p>
          </div>
        </el-upload>
        <div class="Abnormal">
          <el-checkbox @change="abnor" v-model="abnormal">异常结项</el-checkbox>
        </div>
        <el-upload
          v-if="abnormal === false"
          class="upload-demo"
          :action="uploadUrl"
          :on-remove="fankuiRemove"
          multiple
          :on-success="fankuiSuccess"
          :on-exceed="handleExceed"
          :file-list="filesfeed"
        >
          <div class="xmcc">
            <div>学生反馈:</div>
            <p class="ccdoc">添加文件</p>
          </div>
        </el-upload>
        <el-upload
          v-if="abnormal === true"
          class="upload-demo"
          :action="uploadUrl"
          :on-remove="yichangRemove"
          multiple
          :on-success="yichangSuccess"
          :on-exceed="handleExceed"
          :file-list="filesabfeed"
        >
          <div class="xmcc">
            <div>异常结项凭证:</div>
            <p class="ccdoc">添加文件</p>
          </div>
        </el-upload>
      </div>
      <div class="title">
        结项备注
      </div>
      <el-input
        type="textarea"
        placeholder="结项备注"
        v-model="misc"
        class="input"
      ></el-input>
    </template>
    <template v-else>
      <div slot="title">
        <span class="el-dialog__title">不签约确认</span>
      </div>
      <div>
        <el-upload
          class="upload-demo"
          :action="uploadUrl"
          :on-remove="nosignRemove"
          multiple
          :on-success="nosignSuccess"
          :on-exceed="handleExceed"
          :file-list="filesNosign"
        >
          <div class="xmcc">
            <div>不签约原因:</div>
            <p class="ccdoc">添加文件</p>
          </div>
        </el-upload>
      </div>
      <div class="title">
        不签约备注
      </div>
      <el-input
        type="textarea"
        placeholder="不签约备注"
        v-model="misc"
        class="input"
      ></el-input>
    </template>
    <span slot="footer" class="dialog-footer">
      <el-button @click="endDialog = false">取消</el-button>
      <el-button
        :class="{ ftrue: filesout.length === 0 }"
        type="primary"
        @click="finish"
        :disabled="button_disabled"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'

let component = {
  name: 'EndDialog',
  props: {
    tasks: {
      type: Array,
      required: true
    },
    isstart: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      uploadUrl: `${axios.defaults.baseURL}/upload/`,
      filesout: [],
      filesfeed: [],
      filesabfeed: [],
      endDialog: false,
      button_disabled: false,
      time: 0,
      teacher: '',
      project_type: '',
      abnormal: false,
      pid: 0,
      misc: '',
      outKey: [],
      filesNosign: []
    }
  },
  mounted () {},
  methods: {
    abnor () {
      this.abnormal = !this.abnormal
    },
    openDialog (payload) {
      this.filesout = []
      this.time = payload.time
      this.teacher = payload.teacher
      this.project_type = payload.project_type
      this.pid = payload.pid
      this.endDialog = true
      this.misc = ''
    },

    handleExceed (filesout, fileList) {
      this.$message.warning(
        `当前限制选择 5 个文件，本次选择了 ${
          filesout.length
        } 个文件，共选择了 ${filesout.length + fileList.length} 个文件`
      )
    },
    handleRemove (file) {
      const index = this.filesout.indexOf(file.response.key)
      if (index > -1) {
        this.filesout.splice(index, 1)
      }
    },
    fankuiRemove (file) {
      const index = this.filesfeed.indexOf(file.response.key)
      if (index > -1) {
        this.filesfeed.splice(index, 1)
      }
    },
    yichangRemove (file) {
      const index = this.filesabfeed.indexOf(file.response.key)
      if (index > -1) {
        this.filesabfeed.splice(index, 1)
      }
    },
    nosignRemove (file) {
      const index = this.filesNosign.indexOf(file.response.key)
      if (index > -1) {
        this.filesNosign.splice(index, 1)
      }
    },
    nosignSuccess (res) {
      this.$message({
        showClose: true,
        message: `文件上传成功`,
        type: 'success'
      })
      this.filesNosign.push(res.key)
    },
    // 文件上传成功时的钩子
    handleSuccess (res) {
      this.$message({
        showClose: true,
        message: `文件上传成功`,
        type: 'success'
      })
      this.filesout.push(res.key)
    },
    yichangSuccess (res) {
      this.$message({
        showClose: true,
        message: `文件上传成功`,
        type: 'success'
      })
      this.filesabfeed.push(res.key)
    },
    fankuiSuccess (res) {
      this.$message({
        showClose: true,
        message: `文件上传成功`,
        type: 'success'
      })
      this.filesfeed.push(res.key)
    },
    // 文件上传失败时的钩子
    handleError () {
      this.$message({
        showClose: true,
        message: `文件上传失败`,
        type: 'error'
      })
    },
    finish () {
      this.button_disabled = true
      if (this.tasks.length !== 0) {
        if (this.abnormal === false) {
          if (this.filesout.length === 0) {
            this.$message({
              message: '请上传文件',
              type: 'warning'
            })
            this.button_disabled = false
            return true
          } else {
            MessageBox.confirm('是否确认结项？', {
              beforeClose: (action, instance, done) => {
                if (action !== 'confirm') this.button_disabled = false
                done()
              }
            })
              .then(() => {
                axios
                  .post(
                    `/${this.$store.state.roleApi}/project/${this.pid}/finish`,
                  {
                    files_output: this.filesout,
                    files_feed: this.filesfeed,
                    files_abfeed: this.filesabfeed,
                    abnormal: this.abnormal,
                    misc: this.misc
                  }
                  )
                  .then(response => {
                    if (response.status === 200) {
                      this.$message({
                        showClose: true,
                        message: '结项成功',
                        type: 'success'
                      })
                      this.endDialog = false
                      setTimeout(() => {
                        window.location.reload()
                      }, 1000)
                    }
                  })
              })
              .catch(error => {
                console.log(error)
              })
          }
        } else {
          if (this.filesabfeed.length === 0) {
            this.$message({
              message: '请上传文件',
              type: 'warning'
            })
            this.button_disabled = false
            return true
          } else {
            MessageBox.confirm('是否确认结项？', {
              beforeClose: (action, instance, done) => {
                if (action !== 'confirm') this.button_disabled = false
                done()
              }
            })
              .then(() => {
                axios
                  .post(
                    `/${this.$store.state.roleApi}/project/${this.pid}/finish`,
                  {
                    files_output: this.filesout,
                    files_feed: this.filesfeed,
                    files_abfeed: this.filesabfeed,
                    abnormal: this.abnormal,
                    misc: this.misc
                  }
                  )
                  .then(response => {
                    if (response.status === 200) {
                      this.$message({
                        showClose: true,
                        message: '结项成功',
                        type: 'success'
                      })
                      this.endDialog = false
                      setTimeout(() => {
                        window.location.reload()
                      }, 1000)
                    }
                  })
              })
              .catch(error => {
                console.log(error)
              })
          }
        }
      } else {
        console.log('dsdsds')
        MessageBox.confirm('是否确认结项？', {
          beforeClose: (action, instance, done) => {
            if (action !== 'confirm') this.button_disabled = false
            done()
          }
        })
          .then(() => {
            axios
              .post(
                `/${this.$store.state.roleApi}/project/${this.pid}/finish`,
              {
                files_output: this.filesout,
                files_feed: this.filesfeed,
                files_abfeed: this.filesabfeed,
                files_nosign: this.filesNosign,
                abnormal: this.abnormal,
                misc: this.misc
              }
              )
              .then(response => {
                if (response.status === 200) {
                  this.$message({
                    showClose: true,
                    message: '结项成功',
                    type: 'success'
                  })
                  this.endDialog = false
                  setTimeout(() => {
                    window.location.reload()
                  }, 1000)
                }
              })
          })
          .catch(error => {
            console.log(error)
          })
      }
    }
  }
}
export default component
let EndDialogMixin = {
  components: {
    EndDialog: component
  },
  watch: {},
  computed: {
    dialogTname () {
      if (this.teacher === '') {
        return '无导师'
      }
      if (typeof this.teacher === 'string') {
        return this.teacher
      } else {
        return this.teacher.name || '无导师'
      }
    }
  },
  methods: {
    isEnd () {
      let time = this.getSummaries ? this.getSummaries() : 0
      this.$refs.EndDialog.openDialog({
        time,
        teacher: this.dialogTname,
        project_type: this.project_type,
        pid: this.pid
      })
    }
  }
}
export { EndDialogMixin }
</script>

<style lang="scss" scoped>
.xmcc {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 22px;
  margin-top: 10px;
  color: black;
}
.ccdoc {
  margin-left: 10px;
  color: #a0d469;
}
.title {
  margin-top: 40px;
  text-align: left;
  margin-bottom: 10px;
}
.Abnormal {
  position: absolute;
  top: 10px;
  left: 400px;
}
</style>
