<template>
  <div>
    <div class="flex" style="align-items: center;flex-wrap: wrap;">
      <el-tag
        :key="tag.id"
        v-for="tag in direction"
        closable
        :disable-transitions="true"
        @close="handleClose(tag)"
        class="tags"
        :class="selectedTagcol(tag)"
        style="margin: 5px;"
      >
        ⚬ {{ tag.content }}
      </el-tag>
      <div style="font-size: 20px;cursor:pointer;" @click.stop="show = !show">
        <div class="el-icon-circle-plus-outline"></div>
      </div>
    </div>
    <!--  多选细分方向标签组件-->
    <div class="warpper" id="warpper" v-if="show">
      <div class="searchSub">
        <div class="icon">
          <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
        </div>
        <el-input
          v-model="labelsValue"
          placeholder="方向标签"
          ref="keywords"
          @input.native="remoteDirection"
        ></el-input>
        <el-button type="primary" @click="show = false" style="font-size: 12px">
          完成
        </el-button>
      </div>
      <div class="tagSelectWrapper flex" v-if="labels.length > 0">
        <div class="leftNav" id="leftNavs" @wheel.prevent="wheel">
          <div class="rowWrapper" v-for="(item, index) in labels" :key="index">
            <div
              class="title"
              v-if="
                index === 0 ||
                  item.directions[0].type !==
                    labels[index - 1].directions[0].type
              "
              :style="{ border: index === 0 ? 'none' : '' }"
            >
              {{ item.directions[0].type }}
            </div>
            <div
              class="subjects"
              :class="{ isShow: isShow === index }"
              @click="leftClick(index)"
            >
              {{ item.subject }}
            </div>
          </div>
        </div>
        <div class="container">
          <el-tag
            :id="'' + tag.id"
            :key="tag.id"
            v-for="tag in labels[isShow].directions"
            :disable-transitions="true"
            class="tags"
            @click="clickTag(tag)"
            :class="tagcolor(tag)"
            @mouseenter.native="tagHover(tag)"
            @mouseleave.native="tagLeave(tag)"
            style="cursor:default;"
          >
            ⚬ {{ tag.content }}
          </el-tag>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios'
import { debounce } from 'lodash'

export default {
  name: 'tagsSearchSecond',
  props: {
    direction: Array,
    teacher: false
  },
  data () {
    return {
      labels: [],
      labelsValue: '',
      isShow: '',
      show: false
    }
  },
  created () {
    this.remoteLabelList()
  },
  mounted () {
    const x = e => {
      if (
        document.getElementById('warpper') &&
        !document.getElementById('warpper').contains(e.target)
      ) {
        this.show = false
      }
    }
    document.addEventListener('click', x)
  },
  methods: {
    wheel (e) {
      let con = document.getElementById('leftNavs')
      e = e || window.event
      con.scrollTop += e.wheelDelta > 0 ? -10 : 10
      return false
    },
    remoteLabelList (content = '') {
      axios
        .get('DBS/label_list', {
          params: {
            content: content
          }
        })
        .then(res => {
          this.labels = res.data.labels
          this.isShow = 0
        })
    },
    clickTag (item) {
      if (this.direction.find(x => x.id === item.id)) {
        this.handleClose(item)
      } else {
        if (this.teacher || this.direction.length < 6) {
          if (item) {
            this.direction.push(item)
          }
        } else {
          this.$message({
            message: '最多选择六个标签',
            type: 'warning'
          })
        }
      }
    },
    handleClose (tag) {
      let index
      for (let i = 0; i < this.direction.length; i++) {
        if (tag.id === this.direction[i].id) {
          index = i
          break
        }
      }
      this.direction.splice(index, 1)
    },
    tagcolor (tag) {
      switch (tag.type) {
        case '理科':
          if (this.direction.find(x => x.id === tag.id)) {
            return 'tag1'
          } else {
            return 'tag0'
          }
        case '工科':
          if (this.direction.find(x => x.id === tag.id)) {
            return 'tag3'
          } else {
            return 'tag0'
          }
        case '商科':
          if (this.direction.find(x => x.id === tag.id)) {
            return 'tag5'
          } else {
            return 'tag0'
          }
        case '社科':
          if (this.direction.find(x => x.id === tag.id)) {
            return 'tag7'
          } else {
            return 'tag0'
          }
        case '医科':
          if (this.direction.find(x => x.id === tag.id)) {
            return 'tag9'
          } else {
            return 'tag0'
          }
      }
    },
    selectedTagcol (tag) {
      switch (tag.type) {
        case '理科':
          return 'tag2'
        case '工科':
          return 'tag4'
        case '商科':
          return 'tag6'
        case '社科':
          return 'tag8'
        case '医科':
          return 'tag10'
      }
    },
    tagHover (tag) {
      if (!this.direction.find(x => x.id === tag.id)) {
        let className = this.selectedTagcol(tag)
        document.getElementById('' + tag.id).className += ' ' + className
      }
    },
    tagLeave (tag) {
      let className = this.selectedTagcol(tag)
      const classN = document.getElementById('' + tag.id).className
      document.getElementById('' + tag.id).className = classN.replace(
        className,
        ''
      )
    },
    remoteDirection: debounce(function (query) {
      this.remoteLabelList(this.labelsValue)
    }, 500),
    leftClick (index) {
      this.isShow = index
    }
  }
}
</script>

<style scoped lang="scss">
.warpper {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  padding: 0 20px 0;
  overflow: scroll;
  position: absolute;
  z-index: 20;
  left: 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .searchSub {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 21;
    padding-top: 20px;
    background-color: #fff;
    border-bottom: solid 1px #c0c0c0;
    .icon {
      margin-top: 5px;
    }
    /deep/.el-input__inner {
      background-color: #fff;
      border: solid 0px;
      padding-left: 5px;
      padding-right: 0px;
    }
  }
  .tagSelectWrapper {
    margin-top: 10px;
    .leftNav::-webkit-scrollbar {
      width: 0 !important;
    }
    .leftNav {
      width: 115px;
      height: 300px;
      overflow: scroll;
      background-color: #f5f7fa;
      padding: 6px 0;
      scrollbar-width: none;
      -ms-overflow-style: none;

      .title {
        color: #c0c0c0;
        font-size: 12px;
        line-height: 17px;
        padding: 12px 0;
        margin: 0 9px;
        border-top: 1px solid #c0c0c0;
      }
      .isShow {
        background-color: #434a54;
        border: #fff 0px solid;
        background-color: #fff;
        border-bottom-left-radius: 18px;
        border-top-left-radius: 18px;
      }
      .subjects {
        color: #656d78;
        font-size: 14px;
        line-height: 20px;
        padding: 9px 18px;
        cursor: pointer;
      }
    }
    .container {
      padding-top: 10px;
      background-color: #fff;
      width: 100%;
      height: fit-content;
    }
  }
}
</style>
