<template>
  <el-main class="main">
    <div class="flex">
      <schedulelist
        @select="getstu1"
        url="/enterpise/students/not_finished"
        :enterpise="true"
      />
      <div class="neirong">
        <div class="jtitle">
          <h2 class="jtext">辅导进度</h2>
        </div>
        <projectInfo :project="activeProject">
          <div slot="roles" class="rolesIcon">
            <el-tooltip effect="light" content="规划师" placement="top">
              <img src="../../assets/roles/icon/规划师.png" />
            </el-tooltip>
            <span>{{ activeProject.planner }}</span>
            <el-tooltip effect="light" content="客户经理" placement="top">
              <img src="../../assets/roles/icon/客户经理.png" />
            </el-tooltip>
            <span>{{ activeProject.amanager }}</span>
            <el-tooltip effect="light" content="渠道经理" placement="top">
              <img src="../../assets/roles/icon/渠道经理.png" />
            </el-tooltip>
            <span>{{ activeProject.cmanager }}</span>
          </div>
          <div slot="buttons">
            <el-button
              type="primary"
              @click="exportI = true"
              class="el-icon-download"
              size="mini"
              style="font-size: 22px"
            >
            </el-button>
          </div>
          <div slot="stages">
            <stages
              @listentoChart="showMsgchart"
              :tableData="tableData"
              :editable="true"
              :project="activeProject"
              :interview="interview"
              :enterpise="true"
            ></stages>
          </div>
        </projectInfo>
      </div>
    </div>
    <export-img
      v-if="exportI"
      :smes="smes"
      :tableData="tableData"
      :project="activeProject"
      :interview="interview"
      :isEnterprise="true"
    ></export-img>
  </el-main>
</template>

<script>
import axios from '../axios'
import schedulelist from '../pub/scheduleList'
import projectInfo from '../pub/projectInfo'
import stages from '../pub/stages'
import exportImg from '../pub/exportImg'
import fileButton from '../pub/buttons/fileButton'
import informationButton from '../pub/buttons/informationButton'
import statusButton from '../pub/buttons/statusButton'
import remarkButton from '../pub/buttons/remarkButton'
import newProject from '../pub/studentDialog/newProject'
import editProject from '../pub/studentDialog/editProject'
import { EndDialogMixin } from '../pub/EndDialog.vue'
export default {
  name: 'Eshedule',
  components: {
    schedulelist,
    projectInfo,
    stages,
    exportImg,
    fileButton,
    informationButton,
    statusButton,
    remarkButton,
    newProject,
    editProject
  },
  mixins: [EndDialogMixin],
  data () {
    return {
      beizhuTitle: '',
      smes: {
        name: '',
        school: '',
        cellphone: '',
        email: '',
        direction: '',
        sex: ''
      },
      ruleform: {
        status: '',
        beizhu: ''
      },
      tableData: [],
      zhuangTitle: '',
      zhuangDialog: false,
      remarkDialog: false,
      options: ['学员', '渠道', '销售顾问', '规划师', '项目', '方向'],
      interview: {},
      project_type: '',
      teacher: '',
      pid: 0,
      status_id: '',
      remark_id: '',
      activeProject: {},
      beizhuDialog: false,
      exportI: false
    }
  },
  methods: {
    fixitem () {
      this.$refs.editProject.openDialog(this.activeProject)
    },
    showMsgchart (data) {
      switch (data.title) {
        case '修改备注（导师不可见）':
          this.$refs.editRemark.edit(data)
          break
        case '修改状况（导师可见）':
          this.$refs.editStatus.edit(data)
          break
        case 'refresh':
          this.refresh()
          break
      }
    },
    additem () {
      this.$refs.newProject.openDialog(this.activeProject, this.smes)
    },
    getstu1 (smes = false) {
      let id = 0
      if (smes) {
        id = smes.project_id
      } else {
        id = this.pid
      }
      axios
        .get(`/${this.$store.state.roleApi}/project/${id}/`)
        .then(response => {
          this.project_type = response.data.project.project_type
          this.pid = response.data.project.id
          this.smes = response.data.student
          this.activeProject = {
            ...response.data.project
          }
          this.tableData = response.data.stages
        })
        .catch(error => {
          console.log(error)
        })
    },
    refresh () {
      this.getstu1()
    }
  }
}
</script>
