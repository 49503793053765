<template>
  <el-main class="main">
    <div class="content">
      <h2>EPQ选题检索</h2>
      <el-row class="top">
        <el-col :span="8" class="item">
          <el-input
            v-model="subject"
            placeholder="学科门类"
            @input="input_search()"
            @keyup.enter.native="search()"
          >
          </el-input>
        </el-col>
        <el-col :span="7" class="item">
          <el-input
            v-model="specialty"
            placeholder="专业方向"
            @input="input_search()"
            @keyup.enter.native="search()"
          >
          </el-input>
        </el-col>
        <el-col :span="7" class="item">
          <el-input
            placeholder="项目名称关键词"
            v-model="title"
            @keyup.enter.native="search()"
            @input="input_search()"
          >
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button class="sea" type="primary" @click="search()">搜索</el-button>
        </el-col>
      </el-row>
      <div class="DBS">
        <el-table :data="tableData" @sort-change="sortchange" ref="tableList" class="base">
          <el-table-column
            prop="epq_id"
            align="center"
            min-width="12"
            label="编号"
            sortable="custom"
            :sort-orders="['descending', 'ascending']"
          >
          </el-table-column>
          <el-table-column
            prop="degree"
            align="center"
            min-width="10"
            label="难度"
            sortable="custom"
            :sort-orders="['descending', 'ascending']"
          >
          </el-table-column>
          <el-table-column
            prop="title"
            min-width="45"
            header-align="center"
            align="left"
            label="选题"
          >
            <template slot-scope="scope">
              <span v-html="showKeyWord(scope.row.title)"></span>
            </template>
          </el-table-column>
          <el-table-column prop="subject.content" align="center" min-width="10" label="学科门类">
          </el-table-column>
          <el-table-column prop="specialty.content" align="center" min-width="10" label="专业方向">
          </el-table-column>
          <div slot="empty" class="empty" v-if="has_next">
            <i class="eliwise-loading"></i> <span>加载中，请稍后</span>
          </div>
        </el-table>
        <div class="tableborder"></div>
        <div class="bottom">
          <div style="float: left">
            <p>
              共计 <span class="green">{{ topic_total }}</span> 条项目
            </p>
          </div>
          <p class="green" @click="pagedown()" style="cursor: pointer">
            上一页
          </p>
          <p>{{ page }}/{{ page_total }}</p>
          <p class="green" @click="pageup()" style="cursor: pointer">下一页</p>
          <p>跳转至<el-input class="page" v-model="next_page"></el-input>页</p>
          <el-button type="primary" @click="pageto()">确定</el-button>
        </div>
      </div>
    </div>
  </el-main>
</template>

<script>
import axios from '../axios'
import changerole from '../pub/changerole'
export default {
  name: 'Epq',
  components: {
    changerole
  },
  data () {
    return {
      title: '',
      titlevalue: '',
      page: 1,
      topic_total: 1,
      page_total: 1,
      next_page: '',
      has_next: true,
      has_previous: false,
      sort: 'sort_id',
      desc: true,
      subject: '',
      subjects: [],
      specialty: '',
      specialtys: [],
      loading: false,
      tableData: [],
      epqForm: false
    }
  },
  created () {
    this.getlist()
  },
  methods: {
    input_search: _.debounce(function () {
      this.search()
    }, 500),
    search () {
      this.titlevalue = this.title
      this.getsearch()
    },
    getsearch () {
      this.page = 1
      this.getlist()
    },
    sortchange (column) {
      this.sort = column.prop
      switch (column.order) {
        case 'ascending':
          this.desc = false
          break
        case 'descending':
          this.desc = true
          break
        default:
          this.desc = true
          this.sort = ''
      }
      this.getsearch()
    },
    pageto () {
      if (this.next_page > 0 && this.next_page <= this.page_total) {
        this.page = this.next_page
        this.getlist()
        document.querySelector('.el-main').scrollTop = 0
      } else {
        this.$message({
          message: '请输入正确的页码',
          type: 'warning'
        })
      }
    },
    pageup () {
      if (this.has_next) {
        this.page++
        this.getlist()
        document.querySelector('.el-main').scrollTop = 0
      } else {
        this.$message({
          message: '已经到最后一页了',
          type: 'warning'
        })
      }
    },
    pagedown () {
      if (this.has_previous) {
        this.page--
        this.getlist()
        document.querySelector('.el-main').scrollTop = 0
      } else {
        this.$message({
          message: '已经是第一页了',
          type: 'warning'
        })
      }
    },
    getlist () {
      axios
        .get('/DBS/epqs', {
          params: {
            title: this.title,
            subject: this.subject,
            specialty: this.specialty,
            page: this.page,
            sort: this.sort,
            desc: this.desc
          }
        })
        .then(response => {
          this.topic_total = response.data.topic_total
          this.page_total = response.data.page_total
          this.has_next = response.data.has_next
          this.has_previous = response.data.has_previous
          this.tableData = response.data.epqs
        })
    },
    showKeyWord (str) {
      if (this.titlevalue !== '') {
        let regStr = `${this.titlevalue}`
        let reg = new RegExp(regStr, 'g')
        str = str.replace(reg, `<span style="background-color:#F7B500;">${this.titlevalue}</span>`)
      }
      return str
    }
  }
}
</script>
