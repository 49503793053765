<template>
  <el-dialog
    title="分配助教"
    :visible.sync="active"
    width="30%"
    :close-on-click-modal="false"
    destroy-on-close
    class="assistantdialog"
  >
    <el-row>
      <el-col :span="24" class="flex border">
        <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
        <el-input
          v-model="assistName"
          @input="searchAssist"
          @focus="showL"
          @blur="selected"
          placeholder="助教姓名"
          class="seasub fixseasub"
        >
        </el-input>
      </el-col>
    </el-row>
    <div style="position:relative">
      <el-table
        v-if="showList"
        :header-cell-style="{
          background: '#F4F6F9',
          color: '#c0c0c0',
          'text-align': 'center'
        }"
        :data="tableDataSearch"
        height="240"
        @row-click="selectTeacher"
        ref="tabs"
        class="tabin base"
      >
        <el-table-column
          key="2"
          prop="name"
          label="助教姓名"
          align="center"
          min-width="12%"
        >
        </el-table-column>
      </el-table>
    </div>
    <template #footer>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="pushfix" :disabled="pending"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import axios from 'axios'
import { debounce } from 'lodash'
import { MessageBox } from 'element-ui'
export default {
  name: 'assistantDialog',
  data () {
    return {
      showList: false,
      pid: 0,
      aid: '',
      assistName: '',
      tableDataSearch: [],
      active: false,
      pending: false,
      scrollTop: 0,
      has_next: true,
      teacherMisc: ''
    }
  },
  methods: {
    open (pid) {
      this.pid = pid
      this.active = true
      this.$nextTick(function () {
        this.searchAssist()
      })
    },
    close () {
      this.pagenumber = 1
      this.active = false
      this.aid = ''
      this.assistName = ''
    },
    selected () {
      setTimeout(() => {
        this.showList = !this.showList
      }, 400)
    },
    searchAssist: debounce(function () {
      this.pagenumber = 1
      if (this.$refs.tabs) {
        this.$refs.tabs.bodyWrapper.scrollTop = 0
      }
      axios
        .get(`/${this.$store.state.roleApi}/assistants`, {
          params: {
            name: this.assistName,
            page: this.pagenumber
          }
        })
        .then(response => {
          this.has_next = response.data.has_next
          this.tableDataSearch = response.data.assistants
          for (var i = 0; i < this.tableDataSearch.length; i++) {
            if (this.tableDataSearch[i].name === '') {
              this.tableDataSearch.splice(i, 1)
            }
          }
        })
    }, 500),
    selectTeacher (row) {
      this.assistName = row.name
      this.aid = row.id
      axios
        .get(`/${this.$store.state.roleApi}/assistants`, {
          params: {
            name: this.assistName,
            page: this.pagenumber
          }
        })
        .then(response => {
          this.has_next = response.data.has_next
          this.tableDataSearch = response.data.assistName
        })
    },
    linkList () {
      this.$refs.tabs.bodyWrapper.addEventListener('scroll', () => {
        let scrollTop = this.$refs.tabs.bodyWrapper.scrollTop
        let windowHeight =
          this.$refs.tabs.bodyWrapper.clientHeight ||
          this.$refs.tabs.bodyWrapper.clientHeight
        let scrollHeight =
          this.$refs.tabs.bodyWrapper.scrollHeight ||
          this.$refs.tabs.bodyWrapper.scrollHeight
        if (scrollTop + windowHeight >= scrollHeight && this.has_next) {
          this.pagenumber = this.pagenumber + 1
          axios
            .get(`/tdirector/assistants/`, {
              params: {
                name: this.assistName,
                page: this.pagenumber
              }
            })
            .then(response => {
              if (response.data.has_previous) {
                this.tableDataSearch = this.tableDataSearch.concat(
                  response.data.assistName
                )
              }
              this.has_next = response.data.has_next
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    showL () {
      this.showList = !this.showList
      this.$nextTick(() => {
        this.linkList()
      })
    },
    pushfix () {
      if (this.otherItem !== '') {
        this.chooseItem = this.otherItem
      }
      MessageBox.confirm(`是否分配${this.assistName}到该项目？`).then(() => {
        this.pending = true
        axios
          .post(
            `/${this.$store.state.roleApi}/project/${this.pid}/change_assistant`,
          {
            assistant_id: this.aid
          }
          )
          .then(response => {
            if (response.status === 200) {
              this.$message({
                showClose: true,
                message: '添加成功',
                type: 'success'
              })
              setTimeout(() => {
                this.active = false
                window.location.reload()
                this.pending = false
              }, 1000)
            } else this.pending = false
          })
          .catch(error => {
            console.log(error)
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body {
  margin-top: 30px;
  margin-bottom: 40px;
}
.border {
  border-bottom: solid 1px #c0c0c0;
  padding-bottom: 5px;
}
.seasub {
  padding-left: 10px;
  padding-top: 14px;
  position: relative;
  bottom: 3px;
}
.tabin {
  font-size: 12px;
  color: #434a54;
  box-shadow: 0 2px 8px 0 rgba(204, 209, 217, 0.5);
  background-color: #f5f7fa;
  position: absolute;
}
.fixseasub {
  padding-top: 0px !important;
  padding-left: 10px;
}
/deep/.el-table tbody tr:hover > td {
  background-color: #a0d469;
  color: #ffffff;
}
.assistantdialog /deep/ .el-dialog {
  overflow: visible;
}
</style>
