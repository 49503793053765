<template>
  <div>
    <fnav></fnav>
    <div class="main">
      <div class="wrap">
        <el-input
          v-model="finput"
          placeholder="导师姓名或学生姓名"
          @input="search1"
        ></el-input>
        <img
          @click="adduser"
          class="add"
          src="../../assets/icon/添加用户.png"
          alt=""
        />
      </div>
      <el-dialog
        title="添加"
        :visible.sync="dialogVisible"
        width="40%"
        :close-on-click-modal="false"
      >
        <div v-for="(item, index) in addList" :key="index" class="showAdd">
          {{ item.teacher }} -- {{ item.student }} -- {{ item.addPtype }}
          <i
            class="el-icon-close"
            style="margin-right: 20px"
            @click="handleDlete(item)"
          ></i>
        </div>
        <!-- <div class="showAdd" v-if="showAdd">
        {{ addTeacher }}--{{ addStudent }}--{{ addPtype }}
      </div> -->
        <div class="inputicon">
          <i class="el-icon-search"></i>
          <el-input
            placeholder="导师姓名或学生姓名"
            v-model="input2"
            @focus="showL"
            @input="searchList"
            class="addinput"
          >
          </el-input>
        </div>
        <el-table
          height="250"
          :data="tableadd"
          style="width: 100%"
          v-if="showList === true"
          @row-click="selectP"
          class="base"
        >
          <el-table-column
            prop="teacher"
            label="导师姓名"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="student"
            label="学生姓名"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="project_type"
            label="项目类型"
            align="center"
          ></el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="postAdd">确 定</el-button>
        </span>
      </el-dialog>
      <div class="head">
        <div class="left">
          <el-tabs v-model="activeName" @tab-click="handleChange">
            <el-tab-pane label="结项统计" name="first">
              <el-table class="tableJ" :data="table1" :max-height="ch">
                <el-table-column
                  prop="planner"
                  label="规划师"
                  align="center"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  prop="student"
                  label="学生"
                  align="center"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  prop="project_type"
                  label="项目类型"
                  align="center"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  prop="teacher"
                  label="导师"
                  align="center"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  prop="t_identity"
                  label="导师身份"
                  align="center"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  prop="t_star"
                  label="导师星级"
                  align="center"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  prop="start_time"
                  label="启动时间"
                  align="center"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  prop="end_time"
                  label="完成时间"
                  align="center"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="project_time"
                  label="项目历时时长（天）"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="work_time"
                  label="辅导时长（h）"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  prop="hourly_wage"
                  label="时薪"
                  align="center"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  prop="subtotal"
                  label="小计"
                  align="center"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  prop="reward"
                  label="奖励金额"
                  align="center"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  prop="project_wage"
                  label="辅导工资"
                  align="center"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  prop="remark"
                  label="备注"
                  align="center"
                  min-width="5%"
                ></el-table-column>
                <el-table-column width="50px" align="center" min-width="5%">
                  <template slot-scope="scope">
                    <el-button
                      class="edit listedit"
                      @click="cedit(scope.$index, scope.row)"
                    >
                      <i class="el-icon-edit"></i>
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column width="50px" align="center" min-width="5%">
                  <template slot-scope="scope">
                    <el-button
                      class="delete listedit"
                      @click="cdelete(scope.$index, scope.row)"
                    >
                      <i class="el-icon-delete"></i>
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane class="tableG" label="工资统计" name="second">
              <el-table
                :max-height="ch"
                ref="multipleTable"
                :row-style="tableRowStyle"
                :data="table2"
                tooltip-effect="dark"
                @selection-change="handleSelectionChange"
              >
                <el-table-column
                  type="selection"
                  align="center"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  prop="name"
                  label="导师"
                  align="center"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  prop="project_wage"
                  label="辅导总工资"
                  align="center"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  prop="achievements"
                  align="center"
                  label="绩效"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  prop="deduct"
                  label="应扣工资"
                  align="center"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  prop="income"
                  label="个税"
                  align="center"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  prop="grant"
                  label="实发工资"
                  align="center"
                  min-width="5%"
                ></el-table-column>
                <el-table-column
                  prop="grantDate"
                  label="发放日期"
                  align="center"
                  :render-header="renderHeader"
                  min-width="5%"
                ></el-table-column>
                <el-table-column align="center" min-width="5%">
                  <template slot-scope="scope">
                    <img
                      src="../../assets/icon/编辑icon.png"
                      alt=""
                      class="email"
                      @click="fedit(scope.$index, scope.row)"
                      v-if="!scope.row.issend"
                    />
                    <img
                      src="../../assets/icon/编辑灰.png"
                      alt=""
                      class="email"
                      @click="fedit(scope.$index, scope.row)"
                      v-else
                    />
                    <!-- <img
                    src=""
                    class="email"
                    @click="fedit(scope.$index, scope.row)"
                  >
                  </img> -->
                  </template>
                </el-table-column>
                <el-table-column align="center" min-width="5%">
                  <template slot-scope="scope">
                    <img
                      src="../../assets/icon/邮件.png"
                      class="email"
                      @click="femail(scope.$index, scope.row)"
                      v-if="!scope.row.issend"
                    />
                    <img
                      src="../../assets/icon/发邮件灰.png"
                      class="email"
                      @click="femail(scope.$index, scope.row)"
                      v-else
                    />
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <conclusionDialog ref="conclusionDialog" :form="form"></conclusionDialog>
      <salaryDialog ref="salaryDialog" :form2="form2"></salaryDialog>
      <el-dialog title="修改发放日期" :visible.sync="dialogDate" width="50%">
        <div class="putDate">
          <span class="demonstration">发放日期：</span>
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="reDate"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogDate = false">取 消</el-button>
          <el-button type="primary" @click="putall">确 定</el-button>
        </span>
      </el-dialog>
      <dateDialog ref="dateDialog" :date1="date1" :reDate="reDate"></dateDialog>
      <div class="right">
        <span class="demonstration">时间：</span>
        <el-date-picker
          popper-class="aaa"
          v-model="date1"
          type="month"
          placeholder="选择月份"
          :default-value="date1"
          @change="changeTime"
          value-format="yyyy-MM"
        >
        </el-date-picker>
      </div>
      <div class="foot">
        <div class="leftData">
          共<span class="nspan">{{ number }}</span
          >条
        </div>
        <div class="rightData">
          <a
            :href="exportUrl"
            target="blank"
            class="download"
            @click="getexportUrl"
            >导 出</a
          >
          <el-button
            class="emailpost"
            @click="postEmail"
            :disabled="ispost"
            v-if="showPost"
            >发邮件</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MessageBox } from 'element-ui'
import axios from '../axios'
import changerole from '../pub/changerole.vue'
import dateDialog from './dateDialog'
import fnav from './fnav.vue'
import conclusionDialog from './conclusionDialog'
import salaryDialog from './salaryDialog'
import moment from 'moment'
export default {
  name: 'Finance',
  components: {
    changerole,
    fnav,
    dateDialog,
    conclusionDialog,
    salaryDialog
  },
  data () {
    return {
      form: {},
      form2: {},
      dialogDate: false,
      exportUrl: '',
      reDate: '',
      number: '',
      showAdd: false,
      dateDialog: false,
      finput: '',
      ispost: true,
      showPost: false,
      input2: '',
      dialogVisible: false,
      dialogEdit: false,
      dialogEdit2: false,
      showList: false,
      date1: '',
      activeName: 'first',
      table1: [],
      table2: [],
      tableadd: [],
      addTeacher: '',
      addStudent: '',
      addPtype: '',
      addID: [],
      ch: '',
      emailId: [],
      daochu: '',
      getUrl: 1,
      adddate: '',
      lmonth: '',
      host: '',
      addList: []
    }
  },
  beforeCreate () {
    axios.get('/is_login/').then(response => {
      if (response.data.roles.indexOf(this.$store.state.role) > -1) {
        this.$store.commit('setLogin', response.data)
      } else {
        MessageBox.alert('未登录，请重新登录')
        localStorage.removeItem('Authorization')
        this.$router.push('/login')
      }
    })
  },
  created () {
    this.date1 = moment(new Date())
        .subtract(1, 'months')
        .format('YYYY-MM')
    this.ch = document.body.clientHeight - 240
    const that = this
    window.onresize = () => {
      that.ch = document.body.clientHeight - 240
    }
  },
  mounted () {
    this.host = window.location.host

    this.getconclusions()
    this.getUrl = 1
  },
  methods: {
    tableRowStyle ({ row, rowIndex }) {
      let styleJson = {}
      if (row.issend) {
        styleJson = {
          color: '#c0c0c0'
        }
      }
      return styleJson
    },
    changeTime () {
      this.getconclusions()
      this.getSalary()
    },
    handleDlete (item) {
      this.addList.splice(item, 1)
    },
    getexportUrl () {
      if (this.getUrl === 1) {
        this.exportUrl = `${axios.defaults.baseURL}/finance/conclusions/export?date=${this.date1}`
      } else if (this.getUrl === 2) {
        this.exportUrl = `${axios.defaults.baseURL}/finance/salary/export?date=${this.date1}`
      }
    },
    putall () {
      if (this.reDate === '') {
        this.$message({
          message: '请填写修改日期',
          type: 'warning'
        })
      } else {
        axios
          .post(`/finance/salary/edit_date`, {
            grantDate: this.reDate,
            date: this.date1
          })
          .then(res => {
            if (res.status === 200) {
              this.getSalary()
              this.dialogDate = false
            }
          })
      }
    },
    renderHeader (h, { column }) {
      return h('div', [
        h('span', column.label),
        h('i', {
          class: 'el-icon-edit',
          style: 'margin-left:5px;',
          on: {
            click: this.putDate
          }
        })
      ])
    },
    putDate () {
      this.reDate = this.table2[0].grantDate
      this.$refs.dateDialog.openDialog()
    },
    postEmail () {
      MessageBox.alert(`确认发送${this.emailId.length}封邮件？`).then(() => {
        axios
          .post(`/finance/salary/email`, {
            id: this.emailId
          })
          .then(res => {
            if (res.status === 200) {
              this.$message({
                message: '发送成功',
                type: 'success'
              })
              this.getSalary()
            }
          })
      })
    },
    postAdd () {
      for (var i = 0; i < this.addList.length; i++) {
        this.addID.push(this.addList[i].id)
      }
      axios
        .post(`/finance/conclusion/clause`, {
          id: this.addID,
          date: this.date1
        })
        .then(res => {
          if (res.status === 200) {
            window.location.reload()
          }
        })
    },
    adduser () {
      this.dialogVisible = true
      this.getuser()
    },
    search1 () {
      this.getconclusions()
      this.getSalary()
    },
    searchList () {
      this.getuser()
    },
    showL () {
      this.showList = true
    },
    notShow () {
      this.showList = false
    },
    selectP (row) {
      let addUser = {}
      addUser.teacher = row.teacher
      addUser.student = row.student
      addUser.addPtype = row.project_type
      addUser.id = row.id
      this.addList.push(addUser)
      this.showAdd = true
      this.showList = false
    },
    getuser () {
      axios
        .get(`/finance/conclusion/list`, {
          params: {
            name: this.input2
          }
        })
        .then(res => {
          this.tableadd = res.data.subject
        })
    },
    handleChange (e) {
      if (e.label === '结项统计') {
        this.getconclusions()
        this.showPost = false
        this.getUrl = 1
      } else {
        this.showPost = true
        this.getSalary()
        this.getUrl = 2
      }
    },
    getSalary () {
      axios
        .get(`/finance/salary`, {
          params: {
            name: this.finput,
            date: this.date1
          }
        })
        .then(res => {
          if (res.status === 200) {
            this.table2 = res.data.salary
            this.number = res.data.salary.length
          }
        })
    },
    cedit (index, row) {
      this.$refs.conclusionDialog.openDialog()
      this.form = { ...row }
    },
    cdelete (index, row) {
      MessageBox.confirm('是否删除？').then(() => {
        axios.delete(`/finance/conclusion/${row.id}`).then(() => {
          window.location.reload()
        })
      })
    },
    handleSelectionChange (val) {
      let email = []
      val.map(item => {
        email.push(item.id)
      })
      this.emailId = email
      if (this.emailId.length !== 0) {
        this.ispost = false
      } else {
        this.ispost = true
      }
    },
    fedit (index, row) {
      let arr2 = row
      this.form2 = { ...arr2 }
      this.$refs.salaryDialog.openDialog()
    },
    femail (index, row) {
      let emailId = []
      emailId.push(row.id)
      MessageBox.confirm(`确认发送1封邮件？`).then(() => {
        axios
          .post(`/finance/salary/email`, {
            id: emailId
          })
          .then(res => {
            if (res.status === 200) {
              this.$message({
                message: '发送成功',
                type: 'success'
              })
              this.getSalary()
            }
          })
      })
    },
    putedit1 () {
      axios
        .put(`/finance/conclusion/${this.form.id}`, {
          form: this.form
        })
        .then(res => {
          if (res.status == 200) {
            this.getconclusions()
            this.dialogEdit = false
          }
        })
    },
    putedit2 () {
      axios
        .put(`/finance/salary/${this.form2.id}`, {
          form: this.form2
        })
        .then(res => {
          if (res.status === 200) {
            this.getSalary()
            this.dialogEdit2 = false
          }
        })
    },
    getconclusions () {
      axios
        .get(`/finance/conclusions`, {
          params: {
            name: this.finput,
            date: this.date1
          }
        })
        .then(res => {
          if (res.status === 200) {
            this.table1 = res.data.conclusions
            this.number = res.data.conclusions.length
          }
        })
    }
  },
  watch: {
    date1: {
      handler (newName, oldName) {
        if (newName !== oldName) {
          this.date1 = newName
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-table__row div {
  color: inherit !important;
}
.inputicon {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #ebeef5;
}
.main {
  padding: 0 147px;
  position: relative;
  overflow: hidden;
}
.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 50px;
}
.rightData {
  padding-right: 120px;
  text-align: right;
  margin-top: -4px;
}
.changerole {
  position: relative;
  right: -25px;
}
.add {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left {
  width: 100%;
}
.right {
  position: absolute;
  right: 156px;
  top: 125px;
}
.formWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.email {
  width: 13px;
  height: 11px;
}
.emailpost {
  border: 1px solid #a0d469;
  background-color: #f4f6f9;
  color: #a0d469;
}
.emailpost:hover {
  color: #f4f6f9;
  background-color: #a0d469;
}
.formContainer {
  width: 32%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.formMain {
  display: flex;
  align-items: center;
}
.showAdd {
  text-align: left;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.foot {
  position: absolute;
  bottom: 40px;
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding-top: 10px;
  border-top: 1px solid #eee;
  margin-top: 40px;
}
.download {
  border: 1px solid #a0d469;
  color: #a0d469;
  width: 80px;
  height: 30px;
  font-size: 12px;
  padding: 4px 20px;
  margin-right: 12px;
  text-decoration: none;
}
.download:hover {
  color: #f4f6f9;
  background-color: #a0d469;
}
.leftData {
  font-size: 12px;
}
.putDate {
  text-align: left;
}
.addwrap {
  text-align: left;
  margin-top: 10px;
}
.el-icon-close:hover {
  color: #a0d469;
}
.inputwidth {
  width: 135px !important;
}
/deep/ .changerole .el-input .el-input__inner {
  border: none;
}
.nspan {
  color: #a0d469;
  margin: 0 5px;
}
/deep/ .el-tabs__nav-wrap::after {
  background-color: #f4f6f9;
}
/deep/ .el-icon-date:before {
  top: 0;
}
/deep/ .el-col-24 {
  padding-right: 36px;
  text-align: right;
}
/deep/ .el-dialog .el-input__inner {
  width: 100%;
}
/deep/ .el-form-item__label {
  position: absolute;
  top: -5px;
}
/deep/ [data-v-abf932de] .el-icon-date:before {
  margin-right: 7px;
}
/deep/ [data-v-1019b063] .el-dialog .el-input__inner {
  padding-left: 30px;
}
/deep/
  .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk)
  .el-form-item__label-wrap
  > .el-form-item__label:before {
  color: #000;
}
/deep/ .el-button.is-disabled,
.el-button.is-disabled:hover,
.el-button.is-disabled:focus {
  background-color: #f4f6f9;
  color: #c0c0c0;
  border: 1px solid #c0c0c0;
}
/deep/ .el-table::before {
  width: 0;
  height: 0;
}
//"添加"表hover绿的
/deep/.base .el-table__body-wrapper tr:hover {
  background-color: #a0d46a;
}
/deep/.base .el-table__header tr {
  background-color: #f5f7fa;
}
/deep/
  .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk)
  .el-form-item__label-wrap
  > .el-form-item__label:before {
  content: '' !important;
}
/deep/ .el-dialog .addinput .el-input__inner {
  border: none;
  background-color: transparent;
}
/deep/ [data-v-abf932de] .el-dialog__title {
  margin-left: 0;
}
/deep/ [data-v-bcdc5636] .el-dialog__title {
  margin-left: 0;
}
/deep/.el-input__prefix .el-input__icon {
  position: relative;
  left: -3px;
  top: -5px;
}
</style>
