<template>
  <el-main class="main">
    <recommendations />
  </el-main>
</template>

<script>
import recommendations from '../pub/recommendations.vue'
export default {
  name: 'TDrecommendations',
  components: {
    recommendations
  }
}
</script>
