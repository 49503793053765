<template>
  <el-container class="h100">
    <div class="main" v-if="pc">
      <vue-particles color="#ffffff" />
      <div class="pcmid">
        <el-form :model="ruleform" :rules="rules" ref="ruleform">
          <div @click="yz">
            <div class="pctitle">
              <span class="black">欢迎来到</span>
              <span class="green">菁英启航系统4.0</span>
            </div>
            <div class="mb30form">
              <el-form-item prop="username">
                <el-input
                  class="mb30"
                  v-model="ruleform.username"
                  placeholder="用户名"
                  prefix-icon="el-icon-user"
                ></el-input>
              </el-form-item>
            </div>
            <div class="mb30form">
              <el-form-item prop="password">
                <el-input
                  class="mb30"
                  v-model="ruleform.password"
                  type="password"
                  placeholder="密码"
                  @keyup.enter.native="login('ruleform')"
                  prefix-icon="el-icon-lock"
                ></el-input>
              </el-form-item>
            </div>
            <div v-if="yan" class="mb30form flex">
              <el-form-item prop="captcha" class="captcha">
                <el-input
                  class="imgc"
                  v-model="ruleform.captcha"
                  @keyup.enter.native="login('ruleform')"
                  placeholder="验证码"
                ></el-input>
              </el-form-item>
              <img class="cimg" @click="newTime" :src="origin + time" />
            </div>
          </div>
          <el-button class="truel loginbutton" @click="login('ruleform')"
            >登 录</el-button
          >
          <div class="logo">
            <img src="../assets/icon/菁英启航系统.png" height="58" width="58" />
          </div>
        </el-form>
      </div>
      <footer class="footer">
        备案号：<a
          class="beian"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          >京ICP备 15000012号-7</a
        >
      </footer>
    </div>
    <div v-else>
      <div class="mid">
        <el-form :model="ruleform" :rules="rules" ref="ruleform">
          <div @click="yz" style="text-align: center">
            <img src="../assets/icon/菁英启航系统.png" height="43" width="47" />
            <div class="title">
              <span class="black">欢迎来到</span>
              <span class="green">菁英启航系统4.0</span>
            </div>
            <div class="mb30form">
              <el-form-item prop="username">
                <el-input
                  class="mb30"
                  v-model="ruleform.username"
                  placeholder="用户名"
                  prefix-icon="el-icon-user"
                ></el-input>
              </el-form-item>
            </div>
            <div class="mb30form">
              <el-form-item prop="password">
                <el-input
                  class="mb30"
                  v-model="ruleform.password"
                  type="password"
                  placeholder="密码"
                  @keyup.enter.native="login('ruleform')"
                  prefix-icon="el-icon-lock"
                ></el-input>
              </el-form-item>
            </div>
            <div v-if="yan" class="mb30form flex">
              <el-form-item prop="captcha" class="captcha">
                <el-input
                  class="imgc"
                  v-model="ruleform.captcha"
                  @keyup.enter.native="login('ruleform')"
                  placeholder="验证码"
                ></el-input>
              </el-form-item>
              <img class="cimg" @click="newTime" :src="origin + time" />
            </div>
          </div>
          <el-button class="truel loginbutton" @click="login('ruleform')"
            >登 录</el-button
          >
        </el-form>
      </div>
    </div>
  </el-container>
</template>

<script>
import axios from './axios'
import { mapMutations } from 'vuex'

export default {
  name: 'login',
  data () {
    return {
      rules: {
        password: [{ required: true, message: '请填写密码', trigger: 'blur' }],
        username: [
          { required: true, message: '请填写用户名', trigger: 'blur' }
        ],
        captcha: [{ required: true, message: '请填写验证码', trigger: 'blur' }]
      },
      yan: false,
      ruleform: {
        username: '',
        password: '',
        captcha: ''
      },
      origin: `${axios.defaults.baseURL}/captcha?`,
      time: '',
      role: '',
      userToken: '',
      error: '',
      pc: true
    }
  },
  created () {
    if (/Android|webOS|iPhone/i.test(navigator.userAgent)) {
      this.pc = false
    }
  },
  methods: {
    ...mapMutations(['changeLogin']),
    yz () {
      // eslint-disable-next-line no-undef
      if (localStorage.Authorization === 'YTY') {
        this.yan = true
      } else {
        this.yan = false
      }
    },
    login (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const _this = this
          const data = {
            username: this.ruleform.username,
            password: this.ruleform.password,
            captcha: this.ruleform.captcha
          }
          axios
            .post('/login/', data)
            .then(response => {
              _this.userToken = 'YTY' + new Date().getTime()
              // 将用户token保存到vuex中
              _this.changeLogin({ Authorization: _this.userToken })
              this.role = response.data.groups[0]
              if (this.$store.state.defaultRouter !== '') {
                this.$router.push({
                  path: this.$store.state.defaultRouter
                })
              } else this.redirect()
            })
            .catch(() => {
              _this.userToken = 'YTY'
              // 将用户token保存到vuex中
              _this.changeLogin({ Authorization: _this.userToken })
              this.yan = true
              this.newTime()
              this.ruleform.captcha = ''
            })
        } else {
          this.yz()
          return false
        }
      })
    },
    async redirect () {
      let redirect = ''
      let path = ''
      if (this.role === '管理员') {
        path = '/M/Maccount'
      } else if (this.role === '导师') {
        path = '/T/tschedule'
      } else if (this.role === '学生') {
        path = '/S/newmission'
      } else if (this.role === '规划师') {
        path = '/A/Aschedule'
      } else if (this.role === '教务') {
        path = '/J/Jinterview'
      } else if (this.role === '选会') {
        path = '/C'
      } else if (this.role === '助教') {
        path = '/AS/ASschedule'
      } else if (this.role === '市场总监') {
        path = '/MD/MDschedule'
      } else if (this.role === '渠道经理') {
        path = '/D/Dschedule'
      } else if (this.role === '客户经理') {
        path = '/K/Kschedule'
      } else if (this.role === '教学总监') {
        path = '/TD/TDschedule'
      } else if (this.role === '会务组') {
        path = '/MS/MSinterview'
      } else if (this.role === '助教总监') {
        path = '/AD/ADschedule'
      } else if (this.role === '选会') {
        path = '/C/Clist'
      } else if (this.role === '财务') {
        path = '/F'
      } else if (this.role === '渠道') {
        path = '/Enterpise'
      } else if (this.role === '渠道选题库') {
        path = '/Enterpise'
      } else if (this.role === 'glasgow') {
        path = '/uestc-glasgow'
      } else if (this.role === '选题库') {
        path = 'Topic/TopicT'
      }
      redirect = decodeURIComponent(this.$route.query.redirect || path)
      this.$router.push({
        path: redirect
      })
    },
    newTime () {
      this.time = new Date().getTime()
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  background-color: #fff;
  font-family: NSimSun;
  color: #656d78;
}
.beian {
  text-decoration: none;
  color: #656d78;
}
/deep/.el-input__icon {
  line-height: 60px;
  font-size: 20px;
  color: #656d78;
}
/deep/.el-input__inner {
  border-radius: 0px;
  height: 60px;
  padding-left: 50px !important;
  font-size: inherit;
  width: 100%;
}
/deep/.el-input__prefix {
  left: 15px;
}
.pcmid {
  box-shadow: 0 2px 15px 0 rgba(204, 209, 217, 0.5);
  width: 33%;
  padding: 75px 5% 27px 5%;
  min-width: 470px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: white;
  opacity: 0.85;
}
.mid {
  width: 33%;
  min-width: 280px;
  margin: auto;
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: white;
  opacity: 0.85;
}
.main {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  /*background-color: #f4f6f9;*/
  position: relative;
  background-size: cover;
  background-image: url('https://elitebackground.oss-cn-zhangjiakou.aliyuncs.com/qihang-login-bg.jpeg');
}
.pctitle {
  font-size: 30px;
  letter-spacing: 1px;
  font-weight: normal;
  margin-bottom: 50px;
}
.title {
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: normal;
  margin-bottom: 30px;
}
.flex {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative;
}
.green {
  color: #a0d469;
}
.truel {
  width: 100%;
  height: 60px;
  font-size: 20px;
}
.mb30form {
  margin-bottom: 30px;
}
.logo {
  margin-top: 30px;
  text-align: center;
}
.black {
  color: #656d78;
}
.cimg {
  width: 180px;
  height: 60px;
  margin-left: 15px;
}
.captcha {
  flex: 1;
}
.sky {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.mb30 {
  margin-bottom: 0px;
}
</style>
