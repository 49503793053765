<template>
  <el-main class="main">
    <div class="flex">
      <div class="listMD" ref="element">
        <div class="search flex">
          <div class="seaicon">
            <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
          </div>
          <el-input v-model="stutitle" @input="search" class="seasubfix">
          </el-input>
          <el-select
            v-model="role"
            popper-class="rolelist"
            class="changerole"
            @change="rolechange"
          >
            <el-option v-for="item in options" :key="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="jstudent">
          <projectList
            class="preWrapper"
            :type="'not_interviewed'"
            :selectId.sync="selectId"
            ref="not_interviewed"
          />
          <projectList
            class="preWrapper"
            :type="'interviewed'"
            :selectId.sync="selectId"
            ref="interviewed"
            v-if="not_interviewed"
          />
          <div
            style="font-size: 16px;color: #656d78;padding-top: 1em;"
            v-if="selectId === 0"
          >
            未搜索到相关信息
          </div>
        </div>
      </div>
      <div class="neirong">
        <div class="jtitle">
          <h2 class="jtext">面试学员</h2>
        </div>
        <projectInfo :project="activeProject">
          <div slot="roles" class="rolesIcon">
            <img src="../../assets/roles/icon/导师.png" />
            <span>{{ activeProject.teacher || '待分配' }}</span>
            <img src="../../assets/roles/icon/规划师.png" />
            <span>{{ activeProject.planner }}</span>
            <img src="../../assets/roles/icon/客户经理.png" />
            <span>{{ activeProject.amanagerName || '无' }}</span>
          </div>
          <div slot="buttons">
            <informationButton :pid="pid"> </informationButton>
            <el-button type="primary" @click="sendmail">
              面试邮件
            </el-button>
            <el-button type="primary" @click="openTeacherDialog">
              {{ typeof teacher.id === 'undefined' ? '分配导师' : '修改导师' }}
            </el-button>
          </div>
          <el-row class="interview" slot="stages">
            <img src="../../assets/icon/面试.png" />
            <span v-if="!interview">
              暂无面试反馈
            </span>
            <span v-else class="viewdate">{{ interview.date }}</span>
            <span v-if="interview">
              {{ interview.situation }}
            </span>
          </el-row>
        </projectInfo>
      </div>
    </div>
    <teacherDialog ref="teacherDialog" />
    <interviewMail v-if="mail" />
  </el-main>
</template>

<script>
/* eslint-disable no-console */
import axios from '../axios'
import projectList from '../pub/projectList'
import projectInfo from '../pub/projectInfo'
import informationButton from '../pub/buttons/informationButton'
import teacherDialog from '../pub/teacherDialog'
import interviewMail from '../pub/mail/interviewMail'
import _ from 'lodash'
export default {
  name: 'TDinterview',
  components: {
    projectInfo,
    projectList,
    informationButton,
    teacherDialog,
    interviewMail
  },
  data () {
    return {
      smes: {
        name: '',
        school: '',
        cellphone: '',
        email: '',
        direction: '',
        sex: ''
      },
      tableData: [],
      stutitle: '',
      options: ['学员', '导师', '项目'],
      role: '学员',
      teacher: '',
      project_type: '',
      s_name: '',
      t_name: '',
      pro_type: '',
      pid: 0,
      interview: undefined,
      activeProject: {},
      selectId: 0,
      not_interviewed: false,
      connectTime: '',
      mail: false
    }
  },
  watch: {
    selectId (newVal, oldVal) {
      if (newVal !== oldVal) this.getProject()
    }
  },
  methods: {
    sendmail () {
      this.mail = true
    },
    search: _.debounce(function () {
      if (this.role === '学员') this.s_name = this.stutitle
      else if (this.role === '导师') this.t_name = this.stutitle
      else if (this.role === '项目') this.pro_type = this.stutitle
      this.$refs.not_interviewed.getList(
        this.s_name,
        this.t_name,
        this.pro_type
      )
      this.$refs.interviewed.getList(this.s_name, this.t_name, this.pro_type)
    }, 500),
    fixitem () {
      this.$refs.editProject.openDialog(this.activeProject)
    },
    additem () {
      this.$refs.newProject.openDialog(this.activeProject, this.smes)
    },
    rolechange () {
      this.s_name = ''
      this.t_name = ''
      this.pro_type = ''
      this.stutitle = ''
      this.search()
    },
    getProject () {
      axios
        .get(`/${this.$store.state.roleApi}/project/${this.selectId}/`)
        .then(response => {
          this.teacher = response.data.teacher
          this.project_type = response.data.project.project_type
          this.interview = response.data.interview[0]
          this.pid = response.data.project.id
          this.smes = response.data.student
          this.activeProject = {
            ...response.data.project,
            sponser: response.data.support,
            amanager: response.data.amanager,
            cmanager: response.data.cmanager
          }
          this.connectTime = response.data.project.connectTime
        })
        .catch(error => {
          console.log(error)
        })
    },
    openTeacherDialog () {
      this.$refs.teacherDialog.open(this.pid, this.teacher, this.connectTime)
    }
  }
}
</script>
