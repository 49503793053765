<template>
  <el-button
    type="primary"
    @mouseenter.native="active = true"
    @mouseleave.native="active = false"
    @focus.native="active = true"
    @blur.native="active = false"
    class="button"
  >
    <svg
      width="15px"
      height="15px"
      viewBox="0 0 15 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: Sketch 60.1 (88133) - https://sketch.com -->
      <title>Group 34 Copy</title>
      <desc>Created with Sketch.</desc>
      <g
        id="页面-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="学员进度" transform="translate(-1322.000000, -200.000000)">
          <g
            id="分组-10-Copy-2备份"
            transform="translate(1315.000000, 193.000000)"
          >
            <g id="Group-34-Copy" transform="translate(7.000000, 7.000000)">
              <g id="svg" :fill="colors.background">
                <path
                  d="M7.5,15 C3.3645654,15 0,11.6354355 0,7.5 C0,3.3645654 3.3645654,0 7.5,0 C11.6354355,0 15,3.3645654 15,7.5 C15,11.6354355 11.6354355,15 7.5,15 L7.5,15 Z"
                  id="Shape"
                ></path>
              </g>
              <g
                id="svg"
                transform="translate(3.774994, 3.506653)"
                :fill="colors.color"
              >
                <path
                  d="M0.5334462,7.36359787 L0.442726702,7.36359787 C0.291920175,7.36359787 0.150539047,7.28701646 0.0692449838,7.15859457 C-0.0120490852,7.0313519 -0.0226527757,6.87111971 0.042146898,6.7332739 C2.1393,2.25620639 4.468554,0 7.04522475,0 L7.5,0 L7.14536947,0.286296538 C6.59044905,0.73164679 6.11093025,1.36314902 5.5253775,2.34103482 C5.39342175,2.56135374 4.93511122,3.35190975 4.96456575,3.30124798 C4.74660338,3.67355149 4.5863718,3.93275013 4.4273175,4.16131643 C3.96547275,4.82698568 3.55546763,5.16276574 3.08419725,5.16276574 L1.8530031,5.16276574 L0.53344605,7.36359787 L0.5334462,7.36359787 Z M1.44302313,6.8829345 L6.56455376,6.8829345 C6.60696791,6.8829345 6.64820407,6.89942924 6.67765832,6.93006152 C6.70711257,6.95951591 6.72478545,7.00075205 6.72478545,7.04316608 C6.72478545,7.08558011 6.70829077,7.12681626 6.67765832,7.15627064 C6.64702588,7.18572503 6.60696791,7.20339768 6.56455376,7.20339768 L1.44302313,7.20339768 C1.35465994,7.20339768 1.28279115,7.13152924 1.28279115,7.04316608 C1.28279115,6.95480292 1.35465994,6.8829345 1.44302313,6.8829345 L1.44302313,6.8829345 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    添加备注
    <slot></slot>
  </el-button>
</template>

<script>
/* eslint-disable no-console */
export default {
  data () {
    return {
      active: false,
      colors: {
        color: '#fff',
        background: '#a0d469'
      }
    }
  },
  watch: {
    active () {
      if (this.active) {
        this.colors = {
          color: '#a0d469',
          background: '#fff'
        }
      } else {
        this.colors = {
          color: '#fff',
          background: '#a0d469'
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.button svg {
  vertical-align: sub;
}
</style>
