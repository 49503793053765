<template>
  <el-row align="middle" :gutter="10">
    <el-col :span="8" class="channel_type">
      <el-radio v-model="channel_type" label="inst" @change="selectRadio">
        机构
      </el-radio>
      <el-radio v-model="channel_type" label="cons" @change="selectRadio">
        个人顾问
      </el-radio>
      <el-radio v-model="channel_type" label="own">自有</el-radio>
    </el-col>
    <el-col :span="16" class="flex border">
      <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
      <el-input
        v-model="channelName"
        @input="searchchannel"
        @focus="showL"
        @blur="selected"
        :placeholder="placeholder"
        class="seasub fixseasub"
        :disabled="channel_type === 'own'"
      >
      </el-input>
      <el-table
        v-if="showList && this.channel_type === 'inst'"
        :header-cell-style="{
          background: '#F4F6F9',
          color: '#c0c0c0',
          'text-align': 'center'
        }"
        :data="tableDataSearch"
        max-height="225"
        @row-click="selectrow"
        ref="tabs"
        class="tabin base"
      >
        <el-table-column
          key=""
          prop="name"
          label="品牌名"
          align="center"
          min-width="30%"
        >
        </el-table-column>
        <el-table-column
          key="2"
          prop="abbreviation"
          label="公司简称"
          align="center"
          min-width="70%"
        >
        </el-table-column>
      </el-table>
      <el-table
        v-if="showList && this.channel_type === 'cons'"
        :header-cell-style="{
          background: '#F4F6F9',
          color: '#c0c0c0',
          'text-align': 'center'
        }"
        :data="tableDataSearch"
        max-height="225"
        @row-click="selectcons"
        ref="tabs"
        class="tabin base"
      >
        <el-table-column
          key=""
          prop="name"
          label="顾问姓名"
          align="center"
          min-width="30%"
        >
        </el-table-column>
        <el-table-column
          key="2"
          prop="company"
          label="机构名称"
          align="center"
          min-width="70%"
        >
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>

<script>
import axios from '../axios'
import { debounce } from 'lodash'
export default {
  name: 'channelSearchBar',
  props: {
    type: {
      type: String,
      default: 'cons'
    },
    select: {
      type: [Number, Object, String]
    },
    cmanager: {
      type: [Number, String]
    },
    amanager: {
      type: [Number, String]
    },
    readOnly: {
      type: Boolean
    }
  },
  data () {
    return {
      showList: false,
      tableDataSearch: [],
      channelName: ''
    }
  },
  computed: {
    placeholder () {
      let holders = {
        inst: '渠道名称',
        cons: '顾问名称'
      }
      return holders[this.type]
    },
    channel_type: {
      get: function () {
        return this.type
      },
      set: function (newValue) {
        this.$emit('update:type', newValue)
      }
    },
    channel: {
      get: function () {
        return this.select
      },
      set: function (newValue) {
        this.$emit('update:select', newValue.id)
        if (this.channel_type === 'inst') {
          this.channelName = newValue.abbreviation
        } else this.channelName = newValue.name
        if (newValue.name === '自有') {
          this.$emit('update:cmanager', 1)
          this.$emit('update:amanager', 1)
        }
      }
    }
  },
  watch: {
    channel_type: {
      handler () {
        if (this.channel_type === '') {
          this.$emit('update:type', 'inst')
        } else {
          if (this.channel_type === 'own') {
            this.channel = { id: '', name: '自有' }
          } else {
            this.channelName = ''
            this.searchchannel()
          }
        }
      },
      immediate: true
    },
    select: {
      handler: function (val) {
        if (typeof val === 'object') {
          this.channel = val
        }
      },
      immediate: true
    }
  },
  methods: {
    selectRadio () {
      this.$emit('update:cmanager', '')
      this.$emit('update:amanager', '')
    },
    searchC: debounce(function () {
      this.searchchannel()
    }, 500),
    searchchannel () {
      if (this.channel_type == 'inst') {
        axios
          .get(`/${this.$store.state.roleApi}/project/institutions/`, {
            params: {
              abbreviation: this.channelName
            }
          })
          .then(res => {
            this.tableDataSearch = res.data.res
          })
      } else if (this.channel_type == 'cons') {
        axios
          .get(`/${this.$store.state.roleApi}/channel/consultants/`, {
            params: {
              name: this.channelName
            }
          })
          .then(res => {
            this.tableDataSearch = res.data.res
          })
      }
    },
    showL () {
      this.searchC()
      this.showList = true
    },
    selected () {
      setTimeout(() => {
        this.showList = false
      }, 400)
    },
    selectrow (row) {
      this.channel = row
      this.$emit('update:cmanager', row.cmanager.id)
      this.$emit('update:amanager', row.amanager.id)
      let list = false
      if (this.channel_type === 'inst') {
        list = row.advisers
      }
      this.$emit('validateChannel', list)
    },
    selectcons (row) {
      this.channel = row
      this.$emit('update:cmanager', row.salesman.id)
      this.$emit('update:amanager', 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.border {
  border-bottom: solid 1px #c0c0c0;
  padding-bottom: 5px;
}
.flex {
  height: auto;
}
.tabin {
  font-size: 12px;
  color: #434a54;
  box-shadow: 0 2px 8px 0 rgba(204, 209, 217, 0.5);
  position: absolute;
  top: 30px;
  background-color: #f5f7fa;
  z-index: 1000;
}

.seasub {
  padding-left: 10px;
  height: 25px;
  position: relative;
  bottom: 0px;
  line-height: 100%;
}
.channel_type {
  display: flex;
  flex-wrap: wrap;
  margin-top: 7px;
}
/deep/.el-table tbody tr:hover > td {
  background-color: #a0d469;
  color: #ffffff;
}
</style>
