<template>
  <el-button
    type="primary"
    @mouseenter.native="active = true"
    @mouseleave.native="active = false"
    @focus.native="active = true"
    @blur.native="active = false"
    class="button"
  >
    <svg
      width="15px"
      height="15px"
      viewBox="0 0 15 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: Sketch 60.1 (88133) - https://sketch.com -->
      <title>分组 5 copy</title>
      <desc>Created with Sketch.</desc>
      <g
        id="页面-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="学员进度" transform="translate(-1231.000000, -201.000000)">
          <g id="分组-10-Copy-2" transform="translate(1224.000000, 193.000000)">
            <g id="编组" transform="translate(7.000000, 7.000000)">
              <g id="分组-5-copy" transform="translate(0.000000, 1.000000)">
                <g id="分组-copy" :fill="colors.background">
                  <path
                    d="M7.5,15 C11.6421354,15 15,11.6421354 15,7.5 C15,3.35786445 11.6421354,0 7.5,0 C3.35786445,0 0,3.35786445 0,7.5 C0,11.6421354 3.35786445,15 7.5,15 Z"
                    id="椭圆形"
                  ></path>
                </g>
                <g
                  id="分组-4"
                  transform="translate(6.750001, 3.000000)"
                  :fill="colors.color"
                  :stroke="colors.color"
                >
                  <polygon
                    id="三角形"
                    stroke-linecap="square"
                    stroke-linejoin="bevel"
                    transform="translate(0.750000, 3.000000) scale(1, -1) translate(-0.750000, -3.000000) "
                    points="0.75 0 1.5 6 0 6"
                  ></polygon>
                  <rect
                    id="矩形"
                    x="0.5"
                    y="7.99999925"
                    width="1"
                    height="1"
                  ></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    添加状况
    <slot></slot>
  </el-button>
</template>

<script>
/* eslint-disable no-console */
export default {
  data () {
    return {
      active: false,
      colors: {
        color: '#fff',
        background: '#a0d469'
      }
    }
  },
  watch: {
    active () {
      if (this.active) {
        this.colors = {
          color: '#a0d469',
          background: '#fff'
        }
      } else {
        this.colors = {
          color: '#fff',
          background: '#a0d469'
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.button svg {
  vertical-align: sub;
}
</style>
