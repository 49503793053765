<template>
  <div id="main">
    <h1>菁英背景提升学生信息表</h1>
    <div id="projectInfo">
      <Item class="item" :dic="project" :data="data.project">
        <template #misc="{ item }" v-if="item">
          <pre>{{ item }}</pre>
        </template>
      </Item>
      <Item class="item" :dic="student" :data="data.student" />
      <list class="item" :dic="educationalMes" :data="data.educationMes" />
      <list class="item" :dic="activityMes" :data="data.activityMes" />
      <list class="item" :dic="scienceMes" :data="data.scienceMes" />
      <Item class="item" :dic="language" :data="data.language" :hidden="true" />
      <Item class="item" :dic="computerMes" :data="data.computerMes" />
    </div>
  </div>
</template>

<script>
import Item from './item'
import list from './list'
import axios from '../../axios'
export default {
  name: 'projectInfo',
  components: {
    Item,
    list
  },
  data () {
    return {
      data: {
        student: {
          id: 0,
          sex: '',
          name: '',
          misc: '',
          email: '',
          wechat: '',
          phone: '',
          country: '',
          identification: '',
          address: '',
          planedSpecialty: '',
          schoolStartTime: '',
          school: '',
          direction: '',
          username: ''
        },
        educationMes: [],
        activityMes: [],
        scienceMes: [],
        language: {
          four: '',
          six: '',
          toefl: '',
          ieltes: '',
          gre: '',
          gmat: '',
          other: ''
        },
        computerMes: '',
        project: {
          id: '',
          projectName: '',
          important: false,
          abnormal: true,
          connectTime: '',
          project_type: '',
          attachments: [],
          misc: '',
          startTime: '',
          channel: ''
        }
      },
      project: {
        name: '项目信息',
        content: {
          project_type: {
            name: '项目类型'
          },
          projectName: {
            name: '项目名称'
          },
          connectTime: {
            name: '面试时间'
          },
          startTime: {
            name: '意向开始时间'
          },
          channel: {
            name: '学生来源'
          },
          misc: {
            name: '学生备注',
            type: 'long'
          },
          cmanager: {
            name: '渠道经理'
          },
          amanager: {
            name: '客户经理'
          },
          planner: {
            name: '规划师'
          },
          assistant: {
            name: '助教'
          },
          attachments: {
            name: '附件'
          }
        }
      },
      student: {
        name: '基本信息',
        content: {
          name: {
            name: '姓名'
          },
          sex: {
            name: '性别'
          },
          phone: {
            name: '手机'
          },
          wechat: {
            name: '微信',
            copy: true
          },
          email: {
            name: '邮箱'
          },
          school: {
            name: '学校'
          },
          identification: {
            name: '身份证号'
          },
          address: {
            name: '联系地址'
          },
          country: {
            name: '申请国家/地区'
          },
          schoolStartTime: {
            name: '下一学历入学时间'
          },
          direction: {
            name: '打算未来就读专业'
          }
        }
      },
      educationalMes: {
        name: '教育背景',
        content: {
          school: {
            name: '学校'
          },
          schoolStartTime: {
            name: '入学日期'
          },
          specialty: {
            name: '专业方向'
          },
          score: {
            name: '绩点成绩'
          }
        }
      },
      activityMes: {
        name: '活动信息',
        content: {
          activity: {
            name: '组织/活动'
          },
          startEndDate: {
            name: '起止时间'
          },
          position: {
            name: '职位'
          },
          duty: {
            name: '担任职责'
          }
        }
      },
      scienceMes: {
        name: '学术背景',
        content: {
          project: { name: '项目/题目' },
          startEndDate: {
            name: '起止时间'
          },
          achievement: {
            name: '成果'
          },
          content: {
            name: '内容'
          }
        }
      },
      language: {
        name: '外语能力',
        content: {
          four: {
            name: '四级'
          },
          six: {
            name: '六级'
          },
          toefl: {
            name: 'TOEFL'
          },
          ieltes: {
            name: 'IELTES'
          },
          gre: {
            name: 'GRE'
          },
          gmat: {
            name: 'GMAT'
          },
          other: {
            name: '其他'
          }
        }
      },
      computerMes: {
        name: '计算机能力',
        self: true
      }
    }
  },
  mounted () {
    if (this.$route.params.id) {
      axios.get(`/info/${this.$route.params.id}`).then(({ data }) => {
        this.data = {
          student: data.student,
          computerMes: data.student.computerMes,
          activityMes: data.student.activityMes,
          language: data.student.language,
          educationMes: data.student.educationMes,
          scienceMes: data.student.scienceMes,
          project: data.project
        }
        let temp = this.data.project.misc.split('&&^^')[0]
        this.data.project.misc = temp
        document.title = `${this.data.student.name}-${this.data.project.project_type}`
      })
    } else if (this.$route.query.role) {
      axios
        .get(`/${this.$route.query.role}/project/${this.$route.query.pid}/info`)
        .then(({ data }) => {
          this.data = {
            student: data.student,
            computerMes: data.student.computerMes,
            activityMes: data.student.activityMes,
            language: data.student.language,
            educationMes: data.student.educationMes,
            scienceMes: data.student.scienceMes,
            project: data.project
          }
          let temp = this.data.project.misc.split('&&^^')[0]
          this.data.project.misc = temp
          document.title = `${this.data.student.name}-${this.data.project.project_type}`
        })
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 14px;
  color: #656d78;
  margin: 0px;
  text-align: left;
}
#main {
  padding: 60px 30px 0px;
  max-width: 728px;
  margin: auto;
  left: 0;
  right: 0;
}
#projectInfo {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  max-width: 728px;
  margin: auto;
  left: 0;
  right: 0;
  position: absolute;
  padding: 40px 30px;
  .item {
    width: 0;
    flex: 0 0 339px;
    margin-bottom: 18px;
  }
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    margin: 0;
  }
}
</style>
