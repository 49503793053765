<template>
  <div class="listTest" ref="element">
    <div class="search flex">
      <div class="seaicon">
        <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
      </div>
      <el-input @input="search" v-model="stutitle" class="seasubfix"></el-input>
      <el-select
        v-model="role"
        popper-class="rolelist"
        class="changerole"
        @change="rolechange"
        v-if="!enterpise"
      >
        <el-option
          v-for="item in options"
          :key="item"
          :value="item"
        ></el-option>
      </el-select>
    </div>
    <div class="jstudent" v-if="loading">
      <i class="eliwise-loading"></i>加载中请稍后...
    </div>
    <list
      v-else
      ref="list"
      @next="getNext"
      class="jstudent"
      :data="finlist"
      :next="has_next"
      v-slot="{ item, index }"
    >
      <div
        @click="getstu1(index)"
        :class="{
          changeStyle: changeSelectStyle === index,
          unstarted: !item.started
        }"
        class="jstunum"
        :key="index"
      >
        <div class="studentname">
          <span>{{ item.id }}.{{ item.name }}</span>
          <img
            src="../../assets/icon/组合项目.png"
            class="zhimg"
            alt=""
            v-if="item.ispacked === true && item.started === true"
          />
          <img
            src="../../assets/icon/组合项目2.jpg"
            class="zhimg"
            alt=""
            v-if="item.ispacked === true && item.started === false"
          />
        </div>
        <div>
          <div class="colortag">
            <div class="abnormal" v-if="item.abnormal"></div>
            <div class="circle" v-else></div>
          </div>
        </div>
      </div>
    </list>
  </div>
</template>

<script>
import axios from '../axios'
import list from './list'
import { debounce } from 'lodash'
export default {
  name: 'studentList',
  components: {
    list
  },
  data () {
    return {
      page: 1,
      has_next: true,
      stutitle: '',
      s_name: '',
      c_name: '',
      a_name: '',
      pro_type: '',
      t_name: '',
      projectName: '',
      s_dir: '',
      isIm: false,
      finlist: [],
      changeSelectStyle: 0,
      type: '',
      loading: false,
      role: '学员'
    }
  },
  props: {
    url: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      default: []
    },
    enterpise: false
  },
  mounted () {
    this.getstudent()
  },
  methods: {
    day_filter (days) {
      this.resize()
      this.type = days.toString()
      this.getstudent()
    },
    async getNext () {
      if (this.role === '学员') {
        this.s_name = this.stutitle
      } else if (this.role === '导师') {
        this.t_name = this.stutitle
      } else if (this.role === '渠道') {
        this.c_name = this.stutitle
      } else if (this.role === '项目') {
        this.pro_type = this.stutitle
      } else if (this.role === '规划师') {
        this.a_name = this.stutitle
      } else if (this.role === '助教') {
        this.as_name = this.stutitle
      } else if (this.role === '方向') {
        this.projectName = this.stutitle
      } else if (this.role === '专业') {
        this.s_dir = this.stutitle
      } else {
        this.stutitle = ''
        this.isIm = true
      }
      await Promise.all([
        axios
          .get(this.url, {
            params: {
              s_name: this.s_name,
              c_name: this.c_name,
              t_name: this.t_name,
              a_name: this.a_name,
              pro_type: this.pro_type,
              as_name: this.as_name,
              projectName: this.projectName,
              s_dir: this.s_dir,
              page: this.page,
              important: this.isIm
            }
          })
          .then(response => {
            this.page++
            this.finlist.push(...response.data.students)
            this.has_next = response.data.has_next
          })
          .catch(error => {
            console.log(error)
          })
      ])
    },
    async getstudent () {
      if (this.role === '学员') {
        this.s_name = this.stutitle
      } else if (this.role === '导师') {
        this.t_name = this.stutitle
      } else if (this.role === '渠道') {
        this.c_name = this.stutitle
      } else if (this.role === '项目') {
        this.pro_type = this.stutitle
      } else if (this.role === '规划师') {
        this.a_name = this.stutitle
      } else if (this.role === '助教') {
        this.as_name = this.stutitle
      } else if (this.role === '方向') {
        this.projectName = this.stutitle
      } else if (this.role === '专业') {
        this.s_dir = this.stutitle
      } else {
        this.stutitle = ''
        this.isIm = true
      }
      this.loading = true
      await Promise.all([
        axios
          .get(this.url, {
            params: {
              s_name: this.s_name,
              c_name: this.c_name,
              t_name: this.t_name,
              a_name: this.a_name,
              pro_type: this.pro_type,
              as_name: this.as_name,
              projectName: this.projectName,
              s_dir: this.s_dir,
              page: this.page,
              important: this.isIm
            }
          })
          .then(response => {
            this.page++
            this.loading = false
            this.finlist = response.data.students
            this.has_next = response.data.has_next
          })
          .catch(error => {
            console.log(error)
          })
      ])
      if (this.finlist.length !== 0) {
        this.getfirst()
      }
    },
    getstu1 (index) {
      this.changeSelectStyle = index
      this.$emit('select', this.finlist[index].project_id)
    },
    getfirst () {
      this.changeSelectStyle = 0
      this.$emit('select', this.finlist[0].project_id)
    },
    getClass (days, started) {
      if (days > 60) {
        return 'day60'
      } else if (days > 30 && days <= 60) {
        return 'day30'
      } else if (days > 15 && days <= 30) {
        return 'day15'
      } else if (days > 7 && days <= 15) {
        return 'day7'
      } else if (days > 0 && days <= 7) {
        return 'day0'
      } else if (days === 0 && started === true) {
        return 'day0'
      } else if (days === 0 && started === false) {
        return 'dayfull'
      }
    },
    rolechange () {
      this.stutitle = ''
      this.s_name = ''
      this.t_name = ''
      this.c_name = ''
      this.as_name = ''
      this.a_name = ''
      this.pro_type = ''
      this.projectName = ''
      this.s_dir = ''
      this.page = 1
      if (this.role === '标星') {
        this.isIm = true
      } else {
        this.isIm = false
      }
      this.loading = true
      axios
        .get(this.url, {
          params: {
            s_name: this.s_name,
            c_name: this.c_name,
            t_name: this.t_name,
            a_name: this.a_name,
            pro_type: this.pro_type,
            as_name: this.as_name,
            projectName: this.projectName,
            s_dir: this.s_dir,
            page: this.page,
            important: this.isIm
          }
        })
        .then(response => {
          this.finlist = response.data.students
          this.has_next = response.data.has_next
          this.page++
          this.loading = false
          if (this.finlist.length !== 0) {
            this.getfirst()
          }
        })
    },
    search: debounce(function () {
      this.resize()
      this.getstudent()
    }, 500),
    resize () {
      this.$refs.list.$el.scrollTop = 0
      this.page = 1
      this.has_next = false
    },
    chooseColor1 () {
      this.resize()
      this.type = ''
      this.getstudent()
    },
    async chooseColor2 () {
      this.resize()
      this.type = null
      if (this.role === '学员') {
        this.s_name = this.stutitle
      } else if (this.role === '导师') {
        this.t_name = this.stutitle
      } else if (this.role === '渠道') {
        this.c_name = this.stutitle
      } else if (this.role === '项目') {
        this.pro_type = this.stutitle
      } else if (this.role === '规划师') {
        this.a_name = this.stutitle
      } else if (this.role === '助教') {
        this.as_name = this.stutitle
      } else if (this.role === '方向') {
        this.projectName = this.stutitle
      } else if (this.role === '专业') {
        this.s_dir = this.stutitle
      } else {
        this.stutitle = ''
        this.isIm = true
      }
      this.loading = true
      await Promise.all([
        axios
          .get(this.url, {
            params: {
              s_name: this.s_name,
              c_name: this.c_name,
              t_name: this.t_name,
              a_name: this.a_name,
              pro_type: this.pro_type,
              as_name: this.as_name,
              projectName: this.projectName,
              s_dir: this.s_dir,
              page: this.page,
              important: this.isIm,
              started: false
            }
          })
          .then(response => {
            this.page++
            this.finlist = response.data.students.filter(
              item => item.started === false
            )
            this.loading = false
            this.has_next = response.data.has_next
          })
          .catch(error => {
            console.log(error)
          })
      ])
      if (this.finlist.length !== 0) {
        this.getfirst()
      }
    }
  }
}
</script>
