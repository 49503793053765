<template>
  <el-dialog title="扫码次数统计" :visible="true" width="60%" :close-on-click-modal="false">
    <img src="../../assets/icon/搜索@3x.png" class="icon" />
    <el-input
      v-model="title"
      @keyup.enter.native="search"
      placeholder="选题关键词"
      @input="input_search()"
    >
    </el-input>
    <el-table
      class="base"
      :data="topics"
      @sort-change="sortchange"
      :default-sort="{ prop: 'query', order: 'descending' }"
      ref="topics"
      height="65vh"
    >
      <el-table-column
        prop="direction"
        label="方向"
        min-width="30"
        header-align="center"
        v-if="type === 'ipage_query'"
      >
      </el-table-column>
      <el-table-column
        prop="professor"
        label="教授"
        min-width="30"
        header-align="center"
        v-if="type === 'ipage_query'"
      >
      </el-table-column>
      <el-table-column prop="title" label="选题" min-width="60" header-align="center" v-else>
      </el-table-column>
      <el-table-column
        prop="weekly"
        label="近7天扫码次数"
        min-width="20"
        align="center"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
      >
      </el-table-column>
      <el-table-column
        prop="query"
        label="总扫码次数"
        min-width="20"
        align="center"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
      >
      </el-table-column>
      <div slot="append" style="text-align: center; font-size: 16px">
        <span v-if="has_next"> <i class="eliwise-loading"></i>加载中请稍后... </span>
        <i v-else>--没有数据了--</i>
      </div>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$parent.$parent.queryCount = false">返回</el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from '../axios'
export default {
  data () {
    return {
      topics: [],
      title: '',
      desc: true,
      sort: 'query',
      has_next: true,
      page: 1,
      loading: true
    }
  },
  props: {
    type: {
      require: true
    }
  },
  mounted () {
    this.search()
    this.$nextTick(() => {
      this.$refs.topics.bodyWrapper.addEventListener('scroll', () => {
        this.moreList()
      })
    })
  },
  methods: {
    input_search: _.debounce(function () {
      this.search()
    }, 500),
    sortchange (column) {
      this.sort = column.prop
      switch (column.order) {
        case 'ascending':
          this.desc = false
          break
        case 'descending':
          this.desc = true
          break
      }
      this.search()
    },
    search () {
      this.topics = []
      this.getList()
      this.page = 1
    },
    moreList () {
      let dom = this.$refs.topics.bodyWrapper
      let scrollTop = dom.scrollTop
      let windowHeight = dom.clientHeight
      let scrollHeight = dom.scrollHeight
      if (scrollTop + windowHeight >= scrollHeight - 2 && this.has_next && !this.loading) {
        this.loading = true
        this.page++
        this.$nextTick(() => {
          this.getList()
        })
      }
      if (scrollTop > 150) this.searchUnfold = false
      else this.searchUnfold = true
    },
    getList () {
      axios
        .get(`DBS/${this.type}`, {
          params: {
            title: this.title,
            desc: this.desc,
            sort: this.sort,
            page: this.page
          }
        })
        .then(res => {
          this.topics = this.topics.concat(res.data.topics)
          this.has_next = res.data.has_next
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-input__inner {
  background-color: #fff;
  padding-left: 30px;
}
.icon {
  width: 20px;
  position: absolute;
  z-index: 100;
  margin: 5px;
}
</style>
