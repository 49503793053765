<template>
  <div class="home">
    <div class="title">课程视频</div>
    <div class="wrapper flex">
      <div class="blockab">
        <div class="innerTitle abcTitle">项目类别</div>
        <div
          v-for="(item, index) in projects"
          :key="index"
          @click="typeSelect(item, index)"
          :class="index == indexs.a ? 'selected' : ''"
          class="innerblock"
        >
          {{ item.type }}
        </div>
      </div>
      <div class="blockc">
        <div class="innerTitle abcTitle">课程名称</div>
        <div
          v-for="(item, index) in selectedType"
          :key="index"
          @click="courseSelect(item, index)"
          :class="index == indexs.b ? 'selected' : ''"
          class="innerblock"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="blockc">
        <div class="innerTitle abcTitle">阶段</div>
        <div
          v-for="(item, index) in selectedCourse"
          :key="index"
          @click="stageSelect(item, index)"
          :class="index == indexs.c ? 'selected' : ''"
          class="innerblock"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="blocke">
        <div class="innerTitle">包含内容</div>
        <div class="innerTitle" v-if="selectedStage.videos.length > 0">
          视频
        </div>
        <div v-for="(item, index) in selectedStage.videos">
          {{ item }}
        </div>
        <div class="innerTitle" v-if="selectedStage.documentations.length">
          文件资料
        </div>
        <div v-for="(item, index) in selectedStage.documentations" :key="index">
          {{ item }}
        </div>
        <div class="divider"></div>
        <div
          style="font-size: 12px;color:#c0c0c0;line-height: 17px;margin-bottom: 6px"
        >
          选择链接有效期*
        </div>
        <el-select v-model="days" placeholder="请选择" class="elSelect">
          <el-option
            v-for="item in time"
            :key="item.label"
            :label="item.name"
            :value="item.label"
            size="medium"
            class="elOption"
          >
          </el-option>
        </el-select>
        <button type="button" class="button" @click="posturl()">
          复制链接
        </button>
        <div style="margin-top: 10px">{{ courseurl }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios'
export default {
  name: 'Home',
  data () {
    return {
      cjieduan: '',
      projects: [],
      selectedType: '',
      selectedCourse: '',
      selectedStage: '',
      jieduan: [],
      courses: [],
      time: [
        {
          label: '3',
          name: '3天'
        },
        {
          label: '7',
          name: '7天'
        },
        {
          label: '15',
          name: '15天'
        }
      ],
      radio: '',
      radio1: 1,
      courseurl: '',
      coursename: '',
      cjieduan1: '',
      indexs: {
        a: 0,
        b: 0,
        c: 0,
        d: 0
      },
      days: '7'
    }
  },
  created () {
    document.title = '先导课发放'
    axios.get(`/course/courses`).then(res => {
      this.projects = res.data.projects
      this.typeSelect(this.projects[0], 0)
    })
  },
  methods: {
    typeSelect (item, index) {
      this.selectedType = item.courses
      if (item.courses[0]) {
        this.courseSelect(item.courses[0], 0)
        this.stageSelect(item.courses[0].stages[0], 0)
      } else {
        this.selectedCourse = ''
      }
      this.indexs.a = index
    },
    courseSelect (item, index) {
      this.selectedCourse = item.stages
      this.stageSelect(item.stages[0], 0)
      this.indexs.b = index
    },
    stageSelect (item, index) {
      this.selectedStage = item
      this.indexs.c = index
      this.cjieduan1 = item.id
    },
    posturl () {
      if (this.cjieduan1 === '') {
        console.log(this.cjieduan1)
        this.$message({
          message: '请选择必填项',
          type: 'warning'
        })
      } else {
        axios
          .post(`/course/get_url/`, {
            deadline: this.days,
            stage: this.cjieduan1
          })
          .then(res => {
            this.courseurl = res.data.url
            this.coursename = res.data.course
            this.$copyText(this.courseurl).then(
              e => {
                this.$message({
                  message: '复制成功',
                  type: 'success'
                })
              },
              e => {
                this.$message({
                  message: '复制失败',
                  type: 'error'
                })
              }
            )
          })
      }
    }
  }
}
</script>
<style scoped>
.wrapper {
  /*margin: 20px 3vw 30px;*/
  padding: 20px 3vw 30px;
  height: calc(100% - 92px);
}
.blockab,
.blockc,
.blockd,
.blocke {
  height: 100%;
  background-color: #fff;
  margin-right: 2vw;
  border-radius: 15px;
  line-height: 40px;
  padding: 0 1.4vw;
  overflow: scroll;
}
.blocke {
  margin-right: 0;
}
.blocke .innerTitle:first-child {
  margin-top: 15px;
}

.blockab {
  width: 17vw;
}
.innerblock {
  width: calc(100% - 26px);
  /*height: 40px;*/
  border-radius: 8px;
  /*background-color: #2c3e50;*/
  margin: 15px 0 0;
  cursor: pointer;
  overflow: hidden;
  padding: 12px 13px;
  line-height: 22px;
}

.blockc {
  width: 24vw;
}

.abcTitle {
  /*width: 85%;*/
  /*background-color: #2c3e50;*/
  margin: 15px 0 0;
  padding-left: 13px;
}

.blockd {
  width: 200px;
}
.blocke {
  width: 30vw;
}

.blocke div {
  text-align: left;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
  padding: 0 13px;
}
.blocke .divider {
  box-sizing: border-box;
  border-bottom: #dcdfe6 1px solid;
  width: calc(100% - 26px);
  margin-left: 13px;
}
.blocke .innerTitle,
.innerTitle {
  color: #c0c0c0;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 10px;
}

.elSelect {
  width: calc(100% - 26px);
}
/deep/ .elSelect .el-input__inner {
  border-radius: 5px;
}

.button {
  margin-left: 13px;
  width: calc(100% - 26px);
  border-radius: 7px;
  border: #a0d46a 2px solid;
  /*box-sizing: content-box;*/
  font-size: 24px;
  line-height: 54px;
  height: 54px;
  color: #a0d46a;
  background-color: #fff;
}
.button:hover {
  background-color: #a0d46a;
  color: #fff;
}
.button:active {
  background-color: #8fbb60;
}
.home {
  background-color: #f5f7fa;
  width: 100%;
  height: 100vh;
  position: absolute;
  color: #434a54;
  text-align: left;
  overflow: hidden;
}

.title {
  text-align: left;
  margin-left: 3vw;
  font-size: 30px;
}
.selected {
  background-color: #f5f7fa;
  color: #a1d46a;
}
</style>
