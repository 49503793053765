<template>
  <div class="role">
    <img
      :src="require(`../../assets/roles/${role}.png`)"
      style="border: 2px #c0c0c0 solid;padding: 2px;border-radius: 12px;"
      @click="more = !more"
    />
    <!--    这是三个点的图标-->
    <!--    <img-->
    <!--      :src="require(`../../assets/roles/more.png`)"-->
    <!--      v-if="!more"-->
    <!--      @click="more = true"-->
    <!--    />-->
    <div v-if="more" class="roles">
      <!--      用一个长到离谱的padding扩大关闭键到范围-->
      <div>
        <el-tooltip
          v-for="item in roles"
          :key="item"
          placement="right"
          effect="light"
          :content="item"
        >
          <img
            :src="require(`../../assets/roles/${item}.png`)"
            @click="changerole(item)"
        /></el-tooltip>
      </div>
      <p @click="passwordVisible = true" class="word">修改密码</p>
      <i
        class="el-icon-arrow-up"
        style="display:block;padding-bottom: 1000px;width: 100%"
        @click="more = false"
      ></i>
    </div>
    <el-dialog
      :visible.sync="passwordVisible"
      width="1038px"
      title="修改密码"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-form :model="ruleform" :rules="rules" ref="ruleform">
        <div class="permod">
          <el-row class="spacebetween">
            <el-col :span="12" class="inputleft">
              <el-form-item prop="ypassword">
                <div>原密码*</div>
                <el-input
                  placeholder="原密码"
                  maxlength="64"
                  type="password"
                  v-model="ruleform.ypassword"
                  class="input pswinput"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputright">
              <el-form-item prop="npassword" label="">
                <div>新密码*</div>
                <el-input
                  placeholder="新密码"
                  maxlength="64"
                  type="password"
                  v-model="ruleform.npassword"
                  class="input pswinput"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputleft">
              <el-form-item prop="rpassword">
                <div>重复密码*</div>
                <el-input
                  placeholder="重复密码"
                  maxlength="64"
                  type="password"
                  v-model="ruleform.rpassword"
                  class="input pswinput"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button @click="pptrue('ruleform')" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../axios'
export default {
  name: 'changerole',
  data () {
    return {
      rules: {
        ypassword: [
          { required: true, message: '请填写原密码', trigger: 'blur' }
        ],
        npassword: [
          { required: true, message: '请填写新密码', trigger: 'blur' }
        ],
        rpassword: [
          { required: true, message: '请再次填写新密码', trigger: 'blur' }
        ]
      },
      ruleform: {
        ypassword: '',
        npassword: '',
        rpassword: ''
      },
      passwordVisible: false,
      option: [],
      role: '',
      roles: [],
      more: false
    }
  },
  created () {
    this.roles = this.$store.state.roles
    this.role = this.$store.state.role
  },
  methods: {
    cancel () {
      this.passwordVisible = false
    },
    pptrue (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.ruleform.npassword !== this.ruleform.rpassword) {
            this.$message({
              showClose: true,
              message: '密码不一致',
              type: 'error'
            })
          } else {
            axios
              .post(`/${this.$store.state.roleApi}/change_password`, {
                old_password: this.ruleform.ypassword,
                new_password: this.ruleform.npassword,
                confirm: this.ruleform.rpassword
              })
              .then(response => {
                if (response.status === 200) {
                  this.$message({
                    showClose: true,
                    message: '修改成功',
                    type: 'success'
                  })
                  setTimeout(() => {
                    localStorage.removeItem('Authorization')
                    this.$router.replace('/login')
                  }, 1000)
                }
              })
              .catch(error => {
                this.$message({
                  showClose: true,
                  message: `修改失败,${error.response.data.error_message}`,
                  type: 'error'
                })
              })
          }
        } else {
          return false
        }
      })
    },
    changerole (e) {
      if (e === this.$store.state.role) {
        this.more = !this.more
        return
      }
      if (e === '渠道经理') {
        this.$router.push('/D/Dschedule')
      } else if (e === '客户经理') {
        this.$router.push('/K/Kschedule')
      } else if (e === '规划师') {
        this.$router.push('/A/Aschedule')
      } else if (e === '市场总监') {
        this.$router.push('/MD/MDschedule')
      } else if (e === '教学总监') {
        this.$router.push('/TD/TDschedule')
      } else if (e === '导师') {
        this.$router.push('/T/tschedule')
      } else if (e === '选会') {
        this.$router.push('/C')
      } else if (e === '助教总监') {
        this.$router.push('/AD/ADschedule')
      } else if (e === '助教') {
        this.$router.push('/AS/ASschedule')
      } else if (e === '管理员') {
        this.$router.push('/M/Maccount')
      } else if (e === '会务组') {
        this.$router.push('/MS/MSinterview')
      } else if (e === '教务') {
        this.$router.push('/J/Jinterview')
      } else if (e === '财务') {
        this.$router.push('/F')
      } else if (e === '选题库') {
        this.$router.push('/Topic/TopicR')
      } else if (e === '选题库管理员') {
        this.$router.push('/TopicPlus/Research')
      } else if (e === '渠道') {
        this.$router.push('/E/Eschedule')
      } else if (e === '渠道选题库' && this.role !== '渠道选题库') {
        this.$router.push('/Enterpise')
      }
    }
  },
  watch: {
    '$store.state.roles' () {
      this.roles = this.$store.state.roles
    }
  }
}
</script>
<style lang="scss" scoped>
.roles {
  position: absolute;
  top: 88px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 60px;
  border-top: 2px #c0c0c0 dashed;
  padding-top: 5px;
  align-items: center;
  background: linear-gradient(#fff, #fff, rgba(#fff, 0));
  z-index: 5;
}
.role {
  min-height: 52px;
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
    margin-top: 5px;
    width: 40px;
  }
  .roles {
    img:last-child {
      padding-bottom: 5px;
      border-bottom: 2px #c0c0c0 dashed;
    }
    img {
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
    i {
      font-size: 30px;
    }
  }
}
.word {
  font-size: 14px;
  color: #a1d46a;
  margin: 10px auto;
}
</style>
