<template>
  <div style="text-align: left; position: relative">
    <el-tooltip
      effect="light"
      :content="`项目完成度：${percent}%`"
      placement="top"
    >
      <el-progress
        type="circle"
        :percentage="percent"
        :width="20"
        :stroke-width="4"
        :show-text="false"
        color="#ABEC88"
      ></el-progress>
    </el-tooltip>
    <el-button
      @click="show = false"
      v-if="show === true && active < stages.length - 1"
      class="close"
      type="primary"
    >
      折叠
    </el-button>
    <div class="open" v-if="show === false && active < stages.length - 1">
      <el-button @click="show = true" type="primary"> 展开 </el-button>
      <span class="nextS">下一阶段：{{ stages[active + 1].stage }}</span>
      <div class="circle"></div>
      <div class="down"></div>
    </div>
    <div style="display: flex; flex-direction: column-reverse">
      <el-row class="interview" v-if="!enterpise">
        <img src="../../assets/icon/面试.png" width="20" />
        <span v-if="!interview" class="viewdate" :span="2"> 暂无面试反馈 </span>
        <span v-else class="viewdate">{{ interview.date }}</span>
        <span v-if="interview">
          {{ interview.situation }}
        </span>
      </el-row>
      <div
        v-for="(item, index) in stages"
        :key="item.stage"
        class="stage"
        v-if="show || active >= index"
      >
        <div class="circle activeStage" v-if="active === index">
          {{ index + 1 }}
        </div>
        <div
          class="circle"
          :class="active >= index ? 'active' : ''"
          v-else
        ></div>
        <div
          class="up"
          v-if="index !== stages.length - 1"
          :class="active > index ? 'active' : ''"
        ></div>
        <div
          class="down"
          v-if="index !== 0"
          :class="active >= index ? 'active' : ''"
        ></div>
        <div class="content" :style="active >= index ? 'background:#fff' : ''">
          <div
            style="
              display: flex;
              justify-content: space-between;
              padding-right: 2px;
            "
          >
            <div>{{ item.stage }}</div>
            <div v-if="stageCompleteRole">
              <el-button
                type="primary"
                v-if="index === active"
                style="
                width: 80px;
                height: 30px;
                padding: 0 16px;
                line-height: 30px;
              "
                @click="completeStage(item)"
              >
                阶段完成
              </el-button>
              <div
                v-else-if="index < active"
                style="color: #c0c0c0; font-size: 12px; line-height: 22.4px"
              >
                阶段确认：{{ project.stageFinisher[index] }}
              </div>
            </div>
          </div>
          <div v-if="active >= index && tableData[index]">
            <chart
              @listentoChart="listentoChart"
              :stage.sync="tableData[index]"
              :editable="editable"
              :enterpise="enterpise"
              v-if="tableData[index].tasks.length > 0"
            >
            </chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chart from './chart'
import axios from 'axios'
import stageType from './stageType.json'
import stagesList from './stagesList.json'
import { MessageBox } from 'element-ui'
export default {
  components: {
    chart
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    percent () {
      if (this.active < this.stages.length) {
        return this.stages[this.active].percent
      } else {
        return 100
      }
    },
    stages () {
      return stagesList[stageType[this.project.project_type] || '基础']
    },
    active () {
      let stage = this.project.stage || 0
      if (this.stageComplete) {
        stage += 2
      }
      return stage
    },
    stageComplete () {
      return this.project.stageComplete || false
    }
  },
  props: {
    tableData: Array,
    editable: {
      default: false
    },
    project: Object,
    interview: Object,
    stageCompleteRole: {
      default: false
    },
    enterpise: {
      default: false
    }
  },
  methods: {
    listentoChart (data) {
      this.$emit('listentoChart', data)
    },
    completeStage (item) {
      let text = `${this.project.student}-${this.project.project_type}确认完成${item.stage}？`
      MessageBox.confirm(text, '阶段确认').then(() => {
        axios
          .post(
            `${this.$store.state.roleApi}/project/${this.project.id}/stageComplete`,
          {
            complete: this.active >= this.stages.length - 2
          }
          )
          .then(res => {
            if (res.status === 200) {
              this.$message({
                showClose: true,
                message: '阶段完成',
                type: 'success'
              })
              this.$emit('listentoChart', { title: 'refresh' })
            }
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.stage {
  position: relative;
  color: #656d78;
  text-align: center;
  .content {
    margin-top: 15px;
    border-radius: 8px;
    padding: 20px 30px;
    background: #e9ecf1;
    margin-left: 25px;
    p {
      text-align: left;
      margin: 0;
      font-size: 16px;
    }
  }
}
.more-stage {
  margin-top: 10px;
  border-radius: 8px;
  overflow: hidden;
  padding: 20px 30px;
  background: #fff;
}
.up {
  position: absolute;
  top: 0;
  height: 50%;
  border-left: 2px solid #c0c0c0;
  left: 10px;
}
.down {
  position: absolute;
  bottom: 0;
  height: 50%;
  border-left: 2px solid #c0c0c0;
  left: 10px;
}
.circle {
  width: 12px;
  height: 12px;
  background: #c0c0c0;
  border-radius: 12px;
  position: absolute;
  z-index: 10;
  left: 5px;
  top: 50%;
}

.active {
  &.down {
    border-left: 2px solid #a1d46a;
  }
  &.up {
    border-left: 2px solid #a1d46a;
  }
  &.circle {
    background: #a1d46a;
  }
}
.activeStage {
  width: 20px;
  height: 20px;
  background: #a1d46a;
  left: 0px;
  top: calc(50% - 5px);
  color: #fff;
  line-height: 20px;
}
.el-button {
  min-width: 36px;
  padding: 0;
  height: 24px;
  font-size: 12px;
}
.open {
  position: relative;
  .el-button {
    margin-left: 30px;
    position: relative;
    top: -28px;
    width: 50px;
  }
  .circle {
    top: 5px;
  }
  .nextS {
    position: relative;
    left: -36px;
    top: 5px;
    font-size: 12px;
    color: #656d78;
  }
}
.close {
  position: absolute;
  left: 30px;
  width: 50px;
  margin-top: 8px;
}
.el-progress {
  margin-top: 10px;
}
.interview {
  margin-left: 25px;
}
</style>
