<template>
  <div>
    <div class="pre" @click="turnUp">
      <div class="pretext">{{ title }}</div>
      <div :class="{ turn: turnValue }" class="preimg el-icon-arrow-down"></div>
    </div>
    <div v-if="turnValue">
      <div
        @click="setSelectId(item)"
        :class="{ changeStyle: selectId === item.project_id }"
        class="jstunum"
        v-for="(item, index) in list"
        :key="index"
      >
        <div :class="{ bold: item.important }" class="studentname">
          <span>{{ item.id }}.{{ item.name }}</span>
          <img
            src="../../assets/icon/组合项目.png"
            class="zhimg"
            alt=""
            v-if="item.ispacked === true"
          />
        </div>
        <div v-if="item.teacher.id">
          <div class="colortag">
            <div class="circle"></div>
          </div>
        </div>
      </div>
      <div slot="empty" class="empty" v-if="loading">
        <i class="eliwise-loading"></i> <span>加载中请稍后...</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios'
export default {
  name: 'projectList',
  data () {
    return {
      turnValue: true,
      list: [],
      loading: true,
      project_data: {},
      studentInfo: {},
      interview: {},
      teacher: {},
      activeProject: {},
      title: ''
    }
  },
  props: {
    type: {
      type: String,
      required: true
    },
    selectId: {
      type: Number,
      required: true
    }
  },
  created () {
    this.getList()
  },
  beforeMount () {
    switch (this.type) {
      case 'not_interviewed':
        this.title = '未面试'
        break
      case 'interviewed':
        this.title = '已面试'
        break
      case 'part':
        this.title = '未添加项目'
        break
    }
  },
  methods: {
    turnUp () {
      this.turnValue = !this.turnValue
    },
    setSelectId (item) {
      this.$emit('update:selectId', item.project_id)
    },
    getList (s_name = '', t_name = '', pro_type = '') {
      axios
        .get(`/${this.$store.state.roleApi}/students/${this.type}`, {
          params: {
            s_name: s_name,
            t_name: t_name,
            pro_type: pro_type
          }
        })
        .then(response => {
          this.list = response.data.students
          this.loading = false
          this.$parent.$parent[this.type] = true
          if (this.selectId === 0 && this.list.length > 0) {
            this.$emit('update:selectId', this.list[0].project_id)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
<style scoped>
.empty {
  color: #909399;
}
</style>
