<template>
  <div class="aside">
    <el-menu router @select="handleSelect">
      <items v-for="(item, index) in list" :key="index" :type="item"></items>
    </el-menu>
    <div>
      <changeRoleET></changeRoleET>
      <div class="back" style="margin: 20px 0">
        <img
          @click="logout"
          src="../../assets/icon/退出.png"
          height="20"
          width="20"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'
import changeRoleET from '../pub/changeRoleEnterprise'
export default {
  name: 'Enav',
  data () {
    return {
      topics: {},
      list: []
    }
  },
  components: {
    items: {
      render (h) {
        switch (this.type) {
          case 'A':
            return (
              <el-tooltip
                placement="right"
                effect="light"
                popper-class="atooltip"
              >
                <div slot="content"> {this.$store.state.topics.thesis}</div>
                <el-menu-item
                  class={
                    this.$route.path === '/Enterpise/EnterpiseT'
                      ? 'is-active'
                      : ''
                  }
                  index="/Enterpise/EnterpiseT"
                >
                  <img
                    class="iconmenu"
                    src={require('../../assets/icon/论文选题icon.png')}
                  />
                </el-menu-item>
              </el-tooltip>
            )
          case 'B':
            return (
              <el-tooltip
                placement="right"
                effect="light"
                popper-class="atooltip"
              >
                <div slot="content"> {this.$store.state.topics.research}</div>
                <el-menu-item
                  class={
                    this.$route.path === '/Enterpise/EnterpiseR'
                      ? 'is-active'
                      : ''
                  }
                  index="/Enterpise/EnterpiseR"
                >
                  <img
                    class="iconmenu"
                    src={require('../../assets/icon/科研选题icon.png')}
                  />
                </el-menu-item>
              </el-tooltip>
            )
          case 'C':
            return (
              <el-tooltip
                placement="right"
                effect="light"
                popper-class="atooltip"
              >
                <div slot="content"> {this.$store.state.topics.rt}</div>
                <el-menu-item
                  class={
                    this.$route.path === '/Enterpise/EnterpiseRT'
                      ? 'is-active'
                      : ''
                  }
                  index="/Enterpise/EnterpiseRT"
                >
                  <img
                    class="iconmenu"
                    src={require('../../assets/icon/科研论文icon.png')}
                  />
                </el-menu-item>
              </el-tooltip>
            )
          case 'D':
            return (
              <el-tooltip
                placement="right"
                effect="light"
                popper-class="atooltip"
              >
                <div slot="content"> {this.$store.state.topics.academic}</div>
                <el-menu-item
                  class={
                    this.$route.path === '/Enterpise/EnterpiseA'
                      ? 'is-active'
                      : ''
                  }
                  index="/Enterpise/EnterpiseA"
                >
                  <img
                    class="iconmenu"
                    src={require('../../assets/icon/学术指导icon.png')}
                  />
                </el-menu-item>
              </el-tooltip>
            )
          case 'E':
            return (
              <el-tooltip
                placement="right"
                effect="light"
                popper-class="atooltip"
              >
                <div slot="content"> {this.$store.state.topics.epq}</div>
                <el-menu-item
                  class={
                    this.$route.path === '/Enterpise/EnterpiseEpq'
                      ? 'is-active'
                      : ''
                  }
                  index="/Enterpise/EnterpiseEpq"
                >
                  <img
                    class="iconmenu"
                    src={require('../../assets/icon/epqicon.png')}
                  />
                </el-menu-item>
              </el-tooltip>
            )
          case 'F':
            return (
              <el-tooltip
                placement="right"
                effect="light"
                popper-class="atooltip"
              >
                <div slot="content"> {this.$store.state.topics.iap}</div>
                <el-menu-item
                  class={
                    this.$route.path === '/Enterpise/EnterpiseIap'
                      ? 'is-active'
                      : ''
                  }
                  index="/Enterpise/EnterpiseIap"
                >
                  <img
                    class="iconmenu"
                    src={require('../../assets/icon/proicon.png')}
                  />
                </el-menu-item>
              </el-tooltip>
            )
          case 'G':
            return (
              <el-tooltip
                placement="right"
                effect="light"
                popper-class="atooltip"
              >
                <div slot="content"> {this.$store.state.topics.iap2}</div>
                <el-menu-item
                  class={
                    this.$route.path === '/Enterpise/EnterpiseIap2'
                      ? 'is-active'
                      : ''
                  }
                  index="/Enterpise/EnterpiseIap2"
                >
                  <img
                    class="iconmenu"
                    src={require('../../assets/icon/IAP2icon.png')}
                  />
                </el-menu-item>
              </el-tooltip>
            )
          case 'H':
            return (
              <el-tooltip
                placement="right"
                effect="light"
                popper-class="atooltip"
              >
                <div slot="content"> {this.$store.state.topics.lite}</div>
                <el-menu-item
                  class={
                    this.$route.path === '/Enterpise/EnterpiseLite'
                      ? 'is-active'
                      : ''
                  }
                  index="/Enterpise/EnterpiseLite"
                >
                  <img
                    class="iconmenu"
                    src={require('../../assets/icon/Liteicon.png')}
                  />
                </el-menu-item>
              </el-tooltip>
            )
          case 'I':
            return (
              <el-tooltip
                placement="right"
                effect="light"
                popper-class="atooltip"
              >
                <div slot="content"> {this.$store.state.topics.standard}</div>
                <el-menu-item
                  class={
                    this.$route.path === '/Enterpise/EnterpiseStandard'
                      ? 'is-active'
                      : ''
                  }
                  index="/Enterpise/EnterpiseStandard"
                >
                  <img
                    class="iconmenu"
                    src={require('../../assets/icon/Standardicon.png')}
                  />
                </el-menu-item>
              </el-tooltip>
            )
          case 'J':
            return (
              <el-tooltip
                placement="right"
                effect="light"
                popper-class="atooltip"
              >
                <div slot="content"> {this.$store.state.topics.xdf}</div>
                <el-menu-item
                  class={
                    this.$route.path === '/Enterpise/EnterpiseXDF'
                      ? 'is-active'
                      : ''
                  }
                  index="/Enterpise/EnterpiseXDF"
                >
                  <img
                    class="iconmenu"
                    src={require('../../assets/icon/XDFicon.png')}
                  />
                </el-menu-item>
              </el-tooltip>
            )
          case 'K':
            return (
              <el-tooltip
                placement="right"
                effect="light"
                popper-class="atooltip"
              >
                <div slot="content"> {this.$store.state.topics.proHigh}</div>
                <el-menu-item
                  class={
                    this.$route.path === '/Enterpise/EnterpiseIapHigh'
                      ? 'is-active'
                      : ''
                  }
                  index="/Enterpise/EnterpiseIapHigh"
                >
                  <img
                    class="iconmenu"
                    src={require('../../assets/icon/proicon.png')}
                  />
                </el-menu-item>
              </el-tooltip>
            )
          case 'L':
            return (
              <el-tooltip
                placement="right"
                effect="light"
                popper-class="atooltip"
              >
                <div slot="content"> {this.$store.state.topics.xt}</div>
                <el-menu-item
                  class={
                    this.$route.path === '/Enterpise/EnterpiseXT'
                      ? 'is-active'
                      : ''
                  }
                  index="/Enterpise/EnterpiseXT"
                >
                  <img
                    class="iconmenu"
                    src={require('../../assets/icon/xticon.png')}
                  />
                </el-menu-item>
              </el-tooltip>
            )
          case 'M':
            return (
              <el-tooltip
                placement="right"
                effect="light"
                popper-class="atooltip"
              >
                <div slot="content"> {this.$store.state.topics.byr}</div>
                <el-menu-item
                  class={
                    this.$route.path === '/Enterpise/EnterpiseBYR'
                      ? 'is-active'
                      : ''
                  }
                  index="/Enterpise/EnterpiseBYR"
                >
                  <img
                    class="iconmenu"
                    src={require('../../assets/icon/byr.png')}
                  />
                </el-menu-item>
              </el-tooltip>
            )
          case 'N':
            return (
              <el-tooltip
                placement="right"
                effect="light"
                popper-class="atooltip"
              >
                <div slot="content"> {this.$store.state.topics.ewa}</div>
                <el-menu-item
                  class={
                    this.$route.path === '/Enterpise/EnterpiseEWA'
                      ? 'is-active'
                      : ''
                  }
                  index="/Enterpise/EnterpiseEWA"
                >
                  <img
                    class="iconmenu"
                    src={require('../../assets/icon/ewa.png')}
                  />
                </el-menu-item>
              </el-tooltip>
            )
        }
      },
      props: {
        type: {
          type: String,
          required: true
        }
      }
    },
    changeRoleET
  },
  methods: {
    handleSelect (key, keyPath) {
      this.$router.replace({ path: `${keyPath}` })
    },
    logout () {
      MessageBox.confirm('是否注销？')
        .then(() => {
          axios.delete('/logout/').then(() => {
            localStorage.removeItem('Authorization')
            this.$router.replace('/login')
          })
        })
        .catch(() => {})
    }
  },
  created () {
    this.topics = this.$store.state.topics
    this.list = this.topics.sort.split('')
  },
  mounted () {
    if (this.list.length <= 0) {
      MessageBox.alert('未登录，请重新登录')
      localStorage.removeItem('Authorization')
      this.$router.push('/login')
    }
  }
}
</script>
<style lang="scss" scoped>
.iconmenu {
  height: 18px !important;
  width: auto !important;
}
.el-menu-item.is-active,
.el-menu-item:hover {
  .iconmenu {
    height: 20px !important;
    width: auto !important;
  }
}
.aimg {
  width: 18px !important;
  height: auto !important;
}
.el-menu-item.is-active,
.el-menu-item:hover {
  .aimg {
    width: 20px !important;
    height: auto !important;
  }
}
/deep/.el-menu {
  height: 85% !important;
  justify-content: start !important;
}
</style>
