<template>
  <el-main class="main">
    <div>
      <div class="jtitle">
        <h2 class="jtext">权限管理</h2>
      </div>
      <div class="table">
        <div class="search flex">
          <div class="seaicon">
            <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
          </div>
          <el-input
            class="seasubfix"
            v-model="accmessage"
            @input="search"
          ></el-input>
        </div>
        <div>
          <el-table :data="tableData" :height="tableHeight" ref="table">
            <el-table-column
              key="1"
              prop="name"
              label="姓名"
              align="center"
              min-width="10%"
            />
            <el-table-column
              key="2"
              label="市场总监"
              align="center"
              min-width="10%"
            >
              <template slot-scope="scope">
                <img
                  src="../../assets/icon/对勾.png"
                  alt=""
                  v-if="scope.row.市场总监 && scope.row.isedit"
                  class="duigou"
                />
                <el-checkbox
                  v-model="scope.row.市场总监"
                  v-show="!scope.row.isedit"
                  v-else
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              key="3"
              label="渠道经理"
              align="center"
              min-width="10%"
            >
              <template slot-scope="scope">
                <img
                  src="../../assets/icon/对勾.png"
                  alt=""
                  v-if="scope.row.渠道经理 && scope.row.isedit"
                  class="duigou"
                />
                <el-checkbox
                  v-model="scope.row.渠道经理"
                  v-show="!scope.row.isedit"
                  v-else
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              key="4"
              label="客户经理"
              align="center"
              min-width="10%"
            >
              <template slot-scope="scope">
                <img
                  src="../../assets/icon/对勾.png"
                  alt=""
                  v-if="scope.row.客户经理 && scope.row.isedit"
                  class="duigou"
                />
                <el-checkbox
                  v-model="scope.row.客户经理"
                  v-show="!scope.row.isedit"
                  v-else
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              key="5"
              label="规划师"
              align="center"
              min-width="10%"
            >
              <template slot-scope="scope">
                <img
                  src="../../assets/icon/对勾.png"
                  alt=""
                  v-if="scope.row.规划师 && scope.row.isedit"
                  class="duigou"
                />
                <el-checkbox
                  v-model="scope.row.规划师"
                  v-show="!scope.row.isedit"
                  v-else
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              key="6"
              label="教学总监"
              align="center"
              min-width="10%"
            >
              <template slot-scope="scope">
                <img
                  src="../../assets/icon/对勾.png"
                  alt=""
                  v-if="scope.row.教学总监 && scope.row.isedit"
                  class="duigou"
                />
                <el-checkbox
                  v-model="scope.row.教学总监"
                  v-show="!scope.row.isedit"
                  v-else
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              key="7"
              label="教务"
              align="center"
              min-width="10%"
            >
              <template slot-scope="scope">
                <img
                  src="../../assets/icon/对勾.png"
                  alt=""
                  v-if="scope.row.教务 && scope.row.isedit"
                  class="duigou"
                />
                <el-checkbox
                  v-model="scope.row.教务"
                  v-show="!scope.row.isedit"
                  v-else
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              key="8"
              label="助教总监"
              align="center"
              min-width="10%"
            >
              <template slot-scope="scope">
                <img
                  src="../../assets/icon/对勾.png"
                  alt=""
                  v-if="scope.row.助教总监 && scope.row.isedit"
                  class="duigou"
                />
                <el-checkbox
                  v-model="scope.row.助教总监"
                  v-show="!scope.row.isedit"
                  v-else
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              key="8。"
              label="助教"
              align="center"
              min-width="10%"
            >
              <template slot-scope="scope">
                <img
                  src="../../assets/icon/对勾.png"
                  alt=""
                  v-if="scope.row.助教 && scope.row.isedit"
                  class="duigou"
                />
                <el-checkbox
                  v-model="scope.row.助教"
                  v-show="!scope.row.isedit"
                  v-else
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              key="9"
              label="会务组"
              align="center"
              min-width="10%"
            >
              <template slot-scope="scope">
                <img
                  src="../../assets/icon/对勾.png"
                  alt=""
                  v-if="scope.row.会务组 && scope.row.isedit"
                  class="duigou"
                />
                <el-checkbox
                  v-model="scope.row.会务组"
                  v-show="!scope.row.isedit"
                  v-else
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              key="10"
              label="选题库"
              align="center"
              min-width="10%"
            >
              <template slot-scope="scope">
                <img
                  src="../../assets/icon/对勾.png"
                  alt=""
                  v-if="scope.row.选题库 && scope.row.isedit"
                  class="duigou"
                />
                <el-checkbox
                  v-model="scope.row.选题库"
                  v-show="!scope.row.isedit"
                  v-else
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              key="11"
              prop="删除"
              align="center"
              min-width="10%"
            >
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  @click="changeRole(scope.$index, scope.row)"
                  v-if="scope.row.isedit"
                >
                  编辑
                </el-button>
                <el-button
                  type="primary"
                  @click.native.prevent="postRole(scope.index, scope.row)"
                  v-else
                >
                  保存
                </el-button>
              </template>
            </el-table-column>
            <div slot="append" style="text-align: center; font-size: 16px">
              <span v-if="has_next">
                <i class="eliwise-loading"></i>加载中请稍后...
              </span>
              <i v-else>--没有数据了--</i>
            </div>
          </el-table>
        </div>
      </div>
    </div>
  </el-main>
</template>

<script>
import axios from '../axios'
import Vue from 'vue'
export default {
  name: 'Mjurisdict',
  data () {
    return {
      has_next: true,
      check: true,
      accmessage: '',
      tableData: [],
      tableHeight: window.innerHeight - 150,
      msg: {},
      checkrole: [],
      showRole: false,
      bianji: true,
      checked: true,
      isdisabled: true,
      pid: 0,
      roles: [],
      page: 1,
      isedit: true
    }
  },
  mounted () {
    this.getJy()
    this.$refs.table.bodyWrapper.addEventListener('scroll', this.a)
  },
  methods: {
    a () {
      let dom = this.$refs.table.bodyWrapper
      let scrollTop = dom.scrollTop
      let windowHeight = dom.clientHeight
      let scrollHeight = dom.scrollHeight
      if (scrollTop + windowHeight >= scrollHeight && this.has_next) {
        this.getJy()
      }
    },
    search () {
      this.tableData = []
      this.has_next = true
      this.page = 1
      this.getJy()
    },
    getJy () {
      if (this.has_next === true) {
        axios
          .get(`/admin/sponser/group`, {
            params: {
              name: this.accmessage,
              page: this.page
            }
          })
          .then(response => {
            this.tableData.push(...response.data.supports)
            this.page++
            this.has_next = response.data.has_next
            this.tableData.forEach(function (value, index, array) {
              Vue.set(array[index], 'isedit', true)
            })
          })
      }
    },
    changeRole (index, row) {
      row.isedit = false
    },
    postRole (index, row) {
      console.log(index, row.id)
      let roles = [
        '管理员',
        '市场总监',
        '渠道经理',
        '客户经理',
        '规划师',
        '教学总监',
        '助教总监',
        '教务',
        '助教',
        '会务组',
        '导师',
        '学生',
        '选题库',
        '选题库管理员',
        '渠道',
        '选会'
      ]
      let req = []
      roles.forEach(role => {
        if (row[role]) {
          req.push(role)
        }
      })
      axios.put(`/admin/sponser/${row.id}/group`, {
        roles: req
      })
      row.isedit = true
    }
  }
}
</script>
<style lang="scss" scoped>
.duigou {
  width: 22px;
  height: auto;
}
/deep/.el-checkbox__inner::after {
  transition: 0ms;
  border: 3px solid #a0d469;
  border-left: 0;
  border-top: 0;
  height: 15px;
  left: 6px;
  top: -2px;
  width: 8px;
}
/deep/.el-checkbox__inner {
  width: 27px;
  height: 27px;
  border: 2px solid #dcdfe6;
}
/deep/.is-checked .el-checkbox__inner {
  background-color: #ffffff !important;
  border: 2px solid #a0d469 !important;
}
/deep/.el-table .cell {
  text-overflow: clip;
}
</style>
