<template>
  <el-main class="main">
    <div>
      <div class="jtitle">
        <h2 class="jtext">导师管理</h2>
      </div>
      <div class="table">
        <div class="search flex">
          <div class="seaicon">
            <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
          </div>
          <el-input v-model="accmessage" @input="search" class="seasubfix">
          </el-input>
          <div class="add">
            <img
              @click="adduser"
              src="../../assets/icon/添加用户.png"
              height="30"
              width="30"
            />
          </div>
        </div>
        <div>
          <el-table :data="tableData2" :height="tableHeight" ref="tableScroll2">
            <el-table-column
              key="11"
              prop="id"
              label="编号"
              align="center"
              min-width="3"
            >
            </el-table-column>
            <el-table-column
              key="12"
              prop="name"
              label="姓名"
              align="center"
              min-width="5"
            >
              <template slot-scope="scope">
                <span v-html="showKeyWord(scope.row.name)"></span>
              </template>
            </el-table-column>
            <el-table-column
              key="13"
              prop="organization"
              label="机构"
              align="center"
              min-width="5"
            >
              <template slot-scope="scope">
                <span v-html="showKeyWord(scope.row.organization)"></span>
              </template>
            </el-table-column>
            <el-table-column
              key="14"
              prop="subject"
              label="学科方向"
              align="center"
              min-width="8"
            >
              <template slot-scope="scope">
                <el-tag
                  :key="tag.id"
                  v-for="tag in scope.row.subject"
                  class="tags tag"
                  :class="tagclass(tag)"
                  :disable-transitions="true"
                >
                  ⚬ {{ tag.content }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              key="15"
              prop="star"
              label="星级"
              align="center"
              min-width="4"
            >
            </el-table-column>
            <el-table-column
              key="18"
              prop="email"
              label="常用邮箱"
              align="center"
              min-width="10"
            >
            </el-table-column>
            <el-table-column
              key="18.5"
              prop="inst_email"
              label="机构邮箱"
              align="center"
              min-width="8"
            >
            </el-table-column>
            <el-table-column
              key="19"
              prop="cellphone"
              label="电话"
              align="center"
              min-width="6"
            >
            </el-table-column>
            <el-table-column
              key="20"
              prop="misc"
              label="导师身份"
              align="center"
              min-width="10"
            >
            </el-table-column>
            <el-table-column
              key="21"
              prop="graduate"
              label="是否博士毕业"
              align="center"
              min-width="6"
            >
              <template slot-scope="scope">
                <span>{{
                  scope.row.graduate === true ? '博士毕业' : '博士在读'
                }}</span>
              </template>
            </el-table-column>
            <el-table-column key="22" prop="编辑" align="center" min-width="3">
              <template slot-scope="scope">
                <el-button
                  class="edit listedit"
                  @click="tEdit(scope.$index, scope.row)"
                >
                  <i class="el-icon-edit"></i>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column key="23" prop="删除" min-width="3" align="center">
              <template slot-scope="scope">
                <el-button
                  class="listedit"
                  @click="tDel(scope.$index, scope.row)"
                >
                  <i class="el-icon-delete"></i>
                </el-button>
              </template>
            </el-table-column>
            <div slot="append" style="text-align: center; font-size: 16px">
              <span v-if="has_next">
                <i class="eliwise-loading"></i>加载中请稍后...
              </span>
              <i v-else>--没有数据了--</i>
            </div>
          </el-table>
        </div>
        <el-dialog
          title="添加用户"
          :visible.sync="useradd"
          width="60%"
          :close-on-click-modal="false"
          custom-class="addTeacherScroll"
          v-if="useradd"
        >
          <el-form :model="tmes" :rules="rules" ref="tmes">
            <div>
              <el-row class="star">
                <el-col :span="24">
                  <el-form-item>
                    <div class="title">导师星级</div>
                    <el-rate
                      class="elrate"
                      v-model="tmes.star"
                      :colors="colors"
                    >
                    </el-rate>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item prop="star" class="graduate">
                <div class="title">是否博士毕业</div>
                <el-switch
                  v-model="tmes.graduate"
                  active-color="#A1D46A"
                  inactive-color="#c0c0c0"
                >
                </el-switch>
              </el-form-item>
              <el-form-item prop="star" class="resume">
                <span v-if="tmes.inside" class="content">
                  {{ tmes.inside.updated }}
                  <span class="filename">
                    <a :href="tmes.inside.url">
                      {{ tmes.inside.file }}
                    </a>
                    <i
                      class="el-icon-circle-close"
                      @click="deleteResume('inside', tmes.inside.id)"
                    ></i>
                  </span>
                </span>
                <a
                  @click="
                    upload = true
                    only = true
                  "
                  >添加简历（对内）
                </a>
              </el-form-item>
              <el-form-item prop="star" class="resume">
                <span v-if="tmes.outside" class="content">
                  {{ tmes.outside.updated }}
                  <span class="filename">
                    <a :href="tmes.outside.url">
                      {{ tmes.outside.file }}
                    </a>
                    <i
                      class="el-icon-circle-close"
                      @click="deleteResume('outside', tmes.outside.id)"
                    ></i>
                  </span>
                </span>
                <a
                  @click="
                    upload = true
                    only = false
                  "
                  >添加简历（对外）
                </a>
              </el-form-item>
              <div class="inputlist">
                <el-row>
                  <el-col :span="12">
                    <el-form-item prop="name">
                      <div class="title">姓名*</div>
                      <el-input
                        placeholder="姓名"
                        v-model="tmes.name"
                        class="input"
                        maxlength="10"
                        autocomplete="name"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="username">
                      <div class="title">用户名*</div>
                      <el-input
                        placeholder="用户名"
                        v-model="tmes.username"
                        class="input"
                        autocomplete="username"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item prop="organization">
                      <div class="title">机构*</div>
                      <el-input
                        placeholder="机构"
                        v-model="tmes.organization"
                        maxlength="64"
                        class="input"
                        autocomplete="organization"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="cellphone">
                      <div class="title">电话*</div>
                      <el-input
                        placeholder="电话"
                        v-model="tmes.cellphone"
                        maxlength="32"
                        class="input"
                        autocomplete="cellphone"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item prop="email">
                      <div class="title">常用邮箱*</div>
                      <el-input
                        placeholder="常用邮箱"
                        v-model="tmes.email"
                        maxlength="64"
                        class="input"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="email">
                      <div class="title">机构邮箱*</div>
                      <el-input
                        placeholder="机构邮箱"
                        v-model="tmes.inst_email"
                        maxlength="64"
                        class="input"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item prop="cooperate">
                      <div class="title">导师配合程度*</div>
                      <el-select
                        placeholder="导师配合程度"
                        v-model="tmes.cooperate"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in level"
                          :key="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="connect">
                      <div class="title">导师沟通能力*</div>
                      <el-select
                        placeholder="导师沟通能力"
                        v-model="tmes.connect"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in level"
                          :key="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item prop="subject">
                      <div class="title">学科标签</div>
                      <tagsSearchThird
                        :subject="tmes.subject"
                      ></tagsSearchThird>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12" id="dirSearch">
                    <el-form-item prop="direction">
                      <div class="title">细分方向</div>
                      <tagsSearchSecond
                        :teacher="true"
                        :direction="tmes.direction"
                      ></tagsSearchSecond>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item>
                      <div class="title">生日</div>
                      <el-input
                        placeholder="19900101"
                        maxlength="8"
                        v-model="tmes.birthday"
                        class="input"
                        autocomplete="birthday"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item>
                      <div class="title">通讯地址</div>
                      <el-input
                        placeholder="通讯地址"
                        maxlength="128"
                        v-model="tmes.address"
                        class="input"
                        autocomplete="address"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item>
                      <div class="title">导师身份</div>
                      <el-input
                        placeholder="导师身份"
                        v-model="tmes.misc"
                        class="input"
                        autocomplete="misc"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <el-row class="dialogAddbottom">
                <el-col :span="24">
                  <div class="title">导师背景</div>
                  <el-input
                    type="textarea"
                    class="input"
                    :autosize="{ minRows: 6, maxRows: 6 }"
                    placeholder="导师背景"
                    v-model="tmes.paper"
                    autocomplete="paper"
                  ></el-input>
                </el-col>
              </el-row>
            </div>
          </el-form>
          <div slot="footer">
            <el-button @click="quituseradd('tmes')">取 消</el-button>
            <el-button type="primary" @click="pushadd('tmes')">
              确 定
            </el-button>
          </div>
        </el-dialog>
        <el-dialog
          title="编辑用户信息"
          :visible.sync="userfix"
          width="60%"
          :close-on-click-modal="false"
          custom-class="editTeacherScroll"
          v-if="userfix"
        >
          <el-form
            v-if="chooseTypefix === 1"
            :model="tmes"
            :rules="rules"
            ref="tmes"
          >
            <div>
              <el-row class="star">
                <el-col :span="24">
                  <el-form-item>
                    <div class="title">导师星级</div>
                    <el-rate
                      class="elrate"
                      v-model="tmes.star"
                      :colors="colors"
                    >
                    </el-rate>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item prop="star" class="graduate">
                <div class="title">是否博士毕业</div>
                <el-switch
                  v-model="tmes.graduate"
                  active-color="#A1D46A"
                  inactive-color="#c0c0c0"
                >
                </el-switch>
              </el-form-item>
              <el-form-item prop="star" class="resume">
                <span v-if="tmes.inside" class="content">
                  {{ tmes.inside.updated }}
                  <span class="filename">
                    <a :href="tmes.inside.url">
                      {{ tmes.inside.file }}
                    </a>
                    <i
                      class="el-icon-circle-close"
                      @click="deleteResume('inside', tmes.inside.id)"
                    ></i>
                  </span>
                </span>
                <a
                  @click="
                    upload = true
                    only = true
                  "
                  >添加简历（对内）
                </a>
              </el-form-item>
              <el-form-item prop="star" class="resume">
                <span v-if="tmes.outside" class="content">
                  {{ tmes.outside.updated }}
                  <span class="filename">
                    <a :href="tmes.outside.url">
                      {{ tmes.outside.file }}
                    </a>
                    <i
                      class="el-icon-circle-close"
                      @click="deleteResume('outside', tmes.outside.id)"
                    ></i>
                  </span>
                </span>
                <a
                  @click="
                    upload = true
                    only = false
                  "
                  >添加简历（对外）
                </a>
              </el-form-item>
              <div class="inputlist">
                <el-row>
                  <el-col :span="12">
                    <el-form-item prop="name">
                      <div class="title">姓名*</div>
                      <el-input
                        placeholder="姓名"
                        v-model="tmes.name"
                        maxlength="10"
                        class="input"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="username">
                      <div class="title">用户名*</div>
                      <el-input
                        placeholder="用户名"
                        v-model="tmes.username"
                        class="input"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item prop="organization">
                      <div class="title">机构*</div>
                      <el-input
                        placeholder="机构"
                        maxlength="64"
                        v-model="tmes.organization"
                        class="input"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="cellphone">
                      <div class="title">电话*</div>
                      <el-input
                        placeholder="电话"
                        v-model="tmes.cellphone"
                        maxlength="32"
                        class="input"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item prop="email">
                      <div class="title">常用邮箱*</div>
                      <el-input
                        placeholder="常用邮箱"
                        v-model="tmes.email"
                        maxlength="64"
                        class="input"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="email">
                      <div class="title">机构邮箱*</div>
                      <el-input
                        placeholder="机构邮箱"
                        v-model="tmes.inst_email"
                        maxlength="64"
                        class="input"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item prop="cooperate">
                      <div class="title">导师配合程度*</div>
                      <el-select
                        placeholder="导师配合程度"
                        v-model="tmes.cooperate"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in level"
                          :key="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="connect">
                      <div class="title">导师沟通能力*</div>
                      <el-select
                        placeholder="导师沟通能力"
                        v-model="tmes.connect"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in level"
                          :key="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item prop="subject">
                      <div class="title">学科标签</div>
                      <tagsSearchThird
                        :subject="tmes.subject"
                      ></tagsSearchThird>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="direction">
                      <div class="title">细分方向</div>
                      <tagsSearchSecond
                        :teacher="true"
                        :direction="tmes.direction"
                      ></tagsSearchSecond>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item>
                      <div class="title">生日</div>
                      <el-input
                        placeholder="19900101"
                        maxlength="8"
                        v-model="tmes.birthday"
                        class="input"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item>
                      <div class="title">通讯地址</div>
                      <el-input
                        placeholder="通讯地址"
                        maxlength="128"
                        v-model="tmes.address"
                        class="input"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item>
                      <div class="title">导师身份</div>
                      <el-input
                        placeholder="导师身份"
                        v-model="tmes.misc"
                        class="input"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <el-row class="dialogAddbottom">
                <el-col :span="24">
                  <div class="title">导师背景</div>
                  <el-input
                    type="textarea"
                    class="input"
                    :autosize="{ minRows: 6, maxRows: 6 }"
                    placeholder="导师背景"
                    v-model="tmes.paper"
                  ></el-input>
                </el-col>
              </el-row>
            </div>
          </el-form>
          <div slot="footer">
            <el-button @click="quituserfix('tmes')">取 消</el-button>
            <el-button type="primary" @click="repassword">重置密码</el-button>
            <el-button type="primary" @click="fixuser('tmes')">
              确 定
            </el-button>
          </div>
        </el-dialog>
        <el-dialog
          :title="uploadTitle"
          :visible.sync="upload"
          width="40%"
          :close-on-click-modal="false"
          :open="open()"
        >
          <div><p>上传文件*</p></div>
          <el-upload
            drag
            :action="uploadUrl"
            class="upload-demo"
            :on-success="handleSuccess"
            :on-remove="removeFile"
            :before-upload="beforeFileUpload"
            :file-list="files"
          >
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
          <span slot="footer" class="dialog-footer">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </el-main>
</template>
<script>
/* eslint-disable no-console */
import axios from '../axios'
import { MessageBox } from 'element-ui'
import changerole from '../pub/changerole'
import tagsSearchSecond from '../pub/tagsSearchSecond'
import tagsSearchThird from '../pub/tagsSearchThird'
import moment from 'moment'
import { debounce } from 'lodash'
export default {
  name: 'TDaccount',
  data () {
    return {
      level: [1, 2, 3, 4, 5],
      uploadUrl: `${axios.defaults.baseURL}/upload/`,
      chooseTypefix: null,
      rules: {
        username: [
          { required: true, message: '请填写用户名', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
        email: [{ required: true, message: '请填写邮箱', trigger: 'blur' }],
        cooperate: [
          { required: true, message: '请选择导师配合程度', trigger: 'change' }
        ],
        connect: [
          { required: true, message: '请选择导师沟通能力', trigger: 'change' }
        ],
        research_direction: [
          { required: true, message: '请填写研究方向', trigger: 'blur' }
        ],
        cellphone: [
          { required: true, message: '请填写电话号码', trigger: 'blur' }
        ],
        organization: [
          { required: true, message: '请填写机构名称', trigger: 'blur' }
        ]
      },
      tmes: {
        star: 1,
        birthday: '',
        name: '',
        username: '',
        organization: '',
        inst_email: '',
        cellphone: '',
        email: '',
        misc: '',
        research_direction: '',
        address: '',
        paper: '',
        scientific_research: '',
        connect: 1,
        cooperate: 1,
        graduate: false,
        domestic: false,
        inside: '',
        outside: '',
        subject: [],
        direction: []
      },
      tableHeight: window.innerHeight - 150,
      tableData2: [],
      useradd: false,
      userfix: false,
      colors: {
        2: '#A0D469',
        4: { value: '#A0D469', excluded: true },
        5: '#A0D469'
      },
      accmessage: '',
      userid: '',
      tpagenumber: 1,
      has_next: true,
      activeInedx: 0,
      uploadTitle: '',
      only: false,
      upload: false,
      files: []
    }
  },
  created () {
    this.getT()
  },
  mounted () {
    const that = this
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 150
      })()
    }
    this.dom2()
    this.hideTagsSearch()
  },
  methods: {
    showKeyWord (str) {
      if (this.accmessage !== '') {
        let regStr = `${this.accmessage}`
        let reg = new RegExp(regStr, 'g')
        str = str.replace(
          reg,
          `<span style="background-color:#F7B500;">${this.accmessage}</span>`
        )
      }
      return str
    },
    tagclass (tag) {
      switch (tag.type) {
        case '理科':
          return tag.content.indexOf(this.accmessage) > -1 &&
            this.accmessage !== ''
            ? 'tag1'
            : 'tag2'
        case '工科':
          return tag.content.indexOf(this.accmessage) > -1 &&
            this.accmessage !== ''
            ? 'tag3'
            : 'tag4'
        case '商科':
          return tag.content.indexOf(this.accmessage) > -1 &&
            this.accmessage !== ''
            ? 'tag5'
            : 'tag6'
        case '社科':
          return tag.content.indexOf(this.accmessage) > -1 &&
            this.accmessage !== ''
            ? 'tag7'
            : 'tag8'
        case '医科':
          return tag.content.indexOf(this.accmessage) > -1 &&
            this.accmessage !== ''
            ? 'tag9'
            : 'tag10'
      }
    },

    deleteResume (type, id) {
      if (id !== undefined) {
        axios.delete(`/tdirector/resume/${id}`).then(response => {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          })
          this.tmes[type] = ''
        })
      } else {
        this.tmes[type] = ''
      }
    },
    open () {
      if (this.only) this.uploadTitle = '上传对内简历（仅教学总监可见）'
      else this.uploadTitle = '上传对外简历（市场部&教学产品部可见）'
    },
    cancel () {
      this.upload = false
      this.files = []
    },
    confirm () {
      if (this.files.length > 0) {
        let file = {
          file: this.files[0].name,
          url: this.files[0].response.url,
          updated: moment(Date.now()).format('YYYY-MM-DD'),
          key: this.files[0].response.key
        }
        if (this.only) {
          this.tmes.inside = file
        } else {
          this.tmes.outside = file
        }
      }
      this.upload = false
      this.files = []
    },
    beforeFileUpload () {
      let x = this.files.length > 0
      if (x) {
        this.$message({
          message: '最多只能上传一个文件！',
          type: 'warning'
        })
      }
      return !x
    },
    removeFile (file, fileList) {
      this.files = fileList
    },
    handleSuccess (response, file, fileList) {
      this.$message({
        showClose: true,
        message: `文件上传成功`,
        type: 'success'
      })
      this.files = fileList
    },
    a () {
      let scrollTop2 = this.dom2.scrollTop
      let windowHeight2 = this.dom2.clientHeight || this.dom2.clientHeight
      let scrollHeight2 = this.dom2.scrollHeight || this.dom2.scrollHeight
      if (scrollTop2 + windowHeight2 >= scrollHeight2 - 1 && this.has_next) {
        this.tpagenumber = this.tpagenumber + 1
        axios
          .get('/tdirector/teachers/', {
            params: {
              name: this.accmessage,
              page: this.tpagenumber
            }
          })
          .then(response => {
            if (response.data.has_previous) {
              this.tableData2 = this.tableData2.concat(response.data.teachers)
            }
            this.has_next = response.data.has_next
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    dom2 () {
      this.dom2 = this.$refs.tableScroll2.bodyWrapper
      this.dom2.addEventListener('scroll', this.a)
    },
    search () {
      this.getT()
    },
    getT: debounce(function () {
      this.tpagenumber = 1
      this.dom2.scrollTop = 0
      this.getTeacher()
    }, 500),
    getTeacher () {
      axios
        .get('/tdirector/teachers/', {
          params: {
            name: this.accmessage,
            page: this.tpagenumber
          }
        })
        .then(response => {
          this.tableData2 = response.data.teachers
          this.has_next = response.data.has_next
        })
        .catch(error => {
          console.log(error)
        })
    },
    addTeacher () {
      axios
        .post('/tdirector/teacher/', this.tmes)
        .then(response => {
          if (response.status === 200) {
            this.$message({
              showClose: true,
              message: '添加成功',
              type: 'success'
            })
            this.dom2.scrollTop = 0
            this.tpagenumber = 1
            this.has_next = true
            this.getTeacher()
          }
        })
        .catch(error => {
          this.$message({
            showClose: true,
            message: `添加失败,${error.response.data.error_message}`,
            type: 'error'
          })
        })
    },
    adduser () {
      this.useradd = true
      this.tmes = this.$options.data().tmes
    },
    fixuser (formName) {
      const that = this
      this.$refs[formName].validate(valid => {
        if (valid) {
          MessageBox.confirm('是否修改该用户信息').then(() => {
            axios
              .put(`/tdirector/teacher/${this.userid}/`, this.tmes)
              .then(response => {
                if (response.status === 200) {
                  this.$message({
                    showClose: true,
                    message: '修改成功',
                    type: 'success'
                  })
                  this.tableData2[this.activeInedx] = this.tmes
                  this.userfix = false
                  this.tpagenumber=1
                  this.getTeacher()
                }
              })
              .catch(error => {
                this.$message({
                  showClose: true,
                  message: `修改失败,${error.response.data.error_message}`,
                  type: 'error'
                })
              })
          })
        } else {
          const base = that.$refs.tmes.$el.getBoundingClientRect().top
          for (const validElement of that.$refs.tmes.fields) {
            const isError =
              validElement.$el._prevClass.indexOf('is-error') !== -1
            const isSuccess =
              validElement.$el._prevClass.indexOf('is-success') !== -1
            const isRequire =
              validElement.$el._prevClass.indexOf('is-require') !== -1
            if ((isRequire && !isError && !isSuccess) || isError) {
              document.getElementsByClassName(
                'editTeacherScroll'
              )[0].scrollTop =
                validElement.$el.getBoundingClientRect().top - base + 40
              break
            }
          }
          return false
        }
      })
    },
    pushadd (formName) {
      const that = this
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.addTeacher()
          this.useradd = false
        } else {
          const base = that.$refs.tmes.$el.getBoundingClientRect().top
          for (const validElement of that.$refs.tmes.fields) {
            const isError =
              validElement.$el._prevClass.indexOf('is-error') !== -1
            const isSuccess =
              validElement.$el._prevClass.indexOf('is-success') !== -1
            const isRequire =
              validElement.$el._prevClass.indexOf('is-require') !== -1
            if ((isRequire && !isError && !isSuccess) || isError) {
              document.getElementsByClassName('addTeacherScroll')[0].scrollTop =
                validElement.$el.getBoundingClientRect().top - base + 40
              break
            }
          }
          return false
        }
      })
    },
    tEdit (index, row) {
      this.userid = row.id
      this.activeInedx = index
      axios.get(`/tdirector/teacher/${row.id}`).then(response => {
        this.tmes = response.data
      })
      this.userfix = true
      this.chooseTypefix = 1
    },
    tDel (index, row) {
      MessageBox.confirm('是否删除该用户').then(() => {
        axios
          .delete(`/tdirector/teacher/${row.id}`, {
            teacher: row.id
          })
          .then(response => {
            if (response.status === 200) {
              this.$message({
                showClose: true,
                message: '已删除',
                type: 'success'
              })

              if (index > 30) {
                this.tpagenumber = parseInt(index / 30)
                this.has_next = true
                this.tableData2.splice(
                  this.tpagenumber * 30,
                  this.tableData2.length - this.tpagenumber * 30
                )
                this.a()
              } else {
                this.tpagenumber = 1
                this.getTeacher()
              }
            }
          })
          .catch(error => {
            this.$message({
              showClose: true,
              message: `删除失败,${error.response.data.error_message}`,
              type: 'error'
            })
          })
      })
    },
    quituserfix (formNam) {
      this.$refs[formNam].resetFields()
      this.userfix = false
    },
    quituseradd (formName) {
      this.$refs[formName].resetFields()
      this.useradd = false
    },
    repassword () {
      MessageBox.confirm('是否重置该用户密码').then(() => {
        axios
          .post('/reset_password', {
            type: this.chooseTypefix,
            id: this.userid
          })
          .then(response => {
            if (response.status === 200) {
              this.$message({
                showClose: true,
                message: '已重置',
                type: 'success'
              })
            }
          })
          .catch(error => {
            this.$message({
              showClose: true,
              message: `重置失败,${error.response.data.error_message}`,
              type: 'error'
            })
          })
      })
    }
  },
  components: {
    changerole,
    tagsSearchSecond,
    tagsSearchThird
  }
}
</script>
<style lang="scss" scoped>
.flex {
  justify-content: flex-start;
  align-items: center;
}
.sfull {
  margin-right: 10px;
}
.graduate {
  position: absolute;
  top: 0px;
  left: 20%;
  width: 85px;
  text-align: left;
}
.el-form {
  position: relative;
}

.el-upload__text {
  margin: 70px;
  color: #c0c0c0;
}
/deep/.el-upload {
  width: 100%;
}
/deep/.el-upload-dragger {
  background-color: inherit;
  border-width: 3px;
}
.resume {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 50%;
  text-align: right;
  color: #656d78;
  & + & {
    top: 30px;
  }
  .content {
    width: calc(100% - 112px);
    display: inline-block;
    text-align: left;
  }
  a {
    text-decoration: underline;
    color: #a0d469;
    cursor: pointer;
  }
  .filename {
    display: inline-block;
    width: calc(100% - 90px);
  }
  span > a {
    color: #656d78;
    max-width: calc(100% - 40px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    vertical-align: bottom;
    margin-left: 20px;
  }
  i {
    margin-left: 5px;
    &:hover {
      color: #a0d469;
      cursor: pointer;
    }
  }
}
.inputlist {
  .el-col:nth-child(odd) {
    padding-right: 10px;
  }
  .el-col:nth-child(even) {
    padding-left: 10px;
  }
}
.direction {
  border-bottom: solid 1px #c0c0c0;
  .icon {
    margin-top: 5px;
  }
  /deep/.el-input__inner {
    background-color: #f4f6f9;
    border: solid 0px;
    padding-left: 5px;
    padding-right: 0px;
  }
}
</style>
