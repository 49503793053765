<template>
  <div class="item">
    <p class="title">{{ dic.name }}</p>
    <div
      class="container"
      v-if="dic.self"
      :class="{ none: !data && data !== 0 }"
    >
      {{ data || '无' }}
    </div>
    <div class="container" v-else>
      <div
        v-for="(item, name) in dic.content"
        :key="name"
        :class="{ none: !data[name] && data[name] !== 0 }"
      >
        <span class="name" v-if="!hidden || data[name]">
          {{ item.name }}:
        </span>
        <br v-if="name === 'misc'" />
        <span
          class="content"
          :ref="`${item.name}.${name}`"
          v-if="!hidden || data[name]"
          style="white-space: pre-wrap;"
        >
          <slot
            v-bind:item="data[name]"
            :name="name"
            v-if="name !== 'attachments'"
            ><!-- 这里的注释是为了解决文字开头的那个空格
            -->{{ data[name] || '无' }}
          </slot>
          <slot v-else>
            <span v-if="data[name].length !== 0">
              <span v-for="att in data[name]" :key="att.id">
                <a
                  href="javascript:void(0);"
                  @click="download(att.url,att.name)"
                  type="info"
                  :underline="false"
                  class="fileDown"
                >{{
                  att.name
                }}</a
                >，
              </span>
            </span>
            <span v-else>无</span>
          </slot>
        </span>
        <button
          v-if="item.copy && data[name]"
          v-clipboard:copy="data[name]"
          v-clipboard:success="onCopy"
          class="copy"
        ></button>
      </div>
      <span class="none" v-if="noneMessage || hidden"> 无 </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dic: {
      type: Object
    },
    data: {
      type: [Object, String]
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    noneMessage () {
      return !Object.keys(this.data).some(key => this.data[key])
    }
  },
  methods: {
    onCopy () {
      alert('复制成功')
    },
    download (fileUrl,name) {
      let x = new XMLHttpRequest()
      x.open('GET', fileUrl, true)
      x.responseType = 'blob'
      x.onload = function (e) {
        let url = window.URL.createObjectURL(x.response)
        let a = document.createElement('a')
        a.href = url
        a.download = name
        a.click()
      }
      x.send()
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  text-align: left;
  .title {
    font-size: 18px;
    line-height: 18px;
    color: #8dc152;
    padding-left: 18.51px;
    position: relative;
    font-weight: 700;
    &::before {
      content: '';
      background: #8dc152;
      width: 10px;
      height: 10px;
      display: inline-block;
      line-height: 18px;
      transform-origin: center;
      transform: rotate(45deg);
      position: absolute;
      left: -5px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .name {
    font-size: 14px;
    color: #656D78;
  }
  .content {
    font-size: 14px;
    color: #434A54;
  }
  .copy {
    background: url('../../../assets/icon/复制.png');
    height: 13px;
    width: 12px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .none,
  .none * {
    color: #c0c0c0;
  }
}
.el-link.el-link--info {
  color: #303133;
  text-decoration: underline;
  &:hover {
    color: #a0d469;
  }
}
.fileDown{
  color: #303133;
  &:hover{
    color: #a0d469;
  }

}
</style>
