<template>
  <el-dialog title="修改发放日期" :visible.sync="dialogDate" width="500px">
    <div class="putDate">
      <span class="demonstration">发放日期：</span>
      <el-date-picker
        value-format="yyyy-MM-dd"
        v-model="reDate"
        type="date"
        placeholder="选择日期"
        clearable="false"
        class="inputwidth"
      >
      </el-date-picker>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogDate = false">取 消</el-button>
      <el-button type="primary" @click="putall">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from '../axios.js'
export default {
  name: 'dateDialog',
  props: {
    form2: {},
    date1: '',
    reDate: ''
  },
  data () {
    return {
      dialogDate: false
    }
  },
  methods: {
    openDialog () {
      this.dialogDate = true
    },
    putall () {
      if (this.reDate === '') {
        this.$message({
          message: '请填写修改日期',
          type: 'warning'
        })
      } else {
        axios
          .post(`/finance/salary/edit_date`, {
            grantDate: this.reDate,
            date: this.date1
          })
          .then(res => {
            if (res.status === 200) {
              this.$parent.getSalary()
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.dialogDate = false
            }
          })
      }
    }
  }
}
</script>

<style scoped>
.putDate {
  text-align: left;
}
.inputwidth {
  width: 344px !important;
}
/deep/.el-dialog .el-input__inner {
  height: 40px;
}
</style>
