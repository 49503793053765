<template>
  <el-main class="main">
    <div class="flex">
      <supportList @select="getstu1" url="/tdirector/sponsers/" />
      <div
        v-loading="loading"
        element-loading-text="加载中请稍后..."
        element-loading-spinner="eliwise-loading"
        class="neirong"
      >
        <div class="jtitle">
          <h2 class="jtext">规划师</h2>
        </div>
        <h1 class="sname">{{ ames.name }}</h1>
        <jlistgroup :detail="detail" />
      </div>
    </div>
  </el-main>
</template>

<script>
/* eslint-disable no-console */
import axios from '../axios'
import jlistgroup from '../pub/jlistgroup'
import supportList from '../pub/supportList'
export default {
  name: 'TDsupport',
  components: {
    jlistgroup,
    supportList
  },
  data () {
    return {
      detail: {
        notfinlist: [],
        now_interview: [],
        finlist: [],
        fin_interview: [],
        part: []
      },
      ames: {},
      loading: true
    }
  },
  methods: {
    getstu1 (ames) {
      this.ames = ames
      this.loading = true
      axios
        .get(`/tdirector/sponser/${ames.id}/projects`)
        .then(response => {
          this.detail.now_interview = response.data.not_interviewed
          this.loading = false
          this.detail.notfinlist = response.data.not_finished
          this.detail.finlist = response.data.finished
          this.detail.fin_interview = response.data.interviewed
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
h1 {
  font-size: 30px;
  text-align: left;
  margin-top: 15px;
}
</style>
