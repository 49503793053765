<template>
  <el-container v-if="ready">
    <el-aside>
      <asnav>1</asnav>
    </el-aside>
    <router-view></router-view>
  </el-container>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'
import asnav from './asnav'
export default {
  name: 'ASnav',
  components: {
    asnav
  },
  data () {
    return {
      ready: false
    }
  },
  beforeCreate () {
    axios.get('/is_login/').then(response => {
      if (response.data.roles.indexOf(this.$store.state.role) > -1) {
        this.$store.commit('setLogin', response.data)
        this.ready = true
      } else {
        MessageBox.alert('未登录，请重新登录')
        localStorage.removeItem('Authorization')
        this.$router.push('/login')
      }
    })
  }
}
</script>
