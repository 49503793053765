<template>
  <el-main class="main">
    <div class="content">
      <h2>PRO教授项目检索(高中生)</h2>
      <div class="buttonsWra">
        <el-tooltip content="请选择项目" effect="light" placement="top" :disabled="Selection.length > 0">
          <el-button @click="exportTopic()" class="export" type="primary" :disabled="Selection.length === 0">
            导 出
          </el-button>
        </el-tooltip>
        <el-tooltip content="请选择项目" effect="light" placement="top" :disabled="Selection.length > 0">
          <div class="downloadtip">
            <el-button v-loading="loading" type="primary" class="download" @click="down_poseter()"
              :disabled="Selection.length === 0 || loading">下载海报
            </el-button>
          </div>
        </el-tooltip>
        <!-- <el-button @click="queryCount = true" class="add" type="primary">
          扫码次数统计
        </el-button> -->
      </div>
      <el-row class="top">
        <el-col :span="22" class="item">
          <el-input v-model="searchInput" placeholder="教授姓名/职位/机构/课时数等关键词" @input="input_search()"
            @keyup.enter.native="search()">
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button class="sea" type="primary" @click="search()">
            搜索
          </el-button>
        </el-col>
      </el-row>
      <div class="DBS">
        <el-table :data="tableData" class="base" @sort-change="sortchange" ref="tableList" :row-class-name="fullclass"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" align="center" label="全选" :selectable="selectable">
          </el-table-column>
          <el-table-column prop="proId" align="center" min-width="12" label="编号">
          </el-table-column>
          <el-table-column prop="professor" min-width="30" header-align="center" align="left" label="教授">
            <template slot-scope="scope">
              <!--              <span v-html="showKeyWord(scope.row.direction)"></span>-->
              <div class="professorWra">
                <span>{{
                  scope.row.professor.firstName + ' ' + scope.row.professor.lastName
                }}</span>
                <br />
                <span style="color: #9b9b9b" v-html="showKeyWord(scope.row)"></span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="position" align="center" min-width="18" label="教授职位">
            <template v-slot="scope">
              <span>{{ scope.row.professor.position }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="school" align="center" min-width="15" label="教授机构">
            <template v-slot="scope">
              <span>{{ scope.row.professor.school }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="letter" align="center" min-width="15" label="推荐信">
            <template v-slot="scope">
              <span>{{ scope.row.professor.letter }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="hours" align="center" min-width="15" label="课时数">
            <template v-slot="scope">
              <span>{{ scope.row.professor.hours }}</span>
            </template>
          </el-table-column> -->
          <el-table-column prop="updated" align="center" min-width="15" label="更新时间">
          </el-table-column>
          <el-table-column min-width="15" align="center" label="操作">
            <template slot-scope="scope">
              <router-link style="vertical-align: bottom" tag="a" target="_blank" :to="{
                path: '/preview',
                query: {
                  url: origin + scope.row.uuid,
                  title: 'Prof.' + scope.row.professor.firstName,
                  img: scope.row.poster,
                },
              }">
                <img src="../../assets/icon/预览icon.svg" class="svg" />
              </router-link>
            </template>
          </el-table-column>
          <div slot="empty" class="empty" v-if="has_next">
            <i class="eliwise-loading"></i> <span>加载中，请稍后</span>
          </div>
        </el-table>
        <div class="tableborder"></div>
        <div class="bottom">
          <div style="float: left">
            <p style="margin-right: 0px" v-if="Selection.length > 0">
              已选中 <span class="green">{{ Selection.length }}</span> 条项目/
            </p>
            <p>
              共计
              <span class="green">{{ topic_total }}</span>
              条项目（含下架项目
              <span class="green">{{ full_total }}</span>
              条）
            </p>
          </div>
          <p class="green" @click="pagedown()" style="cursor: pointer">
            上一页
          </p>
          <p>{{ page }}/{{ page_total }}</p>
          <p class="green" @click="pageup()" style="cursor: pointer">下一页</p>
          <p>跳转至<el-input class="page" v-model="next_page"></el-input>页</p>
          <el-button type="primary" @click="pageto()">确定</el-button>
        </div>
      </div>
    </div>
    <!-- <query-count v-if="queryCount" :type="'ipage_query'"></query-count> -->
  </el-main>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'
import changerole from '../pub/changerole'
// import queryCount from './queryCount'
export default {
  name: 'Iap',
  components: {
    changerole
    // queryCount
  },
  data() {
    return {
      directionvalue: '',
      page: 1,
      topic_total: 1,
      page_total: 1,
      next_page: '',
      has_next: true,
      has_previous: false,
      sort: 'updated',
      direction: '',
      school: '',
      name: '',
      desc: true,
      loading: false,
      tableData: [],
      iapForm: false,
      Selection: [],
      full_total: 0,
      ids: [],
      hours: '',
      queryCount: false,
      searchInput: '',
      origin: ''
    }
  },
  created() {
    if (window.location.host.indexOf('eliteqihang') > -1) {
      this.origin = 'https://p.eliteqihang.com/pro/'
    } else {
      this.origin = 'https://p.manager.eliwise.demo.sya.org.cn/pro/'
    }
    this.getlist()
  },
  methods: {
    input_search: _.debounce(function () {
      this.search()
    }, 500),
    search() {
      this.directionvalue = this.direction
      this.getsearch()
    },
    getsearch() {
      this.page = 1
      this.getlist()
    },
    selectable(row) {
      return row.status
    },
    sortchange(column) {
      this.sort = column.prop
      switch (column.order) {
        case 'ascending':
          this.desc = false
          break
        case 'descending':
          this.desc = true
          break
        default:
          this.desc = true
          this.sort = ''
      }
      this.getsearch()
    },
    handleSelectionChange(val) {
      this.Selection = []
      val.forEach((value, index, arr) => {
        this.Selection[index] = value.id
      })
      if (this.Selection.length >= 30) this.Selection = this.ids
    },
    exportTopic() {
      axios
        .post(`/topic/export/pro`, {
          id: this.Selection,
          admin: true
        }, {
          responseType: 'blob',
        })
        .then(res => {
          const filename = res.headers["content-disposition"];
          const blob = new Blob([res.data]);
          var downloadElement = document.createElement("a");
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = decodeURIComponent(filename.split("filename=")[1]);
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        })
    },
    down_poseter() {
      MessageBox.confirm(`已选择${this.Selection.length}张海报，确认下载？`).then(() => {
        this.loading = true
        axios
          .get(`${axios.defaults.baseURL}/topic/download/pro`, {
            params: {
              id: this.Selection.join(',')
            }
          })
          .then(response => {
            let url = response.data.url
            let iframe = document.createElement('iframe')
            iframe.setAttribute('src', url)
            iframe.style.display = 'none'
            document.body.appendChild(iframe)
            setTimeout(function () {
              iframe.src = 'about:blank'
              try {
                iframe.contentWindow.document.write('')
                iframe.contentWindow.document.clear()
              } catch (e) { }
              iframe.parentNode.removeChild(iframe)
            }, 2000)
            this.loading = false
          })
      })
    },
    pageto() {
      if (this.next_page > 0 && this.next_page <= this.page_total) {
        this.page = this.next_page
        this.getlist()
        document.querySelector('.el-main').scrollTop = 0
      } else {
        this.$message({
          message: '请输入正确的页码',
          type: 'warning'
        })
      }
    },
    pageup() {
      if (this.has_next) {
        this.page++
        this.getlist()
        document.querySelector('.el-main').scrollTop = 0
      } else {
        this.$message({
          message: '已经到最后一页了',
          type: 'warning'
        })
      }
    },
    pagedown() {
      if (this.has_previous) {
        this.page--
        this.getlist()
        document.querySelector('.el-main').scrollTop = 0
      } else {
        this.$message({
          message: '已经是第一页了',
          type: 'warning'
        })
      }
    },
    getlist() {
      axios
        .get('/topic/pros', {
          params: {
            keyword: this.searchInput,
            page: this.page,
            TargetAudienceStatus: 1
          }
        })
        .then(response => {
          this.tableData = response.data.pros
          this.topic_total = response.data.topic_total
          this.page_total = response.data.page_total
          this.has_next = response.data.has_next
          this.has_previous = response.data.has_previous
          this.ids = response.data.ids
          this.full_total = response.data.full_total
        })
    },
    deletetopic(row) {
      MessageBox.confirm('确定删除').then(() => {
        axios.delete(`/topic/pro/${row.id}`).then(response => {
          if (response.status === 200) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
          }
          this.getlist()
        })
      })
    },
    showKeyWord(str) {
      const res = `${str.subject.content} | ${str.tagsText.replaceAll(',', ' | ')}`
      return res
    },
    fullclass({ row }) {
      if (row.status === false) {
        return 'fulling'
      }
    }
  }
}
</script>
