<template>
  <el-main class="main">
    <post />
  </el-main>
</template>

<script>
import post from '../pub/post.vue'
export default {
  name: 'Jpost',
  components: {
    post
  }
}
</script>
