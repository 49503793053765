<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="project_type">渠道账号信息</div>
      </el-col>
      <el-col :span="12" class="inputleft">
        <el-form-item prop="enterpise.username" :rules="{
          required: true,
          message: '请填写用户名',
          trigger: 'blur'
        }">
          <div class="title">用户名*</div>
          <el-input placeholder="用户名" v-model="enterpise.username">
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-form-item prop="checkList">
        <div class="title">选题库权限</div>
        <el-checkbox-group v-model="checkList" @change="changegroup">
          <el-checkbox label="论文选题">论文项目</el-checkbox>
          <el-checkbox label="科研项目"></el-checkbox>
          <el-checkbox label="科研+论文项目"></el-checkbox>
          <el-checkbox label="学术指导选题">学术指导项目</el-checkbox>
          <el-checkbox label="EPQ选题">EPQ项目</el-checkbox>
          <el-checkbox label="Lite项目"></el-checkbox>
          <el-checkbox label="Standard项目"></el-checkbox>
          <el-checkbox label="Pro">Pro</el-checkbox>
          <el-checkbox label="Pro高中生">Pro高中生</el-checkbox>
          <!-- <el-checkbox label="Pro10课时"></el-checkbox> -->
          <el-checkbox label="XDF项目"></el-checkbox>
          <el-checkbox label="新通">新通</el-checkbox>
          <el-checkbox label="保研人">保研人</el-checkbox>
          <el-checkbox label="EWA">EWA</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item prop="checkList">
        <div class="title">查看学生进度权限</div>
        <el-checkbox v-model="enterpise.permission">
          查看学生进度权限
        </el-checkbox>
      </el-form-item>
    </el-row>
    <el-row class="topicList">
      <el-col :span="12" v-if="checkList.indexOf('论文选题') > -1">
        <el-form-item prop="enterpise.thesis" :rules="[
          {
            required: true,
            message: '请填写论文项目选题库别称',
            trigger: 'blur'
          }
        ]">
          <div class="title">论文项目选题库别称*</div>
          <el-input placeholder="论文项目" v-model="enterpise.thesis">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="checkList.indexOf('科研项目') > -1">
        <el-form-item prop="enterpise.research" :rules="[
          {
            required: true,
            message: '请填写科研项目选题库别称',
            trigger: 'blur'
          }
        ]">
          <div class="title">科研项目选题库别称*</div>
          <el-input placeholder="科研项目" v-model="enterpise.research">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="checkList.indexOf('科研+论文项目') > -1">
        <el-form-item prop="enterpise.rt" :rules="[
          {
            required: true,
            message: '请填写科研+论文项目选题库别称',
            trigger: 'blur'
          }
        ]">
          <div class="title">科研+论文项目选题库别称*</div>
          <el-input placeholder="科研+论文项目" v-model="enterpise.rt">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="checkList.indexOf('学术指导选题') > -1">
        <el-form-item prop="enterpise.academic" :rules="[
          {
            required: true,
            message: '请填写学术指导项目选题库别称',
            trigger: 'blur'
          }
        ]">
          <div class="title">学术指导项目选题库别称*</div>
          <el-input placeholder="学术指导项目" v-model="enterpise.academic">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="checkList.indexOf('EPQ选题') > -1">
        <el-form-item prop="enterpise.epq" :rules="[
          {
            required: true,
            message: '请填写EPQ项目选题库别称',
            trigger: 'blur'
          }
        ]">
          <div class="title">EPQ项目选题库别称*</div>
          <el-input placeholder="EPQ选题" v-model="enterpise.epq"> </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="checkList.indexOf('Lite项目') > -1">
        <el-form-item prop="enterpise.lite" :rules="[
          {
            required: true,
            message: 'Lite项目',
            trigger: 'blur'
          }
        ]">
          <div class="title">Lite项目*</div>
          <el-input placeholder="Lite项目" v-model="enterpise.lite"> </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="checkList.indexOf('Standard项目') > -1">
        <el-form-item prop="enterpise.standard" :rules="[
          {
            required: true,
            message: 'Standard项目',
            trigger: 'blur'
          }
        ]">
          <div class="title">Standard项目*</div>
          <el-input placeholder="Standard项目" v-model="enterpise.standard">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="checkList.indexOf('Pro') > -1">
        <el-form-item prop="enterpise.iap" :rules="[
          {
            required: true,
            message: '请填写Pro别称',
            trigger: 'blur'
          }
        ]">
          <div class="title">Pro别称*</div>
          <el-input placeholder="Pro" v-model="enterpise.iap"> </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="checkList.indexOf('Pro高中生') > -1">
        <el-form-item
          prop="enterpise.proHigh"
          :rules="[
            {
              required: true,
              message: 'Pro高中生别称',
              trigger: 'blur'
            }
          ]"
        >
          <div class="title">Pro高中生别称*</div>
          <el-input placeholder="Pro高中生" v-model="enterpise.proHigh">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="checkList.indexOf('新通') > -1">
        <el-form-item
          prop="enterpise.xt"
          :rules="[
            {
              required: true,
              message: '新通',
              trigger: 'blur'
            }
          ]"
        >
          <div class="title">新通别称*</div>
          <el-input placeholder="新通" v-model="enterpise.xt">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="checkList.indexOf('保研人') > -1">
        <el-form-item
          prop="enterpise.byr"
          :rules="[
            {
              required: true,
              message: '保研人',
              trigger: 'blur'
            }
          ]"
        >
          <div class="title">保研人别称*</div>
          <el-input placeholder="保研人" v-model="enterpise.byr">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="checkList.indexOf('EWA') > -1">
        <el-form-item
          prop="enterpise.ewa"
          :rules="[
            {
              required: true,
              message: 'EWA',
              trigger: 'blur'
            }
          ]"
        >
          <div class="title">EWA别称*</div>
          <el-input placeholder="EWA" v-model="enterpise.ewa">
          </el-input>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="12" v-if="checkList.indexOf('Pro10课时') > -1">
        <el-form-item
          prop="enterpise.iap2"
          :rules="[
            {
              required: true,
              message: 'Pro10课时别称',
              trigger: 'blur'
            }
          ]"
        >
          <div class="title">Pro10课时*</div>
          <el-input placeholder="Pro10课时" v-model="enterpise.iap2">
          </el-input>
        </el-form-item>
      </el-col> -->
      <el-col :span="12" v-if="checkList.indexOf('XDF项目') > -1">
        <el-form-item prop="enterpise.xdf" :rules="[
          {
            required: true,
            message: 'XDF项目',
            trigger: 'blur'
          }
        ]">
          <div class="title">XDF项目*</div>
          <el-input placeholder="XDF项目" v-model="enterpise.xdf"> </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="topicList">
      <el-col v-if="list.length > 0" :span="12">
        <el-form-item>
          <div class="title">标签排序*</div>
          <div class="tips">项目标签<span>上移</span><span>下移</span></div>
          <div v-for="(item, index) in list" :key="item" class="item">
            <span>{{ getItem(item) }}</span>
            <i class="el-icon-top arrows" @click="top(index)" :class="index === 0 ? 'disabled' : ''" />
            <i class="el-icon-bottom arrows" @click="bottom(index)"
              :class="index === list.length - 1 ? 'disabled' : ''" />
          </div>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="list.length > 0">
        <el-form-item prop="enterpise.ignore">
          <div class="title">选择隐藏导师*</div>
          <div style="color:#c0c0c0">
            隐藏选题只会针对当前渠道，被隐藏的导师所有选题都不会对该渠道显示
          </div>
          <div class="flex border">
            <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
            <el-input v-model="teacherName" @input="searchTeacher" @focus="focused" @blur="selected" placeholder="导师姓名"
              class="seasub">
            </el-input>
            <el-button @click="confirmTeacher" :disabled="teacher === ''" type="primary">
              隐藏
            </el-button>
          </div>
          <el-tag :key="tag.id" v-for="tag in selects" closable :disable-transitions="true" @close="tagClose(tag)">
            {{ tag.name }}
          </el-tag>
          <el-table v-if="showList" :data="teachers" height="240" @row-click="selectTeacher" class="tabin">
            <el-table-column key="1" prop="name" label="导师姓名" align="center">
            </el-table-column>
            <el-table-column key="2" prop="organization" label="机构" align="center">
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios'
import { debounce } from 'lodash'
export default {
  data() {
    return {
      checkList: [],
      list: [],
      teacherName: '',
      teachers: [],
      showList: false,
      selects: [],
      teacher: ''
    }
  },
  props: {
    enterpise: Object
  },
  watch: {
    list(val) {
      this.enterpise.sort = val.join('')
    }
  },
  mounted() {
    let enterpise = this.enterpise
    let checkList = []
    if (enterpise.thesis !== '') {
      checkList.push('论文选题')
    }
    if (enterpise.research !== '') {
      checkList.push('科研项目')
    }
    if (enterpise.rt !== '') {
      checkList.push('科研+论文项目')
    }
    if (enterpise.academic !== '') {
      checkList.push('学术指导选题')
    }
    if (enterpise.epq !== '') {
      checkList.push('EPQ选题')
    }
    if (enterpise.iap !== '') {
      checkList.push('Pro')
    }
    if (enterpise.proHigh !== '') {
      checkList.push('Pro高中生')
    }
    if (enterpise.xt !== '') {
      checkList.push('新通')
    }
    if (enterpise.byr !== '') {
      checkList.push('保研人')
    }
    if (enterpise.ewa !== '') {
      checkList.push('EWA')
    }
    // if (enterpise.iap2 !== '') {
    //   checkList.push('Pro10课时')
    // }
    if (enterpise.lite !== '') {
      checkList.push('Lite项目')
    }
    if (enterpise.standard !== '') {
      checkList.push('Standard项目')
    }
    if (enterpise.xdf !== '') {
      checkList.push('XDF项目')
    }
    this.checkList = checkList
    this.list = this.enterpise.sort.split('')
    this.selects = this.enterpise.ignore
    this.enterpise.ignore = JSON.stringify(
      this.selects.map(item => {
        return item.id
      })
    )
  },
  methods: {
    selectTeacher(row) {
      let teacher = {
        id: row.id,
        name: row.name
      }
      if (this.selects.find(x => x.id === teacher.id)) {
        this.$message({
          message: '不能选择相同标签',
          type: 'warning'
        })
        return 0
      }
      this.teacher = teacher
      this.teacherName = row.name
    },
    confirmTeacher() {
      this.selects.push(this.teacher)
      this.teacher = ''
      this.teacherName = ''
      this.enterpise.ignore = JSON.stringify(
        this.selects.map(item => {
          return item.id
        })
      )
    },
    tagClose(tag) {
      this.selects.splice(this.selects.indexOf(tag), 1)
      this.enterpise.ignore = JSON.stringify(
        this.selects.map(item => {
          return item.id
        })
      )
    },
    searchTeacher: debounce(function () {
      axios
        .get(`/${this.$store.state.roleApi}/teachers`, {
          params: {
            name: this.teacherName
          }
        })
        .then(response => {
          this.teachers = response.data.teachers
        })
    }, 500),
    selected() {
      setTimeout(() => {
        this.showList = false
      }, 400)
    },
    focused() {
      this.searchTeacher()
      this.showList = true
    },
    getItem(item) {
      switch (item) {
        case 'A':
          return '论文项目'
        case 'B':
          return '科研项目'
        case 'C':
          return '科研+论文项目'
        case 'D':
          return '学术指导项目'
        case 'E':
          return 'EPQ项目'
        case 'F':
          return 'Pro'
        // case 'G':
        //   return 'Pro10课时'
        case 'H':
          return 'Lite项目'
        case 'I':
          return 'Standard项目'
        case 'J':
          return 'XDF项目'
        case 'K':
          return 'Pro高中生'
        case 'L':
          return '新通'
        case 'M':
          return '保研人'
        case 'N':
          return 'EWA'
      }
    },
    getCode(code) {
      switch (code) {
        case '论文选题':
          return 'A'
        case '科研项目':
          return 'B'
        case '科研+论文项目':
          return 'C'
        case '学术指导选题':
          return 'D'
        case 'EPQ选题':
          return 'E'
        case 'Pro':
          return 'F'
        // case 'Pro10课时':
        //   return 'G'
        case 'Lite项目':
          return 'H'
        case 'Standard项目':
          return 'I'
        case 'XDF项目':
          return 'J'
        case 'Pro高中生':
          return 'K'
        case '新通':
          return 'L'
        case '保研人':
          return 'M'
        case 'EWA':
          return 'N'
      }
    },
    top(index) {
      let top = this.list[index - 1]
      this.$set(this.list, index - 1, this.list[index])
      this.$set(this.list, index, top)
      this.$forceUpdate()
    },
    bottom(index) {
      let bottom = this.list[index + 1]
      this.$set(this.list, index + 1, this.list[index])
      this.$set(this.list, index, bottom)
      this.$forceUpdate()
    },
    changegroup(checked) {
      let list = [
        '论文选题',
        '科研项目',
        '科研+论文项目',
        '学术指导选题',
        'EPQ选题',
        'Lite项目',
        'Standard项目',
        'Pro',
        'Pro高中生',
        '新通',
        '保研人',
        'EWA',
        // 'Pro10课时',
        'XDF项目'
      ]
      let list2 = [
        'thesis',
        'research',
        'rt',
        'academic',
        'epq',
        'lite',
        'standard',
        'iap',
        'proHigh',
        'xt',
        'byr',
        'ewa',
        // 'iap2',
        'xdf'
      ]
      this.list = []
      list.map((val, index) => {
        if (checked.indexOf(val) > -1) {
          this.enterpise[list2[index]] = this.enterpise[list2[index]] || val
        } else this.enterpise[list2[index]] = ''
      })
      checked.map(val => {
        this.list.push(this.getCode(val))
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.topicList {
  .el-col:nth-child(odd) {
    padding-right: 10px;
  }

  .el-col:nth-child(even) {
    padding-left: 10px;
  }
}

.item {
  margin-top: 10px;
  color: #656d78;
}

.tips {
  font-size: 12px;
  color: #c0c0c0;

  span {
    position: absolute;
    left: 202px;
  }

  span+span {
    margin-left: 48px;
  }
}

.arrows {
  color: #656d78;
  border: 1px solid;
  border-radius: 20px;
  font-size: 16px;
  padding: 2px;
  cursor: pointer;
  position: absolute;
  left: 200px;

  &:hover {
    color: #a1d46a;
  }

  &+& {
    margin-left: 50px;
  }
}

.disabled {
  color: #c0c0c0;
  pointer-events: none;
}

.seasub {
  position: relative;
  bottom: 3px;
  padding-top: 0px;
  padding-left: 10px;
}

.border {
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 5px;
}

.tabin {
  font-size: 12px;
  color: #434a54;
  box-shadow: 0 2px 8px 0 rgba(204, 209, 217, 0.5);
  background-color: #f5f7fa;
}

/deep/.el-table tbody tr:hover>td {
  background-color: #a0d469;
  color: #ffffff;
}

.el-tag {
  height: 24px;
  font-size: 12px;
  border: #979797 1px solid;
  color: #979797;
  line-height: 22px;
  background-color: inherit;
  border-radius: 0;
  margin-top: 10px;
  margin-right: 5px;
}
</style>
