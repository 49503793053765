<template>
  <div class="listTest" ref="element">
    <div class="search flex">
      <div class="seaicon">
        <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
      </div>
      <el-input @input="search" v-model="stutitle" class="seasubfix"></el-input>
    </div>
    <div class="jstudent" v-if="loading">
      <i class="eliwise-loading"></i>加载中请稍后...
    </div>
    <list
      v-else
      ref="list"
      @next="getNext"
      class="jstudent"
      :data="list"
      :next="has_next"
      v-slot="{ item, index }"
    >
      <div
        @click="getstu1(item)"
        :class="{
          changeStyle: changeSelectStyle === item.id,
          'click-disable': !display && item[roleApi].id !== uid
        }"
        class="jstunum"
        :key="index"
      >
        <div class="studentname">{{ item.id }}.{{ item.abbreviation }}</div>
        <div class="touimg" v-if="display || item[roleApi].id == uid">
          <div class="img">
            <div class="circle" :class="getnumber(item.number)">
              {{ item.number }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="subcompany in item.subcompanys"
        :key="subcompany.id"
        class="subcompanys"
      >
        <div
          @click="getstu1(subcompany)"
          :class="{ changeStyle: changeSelectStyle === subcompany.id }"
          class="jstunum"
          :key="index"
        >
          <div class="studentname">
            {{ subcompany.id }}.{{ subcompany.abbreviation }}
          </div>
          <div class="touimg">
            <div class="circle" :class="getnumber(subcompany.number)">
              {{ subcompany.number }}
            </div>
          </div>
        </div>
      </div>
    </list>
  </div>
</template>

<script>
import axios from '../axios'
import list from './list'
import { debounce } from 'lodash'
export default {
  name: 'channelList',
  components: {
    list
  },
  data () {
    return {
      page: 1,
      has_next: true,
      stutitle: '',
      list: [],
      changeSelectStyle: 0,
      type: '',
      loading: false,
      roleApi: '',
      display: false,
      uid: 0
    }
  },
  props: {
    url: {
      type: String,
      required: true
    }
  },
  created () {
    this.roleApi = this.$store.state.roleApi
    if (this.$store.state.role === '市场总监') {
      this.display = true
    } else {
      this.uid = this.$store.state.userid
    }
  },
  mounted () {
    this.getteacher()
  },
  methods: {
    async getNext () {
      await Promise.all([
        axios
          .get(this.url, {
            params: {
              abbreviation: this.stutitle,
              page: this.page
            }
          })
          .then(response => {
            this.list.push(...response.data.res)
            this.has_next = response.data.has_next
          })
      ])
      this.page++
    },
    async getteacher () {
      await Promise.all([
        axios
          .get(this.url, {
            params: {
              abbreviation: this.stutitle,
              page: this.page
            }
          })
          .then(response => {
            this.list = response.data.res
            this.has_next = response.data.has_next
          })
      ])
      if (this.list.length !== 0) {
        this.getfirst()
      }
      this.page++
    },
    getstu1 (item) {
      if (
        this.display ||
        item[this.roleApi].id == this.uid ||
        !item.headoffice
      ) {
        this.changeSelectStyle = item.id
        this.$emit('select', item.id)
      }
    },
    getfirst () {
      let i = 0
      while (!this.display && this.list[i][this.roleApi].id !== this.uid) {
        i++
      }
      this.changeSelectStyle = this.list[i].id
      this.$emit('select', this.list[i].id)
    },
    getnumber (t_snum) {
      if (t_snum > 10) {
        return 's11'
      } else if (t_snum > 5 && t_snum <= 10) {
        return 's6'
      } else if (t_snum > 0 && t_snum <= 5) {
        return 's1'
      } else if (t_snum <= 0) {
        return 's0'
      }
    },
    search: debounce(function () {
      this.resize()
      this.getteacher()
    }, 500),
    resize () {
      this.$refs.list.$el.scrollTop = 0
      this.page = 1
      this.has_next = false
    }
  }
}
</script>
<style lang="scss" scoped>
.subcompanys {
  margin-left: 10%;
}
.click-disable {
  cursor: not-allowed !important;
}
.studentname {
  width: 200px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
