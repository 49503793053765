<template>
  <el-main class="main">
    <div class="flex">
      <studentList
        @select="getstu1"
        url="/cmanager/students/finished"
        :options="options"
      />
      <div class="neirong">
        <div class="jtitle">
          <h2 class="jtext">结项学员</h2>
        </div>
        <projectInfo :project="activeProject">
          <div slot="roles" class="rolesIcon">
            <img src="../../assets/roles/icon/导师.png" />
            <span>{{ activeProject.teacher || '待分配' }}</span>
            <img src="../../assets/roles/icon/助教.png" />
            <span>{{ activeProject.assistant || '待分配' }}</span>
            <img src="../../assets/roles/icon/规划师.png" />
            <span>{{ activeProject.planner }}</span>
            <img src="../../assets/roles/icon/客户经理.png" />
            <span>{{ activeProject.amanagerName || '无' }}</span>
          </div>
          <div slot="buttons">
            <informationButton :pid="pid"> </informationButton>
            <final-button :pid="pid"></final-button>
            <el-tooltip placement="top" effect="light" content="导出辅导记录">
              <el-button type="primary" @click="exportI = true" size="mini">
                <i class="el-icon-download" style="font-size:22px"></i>
              </el-button>
            </el-tooltip>
          </div>
          <div slot="stages">
            <div v-if="filegroups_output.length > 0" class="output">
              <div class="outtitle">
                项目产出：
              </div>
              <div class="outcontent">
                <div
                  class="outdoc"
                  v-for="out in filegroups_output"
                  :key="out.id"
                >
                  <a href="javascript:void(0);" @click="download(out.url,out.name)">{{ out.name }}</a>
                  <span>({{ Math.ceil(out.size / 1024) }}KB)</span>
                </div>
              </div>
            </div>
            <div v-if="filegroups_feed.length > 0" class="feed">
              <div class="feedtitle">学生反馈：</div>
              <div class="feedcontent">
                <div
                  class="feeddoc"
                  v-for="feed in filegroups_feed"
                  :key="feed.id"
                >
                  <a href="javascript:void(0);" @click="download(feed.url,feed.name)">{{ feed.name }}</a>
                  <span>({{ Math.ceil(feed.size / 1024) }}KB)</span>
                </div>
              </div>
            </div>
            <div v-if="filegroups_abfeed.length > 0" class="feed">
              <div class="feedtitle">异常结项凭证：</div>
              <div class="feedcontent">
                <div
                  class="feeddoc"
                  v-for="abfeed in filegroups_abfeed"
                  :key="abfeed.id"
                >
                  <a href="javascript:void(0);" @click="download(abfeed.url,abfeed.name)">{{ abfeed.name }}</a>
                  <span>({{ Math.ceil(abfeed.size / 1024) }}KB)</span>
                </div>
              </div>
            </div>
            <div v-if="filegroups_nosign.length > 0" class="feed">
              <div class="feedtitle">不签约原因：</div>
              <div class="feedcontent">
                <div
                  class="feeddoc"
                  v-for="nosign in filegroups_nosign"
                  :key="nosign.id"
                >
                  <a href="javascript:void(0);" @click="download(nosign.url,nosign.name)">{{ nosign.name }}</a>
                  <span>({{ Math.ceil(nosign.size / 1024) }}KB)</span>
                </div>
              </div>
            </div>
            <stages
              :tableData="tableData"
              :project="activeProject"
              :interview="interview"
            ></stages>
          </div>
        </projectInfo>
      </div>
    </div>
    <export-img
      v-if="exportI"
      :smes="smes"
      :tableData="tableData"
      :project="activeProject"
      :interview="interview"
    ></export-img>
    <suggestRead :project="activeProject"></suggestRead>
  </el-main>
</template>

<script>
import axios from '../axios'
import studentList from '../pub/studentList'
import projectInfo from '../pub/projectInfo'
import stages from '../pub/stages'
import informationButton from '../pub/buttons/informationButton'
import finalButton from '../pub/buttons/finalButton'
import exportImg from '../pub/exportImg'
import suggestRead from '../pub/suggestRead'
export default {
  name: 'Dstudent',
  components: {
    studentList,
    projectInfo,
    stages,
    exportImg,
    finalButton,
    informationButton,
    suggestRead
  },
  data () {
    return {
      smes: {
        name: '',
        school: '',
        cellphone: '',
        email: '',
        direction: '',
        sex: ''
      },
      tableData: [],
      options: ['学员', '渠道', '项目', '方向', '专业'],
      interview: {},
      project_type: '',
      teacher: '',
      pid: 0,
      activeProject: {},
      filegroups_abfeed: [],
      filegroups_output: [],
      filegroups_feed: [],
      filegroups_nosign: [],
      exportI: false
    }
  },
  methods: {
    getstu1 (pid = false) {
      let id = 0
      if (pid) {
        id = pid
      } else {
        id = this.pid
      }
      axios
        .get(`/${this.$store.state.roleApi}/project/${id}/`)
        .then(response => {
          this.smes = response.data.student
          this.teacher = response.data.teacher.name
          this.project_type = response.data.project.project_type
          this.interview = response.data.interview[0]
          this.pid = response.data.project.id
          this.tableData = response.data.stages
          this.abnormal = response.data.project.abnormal
          this.filegroups_output = response.data.project.filegroups_output
          this.filegroups_feed = response.data.project.filegroups_feed
          this.filegroups_abfeed = response.data.project.filegroups_abfeed
          this.filegroups_nosign = response.data.project.filegroups_nosign
          this.activeProject = {
            ...response.data.project,
            salesman: response.data.sales,
            sponser: response.data.support,
            amanager: response.data.amanager,
            cmanager: response.data.cmanager
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    download (fileUrl,name) {
      let x = new XMLHttpRequest()
      x.open('GET', fileUrl, true)
      x.responseType = 'blob'
      x.onload = function (e) {
        let url = window.URL.createObjectURL(x.response)
        let a = document.createElement('a')
        a.href = url
        a.download = name
        a.click()
      }
      x.send()
    }
  }
}
</script>
