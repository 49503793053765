<template>
  <el-container class="container">
    <el-aside>
      <div class="aside">
        <changerole></changerole>
        <div style="height: 80%"></div>
        <div class="back">
          <img
            src="../../assets/icon/退出.png"
            @click="logout"
            height="20px"
            width="20px"
          />
        </div>
      </div>
    </el-aside>
    <el-main class="main">
      <div class="jtitle">
        <h2 class="jtext">投稿选会</h2>
      </div>
      <div class="search flex">
        <div class="seaicon">
          <img src="@/assets/icon/搜索@3x.png" height="20" width="20" />
        </div>
        <el-input
          v-model="studentname"
          @input="search"
          class="seasubfix"
        ></el-input>
      </div>
      <el-table
        :data="conferences.items"
        ref="tableScroll"
        :height="tableHeight"
      >
        <el-table-column
          prop="id"
          key="id"
          label="编号"
          min-width="5%"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          key="name"
          label="学生姓名"
          min-width="7%"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="project_type"
          key="type"
          label="项目类型"
          min-width="10%"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sponsor"
          key="sponser"
          label="规划师"
          min-width="7%"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="teacher"
          key="teacher"
          label="导师姓名"
          min-width="7%"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="paper_title"
          label="论文题目"
          min-width="20%"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="url"
          key="url"
          label="官网链接"
          min-width="12%"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="final_memo"
          label="终稿备注"
          min-width="20%"
          align="center"
        ></el-table-column>
        <el-table-column key="查看论文" min-width="8%" align="center">
          <template slot-scope="scope">
            <el-button type="primary" @click="getfile(scope.$index, scope.row)">
              查看论文
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          key="修改"
          min-width="8%"
          align="center"
          #default="{ $index, row }"
        >
          <el-button
            type="primary"
            @click="handleShowChooseDialog($index, row)"
          >
            {{ !row.url ? row.project_type==='Lite'?'添加期刊' : '添加会议':row.project_type==='Lite'?'修改期刊' : '修改会议' }}
          </el-button>
        </el-table-column>
        <el-table-column
          key="辅导记录"
          min-width="8%"
          align="center"
          #default="{ row }"
        >
          <el-button type="primary" @click="handleShowRecord(row.pid)">
            辅导记录
          </el-button>
        </el-table-column>
        <div slot="append" style="text-align: center; font-size: 25px">
          <i class="eliwise-loading" v-if="has_next"></i>
          <i v-else style="font-size: 16px">--没有数据了--</i>
        </div>
      </el-table>
      <el-dialog
        :title="fileTitle"
        :visible.sync="fileDialog"
        width="1090px"
        class="mianDialog mian-upload"
        :close-on-click-modal="true"
      >
        <el-table :data="fileTable" style="width: 100%">
          <el-table-column
            prop="paper_title"
            label="论文题目"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="created" label="上传日期" align="center">
            <template slot-scope="scope">
              <div>{{ formatDate(scope.row.created) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="上传时间" align="center">
            <template slot-scope="scope">
              <div>{{ formatTime(scope.row.created) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="updater.name" label="上传者" align="center">
          </el-table-column>

          <el-table-column align="center">
            <template slot-scope="scope">
              <div class="download" @click="download(scope.row)">
                <img src="../../assets/icon/下载.png" />
              </div>
            </template>
          </el-table-column>
        </el-table>

        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取消</el-button>
        </span>
      </el-dialog>
      <ShowPaperDialog ref="paperDialog" />
      <EditeDialog :project_id="project_id" ref="EditorDialog" />
      <RecordDialog ref="RecordDialog" />
    </el-main>
  </el-container>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import axios from '../axios'
import ShowPaperDialog from '@/components/pub/finalDialog.vue'
import EditeDialog from './EditeDialog.vue'
import { debounce } from 'lodash'
import { MessageBox } from 'element-ui'
import changerole from '../pub/changerole'
import RecordDialog from './RecordDialog.vue'
import moment from 'moment'

export default {
  name: 'Clist',
  components: {
    ShowPaperDialog,
    EditeDialog,
    RecordDialog,
    changerole
  },
  data () {
    return {
      inputurl: '',
      addMeet: false,
      radio: '',
      rules: {
        ypassword: [
          { required: true, message: '请填写原密码', trigger: 'blur' }
        ],
        npassword: [
          { required: true, message: '请填写新密码', trigger: 'blur' }
        ],
        rpassword: [
          { required: true, message: '请再次填写新密码', trigger: 'blur' }
        ]
      },
      ruleform: {
        ypassword: '',
        npassword: '',
        rpassword: ''
      },
      passwordVisible: false,
      fileTable: [],
      fileTitle: '查看论文',
      fileDialog: false,
      option: [
        {
          value: '1',
          label: '投稿选会'
        },
        {
          value: '2',
          label: '导师'
        },
        {
          value: '3',
          label: '修改密码'
        }
      ],
      value: '投稿选会',
      conferences: {
        items: []
      },
      currentPage: 1,
      request: null,
      studentname: '',
      api: 'courier',
      has_next: false,
      pagenumber: 1,
      dom: null,
      tableHeight: window.innerHeight - 155,
      urlless: 0,
      project_id: '',
      cid: ''
    }
  },
  methods: {
    formatDate (data) {
      return moment(data * 1000).format('YYYY-MM-DD')
    },
    formatTime (data) {
      return moment(data * 1000).format('HH:mm')
    },

    getfile (index, row) {
      axios.get(`/courier/project/${row.pid}/finalPapers`).then(res => {
        this.fileTable = res.data.papers[`${row.pid}`]
      })
      this.fileDialog = true
    },
    cancel () {
      this.passwordVisible = false
      this.fileDialog = false
    },
    search: debounce(function () {
      const CancelToken = axios.CancelToken
      this.pagenumber = 1
      if (this.request !== null) {
        this.request.cancel()
      }
      this.request = CancelToken.source()

      axios
        .get(`/${this.api}/students`, {
          params: {
            name: this.studentname
          },
          cancelToken: this.request.token
        })
        .then(response => {
          this.conferences.items = response.data.students
          this.has_next = response.data.has_next
        })
    }, 1000),
    handleShowRecord (projectId) {
      this.$refs.RecordDialog.openDialog(projectId)
    },
    handleShowChooseDialog (index, pdata) {
      if (pdata.conf_id !== '') {
        pdata.conf_id = parseInt(pdata.conf_id)
      }
      this.project_id = pdata.pid
      this.$refs.EditorDialog.openDialog(pdata).then(data => {
        this.$set(this.conferences.items[index], 'url', data.url)
        this.$set(this.conferences.items[index], 'misc', data.misc)
        this.$set(this.conferences.items[index], 'conf_id', data.conf_id)
        this.$set(
          this.conferences.items[index],
          'project_type',
          data.project_type
        )
        this.$set(this.conferences.items[index], 'teacher', data.teacher)
        this.$set(this.conferences.items[index], 'name', data.name)
      })
    },
    handleShowPaper (projectId) {
      this.$refs.paperDialog.openDialog(projectId)
    },
    logout () {
      MessageBox.confirm('是否注销？')
        .then(() => {
          axios.delete('/logout/').then(() => {
            localStorage.removeItem('Authorization')
            this.$router.replace('/login')
          })
        })
        .catch(() => {})
    },
    a () {
      let scrollTop2 = this.dom.scrollTop
      let windowHeight2 = this.dom.clientHeight || this.dom.clientHeight
      let scrollHeight2 = this.dom.scrollHeight || this.dom.scrollHeight

      if (scrollTop2 + windowHeight2 >= scrollHeight2 && this.has_next) {
        this.pagenumber = this.pagenumber + 1
        axios
          .get(`${this.api}/students`, {
            params: {
              name: this.studentname,
              page: this.pagenumber
            }
          })
          .then(response => {
            if (response.data.has_previous) {
              this.conferences.items = this.conferences.items.concat(
                response.data.students
              )
            }
            this.has_next = response.data.has_next
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    dom2 () {
      this.dom = this.$refs.tableScroll.bodyWrapper
      this.dom.addEventListener('scroll', this.a)
    },
    getUrllessNum () {
      axios.get('/courier/contribute/count').then(({ data }) => {
        this.urlless = data.count
      })
    },
    download (row) {
      let x = new XMLHttpRequest()
      x.open('GET', row.url, true)
      x.responseType = 'blob'
      x.onload = function (e) {
        let url = window.URL.createObjectURL(x.response)
        let a = document.createElement('a')
        a.href = url
        a.download = row.name
        a.click()
      }
      x.send()
    }
  },
  beforeCreate () {
    axios.get('/is_login/').then(response => {
      if (response.data.roles.indexOf(this.$store.state.role) > -1) {
        this.$store.commit('setLogin', response.data)
      } else {
        MessageBox.alert('未登录，请重新登录')
        localStorage.removeItem('Authorization')
        this.$router.push('/login')
      }
    })
  },
  mounted () {
    this.getUrllessNum()
    axios
      .get(`/courier/students`, {
        page: 1
      })
      .then(response => {
        this.conferences.items = response.data.students
        this.has_next = response.data.has_next
        const that = this
        ;(window.onresize = () => {
          return (() => {
            that.tableHeight = window.innerHeight - 150
          })()
        }),
          this.dom2()
      })
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-table .cell {
  padding: 0px 5px;
}
</style>
