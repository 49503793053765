<template>
  <el-dialog
    :visible.sync="active"
    width="72%"
    :close-on-click-modal="false"
    :close-on-press-escape="true"
    :before-close="close"
    destroy-on-close
    style="max-height: 90vh"
    title="修改项目信息"
    custom-class="changeScroll"
  >
    <projectform
      :project="project"
      :isNew="false"
      :channelName="channelName"
      :uploadLock.sync="uploadLock"
      ref="projectform"
    />
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-popover
        ref="popover"
        placement="top"
        width="100"
        trigger="hover"
        content="附件未上传完毕"
        :disabled="!uploadLock"
      >
      </el-popover>
      <span v-popover:popover>
        <el-button
          type="primary"
          :disabled="pending || uploadLock"
          @click="pushProject"
          style="margin-left:10px"
        >
          修 改
        </el-button>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import projectform from './projectform'
import axios from '../../axios'
import { MessageBox } from 'element-ui'
import { mapState } from 'vuex'
export default {
  name: 'editProject',
  data () {
    return {
      project: {
        id: '',
        project_type: '',
        channel_type: 'inst', // 0渠道，1顾问
        channel: NaN, // channel对应的id
        misc: '', // 备注
        sponser: '', // 支持
        connectTime: '', // 可联系时间
        startTime: '', // 意向开始时间 string
        projectName: '', // 项目名称
        attachments: [], // 附件
        isPacked: false,
        amanager: '',
        cmanager: '',
        adviser: '',
        project_types: []
      },
      project_temp: '',
      active: false,
      channelName: '',
      pending: false,
      uploadLock: false
    }
  },
  watch: {
    'project.channel': function (newval, oldval) {
      if (
        (typeof newval === 'number' || typeof newval === 'undefined') &&
        typeof oldval === 'object'
      ) {
        this.project_temp = JSON.stringify(this.project)
      }
    }
  },
  components: {
    projectform
  },
  computed: {
    ...mapState({
      sales: 'sales',
      self: 'username',
      roles: 'roles'
    })
  },
  methods: {
    openDialog (project) {
      this.project = {
        ...this.$options.data().project,
        ...project,
        channel_type: project.channel_type || 'inst'
      }
      this.project_temp = JSON.stringify(this.project)
      this.active = true
      this.$nextTick(() => {
        this.$refs.projectform.get_advisers(this.project.channel)
      })
    },
    pushProject () {
      let that = this
      const projectToSend = { ...this.project }
      this.$refs.projectform.check(function (valid, props) {
        if (valid) {
          if (that.project_temp === JSON.stringify(that.project)) {
            that.close()
          } else {
            MessageBox.confirm('是否更改此项目状态')
              .then(() => {
                that.pending = true
                projectToSend.attachments = projectToSend.attachments.map(
                  attachment =>
                    typeof attachment === 'string' ? attachment : attachment.id
                )
                axios
                  .put(
                    `/${that.$store.state.roleApi}/project/${that.project.id}/`,
                    projectToSend
                  )
                  .then(response => {
                    if (response.status === 200) {
                      that.$message({
                        showClose: true,
                        message: `修改成功`,
                        type: 'success'
                      })
                      setTimeout(() => {
                        window.location.reload()
                      }, 1000)
                    }
                  })
                  .catch(error => {
                    console.log(error)
                  })
              })
              .catch(e => {
                console.error(e)
              })
          }
        } else {
          // console.log(that.$refs)
          const base = that.$refs.projectform.$children[0].$el.getBoundingClientRect()
            .top
          for (const validElement of that.$refs.projectform.$children[0]
            .fields) {
            // console.log(validElement.$el._prevClass)
            const isError =
              validElement.$el._prevClass.indexOf('is-error') !== -1
            const isSuccess =
              validElement.$el._prevClass.indexOf('is-success') !== -1
            const isRequire =
              validElement.$el._prevClass.indexOf('is-require') !== -1
            if ((isRequire && !isError && !isSuccess) || isError) {
              // console.log(base, validElement.$el.getBoundingClientRect().top, document.getElementsByClassName('dialogScroll'))
              document.getElementsByClassName('changeScroll')[0].scrollTop =
                validElement.$el.getBoundingClientRect().top - base
              // document.getElementsByClassName('dialogScroll')[0].scrollTop = 50
              break
            }
          }
          return false
        }
      })
    },
    close () {
      if (this.project_temp !== JSON.stringify(this.project)) {
        MessageBox.confirm('是否放弃更改内容').then(() => {
          this.active = false
          this.project = {
            ...this.$options.data().project
          }
          this.pending = false
        })
      } else {
        this.active = false
        this.project = {
          ...this.$options.data().project
        }
        this.pending = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
