<template>
  <el-main class="main">
    <div class="flex">
      <div class="listTest" ref="element">
        <div class="search flex">
          <div class="seaicon">
            <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
          </div>
          <el-input @input="search" v-model="stutitle" class="seasubfix">
          </el-input>
        </div>
        <div class="xing">
          <el-row>
            <el-col class="star">
              <el-rate
                style="text-align: left"
                v-model="star"
                disabled
                :colors="colors"
                disabled-void-color="#c0c0c0"
                score-template="{value}"
              >
              </el-rate>
            </el-col>
          </el-row>
        </div>
        <div class="jstudent" ref="viewBox">
          <div
            @click="getstu(index)"
            :class="{ changeStyle: changeSelectStyle === index }"
            class="jstunum"
            v-for="(item, index) in finlist"
            :key="index"
          >
            <div class="studentname">
              <span>{{ item.id }}.{{ item.name }}</span>
              <img
                src="../../assets/icon/组合项目.png"
                class="zhimg"
                alt=""
                v-if="item.ispacked === true"
              />
            </div>
          </div>
          <div v-if="has_next">
            <i class="eliwise-loading"></i>加载中请稍后...
          </div>
        </div>
      </div>
      <div class="neirong">
        <div class="jtitle">
          <h2 class="jtext">结项学员</h2>
        </div>
        <projectInfo :project="activeProject">
          <div slot="roles" class="rolesIcon">
            <img src="../../assets/roles/icon/导师.png" />
            <span>{{ activeProject.teacher || '待分配' }}</span>
            <img src="../../assets/roles/icon/助教.png" />
            <span>{{ activeProject.assistant || '待分配' }}</span>
          </div>
          <stages :project="activeProject" :interview="interview" slot="stages">
            <template slot-scope="item">
              <el-table
                :data="tableData[item.index].tasks"
                :span-method="arraySpanMethod"
                v-if="
                  tableData[item.index] &&
                    tableData[item.index].tasks.length > 0
                "
              >
                <el-table-column align="center" width="30">
                  <template slot-scope="{ row }">
                    <div class="img">
                      <img
                        src="../../assets/icon/惊叹号.png"
                        v-if="row.type === 'status'"
                      />
                      <img
                        src="../../assets/icon/备注.png"
                        v-else-if="row.type === 'remark'"
                      />
                      <img
                        src="../../assets/icon/结项.png"
                        v-else-if="row.type === 'end'"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="date"
                  label="日期"
                  align="center"
                  min-width="12%"
                >
                  <template slot-scope="{ row }">
                    {{
                      row.type === 'nextMission' ||
                      row.type === 'teacher_evaluate'
                        ? ''
                        : row.date
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="teacher_work"
                  label="导师辅导内容"
                  align="left"
                  header-align="center"
                  min-width="30%"
                >
                  <template slot-scope="{ row }">
                    <div v-if="row.type === 'status'">
                      {{ row.status }} —— {{ row.creator }}
                    </div>
                    <div v-else-if="row.type === 'nextMission'">
                      预计下次辅导内容：{{ row.mission }}
                    </div>
                    <div v-else-if="row.type === 'remark'">
                      <span v-if="row.first">【学生辅导感受】</span>{{ row.remark }}
                    </div>
                    <div v-else-if="row.type === 'end'">
                      <p
                        v-for="item in row.chosen"
                        :key="item"
                        style="display: inline"
                      >
                        {{ item }}，
                      </p>
                      <br />
                      {{ row.written }}
                    </div>
                    <div v-else-if="row.type === 'task'">
                      <div>{{ row.teacher_work }}</div>
                    </div>
                    <div v-else-if="row.type === 'teacher_evaluate'">
                      <div>【学生评价】:{{ row.teacher_evaluate }}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="work_time"
                  label="辅导时间"
                  align="center"
                  min-width="11%"
                  :formatter="timeFormatter"
                >
                </el-table-column>
                <el-table-column
                  prop="student_work"
                  label="学生任务"
                  align="center"
                  min-width="30%"
                >
                </el-table-column>
                <el-table-column
                  prop="deadline"
                  label="Deadline"
                  align="center"
                  min-width="13%"
                >
                </el-table-column>
                <el-table-column
                  label="确认辅导内容"
                  align="center"
                  min-width="10%"
                >
                  <template slot-scope="scope">
                    <div v-if="scope.row.isconfirm === true">
                      <img class="isok" src="../../assets/icon/isok.png" />
                    </div>
                    <div v-else-if="scope.row.feedbacks === undefined"></div>
                    <el-popover
                      v-else-if="scope.row.feedbacks.length !== 0"
                      placement="top-start"
                      width="536px"
                      trigger="hover"
                    >
                      <div class="feedwrap">
                        <div
                          class="feedcontainer"
                          v-for="(item, index) in scope.row.feedbacks"
                          :key="index"
                        >
                          <div class="feedleft">{{ item.date }}</div>
                          <div class="feedright">
                            <span class="feeds">【疑问反馈】</span>
                            <p class="feedp">{{ item.content }}</p>
                          </div>
                        </div>
                      </div>
                      <img
                        class="feedimg"
                        slot="reference"
                        src="../../assets/icon/feedbacks.png"
                      />
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column align="center" min-width="5%"
                  ><template slot-scope="scope">
                    <el-button
                      class="edit listedit"
                      @click="missionEdit(scope.$index, scope.row)"
                      v-if="scope.row.type != 'end'"
                    >
                      <i class="el-icon-edit"></i>
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column align="center" min-width="5%">
                  <template slot-scope="scope">
                    <el-button
                      class="delete listedit"
                      @click="missionDelete(scope.$index, scope.row)"
                      v-if="scope.row.type != 'end'"
                    >
                      <i class="el-icon-delete"></i>
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </stages>
        </projectInfo>
      </div>
    </div>
  </el-main>
</template>

<script>
/* eslint-disable no-console */
import axios from '../axios'
import _ from 'lodash'
import projectInfo from '../pub/projectInfo'
import stages from './stages'
import informationButton from '../pub/buttons/informationButton'
import finalButton from '../pub/buttons/finalButton'
import suggestRead from '../pub/suggestRead'
export default {
  name: 'tfinish',
  components: {
    projectInfo,
    stages,
    informationButton,
    finalButton,
    suggestRead
  },
  data () {
    return {
      tableData: [],
      colors: {
        2: '#A0D469',
        4: { value: '#A0D469', excluded: true },
        5: '#A0D469'
      },
      star: 0,
      changeSelectStyle: '',
      finlist: [],
      stutitle: '',
      interview: undefined,
      pid: 0,
      pagenumber: 1,
      has_next: true,
      activeProject: {}
    }
  },

  created () {
    this.getfinish()
  },
  mounted () {
    this.dom = this.$refs.viewBox
    this.dom.addEventListener('scroll', () => {
      let scrollTop = this.dom.scrollTop
      let windowHeight = this.dom.clientHeight || this.dom.clientHeight
      let scrollHeight = this.dom.scrollHeight || this.dom.scrollHeight
      if (scrollTop + windowHeight >= scrollHeight && this.has_next) {
        this.pagenumber = this.pagenumber + 1
        axios
          .get('/teacher/finished', {
            params: {
              s_name: this.stutitle,
              page: this.pagenumber
            }
          })
          .then(response => {
            if (response.data.has_previous) {
              this.finlist = this.finlist.concat(response.data.students)
            }
            this.has_next = response.data.has_next
          })
      }
    })
  },
  methods: {
    timeFormatter (row) {
      const ttime = row.work_time
      if (ttime) {
        if (ttime > 3) return `${ttime / 4}h`
        else return `${ttime * 15}min`
      }
      return ''
    },
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      const item = row
      const status = item.status
      const nextmission = item.mission
      const teacher_evaluate = item.teacher_evaluate
      const teacher_work = item.teacher_work
      const remark = item.remark
      const written = item.written
      if (!!teacher_work && columnIndex === 2) return [1, 1]
      if (!!teacher_work && columnIndex >= 3 && columnIndex <= 8) return [1, 1]
      if (!!written && columnIndex === 2) return [1, 6]
      if (!!nextmission && columnIndex === 2) return [1, 6]
      if (!!status && columnIndex === 2) return [1, 5]
      if (!!teacher_evaluate && columnIndex === 2) return [1, 6]
      if (!!remark && columnIndex === 2) return [1, 5]
      if (!!written && columnIndex >= 3 && columnIndex <= 8) return [0, 0]
      if (!!nextmission && columnIndex >= 3 && columnIndex <= 8) return [0, 0]
      if (!!status && columnIndex >= 3 && columnIndex <= 6) return [0, 0]
      if (!!teacher_evaluate && columnIndex >= 3 && columnIndex <= 8) {
        return [0, 0]
      }
      if (!!remark && columnIndex >= 3 && columnIndex <= 6) return [0, 0]
    },
    search: _.debounce(function () {
      this.pagenumber = 1
      this.dom.scrollTop = 0
      this.has_next = true
      axios
        .get('/teacher/finished', {
          params: {
            s_name: this.stutitle,
            page: this.pagenumber
          }
        })
        .then(response => {
          this.finlist = response.data.students
          this.has_next = response.data.has_next
          this.getfirst()
        })
        .catch(error => {
          console.log(error)
        })
    }, 500),
    getstu (index) {
      this.changeSelectStyle = index
      axios
        .get(`/teacher/project/${this.finlist[index].project_id}/`)
        .then(response => {
          this.pid = response.data.project.id
          this.interview = response.data.interview[0]
          this.tableData = response.data.stages
          this.activeProject = response.data.project
        })
        .catch(error => {
          console.log(error)
        })
    },
    getfirst () {
      this.changeSelectStyle = 0
      axios
        .get(`/teacher/project/${this.finlist[0].project_id}/`)
        .then(response => {
          this.pid = response.data.project.id
          this.interview = response.data.interview[0]
          this.tableData = response.data.stages
          this.activeProject = response.data.project
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getfinish () {
      await Promise.all([
        axios
          .get('/teacher/')
          .then(response => {
            this.star = response.data.star
          })
          .catch(error => {
            console.log(error)
          }),
        axios
          .get('/teacher/finished', {
            params: {
              s_name: this.stutitle
            }
          })
          .then(response => {
            this.finlist = response.data.students
            this.has_next = response.data.has_next
          })
          .catch(error => {
            console.log(error)
          })
      ])
      if (this.finlist.length !== 0) {
        this.getfirst()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.img img {
  height: 20px;
  vertical-align: top;
}
</style>
