<template>
  <el-main class="main">
    <div
      style="height: 100vh; overflow: scroll"
      @scroll="handleScroll"
      ref="scrollWra"
    >
      <div class="loadingS" v-if="show > 0">
        <div>向上滚动加载报名的上一个项目</div>
      </div>
      <div class="mistitle">
        <h2>学员进度</h2>
      </div>
      <div
        class="wrapper"
        v-if="loaded && show === index"
        v-for="(item, index) in resData"
        :key="index"
      >
        <div class="topTitle">
          <el-row class="row1">
            <el-col :span="24" class="title">
              课题方向：{{ item.projectName }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" class="iconAndName">
              <img
                src="../../assets/roles/icon/导师.png"
                alt=""
                style="width: 30px; height: 30px; margin-left: 5px"
              />
              <div>{{ item.name }}老师</div>
            </el-col>
            <el-col :span="6" class="iconAndName">
              <img
                src="../../assets/icon/学校@3x.png"
                alt=""
                style="width: auto; height: 18px"
              />
              <div>{{ item.misc }}</div>
            </el-col>
            <el-col :span="6" class="iconAndName">
              <img
                src="../../assets/icon/邮件@3x.png"
                alt=""
                style="width: auto; height: 18px"
              />
              <div>{{ item.inst_email }}</div>
            </el-col>
          </el-row>
          <el-row class="row2">
            <el-col
              :span="20"
              class="progress"
              style="display: flex; align-items: center"
            >
              <el-progress
                type="circle"
                :percentage="percent(item).percent"
                :width="50"
                color="#ABEC88"
              ></el-progress>
              <div>
                {{ percent(item).tip }}
              </div>
            </el-col>
            <el-col :span="4" class="buttons">
              <div class="filedoc">
                <el-button
                  type="primary"
                  @click="isupload"
                  class="minbutton upload"
                  v-if="item.status === 1"
                  >上传文件</el-button
                >
                <el-button
                  type="primary"
                  class="minbutton upload"
                  style="margin-right: 10px"
                  @click="uploadOpen"
                >
                  查看文件
                </el-button>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="stagesWrapper">
          <stages
            :tableData="item.stages"
            :stages="stagesList[stageType[item.type] || '基础']"
            :active="active(item)"
          ></stages>
        </div>
        <div class="loadingS" v-if="show < resData.length - 1">
          <div>向下滚动加载报名的下一个项目</div>
        </div>
      </div>
      <el-dialog title="查看文件" :visible.sync="docVisible" width="1050px">
        <el-table :data="docData" style="width: 100%" max-height="500">
          <el-table-column
            prop="paper_type"
            aligen="center"
            label="文件类型"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="paper_name"
            aligen="center"
            label="文件名"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="updater.date" label="上传日期" align="center">
            <template slot-scope="scope">
              <div>{{ formatDate(scope.row.updater.date) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="上传时间" align="center">
            <template slot-scope="scope">
              <div>{{ formatTime(scope.row.updater.date) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="updater.name" label="上传者" align="center">
          </el-table-column>
          <el-table-column label="确认为终稿" align="center">
            <template slot-scope="scope">
              <p v-show="scope.row.isfinal">
                <img class="isok" src="../../assets/icon/isok.png" />
              </p>
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template slot-scope="scope">
              <a href="javascript:void(0);" @click="download(scope.row)">
                <img
                src="../../assets/icon/下载.png"
              /></a>
              <img
                style="
                  padding: 2px 0 0 20px;
                  position: relative;
                  top: 2px;
                  cursor: pointer;
                "
                v-if="scope.row.updater.is_delete"
                src="../../assets/icon/删除.png"
                @click="delect(scope.$index, scope.row)"
              />
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="docVisible = false">返回</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :title="uploadTitle"
        :visible.sync="uploadDialog"
        width="680px"
        class="mianDialog mian-upload"
        :close-on-click-modal="true"
      >
        <p style="text-align: left">上传文件*</p>
        <p class="uploadNotice">支持同时上传多个文件</p>
        <el-upload
          class="upload-demo"
          :action="uploadUrl"
          :on-remove="onRemove"
          :limit="10"
          multiple
          drag
          accept=".jpg,.jpeg,.png,.doc,.docx,.pptx,.pps,.ppsx,.ppt,.pdf,.JPG,.JPEG,.PNG,.DOC,.DOCX,.PPTX,.PPS,.PPSX,.PPT,.PDF,.xlsx,.xls,.RAR,.ZIP,.caj"
          :on-exceed="handleExceed"
          :on-success="handleSuccess"
          :on-error="handleError"
          :beforeUpload="beforeFileUpload"
          :file-list="files"
          :disabled="uploadable"
          ref="upload"
        >
          <!--          <i class="el-icon-upload"></i>-->
          <div class="el-upload__text">
            将文件拖到此处，或
            <em style="color: #8dc152; text-decoration: underline">
              点击上传
            </em>
          </div>
        </el-upload>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="finish" :disabled="buttonDisabled"
            >确认</el-button
          >
        </span>
      </el-dialog>
    </div>
    <el-dialog
      :visible.sync="sureVisible"
      width="936px"
      :showClose="false"
      :close-on-click-modal="false"
    >
      <div class="surecontainer">
        <div class="sureone" v-if="pastlist.length !== 0">
          <div class="suretitle">往期反馈追踪</div>
          <div class="suretip">
            <p class="surefont">请确认辅导中的问题是否已解决</p>
            <el-button class="giveo" @click="oneok">一键确认</el-button>
          </div>
          <el-table
            ref="table1"
            class="tab"
            :data="pastlist"
            row-key="task_id"
            style="width: 100%"
            :span-method="arraySpanMethod"
            :default-sort="{ prop: 'data', order: 'descending' }"
          >
            <el-table-column
              prop="date"
              align="center"
              label="日期"
              width="85px"
            >
              <template slot-scope="{ row }">
                <div>{{ row.date }}</div>
                <div v-for="(item, index) in row.feedbacks" :key="index">
                  {{ item.date }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="teacher_work"
              align="center"
              label="导师辅导内容"
              width="230px"
            >
              <template slot-scope="{ row }">
                <div>{{ row.teacher_work }}</div>
                <div v-for="(item, index) in row.feedbacks" :key="index">
                  【疑问反馈】{{ item.content }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="student_work"
              align="center"
              label="学生辅导内容"
              width="230px"
            ></el-table-column>
            <el-table-column
              prop="teacher"
              align="center"
              label="导师"
              width="65px"
            ></el-table-column>
            <el-table-column
              label="确认辅导内容"
              align="center"
              min-width="109px"
            >
              <template slot-scope="scope">
                <el-button class="nobor" @click="toogleExpand1(scope.row)"
                  >未解决</el-button
                >
              </template>
            </el-table-column>
            <el-table-column align="center" min-width="105px">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  ref="btn2"
                  :class="scope.row.isconfirm ? 'isconfirm' : ''"
                  @click="makesure(scope.$index, scope.row)"
                  >解决</el-button
                >
              </template>
            </el-table-column>
            <el-table-column type="expand" width="1" v-if="showfk === true">
              <template slot-scope="scope">
                <div class="suretitle">
                  <div class="sured">二次疑问反馈</div>
                  <div class="surep">请简述待解决待问题</div>
                </div>
                <el-input v-model="scope.row.feedback" />
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="sureone" v-if="surelist.length !== 0">
          <div class="suretitle">确认辅导内容</div>
          <div class="suretip">
            <p class="surefont">请确认导师是否如实记录辅导内容</p>
            <el-button class="giveo" @click="oneok2">一键确认</el-button>
          </div>
          <el-table
            ref="table"
            style="width: 100%"
            row-key="task_id"
            :data="surelist"
            class="tab"
            :default-sort="{ prop: 'date', order: 'descending' }"
          >
            <el-table-column
              prop="date"
              label="日期"
              align="center"
              min-width="85px"
            ></el-table-column>
            <el-table-column
              prop="teacher_work"
              label="导师辅导内容"
              align="center"
              min-width="230px"
            >
            </el-table-column>
            <el-table-column
              prop="student_work"
              label="学生任务"
              align="center"
              min-width="230px"
            ></el-table-column>
            <el-table-column
              prop="teacher"
              label="导师"
              align="center"
              width="65px"
            ></el-table-column>
            <el-table-column
              label="确认辅导内容"
              align="right"
              min-width="190px"
            >
              <template
                slot-scope="scope"
                style="display: flex; justify-content: center"
              >
                <el-button class="nobor" @click="toogleExpand(scope.row)"
                  >有误</el-button
                >
                <el-button
                  ref="btn2"
                  type="primary"
                  @click="makesure(scope.$index, scope.row)"
                  :class="scope.row.isconfirm ? 'isconfirm' : ''"
                  >准确</el-button
                >
              </template>
            </el-table-column>
            <el-table-column type="expand" width="1" v-if="showfk === true">
              <template slot-scope="scope">
                <div class="suretitle">
                  <div class="sured">疑问反馈</div>
                  <div class="surep">请简述待解决待问题</div>
                </div>
                <el-input v-model="scope.row.feedback" />
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="gologin">退出系统</el-button> -->
        <el-button type="primary" @click="surepost">确 定</el-button>
      </span>
    </el-dialog>
    <suggestRead :project="resData[show]"></suggestRead>
  </el-main>
</template>

<script>
/* eslint-disable no-console */
import axios from '../axios'
import moment from 'moment'
import { MessageBox } from 'element-ui'
import changerole from '../pub/changerole'
import suggestRead from '../pub/suggestRead'
import stages from './Sstages'
import stageType from '../pub/stageType.json'
import stagesList from '../pub/stagesList.json'
export default {
  name: 'newmission',
  data () {
    return {
      buttonDisabled: false,
      surelist: [],
      pastlist: [],
      sureVisible: false,
      uploadTitle: '',
      docVisible: false,
      uploadDialog: false,
      uploadUrl: `${axios.defaults.baseURL}/upload/`,
      files: [],
      loaded: false,
      docData: [],
      uploadable: false,
      tasks: {
        tasks: []
      },
      showfk: true,
      resData: [],
      stages: null,
      show: 0
    }
  },
  components: {
    changerole,
    suggestRead,
    stages
  },
  computed: {
    stagesList () {
      return stagesList
    },
    stageType () {
      return stageType
    },
    percent () {
      return function (item) {
        let i = Math.min(
          stagesList[stageType[item.type] || '基础'].length - 1,
          this.active(item)
        )
        return stagesList[stageType[item.type] || '基础'][i]
      }
    },
    active () {
      return function (item) {
        let stage = item.stage || 0
        if (item.stageComplete) {
          stage += 2
        }
        return stage
      }
    }
  },
  created () {
    this.getstudent()
    this.getconfirm()
  },
  methods: {
    // 利用show字段控制当前显示的项目的index
    handleScroll (e) {
      const { scrollTop, clientHeight, scrollHeight } = e.target
      if (!scrollTop) {
        if (this.show > 0) {
          setTimeout(() => {
            if (this.$refs.scrollWra.scrollTop === 0) {
              this.show--
              // setTimeout(() => {
              //   this.$refs.scrollWra.scrollTop =
              //     this.$refs.scrollWra.scrollHeight +
              //     this.$refs.scrollWra.clientHeight - 150
              //
              //   console.log(this.$refs.scrollWra.scrollTop)
              //   this.$refs.scrollWra.scrollTop--
              //   console.log(this.$refs.scrollWra.scrollTop)
              // }, 200)
              this.$refs.scrollWra.scrollTop = this.show === 0 ? 0 : 100
            }
          }, 500)
        }
      }
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        if (this.show < this.resData.length - 1) {
          setTimeout(() => {
            if (
              this.$refs.scrollWra.scrollTop +
                this.$refs.scrollWra.clientHeight >=
              this.$refs.scrollWra.scrollHeight - 1
            ) {
              this.show++
              this.$refs.scrollWra.scrollTop = 100
            }
          }, 500)
        }
      }
    },
    oneok () {
      this.pastlist.map((item) => {
        item.isconfirm = true
      })
    },
    oneok2 () {
      this.surelist.map((item) => {
        item.isconfirm = true
      })
    },
    getconfirm () {
      axios.get(`/student/task/confirm`).then((res) => {
        this.surelist = res.data.confirm
        this.pastlist = res.data.past
        if (this.surelist.length !== 0 || this.pastlist.length !== 0) {
          this.sureVisible = true
        } else {
          this.sureVisible = false
        }
      })
    },
    makesure (index, row) {
      row.isconfirm = true
      this.showfk = false
    },
    toogleExpand1 (row) {
      this.showfk = true
      let $table1 = this.$refs.table1
      this.pastlist.map((item) => {
        if (row.id !== item.id) {
          $table1.toggleRowExpansion(item, false)
          item.expansion = false
        } else {
          item.expansion = false
        }
      })
      $table1.toggleRowExpansion(row)
      row.isconfirm = false
    },
    toogleExpand (row) {
      this.showfk = true
      let $table = this.$refs.table
      this.surelist.map((item) => {
        if (row.id !== item.id) {
          $table.toggleRowExpansion(item, false)
          item.expansion = false
        } else {
          item.expansion = false
        }
      })
      $table.toggleRowExpansion(row)
      row.isconfirm = false
      if ((this.showfk = true)) {
        this.$refs.btn2.checked = false
      }
    },
    surepost () {
      if (this.$refs.table1 === undefined && this.$refs.table.length !== 0) {
        let tasks = this.$refs.table.data
        let arrk = []
        tasks.map((item) => {
          if (item.isconfirm === false && item.feedback === undefined) {
            arrk.push(item)
          } else {
            this.tasks.tasks.push(item)
          }
        })
        if (arrk.length !== 0) {
          this.$message({
            showClose: true,
            message: '请确认辅导内容',
            type: 'error'
          })
        } else {
          axios.post(`/student/task/confirm`, this.tasks).then((res) => {
            if (res.status === 200) {
              this.sureVisible = false
              this.getstudent()
            }
          })
        }
      } else if (
        this.$refs.table === undefined &&
        this.$refs.table1.length !== 0
      ) {
        let tasks = this.$refs.table1.data
        let arrk = []
        tasks.map((item) => {
          if (item.isconfirm === false && item.feedback === undefined) {
            arrk.push(item)
          } else {
            this.tasks.tasks.push(item)
          }
        })
        if (arrk.length !== 0) {
          this.$message({
            showClose: true,
            message: '请确认辅导内容',
            type: 'error'
          })
        } else {
          axios.post(`/student/task/confirm`, this.tasks).then((res) => {
            if (res.status === 200) {
              this.sureVisible = false
              this.getstudent()
            }
          })
        }
      } else if (
        this.$refs.table.data.length !== 0 &&
        this.$refs.table1.data.length !== 0
      ) {
        let tasks = this.$refs.table.data.concat(this.$refs.table1.data)
        let arrk = []
        tasks.map((item) => {
          if (item.isconfirm === false && item.feedback === undefined) {
            arrk.push(item)
          } else {
            this.tasks.tasks.push(item)
          }
        })
        if (arrk.length !== 0) {
          this.$message({
            showClose: true,
            message: '请确认辅导内容',
            type: 'error'
          })
        } else {
          axios.post(`/student/task/confirm`, this.tasks).then((res) => {
            if (res.status === 200) {
              this.sureVisible = false
              this.getstudent()
            }
          })
        }
      }
    },
    formatDate (data) {
      return moment(data * 1000).format('YYYY-MM-DD')
    },
    formatTime (data) {
      return moment(data * 1000).format('HH:mm')
    },
    delect (index, row) {
      MessageBox.confirm('是否确认删除').then(() => {
        axios.delete(`/student/finalPaper/${row.filekey}`).then((response) => {
          if (response.status === 200) {
            this.$message({
              showClose: true,
              message: '删除成功',
              type: 'success'
            })
            this.uploadOpen()
            // setTimeout(() => {
            //   this.files = ''
            //   this.uploadDialog = false
            //   this.uploadOpen()
            // }, 100)
          }
        })
      })
    },
    beforeFileUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 50
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 50MB!',
          type: 'warning'
        })
      }
      return isLt2M
    },
    handleExceed (files, fileList) {
      this.$message.warning(
        `当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    onRemove (file) {
      const index = this.files.indexOf(file.response.key)
      if (index > -1) {
        this.files.splice(index, 1)
      }
    },
    // 文件上传成功时的钩子
    handleSuccess (res) {
      this.$message({
        showClose: true,
        message: `文件上传成功`,
        type: 'success'
      })
      const timestamp = new Date().getTime()
      this.uploadTime = timestamp
      this.files.push(res.key)
    },
    // 文件上传失败时的钩子
    handleError () {
      this.$message({
        showClose: true,
        message: `文件上传失败`,
        type: 'error'
      })
    },
    getstudent () {
      axios.get('/student/task').then((response) => {
        let resData = response.data.teachers
        this.resData = resData
        if (response.data.teachers.length > 0) {
          this.loaded = true
        }
      })
    },
    isupload () {
      this.files = []
      this.uploadTitle = '上传文件'
      this.uploadDialog = true
    },
    cancel () {
      this.files = []
      this.uploadDialog = false
    },
    finish () {
      if (this.files.length === 0) {
        this.$message({
          message: '请上传文件',
          type: 'warning'
        })
        return true
      } else {
        MessageBox.confirm('是否确认提交？').then(() => {
          this.buttonDisabled = true
          console.log(this)
          axios
            .post(`/student/project/${this.resData[this.show].id}/paper`, {
              uploadFile: this.files.map((e) => {
                return {
                  filekey: e,
                  file_type: '作业'
                }
              })
            })
            .then((response) => {
              if (response.status === 200) {
                this.$message({
                  showClose: true,
                  message: '提交成功',
                  type: 'success'
                })
                this.buttonDisabled = false
                this.files = []
                this.uploadDialog = false
              }
            })
        })
      }
    },
    uploadOpen () {
      this.docVisible = true
      axios
        .get(`/student/project/${this.resData[this.show].id}/paper`)
        .then((response) => {
          let arr = response.data.papers
          let arr1 = arr.reverse()
          for (let i = 0; i < arr1.length; i++) {
            if (arr1[i].paper_type === '学生反馈') {
              arr1.splice(i, 1)
              i--
            }
          }
          this.docData = arr1
        })
    },
    download (row) {
      let x = new XMLHttpRequest()
      x.open('GET', row.url, true)
      x.responseType = 'blob'
      let that = this
      x.onload = function (e) {
        let url = window.URL.createObjectURL(x.response)
        let a = document.createElement('a')
        a.href = url
        a.download = row.paper_name
        a.click()
      }
      x.send()
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  .mistitle {
    display: flex;
    justify-content: right;
    margin: 5px 34px 0 0;
    padding: 12px 14px 12px 0;
    color: #ccd1d9;
    border-bottom: 1px #ccd1d9 solid;
  }

  .wrapper {
    padding: 20px 48px 20px 20px;
    margin-bottom: 20px;
    .topTitle {
      text-align: left;
      .row1 {
        padding-right: 11px;
        display: flex;
        justify-content: center;
        .title {
          font-size: 30px;
          color: #656d78;
          text-wrap: normal;
        }
      }
      .row2 {
        //margin-top: 13px;
        display: flex;
        align-items: center;
        .progress {
          font-size: 14px;
          color: #656d78;
          div:last-child {
            margin-left: 16px;
          }
        }
        .buttons {
          text-align: right;
        }
      }
    }
  }
}
/deep/.el-upload-list__item .el-icon-close-tip {
  display: none !important;
}
/deep/ .el-table__expanded-cell[class*='cell'] {
  padding: 0;
}
.el-upload__text {
  line-height: 100px;
  font-size: 14px;
  color: #656d78;
}
/deep/.el-upload-dragger {
  width: 600px;
  height: 100px;
}
/deep/ .el-popper[x-placement^='top'] {
  width: 536px;
}
/deep/ .el-checkbox-button__inner {
  width: 80px;
  height: 30px;
  border-radius: 0;
  text-align: center;
  line-height: 30px;
  border: none;
  color: #8dc152;
}
/deep/
  [data-v-3883af38]
  .el-checkbox-button:last-child
  .el-checkbox-button__inner:hover {
  background-color: #8dc152;
  color: #fff;
}
/deep/ .el-checkbox-button:last-child .el-checkbox-button__inner {
  border: none;
  border-radius: 0;
}
/deep/ .el-radio-button:first-child .el-radio-button__inner {
  font-size: 12px;
  margin-right: 10px;
}
/deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  font-size: 12px;
  color: #c0c0c0;
}
/deep/ .el-dialog__footer {
  padding-right: 20px;
}
.giveo {
  border: 1px solid #8dc152;
  color: #8dc152;
  position: relative;
  right: -55px;
}
.giveo:hover,
.giveo:focus {
  color: #fff;
  background-color: #8dc152;
}
.nobor {
  border: none;
  font-size: 8px;
}
.isconfirm {
  color: #fff;
  background-color: #a0d469;
  border: #a0d469;
}
.isok {
  width: 25px;
  height: 25px;
}
.suretitle {
  background-color: #f4f6f9;
  padding: 20px 0;
}
.sured {
  font-size: 14px;
  background-color: #f4f6f9;
}
.surep {
  font-size: 14px;
  color: #c0c0c0;
}
.surecontainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.sureone {
  margin-bottom: 20px;
}
.suretitle {
  font-size: 24px;
  text-align: left;
}
.suretip {
  width: 750px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.surefont {
  font-size: 12px;
  color: #c0c0c0;
}

.filedoc {
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.upload {
  position: relative;
  top: 20px;
}
.tab {
  font-size: 12px;
  color: #434a54;
}
.teacher {
  margin-bottom: 0px;
}
.tab {
  border-top: solid 1px #eeeeee;
}
.icon {
  margin-right: 6px;
}
.mian-upload {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  div,
  p {
    margin-bottom: 10px;
  }
}
.el-date-table td.current:not(.disabled) span {
  background-color: #a0d469;
}
.el-picker-panel__icon-btn:hover {
  color: #a0d469;
}
.el-date-picker__header-label.active,
.el-date-picker__header-label:hover {
  color: #a0d469;
}
.el-month-table td.current:not(.disabled) .cell {
  color: #a0d469;
}
.el-date-table td.today span {
  color: #a0d469;
}
.el-date-table td.available:hover {
  color: #a0d469;
}

.mian-upload {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.upload-demo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.uploadNotice {
  text-align: left;
  font-size: 14px;
  color: #c0c0c0;
}
/deep/.el-upload {
  width: 100%;
}
/deep/.el-upload-list {
  width: 100%;
}

.iconAndName {
  margin-top: 10px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: left;
  div {
    margin-left: 6px;
  }
}

.loadingS {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
</style>
