<template>
  <el-main class="main">
    <channel />
  </el-main>
</template>

<script>
import channel from '../pub/channel'
export default {
  name: 'MDchannel',
  components: {
    channel
  }
}
</script>
