<template>
  <div>
    <div class="pre" @click="turnUp">
      <div class="pretext">{{ type }}</div>
      <div :class="{ turn: turnValue }" class="preimg">
        <img src="../../assets/icon/箭头.png" height="15" />
      </div>
    </div>
    <div v-if="turnValue">
      <div
        v-for="(item, index) in manuals"
        :key="index"
        class="project"
        @click="pdfPreview(item.pdf, item.name, getType(item.pdf))"
      >
        <img
          src="../../assets/icon/pdf2.png"
          class="pdf"
          v-if="getType(item.pdf) === 'pdf'"
        />
        <img
          src="../../assets/icon/word.png"
          class="pdf"
          v-else-if="
            getType(item.pdf) === 'doc' || getType(item.pdf) === 'docx'
          "
        />
        <img class="pdf" src="../../assets/icon/others.png" v-else />
        <img src="../../assets/icon/newicon.png" class="new" v-if="item.new" />
        <p class="name">
          {{ item.name }}
        </p>
        <p class="date">上传日期：{{ item.created }}</p>
        <img
          src="../../assets/icon/预览.png"
          height="25"
          width="auto"
          class="preview"
        />
      </div>
    </div>
    <manualForm ref="manualForm" :type="'put'"></manualForm>
  </div>
</template>

<script>
import { MessageBox } from 'element-ui'
import axios from '../axios'
import manualForm from './manualForm'

export default {
  name: 'manualList',
  data () {
    return {
      turnValue: true,
      manuals: [],
      title: ''
    }
  },
  components: {
    manualForm
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.getManualList()
  },
  methods: {
    getType (url) {
      let x = url.split('.')
      return x[x.length - 1]
    },
    turnUp () {
      this.turnValue = !this.turnValue
    },
    pdfPreview (pdfUrl, name, type) {
      if (type === 'pdf') {
        this.$router.push({
          path: '/public/teacherManualReview',
          query: { title: name, url: pdfUrl, key: this.$utils.getUrlKey('key') }
        })
      } else {
        window.open(pdfUrl, '_blank')
      }
    },
    getManualList () {
      axios
        .get('/manual/getManuals', {
          params: {
            stage: this.type
          }
        })
        .then(response => {
          this.manuals = response.data.manuals
          if (response.data.manuals[0].new === null) this.turnValue = false
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.pre {
  text-align: left;
  height: 40px;
  font-size: 12px;
  background-color: #c0c0c0;
  margin-bottom: 10px;
  position: relative;
  .pretext {
    position: absolute;
    left: 20px;
    top: 9px;
    color: #fff;
    font-size: 16px;
  }
  .preimg {
    position: absolute;
    right: 22.5px;
    top: 10px;
    transition: 0.3s;
    transform: rotate(-90deg);
  }
  .turn {
    transform: rotate(0deg);
    transition: 0.3s;
  }
}
.project {
  align-items: center;
  height: 108px;
  background: #ffffff;
  margin-bottom: 10px;
  flex-direction: row;
  position: relative;
  width: 100%;
  cursor: pointer;
  overflow: auto;
  text-align: left;
  border-radius: 8px;
  .new {
    height: 12px;
    position: absolute;
    top: 25px;
    left: 48px;
  }
  .preview {
    position: absolute;
    right: 24px;
    top: 35px;
  }
}
.name {
  font-size: 18px;
  margin: 19px 45px 8px 80px;
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: block;
  .name2 {
    display: inline-block;
    max-width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.date {
  font-size: 14px;
  color: #c0c0c0;
  margin: 0;
  margin-left: 80px;
}
.pdf {
  width: 24px;
  position: absolute;
  top: 19px;
  left: 15px;
}
</style>
