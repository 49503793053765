<template>
  <el-main class="main">
    <div class="modtitle">
      <h2 class="jtext">菁英怀思学院导师手册</h2>
    </div>
    <div class="title">
      <div :close-on-click-modal="true" style="text-align: right">
        <el-button @click="posturl()" type="primary" style="margin-top: 15px"
          >复制链接</el-button
        >
        <el-button @click="postform()" type="primary" style="margin-top: 15px"
          >上传手册</el-button
        >
      </div>
    </div>
    <div class="list">
      <div class="tableTitle">
        <p style="margin-left: 10%">手册名称</p>
        <p style="margin-left: 70%">上传日期</p>
      </div>
      <div class="project">
        <manualList :type="'导师手册'" :readOnly="false" />
        <manualList :type="'项目介绍'" :readOnly="false" />
        <manualList :type="'面试阶段'" :readOnly="false" />
        <manualList :type="'辅导阶段'" :readOnly="false" />
        <manualList :type="'结项阶段'" :readOnly="false" />
        <manualList :type="'其他'" :readOnly="false" />
      </div>
    </div>
    <manualForm ref="manualForm" :type="'post'"></manualForm>
  </el-main>
</template>

<script>
import manualList from '../manual/manualList'
import manualForm from '../manual/manualForm'
import changerole from '../pub/changerole'
import axios from '../axios'

export default {
  name: 'TDmanual',
  data () {
    return {
      manuals: [],
      introduce: false,
      interviewed: false,
      tutoring: false,
      postProject: false
    }
  },
  components: {
    manualList,
    manualForm,
    changerole
  },
  methods: {
    postform () {
      this.$refs.manualForm.active = true
    },
    posturl () {
      axios.post(`/manual/get_url`).then(res => {
        const readOnlyUrl = document.createElement('input')
        document.body.appendChild(readOnlyUrl)
        readOnlyUrl.setAttribute('value', res.data.url)
        readOnlyUrl.select()
        document.execCommand('copy', 'false', null)
        readOnlyUrl.setAttribute('style', 'display:none')
        this.$message({
          message: '复制成功',
          type: 'success'
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tableTitle {
  display: flex;
  font-size: 12px;
  color: #909399;
  font-weight: bold;
}
.title {
  background: #f5f7fa;
  width: 85%;
  margin: 0px auto;
  position: relative;
}
.list {
  width: 85%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 0 auto 45px;
}
.project {
  margin-top: 10px;
  min-width: 150px;
  flex: 1 1 auto;
}
.modtitle {
  height: 63px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c0c0c0;
  .jtext {
    color: #434a54;
    position: absolute;
    left: 10%;
    font-weight: normal;
  }
}
</style>
