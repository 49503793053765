<template>
  <div>
    <div id="pic" v-if="render === ''">
      <div class="top">
        <!--        <div class="meng"></div>-->
        <!--        <img src="../../assets/icon/Pro-tou1.png" alt="" class="topTou1" />-->
        <!--        <img src="../../assets/icon/Pro-tou2.png" alt="" class="topTou2" />-->
        <img class="img" src="../../assets/icon/iap.png" />
        <div crossorigin="anonymous" class="background" :style="`background-image:url('${img}')`" />
      </div>
      <div class="bottom">
        <!-- <img src="../../assets/icon/yinying.png" style="position: absolute;left: -20px;top: -20px;" /> -->
        <img src="../../assets/icon/yinhao-standard.png" class="shangyin" />
        <div class="direction" :style="{ fontSize: dirFont + 'px' }">{{ direction }}</div>
        <div :style="{ fontSize: dirFont + 'px' }" id="direction" class="direction"
          style="max-width: 750px;visibility: hidden;position: absolute">{{ direction }}</div>
        <div class="heng" :style="{ top: linesOfDer * 30 + 100 + 'px' }"></div>
        <img class="tou" crossorigin="anonymous" :src="professor.avatar" />
        <div class="teacher">
          PROF.
          <br />
          <div :style="{ fontSize: fontSize + 'px' }" style="font-family: inherit;display: inline-block;width: 250px">
            {{ professor ? professor.lastName.toUpperCase() : '' }}
          </div>
          <div :style="{ fontSize: fontSize + 'px' }" id="mirror"
            style="position: absolute;visibility: hidden;font-family: inherit">
            {{ professor ? professor.lastName.toUpperCase() : '' }}
          </div>
        </div>
        <div class="school">
          <div :style="{ fontSize: schoolFont + 'px' }" style="display: inline-block">
            {{ professor.school }}
          </div>
          <div :style="{ fontSize: schoolFont + 'px' }" id="school" style="visibility: hidden;position: absolute">
            {{ professor.school }}
          </div>
          <br />
          {{ professor.position }}
        </div>
        <div id="qrcode" />
        <img class="psimg" src="../../assets/icon/ps.png" />
        <img src="../../assets/icon/yinhao-standard.png" class="xiayin" />
      </div>
    </div>
    <img :src="render" v-else style="width:100%" />
  </div>
</template>

<script>
import axios from '../axios'
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas'

export default {
  name: 'litepage',
  data() {
    return {
      uuid: '',
      name: 'Green',
      school: '',
      position: '',
      total: 0,
      num: 0,
      OSS: process.env.VUE_APP_OSS,
      direction: '',
      tagsText: '',
      render: '',
      file: '',
      tou: '',
      img: '', // 海报背景图地址
      linesOfDer: 1,
      firstName: '',
      professor: '',
      tags: '',
      fontSize: 36,
      schoolFont: 28,
      dirFont: 28
    }
  },
  props: {
    pro: {}
  },
  mounted() {
    Object.assign(this.$data, this.pro)
    let res = [this.pro.subject.content]
    this.tags = res.concat(this.tagsText.split(','))
    // console.log(res)
    this.set()
    this.$nextTick(() => {
      this.initFontSize()
    })

    setTimeout(() => {
      this.exportx()
    }, 0)
    // this.set()
    // this.exportx()
  },
  methods: {
    initDover() {
      let d = this.tags
      if (d.length >= 4) {
        this.linesOfDer = 2
        let len = []
        let max = 0
        for (let i of d) {
          max += i.length
          len.push(max)
        }
        let mid = Math.floor(max / 2)
        let key
        for (let i = 0; i < d.length; i++) {
          if (len[i] >= mid) {
            let a = Math.abs((max - len[i] + (d.length - i - 2)) - (len[i] + i))
            let b = Math.abs((max - len[i + 1] + (d.length - i - 3)) - (len[i] + i + 1))
            if (a >= b) {
              key = i + 1
            } else {
              key = i
            }
            break
          }
        }
        let res = ''
        for (let i = 0; i < d.length; i++) {
          res += d[i] + (i === key || i === d.length - 1 ? '\n' : ' | ')
        }
        this.direction = res
        // this.direction = x[0] + ' | ' + x[1] + '\n' + x[2] + ' | ' + x[3]
      } else {
        this.linesOfDer = 1
        let res = ''
        for (let i = 0; i < d.length; i++) {
          res += d[i] + (i === d.length - 1 ? '\n' : ' | ')
        }
        this.direction = res
      }
    },
    initFontSize() {
      let key, key2, key3
      let _this = this
      // console.log('inint')

      async function test() {
        for (let i = 0; i < 20; i++) {
          console.log(i)
          await _this.$nextTick(() => {
            key = document.getElementById('mirror').offsetWidth
            // console.log(key)
            if (key > 250) {
              _this.fontSize -= 1
            }
          })
          if (key <= 250) {
            break
          }
        }
      }

      test()
      async function test2() {
        for (let i = 0; i < 20; i++) {
          // console.log((i))
          await _this.$nextTick(() => {
            key2 = document.getElementById('school').offsetWidth
            if (key2 > 250) {
              _this.schoolFont -= 1
            }
          })
          if (key2 <= 250) {
            break
          }
        }
      }

      test2()

      test2()
      async function test3() {
        for (let i = 0; i < 16; i++) {
          console.log((i))
          await _this.$nextTick(() => {
            key3 = document.getElementById('direction').offsetWidth
            if (key3 > 475) {
              _this.dirFont -= 1
            }
          })
          if (key3 <= 475) {
            break
          }
        }
      }

      test3()
    },
    set() {
      let origin = ''
      if (window.location.host.indexOf('eliteqihang') > -1) {
        origin = 'https://p.eliteqihang.com/pro/'
      } else {
        origin = 'https://p.manager.eliwise.demo.sya.org.cn/pro/'
      }
      let qrcode = `${origin}${this.pro.uuid}`
      this.linesOfDer = 1
      this.initDover()
      document.getElementById('qrcode').innerHTML = ''
      // eslint-disable-next-line
      new QRCode('qrcode', {
        text: qrcode,
        width: 100, // 图像宽度
        height: 100, // 图像高度
        colorDark: '#000000', // 前景色
        colorLight: '#ffffff', // 背景色
        typeNumber: 4,
        correctLevel: QRCode.CorrectLevel.H
      })
      // console.log(qrcode)
    },
    exportx() {
      this.$nextTick(() => {
        document.body.style.width = '2024px'

        html2canvas(document.querySelector('#pic'), {
          useCORS: true,
          scrollY: 0,
          height: 1624
        }).then(canvas => {
          document.body.style.width = 'unset'
          this.render = canvas.toDataURL('image/jpeg', 0.9)
          let arr = this.render.split(',')
          let mime = arr[0].match(/:(.*?);/)
          // eslint-disable-next-line no-undef
          let bstr = atob(arr[1])
          let n = bstr.length
          let u8arr = new Uint8Array(n)
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
          }
          // eslint-disable-next-line no-undef
          let param = new FormData()
          param.append(
            'file',
            // eslint-disable-next-line no-undef
            new File(
              [u8arr],
              `${this.tags.join('、')}-${this.professor.firstName} ${this.professor.lastName}.png`,
              {
                type: mime
              }
            )
          )
          param.append('uuid', this.pro.uuid)
          param.append('type', '')
          axios
            .post('previews/upload', param, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(res => {
              this.$emit('getPoster', res.data.url)
            })
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
* {
  font-family: PFBD;
}

#pic {
  width: 375 * 2px;
  height: 812 * 2px;
  position: relative;

  .img {
    width: 375 * 2px;
    height: 392 * 2px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .background {
    width: 375 * 2px;
    height: 392 * 2px;
    top: 0;
    z-index: 0;
    background-position: top;
    background-size: cover;
    position: absolute;
  }

  .meng {
    width: 375 * 2px;
    height: 392 * 2px;
    position: absolute;
    top: 0 * 2px;
    z-index: 1;
    background: rgba(#000000, 0.65);
  }

  .xian {
    width: 35 * 2px;
    left: 30 * 2px;
    border-top: 2 * 2px solid #fff;
    position: absolute;
    top: 70 * 2px;
  }

  .top {
    width: 375 * 2px;
    height: 392 * 2px;
    position: relative;

    .topTou1 {
      width: 102 * 2px;
      position: absolute;
      top: 38 * 2px;
      left: 50 * 2px;
      z-index: 2;
    }

    .topTou2 {
      width: 275 * 2px;
      position: absolute;
      top: 95 * 2px;
      left: 50 * 2px;
      z-index: 2;
    }
  }

  .bottom {
    width: 375 * 2px;
    height: 437 * 2px;
    position: relative;
    z-index: 100;

    .shangyin {
      top: 40 * 2px;
      left: 50 * 2px;
      position: absolute;
    }

    .tou {
      position: absolute;
      width: 120 * 2px;
      height: 120 * 2px;
      left: 50 * 2px;
      top: 120 * 2px;
      border-radius: 50%;
      background-color: white;
    }

    .direction {
      position: absolute;
      right: 50 * 2px;
      top: 40 * 2px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 14 * 2px;
      line-height: 18 * 2px;
      text-align: right;
      color: #d7af87;
      max-width: 475px;
      white-space: pre-line;
    }

    .heng {
      position: absolute;
      right: 50 * 2px;
      top: 80 * 2px;
      width: 25 * 2px;
      border-top: 2 * 2px solid #d7af87;
    }

    .teacher {
      position: absolute;
      left: 200 * 2px;
      top: 122 * 2px;
      width: 130 * 2px;
      font-family: LemonMilkbold;
      font-style: normal;
      font-weight: 600;
      font-size: 18 * 2px;
      line-height: 25 * 2px;
      //letter-spacing: 0.05em;
      color: #d7af87;
      text-align: left;
    }

    .school {
      position: absolute;
      left: 200 * 2px;
      top: 172 * 2px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 600;
      font-size: 14 * 2px;
      text-align: left;
      line-height: 19.6 * 2px;
      letter-spacing: 0.05em;
      color: #555555;

      div {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 600;
        font-size: 14 * 2px;
        line-height: 19.6 * 2px;
        letter-spacing: 0.05em;
        color: #555555;
      }
    }

    #qrcode {
      position: absolute;
      right: 45 * 2px;
      bottom: 40 * 2px;
    }

    .ps {
      position: absolute;
      left: 90 * 2px;
      bottom: 45 * 2px;
      font-family: PFBD;
      font-style: normal;
      font-weight: 600;
      font-size: 8 * 2px;
      display: flex;
      align-items: center;
      color: #555555;
    }

    .psimg {
      position: absolute;
      left: 50 * 2px;
      bottom: 40 * 2px;
      width: 213 * 2px;
    }

    .xiayin {
      position: absolute;
      right: 50 * 2px;
      bottom: 134 * 2px;
      transform: rotate(-180deg);
    }
  }
}
</style>
