<template>
  <div>
    <el-tooltip placement="top" effect="light" content="查看文件">
      <el-button type="primary" @click="getfile" size="mini">
        <i class="el-icon-document" style="font-size:22px"></i>
      </el-button>
    </el-tooltip>
    <el-dialog
      :title="fileTitle"
      :visible.sync="fileDialog"
      width="1090px"
      class="mianDialog mian-upload"
      :close-on-click-modal="true"
    >
      <el-table :data="file" style="width: 100%" max-height="500">
        <el-table-column
          prop="paper_type"
          label="文件类型"
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="paper_name"
          label="文件名"
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column label="上传日期" align="center" prop="updater.date">
          <template slot-scope="scope">
            <div>{{ formatDate(scope.row.updater.date) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="上传时间" align="center">
          <template slot-scope="scope">
            <div>{{ formatTime(scope.row.updater.date) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="updater.name" label="上传者" align="center">
        </el-table-column>
        <el-table-column label="确认为终稿" align="center">
          <template slot-scope="scope">
            <p v-if="scope.row.isfinal">
              <img class="isok" src="../../../assets/icon/isok.png" />
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot-scope="scope">
            <span class="download" @click="download(scope.row)">
              <img src="../../../assets/icon/下载.png" />
            </span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">返回</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
export default {
  name: 'file',
  data () {
    return {
      file: [],
      fileDialog: false,
      fileTitle: ''
    }
  },
  props: {
    pid: 0
  },
  methods: {
    formatDate (data) {
      return moment(data * 1000).format('YYYY-MM-DD')
    },
    formatTime (data) {
      return moment(data * 1000).format('HH:mm')
    },
    getfile () {
      axios
        .get(`/${this.$store.state.roleApi}/project/${this.pid}/finish_paper`)
        .then(res => {
          let arr = res.data.papers
          this.file = arr.reverse()
        })
      this.fileDialog = true
      this.fileTitle = '查看文件'
    },
    cancel () {
      this.fileDialog = false
    },
    download (row) {
      let x = new XMLHttpRequest()
      x.open('GET', row.url, true)
      x.responseType = 'blob'
      let that = this
      x.onload = function (e) {
        let url = window.URL.createObjectURL(x.response)
        let a = document.createElement('a')
        a.href = url
        a.download = row.paper_name
        a.click()
      }
      x.send()
    }
  }
}
</script>

<style lang="scss" scoped>
.minibutton {
  margin-right: 10px;
  margin-left: 10px;
}</style
>>
