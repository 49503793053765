<template>
  <el-main class="main">
    <div>
      <div class="jtitle">
        <h2 class="jtext">账户管理</h2>
      </div>
      <el-row>
        <el-col class="star">
          <el-rate
            v-model="tinfo.star"
            disabled
            :colors="colors"
            disabled-void-color="#c0c0c0"
            score-template="{value}"
          >
          </el-rate>
        </el-col>
      </el-row>
      <div class="content">
        <el-form :model="tinfo" :rules="rules" ref="tinfo">
          <div>
            <el-row>
              <el-col :span="12">
                <div class="pmodtitle">
                  <div class="flex">
                    <h1 class="pertext">个人信息</h1>
                    <span class="must"> *为必填项 </span>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <div class="grid-content bg-purple-light">
                    <div class="flex right">
                      <el-button @click="pmclear('tinfo')">
                        清空
                      </el-button>
                      <el-button type="primary" @click="pmtrue('tinfo')">
                        确认
                      </el-button>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div>
            <el-row class="spacebetween">
              <el-col :span="6">
                <el-form-item prop="name">
                  <div class="inputgroup">
                    <div class="tleft">姓名 *</div>
                    <el-input
                      placeholder="姓名"
                      maxlength="10"
                      v-model="tinfo.name"
                      class="input"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="ml44">
                <el-form-item prop="organization">
                  <div class="inputgroup">
                    <div class="tleft">机构 *</div>
                    <el-input
                      placeholder="机构"
                      maxlength="64"
                      v-model="tinfo.organization"
                      class="input"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="ml44">
                <el-form-item prop="research_direction">
                  <div class="inputgroup">
                    <div class="tleft">研究方向 *</div>
                    <el-input
                      placeholder="研究方向"
                      maxlength="128"
                      v-model="tinfo.research_direction"
                      class="input"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="ml44">
                <el-form-item prop="email">
                  <div class="inputgroup">
                    <div class="tleft">常用邮箱 *</div>
                    <el-input
                      placeholder="邮箱"
                      maxlength="64"
                      v-model="tinfo.email"
                      class="input"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="nospacebetween">
              <el-col :span="6">
                <el-form-item prop="cellphone">
                  <div class="inputgroup">
                    <div class="tleft">电话 *</div>
                    <el-input
                      placeholder="电话"
                      maxlength="32"
                      v-model="tinfo.cellphone"
                      class="input"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="ml44">
                <el-form-item prop="birthday">
                  <div class="inputgroup">
                    <div class="tleft">生日</div>
                    <el-input
                      placeholder="19900101"
                      v-model="tinfo.birthday"
                      maxlength="8"
                      class="input"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="ml44">
                <el-form-item prop="address">
                  <div class="inputgroup">
                    <div class="tleft">通讯地址</div>
                    <el-input
                      placeholder="通讯地址"
                      maxlength="64"
                      v-model="tinfo.address"
                      class="input"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="ml44"> </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
      <div class="content">
        <div>
          <el-row>
            <el-col :span="12">
              <div class="pmodtitle">
                <div class="flex">
                  <h1 class="pertext">学术信息</h1>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <div class="flex right">
                  <el-link
                    href="https://elitebackground.oss-cn-zhangjiakou.aliyuncs.com/菁英学科标签.xlsx"
                    target="_blank"
                    class="export"
                  >
                    学科标签&细分方向
                  </el-link>
                  <el-button @click="paperclear">
                    清空
                  </el-button>
                  <el-button type="primary" @click="papertrue">
                    确认
                  </el-button>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div>
          <el-row id="subSearch">
            <div class="papertitle">学科标签</div>
            <tagsSearchThird
              style="width: 50%;"
              :subject="tinfo.subject"
            ></tagsSearchThird>
          </el-row>
          <el-row id="dirSearch">
            <div class="papertitle">细分方向</div>
            <tagsSearchSecond
              style="width: 50%;"
              :direction="tinfo.direction"
              :teacher="true"
            ></tagsSearchSecond>
          </el-row>
          <el-row>
            <el-col :span="24" class="pad">
              <div class="papertitle">导师背景</div>
              <el-input
                type="textarea"
                class="input"
                :autosize="{ minRows: 6, maxRows: 6 }"
                placeholder="研究方向、项目经历、发表经历、审稿经历、获得奖项、学术任职等"
                v-model="tinfo.paper"
              ></el-input>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="content">
        <el-form :model="ruleform" :rules="rules" ref="ruleform">
          <div>
            <el-row>
              <el-col :span="12">
                <div class="pmodtitle">
                  <div class="flex">
                    <h1 class="pertext">安全信息</h1>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <div class="grid-content bg-purple-light">
                    <div class="flex right">
                      <el-button @click="ppclear('ruleform')">
                        清空
                      </el-button>
                      <el-button type="primary" @click="pptrue('ruleform')">
                        确认
                      </el-button>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div>
            <el-row class="spacebetween">
              <el-col :span="8">
                <el-form-item prop="ypassword">
                  <div class="inputgroup">
                    <div class="tleft">原密码*</div>
                    <el-input
                      placeholder="原密码"
                      maxlength="64"
                      type="password"
                      v-model="ruleform.ypassword"
                      class="input pswinput"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="ml44">
                <el-form-item prop="npassword">
                  <div class="inputgroup">
                    <div class="tleft">新密码*</div>
                    <el-input
                      placeholder="新密码"
                      maxlength="64"
                      type="password"
                      v-model="ruleform.npassword"
                      class="input pswinput"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="ml44">
                <el-form-item prop="rpassword">
                  <div class="inputgroup">
                    <div class="tleft">重复密码*</div>
                    <el-input
                      placeholder="重复密码"
                      maxlength="64"
                      type="password"
                      v-model="ruleform.rpassword"
                      class="input pswinput"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
    </div>
  </el-main>
</template>

<script>
/* eslint-disable no-console */
import { debounce } from 'lodash'
import axios from '../axios'
import { MessageBox } from 'element-ui'
import changerole from '../pub/changerole'
import tagsSearchSecond from '../pub/tagsSearchSecond'
import tagsSearchThird from '../pub/tagsSearchThird'
export default {
  name: 'tmodify',
  data () {
    return {
      option: [
        {
          value: '1',
          label: '导师'
        }
      ],
      value: '导师',
      rules: {
        organization: [
          { required: true, message: '请填写机构名称', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
        research_direction: [
          { required: true, message: '请填写研究方向', trigger: 'blur' }
        ],
        cellphone: [
          { required: true, message: '请填写电话号码', trigger: 'blur' }
        ],
        email: [{ required: true, message: '请填写邮箱地址', trigger: 'blur' }],
        ypassword: [
          { required: true, message: '请填写原密码', trigger: 'blur' }
        ],
        npassword: [
          { required: true, message: '请填写新密码', trigger: 'blur' }
        ],
        rpassword: [
          { required: true, message: '请再次填写新密码', trigger: 'blur' }
        ]
      },
      colors: {
        2: '#A0D469',
        4: { value: '#A0D469', excluded: true },
        5: '#A0D469'
      },
      star: 1,
      ruleform: {
        ypassword: '',
        npassword: '',
        rpassword: ''
      },
      paper: '',
      tinfo: {
        name: '',
        birthday: '',
        organization: '',
        cellphone: '',
        email: '',
        address: '',
        star: '',
        research_direction: '',
        scientific_research: '',
        paper: '',
        direction: [],
        subject: []
      },
      subjectValue: '',
      directionValue: '',
      directions: [],
      subjects: [],
      showTagSearch: false,
      showSubsTagSearch: false
    }
  },

  created () {
    this.getinfo()
    this.hideTagsSearch('subSearch')
    this.hideTagsSearch('dirSearch')
  },
  methods: {
    tagcolor (tag) {
      switch (tag.type) {
        case '理科':
          return 'tag2'
        case '工科':
          return 'tag4'
        case '商科':
          return 'tag6'
        case '社科':
          return 'tag8'
        case '医科':
          return 'tag10'
      }
    },
    handleClose (tag) {
      this.tinfo.direction.splice(this.tinfo.direction.indexOf(tag), 1)
    },
    remoteSubject: debounce(function (query) {
      axios
        .get('share/subject', {
          params: {
            content: query.data
          }
        })
        .then(response => {
          this.subjects = response.data.subjects
          this.$refs.subject.visible = true
        })
    }, 500),
    subjectClose (tag) {
      this.tinfo.subject.splice(this.tinfo.subject.indexOf(tag), 1)
    },
    subjectConfirm () {
      let subjectValue = this.subjectValue
      if (this.tinfo.subject.find(x => x === subjectValue)) {
        this.$message({
          message: '不能选择相同标签',
          type: 'warning'
        })
      } else {
        if (this.tinfo.subject.length < 4) {
          if (subjectValue) {
            this.tinfo.subject.push(subjectValue)
          }
        } else {
          this.$message({
            message: '最多选择四个标签',
            type: 'warning'
          })
        }
      }
      this.subjectValue = ''
    },
    getinfo () {
      axios
        .get('/teacher/')
        .then(response => {
          this.tinfo = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    pmtrue (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const reg = /^((((1[6-9]|[2-9]\d)\d{2})(0?[13578]|1[02])(0?[1-9]|[12]\d|3[01]))|(((1[6-9]|[2-9]\d)\d{2})(0?[13456789]|1[012])(0?[1-9]|[12]\d|30))|(((1[6-9]|[2-9]\d)\d{2})0?2(0?[1-9]|1\d|2[0-8]))|(((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))0?229))$/
          const pattern = /^([a-zA-Z0-9]+[_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
          if (!pattern.test(this.tinfo.email)) {
            this.$message({
              showClose: true,
              message: '邮箱格式有误',
              type: 'error'
            })
          } else if (
            !reg.test(this.tinfo.birthday) &&
            this.tinfo.birthday !== ''
          ) {
            this.$message({
              showClose: true,
              message: '生日格式有误，正确格式为19900101',
              type: 'error'
            })
          } else {
            axios
              .put('/teacher/', {
                research_direction: this.tinfo.research_direction,
                name: this.tinfo.name,
                organization: this.tinfo.organization,
                birthday: this.tinfo.birthday,
                address: this.tinfo.address,
                email: this.tinfo.email,
                cellphone: this.tinfo.cellphone
              })
              .then(response => {
                if (response.status === 200) {
                  this.$message({
                    showClose: true,
                    message: '您的信息已修改',
                    type: 'success'
                  })
                }
              })
              .catch(error => {
                this.$message({
                  showClose: true,
                  message: `修改失败,${error.response.data.error_message}`,
                  type: 'error'
                })
              })
          }
        } else {
          return false
        }
      })
    },
    pptrue (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.ruleform.npassword !== this.ruleform.rpassword) {
            this.$message({
              showClose: true,
              message: '密码不一致',
              type: 'error'
            })
          } else {
            axios
              .post('/teacher/change_password', {
                old_password: this.ruleform.ypassword,
                new_password: this.ruleform.npassword,
                confirm: this.ruleform.rpassword
              })
              .then(response => {
                if (response.status === 200) {
                  MessageBox.alert('修改成功').then(() => {
                    localStorage.removeItem('Authorization')
                    this.$router.replace('/login')
                  })
                }
              })
              .catch(error => {
                this.$message({
                  showClose: true,
                  message: `修改失败,${error.response.data.error_message}`,
                  type: 'error'
                })
              })
          }
        } else {
          return false
        }
      })
    },
    papertrue () {
      axios
        .put('/teacher/', {
          paper: this.tinfo.paper,
          scientific_research: this.tinfo.scientific_research,
          direction: this.tinfo.direction,
          subject: this.tinfo.subject
        })
        .then(response => {
          if (response.status === 200) {
            this.$message({
              showClose: true,
              message: '您的学术信息已修改',
              type: 'success'
            })
          }
        })
        .catch(error => {
          this.$message({
            showClose: true,
            message: `修改失败,${error.response.data.error_message}`,
            type: 'error'
          })
        })
    },
    paperclear () {
      this.tinfo.paper = ''
      this.tinfo.scientific_research = ''
    },
    ppclear (formName) {
      this.$refs[formName].resetFields()
      this.ruleform = {}
    },
    pmclear (formName) {
      this.$refs[formName].resetFields()
      this.tinfo.birthday = ''
      this.tinfo.organization = ''
      this.tinfo.address = ''
      this.tinfo.email = ''
      this.tinfo.name = ''
      this.tinfo.cellphone = ''
      this.tinfo.research_direction = ''
    },
    hideTagsSearch (id) {
      document.addEventListener('click', e => {
        let ele = document.getElementById(id)
        if (id === 'subSearch') {
          if (this.showSubsTagSearch === true) {
            let isSelf = ele.contains(e.target)
            if (!isSelf) {
              this.showSubsTagSearch = false
            }
          }
        } else {
          if (this.showTagSearch === true) {
            let isSelf = ele.contains(e.target)
            if (!isSelf) {
              this.showTagSearch = false
            }
          }
        }
      })
    }
  },
  components: {
    changerole,
    tagsSearchSecond,
    tagsSearchThird
  }
}
</script>

<style lang="scss" scoped>
.right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.star {
  text-align: left;
  margin-top: 12px;
}
.pertext {
  font-size: 48px;
  text-align: left;
  font-weight: normal;
  color: #656d78;
  margin-bottom: 45px;
}

.el-button,
.export {
  margin-top: 25px;
  margin-left: 12px;
  width: 80px;
  height: 30px;
}
.must {
  font-size: 14px;
  color: #c0c0c0;
  margin-top: 40px;
  margin-left: 10px;
}
.content {
  margin-right: 50px;
  padding: 40px;
  margin-bottom: 20px;
  border-bottom: solid 1px #c0c0c0;
  background: #fff;
  border-radius: 8px;
  min-width: 600px;
}

.tleft {
  text-align: left;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 6px;
}

.inputgroup {
  width: 100%;
}
.spacebetween {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
}
.nospacebetween {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}
.ml44 {
  margin-left: 44px;
}
.pmodtitle {
  min-width: 300px;
}
.papertitle {
  text-align: left;
  line-height: 17px;
  font-size: 14px;
  color: #656d78;
  margin-bottom: 7px;
  margin-top: 11px;
}
.researchtitle {
  text-align: left;
  line-height: 17px;
  font-size: 14px;
  color: #656d78;
  margin-bottom: 7px;
}
.pad {
  margin-bottom: 30px;
}
.direction {
  border-bottom: solid 1px #c0c0c0;
  /deep/.el-input__inner {
    background-color: #f4f6f9;
    border: solid 0px;
    padding-left: 5px;
    padding-right: 0px;
    height: 24.5px;
  }
  .el-select {
    width: 100%;
  }
}
.el-link {
  width: fit-content;
  color: #656d78;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 0;
    bottom: 0;
    border-bottom: 1px solid #656d78;
  }
}
</style>
