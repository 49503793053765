<template>
  <el-main class="main">
    <div>
      <div class="jtitle">
        <h2 class="jtext">账户管理</h2>
      </div>
      <div class="table">
        <div class="search flex">
          <div class="seaicon">
            <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
          </div>
          <el-input
            @input="search"
            v-model="accmessage"
            class="seasubfix"
          ></el-input>
        </div>
        <div>
          <el-table :data="tableData1" ref="tableScroll" :height="tableHeight">
            <el-table-column
              key="2"
              prop="id"
              label="编号"
              align="center"
            ></el-table-column>
            <el-table-column
              key="3"
              prop="name"
              label="姓名"
              align="center"
            ></el-table-column>
            <el-table-column
              key="4"
              prop="sex"
              label="性别"
              align="center"
            ></el-table-column>
            <el-table-column
              key="5"
              prop="school"
              label="学校"
              align="center"
            ></el-table-column>
            <el-table-column
              key="6"
              prop="direction"
              label="专业方向"
              align="center"
            ></el-table-column>
            <el-table-column
              key="7"
              prop="phone"
              label="手机"
              align="center"
            ></el-table-column>
            <el-table-column
              key="8"
              prop="wechat"
              label="微信"
              align="center"
            ></el-table-column>
            <el-table-column
              key="8.8"
              prop="cmanager.name"
              label="渠道经理"
              align="center"
            >
            </el-table-column>
            <el-table-column
              key="8.9"
              prop="amanager.name"
              label="客户经理"
              align="center"
            >
            </el-table-column>
            <el-table-column
              key="9.8"
              prop="planner"
              label="规划师"
              align="center"
            >
            </el-table-column>
            <el-table-column key="9" prop="编辑" width="45px" align="center">
              <template slot-scope="scope">
                <el-button
                  class="edit listedit"
                  @click="sEdit(scope.$index, scope.row)"
                >
                  <i class="el-icon-edit"></i>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column key="10" prop="删除" width="45px" align="center">
              <template slot-scope="scope">
                <el-button
                  class="listedit"
                  @click="jDelete(scope.$index, scope.row)"
                >
                  <i class="el-icon-delete"></i>
                </el-button>
              </template>
            </el-table-column>
            <div slot="append" style="text-align: center; font-size: 16px">
              <span v-if="has_next">
                <i class="eliwise-loading"></i>加载中请稍后...
              </span>
              <i v-else>--没有数据了--</i>
            </div>
          </el-table>
        </div>
        <!-- 编辑学生弹窗 -->
        <editStudent ref="editStudent" />
      </div>
    </div>
  </el-main>
</template>

<script>
/* eslint-disable no-console */
import axios from '../axios'
import { MessageBox } from 'element-ui'
import _ from 'lodash'
import editStudent from '../pub/studentDialog/editStudent'
import changerole from '../pub/changerole'
export default {
  name: 'Aaccount',
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入性别'))
      } else if (value === '男' || value === '女') {
        callback()
      } else {
        callback(new Error('请输入正确字符，“男”或“女”'))
      }
    }
    return {
      rules: {
        username: [
          { required: true, message: '请填写用户名', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
        direction: [{ required: true, message: '请填写方向', trigger: 'blur' }],
        school: [
          { required: true, message: '请填写学校名称', trigger: 'blur' }
        ],
        cellphone: [
          { required: true, message: '请填写电话号码', trigger: 'blur' }
        ],
        sex: [{ validator: validatePass, trigger: 'blur' }],
        salesman: [{ required: true, message: '请选择销售', trigger: 'blur' }],
        ypassword: [
          { required: true, message: '请填写原密码', trigger: 'blur' }
        ],
        npassword: [
          { required: true, message: '请填写新密码', trigger: 'blur' }
        ],
        rpassword: [
          { required: true, message: '请再次填写新密码', trigger: 'blur' }
        ]
      },
      ruleform: {
        ypassword: '',
        npassword: '',
        rpassword: ''
      },
      option: [
        {
          value: '1',
          label: '规划师'
        },
        {
          value: '2',
          label: '渠道经理'
        },
        {
          value: '3',
          label: '客户经理'
        },
        {
          value: '4',
          label: '修改密码'
        }
      ],
      value: '规划师',
      smes: {
        name: '',
        username: '',
        school: '',
        cellphone: '',
        email: '',
        misc: '',
        direction: '',
        wechat: '',
        sponser: '',
        salesman: ''
      },
      tableHeight: window.innerHeight - 150,
      dialog_title: '添加学员信息',
      tableData1: [],
      accmessage: '',
      islookcolumn: false,
      userid: '',
      sales: [],
      pagenumber: 1,
      has_next: true,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近半年',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 182.5)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一年',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },

  created () {
    this.getS()
  },
  mounted () {
    const that = this
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 150
      })()
    }
    this.dom = this.$refs.tableScroll.bodyWrapper
    this.dom.addEventListener('scroll', () => {
      let scrollTop = this.dom.scrollTop
      let windowHeight = this.dom.clientHeight || this.dom.clientHeight
      let scrollHeight = this.dom.scrollHeight || this.dom.scrollHeight
      if (scrollTop + windowHeight >= scrollHeight && this.has_next) {
        this.pagenumber = this.pagenumber + 1
        axios
          .get('/planner/students/', {
            params: {
              name: this.accmessage,
              page: this.pagenumber
            }
          })
          .then(response => {
            if (response.data.has_previous) {
              setTimeout(
                (response => {
                  this.tableData1 = this.tableData1.concat(
                    response.data.students
                  )
                  this.has_next = response.data.has_next
                }).bind(this, response),
                500
              )
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    })
  },
  methods: {
    search () {
      this.getS()
    },
    getS: _.debounce(function () {
      this.pagenumber = 1
      this.dom.scrollTop = 0
      this.getStudent()
    }, 500),
    getStudent () {
      axios
        .get('/planner/students/', {
          params: {
            name: this.accmessage
          }
        })
        .then(response => {
          this.tableData1 = response.data.students
          this.has_next = response.data.has_next
        })
        .catch(error => {
          console.log(error)
        })
    },
    jDelete (index, row) {
      MessageBox.confirm('是否删除该用户').then(() => {
        axios
          .delete(`/planner/student/${row.id}/`, {
            params: {
              student: row.id
            }
          })
          .then(response => {
            if (response.status === 200) {
              this.$message({
                showClose: true,
                message: '已删除',
                type: 'success'
              })
              this.getStudent()
            }
          })
          .catch(error => {
            this.$message({
              showClose: true,
              message: `删除失败,${error.response.data.error_message}`,
              type: 'error'
            })
          })
      })
    },
    update: async function () {
      let pageTotal = Math.ceil(this.tableData1.length / 30)
      let res = []
      let res1 = {}
      for (let i = 1; i <= pageTotal; i++) {
        res1 = await axios.get('/planner/students/', {
          params: {
            name: this.accmessage,
            page: i
          }
        })
        res.push(...res1.data.students)
      }
      this.tableData1 = res
    },
    sEdit (index, row) {
      this.userid = row.id
      this.activeInedx = index
      axios.get(`/planner/student/${row.id}/`).then(response => {
        this.smes = response.data
        this.$refs.editStudent.openDialog(this.smes)
      })
    }
  },
  components: {
    editStudent,
    changerole
  }
}
</script>
