<template>
  <el-dialog
    title="启动邮件（to导师）"
    :visible="true"
    :close-on-click-modal="false"
  >
    <div v-if="step === 1">
      <p>{{ teacher.name }}-{{ teacher.cellphone }}</p>
      <p style="margin-top:25px">
        【启动】{{ project.project_type }}项目-{{ student.name }}
      </p>
      <p>学生信息详见学生信息表：学生信息表</p>
      <p v-if="project.attachments.length > 0">
        附件：<a
          :href="item.url"
          v-for="item in project.attachments"
          :key="item.id"
          target="_blank"
          >{{ item.name }} </a
        >，
      </p>
      <p style="margin-top:20px;width: calc(100% - 6px);">
        <el-input class="teacherName" v-model="teacherName"></el-input>
      </p>
      <p style="line-height:2">
        这个学生报名的是“<el-input
          v-model="projectName"
          style="width:calc(100% - 125px)"
          class="bold"
        ></el-input
        >” 方向的<span class="bold">{{ project.project_type }}</span
        >项目，现在已经缴费了。目前已为学生创立账号，近期规划师会建立微信辅导群，学生预计
        <el-input
          v-model="project.startTime"
          style="color:red;width:100px"
          class="red"
        ></el-input
        >开始，
        <el-input
          v-model="project.startType"
          style="color:red;width:60px"
          class="red"
        ></el-input
        >展开。如有任何问题可以随时与我联系。辛苦啦~
      </p>
      <p
        style="margin-top:20px"
        v-if="
          project.project_type.indexOf('学习型科研') > -1 &&
            project.channel_type === 'inst'
        "
      >
        学生来源：{{ project.channel.abbreviation }}
      </p>
      <p v-if="project.project_type.indexOf('学习型科研') > -1">
        学生备注：<br />
        <span style="white-space: pre-wrap;">
          <span
            v-if="project.channel_type === 'inst'"
            style="color:red;font-weight:600;"
            >导师向学生介绍自己为与{{
              this.project.channel.abbreviation
            }}合作导师<br
          /></span>
          1. 学习型科研没有面试。一共四次辅导沟通，周期一个月。<br />
          2.
          在建立辅导群之后，正式辅导之前，导师和学生做一次简短的沟通（不算辅导），向学生介绍一下项目内容及辅导安排。<br />
          3. 项目产出：科研报告+推荐信
          <el-input
            v-model="project.studentMisc"
            type="textarea"
            class="misc"
            :rows="9"
          ></el-input>
        </span>
      </p>
      <p>
        启动备注：<span style="white-space: pre-wrap">
          {{
            $store.state.baseType[project.project_type] === '科研' &&
            project.project_type.indexOf('学习型科研') === -1
              ? '\n1.项目产出：科研报告+推荐信'
              : $store.state.baseType[project.project_type] === '学术指导'
              ? '\n1.项目产出：会议论文+推荐信'
              : ''
          }}

          <el-input
            v-model="project.startMisc"
            type="textarea"
            class="misc"
            :rows="9"
          ></el-input>
        </span>
      </p>
      <p>展信安，</p>
      <br /><br />
      <p>
        李林子<br />
        教务<br />
        教学产品部<br />
        菁英怀思学院<br />
        M: +86 186-1817-7700<br />
        W: www.elitebackground.cn<br />
        A: 北京市海淀区学院南路15号北发大厦B座1028
      </p>
    </div>
    <div v-else-if="step === 2">
      <p>{{ title }}</p>
      <div v-html="content"></div>
    </div>
    <div slot="footer">
      <el-button @click="close">{{ step === 1 ? '取消' : '返回' }}</el-button>
      <el-button type="primary" @click="next" :disabled="pending">{{
        step === 1 ? '预览' : '发送'
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import axios from 'axios'
import { MessageBox } from 'element-ui'
export default {
  name: 'startMail',
  data () {
    return {
      pending: false,
      teacher: {},
      student: {},
      project: {
        startMisc: '',
        otherMisc: '',
        startType: '远程',
        studentMisc: ''
      },
      step: 1,
      teacherName: '',
      content: '',
      title: '',
      projectName: ''
    }
  },
  created () {
    this.student = this.$parent.$parent.smes
    this.project = { ...this.project, ...this.$parent.$parent.activeProject }
    this.project.studentMisc = this.project.misc.split('&&^^')[0]
    this.project.startMisc = this.project.misc.split('&&^^')[1] || ''
    this.projectName = this.project.projectName
    axios
      .get(`/${this.$store.state.roleApi}/getMailInfo`, {
        params: {
          id: this.project.id
        }
      })
      .then(response => {
        let project = response.data.project
        if (this.project.isPacked) {
          let getType = function (x) {
            if (x.indexOf('论文') > -1) {
              return '论文'
            } else if (x.indexOf('科研') > -1) {
              return '科研'
            } else if (x.indexOf('学术指导') > -1) {
              return '学术指导'
            } else if (x.indexOf('Research') > -1) {
              return 'rp'
            }
          }
          let sort = {
            科研: 1,
            论文: 2,
            学术指导: 3,
            rp: 4
          }
          let types = [this.project.project_type]
          project.pack.forEach(e => {
            types.push(e.project.project_type)
          })
          types.sort((a, b) => {
            return sort[getType(a)] - sort[getType(b)]
          })
          this.project.project_type = types.join('+')
        }
        this.teacher = project.teacher
        this.teacherName = this.teacher.name[0] + '老师，'
      })
  },
  methods: {
    async next () {
      this.pending = true
      if (this.step === 2) {
        MessageBox.confirm('确定发送？')
          .then(() => {
            axios
              .post(`/${this.$store.state.roleApi}/sendMail`, {
                id: this.project.id,
                title: this.title,
                content: this.content
              })
              .then(response => {
                if (response.status === 200) {
                  this.$message({
                    showClose: true,
                    message: `发送成功`,
                    type: 'success'
                  })
                  this.$parent.$parent.mail = false
                }
              })
          })
          .catch(() => {
            this.pending = false
          })
      }
      if (this.step === 1) {
        let url = ''
        await axios
          .get(`/${this.$store.state.roleApi}/projectInfo_url`, {
            params: {
              id: this.project.id
            }
          })
          .then(response => {
            url = response.data.url
          })
        this.title = `【启动】${this.project.project_type}项目-${this.student.name}`
        this.content = `
        <p>学生信息详见学生信息表：<a href="${url}" target="_blank">学生信息表</a></p>
        ${
          this.project.attachments.length > 0
            ? `<p>附件：${this.project.attachments.map(item => {
              return `<a href=${item.url} target="_blank">${item.name}</a>，`
            })}</p>`
            : ''
        }
        <p style="margin-top:20px">
        ${this.teacherName}
        </p>
        <p style="line-height:2">这个学生报名的是
          “<span style="font-weight:600">${
            this.projectName
          }</span>”方向的<span style="font-weight:600">${
          this.project.project_type
        }</span>项目，现在已经缴费了。
          目前已为学生创立账号，近期规划师会建立微信辅导群，学生预计<span style="color:red">${
            this.project.startTime
          }</span>开始，
          <span style="color:red">${
            this.project.startType
          }</span>展开。如有任何问题可以随时与我联系。辛苦啦~
        </p>
        ${
          this.project.project_type.indexOf('学习型科研') > -1 &&
          this.project.channel_type === 'inst'
            ? `<p>学生来源：${this.project.channel.abbreviation}</p>`
            : ''
        }
        ${
          this.project.project_type.indexOf('学习型科研') > -1
            ? `<p>学生备注：<span style="white-space: pre-wrap;">
${
  this.project.channel_type === 'inst'
    ? `<span style="color:red;font-weight:600">导师向学生介绍自己为与${this.project.channel.abbreviation}合作导师</span>
`
    : ''
}1. 学习型科研没有面试。一共四次辅导沟通，周期一个月。
2. 在建立辅导群之后，正式辅导之前，导师和学生做一次简短的沟通（不算辅导），向学生介绍一下项目内容及辅导安排。
3. 项目产出：科研报告+推荐信
${this.project.studentMisc}</span></p>`
            : ''
        }
      ${
        this.project.startMisc === '' &&
        this.$store.state.baseType[this.project.project_type] !== '学术指导' &&
        this.$store.state.baseType[this.project.project_type] !== '科研'
          ? ''
          : `<p>启动备注：<span style="white-space: pre-wrap;">
${
  this.$store.state.baseType[this.project.project_type] === '科研' &&
  this.project.project_type.indexOf('学习型科研') === -1
    ? '1.项目产出：科研报告+推荐信\n'
    : this.$store.state.baseType[this.project.project_type] === '学术指导'
    ? '1.项目产出：会议论文+推荐信\n'
    : ''
}${this.project.startMisc}</span></p>`
      }
        <p>展信安，</p><br /><br />
        <p>李林子<br />
          教务<br />
          教学产品部<br />
          菁英怀思学院<br />
          M: +86 186-1817-7700<br />
          W: www.elitebackground.cn<br />
          A: 北京市海淀区学院南路15号北发大厦B座1028</p>
        `
        this.step = 2
        this.pending = false
      }
    },
    close () {
      if (this.step === 1) this.$parent.$parent.mail = false
      this.step--
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body {
  text-align: left;
  color: #656d78;
  font-size: 14px;
}
/deep/.el-input__inner {
  color: #656d78;
}
.el-textarea {
  vertical-align: top;
}
.red {
  /deep/.el-input__inner {
    color: red;
  }
}
.bold {
  font-weight: 600;
  /deep/.el-input__inner {
    font-weight: 600;
  }
}
</style>
