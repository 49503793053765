<template>
  <el-main class="main">
    <div>
      <div class="jtitle">
        <h2 class="jtext">投稿学员</h2>
      </div>
      <div class="table">
        <div class="search flex">
          <div class="seaicon">
            <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
          </div>
          <el-input
            v-model="studentname"
            @input="search"
            class="seasubfix"
          ></el-input>
        </div>
        <div>
          <el-table
            :data="conference"
            ref="tableScroll"
            :height="tableHeight"
            :cell-style="{ 'vertical-align': 'top' }"
            :row-class-name="finishclass"
            @cell-mouse-enter="hoverRow"
            @cell-mouse-leave="leaveRow"
          >
            <el-table-column
              key="1"
              prop="full_id"
              label="论文ID"
              align="center"
              min-width="10%"
            />
            <el-table-column
              key="2"
              prop="name"
              label="学生"
              align="center"
              min-width="5%"
            />
            <el-table-column
              key="3"
              prop="m_name"
              label="简称"
              align="center"
              min-width="10%"
            />
            <el-table-column
              key="4"
              label="论文题目"
              align="center"
              min-width="20%"
            >
              <template slot-scope="scope">
                <div class="pwrap">
                  <div class="pcontant">{{ scope.row.paper_title }}</div>
                  <i
                    v-if="scope.row.status === 1"
                    style="cursor: pointer"
                    class="el-icon-edit"
                    @click="changePtitle(scope.$index, scope.row)"
                  ></i>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              key="5"
              prop="teacher"
              label="导师"
              align="center"
              min-width="5%"
            />
            <el-table-column
              key="6"
              prop="amanager"
              label="客户经理"
              align="center"
              min-width="10%"
            />
            <el-table-column
              key="7"
              prop="planner"
              label="规划师"
              align="center"
              min-width="10%"
            />
            <el-table-column
              key="8"
              prop="assistant"
              label="助教"
              align="center"
              min-width="10%"
            />
            <el-table-column
              key="9"
              align="center"
              min-width="10%"
              width="100px"
            >
              <template slot-scope="scope">
                <informationButton :pid="scope.row.pid"> </informationButton>
              </template>
            </el-table-column>
            <el-table-column
              key="10"
              align="center"
              width="100px"
              min-width="10%"
            >
              <template slot-scope="scope">
                <el-popover
                  ref="popover"
                  placement="top"
                  trigger="hover"
                  content="已结项"
                  :disabled="scope.row.status === 1"
                >
                </el-popover>
                <div v-popover:popover>
                  <el-button
                    v-if="scope.row.id === ''"
                    type="primary"
                    @click="addId(scope.$index, scope.row)"
                    :disabled="scope.row.status === 2"
                  >
                    添加ID
                  </el-button>
                  <el-button
                    v-else
                    type="primary"
                    @click="addId(scope.$index, scope.row)"
                    :disabled="scope.row.status === 2"
                  >
                    修改ID
                  </el-button>
                </div>
              </template>
            </el-table-column>
            <div slot="append" style="text-align: center; font-size: 25px">
              <i class="eliwise-loading" v-if="has_next"></i>
              <i v-else style="font-size: 16px">--没有数据了--</i>
            </div>
          </el-table>
        </div>
        <el-dialog
          :title="idtitle"
          :visible.sync="idVisible"
          width="500px"
          :close-on-click-modal="false"
        >
          <div class="addwrap">
            <div class="addname">
              学生：<span>{{ sname }}</span>
              <p class="addp">论文ID*</p>
            </div>
            <div class="addinput">
              <span>{{ paper_id }}_</span>
              <el-form :model="numberValidateForm" ref="numberValidateForm">
                <el-form-item
                  prop="age"
                  :rules="[{ required: true, message: '编号不能为空' }]"
                >
                  <el-input
                    v-model="numberValidateForm.age"
                    class="idwid"
                    placeholder="编号"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <span slot="footer">
            <el-button @click="idVisible = false">取 消</el-button>
            <el-button @click="handleAdd" type="primary">确 定</el-button>
          </span>
        </el-dialog>
        <el-dialog title="编辑论文题目" :visible.sync="pDialog" width="30%">
          <el-form :model="putPtitle" :rules="putrule">
            <el-form-item label="论文题目" prop="putp">
              <el-input v-model="putPtitle.paperTitle"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer">
            <el-button @click="cancelP">取 消</el-button>
            <el-button type="primary" @click="handlePut">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </el-main>
</template>

<script>
import axios from '../axios'
import { debounce } from 'lodash'
import informationButton from '../pub/buttons/informationButton'
export default {
  name: 'MSinterview',
  components: {
    informationButton
  },
  data () {
    return {
      numberValidateForm: {
        age: ''
      },
      numberId: {
        addinput: ''
      },
      idVisible: false,
      studentname: '',
      loading: false,
      conference: [],
      tableHeight: window.innerHeight - 155,
      has_next: false,
      sid: '',
      sname: '',
      paper_id: '',
      id: '',
      full_id: '',
      pDialog: false,
      putPtitle: {
        paperTitle: ''
      },
      proId: '',
      putrule: {
        putp: [{ required: true, message: '请填写论文题目', trigger: 'blur' }]
      },
      idtitle: '',
      page: 1
    }
  },
  mounted () {
    this.nextTime()
    this.dom = this.$refs.tableScroll.bodyWrapper
    this.dom.addEventListener('scroll', this.getMore)
  },
  methods: {
    getMore () {
      let scrollTop2 = this.dom.scrollTop
      let windowHeight2 = this.dom.clientHeight || this.dom.clientHeight
      let scrollHeight2 = this.dom.scrollHeight || this.dom.scrollHeight
      if (scrollTop2 + windowHeight2 >= scrollHeight2 - 1 && this.has_next) {
        this.page++
        axios
          .get('/meeting/students', {
            params: {
              name: this.studentname,
              page: this.page
            }
          })
          .then(res => {
            this.conference.concat(res.data.meeting)
            this.conference.forEach(function (item) {
              item.full_id = item.paper_id + '_' + item.id
            })
            this.has_next = res.data.has_next
          })
      }
    },
    cancelP () {
      this.pDialog = false
      this.putPtitle.paperTitle = ''
    },
    handlePut () {
      if (this.putPtitle.paperTitle === '') {
        this.$message({
          message: '请填写论文题目',
          type: 'warning'
        })
      } else {
        axios
          .put(`meeting/project/${this.proId}/paper_title`, {
            paper_title: this.putPtitle.paperTitle
          })
          .then(res => {
            if (res.status === 200) {
              this.pDialog = false
              this.nextTime()
            }
          })
      }
    },
    hoverRow (row) {
      row.showPtitle = true
      this.$set(this.conference, row.index, row)
    },
    leaveRow (row) {
      row.showPtitle = false
      this.$set(this.conference, row.index, row)
    },
    nextTime () {
      axios
        .get(`/meeting/students`, {
          name: '',
          page: 1
        })
        .then(res => {
          this.conference = res.data.meeting
          this.conference.forEach(function (item) {
            item.full_id = item.paper_id + '_' + item.id
          })
          this.conference.map((item, index) => {
            item.showPtitle = false
            item.index = index
          })
          this.has_next = res.data.has_next
        })
    },
    changePtitle (index, row) {
      this.pDialog = true
      let putP = row.paper_title
      this.putPtitle.paperTitle = putP
      this.proId = row.pid
    },
    finishclass ({ row }) {
      if (row.status === 2) {
        return 'finish'
      }
    },
    addId (index, row) {
      this.sid = row.pid
      this.paper_id = row.paper_id
      this.sname = row.name
      this.numberValidateForm.age = row.id
      this.idVisible = true
      if (row.id === '') {
        this.idtitle = '添加ID'
      } else {
        this.idtitle = '修改ID'
      }
    },
    handleAdd () {
      if (this.numberValidateForm.age === '') {
        this.$message({
          message: '请填写编号',
          type: 'warning'
        })
      } else {
        axios
          .put(`meeting/project/${this.sid}/Paperid`, {
            paper_id: this.numberValidateForm.age
          })
          .then(response => {
            if (response.status === 200) {
              window.location.reload()
            }
          })
      }
    },
    search: debounce(function () {
      axios
        .get('/meeting/students', {
          params: {
            name: this.studentname,
            page: 1
          }
        })
        .then(res => {
          this.conference = res.data.meeting
          this.conference.forEach(function (item) {
            item.full_id = item.paper_id + '_' + item.id
          })
          this.has_next = res.data.has_next
        })
    }, 1000)
  }
}
</script>
<style lang="scss" scoped>
/deep/.finish {
  color: #c0c0c0;
}
.addwrap {
  .addinput {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
}
.pwrap {
  display: flex;
  align-items: center;
}
.pcontant {
  flex: 1;
  word-break: normal;
  word-wrap: break-word !important;
}
.el-icon-edit {
  width: 13px;
  height: 13px;
  padding: 5px;
}
.el-icon-edit:hover {
  background-color: #c0c0c0;
  opacity: 0.7;
}
/deep/
  .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk)
  .el-form-item__label-wrap
  > .el-form-item__label:before {
  color: #000;
  position: relative;
  left: 70px;
}
/deep/ .el-form-item__label {
  position: relative;
  left: -10px;
}
/deep/ .el-dialog .el-input__inner {
  height: 30px;
}
.idwid {
  width: 384px;
  margin-left: 5px;
  height: 30px;
}
/deep/.el-table .cell {
  text-overflow: clip;
}
</style>
