<template>
  <el-dialog
    :title="info.uploadTitle"
    :visible.sync="active"
    width="539px"
    class="mianDialog mian-upload"
    :close-on-click-modal="false"
  >
    <p style="margin-bottom:10px">
      {{ info.project_type }} -- {{ info.tname }} -- {{ info.sname }}
    </p>
    <div>
      <p style="margin-bottom:2px">文件类型*</p>
      <el-tooltip
        popper-class="upload-tooltip"
        effect="light"
        :disabled="!isFinal || uploadFile.length === 0"
        content="进选会的论文终稿仅支持单独上传，请将进选会的终稿上传成功后，再上传其他文件"
      >
        <el-select
          v-model="type_radio"
          placeholder="请选择文件类型"
          style="width: 100%"
          @change="getOptions"
          :disabled="isFinal && uploadFile.length > 0"
        >
          <el-option v-for="item in file_option" :key="item" :value="item">
          </el-option>
        </el-select>
      </el-tooltip>
    </div>
    <div v-if="type_radio !== ''">
      <p style="text-align: left">上传文件*</p>
      <p class="uploadNotice" v-if="isFinal || get_mail()">
        {{ type_radio }}仅支持上传一个文件
      </p>
      <p class="uploadNotice" v-else>
        支持同时上传多个文件
      </p>
      <div
        v-if="
          (api === 'assistant' || api === 'adirector') &&
            type_radio.indexOf('终稿') > -1 &&
            get_final()
        "
        @click="ifFinal = true"
        class="checked-dco"
      >
        <el-tooltip
          popper-class="upload-tooltip"
          effect="light"
          placement="bottom-start"
          :disabled="!finalActive"
          content="进入选会的论文终稿仅支持单独上传,请将现有文件上传成功后,再单独上传进选会的论文终稿"
        >
          <el-checkbox v-model="isFinal" :disabled="finalActive"
            >文件为终稿
          </el-checkbox>
        </el-tooltip>
        <div v-if="isFinal" class="type">
          <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :on-remove="handleRemove"
            drag
            :limit="1"
            :on-exceed="handleExceed"
            :on-success="handleSuccessFinal"
            :on-error="handleError"
            :beforeUpload="beforeFileUpload"
            :disabled="uploadable"
            ref="upload"
          >
            <div @click="typeSelected">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em style="color: #8dc152; text-decoration: underline">
                  点击上传
                </em>
              </div>
            </div>
          </el-upload>
          <el-form :model="finalPaper">
            <el-form-item label="论文题目*" prop="paperTitle">
              <el-input v-model="finalPaper.paperTitle"></el-input>
            </el-form-item>
            <el-form-item label="终稿备注" prop="finalMark">
              <el-input
                type="textarea"
                v-model="finalPaper.finalMark"
                rows="3"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-upload
        class="upload-demo"
        :action="uploadUrl"
        :on-remove="handleRemove"
        drag
        multiple
        :limit="get_mail() ? 1 : 10"
        :on-exceed="handleExceed"
        :on-success="handleSuccess"
        :on-error="handleError"
        :beforeUpload="beforeFileUpload"
        :disabled="uploadable"
        v-if="!isFinal"
        ref="upload"
      >
        <div @click="typeSelected">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em style="color: #8dc152; text-decoration: underline">
              点击上传
            </em>
          </div>
        </div>
      </el-upload>
      <el-form
        :model="mail"
        v-if="get_mail()"
        ref="mailForm"
        :rules="rules"
        :hide-required-asterisk="true"
      >
        <el-form-item label="邮件标题*" prop="title">
          <el-select v-model="mail.title">
            <el-option v-for="item in options" :key="item" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="deadline*" prop="deadline">
          <el-date-picker
            v-model="mail.deadline"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注" prop="misc">
          <el-input type="textarea" v-model="mail.misc" rows="6"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="finish" :disabled="buttonDisabled"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'

export default {
  name: 'uploadForm',
  data () {
    return {
      pid: 0,
      active: false,
      uploadUrl: `${axios.defaults.baseURL}/upload/`,
      uploadTime: '',
      uploadFile: [],
      type_radio: '',
      uploadable: false,
      buttonDisabled: false,
      info: '',
      file_option: [],
      uploadFileNum: 0,
      isFinal: false,
      finalActive: false,
      finalPaper: {
        paperTitle: '',
        finalMark: ''
      },
      rules: {
        title: [{ required: true, message: '请填写邮件标题', trigger: 'blur' }],
        deadline: [
          { required: true, message: '请选择deadline', trigger: 'change' }
        ]
      },
      mail: {
        title: '',
        deadline: '',
        misc: ''
      },
      options: [],
      pickerOptions: {
        disabledDate (time) {
          const curDate = new Date().getTime()
          const one = 24 * 3600 * 1000
          const now = curDate - one
          return time.getTime() <= now
        }
      }
    }
  },
  props: {
    api: String
  },
  watch: {
    type_radio: function (newVal) {
      if (newVal !== null) this.uploadable = false
      this.isFinal = false
    },
    uploadFile: function (newVal) {
      if (newVal.length > 0 && this.isFinal !== true) this.finalActive = true
      else this.finalActive = false
    },
    isFinal: function () {
      this.uploadFile = []
    }
  },
  methods: {
    get_mail () {
      if (
        this.type_radio.indexOf('待翻译') > -1 ||
        this.type_radio.indexOf('待润色') > -1
      ) {
        return true
      } else return false
    },
    get_final () {
      switch (this.projectType()) {
        case 'e':
          return true
        case 'l':
          return true
        case 'x':
          return true
        case 'i':
          return true
        case 'k':
          if (this.info.project_type === 'Lite') { return true } else { return false }
        default:
          return false
      }
    },
    getOptions () {
      if (this.get_mail()) {
        let student = this.$parent.$parent.smes.name
        switch (this.type_radio) {
          case 'RP-待翻译':
            this.mail.title = `【RP翻译】-${student}`
            this.options = [
              `【RP翻译】-${student}`,
              `加急 【RP翻译】-${student}`
            ]
            break
          case 'RP-待润色':
            this.mail.title = `【RP润色】-${student}`
            this.options = [
              `【RP润色】-${student}`,
              `加急 【RP润色】-${student}`
            ]
            break
          case '论文-待翻译':
            this.mail.title = `【论文翻译】-${student}`
            this.options = [
              `【论文翻译】-${student}`,
              `加急 【论文翻译】-${student}`
            ]
            break
          case '论文-待润色':
            this.mail.title = `【论文润色】-${student}`
            this.options = [
              `【论文润色】-${student}`,
              `加急 【论文润色】-${student}`
            ]
            break
        }
      }
    },
    openDialog (pid, info) {
      this.info = info
      this.pid = pid
      this.selectFileOption()
      this.active = true
    },
    cancel () {
      this.active = false
      setTimeout(() => {
        this.$emit('update:closeUploadForm', false)
      }, 150)
    },
    handleRemove (file) {
      for (var i = 0; i < this.uploadFile.length; i++) {
        if (this.uploadFile[i].filekey.indexOf(file.response.key) !== -1) {
          this.uploadFile.splice(i, 1)
          this.uploadFileNum -= 1
        }
      }
    },
    handleExceed (files, fileList) {
      if (this.isFinal === true || this.get_mail()) {
        this.$message.warning(`仅支持同时上传一个文件`)
      } else {
        this.$message.warning(
          `当前限制选择 10 个文件，本次选择了 ${
            files.length
          } 个文件，共选择了 ${files.length + fileList.length} 个文件`
        )
      }
    },
    typeSelected () {
      if (!this.type_radio) {
        this.$message({
          message: '请选择文件类型',
          type: 'warning'
        })
        this.uploadable = true
        return false
      }
      return true
    },
    beforeFileUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 50
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 50MB!',
          type: 'warning'
        })
      }
      return isLt2M && this.typeSelected()
    },
    // 文件上传成功时的钩子
    handleSuccess (res) {
      this.$message({
        showClose: true,
        message: `文件上传成功`,
        type: 'success'
      })
      const timestamp = new Date().getTime()
      this.uploadTime = timestamp
      this.uploadFile.push({
        filekey: res.key,
        file_type: this.type_radio
      })
      // 已上传列表 + 上传文件类型
      var temp =
        this.type_radio +
        ': ' +
        this.$refs.upload.uploadFiles[this.uploadFileNum].name
      this.$refs.upload.uploadFiles[this.uploadFileNum].name = temp
      this.uploadFileNum++
    },
    // 终稿文件上传成功时的钩子
    handleSuccessFinal (res) {
      this.$message({
        showClose: true,
        message: `文件上传成功`,
        type: 'success'
      })
      const timestamp = new Date().getTime()
      this.uploadTime = timestamp
      this.uploadFile = [
        {
          filekey: res.key,
          file_type: this.type_radio,
          isfinal: this.isFinal
        }
      ]
      this.$refs.upload.uploadFiles[this.uploadFileNum].name =
        this.type_radio + ': ' + this.$refs.upload.uploadFiles[0].name
    },
    // 文件上传失败时的钩子
    handleError () {
      this.$message({
        showClose: true,
        message: `文件上传失败`,
        type: 'error'
      })
    },
    finish () {
      if (this.uploadFile.length === 0) {
        this.$message({
          message: '请上传文件',
          type: 'warning'
        })
        return true
      } else if (this.finalPaper.paperTitle === '' && this.isFinal === true) {
        this.$message({
          message: '请填写论文题目',
          type: 'warning'
        })
        return true
      } else {
        let postData = { uploadFile: this.uploadFile }
        if (this.get_mail()) {
          let x = ''
          this.$refs.mailForm.validate(valid => {
            x = valid
          })
          if (!x) return 0
          postData.mail = this.mail
        }
        MessageBox.confirm('是否确认提交？').then(() => {
          this.buttonDisabled = true
          if (this.isFinal === true) {
            this.uploadFile[0].finalmark = this.finalPaper.finalMark
            this.uploadFile[0].paper_title = this.finalPaper.paperTitle
          }
          axios
            .post(`/${this.api}/project/${this.pid}/paper`, postData)
            .then(response => {
              if (response.status === 200) {
                this.$message({
                  showClose: true,
                  message: '上传成功',
                  type: 'success'
                })
                this.active = false
                setTimeout(() => {
                  this.$emit('update:closeUploadForm', false)
                }, 150)
              }
            })
        })
      }
    },
    selectFileOption () {
      switch (this.api) {
        case 'teacher':
          this.file_option = ['作业', '参考文件', '批注']
          switch (this.projectType()) {
            case 'i':
              this.file_option.push('论文终稿')
              break
            case 'e':
              this.file_option.push(`${this.info.project_type}文件`)
              break
            case 'h':
              this.file_option = ['论文初稿', '作业', '论文终稿', '查重报告']
              break
          }
          break
        case 'assistant':
        case 'adirector':
          switch (this.projectType()) {
            case 'r':
              this.file_option = ['作业', 'RP终稿', 'RP-待翻译', 'RP-待润色', '评价']
              break
            case 'k':
              this.file_option = ['作业', '科研报告', '推荐信-最终版', '评价']
              if (this.info.project_type === 'Lite') { this.file_option.push('论文终稿') }
              break
            case 'e':
              this.file_option = [
                '作业',
                '论文终稿',
                '论文-待翻译',
                '论文-待润色'
              ]
              break
            case 'h':
              this.file_option = ['论文初稿', '作业', '论文终稿', '查重报告', '评价']
              break
            case 'l':
              this.file_option = [
                '作业',
                '论文终稿',
                '查重报告',
                '论文-待翻译',
                '论文-待润色',
                '评价'
              ]
              break
            case 'x':
              this.file_option = [
                '作业',
                '论文终稿',
                '查重报告',
                '论文-待翻译',
                '论文-待润色',
                '推荐信-最终版',
                '评价'
              ]
              break
            case 'i':
              this.file_option = [
                '作业',
                '论文终稿',
                '论文-待翻译',
                '论文-待润色',
                '其他',
                '评价'
              ]
              break
            default:
              this.file_option = ['作业', '其他', '评价']
          }
          break
      }
    },
    projectType () {
      if (this.$store.state.baseType[this.info.project_type] === 'RP') {
        return 'r'
      } else if (
        this.$store.state.baseType[this.info.project_type] === '科研'
      ) {
        return 'k'
      } else if (this.$store.state.baseType[this.info.project_type] === 'EPQ') {
        return 'e'
      } else if (
        this.$store.state.baseType[this.info.project_type] === '会务组论文'
      ) {
        return 'h'
      } else if (
        this.$store.state.baseType[this.info.project_type] === '论文'
      ) {
        return 'l'
      } else if (
        this.$store.state.baseType[this.info.project_type] === '学术指导'
      ) {
        return 'x'
      } else if (this.$store.state.baseType[this.info.project_type] === 'IAP') {
        return 'i'
      } else return 0
    }
  }
}
</script>
<style lang="scss">
.upload-tooltip {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  width: 260px;
  color: #656d78;
  border: 1px solid #ebeef5 !important;
  .popper__arrow {
    border-bottom-color: #ebeef5 !important;
  }
}
</style>
<style lang="scss" scoped>
.beforeUp {
  border: 0px;
  width: 100%;
  height: 180px;
  /deep/.el-button:hover,
  .el-button:focus {
    background-color: transparent;
  }
}
.uploadNotice {
  text-align: left;
  font-size: 14px;
  color: #c0c0c0;
}
/deep/.el-upload-list__item .el-icon-close-tip {
  display: none !important;
}
/deep/.el-dialog .upload-demo {
  width: 100%;
}
/deep/.el-upload {
  width: 100%;
}
/deep/.el-form {
  width: 100%;
}
/deep/.el-form-item {
  margin-bottom: 4px;
}
/deep/.el-textarea__inner {
  padding: 0px 8px;
  font-size: 12px;
}
/deep/.el-checkbox:last-of-type {
  margin-bottom: 14px;
}
.el-select {
  width: 100%;
}
/deep/.el-input {
  width: 100%;
  .el-input__prefix {
    left: 0px;
    .el-input__icon {
      line-height: 30px;
    }
  }
}
</style>
