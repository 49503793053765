<template>
  <el-main class="main">
    <retouches />
  </el-main>
</template>

<script>
import retouches from '../pub/retouches.vue'
export default {
  name: 'Kretouches',
  components: {
    retouches
  }
}
</script>
