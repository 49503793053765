<template>
  <el-row>
    <el-col :span="24">
      <div class="project_type">选择导师</div>
    </el-col>
    <el-col :span="24" class="flex border">
      <img src="../../../assets/icon/搜索@3x.png" height="20" width="20" />
      <el-input
        v-model="teacherName"
        @input="searchTeacher"
        @focus="showL"
        @blur="selected"
        placeholder="导师姓名"
        class="seasub fixseasub"
      >
      </el-input>
    </el-col>
    <el-col :span="24">
      <el-table
        v-if="showList"
        :header-cell-style="{
          background: '#F4F6F9',
          color: '#c0c0c0',
          'text-align': 'center'
        }"
        :data="tableDataSearch"
        height="240"
        @row-click="selectTeacher"
        ref="tabs"
        class="tabin base"
      >
        <el-table-column
          key=""
          prop="id"
          label="编号"
          align="center"
          min-width="10%"
        >
        </el-table-column>
        <el-table-column
          key="2"
          prop="name"
          label="导师姓名"
          align="center"
          min-width="12%"
        >
        </el-table-column>
        <el-table-column
          key="3"
          prop="organization"
          label="机构"
          align="center"
          min-width="18%"
        >
        </el-table-column>
        <el-table-column
          key="4"
          prop="research_direction"
          label="研究方向"
          align="center"
          min-width="33%"
        >
        </el-table-column>
        <el-table-column
          key="5"
          prop="email"
          label="邮箱"
          align="center"
          min-width="27%"
        >
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>

<script>
import axios from 'axios'
import { debounce } from 'lodash'
import { MessageBox } from 'element-ui'
let component = {
  name: 'baseMail',
  data () {
    return {
      showList: false,
      teacherName: '',
      tableDataSearch: [],
      pending: false,
      scrollTop: 0,
      dialogName: ''
    }
  },
  created () {
    this.searchTeacher()
  },
  methods: {
    selected () {
      setTimeout(() => {
        this.showList = false
      }, 400)
    },
    searchTeacher: debounce(function () {
      this.pagenumber = 1
      if (this.$refs.tabs) {
        this.$refs.tabs.bodyWrapper.scrollTop = 0
      }
      axios
        .get(`/${this.$store.state.roleApi}/teachers`, {
          params: {
            name: this.teacherName,
            page: this.pagenumber
          }
        })
        .then(response => {
          this.has_next = response.data.has_next
          this.tableDataSearch = response.data.teachers
        })
    }, 500),
    selectTeacher (row) {
      this.teacherName = row.name
      this.$parent.$parent.teacher = row
      this.$parent.$parent.teacherName = row.name[0] + '老师，'
    },
    showL () {
      this.showList = true
    }
  }
}
export default component
let baseMailMixin = {
  components: {
    baseMail: component
  },
  data () {
    return {
      teacher: {},
      student: {},
      project: {
        otherMisc: '',
        startMisc: ''
      },
      step: 1,
      teacherName: '',
      content: '',
      title: ''
    }
  },
  created () {
    this.student = this.$parent.$parent.smes
    this.project = { ...this.project, ...this.$parent.$parent.activeProject }
    this.project.startMisc = this.project.misc.split('&&^^')[1] || ''
    this.project.misc = this.project.misc.split('&&^^')[0]
  },
  methods: {
    async next () {
      this.pending = true
      if (this.step === 3) {
        MessageBox.confirm('确定发送？')
          .then(() => {
            axios
              .post(`/${this.$store.state.roleApi}/sendMail`, {
                id: this.project.id,
                title: this.title,
                content: this.content,
                tid: this.teacher.id
              })
              .then(response => {
                if (response.status === 200) {
                  this.$message({
                    showClose: true,
                    message: `发送成功`,
                    type: 'success'
                  })
                  this.$parent.$parent.mail = false
                }
              })
          })
          .catch(() => {
            this.pending = false
          })
      }
      if (this.step === 2) {
        this.$refs.dialog.$refs.dialog.scrollTop = 0
        let url = ''
        await axios
          .get(`/${this.$store.state.roleApi}/projectInfo_url`, {
            params: {
              id: this.project.id
            }
          })
          .then(response => {
            url = response.data.url
          })
        this.title = `【面试】${this.project.project_type}项目-${this.student.name}`
        this.content = `
        <div style="margin-top:20px">
          ${this.teacherName}
        </div><br />
        <div style="color:red">请在面试中确认学生基础知识水平+学生学习态度+预计大致内容/选题方向</div>
        <div style="color:red;font-size:24px;font-weight:600">在面试完成后登陆菁英启航系统记录面试信息。</div><br />
        <div>以下为面试细则。</div>
        ${
          this.project.attachments.length > 0
            ? `<div>附件：${this.project.attachments
                .map(item => {
                  return `<div><a href="${item.url}">${item.name}</a></div>`
                })
                .join('')}</div>`
            : ''
        }
        ${
          this.project.channel_type === 'inst'
            ? `<div>学生来源：${this.project.channel.abbreviation}</div>`
            : ''
        }
        <div>学生信息详见学生信息表：<a href="${url}" target="_blank">学生信息表</a></div>
        <div>
          ${
            this.project.projectName == ''
              ? ''
              : `<div>项目名称：${this.project.projectName}</div>`
          }
          ${
            this.project.startTime == ''
              ? ''
              : `<div>意向开展时间：${this.project.startTime}</div>`
          }
          <div>面试时间：${this.project.connectTime}</div>
          <p>学生备注：<span style="white-space: pre-wrap">
${
  this.project.channel_type === 'inst'
    ? `<span style="color:red;font-weight:600">导师在面试中，介绍自己身份为与${this.project.channel.abbreviation}合作导师。不得提及菁英品牌。</span><br/>`
    : ''
}${this.project.misc}</span>
          </p>
          ${
            this.project.startMisc == ''
              ? ''
              : `<p>面试备注：<span style="white-space: pre-wrap;">
${this.project.startMisc}</span></p>`
          }
          ${
            this.project.otherMisc == ''
              ? ''
              : `<p>其他信息备注：<span style="white-space: pre-wrap;">
${this.project.otherMisc}</span></p>`
          }
        </div><br />
        <div>展信安，</div><br /><br />
        <div style="border-top: 1px solid;width: 300px;"></div>
        <p>李林子<br />
          教务<br />
          教学产品部<br />
          菁英怀思学院<br />
          M: +86 186-1817-7700<br />
          W: www.elitebackground.cn<br />
          A: 北京市海淀区学院南路15号北发大厦B座1028</p>`
        this.pending = false
        this.step = 3
      }
      if (this.step === 1) {
        axios
          .get(`/${this.$store.state.roleApi}/getMailInfo`, {
            params: {
              id: this.project.id
            }
          })
          .then(response => {
            let project = response.data.project
            if (this.project.isPacked) {
              let getType = function (x) {
                if (x.indexOf('论文') > -1) {
                  return '论文'
                } else if (x.indexOf('科研') > -1) {
                  return '科研'
                } else if (x.indexOf('学术指导') > -1) {
                  return '学术指导'
                } else if (x.indexOf('Research') > -1) {
                  return 'rp'
                }
              }
              let sort = {
                科研: 1,
                论文: 2,
                学术指导: 3,
                rp: 4
              }
              let types = [this.project.project_type]
              project.pack.forEach(e => {
                types.push(e.project.project_type)
              })
              types.sort((a, b) => {
                return sort[getType(a)] - sort[getType(b)]
              })
              this.project.project_type = types.join('+')
            }
            this.pending = false
            this.step = 2
          })
      }
    },
    close () {
      if (this.step === 1) this.$parent.$parent.mail = false
      this.step--
    }
  }
}
export { baseMailMixin }
</script>
<style lang="scss">
.seasub .el-input__inner {
  background-color: #f4f6f9;
  border: solid 0px;
  padding-left: 0px;
  padding-right: 0px;
}
</style>
<style lang="scss" scoped>
.border {
  border-bottom: solid 1px #c0c0c0;
  padding-bottom: 5px;
}
.seasub {
  padding-left: 10px;
  padding-top: 14px;
  position: relative;
  bottom: 3px;
}
.tabin {
  font-size: 12px;
  color: #434a54;
  box-shadow: 0 2px 8px 0 rgba(204, 209, 217, 0.5);
  background-color: #f5f7fa;
  position: absolute;
  z-index: 100;
}
.fixseasub {
  padding-top: 0px !important;
  padding-left: 10px;
}
/deep/.el-table tbody tr:hover > td {
  background-color: #a0d469;
  color: #ffffff;
}

.project_type {
  margin-top: 12px;
  margin-bottom: 0px;
}
</style>
