<template>
  <div>
    <div class="jtitle">
      <h2 class="jtext">渠道信息</h2>
    </div>
    <div class="table">
      <!-- 搜索框 -->
      <div class="search flex">
        <div class="seaicon">
          <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
        </div>
        <el-input v-model.trim="channelName" @input="search" class="seasubfix">
        </el-input>
        <el-select
          v-model="role"
          popper-class="rolelist"
          @change="rolechange"
          class="changerole"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="add" v-if="handle">
          <img
            @click="addchannel()"
            src="../../assets/icon/添加用户.png"
            height="30"
            width="30"
          />
        </div>
      </div>
      <div>
        <!-- 渠道table -->
        <el-table
          :data="institution"
          ref="tableScroll"
          :height="tableHeight"
          :cell-style="{ 'vertical-align': 'top' }"
          v-if="role === '机构'"
          :default-sort="{ prop: 'insDate', order: 'descending' }"
          @filter-change="tablefilter"
          @scroll.native="moreInstitution"
          row-key="id"
          default-expand-all
          :tree-props="{ children: 'subcompanys', hasChildren: 'hasChildren' }"
        >
          <el-table-column
            key="1-1"
            prop="name"
            label="品牌名"
            align="center"
            min-width="100"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.headoffice">
                {{ scope.row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            key="1-2"
            prop="status"
            label="状态"
            align="center"
            width="60px"
            column-key="status"
            :filters="filtersRule"
          >
            <template slot-scope="scope">
              <el-tooltip
                placement="top"
                :content="status[scope.row.status].label"
                effect="light"
                popper-class="atooltip"
              >
                <div
                  class="circle"
                  :style="{
                    'background-color': status[scope.row.status].color
                  }"
                ></div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            key="1-3"
            prop="abbreviation"
            label="公司简称"
            align="center"
          >
          </el-table-column>
          <el-table-column key="1-4" label="对接人" align="center" width="100%">
            <template slot-scope="scope">
              <div
                v-if="
                  display ||
                    scope.row[roleApi].id == uid ||
                    !scope.row.headoffice
                "
              >
                <img
                  class="imgBut"
                  :style="{
                    transform: active[scope.$index]
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    opacity: scope.row.contacts.length > 1 ? 1 : 0
                  }"
                  @click="$set(active, scope.$index, !active[scope.$index])"
                  src="../../assets/icon/tran.png"
                  height="20"
                  width="20"
                />
                <div class="contacts">
                  <ul class="tablelist" v-if="active[scope.$index]">
                    <li
                      v-for="({ name }, index) in scope.row.contacts"
                      :key="'' + scope.$index + index"
                    >
                      {{ name }}
                    </li>
                  </ul>
                  <ul class="tablelist" v-else-if="scope.row.contacts[0]">
                    <li>
                      {{ scope.row.contacts[0].name }}
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            key="1-5"
            prop="contacts"
            label="电话"
            align="center"
          >
            <template slot-scope="scope">
              <div
                v-if="
                  display ||
                    scope.row[roleApi].id == uid ||
                    !scope.row.headoffice
                "
              >
                <ul class="tablelist" v-if="active[scope.$index]">
                  <li
                    v-for="({ cellphone }, index) in scope.row.contacts"
                    :key="'' + scope.$index + index"
                  >
                    {{ cellphone }}
                  </li>
                </ul>
                <ul class="tablelist" v-else-if="scope.row.contacts[0]">
                  <li>
                    {{ scope.row.contacts[0].cellphone }}
                  </li>
                </ul>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            key="1-6"
            prop="contacts"
            label="微信"
            align="center"
          >
            <template slot-scope="scope">
              <div
                v-if="
                  display ||
                    scope.row[roleApi].id == uid ||
                    !scope.row.headoffice
                "
              >
                <ul class="tablelist" v-if="active[scope.$index]">
                  <li
                    v-for="({ wechat }, index) in scope.row.contacts"
                    :key="'' + scope.$index + index"
                  >
                    {{ wechat }}&#32;
                  </li>
                </ul>
                <ul class="tablelist" v-else-if="scope.row.contacts[0]">
                  <li>
                    {{ scope.row.contacts[0].wechat }}
                  </li>
                </ul>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            key="1-7"
            prop="contacts"
            label="职务"
            align="center"
          >
            <template v-slot="scope">
              <div
                v-if="
                  display ||
                    scope.row[roleApi].id == uid ||
                    !scope.row.headoffice
                "
              >
                <ul class="tablelist" v-if="active[scope.$index]">
                  <li
                    v-for="({ position }, index) in scope.row.contacts"
                    :key="'' + scope.$index + index"
                  >
                    {{ position }}
                  </li>
                </ul>
                <ul class="tablelist" v-else-if="scope.row.contacts[0]">
                  <li>
                    {{ scope.row.contacts[0].position }}
                  </li>
                </ul>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            key="1-8"
            prop="insDate"
            label="录入日期"
            align="center"
          >
          </el-table-column>
          <el-table-column
            key="1-9"
            prop="cmanager.name"
            label="渠道经理"
            align="center"
          >
          </el-table-column>
          <el-table-column
            key="1-10"
            prop="amanager.name"
            label="客户经理"
            align="center"
          >
            <template v-slot="scope">
              <span
                v-if="
                  display ||
                    scope.row[roleApi].id == uid ||
                    !scope.row.headoffice
                "
              >
                {{ scope.row.amanager.name }}
              </span>
            </template>
          </el-table-column>
          <el-table-column key="1-11" align="center" label="合作协议">
            <template v-slot="scope">
              <div
                v-if="
                  display ||
                    scope.row[roleApi].id == uid ||
                    !scope.row.headoffice
                "
              >
                <u
                  v-if="
                    scope.row.agreement.length > 0 &&
                      scope.row.agreement[0].date
                  "
                  style="cursor: pointer"
                  @click="openAgreementDialog(scope.row.agreement)"
                >
                  {{ scope.row.agreement[0].date }}
                </u>
                <u
                  @click="openUploadDialog('add', scope.row.id)"
                  v-else-if="handle"
                >
                  添加协议
                </u>
              </div>
            </template>
          </el-table-column>
          <el-table-column key="1-15" label="选题单页二维码" align="center">
            <template slot-scope="scope">
              <img
                @click="qropen(scope.row)"
                src="../../assets/icon/qrcode.png"
                style="vertical-align: text-top;width:25px"
              />
            </template>
          </el-table-column>
          <el-table-column key="1-12" align="center" width="50px" v-if="handle">
            <template v-slot="scope">
              <div v-if="display || scope.row[roleApi].id == uid">
                <el-button
                  class="listedit"
                  @click="addchannel(scope.row)"
                  v-if="scope.row.headoffice"
                >
                  <i class="el-icon-circle-plus-outline"></i>
                </el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column key="1-13" align="center" width="50px" v-if="handle">
            <template slot-scope="scope">
              <div
                v-if="
                  display ||
                    scope.row[roleApi].id == uid ||
                    !scope.row.headoffice
                "
              >
                <el-button class="listedit" @click="edit(scope.row)">
                  <i class="el-icon-edit"></i>
                </el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            key="1-14"
            prop="删除"
            width="50px"
            align="center"
            v-if="handle"
          >
            <template slot-scope="scope">
              <div
                v-if="
                  display ||
                    scope.row[roleApi].id == uid ||
                    !scope.row.headoffice
                "
              >
                <el-tooltip
                  content="该渠道已绑定学生"
                  effect="light"
                  placement="top"
                  :disabled="scope.row.number === 0"
                >
                  <div>
                    <el-button
                      class="listedit"
                      @click="institutionDelete(scope.$index, scope.row)"
                      :disabled="scope.row.number > 0"
                    >
                      <i class="el-icon-delete"></i>
                    </el-button>
                  </div>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <div slot="empty" />
          <div slot="append" style="text-align: center; font-size: 16px">
            <span v-if="has_next">
              <i class="eliwise-loading"></i>加载中请稍后...
            </span>
            <i v-else>--没有数据了--</i>
          </div>
        </el-table>
        <el-table
          v-else-if="role === '全部机构'"
          :data="allInstitutions"
          row-key="id"
          default-expand-all
          :tree-props="{ children: 'subcompanys', hasChildren: 'hasChildren' }"
        >
          <el-table-column
            key="2-1"
            label="品牌名"
            prop="name"
            align="center"
            min-width="40%"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.headoffice">
                {{ scope.row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            key="2-2"
            prop="status"
            label="状态"
            align="center"
            min-width="70%"
            column-key="status"
            :filters="filtersRule"
            :filter-method="filterHandler"
          >
            <template slot-scope="scope">
              <el-tooltip
                placement="top"
                :content="status[scope.row.status].label"
                effect="light"
                popper-class="atooltip"
              >
                <div
                  class="circle"
                  :style="{
                    'background-color': status[scope.row.status].color
                  }"
                ></div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column key="2-3" label="公司简称" prop="abbreviation">
          </el-table-column>
          <el-table-column key="2-4" label="录入时间" prop="insdate">
          </el-table-column>
          <el-table-column key="2-5" label="渠道经理" prop="cmanager">
          </el-table-column>
          <el-table-column key="2-6" label="选题单页二维码" align="center">
            <template slot-scope="scope">
              <img
                @click="qropen(scope.row)"
                src="../../assets/icon/qrcode.png"
                style="vertical-align: text-top;width:25px"
              />
            </template>
          </el-table-column>
          <div slot="empty" />
          <div slot="append" style="text-align: center; font-size: 16px">
            <span v-if="has_next">
              <i class="eliwise-loading"></i>加载中请稍后...
            </span>
            <i v-else>--没有数据了--</i>
          </div>
        </el-table>
        <!-- 顾问table -->
        <el-table
          :data="consultant"
          align="center"
          :height="tableHeight"
          :cell-style="{ 'vertical-align': 'top' }"
          v-else
        >
          <el-table-column
            key="3-1"
            prop="status"
            label="状态"
            align="center"
            min-width="6%"
            column-key="status"
            :filters="filtersRule"
            :filter-method="filterHandler"
          >
            <template slot-scope="scope">
              <el-tooltip
                placement="top"
                :content="status[scope.row.status].label"
                effect="light"
                popper-class="atooltip"
              >
                <div
                  class="circle"
                  :style="{
                    'background-color': status[scope.row.status].color
                  }"
                ></div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="姓名"
            key="3-2"
            prop="name"
            min-width="6%"
            align="center"
          ></el-table-column>
          <el-table-column
            label="机构"
            key="3-3"
            prop="institution"
            min-width="10%"
            align="center"
          ></el-table-column>
          <el-table-column
            key="3-4"
            prop="cellphone"
            label="手机"
            align="center"
            min-width="10%"
          ></el-table-column>
          <el-table-column
            key="3-5"
            prop="wechat"
            label="微信"
            align="center"
            min-width="10%"
          ></el-table-column>
          <el-table-column
            key="3-6"
            prop="bank.account"
            label="银行账户"
            align="center"
            min-width="20%"
          ></el-table-column>
          <el-table-column
            key="3-7"
            prop="identity"
            label="身份证号"
            align="center"
            min-width="20%"
          ></el-table-column>
          <el-table-column
            key="3-8"
            prop="insdate"
            label="录入日期"
            align="center"
            min-width="10%"
          ></el-table-column>
          <el-table-column
            key="3-9"
            prop="salesman.name"
            label="渠道经理"
            align="center"
            min-width="10%"
          ></el-table-column>
          <el-table-column
            key="3-15"
            label="选题单页二维码"
            min-width="10%"
            align="center"
          >
            <template slot-scope="scope">
              <img
                @click="qropen(scope.row)"
                src="../../assets/icon/qrcode.png"
                style="vertical-align: text-top;width:25px"
              />
            </template>
          </el-table-column>
          <el-table-column
            key="3-10"
            prop="编辑"
            align="center"
            min-width="4%"
            v-if="handle"
          >
            <template slot-scope="scope">
              <el-button
                class="listedit"
                @click="consultantEdit(scope.$index, scope.row)"
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            key="3-11"
            prop="删除"
            align="center"
            min-width="4%"
            v-if="handle"
          >
            <template slot-scope="scope">
              <el-tooltip
                content="该渠道已绑定学生"
                effect="light"
                placement="top"
                :disabled="scope.row.number === 0"
              >
                <div>
                  <el-button
                    :disabled="scope.row.number > 0"
                    class="listedit"
                    @click="consultantDelete(scope.$index, scope.row)"
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <div slot="empty" />
          <div slot="append" style="text-align: center; font-size: 16px">
            <span v-if="has_next">
              <i class="eliwise-loading"></i>加载中请稍后...
            </span>
            <i v-else>--没有数据了--</i>
          </div>
        </el-table>
        <institutionForm ref="institutionForm" v-if="institutionForm" />
        <consultantForm ref="consultantForm" v-if="consultantForm" />
        <institutionUpload ref="uploadDialog" v-if="uploadDialog" />
        <!-- 查看协议弹窗 -->
        <el-dialog
          width="504px"
          :visible.sync="agreementDialog.active"
          title="查看协议"
        >
          <el-table :data="agreementDialog.data">
            <el-table-column
              align="center"
              min-width="50%"
              label="合作协议"
              prop="url"
            >
              <template v-slot="{ row }">
                <el-link :href="row.url" target="_blank">{{
                  row.name
                }}</el-link>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              min-width="50%"
              label="终止日期"
              prop="date"
            >
              <template v-slot="{ row }">
                {{
                  row.date.toLocaleDateString
                    ? row.date.toLocaleDateString()
                    : row.date
                }}
              </template>
            </el-table-column>
          </el-table>
          <el-button
            type="primary"
            slot="footer"
            @click="agreementDialog.active = false"
            >确认
          </el-button>
        </el-dialog>
        <el-dialog
          :visible.sync="qrcode"
          title="选题库单页二维码"
          width="550px"
        >
          <div class="qrcode-title">
            <p
              :class="{ selected: selected === 'lite' }"
              @click="qrset('lite')"
            >
              Lite
            </p>
            <p
              :class="{ selected: selected === 'standard' }"
              @click="qrset('standard')"
            >
              Standard
            </p>
            <p
              :class="{ selected: selected === 'pro' }"
              @click="qrset('pro')"
            >
              Pro
            </p>
             <p
              :class="{ selected: selected === 'proHigh' }"
              @click="qrset('proHigh')"
            >
              Pro(高中)
            </p>
            <p
              :class="{ selected: selected === 'research' }"
              @click="qrset('research')"
            >
              科研
            </p>
            <p :class="{ selected: selected === 'rt' }" @click="qrset('rt')">
              科研+论文
            </p>
             <p :class="{ selected: selected === 'xdf' }" @click="qrset('xdf')">
             XDF
            </p>
          </div>
          <div class="qrcode-content">
            <div id="qrcode"></div>
            <p>{{ qrtitle }}选题库</p>
          </div>
          <div slot="footer">
            <el-button @click="qrcode = false">取 消</el-button>
            <el-button type="primary" @click="qrdownload">下 载</el-button>
            <el-button type="primary" @click="qrcopy">复 制</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'
import QRCode from 'qrcodejs2'
import { debounce } from 'lodash'
import institutionForm from './institutionForm'
import consultantForm from './consultantForm'
import institutionUpload from './institutionUpload'
import html2canvas from 'html2canvas'
export default {
  components: {
    institutionForm,
    consultantForm,
    institutionUpload
  },
  name: 'channel',
  data () {
    return {
      uploadDialog: false,
      options: [],
      status: {
        0: {
          label: '无人对接',
          color: '#c0c0c0'
        },
        1: {
          label: '未签约',
          color: '#FC6E51'
        },
        2: {
          label: '待合作',
          color: '#FFCE54'
        },
        3: {
          label: '已合作',
          color: '#A0D469'
        }
      },
      active: {},
      role: '机构',
      tableHeight: window.innerHeight - 160,
      institution: [],
      allInstitutions: [],
      agreementDialog: {
        data: [],
        active: false
      },
      consultant: [],
      channelName: '',
      userid: '',
      sales: [],
      page: 1,
      has_next: true,
      loading: false,
      pending: false,
      cmanagerlist: '',
      amanagerlist: '',
      plannerlist: '',
      filterStatus: '',
      checkList: [],
      isdisabled: true,
      roleApi: '',
      institutionForm: false,
      consultantForm: false,
      display: false,
      uid: 0,
      handle: true,
      qrcode: false,
      selected: 'lite',
      qrtitle: ''
    }
  },
  created () {
    this.roleApi = this.$store.state.roleApi
    if (this.roleApi === 'amanager') this.handle = false
    if (this.$store.state.role === '市场总监') {
      this.display = true
      this.options = [
        { value: '机构', label: '机构' },
        { value: '个人', label: '个人' }
      ]
    } else {
      this.uid = this.$store.state.userid
      this.options = [
        { value: '机构', label: '我的机构' },
        { value: '个人', label: '我的个人' },
        { value: '全部机构', label: '全部机构' }
      ]
    }
    this.getI()
  },
  computed: {
    filtersRule () {
      let keys = this.role === '个人' ? [3, 2, 0] : [3, 2, 1, 0]
      return keys.map(key => {
        return {
          text: this.status[key].label,
          value: key
        }
      })
    }
  },
  methods: {
    qrcopy () {
      html2canvas(document.querySelector('.qrcode-content')).then(canvas => {
        canvas.toBlob(blob => {
          let clipboardItem = new ClipboardItem({ 'image/png': blob })
          navigator.clipboard.write([clipboardItem])
        }, 'image/png')
        this.$message({
          showClose: true,
          message: '复制成功',
          type: 'success'
        })
        this.qrcode = false
      })
    },
    qrdownload () {
      html2canvas(document.querySelector('.qrcode-content')).then(canvas => {
        let a = document.createElement('a')
        let url = canvas.toDataURL('image/png')
        a.href = url
        a.download = this.selected + '二维码'
        a.click()
        this.qrcode = false
      })
    },
    qropen (row) {
      this.qrcode = true
      this.enterpise = row.enterpise
      this.qrset('lite')
    },
    qrset (selected) {
      this.selected = selected
      const list = {
        lite: 'Lite',
        standard: 'Standard',
        pro: 'Pro',
        proHigh: 'Pro(高中版)',
        research: '科研',
        rt: '科研+论文',
        xdf: 'XDF'
      }
      this.qrtitle = this.enterpise[selected] || list[selected] + '项目'
      this.$nextTick(() => {
        let origin = ''
        if (window.location.host.indexOf('eliteqihang') > -1) {
          origin = 'https://p.eliteqihang.com'
        } else {
          origin = 'https://p.manager.eliwise.demo.sya.org.cn'
        }
        document.getElementById('qrcode').innerHTML = ''
        new QRCode('qrcode', {
          text: `${origin}/single/${this.selected}/${this.enterpise.qrcode}`,
          width: 160,
          height: 160,
          colorDark: '#000000',
          colorLight: '#ffffff',
          typeNumber: 4,
          correctLevel: QRCode.CorrectLevel.H
        })
      })
    },
    filterHandler (value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    tablefilter (filter) {
      if (filter.status[0] !== undefined) this.filterStatus = filter.status[0]
      else this.filterStatus = ''
      this.page = 1
      this.getInstitution()
    },
    moreInstitution () {
      let dom = this.$refs.tableScroll.$el
      let scrollTop = dom.scrollTop
      let windowHeight = dom.clientHeight || dom.clientHeight
      let scrollHeight = dom.scrollHeight || dom.scrollHeight
      if (
        scrollTop + windowHeight >= scrollHeight - 1 &&
        this.has_next &&
        !this.loading
      ) {
        this.page++
        this.loading = true
        axios
          .get(`/${this.roleApi}/channel/institutions`, {
            params: {
              status: this.filterStatus,
              abbreviation: this.channelName,
              page: this.page
            }
          })
          .then(response => {
            if (response.data.has_previous) {
              this.institution.push(...response.data.res)
            }
            this.loading = false
            this.has_next = response.data.has_next
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    search: debounce(function () {
      this.page = 1
      if (this.role === '机构') {
        this.getI()
      } else if (this.role === '全部机构') {
        this.getAllInstitution()
      } else {
        this.getConsultant()
      }
    }, 500),
    getI () {
      this.page = 1
      this.getInstitution()
    },
    getAllInstitution () {
      this.loading = true
      axios
        .get(`/${this.roleApi}/channel/allInstitutions`, {
          params: {
            abbreviation: this.channelName,
            page: this.page
          }
        })
        .then(response => {
          this.allInstitutions = response.data.institutions
          this.loading = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    getInstitution () {
      this.loading = true
      axios
        .get(`/${this.roleApi}/channel/institutions`, {
          params: {
            status: this.filterStatus,
            abbreviation: this.channelName,
            page: this.page
          }
        })
        .then(response => {
          this.has_next = response.data.has_next
          this.institution = response.data.res
          this.loading = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    getConsultant () {
      this.loading = true
      axios
        .get(`/${this.roleApi}/channel/consultants/`, {
          params: {
            name: this.channelName
          }
        })
        .then(response => {
          this.consultant = response.data.res
          this.loading = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    addchannel (row = false) {
      if (this.role === '机构') {
        this.institutionForm = true
        if (row) {
          this.$nextTick(() => {
            this.$refs.institutionForm.dialogT = '添加二级渠道'
            this.$refs.institutionForm.post = true
            this.$refs.institutionForm.head = row.id
            this.$refs.institutionForm.institution.name = row.name
            this.$refs.institutionForm.step = 2
          })
        } else {
          this.$nextTick(() => {
            this.$refs.institutionForm.step = 1
          })
        }
      } else {
        this.consultantForm = true
        this.$nextTick(() => {
          this.$refs.consultantForm.active = true
        })
      }
    },
    rolechange () {
      this.page = 1
      this.has_next = true
      if (this.role === '机构') {
        this.getI()
      } else if (this.role === '个人') {
        this.getConsultant()
      } else if (this.role === '全部机构') {
        this.getAllInstitution()
      }
    },
    edit (data) {
      if (data !== undefined) {
        this.institutionForm = true
        this.$nextTick(() => {
          if (data.headoffice) this.$refs.institutionForm.dialogT = '编辑渠道'
          else this.$refs.institutionForm.dialogT = '编辑二级渠道'
          this.$refs.institutionForm.post = false
          this.$refs.institutionForm.institution = JSON.parse(
            JSON.stringify(data)
          )
          this.$refs.institutionForm.step = 2
        })
      }
    },
    consultantEdit (index, data) {
      this.consultantForm = true
      this.$nextTick(() => {
        this.$refs.consultantForm.dialogT = '编辑顾问'
        this.$refs.consultantForm.consultant = JSON.parse(JSON.stringify(data))
        this.$refs.consultantForm.active = true
      })
    },
    institutionDelete (index, row) {
      MessageBox.confirm('是否删除该渠道').then(() => {
        axios
          .delete(`/${this.roleApi}/channel/institution/${row.id}/`)
          .then(response => {
            if (response.status === 200) {
              this.$message({
                showClose: true,
                message: '已删除',
                type: 'success'
              })
              this.getI()
            }
          })
          .catch(error => {
            this.$message({
              showClose: true,
              message: `删除失败,${error.response.data.error_message}`,
              type: 'error'
            })
          })
      })
    },
    consultantDelete (index, row) {
      MessageBox.confirm('是否删除该顾问').then(() => {
        axios
          .delete(`/${this.roleApi}/channel/consultant/${row.id}`)
          .then(response => {
            if (response.status === 200) {
              this.$message({
                showClose: true,
                message: '已删除',
                type: 'success'
              })
              this.getConsultant()
            }
          })
          .catch(error => {
            this.$message({
              showClose: true,
              message: `删除失败,${error.response.data.error_message}`,
              type: 'error'
            })
          })
      })
    },
    openUploadDialog (type, id) {
      let uploadData = {
        tempfiles: { name: '', file: '', date: '' },
        type: type,
        id: id,
        active: true
      }
      this.uploadDialog = true
      this.$nextTick(() => {
        this.$refs.uploadDialog.uploadData = uploadData
      })
    },
    openAgreementDialog (data) {
      this.agreementDialog.data = Array.isArray(data) ? data : [data]
      this.agreementDialog.active = true
    }
  }
}
</script>

<style lang="scss" scoped>
.add {
  position: relative;
  bottom: 4px;
  margin-left: 5px;
  margin-right: 15px;
}
.el-icon-delete {
  font-size: 14px;
}
.el-icon-edit {
  font-size: 14px;
}

.accountlist {
  overflow-y: scroll;
}
.dialogAddbottom {
  margin-top: 20px;
  height: 90px;
}
/deep/.title {
  color: #656d78;
  font-size: 14px;
  margin-bottom: 3px;
}
/deep/.saleselect {
  width: 100%;
}
.imgBut {
  position: absolute;
  left: -5px;
  margin-right: 10px;
  transition: 0.3s;
  transform: rotate(-90deg);
}
.tablelist {
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  white-space: pre;
  li {
    line-height: 20px;
    & + li {
      margin-top: 10px;
    }
  }
}
.contacts {
  width: 100%;
}
.href {
  color: #656d78;
}

.circle {
  width: 12px;
  height: 12px;
  margin: 2px auto;
  border-radius: 50%;
  cursor: pointer;
}
/deep/.el-upload--text {
  color: #a0d469;
}
/deep/.el-input__icon {
  width: 15px;
  line-height: 30px;
}

/deep/.el-table {
  overflow: scroll;
}
/deep/ .el-table__body-wrapper {
  height: auto !important;
}
/deep/.el-table__expand-icon {
  position: absolute;
  right: -10px;
  display: inline-block;
  width: 22px;
  height: 22px;
  font-size: 15px;
  border: 2px solid;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  display: flex !important;
  z-index: 1000;
  [class^='el-icon-'] {
    font-weight: 700;
  }
}

/deep/.changerole {
  width: 120px;
  position: relative;
  bottom: 0px;
  right: 0px;
  .el-input--suffix .el-input__inner {
    border: 0px;
    text-align: right;
    border-radius: 0px;
    font-size: 12px;
    height: 30px;
  }
  .el-input__suffix {
    top: -2px;
  }
}
.qrcode-title {
  font-size: 16px;
  color: #434a54;
  display: flex;
  justify-content: space-between;
  p {
    padding: 0 10px 5px;
  }
  .selected {
    color: #8dc152;
    border-bottom: 1px solid #8dc152;
  }
}
.qrcode-content {
  position: relative;
  margin: 35px auto 20px;
  padding: 44px;
  padding-bottom: 55px;
  width: fit-content;
  background: #fff;
  p {
    bottom: 10px;
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
  }
}
#qrcode {
  height: 160px;
  width: 160px;
}
</style>
