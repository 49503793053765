<template>
  <div>
    <el-dialog
      title="添加渠道"
      :visible="step === 1"
      width="310px"
      :close-on-click-modal="false"
      :before-close="close"
    >
      <el-form
        :model="institution"
        ref="step"
        @submit.native.prevent="searchByName"
      >
        <el-form-item
          prop="name"
          :rules="{ required: true, message: '请输入品牌名', trigger: 'blur' }"
        >
          <el-input
            v-model.trim="institution.name"
            placeholder="请输入品牌名"
            autofocus
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" :disabled="pending" @click="searchByName"
          >确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible="step == 2"
      width="70%"
      :before-close="close"
      :close-on-click-modal="false"
      height="720"
      custom-class="addChannelScroll"
    >
      <template #title>
        <span class="el-dialog__title">{{ dialogT }}</span>
        <span style="color: #c0c0c0; font-size: 12px; padding-left: 5px">
          *为必填项
        </span>
        <div
          v-if="institution.id === undefined"
          class="link link-upload"
          @click="openUploadDialog('new')"
        >
          添加协议
        </div>
        <div
          v-else
          class="link link-upload"
          @click="openUploadDialog('add', institution.id)"
        >
          添加协议
        </div>
        <div
          v-if="institution.agreement.length > 0 || institution.agreement.file"
          class="link"
          @click="openAgreementDialog(institution.agreement)"
        >
          查看协议
        </div>
      </template>
      <el-form
        :model="institution"
        :rules="rulesforinstitution"
        ref="institutionForm"
      >
        <el-row>
          <el-col :span="24">
            <div class="project_type">公司信息</div>
          </el-col>
          <el-col :span="12" class="inputleft">
            <el-form-item prop="name">
              <div class="title">品牌名*</div>
              <el-input
                placeholder="品牌名"
                v-model.trim="institution.name"
                maxlength="10"
                class="input"
                :disabled="head > 0 || institution.headoffice == false"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="inputright">
            <el-form-item prop="abbreviation">
              <div class="title">公司简称*</div>
              <el-input
                placeholder="公司简称将用来区分总公司和分公司"
                v-model.trim="institution.abbreviation"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="inputleft">
            <el-form-item prop="company">
              <div class="title">公司全称</div>
              <el-input
                placeholder="公司全称"
                v-model.trim="institution.company"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="inputright">
            <el-form-item prop="pubbank">
              <div class="title">对公银行账户开户行</div>
              <el-input
                placeholder="对公开户行"
                v-model.trim="institution.pubbank.name"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="inputleft">
            <el-form-item prop="pubbank">
              <div class="title">银行账户(对公)</div>
              <el-input
                placeholder="银行账户(对公)"
                v-model.trim="institution.pubbank.account"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="inputright">
            <el-form-item prop="pribank">
              <div class="title">对私银行账户开户行</div>
              <el-input
                placeholder="对私开户行"
                v-model.trim="institution.pribank.name"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="inputleft">
            <el-form-item prop="pribank">
              <div class="title">银行账户(对私)</div>
              <el-input
                placeholder="银行账户(对私)"
                v-model.trim="institution.pribank.account"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="inputright">
            <el-form-item prop="taxnum">
              <div class="title">纳税人识别号</div>
              <el-input
                placeholder="纳税人识别号"
                v-model.trim="institution.taxnum"
                class="input"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <addform
            v-model="institution.contacts"
            v-slot="{ item: contact, index }"
            title="对接人信息"
            :default="1"
          >
            <el-col :span="12" class="inputleft">
              <el-form-item
                :prop="'contacts.' + index + '.name'"
                :rules="{
                  required: true,
                  message: '请输入姓名',
                  trigger: 'blur'
                }"
              >
                <div class="title">姓名*</div>
                <el-input
                  placeholder="对接人姓名"
                  v-model.trim="contact.name"
                  class="input"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputleft">
              <el-form-item
                :prop="'contacts.' + index"
                :rules="{
                  validator: contactsrules,
                  trigger: 'blur'
                }"
              >
                <div class="title">电话</div>
                <el-input
                  placeholder="电话"
                  v-model.trim="contact.cellphone"
                  class="input"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputleft">
              <el-form-item
                :prop="'contacts.' + index"
                :rules="{
                  validator: contactsrules,
                  trigger: 'blur'
                }"
              >
                <div class="title">微信</div>
                <el-input
                  placeholder="微信"
                  v-model.trim="contact.wechat"
                  class="input"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputleft">
              <el-form-item :prop="'contacts.' + index + '.position'">
                <div class="title">职位</div>
                <el-input
                  placeholder="职位"
                  v-model.trim="contact.position"
                  class="input"
                ></el-input>
              </el-form-item>
            </el-col>
          </addform>
        </el-row>
        <el-row>
          <addform
            v-model="institution.advisers"
            v-slot="{ item: adviser, index }"
            title="销售顾问信息"
            :default="1"
          >
            <el-col :span="12" class="inputleft">
              <el-form-item :prop="'advisers.' + index + '.name'">
                <div class="title">姓名</div>
                <el-input
                  placeholder="销售顾问姓名"
                  v-model.trim="adviser.name"
                  class="input"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputleft">
              <el-form-item :prop="'advisers.' + index">
                <div class="title">电话</div>
                <el-input
                  placeholder="电话"
                  v-model.trim="adviser.cellphone"
                  class="input"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputleft">
              <el-form-item :prop="'advisers.' + index">
                <div class="title">微信</div>
                <el-input
                  placeholder="微信"
                  v-model.trim="adviser.wechat"
                  class="input"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputleft">
              <el-form-item :prop="'advisers.' + index + '.position'">
                <div class="title">职位</div>
                <el-input
                  placeholder="职位"
                  v-model.trim="adviser.position"
                  class="input"
                ></el-input>
              </el-form-item>
            </el-col>
          </addform>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="project_type">录入信息</div>
          </el-col>
          <el-col :span="12" class="inputleft">
            <el-form-item
              prop="cmanager.id"
              :rules="{
                required: true,
                message: '请选择渠道经理',
                trigger: 'change'
              }"
            >
              <div class="title">渠道经理*</div>
              <el-select
                placeholder="渠道经理"
                v-model.trim="institution.cmanager.id"
                class="input saleselect"
              >
                <el-option
                  v-for="item in cmanagerlist"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="inputleft">
            <el-form-item
              prop="amanager.id"
              :rules="{
                required: true,
                message: '请选择客户经理',
                trigger: 'change'
              }"
            >
              <div class="title">客户经理*</div>
              <el-select
                placeholder="客户经理"
                v-model.trim="institution.amanager.id"
                class="input saleselect"
              >
                <el-option
                  v-for="item in amanagerlist"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <enterpiseForm :enterpise.sync="institution.enterpise" v-if="ready" />
      </el-form>
      <template #footer>
        <el-button @click="close"> 取 消 </el-button>
        <el-button
          @click="resetpsw"
          type="primary"
          v-if="dialogT === '查看信息'"
        >
          重置密码
        </el-button>
        <el-button type="primary" :disabled="pending" @click="pushInstitution">
          确 定
        </el-button>
      </template>
    </el-dialog>
    <institutionUpload ref="uploadDialog" v-if="uploadDialog" />
  </div>
</template>

<script>
import axios from '../axios'
import addform from './form/addform'
import institutionUpload from './institutionUpload'
import { MessageBox } from 'element-ui'
import enterpiseForm from './enterpiseForm'
export default {
  components: {
    addform,
    institutionUpload,
    enterpiseForm
  },
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入性别'))
      } else if (value === '男' || value === '女') {
        callback()
      } else {
        callback(new Error('请输入正确字符，“男”或“女”'))
      }
    }
    return {
      uploadDialog: false,
      step: 0,
      dialogT: '',
      head: 0,
      post: true,
      institution: {
        agreement: [],
        name: '',
        company: '',
        abbreviation: '',
        pubbank: {
          name: '',
          account: ''
        },
        pribank: {
          name: '',
          account: ''
        },
        taxnum: '',
        amanager: {
          name: '',
          id: ''
        },
        cmanager: {
          name: '',
          id: ''
        },
        contacts: [],
        advisers: [],
        enterpise: {
          id: '',
          username: '',
          thesis: '',
          research: '',
          rt: '',
          academic: '',
          epq: '',
          iap: '',
          proHigh: '',
          xt: '',
          byr: '',
          ewa: '',
          iap2: '',
          lite: '',
          standard: '',
          sort: '',
          ignore: [],
          permission: false
        }
      },
      checkList: [],
      rulesforinstitution: {
        name: [{ required: true, message: '请填写品牌名称', trigger: 'blur' }],
        sex: [{ validator: validatePass, trigger: 'blur' }],
        abbreviation: [
          { required: true, message: '请填写公司简称', trigger: 'blur' }
        ]
      },
      roleApi: '',
      pending: false,
      cmanagerlist: [],
      amanagerlist: [],
      list: [],
      ready: false
    }
  },
  created () {
    this.roleApi = this.$store.state.roleApi
    axios.get(`/${this.roleApi}/sponser_list?role=cmanager`).then(response => {
      this.cmanagerlist = response.data.supports
    })
    axios.get(`/${this.roleApi}/sponser_list?role=amanager`).then(response => {
      this.amanagerlist = response.data.supports
    })
    this.$nextTick(() => {
      this.ready = true
    })
  },
  methods: {
    searchByName () {
      this.pending = true
      axios
        .get(`/${this.roleApi}/channel/institution/exists`, {
          params: {
            name: this.institution.name
          }
        })
        .then(res => {
          this.pending = false
          if (res.data.id !== undefined) {
            MessageBox.confirm(
              `已有同名渠道${this.institution.name}，是否查看该渠道`,
              {
                cancelButtonText: '返回',
                confirmButtonText: '查看'
              }
            ).then(() => {
              this.institution = res.data
              this.dialogT = '查看信息'
              this.step = 2
            })
          } else {
            this.dialogT = '添加渠道'
            this.step = 2
          }
        })
    },
    contactsrules (rule, value, callback) {
      if (
        (value.wechat && value.wechat.length > 0) ||
        (value.cellphone && value.cellphone.length > 0)
      ) {
        callback()
      } else {
        callback(new Error('手机与微信至少填写一个'))
      }
    },
    close () {
      this.$parent.institutionForm = false
    },
    pushInstitution () {
      const that = this
      this.$refs['institutionForm'].validate(valid => {
        if (valid) {
          if (this.post) {
            MessageBox.confirm('是否添加该渠道').then(() => {
              this.pending = true
              let institution = this.institution
              if (this.head > 0) institution['head'] = this.head
              axios
                .post(`/${this.roleApi}/channel/institution/`, institution)
                .then(response => {
                  if (response.status === 200) {
                    this.$message({
                      showClose: true,
                      message: '添加成功',
                      type: 'success'
                    })
                    this.$parent.getI()
                    this.close()
                  }
                  this.pending = false
                })
                .catch(() => {
                  this.pending = false
                })
            })
          } else {
            MessageBox.confirm('是否修改该渠道').then(() => {
              let tempObj = this.institution
              console.log(tempObj)
              delete tempObj.status
              this.pending = true
              axios
                .put(
                  `/${this.roleApi}/channel/institution/${this.institution.id}/`,
                  tempObj
                )
                .then(response => {
                  if (response.status === 200) {
                    this.$message({
                      showClose: true,
                      message: '修改成功',
                      type: 'success'
                    })
                    this.$parent.getI()
                    this.close()
                    this.pending = false
                  }
                })
                .catch(() => {
                  this.$message({
                    showClose: true,
                    message: `修改失败`,
                    type: 'error'
                  })
                  this.pending = false
                })
            })
          }
        } else {
          const base = that.$refs.institutionForm.$el.getBoundingClientRect()
            .top
          for (const validElement of that.$refs.institutionForm.fields) {
            const isError =
              validElement.$el._prevClass.indexOf('is-error') !== -1
            const isSuccess =
              validElement.$el._prevClass.indexOf('is-success') !== -1
            const isRequire =
              validElement.$el._prevClass.indexOf('is-require') !== -1
            if ((isRequire && !isError && !isSuccess) || isError) {
              document.getElementsByClassName('addChannelScroll')[0].scrollTop =
                validElement.$el.getBoundingClientRect().top - base + 40
              break
            }
          }
          return false
        }
      })
    },
    resetpsw () {
      MessageBox.confirm('是否重置该用户密码').then(() => {
        axios
          .post('/reset_password', {
            type: 4,
            id: this.institution.enterpise.id
          })
          .then(response => {
            if (response.status === 200) {
              this.$message({
                showClose: true,
                message: '已重置',
                type: 'success'
              })
            }
          })
          .catch(error => {
            this.$message({
              showClose: true,
              message: `重置失败,${error.response.data.error_message}`,
              type: 'error'
            })
          })
      })
    },
    openUploadDialog (type, id) {
      let uploadData = {
        tempfiles: { name: '', file: '', date: '' },
        type: type,
        id: id,
        active: true
      }
      this.uploadDialog = true
      this.$nextTick(() => {
        this.$refs.uploadDialog.uploadData = uploadData
      })
    },
    openAgreementDialog (data) {
      this.$parent.openAgreementDialog(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  text-decoration: underline;
  cursor: pointer;
  float: right;
  font-size: 14px;
  & + .link {
    padding-right: 1em;
  }
  &.link-upload {
    color: #8dc152;
  }
}
.addform .dialogAdd:first-of-type {
  margin-top: 8px;
  height: 52px;
}
</style>
