<template>
  <div>
    <div class="jtitle">
      <h2 class="jtext">投稿进度</h2>
    </div>
    <div class="table">
      <!-- 搜索框 -->
      <div class="search flex">
        <div class="seaicon">
          <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
        </div>
        <el-input v-model.trim="studentname" @input="search" class="seasubfix">
        </el-input>
      </div>
      <div :style="{paddingRight:loading?'5px':0}">
        <!-- 渠道table -->
        <el-table
          :data="conference"
          ref="tableScroll"
          :height="tableHeight"
          :row-class-name="statusClass"
        >
          <el-table-column
            key="1"
            prop="id"
            label="编号"
            align="center"
            min-width="5%"
          />
          <el-table-column
            key="2"
            prop="name"
            label="学生姓名"
            align="center"
            min-width="6%"
          />
          <el-table-column
            key="2-1"
            prop="conf_type"
            label="分类"
            align="center"
            min-width="5%"
          >
            <template slot-scope="scope">
              {{scope.row.type==='Lite'?'期刊':'会议'}}
            </template>
          </el-table-column>
          <el-table-column
            key="3"
            prop="type"
            label="项目类型"
            align="center"
            min-width="8%"
          >
          </el-table-column>
          <el-table-column
            key="4"
            prop="teacher_name"
            label="导师姓名"
            align="center"
            min-width="6%"
          />
          <el-table-column
            key="5"
            prop="paper_title"
            label="论文题目"
            align="center"
            min-width="15%"
          >
          </el-table-column>
          <el-table-column
            key="6"
            prop="meeting"
            label="官网链接"
            align="center"
            min-width="10%"
          >
          </el-table-column>
          <el-table-column
            key="7"
            prop="final_memo"
            label="终稿备注"
            align="center"
            min-width="15%"
          >
          </el-table-column>
          <el-table-column key="8" align="center" min-width="6%">
            <template slot-scope="scope">
              <el-button @click="openEndDialog(scope.row)" type="primary">
                查看论文
              </el-button>
            </template>
          </el-table-column>
          <el-table-column key="9" label="已投稿" align="center" min-width="5%">
            <template slot-scope="scope" v-if="scope.row.type !== 'EPQ'">
              <el-switch
                :disabled="api !== 'assistant'"
                v-model="scope.row.Contributed"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="iscontri(scope.$index, scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <div slot="append" style="text-align: center; font-size: 16px">
            <span v-if="has_next">
              <i class="eliwise-loading"></i>加载中请稍后...
            </span>
            <i v-else>--没有数据了--</i>
          </div>
        </el-table>
      </div>
    </div>
    <finalDialog ref="finalDialog" />
  </div>
</template>

<script>
import axios from '../axios'
import finalDialog from '../pub/finalDialog.vue'
import { debounce } from 'lodash'
export default {
  name: 'post',
  data () {
    return {
      conference: [],
      studentname: '',
      loading: false,
      tableHeight: window.innerHeight - 155,
      request: null,
      disabled: false,
      has_next: true,
      pagenumber: 1,
      dom: null,
      number: '',
      item: Object,
      api: ''
    }
  },
  components: {
    finalDialog
  },
  mounted () {
    this.loading = true
    this.api = this.$store.state.roleApi
    axios
      .get(`${this.api}/finalPapers`)
      .then(response => {
        this.conference = response.data.projects
        this.has_next = response.data.has_next
      })
      .then(() => {
        const that = this
        window.onresize = () => {
          return (() => {
            that.tableHeight = window.innerHeight - 150
          })()
        }
        this.loading = false
        this.dom2()
      })
  },
  methods: {
    statusClass ({ row }) {
      if (row.status === 2) {
        return 'finished'
      }
    },
    iscontri (index, row) {
      axios.put(`/assistant/project/${row.pid}/progress`, {
        status: row.Contributed
      })
    },
    search: debounce(function () {
      const CancelToken = axios.CancelToken
      this.pagenumber = 1
      if (this.request !== null) {
        this.request.cancel()
      }
      this.request = CancelToken.source()
      axios
        .get(`${this.api}/finalPapers`, {
          params: {
            name: this.studentname
          },
          cancelToken: this.request.token
        })
        .then(response => {
          this.conference = response.data.projects
          this.has_next = response.data.has_next
        })
    }, 1000),
    openEndDialog (row) {
      this.$refs.finalDialog.openDialog(row.pid)
    },
    a () {
      let scrollTop2 = this.dom.scrollTop
      let windowHeight2 = this.dom.clientHeight || this.dom.clientHeight
      let scrollHeight2 = this.dom.scrollHeight || this.dom.scrollHeight
      if (
        scrollTop2 + windowHeight2 >= scrollHeight2 - 1 &&
        this.has_next &&
        !this.loading
      ) {
        this.pagenumber = this.pagenumber + 1
        this.loading = true
        axios
          .get(`${this.api}/finalPapers`, {
            params: {
              name: this.studentname,
              page: this.pagenumber
            }
          })
          .then(response => {
            if (response.data.has_previous) {
              this.conference = this.conference.concat(response.data.projects)
            }
            this.has_next = response.data.has_next
            this.loading = false
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    dom2 () {
      this.dom = this.$refs.tableScroll.bodyWrapper
      this.dom.addEventListener('scroll', this.a)
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-table .cell {
  text-overflow: clip;
}
/deep/.finished .cell {
  color: #c0c0c0;
}
/deep/.gutter {
  display: none;
}
</style>
