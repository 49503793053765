<template>
  <el-form class="projectform" :model="project" :rules="rules" ref="project1">
    <el-upload class="projectupload" :action="uploadUrl" :on-remove="handleRemove" :limit="5" multiple
      accept=".jpg, .jpeg, .png, .doc, .docx, .pdf, .JPG, .JPEG, .PNG, .DOC, .DOCX, .PDF" :on-exceed="handleExceed"
      :on-success="handleSuccess" :on-error="handleError" :beforeUpload="beforeFileUpload" ref="upload"
      :file-list="project.attachments">
      <template v-slot:trigger>
        <a type="primary" style="text-decoration: underline; color: #8dc152">添加附件</a>
      </template>
      <template #file="{ file }">
        <div v-show="file.status !== 'removed'" class="fileitem">
          <div class="filename">
            <div>{{ file.name }}</div>
          </div>
          <el-progress :stroke-width="1" :show-text="false" :width="20" type="circle" :percentage="file.percentage"
            color="#8DC152"></el-progress>
          <div @click="handleRemove(file)" class="el-icon-close"></div>
          <div v-if="file.status == 'success'" class="el-icon-upload-success el-icon-check" style="color: #8dc152">
          </div>
        </div>
      </template>
    </el-upload>
    <el-row>
      <el-col :span="24">
        <div class="project_type">项目信息</div>
      </el-col>
      <el-col style="margin-bottom: 10px; height: 60px">
        <el-form-item prop="channel" class="form-item-error" :rules="{
          validator: channelCheck,
          trigger: 'change',
          required: true
        }">
          <div class="title">渠道</div>
          <channelSearchBar :type.sync="project.channel_type" :select.sync="project.channel"
            :cmanager.sync="project.cmanager" :amanager.sync="project.amanager" @validateChannel="validateChannel" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-if="project.channel_type.indexOf('inst') !== -1" class="top">
      <el-col :span="12">
        <el-form-item prop="cmanager">
          <div class="title">销售顾问（非必填项）</div>
          <el-select placeholder="销售顾问" v-model="project.adviser" class="input saleselect">
            <el-option v-for="item in adviserlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="top">
      <el-col :span="12" v-if="project.channel_type.indexOf('own') === -1">
        <el-form-item prop="cmanager" :rules="{
          required: true,
          message: '请选择渠道经理',
          trigger: 'change'
        }">
          <div class="title">渠道经理*</div>
          <el-select placeholder="渠道经理" v-model="project.cmanager" class="input saleselect"
            @visible-change="validateSalesman" :disabled="
              project.channel_type.indexOf('own') !== -1 ? false : true
            ">
            <el-option v-for="item in cmanagerlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12" class="inputright" v-if="project.channel_type.indexOf('inst') !== -1">
        <el-form-item prop="amanager" :rules="{
          required: true,
          message: '请选择客户经理',
          trigger: 'change'
        }">
          <div class="title">客户经理*</div>
          <el-select placeholder="客户经理" v-model="project.amanager" class="input saleselect"
            @visible-change="validateSalesman" :disabled="true">
            <el-option v-for="item in amanagerlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="sponser" :rules="{
          required: true,
          message: '请选择规划师',
          trigger: 'change'
        }">
          <div class="title">规划师*</div>
          <el-select placeholder="规划师" v-model="project.sponser" class="input saleselect"
            @visible-change="validateSalesman">
            <el-option v-for="item in plannerlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="connectTime" :rules="{
          required: true,
          message: '请填入面试时间段',
          trigger: 'blur'
        }">
          <div class="title">面试时间*</div>
          <el-input placeholder="面试时间" v-model="project.connectTime" class="input"
            :disabled="project.teacher !== ''"></el-input>
          <p class="tip" v-if="project.teacher !== ''">
            教务已填写准确的面试时间，如面试时间有更改，请联系教务修改
          </p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="startTime">
          <div class="title">意向开始时间</div>
          <el-input placeholder="意向开始时间" v-model="project.startTime" class="input"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :span="12">
      <div class="project_type">项目类型*</div>
    </el-row>
    <div class="title" style="margin-bottom: 10px">是否为组合项目？*</div>
    <el-radio-group v-model="project.isPacked" style="margin-bottom: 10px">
      <el-radio :label="false" :disabled="project.isPacked && !isNew" style="margin-right: 30px">
        单项目
      </el-radio>
      <el-radio :label="true" :disabled="!project.isPacked && !isNew">
        组合项目
      </el-radio>
    </el-radio-group>
    <div class="title" style="margin-bottom: 10px">项目类型*</div>
    <el-row v-if="isNew && project.isPacked">
      <el-col :span="24">
        <el-form-item prop="project_types" :rules="{
          validator: projectTypesValidatePass,
          trigger: 'change'
        }">
          <el-checkbox-group class="radiogroup" v-model="project.project_types">
            <el-checkbox class="wid100" v-for="item in project_type" :key="item" :label="item" :title="item">{{ item }}
            </el-checkbox>
            <label class="wid100" @click="moreItem = !moreItem" style="cursor: pointer">
              <span class="el-radio__label" style="padding: 0">
                <i :class="moreItem ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
                其他项目
              </span>
            </label>
            <el-checkbox class="wid100" v-if="moreItem" v-for="item in project_type_more" :key="item" :label="item"
              :title="item">{{ item }}
            </el-checkbox>
            <el-checkbox class="widall" :label="otherItem" v-if="moreItem">
              其他
              <div v-if="project.project_types.indexOf(otherItem) > -1" class="other">
                <el-input class="seasub1 fixd" v-model="otherItem" maxlength="64"></el-input>
              </div>
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-else>
      <el-col :span="24">
        <el-form-item prop="project_type" :rules="{
          required: true,
          message: '请填选择项目类型',
          trigger: 'change'
        }">
          <el-radio-group class="radiogroup" v-model="project.project_type">
            <el-radio class="wid100" v-for="item in project_type" v-model="project.project_type" :key="item"
              :label="item" :title="item">{{ item }}
            </el-radio>
            <label class="wid100" @click="moreItem = !moreItem" style="cursor: pointer">
              <span class="el-radio__label" style="padding: 0">
                <i :class="moreItem ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
                其他项目
              </span>
            </label>
            <!--            这几个div是为了把其他项目后面的单位挤下去-->
            <div></div>
            <div></div>
            <div></div>
            <el-radio class="wid100" v-if="moreItem" v-for="item in project_type_more" v-model="project.project_type"
              :key="item" :label="item" :title="item">{{ item }}
            </el-radio>
            <el-radio class="widall" v-if="moreItem" :label="otherItem" title="其他" v-model="project.project_type">
              其他
              <div v-if="project.project_type === otherItem" class="other">
                <el-input class="seasub1 fixd" v-model="otherItem" @input="project.project_type = otherItem"
                  maxlength="64"></el-input>
              </div>
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item prop="projectName">
          <div class="title">学生方向/项目名称*</div>
          <el-autocomplete class="autocomplete" v-model="project.projectName" :fetch-suggestions="thesisSearch"
            placeholder="学生方向/项目名称" :trigger-on-focus="false" @input="getThiesis" valueKey="title" :debounce="700">
          </el-autocomplete>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row style="margin-bottom: 20px">
      <el-col :span="24">
        <el-form-item>
          <div class="title">学生备注</div>
          <el-input placeholder="学生备注将会被同时发送给教务和老师" type="textarea" v-model="student_misc" class="input"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" style="margin-top: 20px">
        <el-form-item>
          <div class="title">面试/启动备注（内部）</div>
          <el-input placeholder="启动备注（内部）导师不可见" type="textarea" v-model="start_misc" class="input"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import channelSearchBar from '../channelSearchBar'
import axios from 'axios'

export default {
  name: 'projectform',
  props: {
    project: {
      type: Object,
      default: true
    },
    dialogT: String,
    isNew: {
      type: Boolean,
      default: true
    },
    uploadLock: Boolean
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入性别'))
      } else if (value === '男' || value === '女') {
        callback()
      } else {
        callback(new Error('请输入正确字符，“男”或“女”'))
      }
    }
    return {
      rules: {
        username: [
          { required: true, message: '请填写用户名', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
        projectName: [
          {
            required: true,
            message: '请填写学生方向/项目名称',
            trigger: 'blur'
          }
        ],
        school: [
          { required: true, message: '请填写学校名称', trigger: 'blur' }
        ],
        cellphone: [
          { required: true, message: '请填写电话号码', trigger: 'blur' }
        ],
        sex: [{ validator: validatePass, trigger: 'blur' }],
        schoolStartTime: [
          {
            required: true,
            message: '请选择下一学历入学时间',
            trigger: 'blur'
          }
        ]
      },
      uploadUrl: `${axios.defaults.baseURL}/upload/`,
      loading: false,
      onupload: 0,
      plannerlist: '',
      cmanagerlist: '',
      amanagerlist: '',
      otherItem: undefined,
      project_type: [
        '论文A',
        '论文B',
        '学习型科研-4次',
        '远程实践型科研-8次',
        'Lite',
        'Standard',
        'Pro',
        // 'Pro-5',
        // 'Pro-10',
        'Research Proposal',
        'RP+RL',
        'EPQ',
        '会务组论文-刊',
        '会务组论文-会'
      ],
      project_type_more: [
        'EI/CPCI国际会议论文',
        '国家级论文',
        '国际普通期刊论文',
        'IPQ'
      ],
      att: [],
      researchs: [],
      thesises: [],
      adviserlist: [],
      moreItem: false
    }
  },
  mounted() {
    let role = this.$store.state.roleApi
    axios.get(`/${role}/sponser_list?role=cmanager`).then(response => {
      this.cmanagerlist = response.data.supports
    })
    axios.get(`/${role}/sponser_list?role=amanager`).then(response => {
      this.amanagerlist = response.data.supports
    })
    axios.get(`/${role}/sponser_list?role=planner`).then(response => {
      this.plannerlist = response.data.supports
    })
  },
  watch: {
    'project.isPacked'() {
      if (this.isNew) {
        this.project.project_type = ''
        this.project.project_types = []
        this.otherItem = undefined
      }
    },
    'project.project_type'() {
      if (!this.isNew) {
        if (
          [...this.project_type, ...this.project_type_more].indexOf(
            this.project.project_type
          ) === -1
        ) {
          this.otherItem = this.project.project_type
        }
      }
    },
    otherItem(val, oldVal) {
      this.project.project_types = this.project.project_types.map(e => {
        if (e === oldVal) return val
        else return e
      })
    },
    onupload(val) {
      if (val === 0) {
        this.$emit('update:uploadLock', false)
      } else {
        this.$emit('update:uploadLock', true)
      }
    }
  },
  computed: {
    student_misc: {
      get() {
        let tempArr = this.project.misc.split('&&^^')
        return typeof tempArr[0] === 'undefined' ? '' : tempArr[0]
      },
      set(val) {
        let tempArr = this.project.misc.split('&&^^')
        this.project.misc =
          val + '&&^^' + (typeof tempArr[1] === 'undefined' ? '' : tempArr[1])
      }
    },
    start_misc: {
      get() {
        let tempArr = this.project.misc.split('&&^^')
        return typeof tempArr[1] === 'undefined' ? '' : tempArr[1]
      },
      set(val) {
        let tempArr = this.project.misc.split('&&^^')
        this.project.misc =
          (typeof tempArr[0] === 'undefined' ? '' : tempArr[0]) + '&&^^' + val
      }
    }
  },
  components: {
    channelSearchBar
  },
  methods: {
    get_advisers(channel) {
      if (channel > 0) {
        axios
          .get(`${this.$store.state.roleApi}/channel/institution/${channel}/`)
          .then(response => {
            this.adviserlist = response.data.advisers
          })
      }
    },
    validateChannel(adviserlist) {
      this.$refs.project1.validateField('channel')
      if (adviserlist) {
        this.adviserlist = adviserlist
      }
    },
    getThiesis: _.debounce(function () {
      axios
        .get('/DBS/thisis', {
          params: {
            title: this.project.projectName
          }
        })
        .then(response => {
          this.thesises = response.data.topics
        })
    }, 400),
    researchSearch(queryString, cb) {
      cb(this.researchs)
    },
    thesisSearch(queryString, cb) {
      cb(this.thesises)
    },
    projectTypesValidatePass(rule, value, callback) {
      if (this.project.project_types.length < 2) {
        callback(new Error('请至少选择两个项目类型'))
      } else if (this.project.project_types.indexOf('') > -1) {
        callback(new Error('请输入类型'))
      } else {
        callback()
      }
    },
    beforeFileUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 50
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 50MB!',
          type: 'warning'
        })
      } else {
        this.loading = true
        this.onupload++
      }
      return isLt2M
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 5 个文件，本次选择了 ${files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      )
      this.loading = false
    },
    handleRemove(file) {
      if (file.status === 'uploading') {
        this.onupload--
      }
      this.$refs['upload'].abort(file)
      let index = ''
      if (file.response) {
        index = this.project.attachments.indexOf(file.response.key)
      } else {
        index = this.project.attachments.indexOf(file)
      }
      console.log(index)
      if (index > -1) {
        this.project.attachments.splice(index, 1)
      }
      this.loading = false
      file.status = 'removed'
    },
    // 文件上传成功时的钩子
    handleSuccess(res) {
      this.$message({
        showClose: true,
        message: `文件上传成功`,
        type: 'success'
      })
      this.loading = false
      this.onupload--
      this.project.attachments.push(res.key)
    },
    // 文件上传失败时的钩子
    handleError() {
      this.$message({
        showClose: true,
        message: `文件上传失败`,
        type: 'error'
      })
      this.onupload--
      this.loading = false
    },
    validateSalesman() {
      this.$refs.project1.validateField('cmanager', 'amanager', 'sponser')
    },
    check(fun) {
      this.$refs.project1.validate(fun)
    },
    channelCheck(rule, value, callback) {
      if (this.project.channel_type === 'own' || this.project.channel !== '') {
        callback()
      } else {
        callback(new Error('请选择渠道'))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.projectform {
  .el-checkbox__input.is-checked {
    .el-checkbox__inner {
      background: #666d78;
      border-color: #c0c0c0;
    }

    &+.el-checkbox__label {
      color: #606266;
    }
  }
}

.projectupload {
  position: absolute;
  top: 30px;
  right: 59px;
  display: flex;
  z-index: 2;
  flex-direction: column;
  align-items: flex-end;

  .el-upload-list {
    width: 100%;
    align-self: flex-end;
  }

  .el-upload-list__item {
    display: block;
    width: 100%;
  }

  .fileitem {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .el-icon-close,
    .el-icon-upload-success {
      position: absolute;
      top: 5px;
      right: 3px;
    }

    &:hover .el-icon-upload-success {
      display: none;
    }

    .filename {
      flex: 0 0 auto;
      min-width: 0;
      padding-right: 10px;
    }
  }
}

.projectupload {
  .el-upload {
    float: right;
  }

  .el-upload-list {
    .el-upload-list__item {
      :first-child {
        margin: 0;
      }

      .el-progress.el-progress--circle.el-progress--without-text {
        position: relative;
        top: 0;
        width: 20px;
        height: 20px;
      }
    }
  }
}

.other {
  border-bottom: solid 1px #434a54;
  position: absolute;
  bottom: 0px;
  left: 50px;
  margin-left: 10px;
}

/deep/ .isPacked {
  float: right;

  .el-checkbox__label {
    text-align: left;
    color: #434a54;
    font-size: 16px;
    padding-bottom: 17px;
  }
}

@import './style.scss';

.autocomplete {
  width: 100%;
}

.attachment {
  z-index: 100;
  list-style: none;
  position: absolute;
  top: 40px;
  right: 59px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  li {
    flex: 0 0 15px;
    line-height: 20px;
  }
}

/deep/ .el-textarea__inner {
  height: 100px;
}

.form-item-error {
  /deep/ .el-form-item__error {
    left: 22vw;
  }
}

.el-radio {
  margin-right: 0;
}

.tip {
  position: absolute;
  color: #c0c0c0;
  font-size: 12px;
}
</style>
