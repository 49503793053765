<template>
  <el-dialog
    :title="dialogName"
    :visible="true"
    width="35%"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :open="dialogOpen()"
  >
    <el-form ref="epq" :model="epq" :rules="rules" :hide-required-asterisk="true">
      <el-row>
        <el-col>
          <el-form-item label="选题难度*" prop="degree">
            <el-rate v-model="epq.degree" :colors="colors" @change="degreevalid()"> </el-rate>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="选题*" prop="title">
            <el-input v-model="epq.title" placeholder="选题"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="inputleft">
          <el-form-item label="学科门类*" prop="subject" class="neat">
            <el-select
              v-model="epq.subject"
              placeholder="学科门类"
              filterable
              remote
              :remote-method="remoteSubject"
            >
              <el-option
                v-for="item in subjects"
                :key="item.id"
                :label="item.content"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="inputright">
          <el-form-item label="专业方向*" prop="specialty" class="neat">
            <el-select
              v-model="epq.specialty"
              placeholder="专业方向"
              filterable
              remote
              :remote-method="remoteSpecialty"
            >
              <el-option
                v-for="item in specialtys"
                :key="item.id"
                :label="item.content"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="commit" :disabled="commitable">确认</el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from '../axios'

export default {
  name: 'epqForm',
  data () {
    return {
      colors: ['#8DC152', '#8DC152', '#8DC152'],
      directionValue: '',
      rules: {
        degree: [{ min: 1, message: '请填写难度', type: 'number' }],
        title: [{ required: true, message: '请填写标题', trigger: 'blur' }],
        subject: [{ required: true, message: '请填写学科门类', trigger: 'change' }],
        specialty: [{ required: true, message: '请填写专业方向', trigger: 'change' }]
      },
      epq_id: '',
      postable: true,
      subjects: [],
      specialtys: [],
      research: false,
      level: [1, 2, 3, 4, 5],
      dialogName: '',
      deleteOn: false,
      deleteOn_pack: false,
      epq: {
        degree: 0,
        title: '',
        subject: '',
        specialty: ''
      },
      commitable: false
    }
  },
  methods: {
    dialogOpen () {
      if (this.postable) this.dialogName = '添加选题'
      else {
        this.dialogName = '修改选题'
        if (typeof this.epq.subject !== 'number') {
          this.remoteSubject(this.epq.subject.content)
          this.epq.subject = this.epq.subject.id
          this.remoteSpecialty(this.epq.specialty.content)
          this.epq.specialty = this.epq.specialty.id
        }
      }
    },
    cancel () {
      this.$parent.$parent.epqForm = false
    },
    degreevalid () {
      this.$refs['epq'].validateField('degree')
    },
    remoteSubject (query) {
      axios
        .get('DBS/epq/subject', {
          params: {
            content: query
          }
        })
        .then(response => {
          this.subjects = response.data.subjects
        })
    },
    remoteSpecialty (query) {
      axios
        .get('DBS/specialty', {
          params: {
            subject: this.epq.subject,
            content: query
          }
        })
        .then(response => {
          this.specialtys = response.data.specialtys
        })
    },
    commit () {
      this.$refs.epq.validate(valid => {
        if (valid) {
          this.commitable = true
          if (this.postable) {
            this.postform()
          } else {
            this.putform()
          }
        } else {
          this.$message({
            message: '请输入必填项',
            type: 'warning'
          })
          return false
        }
      })
    },
    postform () {
      axios
        .post('/DBS/epq', {
          degree: this.epq.degree,
          title: this.epq.title,
          subject: this.epq.subject,
          specialty: this.epq.specialty
        })
        .then(response => {
          if (response.status === 200) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          }
        })
    },
    putform () {
      axios
        .put(`/DBS/epq/${this.epq.id}`, {
          degree: this.epq.degree,
          title: this.epq.title,
          subject: this.epq.subject,
          specialty: this.epq.specialty
        })
        .then(response => {
          if (response.status === 200) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          }
        })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-dialog__title {
  font-size: 24px;
}
.el-form-item {
  margin-bottom: 5px;
}
/deep/.el-form-item__label {
  float: none;
  color: #656d78;
}
/deep/.el-dialog .el-input__inner {
  background-color: #ffffff;
}
/deep/ .el-select {
  width: 100%;
}
.neat {
  /deep/.el-input__inner {
    cursor: text;
  }
  /deep/.el-dialog__footer {
    margin-top: 30px;
  }
  /deep/.el-input__suffix {
    display: none;
  }
}
</style>
