<template>
  <ul
    v-infinite-scroll="load"
    infinite-scroll-disabled="disableloading"
    infinite-scroll-immediate="false"
    infinite-scroll-delay="200"
    class="scrollList"
    infinite-scroll-distance="5"
  >
    <li v-for="(item, index) in data" :key="index">
      <slot :item="item" :index="index"></slot>
    </li>
    <span class="listInfo" v-if="next">
      <i class="eliwise-loading"></i>加载中请稍后...
    </span>
    <span class="listInfo" v-if="!next">没有更多信息了</span>
  </ul>
</template>

<script>
export default {
  name: 'list',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    next: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$emit('next')
    }
  },
  computed: {
    disableloading () {
      return !this.next
    }
  }
}
</script>

<style lang="scss" scoped>
.scrollList {
  padding-inline-start: 0px;
}
</style>
