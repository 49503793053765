var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticStyle:{"max-height":"90vh"},attrs:{"visible":_vm.active,"width":"70%","close-on-click-modal":false,"close-on-press-escape":true,"before-close":_vm.close,"destroy-on-close":"","title":"修改项目信息"},on:{"update:visible":function($event){_vm.active=$event}}},[_c('el-form',{ref:"projectform",attrs:{"model":_vm.project}},[_c('div',[_c('div',{staticClass:"project_type"},[_vm._v("学生来源信息")]),_c('el-row',[_c('el-col',{staticStyle:{"margin-bottom":"10px","height":"60px"}},[_c('div',{staticClass:"title"},[_vm._v("渠道")]),_c('div',{staticClass:"form-item-error"},[_c('el-form-item',{attrs:{"prop":"channel","rules":{
                validator: _vm.channelCheck,
                trigger: 'change',
                required: true
              }}},[_c('channelSearchBar',{attrs:{"type":_vm.project.channel_type,"select":_vm.project.channel,"cmanager":_vm.project.cmanager,"amanager":_vm.project.amanager},on:{"update:type":function($event){return _vm.$set(_vm.project, "channel_type", $event)},"update:select":function($event){return _vm.$set(_vm.project, "channel", $event)},"update:cmanager":function($event){return _vm.$set(_vm.project, "cmanager", $event)},"update:amanager":function($event){return _vm.$set(_vm.project, "amanager", $event)},"validateChannel":_vm.validateChannel}})],1)],1)])],1),_c('el-row',{staticClass:"top"},[(_vm.project.channel_type.indexOf('own') === -1)?_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"prop":"cmanager"}},[_c('div',{staticClass:"title"},[_vm._v("渠道经理*")]),_c('el-select',{staticClass:"input saleselect",attrs:{"placeholder":"渠道经理","disabled":_vm.project.channel_type.indexOf('own') !== -1 ? false : true},model:{value:(_vm.project.cmanager),callback:function ($$v) {_vm.$set(_vm.project, "cmanager", $$v)},expression:"project.cmanager"}},_vm._l((_vm.cmanagerlist),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1):_vm._e(),(_vm.project.channel_type.indexOf('inst') !== -1)?_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"prop":"amanager"}},[_c('div',{staticClass:"title"},[_vm._v("客户经理*")]),_c('el-select',{staticClass:"input saleselect",attrs:{"placeholder":"客户经理","disabled":true},model:{value:(_vm.project.amanager),callback:function ($$v) {_vm.$set(_vm.project, "amanager", $$v)},expression:"project.amanager"}},_vm._l((_vm.amanagerlist),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1):_vm._e(),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"prop":"sponser","rules":{
              required: true,
              message: '请选择规划师',
              trigger: 'change'
            }}},[_c('div',{staticClass:"title"},[_vm._v("规划师*")]),_c('el-select',{staticClass:"input saleselect",attrs:{"placeholder":"规划师"},model:{value:(_vm.project.sponser),callback:function ($$v) {_vm.$set(_vm.project, "sponser", $$v)},expression:"project.sponser"}},_vm._l((_vm.plannerlist),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1)],1)],1)]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.close}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","disabled":_vm.pending},on:{"click":_vm.pushProject}},[_vm._v(" 修 改 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }