<template>
  <el-dialog :visible="true" width="80%" title="导出辅导记录">
    <div v-if="step === 1">
      <div id="eportcontent">
        <el-row class="studentInfo" :class="smesable ? '' : 'exportable'">
          <el-col class="studentName" :span="8">
            {{ smes.name }}.{{ project.project_type }}
          </el-col>
          <el-col :span="12" class="info">
            <p>性别：{{ smes.sex }}</p>
            <p>学校：{{ smes.school }}</p>
            <p>专业：{{ smes.direction }}</p>
          </el-col>
        </el-row>
        <el-row class="direction" :class="dirAble ? '' : 'exportable'">
          <el-col :span="20">
            课题方向：{{ project.projectName || '暂无' }}
          </el-col>
        </el-row>
        <el-checkbox
          v-model="smesable"
          class="checkbox exportable"
          style="top:25px;right: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="dirAble"
          class="checkboxDirec exportable"
          style="top:77px;right: 10px"
        ></el-checkbox>
        <el-row :class="stageAble ? '' : 'exportable'">
          <el-col
            :span="20"
            style="display: flex;align-items: center;margin:20px 0 10px"
          >
            <span class="stage">当前处于: {{ active }}</span>
          </el-col>
        </el-row>
        <el-checkbox
          v-model="stageAble"
          class="checkboxStage exportable"
        ></el-checkbox>
        <exportChart
          :stage.sync="chartData"
          :editable="true"
          :isEnterprise="isEnterprise"
          :showHeader="showHeader"
          @checked="checked($event)"
          ref="chart"
        >
        </exportChart>
        <el-row
          class="interview"
          v-if="!isEnterprise"
          :class="checks.checkInter ? '' : 'exportable'"
        >
          <img src="../../assets/icon/面试.png" width="20" />
          <div v-if="!interview" class="viewdate">
            暂无面试反馈
          </div>
          <div v-else class="viewdate">{{ interview.date }}</div>
          <span v-if="interview">
            {{ interview.situation }}
          </span>
          <el-checkbox
            v-model="checks.checkInter"
            v-if="!isEnterprise"
            class="checkboxInter exportable"
          ></el-checkbox>
        </el-row>
      </div>
    </div>
    <div v-if="step === 2">
      <div id="preview"></div>
    </div>
    <div slot="footer">
      <div v-if="step === 1">
        <el-button @click="$parent.$parent.exportI = false" key="1-1"
          >取消</el-button
        >
        <el-button type="primary" @click="preview" key="1-2">预览</el-button>
      </div>
      <div v-if="step === 2">
        <el-button @click="back" key="2-1">返回</el-button>
        <el-button type="primary" @click="download" key="2-2">下载</el-button>
        <el-button type="primary" @click="copy" key="2-3">复制</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import studentInfo from '../pub/studentInfo'
import exportChart from './exportChart'
import html2canvas from 'html2canvas'
import stageType from './stageType.json'
import stagesList from './stagesList.json'
import Vue from 'vue'
export default {
  name: 'export-img',
  data () {
    return {
      smesable: true,
      dirAble: true,
      project_typeable: true,
      step: 1,
      checks: {
        checkList: [],
        checkInter: true
      },
      stageAble: true,
      chartData: [],
      showHeader: true
    }
  },
  props: {
    smes: Object,
    tableData: Array,
    project: Object,
    interview: Object,
    direction: String,
    isEnterprise: {
      default: false,
      type: Boolean
    }
  },
  components: {
    studentInfo,
    exportChart
  },
  computed: {
    active () {
      const res = stagesList[stageType[this.project.project_type] || '基础']
      const stage = this.project.stageComplete
        ? this.project.stage + 1
        : this.project.stage
      return res[stage].stage
    }
  },
  created () {
    this.tableData.forEach(function (value, index, array) {
      let val = false
      Vue.set(array[index], 'exportable', val)
      Vue.set(array[index].tasks, 'exportable', val)
      for (const item of array[index].tasks) {
        item.type === 'remark'
          ? Vue.set(item, 'exportable', false)
          : Vue.set(item, 'exportable', true)
      }
    })
    let res = []
    for (const tableDatum of this.tableData) {
      res = [...tableDatum.tasks, ...res]
    }
    this.chartData = res
  },
  methods: {
    preview () {
      this.$nextTick(() => {
        let that = this
        console.log('dassssf')
        setTimeout(() => {
          this.$nextTick(() => {
            html2canvas(document.querySelector('#eportcontent'), {
              scale: 2,
              ignoreElements: e => {
                if (
                  typeof e.className === 'string' &&
                  e.className.indexOf('exportable') > -1
                ) {
                  return true
                }
              }
            }).then(canvas => {
              that.step = 2
              that.$nextTick(() => {
                document.querySelector('#preview').appendChild(canvas)
              })
            })
          })
        }, 0)
      })
    },
    back () {
      this.step = 1
      this.exportable = true
      document.querySelector('#preview').innerHTML = ''
    },
    copy () {
      let canvas = document.querySelector('canvas')
      canvas.toBlob(blob => {
        let clipboardItem = new ClipboardItem({ 'image/png': blob })
        navigator.clipboard.write([clipboardItem])
      }, 'image/png')
      this.$message({
        showClose: true,
        message: '复制成功',
        type: 'success'
      })
      this.$parent.$parent.exportI = false
    },
    download () {
      let canvas = document.querySelector('canvas')
      let a = document.createElement('a')
      let url = canvas.toDataURL('image/png')
      a.href = url
      a.download = '截图'
      a.click()
      this.$parent.$parent.exportI = false
    },
    checked (data, index) {
      this.showHeader = data
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ * {
  font-family: initial;
}
.itemtitle {
  text-align: left;
  font-size: 24px;
  color: #434a54;
  font-weight: normal;
  margin: 0;
  border-bottom: 1px solid #c0c0c0;
  padding: 15px 0px;
}
#eportcontent {
  position: relative;
  background: #f4f6f9;
  padding-left: 30px;
  border-radius: 40px;

  .studentInfo {
    padding-top: 25px;
    color: #656d78;
    font-size: 14px;
    display: flex;
    align-items: flex-end;
    .info {
      display: flex;
      justify-content: space-around;
      p {
        margin: 0 5px;
      }
    }
    .studentName {
      padding-top: 0;
      line-height: 30px;
      font-size: 30px;
    }
  }
}
.checkbox {
  position: absolute;
  top: 50px;
  right: 45px;
  margin: 0;
}
.checkboxDirec {
  position: absolute;
  top: 80px;
  right: 45px;
  margin: 0;
}
/deep/.el-checkbox__inner {
  width: 25px;
  height: 25px;
  border-radius: 30px;
  &::after {
    border: 2px solid #ffffff;
    border-left: 0;
    border-top: 0;
    height: 15px;
    left: 7.5px;
    top: 1px;
    width: 8px;
  }
}
/deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner:before {
  top: 13px;
}
/deep/.el-dialog {
  background: #fff;
  &::-webkit-scrollbar {
    background-color: #fff;
  }
}

.direction {
  font-size: 20px;
  padding-top: 20px;
  color: #656d78;
}
.interview {
  background-color: transparent;
  padding: 25px 48px 25px 8px;
  margin: 0;
}
.checkboxInter {
  position: absolute;
  top: 30px;
  right: 0;
}
.stage {
  line-height: 1;
  border-left: 5px solid #a1d46a;
  padding-left: 5px;
}
.checkboxStage {
  position: absolute;
  top: 119px;
  right: 10px;
  margin: 0;
}
.viewdate {
  padding: 0 10px;
  min-width: 90px;
  text-align: center;
}
</style>
