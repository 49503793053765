<template>
  <el-main class="main">
    <div>
      <div class="jtitle">
        <h2 class="jtext">修改信息</h2>
      </div>
      <div class="content">
        <el-form :model="sinfo" :rules="rules" ref="sinfo">
          <div>
            <el-row>
              <el-col :span="12">
                <div class="pmodtitle">
                  <div class="flex">
                    <h1 class="pertext">个人信息</h1>
                    <h5 class="must">*为必填项</h5>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <div class="grid-content bg-purple-light">
                    <div class="flex right">
                      <el-button type="primary" @click="pmtrue('sinfo')">
                        确认
                      </el-button>
                      <el-button type="primary" @click="pmclear('sinfo')">
                        清空
                      </el-button>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="permod">
            <el-row class="spacebetween">
              <el-col :span="8">
                <el-form-item prop="school">
                  <div class="inputgroup">
                    <div class="tleft">学校 *</div>
                    <el-input
                      placeholder="学校名称"
                      maxlength="64"
                      v-model="sinfo.school"
                      class="input"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="ml44">
                <el-form-item prop="direction">
                  <div class="inputgroup">
                    <div class="tleft">专业方向 *</div>
                    <el-input
                      placeholder="专业方向"
                      maxlength="64"
                      v-model="sinfo.direction"
                      class="input"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="ml44">
                <el-form-item prop="cellphone">
                  <div class="inputgroup">
                    <div class="tleft">手机 *</div>
                    <el-input
                      placeholder="手机"
                      maxlength="64"
                      v-model="sinfo.cellphone"
                      class="input"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="spacebetween">
              <el-col :span="8">
                <el-form-item prop="email">
                  <div class="inputgroup">
                    <div class="tleft">邮箱 *</div>
                    <el-input
                      placeholder="邮箱"
                      maxlength="64"
                      v-model="sinfo.email"
                      class="input"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="ml44">
                <el-form-item>
                  <div class="inputgroup">
                    <div class="tleft">微信</div>
                    <el-input
                      placeholder="微信"
                      maxlength="32"
                      v-model="sinfo.wechat"
                      class="input"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="ml44">
                <div class="inputgroup"></div>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
      <div class="content">
        <el-form :model="ruleform" :rules="rules" ref="ruleform">
          <div>
            <el-row>
              <el-col :span="12">
                <div class="pmodtitle">
                  <div class="flex">
                    <h1 class="pertext">安全信息</h1>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <div class="grid-content bg-purple-light">
                    <div class="flex right">
                      <el-button type="primary" @click="pptrue('ruleform')">
                        确认
                      </el-button>
                      <el-button type="primary" @click="ppclear('ruleform')">
                        清空
                      </el-button>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="permod">
            <el-row class="spacebetween">
              <el-col :span="8">
                <el-form-item prop="ypassword">
                  <div class="inputgroup">
                    <div class="tleft">原密码*</div>
                    <el-input
                      placeholder="原密码"
                      maxlength="64"
                      type="password"
                      v-model="ruleform.ypassword"
                      class="input pswinput"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="ml44">
                <el-form-item prop="npassword">
                  <div class="inputgroup">
                    <div class="tleft">新密码*</div>
                    <el-input
                      placeholder="新密码"
                      maxlength="64"
                      type="password"
                      v-model="ruleform.npassword"
                      class="input pswinput"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="ml44">
                <el-form-item prop="rpassword">
                  <div class="inputgroup">
                    <div class="tleft">重复密码*</div>
                    <el-input
                      placeholder="重复密码"
                      maxlength="64"
                      type="password"
                      v-model="ruleform.rpassword"
                      class="input pswinput"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
    </div>
  </el-main>
</template>

<script>
/* eslint-disable no-console */
import axios from '../axios'
import { MessageBox } from 'element-ui'
import changerole from '../pub/changerole'
export default {
  name: 'smodify',
  data () {
    return {
      role: '学生',
      rules: {
        school: [
          { required: true, message: '请填写学校名称', trigger: 'blur' }
        ],
        direction: [
          { required: true, message: '请填写专业方向', trigger: 'blur' }
        ],
        cellphone: [
          { required: true, message: '请填写手机号码', trigger: 'blur' }
        ],
        email: [{ required: true, message: '请填写邮箱地址', trigger: 'blur' }],
        ypassword: [
          { required: true, message: '请填写原密码', trigger: 'blur' }
        ],
        npassword: [
          { required: true, message: '请填写新密码', trigger: 'blur' }
        ],
        rpassword: [
          { required: true, message: '请再次填写新密码', trigger: 'blur' }
        ]
      },
      ruleform: {
        ypassword: '',
        npassword: '',
        rpassword: ''
      },
      sinfo: {
        school: '',
        direction: '',
        place: '',
        cellphone: '',
        wechat: '',
        email: ''
      }
    }
  },
  components: {
    changerole
  },

  created () {
    this.getinfo()
  },
  methods: {
    getinfo () {
      axios
        .get('/student/')
        .then(response => {
          this.sinfo = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    pmtrue (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const pattern = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
          if (!pattern.test(this.sinfo.email)) {
            this.$message({
              showClose: true,
              message: '邮箱格式有误',
              type: 'error'
            })
          } else {
            axios
              .put('/student/', {
                direction: this.sinfo.direction,
                school: this.sinfo.school,
                email: this.sinfo.email,
                wechat: this.sinfo.wechat,
                cellphone: this.sinfo.cellphone
              })
              .then(response => {
                if (response.status === 200) {
                  this.$message({
                    showClose: true,
                    message: '您的信息已修改',
                    type: 'success'
                  })
                }
              })
              .catch(error => {
                this.$message({
                  showClose: true,
                  message: `修改失败,${error.response.data.error_message}`,
                  type: 'error'
                })
              })
          }
        } else {
          return false
        }
      })
    },
    pptrue (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.ruleform.npassword !== this.ruleform.rpassword) {
            this.$message({
              showClose: true,
              message: '密码不一致',
              type: 'error'
            })
          } else {
            axios
              .post('/student/change_password', {
                old_password: this.ruleform.ypassword,
                new_password: this.ruleform.npassword,
                confirm: this.ruleform.rpassword
              })
              .then(response => {
                if (response.status === 200) {
                  MessageBox.alert('修改成功').then(() => {
                    localStorage.removeItem('Authorization')
                    this.$router.replace('/login')
                  })
                }
              })
              .catch(error => {
                this.$message({
                  showClose: true,
                  message: `修改失败,${error.response.data.error_message}`,
                  type: 'error'
                })
              })
          }
        } else {
          return false
        }
      })
    },
    ppclear (formName) {
      this.$refs[formName].resetFields()
      this.ruleform = {}
    },
    pmclear (formName) {
      this.$refs[formName].resetFields()
      this.sinfo = {}
    }
  }
}
</script>

<style scoped>
.input .el-input__inner {
  border-radius: 0px;
  background-color: #f4f6f9;
  height: 30px;
  font-size: 14px;
  padding-left: 10px;
}
.right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.modtitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 63px;
  border-bottom: 1px solid #c0c0c0;
}
.stu-right {
  margin-right: 40px;
}
.pertext {
  font-size: 48px;
  text-align: left;
  font-weight: 400;
  color: #656d78;
  margin-bottom: 45px;
}
.must {
  font-size: 14px;
  color: silver;
  margin-top: 40px;
  margin-left: 10px;
}
.el-button {
  margin-top: 25px;
  margin-left: 12px;
  width: 80px;
  height: 30px;
}

.content {
  margin-right: 50px;
  padding: 40px;
  margin-bottom: 20px;
  border-bottom: solid 1px #c0c0c0;
  background: #fff;
  border-radius: 8px;
  min-width: 600px;
}

.permod {
  height: 190px;
}
.tleft {
  text-align: left;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 7px;
}
.input {
  margin-top: 0px;
}
.inputgroup {
  width: 100%;
}
.spacebetween {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
}
.ml44 {
  margin-left: 44px;
}
.pmodtitle {
  min-width: 300px;
}
</style>
