<template>
  <el-dialog
    :visible.sync="active"
    width="500px"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <el-row>
      <el-col :span="24">
        {{
          `是否确认启动“${this.data.teacherName}-${this.data.project_type}”项目？`
        }}
      </el-col>
    </el-row>
    <el-form label-position="top" ref="form" :model="data">
      <el-form-item
        label="计划启动时间"
        prop="plan_time"
        :rules="[
          { required: true, message: '请输入计划启动时间', trigger: 'blur' }
        ]"
        class="form-item"
      >
        <el-input
          class="input"
          v-model="data.plan_time"
          placeholder="计划启动时间"
        ></el-input>
      </el-form-item>
      <el-form-item label="学生备注">
        <el-input
          class="input"
          v-model="student_misc"
          placeholder="学生备注将会被同时发送给教务和老师"
          type="textarea"
        ></el-input>
      </el-form-item>
      <el-form-item label="置顶备注">
        <el-input
          class="input"
          v-model="data.stickyMisc"
          placeholder="置顶备注将被放在学员进度页面顶部位置，请将重要信息如申请日期、特殊情况、中途考试信息等填写在此处"
          type="textarea"
        ></el-input>
      </el-form-item>
      <el-form-item label="启动备注（内部）">
        <el-input
          class="input"
          v-model="start_misc"
          placeholder="启动备注（内部）只会被发送给教务"
          type="textarea"
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span slot="footer" class="dialog-footer">
        <el-button @click="active = false">取 消</el-button>
        <el-button type="primary" @click="start" :loading="loading">
          签 约
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import axios from '../axios'
let StartDialog = {
  name: 'StartDialog',
  data () {
    return {
      active: false,
      data: {
        teacherName: '',
        project_type: '',
        plan_time: '',
        pid: 0,
        misc: '',
        stickyMisc: ''
      },
      loading: false
    }
  },
  computed: {
    student_misc: {
      get () {
        let tempArr = this.data.misc.split('&&^^')
        return typeof tempArr[0] === 'undefined' ? '' : tempArr[0]
      },
      set (val) {
        let tempArr = this.data.misc.split('&&^^')
        this.data.misc =
          val + '&&^^' + (typeof tempArr[1] === 'undefined' ? '' : tempArr[1])
      }
    },
    start_misc: {
      get () {
        let tempArr = this.data.misc.split('&&^^')
        return typeof tempArr[1] === 'undefined' ? '' : tempArr[1]
      },
      set (val) {
        let tempArr = this.data.misc.split('&&^^')
        this.data.misc =
          (typeof tempArr[0] === 'undefined' ? '' : tempArr[0]) + '&&^^' + val
      }
    }
  },
  methods: {
    openDialog (payload) {
      this.data.teacherName = payload.teacher
      this.data.project_type = payload.project_type
      this.data.pid = payload.pid
      this.active = true
      this.data.plan_time = payload.plan_time
      this.data.misc = payload.misc
      this.data.stickyMisc = payload.stickyMisc
    },
    start () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          axios
            .post(
              `/${this.$store.state.roleApi}/project/${this.data.pid}/plan_time`,
            {
              plan_time: this.data.plan_time,
              misc: this.data.misc,
              stickyMisc: this.data.stickyMisc
            }
            )
            .then(() => {
              axios
                .post(
                  `/${this.$store.state.roleApi}/project/${this.data.pid}/start`
                )
                .then(response => {
                  if (response.status === 200) {
                    this.$message({
                      showClose: true,
                      message: '该项目启动成功',
                      type: 'success'
                    })
                    setTimeout(() => {
                      window.location.reload()
                    }, 1000)
                  }
                })
                .catch(error => {
                  this.loading = false
                  console.log(error)
                })
            })
        }
      })
    }
  }
}
export default StartDialog
let StartDialogMixin = {
  components: {
    StartDialog: StartDialog
  },
  methods: {
    isStart () {
      this.$refs.StartDialog.openDialog({
        teacher: this.teacher.name,
        project_type: this.activeProject.project_type,
        pid: this.activeProject.id,
        plan_time: this.activeProject.startTime,
        misc: this.activeProject.misc,
        stickyMisc: this.activeProject.stickyMisc
      })
    }
  }
}
export { StartDialogMixin }
</script>

<style lang="scss" scoped>
.el-form /deep/.el-form-item__label {
  font-size: 14px;
  color: #656d78;
  line-height: 12px;
  padding: 20px 0px 10px;
}
.el-form /deep/.el-form-item__content .el-form-item__error {
  position: absolute;
  top: 0;
  right: 10px;
  text-align: right;
  pointer-events: none;
  line-height: 30px;
  padding-top: 0px;
}
/deep/ .el-textarea__inner {
  height: 100px !important;
}
/deep/.el-dialog__body {
  padding: 20px 35px;
}
/deep/.el-dialog__footer {
  padding: 0px 35px 20px 35px;
}
/deep/
  .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before {
  content: '*';
  color: #000;
  margin-right: -5px;
  position: relative;
  left: 85px;
}
/deep/ .el-col-24 {
  text-align: left;
}
</style>
