<template>
  <el-dialog title="编辑" :visible.sync="dialogEdit2" width="800px">
    <el-form ref="form2" :model="form2" :rules="rules2" label-width="90px">
      <el-row>
        <el-col :span="8">
          <el-form-item label="导师姓名">
            <el-input
              class="inputwidth"
              v-model="form2.name"
              disabled
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="辅导总工资">
            <el-input
              class="inputwidth"
              v-model="form2.project_wage"
              disabled
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="绩效*" prop="achievements">
            <el-input
              class="inputwidth"
              v-model="form2.achievements"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="应扣工资*" prop="deduct">
            <el-input class="inputwidth" v-model="form2.deduct"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="个税*" prop="income">
            <el-input class="inputwidth" v-model="form2.income"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="实发工资*">
            <el-input
              class="inputwidth"
              v-model="form2.grant"
              disabled
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="发放日期*" prop="grantDate">
            <el-date-picker
              v-model="form2.grantDate"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              class="inputwidth"
              :clearable="false"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogEdit2 = false">取 消</el-button>
      <el-button type="primary" @click="putedit2">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from '../axios.js'
export default {
  name: 'salaryDialog',
  props: {
    form2: {}
  },
  data () {
    return {
      dialogEdit2: false,
      rules2: {
        achievements: [
          {
            required: true,
            message: '请输入绩效'
          }
        ],
        deduct: [
          {
            required: true,
            message: '请输入应扣工资'
          }
        ],
        income: [
          {
            required: true,
            message: '请输入个税'
          }
        ],
        grantDate: [
          {
            required: true,
            message: '请选择日期',
            trigger: 'blur'
          }
        ]
      },
      finput: ''
    }
  },
  methods: {
    openDialog () {
      this.dialogEdit2 = true
    },
    putedit2 () {
      axios
        .put(`/finance/salary/${this.form2.id}`, {
          form: this.form2
        })
        .then(res => {
          if (res.status === 200) {
            this.$parent.getSalary()
            this.dialogEdit2 = false
            this.$message({
              message: '修改成功',
              type: 'success'
            })
          }
        })
    }
  }
}
</script>
<style scoped>
.inputwidth {
  width: 140px !important;
}
/deep/ .el-input__icon {
  position: relative;
  top: -5px;
  left: -5px;
}
/deep/ .el-dialog__header {
  padding-left: 35px;
}
/deep/ .el-dialog__footer {
  padding-right: 30px;
}
</style>
