<template>
  <el-main class="main">
    <div>
      <div class="jtitle">
        <h2 class="jtext">导师管理</h2>
      </div>
      <div class="table">
        <div class="search flex">
          <div class="seaicon">
            <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
          </div>
          <el-input v-model="accmessage" @input="search" class="seasubfix">
          </el-input>
        </div>
        <el-table :data="tableData2" :height="tableHeight" ref="tableScroll2">
          <el-table-column
            key="11"
            prop="id"
            label="编号"
            align="center"
            min-width="3"
          >
          </el-table-column>
          <el-table-column
            key="12"
            prop="name"
            label="姓名"
            align="center"
            min-width="5"
          >
            <template slot-scope="scope">
              <span v-html="showKeyWord(scope.row.name)"></span>
            </template>
          </el-table-column>
          <el-table-column
            key="13"
            prop="organization"
            label="机构"
            align="center"
            min-width="8"
          >
            <template slot-scope="scope">
              <span v-html="showKeyWord(scope.row.organization)"></span>
            </template>
          </el-table-column>
          <el-table-column
            key="14"
            prop="subject"
            label="学科方向"
            align="center"
            min-width="10"
          >
            <template slot-scope="scope">
              <el-tag
                :key="tag.id"
                v-for="tag in scope.row.subject"
                class="tags tag"
                :class="tagclass(tag)"
                :disable-transitions="true"
              >
                ⚬ {{ tag.content }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            key="15"
            prop="star"
            label="星级"
            align="center"
            min-width="4"
          >
          </el-table-column>
          <el-table-column
            key="18"
            prop="email"
            label="常用邮箱"
            align="center"
            min-width="10"
          >
          </el-table-column>
          <el-table-column
            key="18.5"
            prop="inst_email"
            label="机构邮箱"
            align="center"
            min-width="8"
          >
          </el-table-column>
          <el-table-column
            key="19"
            prop="cellphone"
            label="电话"
            align="center"
            min-width="6"
          >
          </el-table-column>
          <el-table-column
            key="20"
            prop="misc"
            label="导师身份"
            align="center"
            min-width="8"
          >
          </el-table-column>
          <el-table-column
            key="21"
            prop="graduate"
            label="是否博士毕业"
            align="center"
            min-width="6"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.graduate === true ? '博士毕业' : '博士在读'
              }}</span>
            </template>
          </el-table-column>
          <div slot="append" style="text-align: center; font-size: 16px">
            <span v-if="has_next">
              <i class="eliwise-loading"></i>加载中请稍后...
            </span>
            <i v-else>--没有数据了--</i>
          </div>
        </el-table>
      </div>
    </div>
  </el-main>
</template>
<script>
/* eslint-disable no-console */
import axios from '../axios'
import _ from 'lodash'
import changerole from '../pub/changerole'
export default {
  name: 'Jaccount',
  data () {
    return {
      tableHeight: window.innerHeight - 150,
      tableData2: [],
      accmessage: '',
      page: 1,
      has_next: true,
      loading: true
    }
  },
  created () {
    this.search()
  },
  mounted () {
    const that = this
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 150
      })()
    }
    this.dom2()
  },
  methods: {
    showKeyWord (str) {
      if (this.accmessage !== '') {
        let regStr = `${this.accmessage}`
        let reg = new RegExp(regStr, 'g')
        str = str.replace(
          reg,
          `<span style="background-color:#F7B500;">${this.accmessage}</span>`
        )
      }
      return str
    },
    tagclass (tag) {
      switch (tag.type) {
        case '理科':
          return tag.content.indexOf(this.accmessage) > -1 &&
            this.accmessage !== ''
            ? 'tag1'
            : 'tag2'
        case '工科':
          return tag.content.indexOf(this.accmessage) > -1 &&
            this.accmessage !== ''
            ? 'tag3'
            : 'tag4'
        case '商科':
          return tag.content.indexOf(this.accmessage) > -1 &&
            this.accmessage !== ''
            ? 'tag5'
            : 'tag6'
        case '社科':
          return tag.content.indexOf(this.accmessage) > -1 &&
            this.accmessage !== ''
            ? 'tag7'
            : 'tag8'
        case '医科':
          return tag.content.indexOf(this.accmessage) > -1 &&
            this.accmessage !== ''
            ? 'tag9'
            : 'tag10'
      }
    },
    a () {
      let scrollTop2 = this.dom2.scrollTop
      let windowHeight2 = this.dom2.clientHeight || this.dom2.clientHeight
      let scrollHeight2 = this.dom2.scrollHeight || this.dom2.scrollHeight
      if (scrollTop2 + windowHeight2 >= scrollHeight2 && this.has_next) {
        this.page = this.page + 1
        axios
          .get('/eadmin/teachers/', {
            params: {
              name: this.accmessage,
              page: this.page
            }
          })
          .then(response => {
            if (response.data.has_previous) {
              this.tableData2 = this.tableData2.concat(response.data.teachers)
            }
            this.has_next = response.data.has_next
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    dom2 () {
      this.dom2 = this.$refs.tableScroll2.bodyWrapper
      this.dom2.addEventListener('scroll', this.a)
    },
    search: _.debounce(function () {
      this.loading = true
      this.page = 1
      this.dom2.scrollTop = 0
      this.getTeacher()
    }, 500),
    getTeacher () {
      axios
        .get('/eadmin/teachers/', {
          params: {
            name: this.accmessage,
            page: this.page
          }
        })
        .then(response => {
          this.tableData2 = response.data.teachers
          this.loading = false
          this.has_next = response.data.has_next
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  components: {
    changerole
  }
}
</script>
