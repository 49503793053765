<template>
  <el-dialog
    :visible="active"
    width="70%"
    :before-close="close"
    :close-on-click-modal="false"
    custom-class="addCounselorScroll"
  >
    <template #title>
      <span class="el-dialog__title">{{ dialogT }}</span>
      <span style="color: #c0c0c0; font-size: 6px; padding-left: 5px">
        *为必填项
      </span>
    </template>
    <el-form :model="consultant" :rules="rules" ref="consultantForm">
      <el-row>
        <el-col :span="12" class="inputleft">
          <el-form-item prop="name">
            <div class="title">顾问姓名*</div>
            <el-input
              placeholder="顾问名称"
              v-model.trim="consultant.name"
              maxlength="10"
              class="input"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="inputright">
          <el-form-item prop="institution">
            <div class="title">机构</div>
            <el-input
              placeholder="机构"
              v-model.trim="consultant.institution"
              class="input"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="inputleft">
          <el-form-item prop="cellphone">
            <div class="title">电话</div>
            <el-input
              placeholder="电话"
              v-model.trim="consultant.cellphone"
              class="input"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="inputright">
          <el-form-item prop="wechat">
            <div class="title">微信</div>
            <el-input
              placeholder="微信"
              v-model.trim="consultant.wechat"
              class="input"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="inputleft">
          <el-form-item prop="taxnum">
            <div class="title">身份证号</div>
            <el-input
              placeholder="身份证号"
              v-model.trim="consultant.identity"
              class="input"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="inputright">
          <el-form-item prop="pubbank">
            <div class="title">开户行</div>
            <el-input
              placeholder="开户行"
              v-model.trim="consultant.bank.name"
              class="input"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="inputleft">
          <el-form-item prop="pubbank">
            <div class="title">银行账户</div>
            <el-input
              placeholder="银行账户"
              v-model.trim="consultant.bank.account"
              class="input"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="inputright">
          <el-form-item
            prop="salesman.id"
            :rules="{
              required: true,
              message: '请选择渠道经理',
              trigger: 'change'
            }"
          >
            <div class="title">渠道经理*</div>
            <el-select
              placeholder="渠道经理"
              v-model.trim="consultant.salesman.id"
              class="input saleselect"
            >
              <el-option
                v-for="item in cmanagerlist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <enterpiseForm
        :enterpise.sync="consultant.enterpise"
        :checkList.sync="checkList"
        :list.sync="list"
      ></enterpiseForm>
    </el-form>
    <template #footer>
      <el-button @click="close"> 取 消 </el-button>
      <el-button type="primary" @click="pushConsultant">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import axios from '../axios'
import enterpiseForm from './enterpiseForm'
export default {
  components: {
    enterpiseForm
  },
  data () {
    return {
      rules: {
        name: [{ required: true, message: '请填写顾问名称', trigger: 'blur' }],
        salesman: [
          { required: true, message: '请选择渠道经理', trigger: 'change' }
        ]
      },
      consultant: {
        name: '',
        institution: '',
        cellphone: '',
        wechat: '',
        identity: '',
        salesman: {
          id: '',
          name: ''
        },
        bank: {
          name: '',
          account: ''
        },
        enterpise: {
          id: '',
          username: '',
          research: '',
          thesis: '',
          rt: '',
          academic: '',
          epq: '',
          iap: '',
          iap2: '',
          lite: '',
          standard: '',
          sort: '',
          ignore: [],
          permission: false
        }
      },
      checkList: [],
      active: false,
      dialogT: '添加顾问',
      roleApi: '',
      cmanagerlist: [],
      list: []
    }
  },
  created () {
    this.roleApi = this.$store.state.roleApi
    axios.get(`/${this.roleApi}/sponser_list?role=cmanager`).then(response => {
      this.cmanagerlist = response.data.supports
    })
  },
  methods: {
    close () {
      this.$parent.consultantForm = false
    },
    pushConsultant () {
      const that = this
      this.$refs['consultantForm'].validate(valid => {
        if (valid) {
          if (this.dialogT === '添加顾问') {
            axios
              .post(`/${this.roleApi}/channel/consultant/`, this.consultant)
              .then(response => {
                if (response.status === 200) {
                  this.$message({
                    showClose: true,
                    message: '添加成功',
                    type: 'success'
                  })
                  this.$parent.getConsultant()
                  this.close()
                }
              })
              .catch(error => {
                this.$message({
                  showClose: true,
                  message: `添加失败,${error}`,
                  type: 'error'
                })
              })
          } else {
            let {
              name,
              cellphone,
              wechat,
              institution,
              bank,
              identity,
              salesman,
              enterpise
            } = this.consultant
            axios
              .put(
                `/${this.roleApi}/channel/consultant/${this.consultant.id}`,
              {
                name,
                cellphone,
                wechat,
                institution,
                bank,
                identity,
                salesman,
                enterpise
              }
              )
              .then(response => {
                if (response.status === 200) {
                  this.$message({
                    showClose: true,
                    message: '修改成功',
                    type: 'success'
                  })
                  this.$parent.getConsultant()
                  this.close()
                }
              })
              .catch(error => {
                this.$message({
                  showClose: true,
                  message: `添加失败,${error}`,
                  type: 'error'
                })
              })
          }
        } else {
          const base = that.$refs.consultantForm.$el.getBoundingClientRect().top
          for (const validElement of that.$refs.consultantForm.fields) {
            const isError =
              validElement.$el._prevClass.indexOf('is-error') !== -1
            const isSuccess =
              validElement.$el._prevClass.indexOf('is-success') !== -1
            const isRequire =
              validElement.$el._prevClass.indexOf('is-require') !== -1
            if ((isRequire && !isError && !isSuccess) || isError) {
              document.getElementsByClassName(
                'addCounselorScroll'
              )[0].scrollTop =
                validElement.$el.getBoundingClientRect().top - base + 40
              break
            }
          }
          return false
        }
      })
    }
  }
}
</script>
