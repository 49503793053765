<template>
  <el-main class="main">
    <div class="flex">
      <schedulelist
        @select="getstu1"
        url="/mdirector/students/not_finished"
        :options="options"
      />
      <div class="neirong">
        <div class="jtitle">
          <h2 class="jtext">辅导进度</h2>
        </div>
        <projectInfo :project="activeProject">
          <div slot="roles" class="rolesIcon">
            <img src="../../assets/roles/icon/导师.png" />
            <span>{{ activeProject.teacher || '待分配' }}</span>
            <img src="../../assets/roles/icon/助教.png" />
            <span>{{ activeProject.assistant || '待分配' }}</span>
            <img src="../../assets/roles/icon/规划师.png" />
            <span>{{ activeProject.planner }}</span>
            <img src="../../assets/roles/icon/客户经理.png" />
            <span>{{ activeProject.amanagerName || '无' }}</span>
            <img src="../../assets/roles/icon/渠道.png" />
            <span class="channel">
              {{
                activeProject.channel_type === 'inst'
                  ? activeProject.channel.abbreviation
                  : activeProject.channel_type === 'cons'
                  ? `个人-${activeProject.channel.name}`
                  : '自有'
              }}
            </span>
            <label v-if="activeProject.adviserName">{{
              '-' + activeProject.adviserName
            }}</label>
          </div>
          <div slot="buttons">
            <informationButton :pid="pid"> </informationButton>
            <file-button :pid="pid"></file-button>
            <el-tooltip placement="top" effect="light" content="导出辅导记录">
              <el-button type="primary" @click="exportI = true" size="mini">
                <i class="el-icon-download" style="font-size:22px"></i>
              </el-button>
            </el-tooltip>
            <el-button type="primary" @click="additem">添加项目</el-button>
            <el-button type="primary" @click="fixitem">
              修改项目信息
            </el-button>
            <status-button
              ref="editStatus"
              @refresh="refresh"
              :pid="pid"
            ></status-button>
            <remark-button
              ref="editRemark"
              @refresh="refresh"
              :pid="pid"
            ></remark-button>
            <el-button type="primary" @click="isEnd">
              确认结项
            </el-button>
          </div>
          <div slot="stages">
            <p style="font-size: 14px;color: #989898;">
              置顶备注：{{ activeProject.stickyMisc || '无' }}
            </p>
            <stages
              @listentoChart="showMsgchart"
              :tableData="tableData"
              :editable="true"
              :project="activeProject"
              :interview="interview"
            ></stages>
          </div>
        </projectInfo>
      </div>
    </div>
    <newProject ref="newProject" />
    <editProject ref="editProject" />
    <EndDialog isstart="schedule" :tasks="tableData" ref="EndDialog" />
    <export-img
      v-if="exportI"
      :smes="smes"
      :tableData="tableData"
      :project="activeProject"
      :interview="interview"
    ></export-img>
    <suggestRead :project="activeProject"></suggestRead>
  </el-main>
</template>

<script>
import axios from '../axios'
import schedulelist from '../pub/scheduleList'
import projectInfo from '../pub/projectInfo'
import stages from '../pub/stages'
import exportImg from '../pub/exportImg'
import fileButton from '../pub/buttons/fileButton'
import informationButton from '../pub/buttons/informationButton'
import statusButton from '../pub/buttons/statusButton'
import remarkButton from '../pub/buttons/remarkButton'
import newProject from '../pub/studentDialog/newProject'
import editProject from '../pub/studentDialog/editProject'
import { EndDialogMixin } from '../pub/EndDialog.vue'
import suggestRead from '../pub/suggestRead'
export default {
  name: 'MDshedule',
  components: {
    schedulelist,
    projectInfo,
    stages,
    exportImg,
    fileButton,
    informationButton,
    statusButton,
    remarkButton,
    newProject,
    editProject,
    suggestRead
  },
  mixins: [EndDialogMixin],
  data () {
    return {
      beizhuTitle: '',
      smes: {
        name: '',
        school: '',
        cellphone: '',
        email: '',
        direction: '',
        sex: ''
      },
      ruleform: {
        status: '',
        beizhu: ''
      },
      tableData: [],
      zhuangTitle: '',
      zhuangDialog: false,
      remarkDialog: false,
      options: ['学员', '渠道', '销售顾问', '规划师', '项目', '方向', '专业'],
      interview: {},
      project_type: '',
      teacher: '',
      pid: 0,
      status_id: '',
      remark_id: '',
      activeProject: {},
      beizhuDialog: false,
      exportI: false
    }
  },
  methods: {
    fixitem () {
      this.$refs.editProject.openDialog(this.activeProject)
    },
    showMsgchart (data) {
      switch (data.title) {
        case '修改备注（导师不可见）':
          this.$refs.editRemark.edit(data)
          break
        case '修改状况（导师可见）':
          this.$refs.editStatus.edit(data)
          break
        case 'refresh':
          this.refresh()
          break
      }
    },
    additem () {
      this.$refs.newProject.openDialog(this.activeProject, this.smes)
    },
    getstu1 (smes = false) {
      let id = 0
      if (smes) {
        id = smes.project_id
      } else {
        id = this.pid
      }
      axios
        .get(`/${this.$store.state.roleApi}/project/${id}/`)
        .then(response => {
          this.smes = response.data.student
          this.teacher = response.data.teacher.name
          this.project_type = response.data.project.project_type
          this.interview = response.data.interview[0]
          this.pid = response.data.project.id
          this.tableData = response.data.stages
          this.activeProject = {
            ...response.data.project,
            salesman: response.data.sales,
            sponser: response.data.support,
            amanager: response.data.amanager,
            cmanager: response.data.cmanager
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    refresh () {
      this.getstu1()
    }
  }
}
</script>
