<template>
  <div>
    <el-dialog
      title="上传协议"
      :visible.sync="uploadData.active"
      width="500px"
      class="mianDialog uploadData"
      :before-close="close"
    >
      <div>
        <el-upload
          class="channel-upload"
          :action="uploadUrl"
          :on-remove="handleRemove"
          :limit="1"
          multiple
          accept=".jpg,.jpeg,.png,.doc,.docx,.pdf,.JPG,.JPEG,.PNG,.DOC,.DOCX,.PDF"
          :on-exceed="handleExceed"
          :on-success="handleSuccess"
          :on-error="handleError"
          :beforeUpload="beforeFileUpload"
          ref="upload"
        >
          <template v-slot:default>
            <a
              v-show="!uploadData.uploadingfiles"
              type="primary"
              style="text-decoration: underline"
              >添加文件</a
            >
          </template>
          <template #file="{ file }">
            <div class="fileitem">
              <div class="filename">
                <div>{{ file.name }}</div>
              </div>
              <div @click="handleRemove(file)" class="el-icon-close"></div>
              <div
                v-if="file.status == 'success'"
                class="el-icon-upload-success el-icon-check"
                style="color: #8dc152"
              ></div>
            </div>
          </template>
        </el-upload>
        <el-row style="line-height: 25px">
          <el-col :span="10" style="color: #656d78">终止时间</el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-date-picker
              v-model="uploadData.tempfiles.date"
              type="date"
              placeholder="选择日期"
              class="endTime"
              value-format="yyyyMMdd"
              :pickerOptions="pickerOptions"
            />
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button
          :class="{ ftrue: this.files.length === 0 }"
          type="primary"
          @click="uploadfinish"
          >确认
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../axios'
import { cloneDeep } from 'lodash'
export default {
  data () {
    return {
      files: [],
      uploadUrl: `${axios.defaults.baseURL}/upload/`,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now()
        }
      },
      uploadData: {
        active: false,
        tempfiles: { name: '', file: '', date: '' },
        type: '',
        id: ''
      }
    }
  },
  methods: {
    beforeFileUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 5MB!',
          type: 'warning'
        })
      } else {
        this.uploadData.uploadingfiles = true
      }
      return isLt2M
    },
    handleExceed (files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      )
    },
    handleRemove (file) {
      this.$refs['upload'].abort(file)
      this.$refs['upload'].clearFiles()
      this.files = []
      this.uploadData.uploadingfiles = false
      this.uploadData.tempfiles.file = null
    },
    handleSuccess (res, file) {
      this.$message({
        showClose: true,
        message: `文件上传成功`,
        type: 'success'
      })
      this.uploadData.tempfiles.file = res.key
      this.uploadData.tempfiles.name = file.name
      this.files.push(res.key)
    },
    handleError () {
      this.$message({
        showClose: true,
        message: `文件上传失败`,
        type: 'error'
      })
      this.uploadData.uploadingfiles = false
    },
    close () {
      this.$parent.uploadDialog = false
    },
    uploadfinish () {
      if (this.uploadData.tempfiles.date === '') {
        this.$message({
          message: '请选择截止日期',
          type: 'warning'
        })
        return true
      }
      if (this.uploadData.tempfiles.file === '') {
        this.$message({
          message: '请上传文件',
          type: 'warning'
        })
        return true
      }
      if (this.uploadData.type === 'add') {
        axios
          .post(`/${this.$store.state.roleApi}/channel/institution/agreement`, {
            id: this.uploadData.id,
            file: this.uploadData.tempfiles.file,
            enddate: this.uploadData.tempfiles.date
          })
          .then(() => {
            this.$message({
              showClose: true,
              message: `协议上传成功`,
              type: 'success'
            })
            window.location.reload()
          })
        this.uploadData.tempfiles.name = ''
      } else {
        this.$parent.institution.agreement = cloneDeep(
          this.uploadData.tempfiles
        )
      }
      this.uploadData.active = false
    }
  }
}
</script>

<style lang="scss" scoped>
.channel-upload {
  width: 100%;
  position: relative;
  right: 0;
  overflow: visible;
  .fileitem {
    width: 100%;
    display: flex;
    align-items: center;
    .el-icon-close,
    .el-icon-upload-success {
      position: absolute;
      right: 3px;
      top: 6px;
    }
    &:hover .el-icon-upload-success {
      display: none;
    }
    .filename {
      flex: 1 1 auto;
      div {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 25px;
      }
    }
  }
}
</style>
