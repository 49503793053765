<template>
  <div class="addform">
    <div class="title">
      <div class="project_type">{{ title }}</div>
      <el-button
        v-if="listData.length === 0"
        class="circleBtn"
        icon="el-icon-plus"
        @click="addItem()"
        circle
      />
    </div>
    <div v-for="(item, index) in listData" :key="index">
      <slot v-bind:item="item" v-bind:index="index"></slot>
      <div class="btnrow">
        <el-tooltip
          content="已绑定项目"
          effect="light"
          placement="top"
          :disabled="!item.used"
        >
          <div>
            <el-button
              class="circleBtn"
              icon="el-icon-minus"
              @click="deleteItem(index)"
              :disabled="item.used"
              circle
            />
          </div>
        </el-tooltip>
        <el-button
          v-if="index === listData.length - 1"
          class="circleBtn"
          icon="el-icon-plus"
          @click="addItem()"
          circle
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'addform',
  model: {
    prop: 'listData',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      required: true
    },
    listData: {
      type: [Array],
      required: true,
      default: () => []
    },
    default: {
      type: Number,
      default: 0
    }
  },
  watch: {
    listData: {
      handler () {
        if (this.listData.length < this.default) {
          this.$emit(
            'change',
            Array(this.default)
              .fill(null)
              .map(() => Object())
          )
        }
      },
      immediate: true
    }
  },
  methods: {
    deleteItem (index) {
      let temp = this.listData
      temp.splice(index, 1)
      this.$emit('change', temp)
    },
    addItem () {
      this.$emit('change', this.listData.concat(Object()))
    }
  }
}
</script>

<style lang="scss" scoped>
.addform {
  position: relative;
}
.btnrow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  height: 22px;
  margin: 0px -1px 10px;
  & * {
    flex: 0 0 20px;
  }
}
.circleBtn {
  min-width: 20px;
  height: 20px;
  padding: 0;
  border: 1px solid #8d9394;
  color: #8d9394;
  position: absolute;
  right: 0px;
}
.title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-right: 10px;
  box-sizing: border-box;
}
.el-tooltip {
  position: absolute;
  right: 30px;
  top: 0;
}
</style>
