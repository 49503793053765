<template>
  <el-dialog
    :title="title"
    :visible.sync="active"
    width="35%"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :open="dialogOpen()"
  >
    <el-form
      ref="manual"
      :model="manual"
      :rules="rules"
      :hide-required-asterisk="true"
    >
      <el-row>
        <el-col>
          <el-form-item label="手册名称*" prop="name">
            <el-input v-model="manual.name" placeholder="手册名称"> </el-input>
          </el-form-item>
          <el-form-item label="所属阶段*" prop="stage">
            <el-select
              v-model="manual.stage"
              placeholder="所属阶段"
              style="width: 100%"
            >
              <el-option v-for="item in stages" :key="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上传文件*" prop="pdf" ref="pdf">
            <el-upload
              :action="uploadUrl"
              :on-remove="handleRemove"
              multiple
              :limit="1"
              drag
              :on-success="handleSuccess"
              :on-error="handleError"
              :beforeUpload="beforeFileUpload"
              :file-list="files"
              :show-file-list="showFile"
              :disabled="uploadable"
              :on-exceed="uploadclick"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em style="color: #8dc152; text-decoration: underline">
                  点击上传
                </em>
              </div>
            </el-upload>
            <el-link
              :href="manual.pdf"
              type="info"
              class="attachment"
              v-if="files.length === 0"
            >
              {{ pdfName }}
            </el-link>
          </el-form-item>
          <el-form-item label="手册简介" prop="introduction">
            <el-input
              type="textarea"
              :rows="7"
              placeholder="手册简介"
              v-model="manual.introduction"
              maxlength="300"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button @click="cancel()">取消</el-button>
      <el-button type="primary" @click="commit()">确认</el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from '../axios'

export default {
  name: 'manualForm',
  data () {
    return {
      active: false,
      uploadUrl: `${axios.defaults.baseURL}/upload/`,
      files: [],
      title: '',
      showFile: true,
      uploadable: false,
      manualId: '',
      pdfName: '',
      stages: [
        '导师手册',
        '项目介绍',
        '面试阶段',
        '辅导阶段',
        '结项阶段',
        '其他'
      ],
      manual: {
        name: '',
        stage: '',
        pdf: '',
        introduction: ''
      },
      rules: {
        name: [{ required: true, message: '请填写文件名', trigger: 'blur' }],
        stage: [
          { required: true, message: '请选择所属阶段', trigger: 'change' }
        ],
        pdf: [{ required: true, message: '请上传手册' }]
      }
    }
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  methods: {
    dialogOpen () {
      if (this.type === 'post') {
        this.title = '上传手册'
      } else {
        this.title = '编辑手册'
      }
    },
    cancel () {
      this.files = []
      this.manual = {}
      this.active = false
      this.uploadable = false
      this.$refs['manual'].clearValidate()
    },
    uploadclick () {
      if (this.files.length > 0) {
        this.uploadable = true
        this.$message({
          message: '只能上传一个文件',
          type: 'warning'
        })
      }
    },
    handleRemove (file) {
      const index = this.files.indexOf(file.response.key)
      if (index > -1) {
        this.files.splice(index, 1)
      }
    },
    // 文件上传成功时的钩子
    handleSuccess (res) {
      this.$message({
        showClose: true,
        message: `文件上传成功`,
        type: 'success'
      })
      this.files.push(res.key)
      this.manual.pdf = res.url
      this.$refs.manual.validateField('pdf')
    },
    // 文件上传失败时的钩子
    handleError () {
      this.$message({
        showClose: true,
        message: `文件上传失败`,
        type: 'error'
      })
    },
    beforeFileUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 50
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 50MB!',
          type: 'warning'
        })
      }
      this.pdfName = ''
      return isLt2M
    },
    commit () {
      if (this.type === 'post') this.postForm()
      else if (this.type === 'put') this.putForm()
    },
    postForm () {
      this.$refs['manual'].validate(valid => {
        if (valid) {
          axios
            .post(`/manual/Manual`, {
              name: this.manual.name,
              stage: this.manual.stage,
              pdf: this.manual.pdf,
              introduction: this.manual.introduction
            })
            .then(response => {
              if (response.status === 200) {
                this.$message({
                  message: '上传成功',
                  type: 'success'
                })
                setTimeout(() => {
                  window.location.reload()
                }, 1000)
              }
            })
        } else {
          return false
        }
      })
    },
    putForm () {
      this.$refs['manual'].validate(valid => {
        if (valid) {
          axios
            .put(`/manual/Manual/${this.manualId}`, {
              name: this.manual.name,
              stage: this.manual.stage,
              pdf: this.manual.pdf,
              introduction: this.manual.introduction
            })
            .then(response => {
              if (response.status === 200) {
                this.$message({
                  message: '修改成功',
                  type: 'success'
                })
                setTimeout(() => {
                  window.location.reload()
                }, 1000)
              }
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-form-item__label {
  text-align: left;
  width: 100%;
}
/deep/.el-upload {
  width: 100%;
}
/deep/.el-input__inner {
  background: #fff;
}
/deep/textarea {
  background: #fff;
}
</style>
