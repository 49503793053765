<template>
  <div class="wrap">
    <div class="course">{{ course }}</div>
    <div class="subhead" v-if="videos.length > 0">视频</div>
    <div class="connent">
      <div
        class="container"
        v-for="(item, index) in videos"
        :key="index"
        @click="handleClick(item.url)"
        style="cursor: pointer"
      >
        <img :src="item.img" alt="" class="img" />
        <div class="intro">
          <div class="v-title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="subhead" v-if="files.length > 0">文件资料</div>
    <div class="connent">
      <div class="wordcontainer" v-for="(item, index) in files" :key="index">
        <a :href="item.file" class="wordimg">
          <img
            src="../assets/icon/ppt-icon.png"
            class="wordimg"
            v-if="item.name.indexOf('.ppt') > -1"
          />
          <img
            src="../assets/icon/xls-icon.png"
            class="wordimg"
            v-else-if="item.name.indexOf('.xls') > -1"
          />
          <img src="../assets/icon/word-icon.png" class="wordimg" v-else />
        </a>
        <a
          :href="item.file"
          style="text-decoration:none;color:#333;"
          class="w-title"
        >
          <div class="w-title">{{ item.name }}</div></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from './axios'
export default {
  name: 'Course',
  data () {
    return {
      course: '',
      stage: '',
      key: '',
      videos: [],
      url: '',
      files: []
    }
  },
  created () {
    const stage = this.$route.query.stage
    axios
      .get(`/course/get_videos`, {
        params: {
          stage: stage
        }
      })
      .then(res => {
        this.course = res.data.course
        this.stage = res.data.stage
        this.videos = res.data.videos
        this.files = res.data.files
      })
  },
  methods: {
    handleClick (url) {
      localStorage.setItem('temp', url)
      window.open('/video')
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 960px) {
  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 120px;
  }
  .course {
    text-align: left;
    font-size: 36px;
    margin-bottom: 20px;
  }
  .subhead {
    text-align: left;
    font-size: 20px;
  }
  .stage {
    text-align: left;
    font-size: 20px;
  }
  .wraptitle {
    font-size: 24px;
    text-align: left;
    padding-left: 20px;
  }
  .v-title {
    margin: 10px 0;
    text-align: left;
    font-size: 20px;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
  }
  .v-introduction {
    text-align: left;
  }
  .connent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
  }
  .container {
    display: flex;
    flex-direction: column;
    width: 28%;
    /*height: 360px;*/
    text-decoration: none;
    color: #333;
    margin-bottom: 16px;
    margin-right: 5%;
  }
  .wordcontainer {
    display: flex;
    flex-direction: column;
    height: 190px;
    width: 20%;
    text-align: left;
    font-size: 16px;
    color: #333;
    margin-right: 3.5%;
    margin-bottom: 15px;
  }
  .wordimg {
    height: 100px;
    width: 81px;
    margin-bottom: 24px;
    cursor: pointer;
  }
  .w-title {
    max-width: 250px;
    text-wrap: normal;
    cursor: pointer;
  }
  .img {
    height: 270px;
    overflow: hidden;
    box-shadow: 0 2px 8px 0 rgba(204, 209, 217, 0.5);
  }
  .intro {
    width: 100%;
    /*height: 130px;*/
  }
}
@media screen and (max-width: 640px) {
  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }
  .course {
    text-align: left;
    font-size: 20px;
  }
  .subhead {
    text-align: left;
    font-size: 15px;
  }
  .stage {
    text-align: left;
    font-size: 16px;
  }
  .connent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;
  }
  .container {
    display: flex;
    flex-direction: column;
    width: 48%;
    height: 180px;
  }
  /*文档框*/
  .wordcontainer {
    display: flex;
    flex-direction: column;
    height: 190px;
    width: 20%;
    text-align: left;
    font-size: 16px;
    color: #333;
    margin-right: 3.5%;
    margin-bottom: 15px;
  }
  /*文档icon*/
  .wordimg {
    /*height: 100px;*/
    width: 100%;
    margin-bottom: 6px;
    cursor: pointer;
  }
  /*文档名*/
  .w-title {
    max-width: 250px;
    text-wrap: normal;
    cursor: pointer;
    font-size: 8px;
  }
  .img {
    width: 100%;
    height: 106px;
    overflow: hidden;
    box-shadow: 0 2px 8px 0 rgba(204, 209, 217, 0.5);
  }
  .v-title {
    margin-top: 15px;
    text-align: left;
    font-size: 12px;
  }
  .v-introduction {
    text-align: left;
    font-size: 12px;
  }
  .intro {
    width: 100%;
  }
}
</style>
