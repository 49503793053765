<template>
  <div class="aside">
    <changerole></changerole>
    <el-menu router @select="handleSelect"> </el-menu>
    <div class="back">
      <img
        @click="logout"
        src="../../assets/icon/退出.png"
        height="20"
        width="20"
      />
    </div>
  </div>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'
import changerole from '../pub/changerole'
export default {
  name: 'fnav',
  components: { changerole },
  methods: {
    handleSelect (key, keyPath) {
      this.$router.replace({ path: `${keyPath}` })
    },
    logout () {
      MessageBox.confirm('是否注销？')
        .then(() => {
          axios.delete('/logout/').then(() => {
            localStorage.removeItem('Authorization')
            this.$router.replace('/login')
          })
        })
        .catch(() => {})
    }
  }
}
</script>
