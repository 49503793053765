<template>
  <div style="display: inline-block">
    <el-select
      v-model="value"
      popper-class="rolelist"
      class="changepsw select"
      @change="changerole"
    >
      <el-option key="修改密码" label="修改密码" value="修改密码"></el-option>
    </el-select>
    <el-dialog
      :visible.sync="passwordVisible"
      width="1038px"
      title="修改密码"
      :close-on-click-modal="false"
    >
      <el-form :model="ruleform" :rules="rules" ref="ruleform">
        <div class="permod">
          <el-row class="spacebetween">
            <el-col :span="12" class="inputleft">
              <el-form-item prop="ypassword">
                <div>
                  <div class="tleft">原密码*</div>
                  <el-input
                    placeholder="原密码"
                    maxlength="64"
                    type="password"
                    v-model="ruleform.ypassword"
                    class="input pswinput"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputright">
              <el-form-item prop="npassword">
                <div>
                  <div class="tleft">新密码*</div>
                  <el-input
                    placeholder="新密码"
                    maxlength="64"
                    type="password"
                    v-model="ruleform.npassword"
                    class="input pswinput"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputleft">
              <el-form-item prop="rpassword">
                <div>
                  <div class="tleft">重复密码*</div>
                  <el-input
                    placeholder="重复密码"
                    maxlength="64"
                    type="password"
                    v-model="ruleform.rpassword"
                    class="input pswinput"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button @click="pptrue('ruleform')" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'
export default {
  name: 'changepsw',
  data () {
    return {
      rules: {
        ypassword: [
          { required: true, message: '请填写原密码', trigger: 'blur' }
        ],
        npassword: [
          { required: true, message: '请填写新密码', trigger: 'blur' }
        ],
        rpassword: [
          { required: true, message: '请再次填写新密码', trigger: 'blur' }
        ]
      },
      ruleform: {
        ypassword: '',
        npassword: '',
        rpassword: ''
      },
      passwordVisible: false,
      value: '设置'
    }
  },
  methods: {
    cancel () {
      this.passwordVisible = false
    },
    pptrue (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.ruleform.npassword !== this.ruleform.rpassword) {
            this.$message({
              showClose: true,
              message: '密码不一致',
              type: 'error'
            })
          } else {
            axios
              .post(`/DBS/change_password`, {
                old_password: this.ruleform.ypassword,
                new_password: this.ruleform.npassword,
                confirm: this.ruleform.rpassword
              })
              .then(response => {
                if (response.status === 200) {
                  this.$message({
                    showClose: true,
                    message: '修改成功',
                    type: 'success'
                  })
                  setTimeout(() => {
                    localStorage.removeItem('Authorization')
                    this.$router.replace('/login')
                  }, 1000)
                }
              })
              .catch(error => {
                this.$message({
                  showClose: true,
                  message: `修改失败,${error.response.data.error_message}`,
                  type: 'error'
                })
              })
          }
        } else {
          return false
        }
      })
    },
    changerole (e) {
      if (e === '修改密码') {
        this.value = '设置'
        this.passwordVisible = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.changepsw {
  position: absolute;
  right: 0px;
  top: 0px;
  /deep/.el-input__inner {
    width: 80px;
    border: none;
    color: #a0d496;
  }
}
</style>
