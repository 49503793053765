<template>
  <div class="jlistgroup">
    <el-row>
      <el-col class="buttonGroup" :span="24">
        <el-radio-group
          v-model="type"
          @change="choose4"
          size="medium"
          border="false"
        >
          <el-radio-button
            class="textbutton"
            label="学员进度"
          ></el-radio-button>
          <el-radio-button
            class="textbutton"
            label="结项学员"
          ></el-radio-button>
        </el-radio-group>
      </el-col>
    </el-row>
    <ul ref="list" v-if="type === '学员进度'">
      <li
        v-for="(item, index) in detail.notfinlist"
        @mouseover="getProjectTasks(item.project_id)"
        :key="index"
      >
        <div class="jlist">
          <el-row>
            <el-col :span="24" class="flex">
              <h2 class="itemtitle">
                {{ item.name }}. {{ item.type }}
                <img
                  src="../../assets/icon/组合项目.png"
                  class="zhimg"
                  alt=""
                  v-if="item.ispacked === true"
                />
              </h2>
              <div class="zhuang flex">
                <div v-if="item.days <= 7 && item.started" class="yuan color1">
                  <div v-if="item.warning !== false" class="remind"></div>
                </div>
                <div v-if="item.started === false" class="yuan color0">
                  <div v-if="item.warning !== false" class="remind"></div>
                </div>
                <div
                  v-else-if="item.days > 7 && item.days <= 15"
                  class="yuan color2"
                >
                  <div v-if="item.warning !== false" class="remind"></div>
                </div>
                <div
                  v-else-if="item.days > 15 && item.days <= 30"
                  class="yuan color3"
                >
                  <div v-if="item.warning !== false" class="remind"></div>
                </div>
                <div
                  v-else-if="item.days > 30 && item.days <= 60"
                  class="yuan color4"
                >
                  <div v-if="item.warning !== false" class="remind"></div>
                </div>
                <div v-else-if="item.days > 60" class="yuan color5">
                  <div v-if="item.warning !== false" class="remind"></div>
                </div>
                <div v-if="item.days <= 7 && item.started">正常状态</div>
                <div v-if="item.started === false">未开始辅导</div>
                <div v-else-if="item.days > 7 && item.days <= 15">
                  失联7天以上
                </div>
                <div v-else-if="item.days > 15 && item.days <= 30">
                  失联15天以上
                </div>
                <div v-else-if="item.days > 30 && item.days <= 60">
                  失联30天以上
                </div>
                <div v-else-if="item.days > 60">失联60天以上</div>
              </div>
              <div class="avatar flex flex-a-center">
                <div class="avatarIcon">规</div>
                <div>{{ item.support }}</div>
              </div>
              <informationButton
                :pid="item.project_id"
                class="information"
              ></informationButton>
              <final-button :pid="item.project_id" class="file"></final-button>
              <i
                v-if="taskLoading === item.project_id"
                class="eliwise-loading"
              />
              <img
                v-else
                class="imgBut"
                :class="{ imgtran: i === index, imgBut1: i !== index }"
                @click="isview(index, item.project_id)"
                src="../../assets/icon/tran.png"
                height="20"
                width="20"
              />
            </el-col>
          </el-row>
          <el-row v-if="i === index">
            <stages
              :tableData="projectTasks[item.project_id].stages"
              :project="{
                stage: projectTasks[item.project_id].stage,
                project_type: item.type
              }"
              style="background:#F5F7FA;padding:0 10px"
              :interview="item.interview[0]"
            ></stages>
          </el-row>
        </div>
      </li>
    </ul>
    <ul ref="list" v-else-if="type === '结项学员'">
      <li
        v-for="(item, index) in detail.finlist"
        :key="index"
        @mouseover="getProjectTasks(item.project_id)"
      >
        <div class="jlist">
          <el-row>
            <el-col :span="24" class="flex">
              <h2
                class="itemtitle"
                :style="{ color: item.started ? '#434A54' : '#c0c0c0' }"
              >
                {{ item.name }}. {{ item.type }}
                <img
                  src="../../assets/icon/组合项目.png"
                  class="zhimg"
                  alt=""
                  v-if="item.ispacked === true && item.started === true"
                />
                <img
                  src="../../assets/icon/组合项目2.jpg"
                  class="zhimg"
                  alt=""
                  v-if="item.ispacked === true && item.started === false"
                />
              </h2>
              <span v-if="item.abnormal" class="abnormal"> 异常结项 </span>
              <informationButton
                :pid="item.project_id"
                class="information"
              ></informationButton>
              <final-button :pid="item.project_id" class="file"></final-button>
              <i
                v-if="taskLoading === item.project_id"
                class="eliwise-loading"
              />
              <img
                v-else
                class="imgBut"
                :class="{ imgtran: i === index, imgBut1: i !== index }"
                @click="isview(index, item.project_id)"
                src="../../assets/icon/tran.png"
                height="20"
                width="20"
              />
            </el-col>
          </el-row>
          <el-row v-if="i === index">
            <stages
              :tableData="projectTasks[item.project_id].stages"
              :project="{
                stage: projectTasks[item.project_id].stage,
                project_type: item.type
              }"
              style="background:#F5F7FA;padding:0 10px"
              :interview="item.interview[0]"
            ></stages>
          </el-row>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from '../axios'
import finalButton from './buttons/finalButton'
import stages from './stages'
import informationButton from './buttons/informationButton'

export default {
  name: 'alistgroup',
  data() {
    return {
      type: '学员进度',
      i: null,
      projectTasks: {},
      taskLoading: null
    }
  },
  props: {
    detail: {
      type: Object,
      required: true
    }
  },
  components: {
    finalButton,
    stages,
    informationButton
  },
  watch: {
    detail: {
      handler() {
        this.i = null
        this.$refs.list.scrollTop = 0
      },
      deep: true
    }
  },
  methods: {
    async getProjectTasks(id) {
      if (this.projectTasks[id] === undefined)
        this.projectTasks[id] = axios
          .get(`/${this.$store.state.roleApi}/project/${id}/tasks`)
          .then(response => {
            this.projectTasks[id] = {
              stages: response.data.stages,
              stage: response.data.stage
            }
          })
          .catch(() => {
            this.projectTasks[id] = undefined
          })
    },
    isview(index, id) {
      if (this.i === index) {
        this.i = null
      } else if (id !== undefined && this.projectTasks[id] instanceof Promise) {
        this.i = null
        try {
          this.taskLoading = id
          this.projectTasks[id].then(() => {
            this.taskLoading = null
            this.i = index
          })
        } catch {
          this.taskLoading = null
          this.i = index
        }
      } else {
        this.i = index
      }
    },
    choose4() {
      this.i = null
    }
  }
}
</script>

<style lang="scss" scoped>
.buttonGroup {
  text-align: left;
  margin: 16px 0;
}
.jlistgroup ul {
  flex: auto;
  list-style: none;
  padding-left: 0;
  padding-right: 7px;
  overflow-y: scroll;
}
.imgBut {
  transition: 0.3s;
  transform: rotate(-90deg);
  position: absolute;
  right: 0px;
}
.imgtran {
  transition: 0.3s;
  transform: rotate(0deg);
}

.zhuang {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 285px;
  width: 130px;
  margin-left: 20px;
}
.yuan {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
}
.avatar {
  display: flex;
  justify-content: center;
  margin-left: 30px;
  position: absolute;
  left: 430px;
  .avatarIcon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    line-height: 30px;
    background-color: #ac8888;
    //font-weight: 700;
    color: #fff;
    font-size: 15px;
    margin-right: 5px;
  }
}
.color0 {
  background: #c0c0c0;
}
.color1 {
  background: #a0d469;
}
.color2 {
  background: #ffce54;
}
.color3 {
  background: #fc6e51;
}
.color4 {
  background: #ac92ec;
}
.color5 {
  background: #656d78;
}
.zhimg {
  position: relative;
  top: -10px;
  width: 14px;
}
.file {
  position: absolute;
  right: 60px;
}
.information {
  position: absolute;
  right: 108px;
}
.flex-a-center {
  align-items: center;
}
</style>
