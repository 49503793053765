<template>
  <div>
    <div class="jtitle">
      <h2 class="jtext">翻译/润色进度</h2>
    </div>
    <div class="table">
      <div class="search flex">
        <div class="seaicon">
          <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
        </div>
        <el-input v-model.trim="name" @input="search" class="seasubfix">
        </el-input>
      </div>
      <div :style="{ paddingRight: loading ? '5px' : 0, position: 'relative' }">
        <el-popover placement="bottom-end" trigger="click" width="280">
          <div>
            选择文件上传月份
          </div>
          <el-date-picker
            v-model="exportMonth"
            type="month"
            placeholder="选择月份"
            class="date"
            value-format="yyyy-MM"
          >
          </el-date-picker>
          <el-button
            class="exportConfirm"
            type="primary"
            @click="exportConfirm"
          >
            导表
          </el-button>
          <el-button slot="reference" class="export" type="primary">
            导表
          </el-button>
        </el-popover>
        <el-table
          :data="retouches"
          ref="tableScroll"
          :height="tableHeight"
          :row-class-name="statusClass"
        >
          <el-table-column
            key="1"
            prop="status"
            label="翻译/润色状态"
            align="center"
            min-width="5%"
            :filters="filtersRule"
            :filter-method="filterHandler"
            column-key="status"
          >
            <template slot-scope="scope">
              <el-tooltip
                placement="top"
                :content="status[getStatus(scope.row)].label"
                effect="light"
                popper-class="atooltip"
              >
                <div
                  class="circle"
                  :style="{
                    'background-color': status[getStatus(scope.row)].color
                  }"
                ></div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            key="2"
            prop="created"
            label="创建日期"
            align="center"
            min-width="5%"
          />
          <el-table-column
            key="3"
            prop="teacher"
            label="导师"
            align="center"
            min-width="5%"
          />
          <el-table-column
            key="4"
            prop="student"
            label="学生"
            align="center"
            min-width="5%"
          >
          </el-table-column>
          <el-table-column
            key="5"
            prop="need"
            label="需求"
            align="center"
            min-width="5%"
          >
          </el-table-column>
          <el-table-column
            key="6"
            prop="needFile"
            label="需求文件"
            align="center"
            min-width="10%"
          >
            <template slot-scope="scope">
              <span class="download" @click="download(scope.row.needFile)">
                {{ scope.row.needFile.name }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            key="7"
            prop="deadline"
            label="deadline"
            align="center"
            min-width="5%"
          >
          </el-table-column>
          <el-table-column
            key="8"
            prop="misc"
            label="备注"
            align="center"
            min-width="5%"
          >
          </el-table-column>
          <el-table-column
            key="9"
            prop="assigner"
            label="翻译"
            align="center"
            min-width="5%"
          >
            <template slot-scope="scope">
              {{ scope.row.assigner }}
              <el-button class="listedit" @click="addassigner(scope.row)">
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column key="11" align="center" min-width="11%">
            <template slot-scope="scope">
              <div class="flex">
                <information-button :pid="scope.row.pid"></information-button>
                <final-button :pid="scope.row.pid"></final-button>
                <el-button type="primary" @click="uploadFile(scope.row)">
                  上传文件
                </el-button>
                <div>
                  <el-button
                    type="primary"
                    size="mini"
                    @click="deleteRow(scope.row)"
                  >
                    <i class="el-icon-delete" style="font-size:22px"></i>
                  </el-button>
                </div>
              </div>
            </template>
          </el-table-column>
          <div slot="append" style="text-align: center; font-size: 16px">
            <span v-if="has_next">
              <i class="eliwise-loading"></i>加载中请稍后...
            </span>
            <i v-else>--没有数据了--</i>
          </div>
        </el-table>
      </div>
    </div>
    <el-dialog :visible="put" title="翻译" width="400px">
      <form name="recommendationForm" @submit.prevent="confirm">
        <el-input v-model="assigner" autocomplete="on" name="retouches" />
        <div style="text-align: right;margin-top: 30px;margin-bottom: 10px;">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" native-type="submit">确定</el-button>
        </div>
      </form>
    </el-dialog>
    <el-dialog :visible="upload" title="上传文件" width="400px">
      <el-upload
        :action="uploadUrl"
        drag
        :limit="1"
        :on-success="success"
        ref="upload"
      >
        <div style="margin-top:75px">
          将文件拖到此处，或
          <em style="color: #8dc152; text-decoration: underline">
            点击上传
          </em>
        </div>
      </el-upload>
      <div slot="footer">
        <el-button @click="uploadcancel">取消</el-button>
        <el-button type="primary" :disabled="pending" @click="uploadconfirm">
          确定
        </el-button>
        <i
          class="el-icon-loading"
          style="position: absolute;margin-top: 5px;margin-left: 5px;"
          v-if="pending"
        ></i>
      </div>
    </el-dialog>
    <iframe name="targetIfr" style="display:none"></iframe>
  </div>
</template>

<script>
import axios from '../axios'
import { debounce } from 'lodash'
import { MessageBox } from 'element-ui'
import informationButton from './buttons/informationButton'
import finalButton from './buttons/finalButton.vue'
import moment from 'moment'
export default {
  name: 'retouches',
  components: {
    informationButton,
    finalButton
  },
  data () {
    return {
      uploadUrl: `${axios.defaults.baseURL}/upload/`,
      status: {
        0: {
          label: '待领取',
          color: '#c0c0c0'
        },
        1: {
          label: '进行中',
          color: '#F7B500'
        },
        2: {
          label: '已完成',
          color: '#A1D46A'
        },
        3: {
          label: '逾期未完成',
          color: '#FA6400'
        }
      },
      retouches: [],
      name: '',
      loading: false,
      tableHeight: window.innerHeight - 155,
      has_next: true,
      pagenumber: 1,
      api: '',
      put: false,
      assigner: '',
      assignerid: 0,
      upload: false,
      key: '',
      rid: 0,
      filtersRule: [
        { text: '待领取', value: 0 },
        { text: '进行中', value: 1 },
        { text: '已完成', value: 2 },
        { text: '逾期未完成', value: 3 }
      ],
      pending: false,
      exportMonth: moment(Date.now())
        .subtract(1, 'months')
        .format('YYYY-MM')
    }
  },

  mounted () {
    this.loading = true
    this.api = this.$store.state.roleApi
    axios
      .get(`${this.api}/retouches`)
      .then(response => {
        this.retouches = response.data.retouches
        this.has_next = response.data.has_next
      })
      .then(() => {
        this.loading = false
      })
  },
  methods: {
    download (file) {
      let x = new XMLHttpRequest()
      x.open('GET', file.url, true)
      x.responseType = 'blob'
      x.onload = function (e) {
        var url = window.URL.createObjectURL(x.response)
        var a = document.createElement('a')
        a.href = url
        a.download = file.name
        a.click()
      }
      x.send()
    },
    filterHandler (value, row, column) {
      return this.getStatus(row) === value
    },
    uploadFile (row) {
      this.upload = true
      this.rid = row.id
    },
    success (res) {
      this.key = res.key
    },
    uploadcancel () {
      this.$refs.upload.clearFiles()
      this.key = ''
      this.upload = false
      this.pending = false
      this.rid = 0
    },
    exportConfirm () {
      window.open(
        `${axios.defaults.baseURL}/${this.api}/retouch/exportConfirm?month=${this.exportMonth}`,
        '_blank'
      )
    },
    uploadconfirm () {
      if (this.key === '') {
        this.$message({
          showClose: true,
          message: '文件不能为空',
          type: 'error'
        })
        return
      }
      this.pending = true
      axios
        .post(`${this.api}/retouch/${this.rid}`, {
          key: this.key
        })
        .then(res => {
          if (res.status === 200) {
            this.search()
            this.$message({
              showClose: true,
              message: '上传成功',
              type: 'success'
            })
            this.uploadcancel()
          }
        })
        .catch(res => {
          this.pending = false
        })
    },
    addassigner (row) {
      this.put = true
      this.assigner = row.assigner
      this.assignerid = row.id
    },
    cancel () {
      this.put = false
      this.assigner = ''
    },
    confirm () {
      axios
        .post(`${this.api}/retouch/${this.assignerid}/assigner`, {
          assigner: this.assigner
        })
        .then(res => {
          if (res.status === 200) {
            this.search()
            this.$message({
              showClose: true,
              message: '修改成功',
              type: 'success'
            })
            this.cancel()
          }
        })
    },
    deleteRow (row) {
      MessageBox.confirm('确定删除？').then(() => {
        axios.delete(`${this.api}/retouch/${row.id}`).then(res => {
          if (res.status === 200) {
            this.search()
            this.$message({
              showClose: true,
              message: '删除成功',
              type: 'success'
            })
          }
        })
      })
    },
    getStatus (row) {
      if (row.status === 2) {
        return 2
      }
      let now = new Date()
      let deadline = new Date(Date.parse(row.deadline.replace('-', '/')))
      if (now > deadline) {
        return 3
      } else {
        return row.status
      }
    },
    statusClass ({ row }) {
      if (row.pro_status === 2) {
        return 'finished'
      }
    },
    search: debounce(function () {
      axios
        .get(`${this.api}/retouches`, {
          params: {
            name: this.name
          }
        })
        .then(response => {
          this.retouches = response.data.retouches
          this.has_next = response.data.has_next
        })
    }, 1000)
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-table .cell {
  text-overflow: clip;
}
/deep/.finished .cell {
  color: #c0c0c0;
}
/deep/.flex {
  .el-button {
    margin-left: 10px;
  }
}
.circle {
  width: 12px;
  height: 12px;
  margin: 2px auto;
  border-radius: 50%;
  cursor: pointer;
}
.export {
  position: absolute;
  right: 0;
  top: 8px;
  z-index: 100;
}
/deep/.el-input__icon {
  line-height: 30px;
}
.date {
  width: 100%;
}
.exportConfirm {
  width: 100%;
  margin-top: 20px;
}
.download {
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    color: #a0d469;
  }
}
</style>
