<template>
  <div class="aside">
    <changerole></changerole>
    <el-menu router @select="handleSelect">
      <el-menu-item
        :class="$route.path == '/AD/ADschedule' ? 'is-active' : ''"
        index="/AD/ADschedule"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">学员<br />进度</div>
          <img class="iconmenu" src="../../assets/icon/学员进度@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/AD/ADpost' ? 'is-active' : ''"
        index="/AD/ADpost"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">投稿<br />进度</div>
          <img class="iconmenu" src="../../assets/icon/稿件.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/AD/ADrecommendations' ? 'is-active' : ''"
        index="/AD/ADrecommendations"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">推荐信<br />进度</div>
          <img class="iconmenu" src="../../assets/icon/推荐信.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/AD/ADretouches' ? 'is-active' : ''"
        index="/AD/ADretouches"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">翻译/润色<br />进度</div>
          <img class="iconmenu" src="../../assets/icon/翻译_润色icon.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/AD/ADstudent' ? 'is-active' : ''"
        index="/AD/ADstudent"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">结项<br />学员</div>
          <img class="iconmenu" src="../../assets/icon/结项学员@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/AD/ADteacher' ? 'is-active' : ''"
        index="/AD/ADteacher"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">查看<br />导师</div>
          <img class="iconmenu" src="../../assets/icon/查看导师@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/AD/ADassistant' ? 'is-active' : ''"
        index="/AD/ADassistant"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">查看<br />助教</div>
          <img class="iconmenu" src="../../assets/icon/查看助教.png" />
        </el-tooltip>
      </el-menu-item>
    </el-menu>
    <div class="back">
      <img
        @click="logout"
        src="../../assets/icon/退出.png"
        height="20"
        width="20"
      />
    </div>
  </div>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'
import changerole from '../pub/changerole'
export default {
  name: 'adnav',
  components: { changerole },
  methods: {
    handleSelect (key, keyPath) {
      this.$router.replace({ path: `${keyPath}` })
    },
    logout () {
      MessageBox.confirm('是否注销？')
        .then(() => {
          axios.delete('/logout/').then(() => {
            localStorage.removeItem('Authorization')
            this.$router.replace('/login')
          })
        })
        .catch(() => {})
    }
  }
}
</script>
