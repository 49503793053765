import axios from 'axios'
import {
    version
} from '../../package.json'
import {
    Message,
    MessageBox
} from 'element-ui'
// import { config } from "vue/types/umd"
import router from '../router/index.js'
axios.interceptors.request.use(
    config => {
      if (localStorage.getItem('Authorization')) {
        config.headers.Authorization = localStorage.getItem('Authorization')
        config.headers['Accept'] = `application/json, text/plain, */*; version=${version}`
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
)

// 请求拦截
axios.interceptors.response.use(
    config => {
      return config
    },
    error => {
      if (!axios.isCancel(error)) {
        if (error && error.response) {
          switch (error.response.status) {
            case 400:
              Message.error({
                message: error.response.data.error_message
              })
              break
            case 401:
              if (error.response.config.url.indexOf('is_login') > -1) {
                MessageBox.alert('未登陆，请重新登陆')
                localStorage.removeItem('Authorization')
                router.push('/login')
              }
              break
            case 403:
              Message.error({
                message: '拒绝访问(403)'
              })
              break
            case 404:
              Message.error({
                message: '请求出错(404)'
              })
              break
            case 405:
              Message.error({
                message: '请求方法未允许(405)'
              })
              break
            case 408:
              Message.error({
                message: '请求超时(408)'
              })
              break
            case 415:
              alert('系统有内容更新，请点击确定刷新')
              location.reload(true)
              break
            case 500:
              Message.error({
                message: '服务器错误(500)'
              })
              break
            case 502:
              Message.error({
                message: '网络错误(502)'
              })
              break
            case 503:
              Message.error({
                message: '服务不可用(503)'
              })
              break
            case 504:
              Message.error({
                message: '网络超时(504)'
              })
              break
            case 505:
              Message.error({
                message: 'http版本不支持该请求(505)'
              })
              break
            default:
              Message.error({
                message: `连接错误${error.response.status}`
              })
          }
        } else {
          Message.error({
            message: '连接到服务器失败'
          })
        }
      }
      return Promise.reject(error.message)
    }
)

// 超时时间
axios.defaults.timeout = 60000

axios.defaults.withCredentials = true

axios.defaults.baseURL = process.env.VUE_APP_ORIGIN

export default axios
