<template>
  <el-main class="main">
    <div class="flex">
      <div class="listMD" ref="element">
        <div class="search flex">
          <div class="seaicon">
            <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
          </div>
          <el-input @input="search" v-model="stutitle" class="seasubfix">
          </el-input>
          <el-select
            v-model="role"
            popper-class="rolelist"
            class="changerole"
            @change="rolechange"
          >
            <el-option
              v-for="item in searches"
              :key="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div>
          <el-rate
            style="text-align: left"
            v-model="star"
            disabled
            :colors="colors"
            disabled-void-color="#c0c0c0"
            score-template="{value}"
          >
          </el-rate>
        </div>
        <div :style="height" class="jstudent">
          <div class="preWrapper">
            <div class="pre" @click="preturn1 = !preturn1">
              <div class="pretext flex">
                <div class="listTip">未面试</div>
                <div class="wnum">
                  <span class="number">
                    {{ ('000' + notlist.length.toString()).slice(-3) }}
                  </span>
                </div>
              </div>
              <div
                :class="{ turn: preturn1 }"
                class="preimg el-icon-arrow-down"
              ></div>
            </div>
            <div v-if="preturn1">
              <div
                @click="getstu1(index)"
                :class="{ changeStyle: changeSelectStyle === index }"
                class="jstunum"
                v-for="(item, index) in notlist"
                :key="index"
              >
                <div class="studentname">
                  <span>{{ item.id }}.{{ item.name }}</span>
                  <img
                    src="../../assets/icon/组合项目.png"
                    class="zhimg"
                    alt=""
                    v-if="item.ispacked === true"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="preWrapper">
            <div class="pre" @click="midturn2 = !midturn2">
              <div class="pretext flex">
                <div class="listTip">辅导中</div>
                <div class="wnum">
                  <span class="number">
                    {{ ('000' + notfinlist.length.toString()).slice(-3) }}
                  </span>
                </div>
              </div>
              <div
                :class="{ turn: midturn2 }"
                class="preimg el-icon-arrow-down"
              ></div>
            </div>
            <div v-if="midturn2">
              <div
                @click="getstu2(index)"
                :class="{ changeStyle: changeSelectStyle2 === index }"
                class="jstunum"
                v-for="(item, index) in notfinlist"
                :key="index"
              >
                <div class="studentname">
                  <span>{{ item.id }}.{{ item.name }}</span>
                  <img
                    src="../../assets/icon/组合项目.png"
                    class="zhimg"
                    alt=""
                    v-if="item.ispacked === true"
                  />
                </div>
                <el-tooltip
                  placement="top"
                  effect="light"
                  popper-class="atooltip"
                >
                  <div v-if="item.posted" slot="content">投稿中</div>
                  <div
                    v-else-if="item.started && item.days <= 0"
                    slot="content"
                  >
                    正常辅导中
                  </div>
                  <div v-else-if="item.started === false" slot="content">
                    未开始辅导
                  </div>
                  <div v-else slot="content">
                    学生未交付任务已超{{ item.days }}天
                  </div>
                  <div class="colortag">
                    <div
                      :class="getClass(item.days, item.started, item.posted)"
                    ></div>
                    <div
                      v-if="item.warning !== false && item.posted === false"
                      class="remind"
                    ></div>
                    <div class="days"></div>
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="preWrapper">
            <div class="pre" @click="endturn3 = !endturn3">
              <div class="pretext flex">
                <div class="listTip">已面试</div>
                <div class="wnum">
                  <span class="number">
                    {{ ('000' + mianlist.length.toString()).slice(-3) }}
                  </span>
                </div>
              </div>
              <div
                :class="{ turn: endturn3 }"
                class="preimg el-icon-arrow-down"
              ></div>
            </div>
            <div v-if="endturn3">
              <div
                @click="getstu3(index)"
                :class="{ changeStyle: changeSelectStyle3 === index }"
                class="jstunum"
                v-for="(item, index) in mianlist"
                :key="index"
              >
                <div class="studentname">
                  <span>{{ item.id }}.{{ item.name }}</span>
                  <img
                    src="../../assets/icon/组合项目.png"
                    class="zhimg"
                    alt=""
                    v-if="item.ispacked === true"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="notlist.length + mianlist.length + notfinlist.length == 0"
            style="font-size: 16px; color: #656d78; padding-top: 1em"
          >
            未搜索到相关信息
          </div>
        </div>
      </div>
      <div class="neirong">
        <div class="jtitle">
          <h2 class="jtext">学员进度</h2>
        </div>
        <div v-show="notlist !== 0 || notfinlist !== 0 || view">
          <div v-if="statusCode === 1" :style="jlistheight">
            <projectInfo :project="activeProject">
              <div slot="roles" class="rolesIcon">
                <img src="../../assets/roles/icon/导师.png" />
                <span>{{ activeProject.teacher || '待分配' }}</span>
              </div>
              <div slot="buttons">
                <informationButton :pid="pid"> </informationButton>
                <el-button type="primary" @click="mianOpen">
                  面试反馈
                </el-button>
              </div>
              <el-row class="interview" slot="stages">
                <img src="../../assets/icon/面试.png" />
                <span v-if="!interview"> 暂无面试反馈 </span>
                <span v-else class="viewdate">{{ interview.date }}</span>
                <span v-if="interview">
                  {{ interview.situation }}
                </span>
              </el-row>
            </projectInfo>
          </div>
          <div v-if="statusCode === 2" :style="jlistheight">
            <suggestRead :project="activeProject"></suggestRead>
            <project-info :project="activeProject">
              <div slot="roles" class="rolesIcon">
                <img src="../../assets/roles/icon/导师.png" />
                <span>{{ activeProject.teacher || '待分配' }}</span>
                <img src="../../assets/roles/icon/助教.png" />
                <span>{{ activeProject.assistant || '待分配' }}</span>
              </div>
              <div slot="buttons">
                <informationButton :pid="pid"> </informationButton>
                <file-button :pid="pid"></file-button>
                <el-button type="primary" @click="uploadOpen">
                  上传文件
                </el-button>
                <el-button type="primary" @click="zhuangOpen">
                  添加状况
                </el-button>
                <el-button type="primary" @click="openend">
                  {{ isend ? '已申请结项' : '申请结项' }}
                </el-button>
                <el-button type="primary" @click="missionOpen">
                  添加任务
                </el-button>
                <div class="yuan" @click="tipDialog = true">
                  <div class="wen">?</div>
                </div>
              </div>
              <stages
                :project="activeProject"
                :interview="interview"
                slot="stages"
                :stageCompleteRole="true"
                @refresh="refresh"
              >
                <template slot-scope="item">
                  <el-table
                    :data="tableData[item.index].tasks"
                    :span-method="arraySpanMethod"
                    v-if="
                      tableData[item.index] &&
                        tableData[item.index].tasks.length > 0
                    "
                  >
                    <el-table-column align="center" width="30">
                      <template slot-scope="{ row }">
                        <div class="img">
                          <img
                            src="../../assets/icon/惊叹号.png"
                            v-if="row.type === 'status'"
                          />
                          <img
                            src="../../assets/icon/备注.png"
                            v-else-if="row.type === 'remark'"
                          />
                          <img
                            src="../../assets/icon/结项.png"
                            v-else-if="row.type === 'end'"
                          />
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="date"
                      label="日期"
                      align="center"
                      min-width="12%"
                    >
                      <template slot-scope="{ row }">
                        {{
                          row.type === 'nextMission' ||
                          row.type === 'teacher_evaluate'
                            ? ''
                            : row.date
                        }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="teacher_work"
                      label="导师辅导内容"
                      align="left"
                      header-align="center"
                      min-width="30%"
                    >
                      <template slot-scope="{ row }">
                        <div v-if="row.type === 'status'">
                          {{ row.status }} —— {{ row.creator }}
                        </div>
                        <div v-else-if="row.type === 'nextMission'">
                          预计下次辅导内容：{{ row.mission }}
                        </div>
                        <div v-else-if="row.type === 'remark'">
                          <span v-if="row.first">【学生辅导感受】</span
                          >{{ row.remark }}
                        </div>
                        <div v-else-if="row.type === 'end'">
                          <p
                            v-for="item in row.chosen"
                            :key="item"
                            style="display: inline"
                          >
                            {{ item }}，
                          </p>
                          <br />
                          {{ row.written }}
                        </div>
                        <div v-else-if="row.type === 'task'">
                          <div>{{ row.teacher_work }}</div>
                        </div>
                        <div v-else-if="row.type === 'teacher_evaluate'">
                          <div>【学生评价】:{{ row.teacher_evaluate }}</div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="work_time"
                      label="辅导时间"
                      align="center"
                      min-width="11%"
                      :formatter="timeFormatter"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="student_work"
                      label="学生任务"
                      align="center"
                      min-width="30%"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="deadline"
                      label="Deadline"
                      align="center"
                      min-width="13%"
                    >
                    </el-table-column>
                    <el-table-column
                      label="确认辅导内容"
                      align="center"
                      min-width="10%"
                    >
                      <template slot-scope="scope">
                        <div v-if="scope.row.isconfirm === true">
                          <img class="isok" src="../../assets/icon/isok.png" />
                        </div>
                        <div
                          v-else-if="scope.row.feedbacks === undefined"
                        ></div>
                        <el-popover
                          v-else-if="scope.row.feedbacks.length !== 0"
                          placement="top-start"
                          width="536px"
                          trigger="hover"
                        >
                          <div class="feedwrap">
                            <div
                              class="feedcontainer"
                              v-for="(item, index) in scope.row.feedbacks"
                              :key="index"
                            >
                              <div class="feedleft">{{ item.date }}</div>
                              <div class="feedright">
                                <span class="feeds">【疑问反馈】</span>
                                <p class="feedp">{{ item.content }}</p>
                              </div>
                            </div>
                          </div>
                          <img
                            class="feedimg"
                            slot="reference"
                            src="../../assets/icon/feedbacks.png"
                          />
                        </el-popover>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" min-width="5%"
                      ><template slot-scope="scope">
                        <el-button
                          class="edit listedit"
                          @click="missionEdit(scope.$index, scope.row)"
                          v-if="scope.row.type != 'end'"
                        >
                          <i class="el-icon-edit"></i>
                        </el-button>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" min-width="5%">
                      <template slot-scope="scope">
                        <el-button
                          class="delete listedit"
                          @click="missionDelete(scope.$index, scope.row)"
                          v-if="scope.row.type != 'end'"
                        >
                          <i class="el-icon-delete"></i>
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </stages>
            </project-info>
            <el-dialog
              title="申请结项"
              :visible.sync="endVisible"
              :close-on-click-modal="false"
              @close="closeEnd"
              width="70%"
              custom-class="shenqignjiexiang"
            >
              <div v-if="get_type()">
                <template
                  v-if="
                    get_type().indexOf('科研') > -1 && activeProject.isPacked
                  "
                >
                  <div>
                    <p style="color: red">
                      此项目为组合项目，无科研报告，科研完成进入下一个阶段
                    </p>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <p>上传{{ get_type() }}*</p>
                  </div>
                  <el-upload
                    drag
                    :action="uploadUrl"
                    multiple
                    :on-success="success"
                    ref="upload"
                    :limit="1"
                  >
                    <div class="el-upload__text">
                      将文件拖到此处，或
                      <em style="color: #8dc152; text-decoration: underline">
                        点击上传
                      </em>
                    </div>
                  </el-upload>
                  <div v-if="activeProject.project_type === '论文A' || activeProject.project_type === '论文B' " >
                    <div>
                      <p>答辩文档*</p>
                    </div>
                    <el-upload
                        drag
                        :action="uploadUrl"
                        :on-success="defenseDocument"
                        ref="upload"
                        :limit="1"
                    >
                      <div class="el-upload__text">
                        将文件拖到此处，或
                        <em style="color: #8dc152; text-decoration: underline">
                          点击上传
                        </em>
                      </div>
                    </el-upload>
                  </div>
                  <template v-if="get_type().indexOf('终稿') > -1">
                    <div style="margin-top: 16px">
                      <p>是否需要翻译/润色*</p>
                    </div>
                    <div class="endRadio">
                      <el-radio v-model="modify" label="不需要翻译/润色">
                      </el-radio>
                      <el-radio v-model="modify" label="需要翻译"></el-radio>
                      <el-radio v-model="modify" label="需要润色"></el-radio>
                    </div>
                  </template>
                  <p v-if="modify === '需要翻译'" style="color: red">
                    仅对语言进行翻译，专业名词或专业知识部分翻译后还需导师确认
                  </p>
                  <p v-if="modify === '需要润色'" style="color: red">
                    仅对语法和句法错误进行润色，润色不会涉及到专业表达的调整
                  </p>
                </template>
              </div>
              <div style="margin-top: 16px">
                <p>学生评价（学生不可见）</p>
              </div>
              <el-checkbox-group
                v-model="checkremarks"
                type="primary"
                style="text-align: left"
              >
                <el-checkbox-button
                  v-for="remark1 in remarks1"
                  :label="remark1"
                  :key="remark1"
                  class="remark1"
                  >{{ remark1 }}</el-checkbox-button
                >
                <br />
                <el-checkbox-button
                  v-for="remark2 in remarks2"
                  :label="remark2"
                  :key="remark2"
                  class="remark2"
                  >{{ remark2 }}</el-checkbox-button
                >
              </el-checkbox-group>
              <el-input
                type="textarea"
                v-model="endinput"
                placeholder="评价内容（选填）"
                rows="3"
              ></el-input>
              <div style="margin-top: 16px">
                <p>备注</p>
              </div>
              <el-input
                type="textarea"
                v-model="endmisc"
                placeholder="附件中的特殊情况可备注在此处，无特殊情况则无需填写"
                rows="5"
              ></el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="closeEnd">取 消</el-button>
                <el-button
                  @click="postend"
                  type="primary"
                  :disabled="endpending"
                >
                  确 定
                </el-button>
              </span>
            </el-dialog>
            <el-dialog :visible.sync="tipDialog" width="50%">
              <el-row>
                <el-col class="flex" :span="24">
                  <div class="yuan">
                    <div class="wen">?</div>
                  </div>
                  <div class="addZ">状态说明</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="inadd">
                  <div class="flex">
                    <div class="intext">超期未联系标记</div>
                    <div>
                      <el-tooltip
                        placement="top"
                        content="学生未交付任务已超期"
                        effect="light"
                        popper-class="atooltip"
                      >
                        <div class="tagcolor">
                          <div class="day7"></div>
                          <div class="days"></div>
                        </div>
                      </el-tooltip>
                    </div>
                    <div>
                      <el-tooltip
                        placement="top"
                        content="学生未交付任务已超7天"
                        effect="light"
                        popper-class="atooltip"
                      >
                        <div class="tagcolor">
                          <div class="day15"></div>
                          <div class="days"></div>
                        </div>
                      </el-tooltip>
                    </div>
                    <div>
                      <el-tooltip
                        placement="top"
                        content="学生未交付任务已超15天"
                        effect="light"
                        popper-class="atooltip"
                      >
                        <div class="tagcolor">
                          <div class="day30"></div>
                          <div class="days"></div>
                        </div>
                      </el-tooltip>
                    </div>
                    <div>
                      <el-tooltip
                        placement="top"
                        content="学生未交付任务已超30天"
                        effect="light"
                        popper-class="atooltip"
                      >
                        <div class="tagcolor">
                          <div class="day60"></div>
                          <div class="days"></div>
                        </div>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="intext">添加状况后</div>
                    <div>
                      <el-tooltip
                        placement="top"
                        content="学生未交付任务已超期"
                        effect="light"
                        popper-class="atooltip"
                      >
                        <div class="tagcolor">
                          <div class="day7"></div>
                          <div class="remind"></div>
                          <div class="days"></div>
                        </div>
                      </el-tooltip>
                    </div>
                    <div>
                      <el-tooltip
                        placement="top"
                        content="学生未交付任务已超7天"
                        effect="light"
                        popper-class="atooltip"
                      >
                        <div class="tagcolor">
                          <div class="day15"></div>
                          <div class="remind"></div>
                          <div class="days"></div>
                        </div>
                      </el-tooltip>
                    </div>
                    <div>
                      <el-tooltip
                        placement="top"
                        content="学生未交付任务已超15天"
                        effect="light"
                        popper-class="atooltip"
                      >
                        <div class="tagcolor">
                          <div class="day30"></div>
                          <div class="remind"></div>
                          <div class="days"></div>
                        </div>
                      </el-tooltip>
                    </div>
                    <div>
                      <el-tooltip
                        placement="top"
                        content="学生未交付任务已超30天"
                        effect="light"
                        popper-class="atooltip"
                      >
                        <div class="tagcolor">
                          <div class="day60"></div>
                          <div class="remind"></div>
                          <div class="days"></div>
                        </div>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="intext">投稿中</div>
                    <div>
                      <div class="tagcolor">
                        <div class="posted"></div>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <div slot="footer" class="dialog-footer">
                <el-button @click="tipDialog = false">取 消</el-button>
              </div>
            </el-dialog>

            <el-dialog
              :title="zhuangTitle"
              :visible.sync="zhuangDialog"
              width="60%"
            >
              <div>
                <p>
                  暂停辅导或未能按时和学生进行辅导沟通的原因，如学生考试周等<br />
                  *此条信息学生不可见
                </p>
              </div>
              <el-form :model="ruleforms" :rules="rules" ref="ruleforms">
                <el-form-item prop="state">
                  <el-input
                    type="textarea"
                    class="input"
                    maxlength="500"
                    show-word-limit
                    :autosize="{ minRows: 3, maxRows: 3 }"
                    v-model="ruleforms.state"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
              <div slot="footer">
                <el-button @click="quit('ruleforms')">取 消</el-button>
                <el-button
                  type="primary"
                  @click="pushzhuang('ruleforms')"
                  :disabled="pending"
                >
                  确 定
                </el-button>
              </div>
            </el-dialog>
            <el-dialog
              :title="missionTitle"
              :visible.sync="missionDialog"
              width="75%"
              class="mission"
              :close-on-click-modal="false"
              destroy-on-close
              custom-class="missionScroll"
            >
              <el-form :model="mission" :rules="rules" ref="mission">
                <el-row>
                  <el-col :span="24">
                    <div class="mDtitle" v-if="task_type">
                      学生评价及推荐信素材
                    </div>
                    <div class="mDtitle" v-else>学生评价</div>
                    <div class="itemTip">此条信息学生不可见</div>
                    <el-form-item prop="teacher_evaluate">
                      <el-input
                        v-if="task_type"
                        type="textarea"
                        class="input"
                        maxlength="500"
                        show-word-limit
                        :autosize="{ minRows: 4, maxRows: 4 }"
                        placeholder="导师在本次辅导中对于学生表现的主观评价，或者学生的突出表现
此部分内容将用于推荐信整理，请尽可能包含具体细节或专有名词"
                        v-model="mission.teacher_evaluate"
                      >
                      </el-input>
                      <el-input
                        v-else
                        type="textarea"
                        class="input"
                        maxlength="500"
                        show-word-limit
                        :autosize="{ minRows: 4, maxRows: 4 }"
                        placeholder="导师在本次辅导中对于学生表现的直观评价"
                        v-model="mission.teacher_evaluate"
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <div class="mDtitle">学员学习态度及沟通积极性*</div>
                    <div class="itemTip">此条信息学生不可见</div>
                  </el-col>
                </el-row>
                <el-row class="mb10">
                  <el-col :span="24">
                    <el-form-item prop="bearing">
                      <el-radio-group
                        class="radiogroup"
                        v-model="mission.bearing"
                      >
                        <el-radio class="wid100" :label="`出色`"
                          >出色，可以清晰准确地提出自己的观点或问题</el-radio
                        >
                        <el-radio class="wid100" :label="`良好`"
                          >良好，可以完成基本辅导内容的学习</el-radio
                        >
                        <el-radio class="wid100" :label="`较差`"
                          >较差，无明显互动或反馈</el-radio
                        >
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <div class="mDtitle">学员上次任务完成质量*</div>
                    <div class="itemTip">此条信息学生不可见</div>
                  </el-col>
                </el-row>
                <el-row class="mb10">
                  <el-col :span="24">
                    <el-form-item prop="quality">
                      <el-radio-group
                        class="radiogroup"
                        v-model="mission.quality"
                      >
                        <el-radio class="wid100s" :label="`出色`"
                          >出色，超出预期地完成全部任务</el-radio
                        >
                        <el-radio class="wid100s" :label="`良好`"
                          >良好，完成任务质量80%以上</el-radio
                        >
                        <el-radio class="wid100s" :label="`一般`"
                          >一般，完成任务质量60%左右</el-radio
                        >
                        <el-radio class="wid100s" :label="`较差`"
                          >较差，完成任务质量40%或以下</el-radio
                        >
                        <el-radio class="wid100s" :label="`无`"
                          >未布置任务</el-radio
                        >
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row class="mb10">
                  <el-col :span="17">
                    <div class="mDtitle">导师辅导内容*</div>
                    <el-form-item prop="teacher_work">
                      <el-input
                        type="textarea"
                        class="input"
                        maxlength="500"
                        show-word-limit
                        :autosize="{ minRows: 4, maxRows: 4 }"
                        placeholder="导师在本次辅导中主要讲解的知识点或其他主要工作内容"
                        v-model="mission.teacher_work"
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7" class="flexc">
                    <div class="mDtitle">工作时长*</div>
                    <div class="itemTip">此条信息学生不可见</div>
                    <el-select
                      class="selectfix"
                      v-model="timevalue"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <div class="itemTip">
                      本次辅导中导师与学生的沟通时长及准备时长
                    </div>
                  </el-col>
                </el-row>
                <el-row class="mb10">
                  <el-col :span="17" v-if="mission.islast">
                    <div class="mDtitle">学生任务</div>
                    <el-form-item prop="student_work">
                      <el-input
                        type="textarea"
                        class="input"
                        maxlength="500"
                        show-word-limit
                        :autosize="{ minRows: 4, maxRows: 4 }"
                        placeholder="学员下次应交付给导师的所有工作任务内容"
                        v-model="mission.student_work"
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="17" v-else>
                    <div class="mDtitle">学生任务*</div>
                    <el-form-item
                      prop="student_work"
                      :rules="{ required: true, message: '请填写学生任务', trigger: 'blur' },"
                    >
                      <el-input
                        type="textarea"
                        class="input"
                        maxlength="500"
                        show-word-limit
                        :autosize="{ minRows: 4, maxRows: 4 }"
                        placeholder="学员下次应交付给导师的所有工作任务内容"
                        v-model="mission.student_work"
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7" class="flexc">
                    <div class="mDtitle">Deadline*</div>
                    <el-form-item
                      prop="deadline"
                      :rules="[
                        {
                          validator: deadlineRule,
                          trigger: 'blur'
                        },
                        {
                          validator: deadlineRule,
                          trigger: 'change'
                        }
                      ]"
                    >
                      <el-date-picker
                        class="selectDate"
                        v-model="mission.deadline"
                        type="date"
                        placeholder="选择日期"
                        :picker-options="pickerOptions1"
                        value-format="yyyy-MM-dd"
                        popper-class="tscheduleDatePicker"
                        :editable="false"
                        :clearable="true"
                        :disabled="mission.islast"
                        style="width: 100%"
                      >
                      </el-date-picker>
                      <el-checkbox v-model="mission.islast" class="last">
                        此次为最后一次辅导
                      </el-checkbox>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <div class="mDtitle">预计下次辅导内容</div>
                    <el-form-item prop="next_mission">
                      <el-input
                        type="textarea"
                        class="input"
                        maxlength="500"
                        show-word-limit
                        :autosize="{ minRows: 4, maxRows: 4 }"
                        placeholder="此部分内容为非必填项，主要为了学生提前预习或者更加有目的性的完成本次任务"
                        v-model="mission.next_mission"
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
              <div slot="footer">
                <el-button @click="quit('mission')">取 消</el-button>
                <el-button
                  type="primary"
                  @click="pushMission('mission')"
                  :disabled="pending || !missionDialog"
                >
                  确 定
                </el-button>
              </div>
            </el-dialog>
          </div>
          <div v-if="statusCode === 3" :style="jlistheight">
            <projectInfo :project="activeProject">
              <div slot="roles" class="rolesIcon">
                <img src="../../assets/roles/icon/导师.png" />
                <span>{{ activeProject.teacher || '待分配' }}</span>
              </div>
              <div slot="buttons">
                <informationButton :pid="pid"> </informationButton>
              </div>
              <el-row class="interview" slot="stages">
                <img src="../../assets/icon/面试.png" />
                <span v-if="!interview"> 暂无面试反馈 </span>
                <span v-else class="viewdate">{{ interview.date }}</span>
                <span v-if="interview">
                  {{ interview.situation }}
                </span>
                <el-button class="listedit" @click="mianOpen">
                  <i class="el-icon-edit"></i>
                </el-button>
                <el-button class="listedit" @click="deleteMian">
                  <i class="el-icon-delete"></i>
                </el-button>
              </el-row>
            </projectInfo>
          </div>
        </div>
      </div>
    </div>
    <interviewDialog
      ref="interviewDialog"
      :interviewPro="interview"
      :pid="pid"
      @refresh="refresh"
    />
    <uploadForm
      api="teacher"
      ref="uploadForm"
      :closeUploadForm.sync="uploadFormActive"
      v-if="uploadFormActive"
    />
  </el-main>
</template>

<script>
/* eslint-disable no-console */
import axios from '../axios'
import projectInfo from '../pub/projectInfo'
import uploadForm from '../pub/uploadForm'
import informationButton from '../pub/buttons/informationButton'
import moment from 'moment'
import { MessageBox } from 'element-ui'
import _ from 'lodash'
import ProjectInfo from '../pub/projectInfo.vue'
import fileButton from '../pub/buttons/fileButton'
import statusButton from '../pub/buttons/statusButton'
import stages from './stages'
import suggestRead from '../pub/suggestRead'
import stageType from '../pub/stageType.json'
import stagesList from './stagesList.json'
import interviewDialog from './interviewDialog.vue'

export default {
  name: 'tschedule',
  components: {
    projectInfo,
    uploadForm,
    informationButton,
    ProjectInfo,
    fileButton,
    statusButton,
    stages,
    suggestRead,
    interviewDialog
  },
  data () {
    return {
      uploadUrl: `${axios.defaults.baseURL}/upload/`,
      remarks1: [
        '思维敏捷',
        '分析能力强',
        '沟通顺畅',
        '执行能力强',
        '基础扎实',
        '态度端正',
        '思路清晰',
        '时间观念强'
      ],
      remarks2: [
        '不愿与老师沟通',
        '不写作业',
        '需经常催促',
        '撒谎',
        '联系不上学生'
      ],
      uploadFormActive: false,
      focus: 0,
      smes: {
        name: '',
        school: '',
        cellphone: '',
        email: '',
        direction: '',
        sex: ''
      },
      tableData: [],
      rules: {
        state: [{ required: true, message: '请填写状况信息', trigger: 'blur' }],
        teacher_work: [
          { required: true, message: '请填写导师任务', trigger: 'blur' }
        ],
        bearing: [
          { required: true, message: '请选择完成态度', trigger: 'change' }
        ],
        quality: [
          { required: true, message: '请选择任务质量', trigger: 'change' }
        ]
      },
      ruleforms: {
        state: ''
      },
      statusCode: 0,
      colors: {
        2: '#A0D469',
        4: { value: '#A0D469', excluded: true },
        5: '#A0D469'
      },
      star: 0,
      changeSelectStyle: 0,
      changeSelectStyle3: 0,
      changeSelectStyle2: 0,
      notlist: [],
      notfinlist: [],
      mianlist: [],
      stutitle: '',
      endVisible: false,
      height: {
        height: ''
      },
      jlistheight: {
        height: ''
      },
      preturn1: true,
      midturn2: true,
      endturn3: false,
      zhuangDialog: false,
      tipDialog: false,
      missionDialog: false,
      zhuangTitle: '',
      uploadTitle: '',
      missionTitle: '',
      fileTitle: '查看文件',
      checkremarks: [],
      mission: {
        teacher_work: '',
        student_work: '',
        next_mission: '',
        bearing: '',
        quality: '',
        teacher_evaluate: '',
        deadline: '',
        islast: false
      },
      endinput: '',
      endmisc: '',
      timevalue: '',
      project_type: '',
      teacher_name: '',
      options: [
        {
          value: 1,
          label: '15min'
        },
        {
          value: 2,
          label: '30min'
        },
        {
          value: 3,
          label: '45min'
        },
        {
          value: 4,
          label: '1h'
        },
        {
          value: 6,
          label: '1.5h'
        },
        {
          value: 8,
          label: '2h'
        }
      ],
      activeProject: {},
      pickerOptions1: {
        disabledDate (time) {
          const curDate = new Date().getTime()
          const two = 13 * 24 * 3600 * 1000
          const one = 24 * 3600 * 1000
          const now = curDate - one
          const twoweek = curDate + two
          return time.getTime() <= now || time.getTime() >= twoweek
        }
      },
      status_number: 0,
      month: '',
      strDate: '',
      pid: 0,
      mianid: '',
      task_id: 0,
      status_id: 0,
      nextMission_id: 0,
      interview: undefined,
      now: '',
      view: false,
      pending: false,
      tname: '',
      sname: '',
      task_type: false,
      isend: false,
      modify: '',
      filekey: '',
      defenseDocumentFile: '',
      searches: ['学员', '项目'],
      role: '学员',
      endpending: false
    }
  },
  created () {
    this.listhh()
    this.inhh()
    this.getinfo()
    this.getstudent()
  },
  computed: {
    stages () {
      return stagesList[stageType[this.activeProject.project_type] || '基础']
    }
  },
  methods: {
    rolechange () {
      this.stutitle = ''
      this.getstudent()
    },
    success (res) {
      this.filekey = res.key
    },
    defenseDocument (res) {
      this.defenseDocumentFile = res.key
    },
    get_type () {
      if (
        this.$store.state.baseType[this.activeProject.project_type] === '论文'
      ) {
        return '论文终稿'
      } else if (
        this.$store.state.baseType[this.activeProject.project_type] ===
        '学术指导'
      ) {
        return '论文终稿'
      } else if (
        this.$store.state.baseType[this.activeProject.project_type] === 'EPQ'
      ) {
        return '论文终稿'
      } else if (
        this.$store.state.baseType[this.activeProject.project_type] === '科研'
      ) {
        return '科研报告'
      } else if (
        this.$store.state.baseType[this.activeProject.project_type] === 'RP'
      ) {
        return 'RP终稿'
      } else if (
        this.$store.state.baseType[this.activeProject.project_type] === 'IAP'
      ) {
        return '论文终稿'
      } else {
        return false
      }
    },
    formatDate (data) {
      return moment(data * 1000).format('YYYY-MM-DD')
    },
    formatTime (data) {
      return moment(data * 1000).format('HH:mm')
    },
    closeEnd () {
      this.endVisible = false
      this.endinput = ''
      this.endmisc = ''
      this.checkremarks = []
      this.filekey = ''
      this.defenseDocumentFile = ''
      this.modify = ''
      this.$refs.upload.clearFiles()
    },
    openend () {
      if (this.isend) {
        MessageBox.confirm(
          '已成功申请结项，是否取消当前申请？（取消后仍可再次申请）'
        ).then(() => {
          axios.delete(`/teacher/project/${this.pid}/endProject`).then(() => {
            this.getstu2(this.focus)
            this.$message({
              type: 'success',
              message: '取消成功'
            })
          })
        })
      } else {
        this.tableData.forEach(e => {
          if (e.type === 'end') {
            this.endinput = e.written
            this.checkremarks = e.chosen
          }
        })
        this.endVisible = true
      }
    },
    postend () {
      this.endpending = true
      if (this.activeProject.project_type === '论文A' || this.activeProject.project_type === '论文B') {
        if (this.defenseDocumentFile === '') {
          this.$message({
            message: `请上传答辩文档`,
            type: 'warning'
          })
          this.endpending = false
          return
        }
      }
      if (
        this.get_type() &&
        !(this.get_type().indexOf('科研') > -1 && this.activeProject.isPacked)
      ) {
        if (this.filekey === '') {
          this.$message({
            message: `请上传文件`,
            type: 'warning'
          })
          this.endpending = false
          return
        }
        if (this.get_type().indexOf('终稿') > -1 && this.modify === '') {
          this.$message({
            message: `选择是否需要翻译/润色`,
            type: 'warning'
          })
          this.endpending = false
          return
        }
      }
      if (this.endinput === '' && this.checkremarks.length === 0) {
        this.$message({
          message: `请完成学生评价`,
          type: 'warning'
        })
        this.endpending = false
        return
      }
      axios
        .post(`/teacher/project/${this.pid}/endProject`, {
          chosen: this.checkremarks,
          written: this.endinput,
          endmisc: this.endmisc,
          uploadFile: [
            {
              file_type: this.get_type(),
              filekey: this.filekey,
              modify: this.modify
            },
            ...(this.defenseDocumentFile ? [
              {
                file_type: '答辩文档',
                filekey: this.defenseDocumentFile,
                modify: this.modify
              }
            ] : [])
          ]
        })
        .then(res => {
          if (res.status === 201 || res.status === 200) {
            this.$message({
              message: `申请结项成功`,
              type: 'success'
            })
            this.getstu2(this.focus)
            this.endVisible = false
            this.endpending = false
          }
        })
    },
    deadlineRule (rule, value, callback) {
      if (this.mission.islast || this.mission.deadline !== '') {
        callback()
      }
      callback(new Error('请输入Deadline或者勾选最后一次任务'))
    },
    getNow () {
      const date = new Date()
      const seperator1 = '-'
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        this.month = '0' + month
      } else {
        this.month = month
      }
      if (strDate >= 0 && strDate <= 9) {
        this.strDate = '0' + strDate
      } else {
        this.strDate = strDate
      }
      const currentdate =
        year + seperator1 + this.month + seperator1 + this.strDate
      this.now = currentdate
    },
    openUploadForm () {
      this.$nextTick(() => {
        let info = {
          project_type: this.project_type,
          tname: this.tname,
          sname: this.smes.name,
          uploadTitle: '上传文件'
        }
        this.$refs.uploadForm.openDialog(this.activeProject.id, info)
      })
    },
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      const item = row
      const status = item.status
      const nextmission = item.mission
      const teacher_evaluate = item.teacher_evaluate
      const teacher_work = item.teacher_work
      const written = item.written
      const end = item.chosen || item.written
      if (!!teacher_work && columnIndex === 2) return [1, 1]
      if (!!teacher_work && columnIndex >= 3 && columnIndex <= 8) return [1, 1]
      if (!!written && columnIndex === 2) return [1, 6]
      if (!!nextmission && columnIndex === 2) return [1, 6]
      if (!!status && columnIndex === 2) return [1, 5]
      if (!!end && columnIndex === 2) return [1, 7]
      if (!!teacher_evaluate && columnIndex === 2) return [1, 6]
      if (!!written && columnIndex >= 3 && columnIndex <= 8) return [0, 0]
      if (!!nextmission && columnIndex >= 3 && columnIndex <= 8) return [0, 0]
      if (!!status && columnIndex >= 3 && columnIndex <= 6) return [0, 0]
      if (!!teacher_evaluate && columnIndex >= 3 && columnIndex <= 8) {
        return [0, 0]
      }

      if (!!end && columnIndex >= 3 && columnIndex <= 8) return [0, 0]
    },
    search: _.debounce(function () {
      this.getstudent()
    }, 500),
    getinfo () {
      axios
        .get('/teacher/')
        .then(response => {
          this.star = response.data.star
          this.tname = response.data.name
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getstudent () {
      await Promise.all([
        axios
          .get('/teacher/interviewed', {
            params: {
              s_name: this.stutitle,
              type: this.role
            }
          })
          .then(response => {
            this.mianlist = response.data.students
          })
          .catch(error => {
            console.log(error)
          }),
        axios
          .get('/teacher/not_finished', {
            params: {
              s_name: this.stutitle,
              type: this.role
            }
          })
          .then(response => {
            this.notfinlist = response.data.students
          })
          .catch(error => {
            console.log(error)
          }),
        axios
          .get('/teacher/not_interviewed', {
            params: {
              s_name: this.stutitle,
              type: this.role
            }
          })
          .then(response => {
            this.notlist = response.data.students
          })
          .catch(error => {
            console.log(error)
          })
      ])
      if (this.notlist.length !== 0) {
        this.getfirst()
      } else if (this.notfinlist.length !== 0) {
        this.getsecond()
      }
    },
    timeFormatter (row) {
      const ttime = row.work_time
      if (ttime) {
        if (ttime > 3) return `${ttime / 4}h`
        else return `${ttime * 15}min`
      }
      return ''
    },
    zhuangOpen () {
      this.zhuangTitle = '添加状况'
      this.zhuangDialog = true
      this.ruleforms.state = ''
    },
    uploadOpen () {
      this.uploadFormActive = true
      this.openUploadForm()
    },
    missionOpen () {
      this.missionTitle = '添加任务'
      this.missionDialog = true
      this.mission = {
        teacher_work: '',
        student_work: '',
        next_mission: '',
        bearing: '',
        quality: '',
        teacher_evaluate: '',
        deadline: '',
        islast: false
      }
      this.timevalue = 2
    },
    missionEdit (index, row) {
      if (row.type === 'task') {
        this.missionTitle = '修改任务'
        this.missionDialog = true
        this.mission = JSON.parse(JSON.stringify(row))
        this.mission.deadline = row.deadline
        this.mission.islast = row.islast
        this.timevalue = row.work_time
        this.task_id = row.id
        if (this.tableData[index + 1].mission) {
          this.$set(this.mission, 'mission', this.tableData[index + 1].mission)
          this.nextMission_id = this.tableData[index + 1].id
        }
      } else if (row.type === 'status') {
        this.ruleforms.state = row.status
        this.status_id = row.id
        this.zhuangDialog = true
        this.zhuangTitle = '修改状况'
      } else if (row.type === 'nextMission') {
        this.missionTitle = '修改任务'
        this.missionDialog = true
        this.mission = this.tableData[index - 1]
        this.timevalue = this.tableData[index - 1].work_time
        this.task_id = this.tableData[index - 1].id
        this.nextMission_id = row.id
        this.$set(this.mission, 'mission', row.mission)
      }
    },
    deleteMian () {
      MessageBox.confirm('是否删除此条面试信息')
        .then(() => {
          axios
            .delete(`/teacher/interview/${this.mianid}`)
            .then(response => {
              if (response.status === 200) {
                this.$message({
                  showClose: true,
                  message: '已删除',
                  type: 'success'
                })
                setTimeout(() => {
                  window.location.reload()
                }, 1000)
              }
            })
            .catch(error => {
              console.log(error)
            })
        })
        .catch(error => {
          console.log(error)
        })
    },
    fixitem () {
      let routeUrl = this.$router.resolve({
        path: '/projectinfo',
        query: {
          role: 'teacher',
          pid: this.activeProject.id
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    getstut () {
      axios
        .get(`/teacher/project/${this.pid}/`)
        .then(response => {
          this.smes = response.data.student
          this.teacher = response.data.teacher.name
          this.project_type = response.data.project.project_type
          this.interview = response.data.interview[0]
          this.task_type = response.data.task_type
          this.isend = response.data.isend
          this.pid = response.data.project.id
          this.sid = response.data.student.id
          this.tableData = response.data.stages
          this.loading = false
          this.activeProject = {
            ...response.data.project,
            salesman: response.data.sales,
            sponser: response.data.support
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    pushzhuang (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.getNow()
          if (this.zhuangTitle === '添加状况') {
            MessageBox.confirm('是否添加状况').then(() => {
              this.pending = true
              axios
                .post(`/teacher/project/${this.pid}/status`, {
                  project_id: this.pid,
                  status: this.ruleforms.state,
                  date: this.now
                })
                .then(response => {
                  if (response.status === 201) {
                    this.$message({
                      showClose: true,
                      message: '添加成功',
                      type: 'success'
                    })
                    this.getstut()
                    this.zhuangDialog = false
                    this.pending = false
                  } else {
                    this.pending = false
                  }
                })
                .catch(error => {
                  console.log(error)
                })
            })
          } else {
            MessageBox.confirm('是否更改状况').then(() => {
              this.pending = true
              axios
                .put(`/teacher/status/${this.status_id}`, {
                  status: this.ruleforms.state
                })
                .then(response => {
                  if (response.status === 200) {
                    this.$message({
                      showClose: true,
                      message: '更改成功',
                      type: 'success'
                    })
                    axios
                      .get(`/teacher/project/${this.pid}/`)
                      .then(response => {
                        this.tableData = response.data.stages
                      })
                      .catch(error => {
                        console.log(error)
                      })
                    this.zhuangDialog = false
                  }
                  this.pending = false
                })
                .catch(error => {
                  console.log(error)
                })
            })
          }
        } else {
          return false
        }
      })
    },
    pushMission (formName) {
      const that = this
      this.$refs[formName].validate(async (valid, prop) => {
        if (valid) {
          this.pending = true
          if (this.missionTitle === '添加任务') {
            await axios
              .post(`/teacher/project/${this.pid}/task`, {
                teacher_work: this.mission.teacher_work,
                student_work: this.mission.student_work,
                work_time: this.timevalue,
                deadline: this.mission.deadline,
                islast: this.mission.islast,
                attitude: this.mission.bearing,
                quality: this.mission.quality,
                next_mission: this.mission.next_mission,
                teacher_evaluate: this.mission.teacher_evaluate,
                stageLength: this.stages.length
              })
              .then(response => {
                if (response.status === 201) {
                  this.$message({
                    showClose: true,
                    message: '添加成功',
                    type: 'success'
                  })
                  this.missionDialog = false
                  this.pending = false
                  this.getstut()
                }
                this.pending = false
              })
              .catch(error => {
                this.pending = false
                console.log(error)
              })
          } else {
            await axios
              .put(`/teacher/task/${this.task_id}`, {
                teacher_work: this.mission.teacher_work,
                student_work: this.mission.student_work,
                work_time: this.timevalue,
                deadline: this.mission.deadline,
                attitude: this.mission.bearing,
                quality: this.mission.quality,
                next_mission: this.mission.next_mission,
                teacher_evaluate: this.mission.teacher_evaluate
              })
              .then(response => {
                if (response.status === 200) {
                  this.$message({
                    showClose: true,
                    message: '修改成功',
                    type: 'success'
                  })
                  this.missionDialog = false
                  this.pending = true
                  axios
                    .get(`/teacher/project/${this.pid}/`)
                    .then(response => {
                      this.tableData = response.data.stages
                    })
                    .catch(error => {
                      console.log(error)
                    })
                }
                this.pending = false
              })
              .catch(error => {
                this.pending = false
                console.log(error)
              })
            this.missionDialog = false
          }
          this.mission.islast = false
          this.mission.deadline = ''
          this.$refs[formName].resetFields()
        } else {
          const base = that.$refs.mission.$el.getBoundingClientRect().top
          for (const validElement of that.$refs.mission.fields) {
            const isError =
              validElement.$el._prevClass.indexOf('is-error') !== -1
            const isSuccess =
              validElement.$el._prevClass.indexOf('is-success') !== -1
            const isRequire =
              validElement.$el._prevClass.indexOf('is-require') !== -1
            if ((isRequire && !isError && !isSuccess) || isError) {
              console.log(base, validElement.$el.getBoundingClientRect().top)
              document.getElementsByClassName('missionScroll')[0].scrollTop =
                validElement.$el.getBoundingClientRect().top - base + 40
              break
            }
          }
          return false
        }
      })
    },
    quit (formName) {
      this.$refs[formName].resetFields()
      this.zhuangDialog = false
      this.mission.islast = false
      this.mission.deadline = ''
      this.missionDialog = false
    },
    getClass (days, started, posted) {
      if (posted) {
        return 'posted'
      } else if (days > 30) {
        return 'day60'
      } else if (days > 15 && days <= 30) {
        return 'day30'
      } else if (days > 7 && days <= 15) {
        return 'day15'
      } else if (days > 0 && days <= 7) {
        return 'day7'
      } else if (days === 0 && started === true) {
        return 'day0'
      } else if (days === 0 && started === false) {
        return 'dayfull'
      }
    },
    getfirst () {
      this.changeSelectStyle = 0
      this.changeSelectStyle2 = null
      this.changeSelectStyle3 = null
      this.statusCode = 1
      axios
        .get(`/teacher/project/${this.notlist[0].project_id}/`)
        .then(response => {
          this.smes = response.data.student
          this.project_type = response.data.project.project_type
          this.pid = response.data.project.id
          this.activeProject = {
            ...response.data.project,
            salesman: response.data.salesman,
            sponser: response.data.support
          }
          this.mianid = response.data.interview[0].id
        })
        .catch(error => {
          console.log(error)
        })
    },
    getstu1 (index) {
      this.changeSelectStyle = index
      this.changeSelectStyle2 = null
      this.changeSelectStyle3 = null
      this.statusCode = 1
      axios
        .get(`/teacher/project/${this.notlist[index].project_id}/`)
        .then(response => {
          this.smes = response.data.student
          this.project_type = response.data.project.project_type
          this.pid = response.data.project.id
          this.interview = undefined
          this.mianid = ''
          this.activeProject = {
            ...response.data.project,
            salesman: response.data.salesman,
            sponser: response.data.support
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    getsecond () {
      this.changeSelectStyle = null
      this.changeSelectStyle3 = null
      this.statusCode = 2
      axios
        .get(
          `/teacher/project/${
            this.notfinlist[this.changeSelectStyle2].project_id
          }/`
        )
        .then(response => {
          this.smes = response.data.student
          this.project_type = response.data.project.project_type
          this.pid = response.data.project.id
          this.interview = response.data.interview[0]
          this.task_type = response.data.task_type
          this.isend = response.data.isend
          this.activeProject = {
            ...response.data.project,
            salesman: response.data.salesman,
            sponser: response.data.support
          }
          if (this.interview) {
            this.mianid = response.data.interview[0].id
          }
          this.tableData = response.data.stages
        })
        .catch(error => {
          console.log(error)
        })
    },
    getstu2 (index) {
      this.focus = index
      this.changeSelectStyle2 = index
      this.changeSelectStyle = null
      this.changeSelectStyle3 = null
      this.statusCode = 2
      axios
        .get(`/teacher/project/${this.notfinlist[index].project_id}/`)
        .then(response => {
          this.smes = response.data.student
          this.project_type = response.data.project.project_type
          this.pid = response.data.project.id
          this.interview = response.data.interview[0]
          this.task_type = response.data.task_type
          this.isend = response.data.isend
          this.activeProject = {
            ...response.data.project,
            salesman: response.data.sales,
            sponser: response.data.support
          }
          if (this.interview) {
            this.mianid = response.data.interview[0].id
          }
          this.tableData = response.data.stages
        })
        .catch(error => {
          console.log(error)
        })
    },
    getstu3 (index) {
      this.view = true
      this.changeSelectStyle3 = index
      this.changeSelectStyle = null
      this.changeSelectStyle2 = null
      this.statusCode = 3
      axios
        .get(`/teacher/project/${this.mianlist[index].project_id}/`)
        .then(response => {
          this.smes = response.data.student
          this.project_type = response.data.project.project_type
          this.pid = response.data.project.id
          this.interview = response.data.interview[0]
          this.task_type = response.data.task_type
          this.isend = response.data.isend
          this.mianid = response.data.interview[0].id
          this.activeProject = {
            ...response.data.project,
            salesman: response.data.sales,
            sponser: response.data.support
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    missionDelete (index, row) {
      if (row.type === 'task') {
        this.task_id = row.id
        MessageBox.confirm('是否删除该任务？').then(() => {
          if (
            index + 1 < this.tableData.length &&
            this.tableData[index + 1].type === 'nextMission'
          ) {
            this.nextMission_id = this.tableData[index + 1].id
            axios
              .delete(`/teacher/next_mission/${this.nextMission_id}`)
              .then(response => {
                if (response.status === 200) {
                  this.status_number = response.status
                } else {
                  this.status_number = ''
                  this.$message({
                    showClose: true,
                    message: '删除失败',
                    type: 'error'
                  })
                }
              })
              .catch(error => {
                console.log(error)
              })
          }
          axios
            .delete(`/teacher/task/${this.task_id}`)
            .then(response => {
              if (this.status_number !== '' && response.status === 200) {
                this.$message({
                  showClose: true,
                  message: '删除成功',
                  type: 'success'
                })
                axios
                  .get(`/teacher/project/${this.pid}/`)
                  .then(response => {
                    this.tableData = response.data.stages
                    this.teacher_name = response.data.teacher
                    this.sname = response.data.student.name
                  })
                  .catch(error => {
                    console.log(error)
                  })
              } else {
                this.$message({
                  showClose: true,
                  message: '删除失败',
                  type: 'error'
                })
              }
            })
            .catch(error => {
              console.log(error)
            })
        })
      } else if (row.type === 'nextMission') {
        this.nextMission_id = row.id
        MessageBox.confirm('是否删除此条任务信息').then(() => {
          axios
            .delete(`/teacher/next_mission/${this.nextMission_id}`)
            .then(response => {
              if (response.status === 200) {
                this.$message({
                  showClose: true,
                  message: '删除成功',
                  type: 'success'
                })
                axios
                  .get(`/teacher/project/${this.pid}/`)
                  .then(response => {
                    this.tableData = response.data.stages
                  })
                  .catch(error => {
                    console.log(error)
                  })
              }
            })
            .catch(error => {
              console.log(error)
            })
        })
      } else if (row.type === 'status') {
        this.status_id = row.id
        MessageBox.confirm('是否删除此条状况信息')
          .then(() => {
            axios
              .delete(`/teacher/status/${this.status_id}`)
              .then(response => {
                if (response.status === 200) {
                  this.$message({
                    showClose: true,
                    message: '删除成功',
                    type: 'success'
                  })
                  setTimeout(() => {
                    window.location.reload()
                  }, 1000)
                }
              })
              .catch(error => {
                console.log(error)
              })
          })
          .catch(() => {
            console.log('ettoo')
          })
      }
    },
    listhh () {
      this.height.height = window.innerHeight - 110 + 'px'
    },
    inhh () {
      this.jlistheight.height = window.innerHeight - 190 + 'px'
    },
    mianOpen () {
      this.getNow()
      // 顺道把时间传进去
      this.$refs.interviewDialog.openDialog(this.now)
    },
    refresh () {
      // 面试反馈子组件回调 刷新页面
      this.getstut()
    }
  },
  watch: {
    'mission.islast' () {
      if (this.mission.islast) {
        this.mission.deadline = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.isok {
  width: 25px;
  height: 25px;
}
.feedbs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #f4f6f9;
}
.feedbsp {
  margin-left: 60px;
}
.feedbssp {
  margin-right: 20px;
  flex: 1;
  text-align: center;
}
.feedp {
  margin-top: 0;
  width: 300px;
  padding-left: 7px;
}
.feedcontainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: cneter;
}
.feedleft {
  width: 100px;
}
.feedright {
  float: left;
}
.feedimg {
  width: 25px;
  height: 25px;
}
.flexc {
  padding-left: 20px;
}
.last {
  margin-top: 45px;
}
.yuan {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #a0d469;
  color: #fff;
  position: relative;
  font-weight: 700;
  margin-left: 10px;
  .wen {
    position: absolute;
    top: 2.5px;
    left: 8px;
    color: #fff;
  }
}
.antitxtbutton,
.txtbutton {
  font-size: 18px;
  border: 1px solid #a0d469;
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 5px;
  color: #a0d469;
}
.intxt {
  margin-right: 5px;
  padding-top: 1px;
}
.days:hover {
  width: 80px;
  transition: width 0.6s;
  border-radius: 15px;
  height: 15px;
  transition-timing-function: ease-out;
}
.dayfull {
  background-color: #c0c0c0;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}
.day0 {
  background-color: #a0d469;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}
.day7 {
  background-color: #ffce54;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}
.day15 {
  background-color: #fc6e51;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}
.day30 {
  background-color: #ac92ec;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}
.day60 {
  background-color: #656d78;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}
.posted {
  background-image: url(../../assets/icon/投稿中icon.png);
  background-size: cover;
  width: 13px;
  height: 13px;
  margin-top: 0px;
  border-radius: 50%;
}
.remind {
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  height: 5.5px;
  width: 5.5px;
  position: absolute;
  top: 1.5px;
  left: 7px;
}
.inadd {
  margin-top: 24px;
  font-size: 18px;
  color: #656d78;
  text-align: left;
  padding-left: 45px;
  padding-right: 45px;
  line-height: 30px;
}
.tagcolor {
  margin-top: 7px;
  margin-right: 50px;
  position: relative;
}
.intext {
  width: 150px;
}
.antitxtbutton {
  color: #fff;
  background-color: #a0d469;
}
.tagcolorfix {
  margin-top: 8px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}
.wnum {
  border-radius: 10px;
  font-size: 12px;
  width: 34px;
  height: 20px;
  background-color: #434a54;
  line-height: 20px;
  margin-left: 6px;
  margin-top: -1px;
  text-align: center;
  color: #fff;
}
.itemTip {
  color: #c0c0c0;
}

/deep/.el-checkbox-button__inner {
  width: 90px;
  height: 30px;
  padding: 9px 0px;
  margin: 0px 5px 6px 0px;
  border: 1px solid #dcdfe6;
  border-radius: 0 !important ;
}
/deep/.remark2.el-checkbox-button.is-checked .el-checkbox-button__inner {
  color: #ffffff;
  background-color: #ea4848;
  border-color: #ea4848;
  box-shadow: -1px 0 0 0 #c6e5a5;
}
.mDtitle {
  color: #434a54;
}
.upbutton {
  position: absolute;
  left: 950px;
  top: 20px;
}
.mission /deep/.el-dialog {
  margin-top: 5vh !important;
  overflow: scroll;
  height: 88%;
}
/deep/.el-input__icon {
  width: 15px;
  line-height: 30px;
}
/deep/.el-upload {
  width: 100%;
  .el-upload-dragger {
    background-color: inherit;
    border-width: 2px;
    height: 150px;
  }
  .el-upload__text {
    margin: 65px;
  }
}
.endRadio {
  text-align: left;
  .el-radio {
    color: #656d78;
  }
}
/**/
.dialogTitle {
  justify-content: space-between;
  flex-direction: row;
  font-size: 20px;
  line-height: 20px;
  color: #656d78;
}
/deep/ .axesx {
  padding-top: 10px !important;
}
.img img {
  height: 20px;
  vertical-align: top;
}
/deep/.el-button span {
  padding: 0;
}
/deep/.el-textarea__inner {
  min-height: 100px !important;
}
</style>
