<template>
  <div class="aside">
    <changerole></changerole>
    <el-menu router @select="handleSelect">
      <el-menu-item
        :class="$route.path == '/S/newmission' ? 'is-active' : ''"
        index="/S/newmission"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">最新<br />任务</div>
          <img class="iconmenu" src="../../assets/icon/学员进度@3x.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/S/ku' ? 'is-active' : ''"
        index="/S/ku"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">智库</div>
          <img class="iconmenu" src="../../assets/icon/智库.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/S/smodify' ? 'is-active' : ''"
        index="/S/smodify"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">修改<br />信息</div>
          <img class="iconmenu" src="../../assets/icon/账户管理@3x.png" />
        </el-tooltip>
      </el-menu-item>
    </el-menu>
    <div class="back">
      <img
        @click="logout"
        src="../../assets/icon/退出.png"
        height="20"
        width="20"
      />
    </div>
  </div>
</template>

<script>
import axios from '../axios'
import {MessageBox} from 'element-ui'
import changerole from '../pub/changerole'

export default {
  name: 'snav',
  components: {
    changerole
  },
  methods: {
    handleSelect(key, keyPath) {
      this.$router.replace({path: `${keyPath}`})
    },
    logout() {
      MessageBox.confirm('是否注销？')
        .then(() => {
          axios.delete('/logout/').then(() => {
            localStorage.removeItem('Authorization')
            this.$router.replace('/login')
          })
        })
        .catch(() => {
        })
    }
  }
}
</script>
