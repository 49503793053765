<template>
  <main>
    <div class="top">
      <p class="title">定制学术指导</p>
      <el-link :href="url" download="定制学术指导.png" target="blank" :underline="false">
        下载海报
      </el-link>
      <el-popover placement="bottom" trigger="hover">
        <img src="../../assets/icon/glasgowicon.png" height="180" width="180" />
        <p class="glasgow" slot="reference">立即咨询</p>
      </el-popover>
    </div>
    <img id="elite" :src="url" width="35%" />
  </main>
</template>
<script>
export default {
  name: 'preview',
  data () {
    return {
      url: 'https://qihang-prod.oss-cn-zhangjiakou.aliyuncs.com/glasgow.png',
      title: ''
    }
  }
}
</script>
<style lang="scss" scoped>
main {
  background-color: #979797;
}
.top {
  height: 60px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  .title {
    font-size: 24px;
    position: absolute;
    color: #c0c0c0;
    top: 20%;
    left: 10%;
    margin: 0;
  }
  a {
    position: absolute;
    top: 20%;
    right: 20%;
    font-size: 24px;
    color: #c0c0c0;
    font-weight: normal;
    &:hover {
      color: #a0d469;
    }
  }
}
span {
  position: absolute;
  top: 20%;
  right: 10%;
  color: #c0c0c0;
  font-size: 24px;
}
.glasgow {
  font-size: 24px;
  color: #c0c0c0;
  text-align: left;
  margin: 0% 0% 0% 0%;
  &:hover {
    color: #a0d469;
  }
}
</style>
