import { render, staticRenderFns } from "./MSinterview.vue?vue&type=template&id=24ba5e94&scoped=true"
import script from "./MSinterview.vue?vue&type=script&lang=js"
export * from "./MSinterview.vue?vue&type=script&lang=js"
import style0 from "./MSinterview.vue?vue&type=style&index=0&id=24ba5e94&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24ba5e94",
  null
  
)

export default component.exports