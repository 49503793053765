<template>
  <el-main class="main">
    <div class="content">
      <h2>论文选题检索</h2>
      <a href="https://www.gla.uestc.edu.cn/" target="_blank"
        ><img src="../../assets/icon/格拉斯哥icon.png" class="glasgow"
      /></a>
      <a href="https://www.elitebackground.cn/" target="_blank"
        ><img src="../../assets/icon/菁英icon.png" class="elite"
      /></a>
      <el-row class="top">
        <el-col :span="7" class="item">
          <el-input placeholder="标签" v-model="keyword" @keyup.enter.native="search()"> </el-input>
          <el-tag
            :key="tag"
            v-for="tag in keywords"
            closable
            :disable-transitions="true"
            @close="keywordsClose(tag)"
          >
            {{ tag }}
          </el-tag>
        </el-col>
        <el-col :span="7" class="item">
          <el-input
            placeholder="选题关键词"
            v-model="title"
            @keyup.enter.native="search()"
            @input="input_search()"
          >
          </el-input>
        </el-col>
        <el-col :span="5" class="item">
          <el-input
            placeholder="编号"
            v-model="topic_id"
            @keyup.enter.native="search()"
            @input="input_search()"
          >
          </el-input>
        </el-col>
        <el-button class="sea" type="primary" @click="search()">搜索</el-button>
      </el-row>
      <div class="DBS">
        <el-table
          :data="tableData"
          class="base"
          :row-class-name="fullclass"
          @sort-change="sortchange"
          ref="tableList"
          @row-click="openprieview"
          :row-style="{ cursor: 'pointer' }"
        >
          <el-table-column
            prop="topic_id"
            align="center"
            min-width="15"
            label="编号"
            sortable="custom"
            :sort-orders="['descending', 'ascending']"
          >
          </el-table-column>
          <el-table-column
            prop="degree"
            align="center"
            min-width="25"
            label="难度"
            sortable="custom"
            :sort-orders="['descending', 'ascending']"
          >
          </el-table-column>
          <el-table-column
            prop="title"
            min-width="45"
            header-align="center"
            align="left"
            label="选题"
          >
            <template slot-scope="scope">
              <span v-html="showKeyWord(scope.row.title)"></span>
              <div class="direction">
                {{ scope.row.subject.content }} |
                {{
                  scope.row.direction
                    .map((e) => {
                      return e.content
                    })
                    .join(' | ')
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="20"> </el-table-column>
          <div slot="empty" class="empty" v-if="has_next">
            <i class="eliwise-loading"></i> <span>加载中，请稍后</span>
          </div>
        </el-table>
        <div class="tableborder"></div>
        <div class="bottom">
          <div style="float: left">
            <p>
              共计 <span class="green">{{ topic_total }}</span> 条选题
            </p>
          </div>
          <p class="green" @click="pagedown()" style="cursor: pointer">
            上一页
          </p>
          <p>{{ page }}/{{ page_total }}</p>
          <p class="green" @click="pageup()" style="cursor: pointer">下一页</p>
          <p>跳转至<el-input class="page" v-model="next_page"></el-input>页</p>
          <el-button type="primary" @click="pageto()">确定</el-button>
        </div>
      </div>
      <el-link href="https://www.elitebackground.cn/" target="_blank" class="copyright"
        >菁英背景提升©️</el-link
      >
    </div>
  </el-main>
</template>

<script>
import axios from '../axios'
export default {
  name: 'Topic-glasgow',
  data () {
    return {
      title: '',
      titlevalue: '',
      teacher: '',
      topic_id: '',
      page: 1,
      topic_total: 1,
      page_total: 1,
      next_page: '',
      has_next: true,
      has_previous: false,
      sort: 'sort_id',
      desc: true,
      directions: [],
      keyword: '',
      keywords: [],
      keyid: [],
      tableData: []
    }
  },
  created () {
    this.getlist()
  },
  methods: {
    openprieview () {
      let routeUrl = this.$router.resolve({
        path: '/preview-uestc-glasgow'
      })
      window.open(routeUrl.href, '_blank')
    },
    remoteDirection (query) {
      axios
        .get('DBS/direction', {
          params: {
            content: query
          }
        })
        .then(response => {
          this.directions = response.data.directions
        })
    },
    fullclass ({ row }) {
      if (row.online === false) {
        return 'fulling'
      }
    },
    tagclass (tag) {
      switch (tag.type) {
        case '理科':
          return this.keyid.indexOf(tag.id) > -1 ? 'tag1' : 'tag2'
        case '工科':
          return this.keyid.indexOf(tag.id) > -1 ? 'tag3' : 'tag4'
        case '商科':
          return this.keyid.indexOf(tag.id) > -1 ? 'tag5' : 'tag6'
        case '社科':
          return this.keyid.indexOf(tag.id) > -1 ? 'tag7' : 'tag8'
        case '医科':
          return this.keyid.indexOf(tag.id) > -1 ? 'tag9' : 'tag10'
      }
    },
    keywordselect () {
      if (this.keywords.indexOf(this.keyword) > -1) {
        this.$message({
          message: '请选择其他标签',
          type: 'warning'
        })
      } else this.search()
    },
    keywordsClose (tag) {
      this.keywords.splice(this.keywords.indexOf(tag), 1)
      this.search()
    },
    input_search: _.debounce(function () {
      this.search()
    }, 500),
    search () {
      this.sort = 'topic_id'
      this.desc = true
      this.titlevalue = this.title
      if (this.keyword) {
        this.keywords.push(this.keyword)
      }
      this.getsearch()
    },
    getsearch () {
      this.keyword = ''
      this.page = 1
      this.getlist()
    },
    sortchange (column) {
      this.sort = column.prop
      switch (column.order) {
        case 'ascending':
          this.desc = false
          break
        case 'descending':
          this.desc = true
          break
        default:
          this.desc = true
          this.sort = ''
      }
      this.getsearch()
    },
    pageto () {
      if (this.next_page > 0 && this.next_page <= this.page_total) {
        this.page = this.next_page
        this.getlist()
        document.querySelector('.el-main').scrollTop = 0
      } else {
        this.$message({
          message: '请输入正确的页码',
          type: 'warning'
        })
      }
    },
    pageup () {
      if (this.has_next) {
        this.page++
        this.getlist()
        document.querySelector('.el-main').scrollTop = 0
      } else {
        this.$message({
          message: '已经到最后一页了',
          type: 'warning'
        })
      }
    },
    pagedown () {
      if (this.has_previous) {
        this.page--
        this.getlist()
        document.querySelector('.el-main').scrollTop = 0
      } else {
        this.$message({
          message: '已经是第一页了',
          type: 'warning'
        })
      }
    },
    getlist () {
      axios
        .get('/DBS/topic_list/glasgow', {
          params: {
            keyword: this.keywords.join(','),
            title: this.title,
            teacher: this.teacher,
            topic_id: this.topic_id,
            page: this.page,
            sort: this.sort,
            desc: this.desc,
            role: 'read'
          }
        })
        .then(response => {
          this.topic_total = response.data.topic_total
          this.page_total = response.data.page_total
          this.has_next = response.data.has_next
          this.has_previous = response.data.has_previous
          this.tableData = response.data.topics
        })
    },
    showKeyWord (str) {
      if (this.titlevalue !== '') {
        let regStr = `${this.titlevalue}`
        let reg = new RegExp(regStr, 'g')
        str = str.replace(reg, `<span style="background-color:#F7B500;">${this.titlevalue}</span>`)
      }
      return str
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  text-align: left;
  margin: 15px 5% 15px 0px;
  .el-autocomplete {
    width: 100%;
  }
}
.main {
  background: #f5f7fa;
  padding: 1%;
  .content {
    width: 85%;
    margin: 5px auto 0px;
    position: relative;
    h2 {
      font-size: 24px;
      color: #434a54;
      font-weight: normal;
      float: left;
    }
    .top {
      display: flex;
      justify-content: center;
      height: auto;
      margin-bottom: 10px;
      width: 100%;
      .sea {
        width: 80px;
        height: 40px;
        margin-top: 15px;
        float: right;
      }
    }
  }
}
.add {
  position: relative;
  right: 0px;
  margin-top: 25px;
  float: right;
}
.top {
  /deep/ .el-select {
    width: 100%;
    /deep/.el-input__inner {
      cursor: text;
    }
  }
}

/deep/.el-table__body-wrapper {
  font-size: 16px;
}
.bottom {
  margin-top: 10px;
  text-align: right;
  .green {
    color: #a0d469;
  }
  .page {
    width: 58px;
    margin: 0px 10px;
    /deep/.el-input__inner {
      height: 25px;
    }
  }
}
.tableborder {
  border-top: 1px #979797 solid;
  width: 106%;
  position: absolute;
  left: -3%;
}

p {
  display: inline;
  font-size: 14px;
  margin-right: 10px;
}

/deep/.fulling {
  color: #c0c0c0;
}

.DBS {
  width: 100%;
  margin: auto;
}
/deep/.el-table tbody tr:hover > td {
  background-color: #f2f2f2;
}
.glasgow {
  width: 147px;
  height: 45px;
  position: absolute;
  right: 220px;
}
.elite {
  width: 183px;
  height: 36px;
  position: absolute;
  right: 0px;
}
.copyright {
  position: absolute;
  bottom: 5px;
  left: 47%;
}
</style>
