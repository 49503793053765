<template>
  <el-main class="main">
    <div>
      <div class="jtitle">
        <h2 class="jtext">会议/期刊管理</h2>
      </div>
      <div class="table">
        <div class="search flex">
          <div class="seaicon">
            <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
          </div>
          <el-input
            @input="search"
            v-model.trim="meetname"
            class="seasubfix"
          ></el-input>
          <div class="addmeet" @click="handleMeet">
            <img
              src="../../assets/icon/添加用户.png"
              alt=""
              height="30"
              width="30"
            />
          </div>
        </div>
      </div>
      <el-table :data="meetData" width="100%" :height="tableHeight" ref="tableScroll">
        <el-table-column prop="name" label="简称" align="center">
        </el-table-column>
        <el-table-column prop="conf_type" align="center" label="分类">
          <template slot-scope="scope">
            {{scope.row.conf_type==='0'?'会议':'期刊'}}
          </template>
        </el-table-column>
        <el-table-column prop="url" align="center" label="官网链接">
          <template slot-scope="scope">
            <el-link
              :href="scope.row.url"
              target="_blank"
              :underline="false"
              class="buttonText"
              >{{ scope.row.url }}</el-link
            >
          </template>
        </el-table-column>
        <el-table-column prop="paper_id" label="论文id前缀" align="center">
        </el-table-column>
        <el-table-column width="200px" label="状态">
          <template slot-scope="scope">
            <el-switch
              @change="swithChange(scope.$index, scope.row)"
              v-model="scope.row.isend"
              active-color="#A0D469"
              inactive-color="#c0c0c0"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column width="100px">
          <template slot-scope="scope">
            <el-button type="primary" @click="editMeet(scope.$index, scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
        <el-table-column width="100px">
          <template slot-scope="scope">
            <el-popover
              ref="popover"
              placement="top"
              trigger="hover"
              content="已绑定项目"
              :disabled="scope.row.number === 0"
            >
            </el-popover>
            <div v-popover:popover>
              <el-button
                type="primary"
                @click="deleteMeet(scope.$index, scope.row)"
                :disabled="scope.row.number > 0"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="新增会议/期刊" :visible.sync="meetVisible" width="1050px">
        <el-form :model="addmeet" :rules="rules" ref="addmeet">
          <el-row>
            <el-col :span="12" class="inputleft">
              <el-form-item label="简称*" prop="name">
                <el-input
                  v-model="addmeet.name"
                  placeholder="简称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputright">
              <el-form-item label="官网链接*" prop="url">
                <el-input
                  v-model="addmeet.url"
                  placeholder="官网链接"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputleft">
              <el-form-item label="论文id前缀*" prop="paperid">
                <el-input
                  v-model="addmeet.paperid"
                  placeholder="论文id前缀"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputright">
              <el-form-item label="分类*" prop="conf_type">
                <el-select
                  placeholder="分类"
                  v-model="addmeet.conf_type"
                  style="width:100%"
                >
                  <el-option
                    v-for="item in conf_types"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="meetVisible = false">取 消</el-button>
          <el-button @click="addMeet('addmeet')" type="primary"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <el-dialog title="修改会议/期刊" :visible.sync="putVisible" width="1050px">
        <el-form :model="addmeet" :rules="rules" ref="addmeet">
          <el-row>
            <el-col :span="12" class="inputleft">
              <el-form-item label="简称*" prop="name">
                <el-input
                  v-model="addmeet.name"
                  placeholder="简称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputright">
              <el-form-item label="链接*" prop="url">
                <el-input
                  v-model="addmeet.url"
                  placeholder="链接"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputleft">
              <el-form-item label="论文id前缀*" prop="paperid">
                <el-input
                  v-model="addmeet.paperid"
                  placeholder="论文id前缀"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="inputright">
              <el-form-item label="分类*" prop="conf_type">
                <el-select
                  placeholder="分类"
                  v-model="addmeet.conf_type"
                  style="width:100%"
                >
                  <el-option
                    v-for="item in conf_types"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="putVisible = false">取 消</el-button>
          <el-button @click="putMeet('addmeet')" type="primary"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </el-main>
</template>

<script>
import axios from '../axios'
import { debounce } from 'lodash'
import { MessageBox } from 'element-ui'
export default {
  name: 'MSmeet',
  data () {
    return {
      rules: {
        name: [{ required: true, message: '请填写简称', trigger: 'blur' }],
        url: [{ required: true, message: '请填写链接', trigger: 'blur' }],
        paperid: [
          { required: true, message: '请填写论文id前缀', trigger: 'blur' }
        ]
      },
      passwordVisible: false,
      option: [
        {
          value: '1',
          label: '会务组'
        },
        {
          value: '2',
          label: '修改密码'
        }
      ],
      value: '会务组',
      putVisible: false,
      meetData: [],
      addmeet: {
        name: '',
        paperid: '',
        url: '',
        conf_type: '0'
      },
      meetname: '',
      tableHeight: window.innerHeight - 155,
      has_next: true,
      meetVisible: false,
      conf_id: '',
      edit_id: 0,
      isend: false,
      putid: '',
      conf_types: [{
        value: '0',
        label: '会议'
      }, {
        value: '1',
        label: '期刊'
      }
      ],
      page: 1
    }
  },
  mounted () {
    axios
      .get(`/meeting/confs`, {
        params: {
          page: this.page
        }
      })
      .then(res => {
        this.meetData = res.data.confs
      })

    this.dom = this.$refs.tableScroll.bodyWrapper
    this.dom.addEventListener('scroll', this.scroll)
  },
  methods: {
    scroll () {
      let scrollTop = this.dom.scrollTop
      let clientHeight = this.dom.clientHeight || this.dom.clientHeight
      let scrollHeight = this.dom.scrollHeight || this.dom.scrollHeight
      if (scrollTop + clientHeight >= scrollHeight - 1 && this.has_next) {
        this.page = this.page + 1
        axios
          .get(`/meeting/confs`, {
            params: {
              page: this.page
            }
          })
          .then(res => {
            this.meetData = this.meetData.concat(res.data.confs)
            this.has_next = res.data.has_next
          })
      }
    },
    addMeet (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          axios.post(`/meeting/conf`, {
            name: this.addmeet.name,
            url: this.addmeet.url,
            paper_id: this.addmeet.paperid,
            conf_type: this.addmeet.conf_type
          }).then(() => {
            window.location.reload()
          })
        } else {
          return false
        }
      })
    },
    putMeet (formName, inedx, row) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          axios.put(`/meeting/conf/${this.putid}`, {
            name: this.addmeet.name,
            url: this.addmeet.url,
            paper_id: this.addmeet.paperid,
            conf_type: this.addmeet.conf_type
          }).then(() => {
            window.location.reload()
          })
        } else {
          return false
        }
      })
    },
    deleteMeet (index, row) {
      MessageBox.confirm('确定删除').then(() => {
        axios
          .delete(`/meeting/conf/${row.conf_id}`)
          .then(window.location.reload())
      })
    },
    editMeet (index, row) {
      this.putVisible = true
      this.addmeet.paperid = row.paper_id
      this.addmeet.name = row.name
      this.addmeet.url = row.url
      this.addmeet.conf_type = row.conf_type
      this.putid = row.conf_id
      console.log(this.addmeet.paperid)
    },
    swithChange (index, row) {
      console.log(row.isend)
      axios.post(`meeting/conf/${row.conf_id}/end`, {
        isend: row.isend
      })
    },
    search: debounce(function () {
      this.page = 1
      this.has_next = true
      axios
        .get(`meeting/confs`, {
          params: {
            name: this.meetname,
            page: this.page
          }
        })
        .then(res => {
          this.meetData = res.data.confs
        })
    }),
    handleMeet () {
      this.meetVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-form-item__label::before {
  display: none;
}
</style>
