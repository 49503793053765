<template>
  <div>
    <div class="pre" @click="turnUp">
      <div class="pretext">{{ type }}</div>
      <div :class="{ turn: turnValue }" class="preimg">
        <img src="../../assets/icon/箭头.png" height="12" />
      </div>
    </div>
    <div v-if="turnValue">
      <div
        v-for="(item, index) in manuals"
        :key="index"
        class="project"
        @click="pdfPreview(item.pdf, getType(item.pdf))"
      >
        <img
          src="../../assets/icon/pdf2.png"
          width="20"
          style="margin-left: 15px"
          v-if="getType(item.pdf) === 'pdf'"
        />
        <img
          src="../../assets/icon/word.png"
          width="18"
          style="margin-left: 15px"
          v-else-if="
            getType(item.pdf) === 'doc' || getType(item.pdf) === 'docx'
          "
        />
        <img
          src="../../assets/icon/others.png"
          width="18"
          style="margin-left: 15px"
          v-else
        />
        <p class="name">
          {{ item.name }}
          <img
            src="../../assets/icon/newicon.png"
            class="new"
            v-if="item.new"
          />
        </p>
        <p>
          {{ item.created }}
        </p>
        <div style="margin-left: 30px" v-if="!readOnly">
          <el-button class="edit listedit" @click.stop="manualEdit(item)">
            <i class="el-icon-edit"></i>
          </el-button>
          <el-button class="listedit" @click.stop="manualDelete(item.id)">
            <i class="el-icon-delete"></i>
          </el-button>
        </div>
        <span class="introduction" v-if="item.introduction != ''">
          手册简介：{{ item.introduction }}
        </span>
        <img
          src="../../assets/icon/预览.png"
          height="18"
          width="auto"
          class="preview"
          v-if="readOnly"
        />
      </div>
    </div>
    <manualForm ref="manualForm" :type="'put'"></manualForm>
  </div>
</template>

<script>
import { MessageBox } from 'element-ui'
import axios from '../axios'
import manualForm from './manualForm'

export default {
  name: 'manualList',
  data () {
    return {
      turnValue: true,
      manuals: [],
      title: ''
    }
  },
  components: {
    manualForm
  },
  props: {
    type: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: true
    }
  },
  mounted () {
    this.getManualList()
  },
  methods: {
    getType (url) {
      let x = url.split('.')
      return x[x.length - 1]
    },
    turnUp () {
      this.turnValue = !this.turnValue
    },
    pdfPreview (pdfUrl, type) {
      window.open(pdfUrl, '_blank')
    },
    manualEdit (item) {
      this.$refs.manualForm.active = true
      this.$refs.manualForm.manual.name = item.name
      this.$refs.manualForm.manual.stage = item.stage
      this.$refs.manualForm.manual.pdf = item.pdf
      this.$refs.manualForm.manual.introduction = item.introduction
      this.$refs.manualForm.pdfName = item.pdf.split('/')[
        item.pdf.split('/').length - 1
      ]
      this.$refs.manualForm.manualId = item.id
    },
    manualDelete (id) {
      MessageBox.confirm('是否删除该导师手册').then(() => {
        axios
          .delete(`/manual/Manual/${id}`)
          .then(response => {
            if (response.status === 200) {
              this.$message({
                showClose: true,
                message: '已删除',
                type: 'success'
              })
              this.getManualList()
            }
          })
          .catch(error => {
            this.$message({
              showClose: true,
              message: `删除失败,${error.response.data.error_message}`,
              type: 'error'
            })
          })
      })
    },
    getManualList () {
      axios
        .get('/manual/getManuals', {
          params: {
            stage: this.type
          }
        })
        .then(response => {
          this.manuals = response.data.manuals
          if (this.manuals[0].new === null && this.readOnly === true) {
            this.turnValue = false
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.pre {
  text-align: left;
  height: 30px;
  font-size: 12px;
  background-color: #c0c0c0;
  margin-bottom: 10px;
  position: relative;
  .pretext {
    position: absolute;
    left: 25px;
    top: 7px;
    color: white;
  }
  .preimg {
    position: absolute;
    right: 22.5px;
    top: 10px;
    transition: 0.3s;
    transform: rotate(-90deg);
  }
  .turn {
    transform: rotate(0deg);
    transition: 0.3s;
  }
}
.project {
  align-items: center;
  text-align: left;
  background: #ffffff;
  font-size: 14px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  cursor: pointer;
  height: auto;
  flex-wrap: wrap;
  padding: 20px 0;
  border-radius: 8px;
  .name {
    position: relative;
    left: 10px;
    flex: 0 1 79%;
  }
  .new {
    height: 12px;
    position: relative;
    top: -5px;
  }
  .introduction {
    flex: 0 1 85%;
    text-align: left;
    width: 47%;
    margin-left: 45px;
    color: #989898;
  }
  .preview {
    position: absolute;
    right: 24px;
    top: 12px;
  }
}
</style>
