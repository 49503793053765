<template>
  <el-main class="main">
    <div>
      <div class="jtitle">
        <h2 class="jtext">学员账户</h2>
      </div>
      <div class="table">
        <div class="search flex">
          <div class="seaicon">
            <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
          </div>
          <el-input
            class="seasubfix"
            v-model="accmessage"
            @input="search"
          ></el-input>
        </div>
        <div>
          <el-table :data="tableData1" ref="tableScroll" :height="tableHeight">
            <el-table-column key="1" prop="id" label="编号" align="center">
            </el-table-column>
            <el-table-column key="2" prop="name" label="姓名" align="center">
            </el-table-column>
            <el-table-column key="3" prop="sex" label="性别" align="center">
            </el-table-column>
            <el-table-column key="4" prop="school" label="学校" align="center">
            </el-table-column>
            <el-table-column
              key="5"
              prop="direction"
              label="专业方向"
              align="center"
            >
            </el-table-column>
            <el-table-column key="6" prop="phone" label="手机" align="center">
            </el-table-column>
            <el-table-column key="7" prop="wechat" label="微信" align="center">
            </el-table-column>
            <el-table-column
              key="8.8"
              prop="cmanager.name"
              label="渠道经理"
              align="center"
            >
            </el-table-column>
            <el-table-column
              key="8.9"
              prop="amanager.name"
              label="客户经理"
              align="center"
            >
            </el-table-column>
            <el-table-column
              key="9.8"
              prop="planner"
              label="规划师"
              align="center"
            >
            </el-table-column>
            <!-- <el-table-column
              key="8"
              prop="编辑"
              width="45px"
              
              align="center"
              ><template slot-scope="scope">
                <el-button
                  
                  style="width:40px"
                  class="edit listedit"
                  @click="sEdit(scope.$index, scope.row)"
                >
                  <i class="el-icon-edit"></i>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              key="9"
              prop="删除"
              width="45px"
              
              align="center"
              ><template slot-scope="scope">
                <el-button
                  
                  style="width:40px"
                  class="listedit"
                  @click="sDel(scope.$index, scope.row)"
                >
                  <i class="el-icon-delete"></i>
                </el-button>
              </template>
            </el-table-column> -->
            <div slot="append" style="text-align: center; font-size: 25px">
              <i class="eliwise-loading" v-if="has_next"></i>
              <i v-else style="font-size: 16px">--没有数据了--</i>
            </div>
          </el-table>
        </div>
      </div>
    </div>
  </el-main>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'
import _ from 'lodash'
import changerole from '../pub/changerole'
export default {
  name: 'Maccount',
  data () {
    return {
      repassvisible: false,
      rules: {
        ypassword: [
          { required: true, message: '请填写原密码', trigger: 'blur' }
        ],
        npassword: [
          { required: true, message: '请填写新密码', trigger: 'blur' }
        ],
        rpassword: [
          { required: true, message: '请再次填写新密码', trigger: 'blur' }
        ]
      },
      ruleform: {
        ypassword: '',
        npassword: '',
        rpassword: ''
      },
      sinfo: {
        school: '',
        direction: '',
        place: '',
        cellphone: '',
        wechat: '',
        email: ''
      },
      rolevalue: '客户经理',
      accmessage: '',
      pagenumber: 1,
      has_next: '',
      tableData1: [],
      tableHeight: window.innerHeight - 150
    }
  },
  components: {
    changerole
  },
  created () {
    this.getS()
    this.getinfo()
  },
  mounted () {
    const that = this
    ;(window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 150
      })()
    }),
      this.dom()
  },
  methods: {
    getinfo () {
      axios
        .get('/amanager/')
        .then(response => {
          this.sinfo = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    pptrue (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.ruleform.npassword !== this.ruleform.rpassword) {
            this.$message({
              showClose: true,
              message: '密码不一致',
              type: 'error'
            })
          } else {
            axios
              .post('/amanager/change_password', {
                old_password: this.ruleform.ypassword,
                new_password: this.ruleform.npassword,
                confirm: this.ruleform.rpassword
              })
              .then(response => {
                if (response.status === 200) {
                  MessageBox.alert('修改成功').then(() => {
                    localStorage.removeItem('Authorization')
                    this.$router.replace('/login')
                  })
                }
              })
              .catch(error => {
                this.$message({
                  showClose: true,
                  message: `修改失败,${error.response.data.error_message}`,
                  type: 'error'
                })
              })
          }
        } else {
          return false
        }
      })
    },
    ppclear (formName) {
      this.$refs[formName].resetFields()
      this.ruleform = {}
    },
    pmclear (formName) {
      this.$refs[formName].resetFields()
      this.sinfo = {}
    },

    repassword (e) {
      if (e === '4') {
        this.passwordVisible = true
      } else if (e === '2') {
        this.$router.push('/D/Dinterview')
      } else if (e === '3') {
        this.$router.push('/A/Ainterview')
      } else if (e === '5') {
        this.$router.push('/MD/MDinterview')
      }
    },
    search () {
      this.getS()
    },
    getS: _.debounce(function () {
      this.pagenumber = 1
      this.dom.scrollTop = 0
      this.getStudent()
    }, 500),
    b () {
      let scrollTop = this.dom.scrollTop
      let windowHeight = this.dom.clientHeight || this.dom.clientHeight
      let scrollHeight = this.dom.scrollHeight || this.dom.scrollHeight
      if (scrollTop + windowHeight >= scrollHeight - 1 && this.has_next) {
        this.pagenumber = this.pagenumber + 1
        axios
          .get('/amanager/students/', {
            params: {
              name: this.accmessage,
              page: this.pagenumber
            }
          })
          .then(response => {
            if (response.data.has_previous) {
              this.tableData1 = this.tableData1.concat(response.data.students)
            }
            this.has_next = response.data.has_next
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    dom () {
      this.dom = this.$refs.tableScroll.bodyWrapper
      this.dom.addEventListener('scroll', this.b)
    },
    getStudent () {
      axios
        .get('/amanager/students/', {
          params: {
            name: this.accmessage,
            page: this.pagenumber
          }
        })
        .then(response => {
          this.tableData1 = response.data.students
          this.has_next = response.data.has_next
        })
    }
  }
}
</script>
