<template>
  <div class="item">
    <p class="title">{{ dic.name }}</p>
    <div class="container">
      <div class="listItem" v-for="(item, index) in data" :key="index">
        <div
          v-for="(dicItem, name) in dic.content"
          :key="name + index"
          :class="{ none: !item[name] && item[name] !== 0 }"
        >
          <span class="name"> {{ dicItem.name }}: </span>
          <span class="content">
            <slot v-bind:line="item[name]" :name="name">
              {{ item[name] || '无' }}
            </slot>
          </span>
        </div>
      </div>
      <div v-if="data.length === 0" class="none list-none">无</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dic: {
      type: Object
    },
    data: {
      type: Array
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  text-align: left;
  .title {
    font-size: 18px;
    line-height: 18px;
    color: #8dc152;
    padding-left: 18.51px;
    position: relative;
    font-weight: 700;
    &::before {
      content: '';
      background: #8dc152;
      width: 10px;
      height: 10px;
      display: inline-block;
      line-height: 18px;
      transform-origin: center;
      transform: rotate(45deg);
      position: absolute;
      left: -5px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .listItem {
    position: relative;
    &::before {
      content: '-';
      color: #8dc152;
      position: absolute;
      left: -10px;
    }
    & + .listItem {
      padding-top: 1em;
    }
  }
  .name {
    font-size: 14px;
    color: #656D78;
  }
  .content {
    font-size: 14px;
    color: #434A54;
  }
  .none {
    color: #c0c0c0;
    position: relative;
    * {
      color: #c0c0c0;
    }
  }
}
</style>
