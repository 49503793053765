<template>
  <el-main class="main">
    <div class="flex">
      <teacherList @select="getstu1"  />
      <div v-loading="loading" class="neirong">
        <div class="jtitle">
          <h2 class="jtext">查看导师</h2>
        </div>
        <teacherInfo :tmes="tmes" />
        <jlistgroup :detail="detail" />
      </div>
    </div>
  </el-main>
</template>

<script>
/* eslint-disable no-console */
import axios from '../axios'
import jlistgroup from '../pub/jlistgroup'
import teacherList from '../pub/teacherList'
import teacherInfo from '../pub/teacherInfo'
export default {
  name: 'ADteacher',
  components: {
    jlistgroup,
    teacherList,
    teacherInfo
  },
  data () {
    return {
      detail: {
        notfinlist: [],
        now_interview: [],
        finlist: [],
        fin_interview: [],
        part: []
      },
      tmes: {},
      loading: true
    }
  },
  methods: {
    getstu1 (tmes) {
      this.loading = true
      axios
        .get(`/${this.$store.state.roleApi}/teacher/${tmes.id}/projects`)
        .then(response => {
          this.tmes = response.data.teacher
          this.detail.now_interview = response.data.not_interviewed

          this.detail.notfinlist = response.data.not_finished
          this.detail.finlist = response.data.finished
          this.detail.fin_interview = response.data.interviewed
          this.loading = false
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
h1 {
  font-size: 30px;
  text-align: left;
  margin-top: 15px;
}
/deep/.jlistgroup {
  height: calc(100vh - 140px);
}
</style>
