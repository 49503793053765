<template>
  <p>
    <span :class="{ active: is_active }"> <slot></slot> </span>
    <el-input
      type="textarea"
      autosize
      @focus="focus"
      @blur="blur"
      @input="handleInput"
      :value="textValue"
      @keydown.native="keydown"
      @keyup.native="keyup"
      :maxlength="750 - total + textValue.length"
    ></el-input>
  </p>
</template>

<script>
export default {
  data () {
    return {
      is_active: false,
      textValue: ''
    }
  },
  props: {
    value: [String, Number],
    total: Number
  },
  mounted () {
    this.textValue = this.value
  },
  methods: {
    focus () {
      this.is_active = true
      this.$parent.$parent.focus()
    },
    blur () {
      if (this.value === '') {
        this.is_active = false
        this.$parent.$parent.blur()
      }
    },
    keyup (e) {
      this.textValue = e.target.value
      this.$emit('input', this.textValue)
    },
    keydown (e) {
      if (e.keyCode === 13) {
        e.preventDefault()
      }
    },
    handleInput (e) {
      this.textValue = e
      this.$emit('input', e)
    }
  }
}
</script>
<style lang="scss" scoped>
span {
  color: #c0c0c0;
}
.active {
  color: #656d78;
}
</style>
