<template>
  <div class="aside">
    <changerole></changerole>
    <el-menu router @select="handleSelect">
      <el-menu-item
        :class="$route.path == '/Topic/TopicR' ? 'is-active' : ''"
        index="/Topic/TopicR"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">科研<br />D计划</div>
          <img class="iconmenu" src="../../assets/icon/科研选题icon.png" />
        </el-tooltip>
      </el-menu-item>

      <el-menu-item :class="$route.path == '/Topic/EWA' ? 'is-active' : ''" index="/Topic/EWA">
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">EWA</div>
          <img class="iconmenu" src="../../assets/icon/ewa.png" />
        </el-tooltip>
      </el-menu-item>

      <el-menu-item :class="$route.path == '/Topic/BYR' ? 'is-active' : ''" index="/Topic/BYR">
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">BYR</div>
          <img class="iconmenu" src="../../assets/icon/byr.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item :class="$route.path == '/Topic/Lite' ? 'is-active' : ''" index="/Topic/Lite">
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">Lite</div>
          <img class="iconmenu" src="../../assets/icon/Liteicon.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/Topic/Standard' ? 'is-active' : ''"
        index="/Topic/Standard"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">Standard</div>
          <img class="iconmenu" src="../../assets/icon/Standardicon.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
          :class="$route.path == '/Topic/TopicIap' ? 'is-active' : ''"
          index="/Topic/TopicIap"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">PRO</div>
          <img class="iconmenu" src="../../assets/icon/proicon.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item :class="$route.path == '/Topic/TopicIapHigh' ? 'is-active' : ''" index="/Topic/TopicIapHigh">
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">PRO高中生</div>
          <img class="iconmenu" src="../../assets/icon/proicon.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item :class="$route.path == '/Topic/XDF' ? 'is-active' : ''" index="/Topic/XDF">
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">XDF</div>
          <img class="iconmenu" src="../../assets/icon/XDFicon.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item :class="$route.path == '/Topic/XT' ? 'is-active' : ''" index="/Topic/XT">
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">XT</div>
          <img class="iconmenu" src="../../assets/icon/xticon.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/Topic/TopicRT' ? 'is-active' : ''"
        index="/Topic/TopicRT"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content" style="text-align: center">科研<br />+<br />论文</div>
          <img class="iconmenu" src="../../assets/icon/科研论文icon.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/Topic/TopicT' ? 'is-active' : ''"
        index="/Topic/TopicT"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">论文<br />选题</div>
          <img class="iconmenu" src="../../assets/icon/论文选题icon.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/Topic/TopicA' ? 'is-active' : ''"
        index="/Topic/TopicA"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">备用<br />选题</div>
          <img class="iconmenu aimg" src="../../assets/icon/学术指导icon.png" />
        </el-tooltip>
      </el-menu-item>
      <el-menu-item
        :class="$route.path == '/Topic/TopicEpq' ? 'is-active' : ''"
        index="/Topic/TopicEpq"
      >
        <el-tooltip placement="right" effect="light" popper-class="atooltip">
          <div slot="content">EPQ</div>
          <img class="iconmenu" src="../../assets/icon/epqicon.png" />
        </el-tooltip>
      </el-menu-item>

    </el-menu>
    <div class="back">
      <img @click="logout" src="../../assets/icon/退出.png" height="20" width="20" />
    </div>
  </div>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'
import changerole from '../pub/changerole'
export default {
  name: 'TopicNav',
  components: { changerole },
  methods: {
    handleSelect (key, keyPath) {
      this.$router.replace({ path: `${keyPath}` })
    },
    logout () {
      MessageBox.confirm('是否注销？')
        .then(() => {
          axios.delete('/logout/').then(() => {
            localStorage.removeItem('Authorization')
            this.$router.replace('/login')
          })
        })
        .catch(() => {})
    }
  }
}
</script>
<style lang="scss" scoped>
.aimg {
  width: 15px !important;
  height: auto !important;
}
.el-menu-item.is-active,
.el-menu-item:hover {
  .aimg {
    width: 18px !important;
    height: auto !important;
  }
}
</style>
