<template>
  <div>
    <el-row class="jstumessage">
      <el-col :span="5" style="display: flex;align-items: end;">
        <h1 class="sname">{{ tmes.name }}</h1>
        <el-tooltip
          effect="light"
          content="暂无简历文件"
          placement="top"
          :disabled="Boolean(tmes.outside)"
          style="margin-left:15px"
        >
          <div>
            <el-button
              type="primary"
              :disabled="!tmes.outside"
              @click="downResume"
              >下载简历
            </el-button>
          </div>
        </el-tooltip>
      </el-col>
      <el-col :span="19">
        <div class="mes">
          <el-row align="middle" type="flex">
            <el-col :span="5">
              <img
                class="icon"
                src="../../assets/icon/学校@3x.png"
                height="12"
                width="14"
              />
              <el-tooltip
                placement="top"
                :disabled="!organizationEllips"
                :content="tmes.organization"
                effect="light"
                popper-class="atooltip"
              >
                <span ref="organizationtext" class="messagetext">{{
                  tmes.organization
                }}</span>
              </el-tooltip>
            </el-col>
            <el-col :span="5">
              <img
                class="icon"
                src="../../assets/icon/专业@3x.png"
                height="13"
                width="13"
              />
              <el-tooltip
                placement="top"
                :disabled="!directionEllips"
                :content="tmes.research_direction"
                effect="light"
                popper-class="atooltip"
              >
                <span ref="directiontext" class="messagetext">{{
                  tmes.research_direction
                }}</span>
              </el-tooltip>
            </el-col>
            <el-col class="inmes inmesfix" :span="5">
              <img
                class="icon"
                src="../../assets/icon/电话@3x.png"
                height="13"
                width="13"
              />
              <el-tooltip
                placement="top"
                :disabled="!phoneEllips"
                :content="tmes.cellphone"
                effect="light"
                popper-class="atooltip"
              >
                <span ref="phonetext" class="messagetext">{{
                  tmes.cellphone || '无手机信息'
                }}</span>
              </el-tooltip>
            </el-col>
            <el-col class="inmes inmesfix" :span="7">
              <img
                class="icon"
                src="../../assets/icon/邮件@3x.png"
                height="13"
                width="17"
              />
              <el-tooltip
                placement="top"
                :disabled="!mailEllips"
                :content="tmes.inst_email"
                effect="light"
                popper-class="atooltip"
              >
                <span ref="mailtext" class="messagetext">{{
                  tmes.inst_email || '无邮箱信息'
                }}</span>
              </el-tooltip>
              <img
                class="tran"
                :class="{ changekai: !background }"
                @click="background = !background"
                src="../../assets/icon/tran.png"
                height="20"
                width="20"
              />
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-rate
        class=" star"
        v-model="tmes.star"
        disabled
        :colors="colors"
        disabled-void-color="#c0c0c0"
        score-template="{value}"
      ></el-rate>
    </el-row>
    <el-row class="bg" v-if="background">
      <el-col :span="24">
        <div class="ktitle">导师背景</div>
        <div class="ktext">{{ tmes.paper }}</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'teacherInfo',
  props: {
    tmes: {
      type: Object,
      default: () => {
        return {
          star: '',
          name: '',
          id: '',
          organization: '',
          research_direction: '',
          cellphone: '',
          scientific_research: '',
          paper: ''
        }
      }
    }
  },
  data () {
    return {
      val: true,
      background: false,
      colors: {
        2: '#A0D469',
        4: { value: '#A0D469', excluded: true },
        5: '#A0D469'
      },
      mailEllips: false,
      phoneEllips: false,
      directionEllips: false,
      organizationEllips: false
    }
  },
  watch: {
    tmes: {
      handler () {
        this.$nextTick(function () {
          this.mailEllips =
            this.$refs['mailtext'].offsetWidth <
            this.$refs['mailtext'].scrollWidth
          this.phoneEllips =
            this.$refs['phonetext'].offsetWidth <
            this.$refs['phonetext'].scrollWidth
          this.directionEllips =
            this.$refs['directiontext'].offsetWidth <
            this.$refs['directiontext'].scrollWidth
          this.organizationEllips =
            this.$refs['organizationtext'].offsetWidth <
            this.$refs['organizationtext'].scrollWidth
        })
      },
      deep: true
    }
  },
  methods: {
    downResume () {
      let url = this.tmes.outside.url
      let xx = this.tmes.outside.url.split('.')
      let type = xx[xx.length - 1]
      let x = new XMLHttpRequest()
      x.open('GET', url, true)
      x.responseType = 'blob'
      let that = this
      x.onload = function (e) {
        let url = window.URL.createObjectURL(x.response)
        let a = document.createElement('a')
        a.href = url
        a.download = that.tmes.organization + '-' + that.tmes.name + '.' + type
        a.click()
      }
      x.send()
    }
  }
}
</script>

<style lang="scss" scoped>
.teacherInfo {
  flex: 0 0 auto;
}
.tran {
  position: absolute;
  right: 30px;
  margin-top: -2px;
  transition: 0.3s;
}
.changekai {
  transform: rotate(-90deg);
  transition: 0.3s;
}
.sname {
  font-size: 40px;
  color: #656d78;
  text-align: left;
  margin-top: 0px;
  font-weight: normal;
  margin-bottom: 0px;
  line-height: 50px;
}
.icon {
  position: relative;
  top: 2px;
  margin-right: 3px;
}
.messagetext {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #656d78;
  line-height: 16px;
  font-size: 14px;
}
.star {
  text-align: left;
}
.bg {
  border-bottom: solid 1px #c0c0c0;
  padding-bottom: 10px;
}
.ktitle {
  text-align: left;
  font-size: 24px;
  color: #434a54;
  margin-top: 12.5px;
}
.ktext {
  text-align: left;
  font-size: 12px;
  line-height: 20px;
  margin-top: 7px;
  white-space: pre-line;
  color: #656d78;
}
</style>
