<template>
  <div>
    <div class="flex" style="align-items: center;">
      <el-tag
        :disable-trasnsitions="true"
        class="tags"
        :class="selectedTagcol(subject)"
        v-if="subject"
        style="margin: 5px;"
      >
        ⚬ {{ subject.content }}
      </el-tag>
      <div style="font-size: 20px;cursor:pointer;" @click.stop="show = !show">
        <div
          :class="subject ? 'el-icon-edit' : 'el-icon-circle-plus-outline'"
        ></div>
      </div>
    </div>
    <!--  单选学科标签组件-->
    <div
      class="warpper"
      id="wrapper"
      v-if="show"
      @mousewheel.prevent="mousewheel"
    >
      <div class="searchSub">
        <div class="icon">
          <img src="../../assets/icon/搜索@3x.png" height="20" width="20" />
        </div>
        <el-input
          v-model="subjectValue"
          placeholder="学科标签"
          ref="keywords"
          @input.native="remoteDirection"
        ></el-input>
        <el-button type="primary" @click="show = false" style="height: 24px">
          完成
        </el-button>
      </div>
      <div class="tagSelectWrapper">
        <div
          class="rowWrapper"
          v-for="(item, index) in showSubjects"
          :key="index"
        >
          <div class="title">{{ item[0] ? item[0].type : null }}</div>
          <div class="tagsWrap">
            <el-tag
              :id="'' + tag.id"
              :key="tag.id"
              v-for="tag in item"
              :disable-transitions="true"
              class="tags"
              @click="clickTag(tag)"
              :class="tagcolor(tag)"
              @mouseenter.native="tagHover(tag)"
              @mouseleave.native="tagLeave(tag)"
              style="cursor:default;"
            >
              ⚬ {{ tag.content }}
            </el-tag>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios'
import { debounce } from 'lodash'

export default {
  name: 'tagsSearch',
  props: {
    subject: {}
  },
  data () {
    return {
      showSubjects: {
        理科: [],
        医科: [],
        工科: [],
        商科: [],
        社科: []
      },
      subjectValue: '',
      show: false
    }
  },
  created () {
    this.getSubject()
  },
  mounted () {
    const x = e => {
      if (
        document.getElementById('wrapper') &&
        !document.getElementById('wrapper').contains(e.target)
      ) {
        this.show = false
      }
    }
    document.addEventListener('click', x)
  },
  methods: {
    mousewheel (e) {
      let con = document.getElementById('wrapper')
      e = e || window.event
      con.scrollTop += e.wheelDelta > 0 ? -10 : 10
      return false
    },
    remoteSubject (page, content = '') {
      return axios.get('DBS/subject', {
        params: {
          content: content,
          page: page
        }
      })
    },
    async getSubject (content = '') {
      let page = 1
      let res = await this.remoteSubject(page, content)
      let subjects = []

      subjects = [...subjects, ...res.data.subjects]
      while (res.data.has_next === true) {
        page++
        res = await this.remoteSubject(page, content)
        subjects = [...subjects, ...res.data.subjects]
      }
      for (let item of subjects) {
        switch (item.type) {
          case '理科': {
            this.showSubjects['理科'].push(item)
            break
          }
          case '医科': {
            this.showSubjects['医科'].push(item)
            break
          }
          case '工科': {
            this.showSubjects['工科'].push(item)
            break
          }
          case '商科': {
            this.showSubjects['商科'].push(item)
            break
          }
          case '社科': {
            this.showSubjects['社科'].push(item)
            break
          }
        }
      }
    },
    clickTag (item) {
      if (this.subject.id === item.id) {
        this.$message({
          message: '不能选择相同标签',
          type: 'warning'
        })
      } else {
        this.$emit('update:subject', item)
      }
    },
    tagcolor (tag) {
      switch (tag.type) {
        case '理科':
          if (this.subject.id === tag.id) {
            return 'tag1'
          } else {
            return 'tag0'
          }
        case '工科':
          if (this.subject.id === tag.id) {
            return 'tag3'
          } else {
            return 'tag0'
          }
        case '商科':
          if (this.subject.id === tag.id) {
            return 'tag5'
          } else {
            return 'tag0'
          }
        case '社科':
          if (this.subject.id === tag.id) {
            return 'tag7'
          } else {
            return 'tag0'
          }
        case '医科':
          if (this.subject.id === tag.id) {
            return 'tag9'
          } else {
            return 'tag0'
          }
      }
    },
    selectedTagcol (tag) {
      switch (tag.type) {
        case '理科':
          return 'tag2'
        case '工科':
          return 'tag4'
        case '商科':
          return 'tag6'
        case '社科':
          return 'tag8'
        case '医科':
          return 'tag10'
      }
    },
    tagHover (tag) {
      if (this.subject.id !== tag.id) {
        let className = this.selectedTagcol(tag)
        document.getElementById('' + tag.id).className += ' ' + className
      }
    },
    tagLeave (tag) {
      if (this.subject.id !== tag.id) {
        let className = this.selectedTagcol(tag)
        const classN = document.getElementById('' + tag.id).className
        document.getElementById('' + tag.id).className = classN.replace(
          className,
          ''
        )
      }
    },
    initSubjects () {
      this.showSubjects = {
        理科: [],
        医科: [],
        工科: [],
        商科: [],
        社科: []
      }
    },
    remoteDirection: debounce(function (query) {
      this.initSubjects()
      this.getSubject(this.subjectValue)
    }, 500)
  }
}
</script>

<style scoped lang="scss">
.warpper {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  padding: 0 20px 20px;
  overflow: scroll;
  height: 380px;
  position: absolute;
  z-index: 20;
  left: 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .searchSub {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 21;
    padding-top: 20px;
    background-color: #fff;
    border-bottom: solid 1px #c0c0c0;
    .icon {
      margin-top: 5px;
    }
    /deep/.el-input__inner {
      background-color: #fff;
      border: solid 0px;
      padding-left: 5px;
      padding-right: 0px;
    }
  }
  .tagSelectWrapper {
    .rowWrapper {
      margin-top: 16px;
      .title {
        margin-left: 6px;
        color: #c0c0c0;
        font-size: 12px;
        line-height: 17px;
      }
    }
  }
}
</style>
