<template>
  <div>
    <el-dialog :visible="true" v-if="step === 0" title="批量上架">
      <div>
        <el-upload drag action="" :httpRequest="success">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em style="color: #8dc152; text-decoration: underline">
              点击上传
            </em>
          </div>
        </el-upload>
      </div>
      <div slot="footer">
        <el-button @click="close()">
          返回
        </el-button>
        <el-button type="primary" @click="next">加载</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible="true" :custom-class="pros.length > 0 ? 'forms' : 'form'" v-else-if="step === 1" title="上架科研">
      <el-row>
        <el-col :span="10" v-if="pros.length > 0">
          <div class="tlist">
            <div v-for="(item, index) in pros" :key="item.title" :class="clicked === index ? 'clicked' : ''"
              @click="selectTopic(index)">
              {{ index + 1 }}.{{ item.firstName }}
              {{ item.lastName }}
              <i class="el-icon-close close" @click.stop="deleteOne(index)"></i>
            </div>
          </div>
        </el-col>
        <el-col :span="pros.length > 0 ? 14 : 24" style="padding-left:10px">
          <el-form :model="pro" :rules="rules" ref="form">
            <el-row>
              <el-col :span="5">姓名*：</el-col>
              <el-col :span="19">
                <el-form-item prop="professor">
                  <el-select v-model="pro.professor" placeholder="姓名" remote filterable :remote-method="remoteProfessor"
                    value-key="id">
                    <el-option v-for="item in professors" :key="item.id" :label="`${item.firstName} ${item.lastName}`"
                      :value="item" @click.native="getProfessor(item)"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">职位</el-col>
              <el-col :span="19">
                <el-form-item prop="position">
                  <el-input v-model="pro.professor.position" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">学校</el-col>
              <el-col :span="19">
                <el-form-item prop="school">
                  <el-input v-model="pro.professor.school" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row>
              <el-col :span="5">课时数</el-col>
              <el-col :span="19">
                <el-form-item prop="hours">
                  <el-input v-model="pro.professor.hours" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
            </el-row> -->
            <el-row>
              <el-col :span="5">推荐信</el-col>
              <el-col :span="19">
                <el-form-item prop="letter">
                  <el-input v-model="pro.professor.letter" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">备注</el-col>
              <el-col :span="19">
                <el-form-item prop="misc">
                  <el-input v-model="pro.professor.misc" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row>
              <el-col :span="5">海报标签*：</el-col>
              <el-col :span="19">
                <el-form-item prop="direction">
                  <el-select v-model="pro.direction" filterable remote :remote-method="remoteDirection" value-key="id"
                    style="width:100%">
                    <el-option v-for="item in directions" :key="item.id" :label="item.content" :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row> -->
            <el-row>
              <el-col :span="5">学科标签*：</el-col>
              <el-col :span="19">
                <el-form-item prop="subject">
                  <tagsSearch :subject.sync="pro.subject"></tagsSearch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5"> 方向标签*：</el-col>
              <el-col :span="19">
                <el-form-item prop="tagsText">
                  <el-input v-model="pro.tagsText"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">学科简介*</el-col>
              <el-col :span="19">
                <el-form-item prop="introduction">
                  <el-input v-model="pro.introduction" type="textarea"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">专题方向一*</el-col>
              <el-col :span="19">
                <el-form-item prop="topicDirection[0].title">
                  <el-input v-model="pro.topicDirection[0].title" type="textarea"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">专题方向一摘要*</el-col>
              <el-col :span="19">
                <el-form-item prop="topicDirection[0].abstract">
                  <el-input v-model="pro.topicDirection[0].abstract" type="textarea"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">专题方向二*</el-col>
              <el-col :span="19">
                <el-form-item prop="topicDirection[1].title">
                  <el-input v-model="pro.topicDirection[1].title" type="textarea"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">专题方向二摘要*</el-col>
              <el-col :span="19">
                <el-form-item prop="topicDirection[1].abstract">
                  <el-input v-model="pro.topicDirection[1].abstract" type="textarea"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">专题方向三*</el-col>
              <el-col :span="19">
                <el-form-item prop="topicDirection[2].title">
                  <el-input v-model="pro.topicDirection[2].title" type="textarea"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">专题方向三摘要*</el-col>
              <el-col :span="19">
                <el-form-item prop="topicDirection[2].abstract">
                  <el-input v-model="pro.topicDirection[2].abstract" type="textarea"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">推荐选题</el-col>
              <el-col :span="19">
                <el-form-item prop="advise" style="text-align:right ">
                  <el-input v-model="pro.advise[index]" v-for="(item, index) in pro.advise" :key="index"
                    style="margin-bottom:5px">
                  </el-input>
                  <div style="">
                    <el-button icon="el-icon-minus" style="min-width:30px" circle @click="
                      () => {
                        if (this.pro.advise.length > 1) this.pro.advise.pop()
                      }
                    " />
                    <el-button icon="el-icon-plus" style="min-width:30px" circle @click="
                      () => {
                        this.pro.advise.push('')
                      }
                    " />
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">海报背景图*：</el-col>
              <el-col :span="19">
                <el-form-item prop="img">
                  <el-upload class="uploader" drag :action="uploadUrl" multiple :show-file-list="false" :on-success="
                    (res) => {
                      this.pro.img = res.url
                      this.$forceUpdate()
                    }
                  ">
                    <img v-if="pro.img" :src="pro.img" class="avatar" crossorigin="anonymous" />
                    <i v-else class="el-icon-plus uploader-icon"></i>
                  </el-upload>
                  <div>推荐尺寸375*393（宽高）</div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-button @click="back()">返回</el-button>
        <el-button type="primary" @click="preview()">预览</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible="true" v-else-if="step === 2" :title="pro.title" custom-class="iframe">
      <div style="display:flex;overflow: scroll;">
        <proProPage :pro="pro" @getPoster="getPoster" style="height: 70vh;overflow: scroll;width: 450px;flex-shrink: 0;">
        </proProPage>
        <div class="divide"></div>
        <div style="width: 450px;position:relative;height: 70vh;overflow: scroll;overflow-x: hidden;flex-shrink: 0;">
          <proIpage  ref="proIpage" :pro="pro"></proIpage>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="step = 1">返回</el-button>
        <el-button type="primary" @click="commit()" :disabled="pending">
          上传
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import axios from '../axios'
import tagsSearch from '../pub/tagsSearch'
import tagsSearchSecond from '../pub/tagsSearchSecond'
import ExcelJS from 'exceljs'
import addform from '../pub/form/addform'
import proProPage from './proProPage'
import proIpage from './proIpage'

export default {
  components: {
    tagsSearch,
    tagsSearchSecond,
    addform,
    proProPage,
    proIpage
  },
  data() {
    var checkImg = (rule, value, callback) => {
      if (!this.pro.img) {
        callback(new Error('请输入海报背景图'))
      } else {
        callback()
      }
    }
    return {
      uploadUrl: `${axios.defaults.baseURL}/upload/`,
      step: 0,
      rules: {
        professor: [{ required: true, message: '请填写教授', trigger: 'blur' }],
        subject: [{ required: true, message: '请填写学科门类', trigger: 'blur' }],
        direction: [{ required: true, message: '请填写方向', trigger: 'change' }],
        introduction: [{ required: true, message: '请填写学科简介', trigger: 'blur' }],
        'topicDirection[0].title': [{ required: true, message: '请填写专题方向一', trigger: 'blur' }],
        'topicDirection[0].abstract': [{ required: true, message: '请填写专题方向一摘要', trigger: 'blur' }],
        'topicDirection[1].title': [{ required: true, message: '请填写专题方向二', trigger: 'blur' }],
        'topicDirection[1].abstract': [{ required: true, message: '请填写专题方向二摘要', trigger: 'blur' }],
        'topicDirection[2].title': [{ required: true, message: '请填写专题方向三', trigger: 'blur' }],
        'topicDirection[2].abstract': [{ required: true, message: '请填写专题方向三摘要', trigger: 'blur' }],
      },
      pro: {
        advise: [''],
        subject: '',
        tagsText: '',
        tags: [],
        professor: {},
        topicDirection: [{ title: '', abstract: '' }, { title: '', abstract: '' }, { title: '', abstract: '' }],
        targetAudienceStatus: 0
      },
      pros: [],
      clicked: 0,
      iap_id: 0,
      put: false,
      pending: false,
      ready: false,
      professors: [],
      directions: [],
    }
  },
  methods: {
    get_pro(pro) {
      this.pro = pro
      this.put = true
      this.step = 1
      this.remoteProfessor(this.pro.professor.firstName, true)
      // this.remoteDirection(this.pro.direction.content)
      // console.log(this.pro, pro)
    },
    back() {
      if (this.put) {
        this.close()
      } else {
        this.step = 0
      }
    },
    close() {
      this.$parent.$parent.getlist()
      this.$parent.$parent.iapForm = false
    },
    success(e) {
      const key = e.file
      const render = new FileReader()
      render.readAsArrayBuffer(key)
      render.onload = async () => {
        const workbook = new ExcelJS.Workbook()
        workbook.xlsx.load(render.result).then(book => {
          // console.log('book', book)
          const worksheet = book.getWorksheet(1)
          const rows = worksheet.getRows(2, worksheet.rowCount)
          rows.forEach(e => {
            const t = e.values
            console.log('1', t[5])
            const pro = {
              professor: {
                position: '',
                school: '',
                hour: '',
                letter: '',
                misc: '',
              },
              subject: t[4],
              introduction: t[6],
              // involve: t[8],
              // direction: t[3],
              // direction_tag: t[3],
              tagsText: t[5],
              topicDirection: [{ title: t[7], abstract: t[8] }, { title: t[9], abstract: t[10] }, { title: t[11], abstract: t[12] }],
              firstName: t[1],
              lastName: t[2]
            }
            // for (let i = 4; i < 10; i++) { 
            //   if (t[i] !== undefined) {
            //     pro.tags.push(t[i])
            //   } else {
            //     break
            //   }
            // }
            // for (let i = 11; i < 14; i++) {
            //   if (t[i] !== undefined) {
            //     pro.advise.push(t[i])
            //   } else {
            //     break
            //   }
            // }
            if (pro.firstName || pro.lastName) {
              this.pros.push(pro)
            }
          })
        })
      }
    },
    next() {
      console.log(this.pro.targetAudienceStatus)
      if (this.pros.length === 0) {
        this.step = 1
      } else {
        this.step = 1
        this.selectTopic(0)
      }
    },
    selectTopic(index) {
      this.pro = this.pros[index]
      this.clicked = index
      this.remoteProfessor('', false, true)
      this.validateLabel()
    },
    validateLabel() {
      if (typeof this.pro.subject === 'string') {
        axios
          .post('previews/validateLabel', {
            subject: this.pro.subject,
            // tags: this.pro.tags,
            // direction_tag: this.pro.direction_tag
          })
          .then(response => {
            Object.assign(this.pro, response.data)
            // this.pro.direction = response.data.direction_tag
            // this.directions = [response.data.direction_tag]
            this.$forceUpdate()
          })
      }
    },
    deleteOne(index) {
      this.pros.splice(index, 1)
      if (this.pros.length > 0) {
        if (index < this.clicked) {
          this.clicked--
        } else if (index === this.clicked) {
          this.selectTopic(0)
        }
      } else {
        this.close()
      }
    },
    remoteProfessor(query, put = false, fileFrom = false) {
      axios
        .get('topic/professors', {
          params: {
            name: query
          }
        })
        .then(response => {
          this.professors = response.data.teachers
          if (put) {
            this.professors.forEach(e => {
              if (e.id === this.pro.professor.id) {
                this.getProfessor(e)
              }
            })
          }
          if (fileFrom) {
            this.professors.forEach(e => {
              // console.log(e.firstName, e.lastName, this.pro.professor.firstName, this.pro.professor.lastName)
              if (e.firstName === this.pro.firstName && e.lastName === this.pro.lastName) {
                // console.log(e.firstName)
                this.getProfessor(e)
              }
            })
          }
        })
    },
    getProfessor(item) {
      this.pro.professor = item
      // console.log(this.pro)
    },
    // remoteDirection(query) {
    //   axios
    //     .get('DBS/direction_tag', {
    //       params: {
    //         content: query
    //       }
    //     })
    //     .then(response => {
    //       this.directions = response.data.directions
    //       this.$forceUpdate()
    //     })
    // },
    preview() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (!this.put) {
            let res = ''
            for (let i = 0; i < 5; i++) {
              res += Math.floor(Math.random() * 10)
            }
            res += ('000' + this.pro.professor.id).slice(('000' + this.pro.professor.id).length - 3)
            res += ('000' + this.pro.subject.id).slice(('000' + this.pro.subject.id).length - 3)
            let uuid = parseInt(res).toString(16)
            // console.log(uuid, 'uuid')
            this.pro.uuid = uuid
          }
          this.$nextTick(() => {
            this.$refs.proIpage.targetAudienceStatus = this.pro.targetAudienceStatus
          })
          this.step = 2
        } else {
          this.$message({
            showClose: true,
            message: '请输入必填项',
            type: 'warning'
          })
        }
      })
    },
    commit() {
      this.pending = true
      if (this.put) {
        axios.put(`topic/pro/${this.pro.id}`, this.pro).then(() => {
          this.$message({
            showClose: true,
            message: '修改成功',
            type: 'success'
          })
          this.close()
        })
      } else {
        axios.post('topic/pro', this.pro).then(res => {
          this.$message({
            showClose: true,
            message: '上传成功',
            type: 'success'
          })
          this.pros.splice(this.clicked, 1)
          if (this.pros.length > 0) {
            this.selectTopic(0)
            this.step = 1
            this.pending = false
          } else {
            this.close()
          }
        })
      }
    },
    getPoster(data) {
      this.pro.poster = data
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-upload {
  width: 100%;

  .el-upload-dragger {
    background-color: inherit;
    border-width: 2px;
  }
}

/deep/ .form {
  max-height: 95%;

  .el-dialog__footer {
    padding-top: 25px;
  }
}

/deep/ .forms {
  width: 70%;
  padding: 0;
  max-height: 95%;

  .el-dialog__body {
    padding: 0;
    color: #656d78;
  }

  .el-dialog__footer {
    padding-top: 25px;
  }
}

.tlist {
  height: 70vh;
  overflow: scroll;
  background-color: #fff;
  text-align: left;
  width: 85%;
  margin: auto;
  padding: 10px;

  div {
    width: 90%;
    margin: 5px auto;
    padding: 5px 5px;
    line-height: 30px;
    cursor: pointer;
    position: relative;
  }

  .close {
    color: #c0c0c0;
    font-size: 20px;
    position: absolute;
    right: 5px;
    top: 8px;
  }

  .clicked {
    background-color: #a0d469;
    color: #fff;

    .close {
      color: #fff;
    }
  }
}

.el-form {
  height: 70vh;
  overflow: scroll;
  padding-right: 30px;

  .el-rate__icon {
    line-height: 30px;
  }

  .el-row {
    padding: 8px 0;

    .el-col {
      line-height: 30px;
    }
  }
}

.direction {
  border-bottom: solid 1px #c0c0c0;

  .icon {
    margin-top: 5px;
  }

  /deep/ .el-input__inner {
    background-color: #f4f6f9;
    border: solid 0px;
    padding-left: 5px;
    padding-right: 0px;
  }
}

/deep/ .uploader {
  .el-upload {
    width: 180px;
  }

  .uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}

/deep/ .iframe {
  width: 70%;
  max-height: 95%;

  .el-dialog__body {
    text-align: left;
  }
}

iframe {
  border: none;
  height: 70vh;
  width: 500px;
}

.el-button {
  margin-bottom: 10px;
}

.divide {
  width: 0;
  height: 70vh;
  margin: 0 10px;
  border-left: #c0c0c0 3px dashed;
}

.eliwise-loading {
  font-size: 100px;
  width: 100px;
  height: 100px;
  color: #c0c0c0;
  margin: auto;
}

/deep/ .el-form-item {
  margin-bottom: 0px;
}
</style>
