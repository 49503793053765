<template>
  <el-main class="main" v-if="show">
    <div class="title">
      <div style="margin: auto; width: 85%">
        <h2>菁英怀思学院导师手册</h2>
      </div>
    </div>
    <div class="list">
      <div class="project">
        <manualList :type="'项目介绍'" />
        <manualList :type="'面试阶段'" />
        <manualList :type="'辅导阶段'" />
        <manualList :type="'结项阶段'" />
        <manualList :type="'其他'" />
      </div>
    </div>
    <manualForm ref="manualForm" :type="'post'"></manualForm>
  </el-main>
</template>

<script>
import manualList from './manualList_mp'
import manualForm from './manualForm'
import axios from '../axios'

export default {
  name: 'manual_mp',
  data () {
    return {
      manuals: [],
      introduce: false,
      interviewed: false,
      tutoring: false,
      postProject: false,
      url: '',
      key: '',
      show: true
    }
  },
  components: {
    manualList,
    manualForm
  },
  beforeCreate () {
    this.$eventbus.$on('postval', val => {
      this.url = val
    })
    this.key = this.$utils.getUrlKey('key')
    axios
      .post('/manual/is_valid', {
        key: this.key
      })
      .then(res => {
        if (res.data.is_valid === false) {
          this.show = false
          this.$message({
            message: '链接已失效',
            type: 'error'
          })
        }
      })
  },
  methods: {
    postform () {
      this.$refs.manualForm.active = true
    }
  }
}
</script>
<style lang="scss" scoped>
.main {
  padding: 0px;
  min-width: 375px;
}
.tableTitle {
  display: flex;
  font-size: 12px;
  color: #909399;
  font-weight: bold;
}
.title {
  background: #f5f7fa;
  position: relative;
  height: 70px;
  border-bottom: 10px #e8e8e8 solid;
  h2 {
    font-size: 24px;
    color: #434a54;
    font-weight: normal;
    float: left;
    margin-top: 20px;
  }
}
.list {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 0 auto 45px;
}
.project {
  padding-right: 10px;
  margin-top: 10px;
  min-width: 150px;
  flex: 1 1 auto;
  margin-bottom: 15px;
}
</style>
