import Vue from 'vue'
import './scss/element.scss'
import App from './App.vue'
import store from './store/store'
import router from './router/index'
import ElementUI from 'element-ui'
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

import VueParticles from 'vue-particles'
import VueClipboard from 'vue-clipboard2'
import utils from '../utils'
import './bus.js'

Vue.prototype.$utils = utils
Vue.config.productionTip = false

ElementUI.Select.computed.readonly = function () {
  return !this.filterable
}
ElementUI.Dialog.props.showClose = false
ElementUI.MessageBox.setDefaults({
  showClose: false
})
Vue.use(ElementUI)
Vue.use(VueParticles)
Vue.use(VueClipboard)
Vue.use(VideoPlayer)
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
