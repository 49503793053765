<template>
  <el-main class="main">
    <div class="content">
      <h2>{{title}}项目检索</h2>
      <div class="buttonsWra">
        <el-tooltip
          content="请选择项目"
          effect="light"
          placement="top"
          :disabled="Selection.length > 0"
        >
          <el-button
            @click="exportTopic()"
            class="export"
            type="primary"
            :disabled="Selection.length === 0">
            导 出
          </el-button>
        </el-tooltip>
        <el-tooltip
          content="请选择项目"
          effect="light"
          placement="top"
          :disabled="Selection.length > 0"
        >
          <div class="downloadtip">
            <el-button
              v-loading="loading"
              type="primary"
              class="download"
              @click="down_poseter()"
              :disabled="Selection.length === 0 || loading"
              >下载海报
            </el-button>
          </div>
        </el-tooltip>
        <el-button @click="queryCount = true" class="add" type="primary" v-if="!xdf && !xt">
          扫码次数统计
        </el-button>
      </div>
      <el-row class="top">
        <el-col :span="6" class="item">
          <el-select
            v-model="tag"
            placeholder="标签"
            filterable
            remote
            :remote-method="remoteDirection"
            @change="tagselect()"
          >
            <el-option
              v-for="item in directions"
              :key="item.id"
              :label="item.content"
              :value="item.content"
            >
            </el-option>
          </el-select>
          <el-tag
            :key="tag"
            v-for="tag in tags"
            closable
            :disable-transitions="true"
            @close="tagClose(tag)"
          >
            {{ tag }}
          </el-tag>
        </el-col>
        <el-col :span="5" class="item" v-if="lsp">
          <div class="domestic-filter">
            <span class="filter-label">国内申请:</span>
            <el-select v-model="teacher_domestic" placeholder="国内申请" @change="handleDomesticChange">
              <el-option label="全部" value=""></el-option>
              <el-option label="是" value="是"></el-option>
              <el-option label="否" value="否"></el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="lsp ? 11 : 16" class="item">
          <el-input
            placeholder="项目名称/适合专业/导师院校/一二级学科标签/导师姓名等关键词"
            v-model="keyword"
            @keyup.enter.native="search()"
            @input="input_search()"
          >
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button class="sea" type="primary" @click="search()">
            搜索
          </el-button>
        </el-col>
      </el-row>
      <div class="DBS">
        <el-table
          :data="tableData"
          class="base"
          :row-class-name="fullclass"
          @sort-change="sortchange"
          :default-sort="{ prop: 'updated', order: 'descending' }"
          ref="tableList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" align="center" label="全选"> </el-table-column>
          <el-table-column
            prop="topic_id"
            align="center"
            min-width="12"
            label="编号"
            sortable="custom"
            :sort-orders="['descending', 'ascending']"
          >
          </el-table-column>
          <el-table-column
            prop="degree"
            align="center"
            min-width="10"
            label="难度"
            sortable="custom"
            :sort-orders="['descending', 'ascending']"
          >
          </el-table-column>
          <el-table-column
            prop="title"
            min-width="45"
            header-align="center"
            align="left"
            label="项目名称"
          >
            <template slot-scope="scope">
              <span v-html="showKeyWord(scope.row.title)"></span>
              <img v-if="scope.row.new" src="../../assets/icon/newicon.png" class="new" />
              <div class="direction">
                {{ scope.row.direction_tag.content }}：{{ scope.row.subject.content }}
                |
                {{
                  scope.row.direction
                    .map((e) => {
                      return e.content
                    })
                    .join(' | ')
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="teacher"
            min-width="10"
            align="center"
            label="导师姓名"
            sortable="custom"
            :sort-orders="['descending', 'ascending']"
          >
            <template slot-scope="scope">
              {{ scope.row.teacher.name }}
            </template>
          </el-table-column>
          <el-table-column prop="teacher" min-width="10" align="center" label="导师配合程度">
            <template slot-scope="scope">
              {{ scope.row.teacher.cooperate }}
            </template>
          </el-table-column>
          <el-table-column prop="teacher" min-width="10" align="center" label="导师沟通能力">
            <template slot-scope="scope">
              {{ scope.row.teacher.connect }}
            </template>
          </el-table-column>
          <el-table-column v-if="lsp" prop="teacher" min-width="10" align="center" label="是否支持国内申请">
            <template slot-scope="scope">
              {{ scope.row.teacher.domestic ? "是" : "否" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="updated"
            min-width="15"
            align="center"
            label="更新时间"
            sortable="custom"
            :sort-orders="['descending', 'ascending']"
          >
          </el-table-column>
          <el-table-column min-width="15" align="center" label="状态" v-if="byr">
            <template slot-scope="scope">
              <label v-if="scope.row.is_byr_show" class='el-icon-success'></label>
              <label v-if="!scope.row.is_byr_show" class='el-icon-error'></label>
            </template>
          </el-table-column>

          <el-table-column min-width="15" align="center" label="查看详情">
            <template slot-scope="scope">
              <img src="../../assets/icon/预览icon.svg" v-if="IsShowImg(scope.row)"  @click="preview(scope.row)" class="svg" />
              <img v-if="byr"
                src="../../assets/icon/编辑icon.png"
                class="svg"
                @click="hideTopic(scope.$index, scope.row)"
              />
            </template>
          </el-table-column>
          <div slot="empty" class="empty" v-if="has_next">
            <i class="eliwise-loading"></i><span>加载中，请稍后</span>
          </div>
        </el-table>
        <div class="tableborder"></div>
        <div class="bottom">
          <div style="float: left">
            <p style="margin-right: 0px" v-if="Selection.length > 0">
              已选中 <span class="green">{{ Selection.length }}</span> 条项目/
            </p>
            <p>
              共计 <span class="green">{{ topic_total }}</span> 条项目
            </p>
          </div>
          <p class="green" @click="pagedown()" style="cursor: pointer">
            上一页
          </p>
          <p>{{ page }}/{{ page_total }}</p>
          <p class="green" @click="pageup()" style="cursor: pointer">下一页</p>
          <p>跳转至<el-input class="page" v-model="next_page"></el-input>页</p>
          <el-button type="primary" @click="pageto()">确定</el-button>
        </div>
      </div>
    </div>
    <query-count v-if="queryCount" :type="lsp ? 'standard_query' : 'acpage_query'"></query-count>
  </el-main>
</template>

<script>
import axios from '../axios'
import { MessageBox } from 'element-ui'
import changerole from '../pub/changerole'
import queryCount from './queryCount'
export default {
  name: 'Research_pack',
  components: {
    changerole,
    queryCount
  },
  data () {
    return {
      keyword: '',
      keywordvalue: '',
      page: 1,
      topic_total: 1,
      page_total: 1,
      next_page: '',
      has_next: true,
      has_previous: false,
      sort: 'updated',
      desc: true,
      directions: [],
      tag: '',
      tags: [],
      keyid: [],
      Selection: [],
      ids: [],
      loading: false,
      tableData: [],
      queryCount: false,
      lsp: false,
      xdf: false,
      xt: false,
      byr: false,
      ewa: false,
      title: '科研+论文',
      teacher_domestic: ''
    }
  },
  created () {
    if (this.$route.name === 'Standard') {
      this.lsp = true
      this.title = 'Standard'
    }
    if (this.$route.name === 'XDF') {
      this.xdf = true
      this.title = 'XDF'
    }
    if (this.$route.name === 'XT') {
      this.xt = true
      this.title = '新通教育'
    }
    if (this.$route.name === 'BYR') {
      this.byr = true
      this.title = '保研人'
    }
    if (this.$route.name === 'EWA') {
      this.ewa = true
      this.title = 'EWA'
    }
    this.getlist()
  },
  methods: {
    IsShowImg (row) {
      if (!this.byr) {
        return true
      }
      return row.byr_img !== ''
    },
    hideTopic (index, row) {
      MessageBox.confirm('确定修改状态吗').then(() => {
        axios.delete(`/DBS/topic/hide/${row.id}`).then(response => {
          if (response.status === 200) {
            this.$message({
              message: '操作成功',
              type: 'success'
            })
          }
          this.getlist()
        })
      })
    },
    preview (row) {
      let routeUrl = {}
      if (this.xdf) {
        routeUrl = this.$router.resolve({
          path: '/preview',
          query: {
            url: row.xdf_url,
            title: row.title,
            img: row.xdf_img
          }
        })
      } else if (this.byr) {
        routeUrl = this.$router.resolve({
          path: '/preview',
          query: {
            url: row.byr_url,
            title: row.title,
            img: row.byr_img
          }
        })
      } else if (this.ewa) {
        routeUrl = this.$router.resolve({
          path: '/preview',
          query: {
            url: row.ewa_url,
            title: row.title,
            img: row.ewa_img
          }
        })
      } else if (this.xt) {
        routeUrl = this.$router.resolve({
          path: '/preview',
          query: {
            url: row.xt_url,
            title: row.title,
            img: row.xt_img
          }
        })
      } else if (this.lsp) {
        routeUrl = this.$router.resolve({
          path: '/preview',
          query: {
            url: row.lsp_pack_url + '?teacher=true',
            title: row.title,
            img: row.lsp_pack_img
          }
        })
      } else {
        routeUrl = this.$router.resolve({
          path: '/preview',
          query: {
            url: row.pack_url,
            title: row.title,
            img: row.pack_img
          }
        })
      }
      window.open(routeUrl.href, '_blank')
    },
    remoteDirection (query) {
      axios
        .get('DBS/tags', {
          params: {
            content: query
          }
        })
        .then(response => {
          this.directions = response.data.directions
        })
    },
    fullclass ({ row }) {
      if (this.byr) {
        if (row.is_byr_show === false || row.online === false) {
          return 'fulling'
        }
      } else {
        if (row.online === false) {
          return 'fulling'
        }
      }
    },
    tagselect () {
      if (this.tags.indexOf(this.tag) > -1) {
        this.$message({
          message: '请选择其他标签',
          type: 'warning'
        })
      } else this.search()
    },
    tagClose (tag) {
      this.tags.splice(this.tags.indexOf(tag), 1)
      this.search()
    },

    input_search: _.debounce(function () {
      this.search()
    }, 500),
    search () {
      this.keywordvalue = this.keyword
      if (this.tag) {
        this.tags.push(this.tag)
      }
      if (this.tags.length > 0) {
        this.sort = 'search'
        this.$refs.tableList.clearSort()
      } else {
        this.desc = true
        this.sort = 'updated'
        this.$refs.tableList.sort('updated', 'descending')
      }
      this.getsearch()
    },
    getsearch () {
      this.tag = ''
      this.page = 1
      this.getlist()
    },
    sortchange (column) {
      this.sort = column.prop
      switch (column.order) {
        case 'ascending':
          this.desc = false
          break
        case 'descending':
          this.desc = true
          break
        default:
          this.desc = true
          this.sort = ''
      }
      this.getsearch()
    },
    handleSelectionChange (val) {
      this.Selection = []
      val.forEach((value, index, arr) => {
        this.Selection[index] = value.id
      })
      if (this.Selection.length >= 30) this.Selection = this.ids
    },
    exportTopic () {
      axios
          .post(`/DBS/export`, {
            id: this.Selection,
            type: this.xdf ? 'xdf' : this.lsp ? 'standard' : 'pack'
          }, {
            responseType: 'blob'
          })
          .then(res => {
            const filename = res.headers['content-disposition']
            const blob = new Blob([res.data])
            var downloadElement = document.createElement('a')
            var href = window.URL.createObjectURL(blob)
            downloadElement.href = href
            downloadElement.download = decodeURIComponent(filename.split('filename=')[1])
            document.body.appendChild(downloadElement)
            downloadElement.click()
            document.body.removeChild(downloadElement)
            window.URL.revokeObjectURL(href)
          })
    },
    down_poseter () {
      MessageBox.confirm(`已选择${this.Selection.length}张海报，确认下载？`).then(() => {
        this.loading = true
        const url = this.xdf
          ? `${axios.defaults.baseURL}/DBS/xdf/download`
          : `${axios.defaults.baseURL}/DBS/pack/download`
        axios
          .post(url, {
            id: this.Selection,
            lsp: this.lsp
          })
          .then(response => {
            let url = response.data.url
            let iframe = document.createElement('iframe')
            iframe.setAttribute('src', url)
            iframe.style.display = 'none'
            document.body.appendChild(iframe)
            setTimeout(function () {
              iframe.src = 'about:blank'
              try {
                iframe.contentWindow.document.write('')
                iframe.contentWindow.document.clear()
              } catch (e) {}
              iframe.parentNode.removeChild(iframe)
            }, 2000)
            this.loading = false
          })
      })
    },
    pageto () {
      if (this.next_page > 0 && this.next_page <= this.page_total) {
        this.page = this.next_page
        this.getlist()
        document.querySelector('.el-main').scrollTop = 0
      } else {
        this.$message({
          message: '请输入正确的页码',
          type: 'warning'
        })
      }
    },
    pageup () {
      if (this.has_next) {
        this.page++
        this.getlist()
        document.querySelector('.el-main').scrollTop = 0
      } else {
        this.$message({
          message: '已经到最后一页了',
          type: 'warning'
        })
      }
    },
    pagedown () {
      if (this.has_previous) {
        this.page--
        this.getlist()
        document.querySelector('.el-main').scrollTop = 0
      } else {
        this.$message({
          message: '已经是第一页了',
          type: 'warning'
        })
      }
    },
    getlist () {
      let source = ''
      if (this.xt) {
        source = 'XT'
      }
      if (this.byr) {
        source = 'BYR'
      }
      if (this.xdf) {
        source = 'XDF'
      }
      if (this.lsp) {
        source = 'LSP'
      }
      if (this.ewa) {
        source = 'EWA'
      }
      axios
        .get('/DBS/topic_list/thesis/pack', {
          params: {
            tags: this.tags.join(','),
            keyword: this.keyword,
            page: this.page,
            sort: this.sort,
            desc: this.desc,
            role: true,
            admin: true,
            source: source,
            teacher_domestic: this.teacher_domestic
          }
        })
        .then(response => {
          this.topic_total = response.data.topic_total
          this.page_total = response.data.page_total
          this.has_next = response.data.has_next
          this.has_previous = response.data.has_previous
          this.ids = response.data.ids
          this.tableData = response.data.topics
        })
    },
    showKeyWord (str) {
      if (this.keywordvalue !== '') {
        let regStr = `${this.keywordvalue}`
        let reg = new RegExp(regStr, 'g')
        str = str.replace(
          reg,
          `<span style="background-color:#F7B500;">${this.keywordvalue}</span>`
        )
      }
      return str
    },
    handleDomesticChange() {
      this.page = 1 // Reset page number when filter changes
      this.getlist()
    }
  }
}
</script>

<style scoped>
.domestic-filter {
  display: flex;
  align-items: center;
}

.filter-label {
  white-space: nowrap;
  margin-right: 8px;
  font-size: 14px;
  color: #606266;
}

.domestic-filter .el-select {
  flex: 1;
}
</style>
