<template>
  <el-main class="main">
    <div class="modtitle">
      <h2 class="jtext">菁英怀思学院导师手册</h2>
    </div>
    <div class="list">
      <div class="tableTitle">
        <p style="margin-left: 10%">手册名称</p>
        <p style="margin-left: 70%">上传日期</p>
      </div>
      <div class="project">
        <manualList :type="'导师手册'" :readOnly="true" />
        <manualList :type="'项目介绍'" :readOnly="true" />
        <manualList :type="'面试阶段'" :readOnly="true" />
        <manualList :type="'辅导阶段'" :readOnly="true" />
        <manualList :type="'结项阶段'" :readOnly="true" />
        <manualList :type="'其他'" :readOnly="true" />
      </div>
    </div>
  </el-main>
</template>

<script>
import manualList from '../manual/manualList'
import changerole from '../pub/changerole'

export default {
  name: 'manual',
  data () {
    return {
      manuals: [],
      introduce: false,
      interviewed: false,
      tutoring: false,
      postProject: false
    }
  },
  components: {
    manualList,
    changerole
  }
}
</script>
<style lang="scss" scoped>
.tableTitle {
  margin-top: 30px;
  display: flex;
  font-size: 12px;
  color: #909399;
  font-weight: bold;
}
.list {
  width: 85%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 0 auto 45px;
}
.project {
  margin-top: 10px;
  min-width: 150px;
  flex: 1 1 auto;
}
.modtitle {
  height: 63px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c0c0c0;
  .jtext {
    color: #434a54;
    position: absolute;
    left: 10%;
    font-weight: normal;
  }
}
</style>
